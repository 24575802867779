import Vue from 'vue';
import Router from 'vue-router';

import Content from './components/Content';
import Login from './views/Login';
import Home from './views/Home';
import AnnouncementList from './views/AnnouncementList';
import AnnouncementEdit from './views/AnnouncementEdit';
import Announcement from './views/Announcement';
import AnnouncementType from './views/AnnouncementType';
import MeetingRoom from '@/views/PlaceReservation/MeetingRoom';
import ReceptionArea from '@/views/PlaceReservation/ReceptionArea';
import Booked from '@/views/PlaceReservation/Booked';
import CompanyFiles from './views/CompanyFiles/CompanyFilesList';
import CompanyFilesEdit from './views/CompanyFiles/CompanyFilesEdit';
import CompanyFilesType from './views/CompanyFiles/CompanyFilesType';
import Account from './views/Account';
import AccountRecord from './views/AccountRecord';
import Groups from './views/Groups';
import PermissionList from './views/PermissionList';
import GroupsPermissionSetting from './views/GroupsPermissionSetting';
import RoleSwitching from './views/RoleSwitching';
import Seating from './views/Seating';
import Extension from './views/Extension';
import Order from './views/OrderSystem/Order';
import OrderDinner from './views/OrderSystem/OrderDinner';
import Stores from './views/OrderSystem/Stores';
import OrderManagement from './views/OrderSystem/OrderManagement';
import OrderTime from './views/OrderSystem/OrderTime';
import OrderStatistics from './views/OrderSystem/OrderStatistics';
import OrderMealCosts from './views/OrderSystem/OrderMealCosts';
import DailyMealSetting from './views/OrderSystem/DailyMealSetting';
import EatingHabits from './views/OrderSystem/EatingHabits';
import StoreMenu from './views/OrderSystem/StoreMenu';
import Newcomers from './views/Newcomers';
import OrderNextWeekMealAll from '@/views/OrderSystem/OrderNextWeekMealAll';
import OrderNextWeekMeal from '@/views/OrderSystem/OrderNextWeekMeal';
import OrderMenu from '@/views/OrderSystem/OrderMenu';
import OrderDinnerMenu from '@/views/OrderSystem/OrderDinnerMenu';
import Products from '@/views/Product/Products';
import ProductsManager from '@/views/Product/ProductsManager';
import OfficeSetting from '@/views/OfficeSetting';
import RollCallSystem from '@/views/RollCallSystem';
import ActionWorkSystem from '@/views/ActionWorkSystem';
import TimeClock from '@/views/Punch/TimeClock';
import AttendanceRecord from '@/views/Punch/AttendanceRecord';
import ActivityCalendar from '@/views/Activity/ActivityCalendar';
import MyActivities from '@/views/Activity/MyActivities';
import ActivityEdit from '@/views/Activity/ActivityEdit';
import ActivityType from '@/views/Activity/ActivityType';
import ActivityParticipants from '@/views/Activity/ActivityParticipants';
import ActivitySetting from '@/views/Activity/ActivitySetting';
import ActivityList from '@/views/Activity/ActivityList';
import ActivityAllList from '@/views/Activity/ActivityAllList';
import MarqueeList from '@/views/Marquee/MarqueeList';
import ExpatList from '@/views/Expat/ExpatList';
import Sign from '@/views/Flow/Sign';
import SignHistory from '@/views/Flow/SignHistory';
import YearList from '@/views/OKR/YearList';
import YearEdit from '@/views/OKR/YearEdit';
import DivisionList from '@/views/OKR/DivisionList';
import DivisionEdit from '@/views/OKR/DivisionEdit';
import DeptList from '@/views/OKR/DeptList';
import DeptEdit from '@/views/OKR/DeptEdit';
import PersonalAll from '@/views/OKR/PersonalAll';
import PersonalList from '@/views/OKR/PersonalList';
import PersonalEdit from '@/views/OKR/PersonalEdit';
import ScoreAddFlow from '@/views/OKR/ScoreAddFlow';
import ScorePersonalAll from '@/views/OKR/ScorePersonalAll';
import ScorePersonalList from '@/views/OKR/ScorePersonalList';
import ScorePersonalEdit from '@/views/OKR/ScorePersonalEdit';
import ScoreTotalEdit from '@/views/OKR/ScoreTotalEdit';
import ScoreHistoryAll from '@/views/OKR/ScoreHistoryAll';
import ScoreHistoryList from '@/views/OKR/ScoreHistoryList';
import ScoreBalance from '@/views/OKR/ScoreBalance';
import NewJobList from '@/views/OKR/NewJobList';
import NewJobEdit from '@/views/OKR/NewJobEdit';
import BigScorePersonalAll from '@/views/OKR/BigScorePersonalAll';
import BigScorePersonalList from '@/views/OKR/BigScorePersonalList';
import BigScorePersonalEdit from '@/views/OKR/BigScorePersonalEdit';
import BigScoreCenterEdit from '@/views/OKR/BigScoreCenterEdit';
import BigScoreTotalEdit from '@/views/OKR/BigScoreTotalEdit';
import BigScoreHistoryAll from '@/views/OKR/BigScoreHistoryAll';
import BigScoreHistoryList from '@/views/OKR/BigScoreHistoryList';
import BigScoreBalanceAll from '@/views/OKR/BigScoreBalanceAll';
import BigScoreBalance from '@/views/OKR/BigScoreBalance';
import HrScoreBalance from '@/views/OKR/HrScoreBalance';
import GroupSet from '@/views/OKR/GroupSet';
import FlowEdit from '@/views/OKR/FlowEdit';
import TraineeAssignList from '@/views/OKR/TraineeAssignList';
import CycleList from '@/views/OKR/CycleList';
import PersonalTimeAll from '@/views/OKR/PersonalTimeAll';
import PersonalTimeList from '@/views/OKR/PersonalTimeList';
import PersonalTimeEdit from '@/views/OKR/PersonalTimeEdit';
import BalanceTimeList from '@/views/OKR/BalanceTimeList';
import PersonalTimeSetHistory from '@/views/OKR/PersonalTimeSetHistory';
import OverTimeSet from '@/views/OKR/OverTimeSet';
import Score from '@/views/OKR/Score';
import SetScorePerson from '@/views/OKR/SetScorePerson';
import ScoreList from '@/views/OKR/ScoreList';
import HrScoreWorking from '@/views/OKR/HrScoreWorking';
import ManagerScoreWorking from '@/views/OKR/ManagerScoreWorking';
import ScoreEdit from '@/views/OKR/ScoreEdit';
import XinStarsAccount from '@/views/XinStarsAccount';
import Shop from '@/views/Shop/Shop';
import ShopDetail from '@/views/Shop/ShopDetail';
import ShopList from '@/views/Shop/ShopList';
import ShopEdit from '@/views/Shop/ShopEdit';
import ShopCategory from '@/views/Shop/ShopCategory';
import ShopArea from '@/views/Shop/ShopArea';

import ETCourseList from '@/views/EducationTraining/CourseList';
import ETCourseTypes from '@/views/EducationTraining/CourseTypes';
import ETCourseEdit from '@/views/EducationTraining/CourseEdit';
import ETCourseRecordAll from '@/views/EducationTraining/CourseRecordAll';
import ETChapter from '@/views/EducationTraining/Chapter';
import ETChapterEdit from '@/views/EducationTraining/ChapterEdit';
import ETCourseDetail from '@/views/EducationTraining/CourseDetail';
import ETCourseRecordDept from '@/views/EducationTraining/CourseRecordDept';
import ETCourseRecordPersonal from '@/views/EducationTraining/CourseRecordPersonal';
import ETCourseRecordEdit from '@/views/EducationTraining/CourseRecordEdit';
import ETCourseRecordDetail from '@/views/EducationTraining/CourseRecordDetail';
import ETStudentCourseList from '@/views/EducationTraining/Student/List';
import ETStudentCourse from '@/views/EducationTraining/Student/Course';
import ETStudentCourseDetail from '@/views/EducationTraining/Student/Detail';
import ETCourseQuizEdit from '@/views/EducationTraining/CourseQuizEdit';
import ETCourseQuizPreview from '@/views/EducationTraining/CourseQuizPreview';

Vue.use(Router);

export default new Router({
  //mode: "history",
  routes: [
    {
      path: '/Login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/Home',
      component: Content,
      children: [
        {
          path: '/',
          name: 'Home',
          component: Home,
        },
        {
          path: '/AnnouncementList/:id', // 公告列表
          name: 'AnnouncementList',
          component: AnnouncementList,
          props: true,
        },
        {
          path: '/AnnouncementEdit/:type/:id?', // 公告編輯
          name: 'AnnouncementEdit',
          component: AnnouncementEdit,
          props: true,
        },
        {
          path: '/Announcement/:id', // 公告內容
          name: 'Announcement',
          component: Announcement,
        },
        {
          path: '/AnnouncementType', // 公告類別
          name: 'AnnouncementType',
          component: AnnouncementType,
        },
        {
          path: '/MeetingRoom', // 會議室
          name: 'MeetingRoom',
          component: MeetingRoom,
        },
        {
          path: '/ReceptionArea', // 交誼廳
          name: 'ReceptionArea',
          component: ReceptionArea,
        },
        {
          path: '/Booked', // 我的預約歷程
          name: 'Booked',
          component: Booked,
        },
        {
          path: '/CompanyFilesType', // 公司文件類別
          name: 'CompanyFilesType',
          component: CompanyFilesType,
        },
        {
          path: '/CompanyFiles', // 公司文件
          name: 'CompanyFiles',
          component: CompanyFiles,
        },
        {
          path: '/CompanyFilesEdit/:id?', // 公司文件編輯
          name: 'CompanyFilesEdit',
          component: CompanyFilesEdit,
        },
        {
          path: '/Seating', // 座位表
          name: 'Seating',
          component: Seating,
        },
        {
          path: '/Extension', // 分機表
          name: 'Extension',
          component: Extension,
        },
        {
          path: '/OfficeSetting', // 辦公室管理
          name: 'OfficeSetting',
          component: OfficeSetting,
        },
        {
          path: '/Products', // 公司產品
          name: 'Products',
          component: Products,
        },
        {
          path: '/ProductsManager', // 公司產品管理
          name: 'ProductsManager',
          component: ProductsManager,
        },
        {
          path: '/Order/:expatId?', // 午餐列表
          name: 'Order',
          component: Order,
        },
        {
          path: '/All/OrderNextWeekMeal/:expatId?', // 預訂下週餐點
          name: 'OrderNextWeekMealAll',
          component: OrderNextWeekMealAll,
        },
        {
          path: '/OrderNextWeekMeal/:expatId?', // 預訂下週餐點
          name: 'OrderNextWeekMeal',
          component: OrderNextWeekMeal,
        },
        {
          path: '/OrderMenu/:date/:state?/:expatId?', // 下週餐點
          name: 'OrderMenu',
          component: OrderMenu,
        },
        {
          path: '/OrderDinnerMenu', // 今天晚餐
          name: 'OrderDinnerMenu',
          component: OrderDinnerMenu,
        },
        {
          path: '/OrderDinner', // 預訂今天晚餐
          name: 'OrderDinner',
          component: OrderDinner,
        },
        {
          path: '/Stores', // 店家管理
          name: 'Stores',
          component: Stores,
        },
        {
          path: '/StoreMenu/:id', // 店家菜單管理
          name: 'StoreMenu',
          component: StoreMenu,
        },
        {
          path: '/OrderManagement', // 訂單管理
          name: 'OrderManagement',
          component: OrderManagement,
        },
        {
          path: '/OrderTime', // 訂餐時間
          name: 'OrderTime',
          component: OrderTime,
        },
        {
          path: '/OrderStatistics', // 訂餐統計
          name: 'OrderStatistics',
          component: OrderStatistics,
        },
        {
          path: '/OrderMealCosts', // 餐費統計
          name: 'OrderMealCosts',
          component: OrderMealCosts,
        },
        {
          path: '/EatingHabits', // 員工飲食習慣
          name: 'EatingHabits',
          component: EatingHabits,
        },
        {
          path: '/DailyMealSetting', // 每日訂餐設定
          name: 'DailyMealSetting',
          component: DailyMealSetting,
        },
        {
          path: '/Newcomers', // 本月新進同仁
          name: 'Newcomers',
          component: Newcomers,
        },
        {
          path: '/Account', // 帳號設定
          name: 'Account',
          component: Account,
        },
        {
          path: '/AccountRecord', // 帳號使用紀錄
          name: 'AccountRecord',
          component: AccountRecord,
        },
        {
          path: '/Groups', // 群組設定
          name: 'Groups',
          component: Groups,
        },
        {
          path: '/PermissionList', // 功能選單設定
          name: 'PermissionList',
          component: PermissionList,
        },
        {
          path: '/GroupsPermissionSetting', // 群組權限設定
          name: 'GroupsPermissionSetting',
          component: GroupsPermissionSetting,
        },
        {
          path: '/RoleSwitching', // 帳號切換設定
          name: 'RoleSwitching',
          component: RoleSwitching,
        },
        {
          path: '/RollCallSystem', // 線上點名系統
          name: 'RollCallSystem',
          component: RollCallSystem,
        },
        {
          path: '/ActionWorkSystem', // 行動作業系統
          name: 'ActionWorkSystem',
          component: ActionWorkSystem,
        },
        {
          path: '/TimeClock', // 打卡鐘資訊
          name: 'TimeClock',
          component: TimeClock,
        },
        {
          path: '/AttendanceRecord', // 出勤記錄
          name: 'AttendanceRecord',
          component: AttendanceRecord,
        },
        {
          path: '/ActivityCalendar', // 社團活動
          name: 'ActivityCalendar',
          component: ActivityCalendar,
        },
        {
          path: '/MyActivities', // 我的活動歷程
          name: 'MyActivities',
          component: MyActivities,
        },
        {
          path: '/ActivityEdit/:id?', // 編輯活動
          name: 'ActivityEdit',
          component: ActivityEdit,
        },
        {
          path: '/ActivityType', // 活動類別設定
          name: 'ActivityType',
          component: ActivityType,
        },
        {
          path: '/ActivityParticipants/:name/:id', // 活動參加人員
          name: 'ActivityParticipants',
          component: ActivityParticipants,
        },
        {
          path: '/ActivitySetting', // 維護活動分類
          name: 'ActivitySetting',
          component: ActivitySetting,
        },
        {
          path: '/ActivityList', // 活動列表管理
          name: 'ActivityList',
          component: ActivityList,
        },
        {
          path: '/ActivityAllList', // 活動列表
          name: 'ActivityAllList',
          component: ActivityAllList,
        },
        {
          path: '/MarqueeList', // 跑馬燈列表
          name: 'MarqueeList',
          component: MarqueeList,
        },
        {
          path: '/ExpatList', // 人員派駐設定
          name: 'ExpatList',
          component: ExpatList,
        },
        {
          path: '/Flow/Sign', // 收件匣
          name: 'Sign',
          component: Sign,
        },
        {
          path: '/Flow/SignHistory', // 簽核紀錄
          name: 'SignHistory',
          component: SignHistory,
        },
        {
          path: '/OKR/YearList', // 年度目標
          name: 'YearList',
          component: YearList,
        },
        {
          path: '/OKR/YearEdit/:edit?/:year?/:id?', // 編輯年度目標
          name: 'YearEdit',
          component: YearEdit,
        },
        {
          path: '/OKR/DivisionList', // 處級目標
          name: 'DivisionList',
          component: DivisionList,
        },
        {
          path: '/OKR/DivisionEdit/:year?/:month?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯處級目標
          name: 'DivisionEdit',
          component: DivisionEdit,
        },
        {
          path: '/OKR/DeptList', // 部級目標
          name: 'DeptList',
          component: DeptList,
        },
        {
          path: '/OKR/DeptEdit/:year?/:month?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯部級目標
          name: 'DeptEdit',
          component: DeptEdit,
        },
        {
          path: '/All/OKR/PersonalList/:type?', // 人員、新人、實習生目標
          name: 'PersonalAll',
          component: PersonalAll,
        },
        {
          path: '/OKR/PersonalList/:type?', // 人員、新人、實習生目標
          name: 'PersonalList',
          component: PersonalList,
        },
        {
          path: '/OKR/PersonalEdit/:type?/:year?/:month?/:deptId?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯人員、新人、實習生目標
          name: 'PersonalEdit',
          component: PersonalEdit,
        },
        {
          path: '/OKR/ScoreAddFlow/:type?/:id?', // 例外小考
          name: 'ScoreAddFlow',
          component: ScoreAddFlow,
        },
        {
          path: '/All/OKR/ScorePersonalList/:type?', // 人員、新人、實習生小考
          name: 'ScorePersonalAll',
          component: ScorePersonalAll,
        },
        {
          path: '/OKR/ScorePersonalList/:type?', // 人員、新人、實習生小考
          name: 'ScorePersonalList',
          component: ScorePersonalList,
        },
        {
          path: '/OKR/ScorePersonalEdit/:type?/:year?/:month?/:deptId?/:deptLevel?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯人員、新人、實習生小考
          name: 'ScorePersonalEdit',
          component: ScorePersonalEdit,
        },
        {
          path: '/All/OKR/ScoreHistoryList/:type?', // 小考考核歷程
          name: 'ScoreHistoryAll',
          component: ScoreHistoryAll,
        },
        {
          path: '/OKR/ScoreHistoryList/:type?', // 小考考核歷程
          name: 'ScoreHistoryList',
          component: ScoreHistoryList,
        },
        {
          path: '/OKR/ScoreBalance', // 小考平衡分數
          name: 'ScoreBalance',
          component: ScoreBalance,
        },
        {
          path: '/OKR/NewJobList/:type?', // 新進員工轉正表
          name: 'NewJobList',
          component: NewJobList,
        },
        {
          path: '/OKR/NewJobEdit/:type?/:year?/:month?/:deptId?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯新進員工轉正表
          name: 'NewJobEdit',
          component: NewJobEdit,
        },
        {
          path: '/All/OKR/BigScorePersonalList/:type?', // 實習生、人員、部級、處級、中心大考
          name: 'BigScorePersonalAll',
          component: BigScorePersonalAll,
        },
        {
          path: '/OKR/BigScorePersonalList/:type?', // 實習生、人員、部級、處級、中心大考
          name: 'BigScorePersonalList',
          component: BigScorePersonalList,
        },
        {
          path: '/OKR/BigScorePersonalEdit/:type?/:year?/:month?/:deptId?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯實習生、人員、部級大考
          name: 'BigScorePersonalEdit',
          component: BigScorePersonalEdit,
        },
        {
          path: '/OKR/BigScoreCenterEdit/:type?/:year?/:month?/:deptId?/:id?/:formType?/:formId?/:createId?/:signId?/:back?', // 編輯處級、中心大考
          name: 'BigScoreCenterEdit',
          component: BigScoreCenterEdit,
        },
        {
          path: '/All/OKR/BigScoreHistoryList/:type?', // 大考考核歷程
          name: 'BigScoreHistoryAll',
          component: BigScoreHistoryAll,
        },
        {
          path: '/OKR/BigScoreHistoryList/:type?', // 大考考核歷程
          name: 'BigScoreHistoryList',
          component: BigScoreHistoryList,
        },
        {
          path: '/All/OKR/BigScoreBalance/:type?', // 總分平衡分數
          name: 'BigScoreBalanceAll',
          component: BigScoreBalanceAll,
        },
        {
          path: '/OKR/BigScoreBalance/:type?', // 總分平衡分數
          name: 'BigScoreBalance',
          component: BigScoreBalance,
        },
        {
          path: '/OKR/ScoreTotalEdit/:type?/:year?/:month?/:id?', // 編輯人員小考總表
          name: 'ScoreTotalEdit',
          component: ScoreTotalEdit,
        },
        {
          path: '/OKR/BigScoreTotalEdit/:type?/:year?/:month?/:id?', // 編輯人員、部主管大考總表
          name: 'BigScoreTotalEdit',
          component: BigScoreTotalEdit,
        },
        {
          path: '/OKR/GroupSet', // 簽核群組流程設定
          name: 'GroupSet',
          component: GroupSet,
        },
        {
          path: '/OKR/FlowEdit/:id?/:groupId?', // 流程設定
          name: 'FlowEdit',
          component: FlowEdit,
        },
        {
          path: '/OKR/TraineeAssignList', // 實習生指派設定
          name: 'TraineeAssignList',
          component: TraineeAssignList,
        },
        {
          path: '/OKR/CycleList', // 小考週期設定
          name: 'CycleList',
          component: CycleList,
        },
        {
          path: '/OKR/HrScoreBalance', // 小考總表匯出
          name: 'HrScoreBalance',
          component: HrScoreBalance,
        },
        {
          path: '/All/OKR/PersonalTimeList/:type?', // 目標、小考、大考開放時間
          name: 'PersonalTimeAll',
          component: PersonalTimeAll,
        },
        {
          path: '/OKR/PersonalTimeList/:type?', // 目標、小考、大考開放時間
          name: 'PersonalTimeList',
          component: PersonalTimeList,
        },
        {
          path: '/OKR/PersonalTimeEdit/:type?/:openType?/:year?/:month?/:deptId?/:employeeId?/:id?', // 編輯目標、小考、大考開放時間
          name: 'PersonalTimeEdit',
          component: PersonalTimeEdit,
        },
        {
          path: '/OKR/BalanceTimeList', // 總分平衡關閉作業
          name: 'BalanceTimeList',
          component: BalanceTimeList,
        },
        {
          path: '/OKR/PersonalTimeSetHistory', // 逾期開放歷史查詢
          name: 'PersonalTimeSetHistory',
          component: PersonalTimeSetHistory,
        },
        {
          path: '/OKR/OverTimeSet', // 作業時間限制設定
          name: 'OverTimeSet',
          component: OverTimeSet,
        },
        // 績效新機制
        {
          path: '/OKR/Score', 
          name: 'Score',
          component: Score,
        },
        {
          path: '/OKR/SetScorePerson', 
          name: 'SetScorePerson',
          component: SetScorePerson,
        },
        {
          path: '/OKR/ScoreList/:year?/:month?/:listType?', 
          name: 'ScoreList',
          component: ScoreList,
        },
        {
          path: '/OKR/ScoreWorking/HR', 
          name: 'HrScoreWorking',
          component: HrScoreWorking,
        },
        {
          path: '/OKR/ScoreWorking/Manager', 
          name: 'ManagerScoreWorking',
          component: ManagerScoreWorking,
        },
        {
          path: '/OKR/ScoreEdit/:id?/:year?/:month?/:formId?/:formType?/:back?/:listType?/:edit?', 
          name: 'ScoreEdit',
          component: ScoreEdit,
        },

        // 星城體驗服
        {
          path: '/XinStarsAccount', // 星城體驗服帳號維護
          name: 'XinStarsAccount',
          component: XinStarsAccount,
        },

        // 特約商店
        {
          path: '/Shop',
          name: 'Shop',
          component: Shop,
        },
        {
          path: '/ShopDetail/:id?/:status?',
          name: 'ShopDetail',
          component: ShopDetail,
        },
        {
          path: '/ShopList',
          name: 'ShopList',
          component: ShopList,
        },
        {
          path: '/ShopEdit/:id?',
          name: 'ShopEdit',
          component: ShopEdit,
        },
        {
          path: '/ShopCategory',
          name: 'ShopCategory',
          component: ShopCategory,
        },
        {
          path: '/ShopArea',
          name: 'ShopArea',
          component: ShopArea,
        },

        // 教育訓練
        {
          path: '/CourseList',
          name: 'ETCourseList',
          component: ETCourseList,
        },
        {
          path: '/CourseTypes',
          name: 'ETCourseTypes',
          component: ETCourseTypes,
        },
        {
          path: '/CourseEdit/:id?',
          name: 'ETCourseEdit',
          component: ETCourseEdit,
        },
        {
          path: '/CourseRecordAll',
          name: 'ETCourseRecordAll',
          component: ETCourseRecordAll,
        },
        {
          path: '/Chapter/:id?/:name?',
          name: 'ETChapter',
          component: ETChapter,
        },
        {
          path: '/ChapterEdit/:id?/:chapterId?/:chapterName?',
          name: 'ETChapterEdit',
          component: ETChapterEdit,
        },
        {
          path: '/CourseDetail/:id?/:name?',
          name: 'ETCourseDetail',
          component: ETCourseDetail,
        },

        {
          path: '/CourseRecord/Department',
          name: 'ETCourseRecordDept',
          component: ETCourseRecordDept,
        },
        {
          path: '/CourseRecord/Personal/:companyId?/:id?/:name?',
          name: 'ETCourseRecordPersonal',
          component: ETCourseRecordPersonal,
        },
        {
          path: '/CourseRecordEdit/:cId?/:depId?',
          name: 'ETCourseRecordEdit',
          component: ETCourseRecordEdit,
        },
        {
          path: '/CourseRecordDetail/:cid?/:depId?/:id?',
          name: 'ETCourseRecordDetail',
          component: ETCourseRecordDetail,
        },

        {
          path: '/Student/CourseList/:type?',
          name: 'ETStudentCourseList',
          component: ETStudentCourseList,
        },
        {
          path: '/Student/Course/:type?/:id?',
          name: 'ETStudentCourse',
          component: ETStudentCourse,
        },
        {
          path: '/Student/CourseDetail/:type?/:id?/:chapterId?',
          name: 'ETStudentCourseDetail',
          component: ETStudentCourseDetail,
        },
        {
          path: '/Student/Quiz/:id?/:name?',
          name: 'ETCourseQuizEdit',
          component: ETCourseQuizEdit,
        },
        {
          path: '/Student/QuizPreview/:id?/:name?',
          name: 'ETCourseQuizPreview',
          component: ETCourseQuizPreview,
        },
      ],
    },
    {
      path: '/',
      redirect: '/Home',
    },
  ],
});
