var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ActionWorkSystem" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("移動式作業人員設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("線上點名")]),
        _c("li", { staticClass: "active" }, [_vm._v("移動式作業人員設定")]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content order-management" },
      [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box box-header with-border" }, [
              _c(
                "div",
                { staticClass: "date-group" },
                [
                  _c("DatePicker", {
                    attrs: { propDisplay: true },
                    model: {
                      value: _vm.searchData.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "startDate", $$v)
                      },
                      expression: "searchData.startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "date-group" },
                [
                  _c("DatePicker", {
                    attrs: { propDisplay: true },
                    model: {
                      value: _vm.searchData.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "endDate", $$v)
                      },
                      expression: "searchData.endDate",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.employeeId,
                      expression: "searchData.employeeId",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋員工編號",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.employeeId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "employeeId",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v(" 返回線上點名\n          "),
                ]
              ),
            ]),
            _c(
              "form",
              { staticClass: "form-horizontal batchCancelOrder clearfix" },
              [
                _c("div", { staticClass: "form-group name-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("名單匯入："),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c("input", {
                      ref: "batchCancelOrder",
                      attrs: { id: "batchCancelOrder", type: "file" },
                    }),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-import mr-10 mr-0-w600",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.readExcel()
                      },
                    },
                  },
                  [_vm._v("確定匯入")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success btn-example mr-10 mr-0-w600",
                    attrs: {
                      href: "/uploads/行動作業人員範例.xlsx",
                      type: "button",
                      download: "",
                    },
                  },
                  [_vm._v("名單範例")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info btn-import mr-10 mr-0-w600",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.RollCallExportExcel()
                      },
                    },
                  },
                  [_vm._v("點名結果匯出")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info btn-import mr-10 mr-0-w600",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportExcel()
                      },
                    },
                  },
                  [_vm._v("名單匯出")]
                ),
              ]
            ),
            _c("div", { staticClass: "top-box" }, [
              _c("form", { staticClass: "form-horizontal" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addType()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" 新增人員\n            "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-block btn-danger btn-add mr-10 mr-0-w600",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onSelectDelete()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash" }),
                    _vm._v(" 批次刪除\n            "),
                  ]
                ),
              ]),
              _c("label", [_vm._v("顯示筆數 ：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.pageSize,
                      expression: "searchData.pageSize",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "pageSize",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.onSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "9999" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "10" } }, [_vm._v("10")]),
                  _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
                  _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
                  _c("option", { attrs: { value: "200" } }, [_vm._v("200")]),
                ]
              ),
              _c("p", {
                staticClass: "data-num",
                domProps: { textContent: _vm._s("資料總筆數：" + _vm.total) },
              }),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "20" },
                          attrs: { width: "1%" },
                        },
                        [
                          _c("input", {
                            attrs: { type: "checkbox", id: "selectAll" },
                            on: { click: _vm.selectAll },
                          }),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "120px" },
                          attrs: { width: "10%" },
                        },
                        [_vm._v("部門")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "120px" },
                          attrs: { width: "10%" },
                        },
                        [_vm._v("工號")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { "min-width": "120px" },
                          attrs: { width: "10%" },
                        },
                        [_vm._v("姓名")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "100px", width: "50%" },
                        },
                        [_vm._v("執行區間")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "150px", width: "20%" },
                        },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _vm.actionworks.length > 0
                      ? _vm._l(_vm.actionworks, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectDelete,
                                    expression: "selectDelete",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: item,
                                  checked: Array.isArray(_vm.selectDelete)
                                    ? _vm._i(_vm.selectDelete, item) > -1
                                    : _vm.selectDelete,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.selectDelete,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectDelete = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectDelete = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectDelete = $$c
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.deptName))]),
                            _c("td", [
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "m-0",
                                  attrs: { id: "name" + item.id },
                                },
                                [_vm._v(_vm._s(item.employeeId))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.employeeId,
                                    expression: "item.employeeId",
                                  },
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.editor != 0,
                                    expression: "item.editor!=0",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { required: "required", type: "text" },
                                domProps: { value: item.employeeId },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "employeeId",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.employeeName))]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.editor == 0,
                                        expression: "item.editor==0",
                                      },
                                    ],
                                    staticClass: "m-0",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatTime(item.startDate)) +
                                        " ~ " +
                                        _vm._s(_vm.formatTime(item.endDate))
                                    ),
                                  ]
                                ),
                                _c("DateRangePicker", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 1,
                                      expression: "item.editor==1",
                                    },
                                  ],
                                  attrs: {
                                    propEndDate: item.endDate,
                                    propStartDate: item.startDate,
                                  },
                                  on: {
                                    "update:propEndDate": function ($event) {
                                      return _vm.$set(item, "endDate", $event)
                                    },
                                    "update:prop-end-date": function ($event) {
                                      return _vm.$set(item, "endDate", $event)
                                    },
                                    "update:propStartDate": function ($event) {
                                      return _vm.$set(item, "startDate", $event)
                                    },
                                    "update:prop-start-date": function (
                                      $event
                                    ) {
                                      return _vm.$set(item, "startDate", $event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.change(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor != 0,
                                      expression: "item.editor!=0",
                                    },
                                  ],
                                  staticClass: "btn btn-success btn-save",
                                  on: {
                                    click: function ($event) {
                                      return _vm.save(item)
                                    },
                                  },
                                },
                                [_vm._v("儲存")]
                              ),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteType(item, index)
                                    },
                                  },
                                },
                                [_vm._v("刪除")]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor != 0,
                                      expression: "item.editor!=0",
                                    },
                                  ],
                                  staticClass: "btn btn-default",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel(item, index)
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(0)],
                  ],
                  2
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticClass: "box-footer text-center no-border",
              },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    total: _vm.total,
                    pageLimitCount: _vm.searchData.pageSize,
                  },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("AddActionWork", {
          ref: "editDialog",
          on: {
            refresh: function ($event) {
              return _vm.onSearch()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }