var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseRecordPersonal" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("上課紀錄(人員)")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("上課紀錄(人員)")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" 新增部門必修\n          "),
              ]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.companyId,
                      expression: "searchData.companyId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectCompany", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "companyId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                _vm._l(_vm.companyList, function (company, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: company.companyId } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(company.companyName) +
                          "\n              "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.deptArray,
                      expression: "deptArray",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectDept", required: "required" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.deptArray = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.deptList, function (dept, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      domProps: {
                        value: {
                          companyId: dept.companyId,
                          deptId: dept.deptId,
                          deptIdName: dept.deptIdName || dept.deptName,
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(dept.deptIdName || dept.deptName) +
                          "\n              "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(1)]
                      : _vm.list.length == 0
                      ? [_vm._m(2)]
                      : _vm._l(_vm.list, function (data, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.deptName)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.employeeId)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.employeeName)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(data.notFinishedChapterCount) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      詳細\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "15%" },
          },
          [_vm._v("\n                  部門\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  員工編號\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "15%" },
          },
          [_vm._v("\n                  姓名\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  未完成必修課程數\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  功能\n                ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "5" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "5" } }, [
        _vm._v("查無資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }