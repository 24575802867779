var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "add_activity_member", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("tbody", [
              _c("tr", [
                _vm._m(1),
                _c(
                  "td",
                  [
                    _c("select2Single", {
                      attrs: {
                        options: _vm.depts,
                        "data-placeholder": "請選擇部門",
                      },
                      model: {
                        value: _vm.deptId,
                        callback: function ($$v) {
                          _vm.deptId = $$v
                        },
                        expression: "deptId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _vm._m(2),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.employee,
                          expression: "employee",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.employee = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v("請選擇人員"),
                      ]),
                      _vm._l(_vm.memberOptions, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item } },
                          [_vm._v(_vm._s(item.employeeName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.Submit()
                },
              },
            },
            [_vm._v("\n          新增\n        ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("\n          取消\n        ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("新增參加人員")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "text-nowrap", staticStyle: { width: "10%" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 選擇部門\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 選擇人員"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }