<template>
  <input class="form-control" ref="datepicker" type="text" />
</template>

<script>
import moment from "moment";
// import utils from "@/assets/js/app-utils"; 沒使用到

//文件範例
//http://bsify.admui.com/daterangepicker/s

export default {
  props: {
    propStartDate: "",
    propEndDate: "",
    propTimePicker: false,
  },
  data() {
    return {
      format: "YYYY/MM/DD",
    };
  },
  mounted() {
    $(this.$refs.datepicker).daterangepicker({
      locale: {
        format: this.propTimePicker ? "YYYY/MM/DD hh:mm A" : this.format,
      },
      timePicker: this.propTimePicker,
      startDate: new moment(this.propStartDate),
      endDate: new moment(this.propEndDate),
    });
    $(this.$refs.datepicker).on("apply.daterangepicker", (evt, picker) => {
      let sdate = picker.startDate.format(this.format + " HH:mm:ss");
      let edate = picker.endDate.format(this.format + " HH:mm:ss");
      this.$emit("update:propStartDate", sdate);
      this.$emit("update:propEndDate", edate);
      this.$emit("update:date", sdate, edate);
      this.$emit("restList");
    });
  },
  watch: {},
};
</script>