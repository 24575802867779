var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "GroupSet" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("簽核群組流程設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("簽核群組流程設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content seating" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-success btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportList()
                      },
                    },
                  },
                  [_vm._v("\n              匯出\n            ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.showGroupContent()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\n              新增群組\n            "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("查詢群組："),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.deptId,
                          expression: "searchData.deptId",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "deptId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("部別")]),
                      _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.deptId } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.deptName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.keyword,
                        expression: "searchData.keyword",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: { placeholder: "請輸入群組名稱", type: "text" },
                    domProps: { value: _vm.searchData.keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "keyword",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.resetDataList()
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", {
                  staticClass: "data-num",
                  domProps: { textContent: _vm._s(`資料總筆數：${_vm.total}`) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "7" } },
                              [_vm._v("查詢中")]
                            ),
                          ])
                        : _vm.datas.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "7" } },
                              [_vm._v("查詢無任何資料")]
                            ),
                          ])
                        : _vm._l(_vm.datas, function (data, index) {
                            return _c("tr", { key: index }, [
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(data.deptName),
                                },
                              }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(data.groupName),
                                },
                              }),
                              _c(
                                "td",
                                { staticClass: "tc" },
                                _vm._l(data.groupMembers, function (member) {
                                  return _c("div", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(member.empName) +
                                        "\n                  "
                                    ),
                                  ])
                                }),
                                0
                              ),
                              _c("td", { staticClass: "tc" }, [
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      "\n                    小考： \n                    "
                                    ),
                                    _vm._l(data.flowStops, function (item) {
                                      return item.stopType == "小考"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.stopEmpName) +
                                                "\n                    "
                                            ),
                                          ])
                                        : _vm._e()
                                    }),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      "\n                    大考： \n                    "
                                    ),
                                    _vm._l(data.flowStops, function (item) {
                                      return item.stopType == "大考"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.stopEmpName) +
                                                "\n                    "
                                            ),
                                          ])
                                        : _vm._e()
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                              _vm.permission.deptIds.includes(data.deptId) ||
                              _vm.permission.isHR
                                ? _c(
                                    "td",
                                    { staticClass: "tc" },
                                    [
                                      _c("iCheckCheckBox", {
                                        attrs: {
                                          propItem: data,
                                          propChecked: data.checked,
                                        },
                                        on: { "update:checked": _vm.setScore },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("td", {
                                    staticClass: "tc",
                                    domProps: {
                                      textContent: _vm._s(
                                        data.checked ? "是" : "否"
                                      ),
                                    },
                                  }),
                              _c("td", { staticClass: "tc" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showGroupContent(data)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    成員" +
                                        _vm._s(
                                          _vm.permission.deptIds.includes(
                                            data.deptId
                                          ) || _vm.permission.isHR
                                            ? "設定"
                                            : "檢視"
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary ml10",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateFlowData(data)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    流程" +
                                        _vm._s(
                                          _vm.permission.deptIds.includes(
                                            data.deptId
                                          ) || _vm.permission.isHR
                                            ? "設定"
                                            : "檢視"
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.permission.deptIds.includes(data.deptId) ||
                              _vm.permission.isHR
                                ? _c("td", { staticClass: "tc" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteData(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    刪除\n                  "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c("td", { staticClass: "tc" }, [
                                    _vm._v("-"),
                                  ]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("GroupContent", {
        ref: "groupContent",
        on: { updateList: _vm.getDataList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc" }, [_vm._v("部別")]),
      _c("th", { staticClass: "tc mw150" }, [_vm._v("群組名稱")]),
      _c("th", { staticClass: "tc mw150" }, [_vm._v("成員")]),
      _c("th", { staticClass: "tc" }, [_vm._v("核決人員")]),
      _c("th", { staticClass: "tc" }, [_vm._v("需考核")]),
      _c("th", { staticClass: "tc" }, [_vm._v("功能")]),
      _c("th", { staticClass: "tc" }, [_vm._v("管理")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }