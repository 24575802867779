var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ActivityType" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動類別設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("社團活動")]),
        _c("li", { staticClass: "active" }, [_vm._v("維護活動分類")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.goBack()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: { click: _vm.AddActivityType },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" 新增類別\n          "),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered min-600" }, [
              _c(
                "tbody",
                [
                  _vm._m(0),
                  _vm._l(_vm.activityTypes, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.editor,
                                expression: "!item.editor",
                              },
                            ],
                            staticClass: "m-0",
                          },
                          [_vm._v(_vm._s(item.typeName))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.editor,
                              expression: "item.editor",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.typeName,
                              expression: "item.typeName",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required", type: "text" },
                          domProps: { value: item.typeName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(item, "typeName", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.editor,
                                expression: "!item.editor",
                              },
                            ],
                            staticClass: "m-0",
                          },
                          [_vm._v(_vm._s(item.orderNum))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.editor,
                              expression: "item.editor",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.orderNum,
                              expression: "item.orderNum",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required", type: "number" },
                          domProps: { value: item.orderNum },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(item, "orderNum", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.isStop,
                                expression: "item.isStop",
                              },
                            ],
                            attrs: {
                              name: "ckbx-style-8",
                              id: "check" + index,
                              type: "checkbox",
                              "false-value": 1,
                              "true-value": 0,
                            },
                            domProps: {
                              checked: Array.isArray(item.isStop)
                                ? _vm._i(item.isStop, null) > -1
                                : _vm._q(item.isStop, 0),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = item.isStop,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 0 : 1
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          item,
                                          "isStop",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          item,
                                          "isStop",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(item, "isStop", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.save(item)
                                },
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "check" + index } }),
                        ]),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.editor,
                                expression: "!item.editor",
                              },
                            ],
                            staticClass: "btn btn-primary",
                            on: {
                              click: function ($event) {
                                return _vm.change(item)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.editor,
                                expression: "item.editor",
                              },
                            ],
                            staticClass: "btn btn-success btn-save",
                            on: {
                              click: function ($event) {
                                return _vm.save(item)
                              },
                            },
                          },
                          [_vm._v("儲存")]
                        ),
                        _vm._v("\n                   \n                  "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.editor,
                                expression: "item.editor",
                              },
                            ],
                            staticClass: "btn btn-default",
                            on: { click: _vm.QueryAll },
                          },
                          [_vm._v("取消")]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap", attrs: { width: "70%" } }, [
        _vm._v("活動類別名稱"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "90px", width: "10%" },
        },
        [_vm._v("排序")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("啟用狀態")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }