<template>
  <select
    class="form-control"
    required="required"
    v-model="departmentIdx"
    @change="$emit('update:departmentIdx', departmentIdx)"
  >
    <option
      v-for="(dept, index) in departments"
      :key="index"
      :value="dept.deptId"
    >{{ dept.deptIdName }}</option>
  </select>
</template>
<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  props: {
    propDepartmentIdx: {
      default: ""
    },
    propCompanyIdx: 0
  },
  data() {
    return {
      departmentIdx: 0,
      departments: []
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      //目前這個API沒有用途
      var res = await api.organization.getDeptList({
        companyId: this.propCompanyIdx
      });
      
      this.departments = res.data.result;
      if( this.departments.length == 0 ){
        this.departmentIdx = "";
        this.$emit('update:departmentIdx', this.departmentIdx);
        return;
      }

      var focusDepId = this.departments[0].deptId;
      for(var i = 0 ; i < this.departments.length ; i++){
          if( this.propDepartmentIdx == this.departments[i].deptId){
            focusDepId = this.departments[i].deptId;
            break;
          }
      }
      this.departmentIdx = focusDepId;
      this.$emit('update:departmentIdx', this.departmentIdx);
    }
  },
  watch: {
    propCompanyIdx: function(value) {
      this.refresh();
    },
    propDepartmentIdx:function(value) {
      this.departmentIdx = value;
    }
  }
};
</script>