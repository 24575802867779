var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "groupContent", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(this.isEdit ? "編輯" : "新增") + "群組"),
          ]),
        ]),
        _c(
          "form",
          {
            staticClass: "form-horizontal",
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit()
              },
            },
          },
          [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table no-border" }, [
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(1),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.deptId,
                                expression: "data.deptId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              required: "",
                              disabled: (_vm.single && _vm.exist) || _vm.isEdit,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "deptId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.getUserList()
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.list, function (item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item.deptId } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.deptName) +
                                    "\n                      "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(2),
                      _c(
                        "td",
                        [
                          _c("select2-multiple", {
                            attrs: {
                              "data-placeholder": "請選擇人員",
                              options: _vm.users,
                              disabled: !_vm.exist && _vm.isEdit,
                            },
                            model: {
                              value: _vm.members,
                              callback: function ($$v) {
                                _vm.members = $$v
                              },
                              expression: "members",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _vm._m(3),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.groupName,
                              expression: "data.groupName",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            disabled: !_vm.exist && _vm.isEdit,
                          },
                          domProps: { value: _vm.data.groupName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "groupName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.exist || !_vm.isEdit
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("\n            儲存\n          ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("\n            關閉\n          ")]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label w90" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    部別\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    選擇成員\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    群組名稱\n                  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }