<template>
  <div id="Announcement">
    <section class="content-header">
      <h1>{{ announcementtypes.typeName }} - 公告內容</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司公告</li>
        <li>{{ announcementtypes.typeName }}</li>
        <li class="active">公告內容</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <h4 class="h4">{{ announcement.title }}</h4>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td style="width: 10%;">發布日期</td>
                  <td>{{ announcement.publishTime }}</td>
                </tr>
                <tr>
                  <td>公告文號</td>
                  <td>{{ announcement.docNo }}</td>
                </tr>
                <tr>
                  <td>公告單位</td>
                  <td>{{ announcement.issuedUnitName }}</td>
                </tr>
                <tr>
                  <td>公告聯絡人</td>
                  <td>{{ announcement.contactEmpName }}</td>
                </tr>
                <tr>
                  <td>內容</td>
                  <td>
                    <div class="text" v-html="announcementContents"></div>
                  </td>
                </tr>
                <tr>
                  <td>附件</td>
                  <td>
                    <a
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      :key="file.id"
                      :title="file.name"
                      class="file-icon col-sm-1"
                      v-for="file in files"
                    >
                      <i :class="'icon icon-'+file.mine.substring(0,3)"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <button @click="goBack()" class="btn btn-default mt-10" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import moment from "moment";

export default {
  props: {
    id: ""
  },
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      announcementtypes: [],
      announcement: [],
      files: []
    };
  },
  created() {
    this.workShowAll();
  },
  computed:{
    announcementContents(){
      if( this.announcement.contents != undefined ) return this.announcement.contents.replace(/\r\n/g,"<br />");
      return "";
    }
  },
  methods: {
    async workShowAll() {
      var task_query = api.announcement.query({
        id: this.$route.params.id
      });
      var response_query = await task_query;
      response_query.data.result.publishTime = moment(
        response_query.data.result.publishTime
      ).format("YYYY/MM/DD HH:mm:ss");

      var task_queryall = api.file.queryApplyAll({
        moduleId: this.$route.params.id,
        module: "Announcement"
      });
      var response_queryall = await task_queryall;
      this.files = response_queryall.data.result;

      this.announcement = JSON.parse(
        JSON.stringify(response_query.data.result)
      );

      //*---- 單筆公告
      var task_uni = api.announcementtype.query({
        id: response_query.data.result.typeId
      });
      var response_uni = await task_uni;
      this.announcementtypes = response_uni.data.result;
    },
    goBack() {
      if (this.$route.params.searchData != undefined) {
        this.$router.push({
          name: "AnnouncementList",
          params: { id: this.$route.params.type, searchData: this.$route.params.searchData }
        });
      } else {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push({
              name: "AnnouncementList",
              params: { id: this.announcementtypes.id }
            });
      }
    }
  }
};
</script>