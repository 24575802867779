var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RollCallSystem" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("線上點名")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("線上點名")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max550" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box box-header with-border" }, [
            _vm.hasEditPermission
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add btn-set",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.AddActionWork()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-cog" }),
                    _vm._v(" 移動式作業人員設定\n          "),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "table-responsive mt-10 tc" }, [
            _c("div", { staticClass: "dateDate" }, [
              _c("span", { staticClass: "date" }, [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "YYYY-MM-DD"))),
              ]),
              _c("span", { staticClass: "week" }, [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "dddd"))),
              ]),
            ]),
            _c("div", { staticClass: "clock", attrs: { id: "Call-Clock" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "HH").substr(0, 1))),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "HH").substr(1))),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "mm").substr(0, 1))),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "mm").substr(1))),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "ss").substr(0, 1))),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.formatTime(_vm.timenow, "ss").substr(1))),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add btn-signIn",
                attrs: { type: "button", disabled: !_vm.isActionWork },
                on: {
                  click: function ($event) {
                    return _vm.RollCall()
                  },
                },
              },
              [_vm._v("簽到")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isActionWork,
                    expression: "!isActionWork",
                  },
                ],
                staticStyle: {
                  width: "300px",
                  height: "20px",
                  margin: "40px auto",
                  "text-align": "center",
                },
              },
              [
                _c("label", { staticStyle: { color: "red" } }, [
                  _vm._v("此帳號目前非移動式作業人員"),
                ]),
              ]
            ),
            _vm.isActionWork
              ? _c(
                  "table",
                  {
                    staticClass: "table table-hover table-bordered table-call",
                  },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(0),
                        _vm.rollcallrecords.total > 0
                          ? _vm._l(_vm.rollcallrecords.datas, function (item) {
                              return _c("tr", { key: item.id }, [
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    _vm._s(_vm.formatTime(item.checkinTime))
                                  ),
                                ]),
                              ])
                            })
                          : [_vm._m(1)],
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "90px", width: "5%" },
        },
        [_vm._v("今日簽到歷程")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }