<template>
  <!--預定活動-->
  <div class="modal fade" ref="add_activity_member">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增參加人員</h4>
        </div>
        <div class="modal-body">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td class="text-nowrap" style="width: 10%;">
                  <span class="text-red">*</span> 選擇部門
                </td>
                <td>
                  <select2Single
                    :options="depts"
                    data-placeholder="請選擇部門"
                    v-model="deptId"
                  />
                </td>
              </tr>
              <tr>
                <td><span class="text-red">*</span> 選擇人員</td>
                <td>
                  <select
                    class="form-control"
                    required="required"
                    v-model="employee"
                  >
                    <option value disabled>請選擇人員</option>
                    <option
                      :value="item"
                      v-for="(item, index) in memberOptions"
                      :key="index"
                      >{{ item.employeeName }}</option
                    >
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="Submit()">
            新增
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            取消
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import select2Single from "@/components/select2Single";
import api from "@/assets/js/app-api";

export default {
  components: {
    select2Single
  },
  data() {
    return {
      activityId: "",
      deptId: "",
      employee: "",
      depts: [{ id: "", text: "請選擇部門" }],
      memberOptions: []
    };
  },
  watch: {
    deptId() {
      this.selectDept();
    }
  },
  methods: {
    showAddActivityMember(item) {
      if (item == null || item.activityId == null || item.activityId == "") {
        this.$eventBus.$emit("showConfirm", "發生異常，請重新操作!!");
        return;
      }

      this.activityId = item.activityId;
      this.getDepts();

      $(this.$refs.add_activity_member).modal("show");
    },

    async getDepts() {
      var task_deps = api.organization.getDeptList({
        companyId: this.$store.state.loginUser.companyId
      });
      var response_desps = await task_deps;
      let temp = response_desps.data.result;
      temp.forEach(dept => {
        this.depts.push({ id: dept.deptId, text: dept.deptIdName });
      });
    },

    //*---- 切換部門時替換員工資料
    async selectDept() {
      this.employee = "";
      var task_emps = api.organization.getDeptEmpList({
        deptId: this.deptId,
        leftMonth: 1
      });
      var response_emps = await task_emps;
      this.memberOptions = response_emps.data.result;
    },
    Submit() {
      if (this.employee == "" || this.employee == null) {
        this.$eventBus.$emit("showConfirm", "請選擇參加人員!!");
        return;
      }

      this.$eventBus.$emit(
        "showConfirm",
        "新增後人員無法刪除，僅能修改參加狀態，請問是否確定新增？",
        isOK => {
          if (isOK) {
            api.activity
              .addJoin({
                activityId: this.activityId,
                joinEmpId: this.employee.employeeId,
                joinEmpName: this.employee.employeeName
              })
              .then(res => {
                if (res.data.status == "1") {
                  this.$emit("refresh");
                } else {
                  this.$eventBus.$emit("showAlert", res.data.message);
                }
                this.queryAll();
              });
          }
        }
      );
    }
  }
};
</script>
