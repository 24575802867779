<template>
  <div id="Groups">
    <section class="content-header">
      <h1>群組設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li>權限管理</li>
        <li class="active">群組設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button class="btn btn-block btn-warning btn-add" type="button" data-target="#add-group" data-toggle="modal">
              <i class="fa fa-plus"></i> 新增群組
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered table-striped min-600">
              <tbody>
                <tr>
                  <th>群組名稱</th>
                  <th>群組描述</th>
                  <th class="text-nowrap text-center" style="min-width: 260px;">功能</th>
                </tr>
                <tr>
                  <td>一般使用者</td>
                  <td>一般有帳號的使用者</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>網銀員工</td>
                  <td>網銀一般員工</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>部級主管</td>
                  <td>擔任部級單位的主管</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>處級主管</td>
                  <td>擔任處級單位的主管</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>中心層級主管</td>
                  <td>擔任中心層級單位的主管</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>人資部</td>
                  <td>人資部管理使用</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>測試人員</td>
                  <td>企劃測試人員</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>SystemAdmin</td>
                  <td>系統管理者，開發人員設定使用</td>
                  <td class="text-center">
                    <button class="btn btn-info"><i class="fa fa-user-plus"></i> 成員設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-group" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination />
        </div>
      </div>
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="h4 pull-left">一般使用者 - 成員設定</h4>
            <button class="btn btn-default btn-add" type="button"><i class="fa fa-reply"></i> 返回列表</button>
          </div>
          <div class="nav-tabs-custom mt-10">
            <ul class="nav nav-tabs">
              <li class="active"><a href="#tab_1" data-toggle="tab">公司</a></li>
              <li><a href="#tab_2" data-toggle="tab">角色</a></li>
              <li><a href="#tab_3" data-toggle="tab">單位</a></li>
              <li><a href="#tab_4" data-toggle="tab">單位層級主管</a></li>
              <li><a href="#tab_5" data-toggle="tab">單位人員</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="tab_1">
                  <form class="form-horizontal">
                    <div class="form-group member-setting">
                      <label class="col-sm-2 control-label" for="selectCompany">
                        <span class="text-red">*</span> 選擇公司：
                      </label>
                      <div class="col-sm-10">
                        <select class="form-control" id="selectCompany" required="required">
                          <option>請選擇</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit"><i class="fa fa-arrow-down"></i> 新增</button>
                    </div>
                  </form>
                </div>
                <!-- /.tab-pane -->
                <div class="tab-pane" id="tab_2">
                  <form class="form-horizontal">
                    <div class="form-group member-setting">
                      <label class="col-sm-2 control-label" for="selectRole">
                        <span class="text-red">*</span> 選擇角色：
                      </label>
                      <div class="col-sm-10">
                        <select class="form-control" id="selectRole" required="required">
                          <option>請選擇</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit"><i class="fa fa-arrow-down"></i> 新增</button>
                    </div>
                  </form>
                </div>
                <!-- /.tab-pane -->
                <div class="tab-pane" id="tab_3">
                  <form class="form-horizontal">
                    <div class="form-group member-setting">
                      <label class="col-sm-2 control-label" for="selectUnit">
                        <span class="text-red">*</span> 選擇單位：
                      </label>
                      <div class="col-sm-10">
                        <select class="form-control" id="selectUnit" required="required">
                          <option>請選擇</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit"><i class="fa fa-arrow-down"></i> 新增</button>
                    </div>
                  </form>
                </div>
                <!-- /.tab-pane -->
                <div class="tab-pane" id="tab_4">
                  <form class="form-horizontal">
                    <div class="form-group member-setting">
                      <label class="col-sm-2 control-label" for="selectLevel">
                        <span class="text-red">*</span> 選擇主管層級：
                      </label>
                      <div class="col-sm-10">
                        <select class="form-control" id="selectLevel" required="required">
                          <option>請選擇</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit"><i class="fa fa-arrow-down"></i> 新增</button>
                    </div>
                  </form>
                </div>
                <!-- /.tab-pane -->
                <div class="tab-pane" id="tab_5">
                  <form class="form-horizontal">
                    <div class="form-group member-setting">
                      <label class="col-sm-2 control-label" for="selectPeople">
                        <span class="text-red">*</span> 單位人員：
                      </label>
                      <div class="col-sm-10 has-two">
                        <select class="form-control" id="selectPeople" required="required">
                          <option>請選擇</option>
                        </select>
                        <select class="form-control" id="selectPeople2" required="required">
                          <option>請選擇</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit"><i class="fa fa-arrow-down"></i> 新增</button>
                    </div>
                  </form>
                </div>
                <!-- /.tab-pane -->
            </div>
            <!-- /.tab-content -->
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered table-striped min-600">
              <tbody>
                <tr>
                  <th>成員類別</th>
                  <th>成員ID</th>
                  <th>成員名稱</th>
                  <th class="text-nowrap text-center" style="min-width: 90px;">功能</th>
                </tr>
                <tr>
                  <td>一般使用者</td>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td class="text-center">
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination />
        </div>
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import Pagination from '../components/Pagination';
import AlertBox from '../components/AlertBox';

export default {
  components: {
    Pagination,
    AlertBox,
  },
};
</script>
