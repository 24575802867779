var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "companyFileAlert", staticClass: "modal fade normal" },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h4", { staticClass: "modal-title" }, [
              _vm.action === "download"
                ? _c("span", [_vm._v("是否下載以下文件的檔案？")])
                : _c("span", [
                    _vm._v(
                      "\n            是否刪除以下文件？(共計 " +
                        _vm._s(_vm.checkDatas.length) +
                        " 筆)\n          "
                    ),
                  ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "ul",
              _vm._l(_vm.checkDatas, function (item, index) {
                return _c("li", { key: index }, [
                  _vm._v("\n            " + _vm._s(item.text) + "\n          "),
                ])
              }),
              0
            ),
          ]),
          _vm.loading
            ? _c("div", { staticClass: "modal-footer" }, [_vm._m(1)])
            : _vm.downloadStart
            ? _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success",
                    attrs: { href: _vm.downloadLink },
                    on: {
                      click: function ($event) {
                        return _vm.hideAlert()
                      },
                    },
                  },
                  [_vm._v("\n          開始下載\n        ")]
                ),
              ])
            : _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submit()
                      },
                    },
                  },
                  [_vm._v("\n          確定\n        ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default pull-right",
                    attrs: { "data-dismiss": "modal", type: "button" },
                  },
                  [_vm._v("\n          關閉\n        ")]
                ),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading" }, [
      _c("i", { staticClass: "fa fa-spinner fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }