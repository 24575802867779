<template>
  <div id="ReceptionArea">
    <section class="content-header">
      <h1>交誼廳</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>場地申請</li>
        <li class="active">交誼廳</li>
      </ol>
    </section>
    <section class="content reception-area">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group date">
              <div class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </div>
              <div class="pull-right">
                <DatePicker v-model="meetingDay" />
              </div>
              <!-- <div class="input-group-btn">
                <button class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>-->
            </div>
            <div class="text">
              <p class="data-num">目前查看：{{meetingDay}}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table order-table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th>時段</th>
                  <th class="text-center">{{GetOneWeek(0,'MM/DD dddd')}}</th>
                  <th class="text-center">{{GetOneWeek(1,'MM/DD dddd')}}</th>
                  <th class="text-center">{{GetOneWeek(2,'MM/DD dddd')}}</th>
                  <th class="text-center">{{GetOneWeek(3,'MM/DD dddd')}}</th>
                  <th class="text-center">{{GetOneWeek(4,'MM/DD dddd')}}</th>
                  <th class="text-center">{{GetOneWeek(5,'MM/DD dddd')}}</th>
                  <th class="text-center">{{GetOneWeek(6,'MM/DD dddd')}}</th>
                </tr>
                <tr>
                  <td>18:00-19:00</td>
                  <td
                    :class="{'booking': checkUserBooked(GetOneWeek((day-1),'YYYY/MM/DD'), '1800', '1900'), 'disabled':checkCanBooking(GetOneWeek((day-1),'YYYY/MM/DD'), '1800', '1900')}"
                    :key="day"
                    v-for="day in days"
                    v-html="PrintBookedInfo(GetOneWeek((day-1),'YYYY/MM/DD'), '1800', '1900')"
                  ></td>
                </tr>
                <tr>
                  <td>19:00-20:00</td>
                  <td
                    :class="{'booking': checkUserBooked(GetOneWeek((day-1),'YYYY/MM/DD'), '1900', '2000'), 'disabled':checkCanBooking(GetOneWeek((day-1),'YYYY/MM/DD'), '1900', '2000')}"
                    :key="day"
                    v-for="day in days"
                    v-html="PrintBookedInfo(GetOneWeek((day-1),'YYYY/MM/DD'), '1900', '2000')"
                  ></td>
                </tr>
                <tr>
                  <td>20:00-21:00</td>
                  <td
                    :class="{'booking': checkUserBooked(GetOneWeek((day-1),'YYYY/MM/DD'), '2000', '2100'), 'disabled':checkCanBooking(GetOneWeek((day-1),'YYYY/MM/DD'), '2000', '2100')}"
                    :key="day"
                    v-for="day in days"
                    v-html="PrintBookedInfo(GetOneWeek((day-1),'YYYY/MM/DD'), '2000', '2100')"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <p class="text-red mt-10">
            <i class="fa fa-warning"></i> 提醒您今日18:00-21:00有預約交誼廳。
          </p>-->
          <p class="text-red mt-10">
            <i class="fa fa-warning"></i> 點擊空白時段即可進行預約或取消，已過的時段無法預約。
          </p>
        </div>
      </div>
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="title">交誼廳下班時間使用規則說明</h4>
            <button
              @click="openReceptionEdit"
              class="btn btn-block btn-default btn-cog"
              type="button"
              v-show="checkByUserPermission()"
            >
              <i class="fa fa-cog"></i> 修改使用規則
            </button>
          </div>
          <div class="table-responsive mt-10">
            <div class="editor" v-html="areaRuler.roomInfo">
              <!-- 編輯器 開始 -->

              <!-- / 編輯器 結束 -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <receptionBooking ref="receptionBooking" v-on:refreshList="GetRestBookingList" />
    <receptionEdit ref="receptionEdit" v-on:refreshList="GetInfo" />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import receptionBooking from "@/views/PlaceReservation/ReceptionBooking";
import receptionEdit from "@/views/PlaceReservation/ReceptionEdit";

export default {
  components: {
    DatePicker,
    receptionBooking,
    receptionEdit
  },
  data() {
    return {
      meetingDay: "",
      employeeId: this.$store.state.loginUser.employeeId,
      days: 7,
      restBookingList: {},
      areaRuler: {}
    };
  },
  created() {
    this.GetRestBookingList();
    this.GetInfo();
  },
  methods: {
    GetInfo() {
      api.meetingRoom.queryRestInfo({}).then(res => {
        if (res.data.status == "1") {
          this.areaRuler = res.data.result;
        }
      });
    },
    GetRestBookingList() {
      api.meetingBooking.restBookingList({}).then(res => {
        if (res.data.status == "1") {
          this.restBookingList = res.data.result;
        }
      });
    },
    GetOneWeek(day, format) {
      return new moment(this.meetingDay)
        .locale("zh-tw")
        .startOf("isoWeek")
        .add(day, "Days")
        .format(format);
    },
    PrintBookedInfo(meetingDay, startTime, endTime) {
      if (
        this.restBookingList.total == undefined ||
        this.restBookingList.total == 0
      )
        return "";

      let query = this.restBookingList.datas.filter(booked => {
        return (
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(meetingDay)
        );
      });

      if (query.length > 0) {
        return (
          "預約人：" +
          query[0].createEmpName +
          " <br />主題：" +
          query[0].meetingTitle
        );
      }
      return "";
    },
    checkUserBooked(meetingDay, startTime, endTime) {
      if (
        this.restBookingList.total == undefined ||
        this.restBookingList.total == 0
      )
        return false;

      let query = this.restBookingList.datas.filter(booked => {
        return (
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(meetingDay) &&
          booked.createEmpId == this.employeeId
        );
      });

      if (query.length > 0) {
        return true;
      }
      return false;
    },
    openReceptionBooking(meetingDay, startTime, endTime) {
      let booked = this.restBookingList.datas.filter(booked => {
        return (
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(meetingDay)
        );
      });

      if (
        new moment().isAfter(
          meetingDay + " " + endTime.substring(0, 2) + ":00"
        ) ||
        (booked.length > 0 &&
          !this.checkUserBooked(meetingDay, startTime, endTime))
      ) {
        // this.$eventBus.$emit("showAlert", "此時段無法預約!!");
        return;
      }

      if (booked.length != 0) {
        startTime = booked[0].startTime;
        endTime = booked[0].endTime;
      }

      this.$refs.receptionBooking.showReceptionBooking(
        booked,
        startTime,
        endTime,
        meetingDay,
        this.areaRuler.roomId
      );
    },
    openReceptionEdit() {
      this.$refs.receptionEdit.showReceptionEdit(this.areaRuler.roomId);
    },
    checkByUserPermission() {
      return utils.checkByUserPermission("B0-900");
    },
    checkCanBooking(meetingDay, startTime, endTime) {
      // if (
      //   this.restBookingList.total == undefined ||
      //   this.restBookingList.total == 0
      // )
      //   return false;

      // let booked = this.restBookingList.datas.filter(booked => {
      //   return (
      //     ((booked.startTime >= startTime && booked.startTime < endTime) ||
      //       (booked.endTime > startTime && booked.endTime <= endTime) ||
      //       (booked.startTime <= startTime && booked.endTime >= endTime)) &&
      //     new moment(booked.meetingDay).isSame(meetingDay)
      //   );
      // });
      return true
      // return new moment().isAfter(
      //   meetingDay + " " + endTime.substring(0, 2) + ":00"
      // );
    }
  }
};
</script>
