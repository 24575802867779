<template>
  <div id="AnnouncementType">
    <section class="content-header">
      <h1>公告類別設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司公告</li>
        <li class="active">公告類別設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addType()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增類別
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered" id="announcementTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;" width="50%">類別名稱</th>
                  <th class="text-center">排序</th>
                  <th class="text-nowrap" width="10%">公告數</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%;"
                  >
                    啟用狀態
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 20%;"
                  >
                    功能
                  </th>
                </tr>
                <tr :key="index" v-for="(item, index) in announcementtypes">
                  <td>
                    <p
                      :id="'name' + item.id"
                      class="m-0"
                      v-show="item.editor == 0"
                    >
                      {{ item.typeName }}
                    </p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.typeName"
                      v-show="item.editor != 0"
                    />
                  </td>
                  <td class="text-center">
                    <p
                      :id="'name' + item.id"
                      class="m-0"
                      v-show="item.editor == 0"
                    >
                      {{ item.orderNum }}
                    </p>
                    <input
                      class="form-control text-center"
                      required="required"
                      type="text"
                      v-model="item.orderNum"
                      v-show="item.editor != 0"
                    />
                  </td>
                  <td>{{ item.announcementCount }}</td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'check' + item.id"
                        :true-value="1"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.status"
                      />
                      <label :for="'check' + item.id"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="item.editor == 0"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor != 0"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-show="item.editor == 0"
                    >
                      刪除
                    </button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor != 0"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../assets/js/app-api";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      announcementtypes: [],
      announcementTemp: []
    };
  },
  mounted() {
    this.workShowAll();
  },
  methods: {
    // 顯示列表
    async workShowAll() {
      var task1 = api.announcementtype.queryAllTypeEdit({
        pageNum: 0,
        pageSize: 20
      });
      var response = await task1;
      var tmp = response.data.result.datas;
      for (var i = 0; i < tmp.length; i++) {
        tmp[i]["editor"] = 0;
      }
      this.announcementTemp = JSON.parse(JSON.stringify(tmp));
      this.announcementtypes = tmp;
      // console.log(response.data.result);
    },

    // 儲存更新
    workUpdate(item) {
      api.announcementtype
        .update(item)
        .then(res => {
          // console.log('workUpdate', res);
          if (res.data.status == "1") {
            item.editor = 0;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message, "注意");
          }

          this.workShowAll();
        })
        .catch(err => {
          this.$eventBus.$emit("showAlert", err.data.message, "注意");
          // console.log('workUpdate', err);
        });
    },

    // 切換文字或輸入框
    change(item) {
      //this.$data.typeName = this.$options.data().typeName;
      item.editor = item.editor == 1 ? 0 : 1;
      // console.log(item.typeName, 'change editor:' + item.editor);
    },

    // 儲存
    save(item) {
      if (item.orderNum == null) {
        this.$eventBus.$emit("showAlert", "請填寫排序", "注意");
        return;
      }

      item.orderNum = parseInt(item.orderNum);

      var that = this;
      if (item.id == 0) {
        api.announcementtype
          .create(item)
          .then(res => {
            if (item.typeName == "") {
              this.$eventBus.$emit("showAlert", res.data.message, "注意");
            } else {
              item.editor = item.editor == 1 ? 0 : 1;
              that.workShowAll();
            }
          })
          .catch(err => {
            this.$eventBus.$emit("showAlert", err.data.message, "注意");
            // console.log('save', err);
          });
      } else {
        //item.editor = 0
        this.workUpdate(item);
      }
      // console.log(
      //   'id:' + item.id,
      //   'status:' + item.status,
      //   'editor:' + item.editor
      // );
    },

    // 新增類別
    addType() {
      this.announcementtypes.push({
        id: "",
        typeName: "",
        status: "1",
        editor: "1",
        orderNum: 0
      });
    },

    cancel(item, index) {
      var that = this;
      if (item.id == 0) {
        // console.log('splice');
        that.announcementtypes.splice(index, 1);
      } else {
        var temp = JSON.parse(JSON.stringify(that.announcementTemp[index]));
        that.announcementtypes.splice(index, 1, temp);
        // console.log('cancel');
      }
    },

    // 改變狀態
    changeStatus(item) {
      this.workUpdate(item);
      // console.log(item.typeName, 'status:' + item.status);
    },

    // 刪除
    deleteType(item) {
      var that = this;
      this.$eventBus.$emit(
        "showConfirm",
        "刪除後將無法復原，請確認是否刪除？",
        "注意"
      );
      $("#attention-delete").click(function() {
        api.announcementtype
          .delete(item)
          .then(res => {
            $("#attention").modal("hide");
            that.workShowAll();
            // console.log(item.typeName, 'Delete success!');
          })
          .catch(err => {
            this.$eventBus.$emit("showAlert", err.data.message, "注意");
            $("#attention-delete").hide();
            // console.log(item.typeName, 'Delete error!');
          });
      });
    }
  }
};
</script>
