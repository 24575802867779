var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasEditPermission
        ? _c(
            "div",
            { attrs: { id: "home" } },
            [
              _c("section", { staticClass: "content home" }, [
                _vm.$user.hasPermission("E0")
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-lg-6" }, [
                        _c("div", { staticClass: "box box-warning" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "box-body" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-hover",
                                },
                                [
                                  _c(
                                    "tbody",
                                    [
                                      _vm._m(1),
                                      _vm._l(
                                        _vm.eatDays,
                                        function (day, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: index,
                                              class: {
                                                "bg-blue-1":
                                                  day === _vm.today.getDay(),
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "text-nowrap" },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.getDate(day - 1)
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-nowrap" },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.formatDateTime(
                                                          _vm.getDate(day - 1),
                                                          "dddd"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _vm.getDateOrderLunch(
                                                _vm.getDate(day - 1)
                                              ).length != 0
                                                ? _c("td", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.getDateOrderLunch(
                                                            _vm.getDate(day - 1)
                                                          )[0].eatStatus == "0"
                                                            ? "不訂餐"
                                                            : _vm.getDateOrderLunch(
                                                                _vm.getDate(
                                                                  day - 1
                                                                )
                                                              )[0].storeName
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ])
                                                : _vm.checkDailyMeal(
                                                    _vm.getDate(day - 1)
                                                  )
                                                ? _c("td", [
                                                    _vm._v(
                                                      "\n                        未訂餐\n                      "
                                                    ),
                                                  ])
                                                : _c("td", [_vm._v("休假")]),
                                              _vm.getDateOrderLunch(
                                                _vm.getDate(day - 1)
                                              ).length != 0
                                                ? _c("td", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.getDateOrderLunch(
                                                            _vm.getDate(day - 1)
                                                          )[0].eatStatus == "0"
                                                            ? "不訂餐"
                                                            : _vm.getDateOrderLunch(
                                                                _vm.getDate(
                                                                  day - 1
                                                                )
                                                              )[0].mealName
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ])
                                                : _vm.checkDailyMeal(
                                                    _vm.getDate(day - 1)
                                                  )
                                                ? _c("td", [
                                                    _vm._v(
                                                      "\n                        未訂餐\n                      "
                                                    ),
                                                  ])
                                                : _c("td", [_vm._v("休假")]),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "tr",
                                        { staticClass: "bg-gray-light" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "text-nowrap" },
                                            [_vm._v(_vm._s(_vm.getToday()))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-nowrap" },
                                            [_vm._v("今晚")]
                                          ),
                                          _vm.orderDinnerRecord.length > 0
                                            ? _c("td", [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.orderDinnerRecord[0]
                                                        .storeName
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ])
                                            : _c("td", [_vm._v("-")]),
                                          _vm.orderDinnerRecord.length > 0
                                            ? _c("td", [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.orderDinnerRecord[0]
                                                        .mealName
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ])
                                            : _c("td", [_vm._v("-")]),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticClass: "text-red mt-10" }, [
                              _c("i", { staticClass: "fa fa-warning" }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.tip) +
                                  " 開放訂餐截止時間：" +
                                  _vm._s(_vm.orderDeadline) +
                                  "。\n              "
                              ),
                            ]),
                            _c("table", { staticClass: "table border-0" }, [
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-block btn-warning",
                                          attrs: {
                                            to: `/All/OrderNextWeekMeal/${_vm.expatId}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        預訂" +
                                              _vm._s(
                                                _vm.expatId === "J"
                                                  ? ""
                                                  : "下週"
                                              ) +
                                              "午餐\n                      "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.expatId !== "J"
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "btn btn-block btn-default",
                                              attrs: { to: "/OrderDinner" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        預訂當天晚餐\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-lg-6" }, [
                        _c("div", { staticClass: "box box-warning" }, [
                          _vm._m(2),
                          _c("div", { staticClass: "box-body" }, [
                            _c(
                              "div",
                              { staticClass: "home-links" },
                              [
                                _vm.expatId !== "J"
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-purple",
                                        attrs: { to: "/Seating" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-street-view",
                                        }),
                                        _c("br"),
                                        _vm._v("座位表\n                "),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.expatId !== "J"
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-orange",
                                        attrs: { to: "/Extension" },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-fax" }),
                                        _c("br"),
                                        _vm._v("分機表\n                "),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._m(3),
                                _vm._m(4),
                                _vm._m(5),
                                _vm._m(6),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.$user.hasPermission("AT")
                  ? _c("div", { staticClass: "box box-warning" }, [
                      _c("div", { staticClass: "box-body" }, [
                        _c(
                          "div",
                          { staticClass: "top-box with-border" },
                          [
                            _c("h4", { staticClass: "title" }, [
                              _vm._v("活動行事曆"),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-primary pull-right",
                                attrs: { to: "/ActivityCalendar" },
                              },
                              [_vm._v("瀏覽更多")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "activity-calendar" }, [
                          _c(
                            "div",
                            {
                              staticClass: "btns pre-next mt-10 bg-gray-light",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getWeekActivitys(-1)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-chevron-circle-left",
                                  }),
                                  _vm._v(" 上一週\n              "),
                                ]
                              ),
                              _c("h3", { staticClass: "date" }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getWeekActivitys(1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                下一週 \n                "
                                  ),
                                  _c("i", {
                                    staticClass: "fa fa-chevron-circle-right",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table order-table table-bordered",
                              },
                              [
                                _c("tbody", [
                                  _vm._m(7),
                                  _c(
                                    "tr",
                                    _vm._l(
                                      _vm.activitysWeek,
                                      function (item, index) {
                                        return _c(
                                          "td",
                                          { key: index, class: item.class },
                                          [
                                            _c("div", { staticClass: "date" }, [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.date,
                                                      "YYYY-MM-DD"
                                                    )
                                                  ) +
                                                  " \n                        "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "week" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDateTime(
                                                        item.date,
                                                        "dddd"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("div", { staticClass: "day" }, [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.date,
                                                      "YYYY-MM-DD"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]),
                                            _vm._l(
                                              item.datas,
                                              function (data, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    class:
                                                      data.joinStatus >= 0
                                                        ? "event btn btn-default is-join"
                                                        : "event btn btn-default",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openActivityBooking(
                                                          data
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              data.typeName
                                                            ) +
                                                            "-" +
                                                            _vm._s(
                                                              data.kindName
                                                            ) +
                                                            "-" +
                                                            _vm._s(
                                                              data.activityName
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "time" },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.formatDateTime(
                                                                data.activitySTime,
                                                                "HH:mm"
                                                              )
                                                            ) +
                                                            "~" +
                                                            _vm._s(
                                                              _vm.formatDateTime(
                                                                data.activityETime,
                                                                "HH:mm"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "note" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(data.location)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._m(8),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "shop-area" }, [
                  _vm.$user.hasPermission("SP")
                    ? _c(
                        "div",
                        { staticClass: "box box-warning _small mr-20" },
                        [
                          _vm._m(9),
                          _c("div", { staticClass: "box-body" }, [
                            _c(
                              "div",
                              { staticClass: "shop-list" },
                              _vm._l(_vm.shopList.hot, function (shop, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "shop-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goShop(shop)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "photo" }, [
                                      _c("img", {
                                        staticClass: "logo",
                                        attrs: {
                                          src: _vm.getImage(shop.files),
                                          alt: "",
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(shop.storeName)),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.$user.hasPermission("SP")
                    ? _c("div", { staticClass: "box box-warning _small" }, [
                        _vm._m(10),
                        _c("div", { staticClass: "box-body" }, [
                          _c(
                            "div",
                            { staticClass: "shop-list" },
                            _vm._l(_vm.shopList.new, function (shop, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "shop-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goShop(shop)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "photo" }, [
                                    _c("img", {
                                      staticClass: "logo",
                                      attrs: {
                                        src: _vm.getImage(shop.files),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(shop.storeName)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "box box-warning" }, [
                  _vm._m(11),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [
                      _c("div", { staticClass: "nav-tabs-custom" }, [
                        _c(
                          "ul",
                          { staticClass: "nav nav-tabs" },
                          _vm._l(_vm.announcementTypes, function (type, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: {
                                  active: type.id == _vm.announcementTypeId,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      "aria-expanded":
                                        type.id == _vm.announcementTypeId,
                                      href: "#announcement_" + index,
                                      "data-toggle": "tab",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getAnnouncements(type.id)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(type.typeName))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "tab-content" }, [
                          _c("div", { staticClass: "tab-pane active" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-hover table-bordered min-600",
                                },
                                [
                                  _c(
                                    "tbody",
                                    [
                                      _vm._m(12),
                                      _vm._l(
                                        _vm.announcements,
                                        function (item, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.publishTime,
                                                      "YYYY-MM-DD"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.docNo)),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(item.issuedUnitName)
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(item.contactEmpName)
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/Announcement/" +
                                                        item.id,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._l(
                                                  item.files,
                                                  function (file) {
                                                    return _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              item.files
                                                                .length > 0,
                                                            expression:
                                                              "item.files.length > 0",
                                                          },
                                                        ],
                                                        key: file.id,
                                                        staticClass:
                                                          "file-icon",
                                                        attrs: {
                                                          href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                                                          title: file.name,
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          class:
                                                            "icon icon-" +
                                                            file.mine.substring(
                                                              0,
                                                              3
                                                            ),
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.files.length ==
                                                          0,
                                                        expression:
                                                          "item.files.length == 0",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            to: "/AnnouncementList/" + _vm.announcementTypeId,
                          },
                        },
                        [_vm._v("瀏覽更多")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "box box-warning" }, [
                  _vm._m(13),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [
                      _c("div", { staticClass: "nav-tabs-custom" }, [
                        _c(
                          "ul",
                          { staticClass: "nav nav-tabs" },
                          _vm._l(_vm.fileTypes, function (type, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: { active: type.tId == _vm.fileTypeId },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      "aria-expanded":
                                        type.tId == _vm.fileTypeId,
                                      href: "#companyFiles_" + index,
                                      "data-toggle": "tab",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getCompanyFiles(type.tId)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(type.typeName))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "tab-content" }, [
                          _c("div", { staticClass: "tab-pane active" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-hover table-bordered min-600",
                                },
                                [
                                  _c(
                                    "tbody",
                                    [
                                      _vm._m(14),
                                      _vm._l(_vm.files, function (file, index) {
                                        return _c("tr", { key: index }, [
                                          _c("td", [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.formatDateTime(
                                                    file.createTime,
                                                    "YYYY-MM-DD"
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(file.typeName)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(file.issuedUnitName)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(file.documentName)),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._l(
                                                file.files,
                                                function (file) {
                                                  return _c(
                                                    "a",
                                                    {
                                                      key: file.id,
                                                      staticClass: "file-icon",
                                                      attrs: {
                                                        href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                                                        title: file.name,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        class:
                                                          "icon icon-" +
                                                          file.mine.substring(
                                                            0,
                                                            3
                                                          ),
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        file.files.length == 0,
                                                      expression:
                                                        "file.files.length == 0",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("-")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ])
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { to: "/CompanyFiles" },
                        },
                        [_vm._v("瀏覽更多")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "box box-warning" }, [
                  _vm._m(15),
                  _c("div", { staticClass: "box-body" }, [
                    _c("div", { staticClass: "tab-content" }, [
                      _c("div", { staticClass: "tab-pane active" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-hover table-bordered min-600",
                            },
                            [
                              _c(
                                "tbody",
                                [
                                  _vm._m(16),
                                  _vm.courseList.length == 0
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            attrs: { colspan: "3" },
                                          },
                                          [_vm._v("您已完成所有必修課程")]
                                        ),
                                      ])
                                    : _vm._l(
                                        _vm.courseList,
                                        function (course, index) {
                                          return _c("tr", { key: index }, [
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  _vm._s(course.courseType)
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _vm._v(_vm._s(course.courseName)),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "btn btn-warning",
                                                    attrs: {
                                                      to: {
                                                        name: "ETStudentCourse",
                                                        params: {
                                                          type: "Required",
                                                          id: course.courseId,
                                                          name: course.courseName,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          詳細\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("activityBooking", {
                ref: "activityBooking",
                on: {
                  refresh: function ($event) {
                    return _vm.getWeekActivitys(0)
                  },
                },
              }),
            ],
            1
          )
        : _c("div", [_vm._m(17)]),
      _c("midify", { ref: "midify", attrs: { proplogin: false } }),
      _c(
        "div",
        {
          staticClass: "modal fade",
          staticStyle: { "z-index": "999" },
          attrs: { id: "attention-mail" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(18),
              _vm._m(19),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.midify()
                      },
                    },
                  },
                  [_vm._v("前往設定信箱")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default pull-right",
                    attrs: {
                      id: "attention-dismiss",
                      "data-dismiss": "modal",
                      type: "button",
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header with-border" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("本週訂餐資訊")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("日期")]),
      _c("th", [_vm._v("星期")]),
      _c("th", [_vm._v("店家名稱")]),
      _c("th", [_vm._v("餐點名稱")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header with-border" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("常用連結")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-warning",
        attrs: {
          href: "https://outlook.office.com/mail/",
          target: "_blank",
          to: "",
        },
      },
      [
        _c("i", { staticClass: "fa fa-envelope-o" }),
        _c("br"),
        _vm._v("公司郵件\n                "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-success",
        attrs: {
          href: "https://efnet.wanin.tw/EFNET/",
          target: "_blank",
          to: "",
        },
      },
      [
        _c("i", { staticClass: "fa fa-calendar-check-o" }),
        _c("br"),
        _vm._v("電子簽核\n                "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-info",
        attrs: { href: "https://www.wanin.tw/", target: "_blank" },
      },
      [
        _c("i", { staticClass: "fa fa-bank" }),
        _c("br"),
        _vm._v("網銀官網\n                "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-maroon",
        attrs: { href: "http://epaper.wanin.tw/", target: "_blank" },
      },
      [
        _c("i", { staticClass: "fa fa-newspaper-o" }),
        _c("br"),
        _vm._v("網銀人季刊\n                "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("星期一")]),
      _c("th", [_vm._v("星期二")]),
      _c("th", [_vm._v("星期三")]),
      _c("th", [_vm._v("星期四")]),
      _c("th", [_vm._v("星期五")]),
      _c("th", [_vm._v("星期六")]),
      _c("th", [_vm._v("星期日")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red mt-10" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(
        "\n              點擊活動區塊即可查看明細、進行報名參加，已過的時段無法參加。\n            "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("熱門特約")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("最新特約")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("公司公告")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("發布日期"),
      ]),
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("公告文號")]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("公告單位"),
      ]),
      _c("th", [_vm._v("公告聯絡人")]),
      _c("th", [_vm._v("公告主旨")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("詳閱檔案")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("公司文件")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("上傳日期"),
      ]),
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("文件分類")]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("歸屬單位"),
      ]),
      _c("th", [_vm._v("文件名稱")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("附件")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-header" }, [
      _c("h3", { staticClass: "box-title" }, [_vm._v("未完成之必修課程")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-center" }, [_vm._v("類別")]),
      _c("th", [_vm._v("課程名稱")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content home" }, [
      _c("h1", { staticStyle: { color: "#3c8dbc" }, attrs: { id: "test" } }, [
        _vm._v("⇦請點選左側列表進行操作"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title text-red" }, [
        _c("i", { staticClass: "fa fa-warning" }),
        _vm._v(" 注意\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("尚未設定信箱，請盡快設定")]),
      _c("p", [_vm._v("( 當忘記密碼時將會傳送新密碼至您所設定信箱 )")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }