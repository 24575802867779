var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "BigScoreCenterEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("年度回顧暨未來展望計畫書")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: { to: `/OKR/BigScorePersonalList/${_vm.person.type}` },
                },
                [_vm._v("\n          年度回顧暨未來展望計畫書\n        ")]
              ),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(
              "\n        " +
                _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
                "考核\n      "
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
                    "考核\n          "
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v("\n            返回列表\n          "),
                ]
              ),
              _vm.scoreDatas[0] &&
              _vm.scoreDatas[0].status === 1 &&
              _vm.data.createId === _vm.userData.user.employeeId
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn-cog",
                      staticStyle: { width: "auto" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.signCancel()
                        },
                      },
                    },
                    [_vm._v("\n            抽單\n          ")]
                  )
                : _vm._e(),
              !_vm.hasEdit
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-cog",
                      staticStyle: { width: "auto" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.printScoreData()
                        },
                      },
                    },
                    [_vm._v("\n            列印\n          ")]
                  )
                : _vm._e(),
            ]),
            _vm.data.message
              ? _c("div", { staticClass: "tc" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.data.message) + "\n        "
                  ),
                ])
              : _c("div", { attrs: { id: "export" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "table-responsive",
                      staticStyle: { "max-width": "1400px", margin: "auto" },
                    },
                    [
                      _c("table", { staticClass: "table no-border" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c(
                                "table",
                                {
                                  staticClass: "table no-border",
                                  staticStyle: { width: "400px" },
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", { staticClass: "tr w90" }, [
                                        _vm._v("員工編號："),
                                      ]),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.data.employeeId
                                          ),
                                        },
                                      }),
                                      _c("th", { staticClass: "tr" }, [
                                        _vm._v("單位："),
                                      ]),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.data.deptName
                                          ),
                                        },
                                      }),
                                    ]),
                                    _c("tr", [
                                      _c("th", { staticClass: "tr" }, [
                                        _vm._v("員工姓名："),
                                      ]),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.data.employeeName
                                          ),
                                        },
                                      }),
                                      _c("th", { staticClass: "tr" }, [
                                        _vm._v("年度："),
                                      ]),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            `${_vm.addData.year}年(${
                                              _vm.define.halfYearStatus[
                                                _vm.addData.month
                                              ]
                                            })`
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "table-responsive",
                      staticStyle: { "max-width": "1400px", margin: "auto" },
                    },
                    [
                      _c("hr"),
                      _vm.hasChange && !_vm.hasEdit
                        ? _c("div", { staticClass: "btns" }, [
                            _vm.data.createId === _vm.userData.user.employeeId
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-warning",
                                    staticStyle: {
                                      float: "right",
                                      margin: "10px 0px",
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.hasEdit = true
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-edit" }),
                                    _vm._v(
                                      "\n                編輯檔案\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.hasChange && _vm.hasEdit
                        ? _c("div", { staticClass: "btns" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning",
                                staticStyle: {
                                  float: "right",
                                  margin: "10px 0px",
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.hasEdit = false
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-edit" }),
                                _vm._v(
                                  "\n                取消編輯檔案\n              "
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                staticStyle: {
                                  float: "right",
                                  margin: "10px 10px",
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateScoreData(_vm.scoreData)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-edit" }),
                                _vm._v(
                                  "\n                儲存\n              "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "table",
                        {
                          staticClass: "table no-border min-800",
                          staticStyle: { margin: "5px auto" },
                        },
                        [
                          _c("tbody", [
                            _vm._m(0),
                            _c("tr", [
                              _vm.hasEdit &&
                              _vm.userData.user.employeeId ===
                                _vm.scoreData.createEmpId
                                ? _c(
                                    "td",
                                    { staticClass: "tc" },
                                    [
                                      _c("tinymce", {
                                        model: {
                                          value: _vm.scoreData.note,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.scoreData,
                                              "note",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scoreData.note",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("td", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.scoreData.note),
                                    },
                                  }),
                            ]),
                            _vm._m(1),
                            _vm._m(2),
                            _c("tr", [
                              _c("td", [
                                _vm.hasEdit &&
                                _vm.userData.user.employeeId ===
                                  _vm.scoreData.createEmpId
                                  ? _c(
                                      "label",
                                      { staticClass: "btn btn-success" },
                                      [
                                        _c("input", {
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            id: "inputFile",
                                            type: "file",
                                            accept: "*",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeFile(
                                                $event,
                                                _vm.scoreData
                                              )
                                            },
                                          },
                                        }),
                                        _c("i", {
                                          staticClass: "fa fa-upload",
                                        }),
                                        _vm._v(
                                          " 上傳附件\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "table",
                                  { staticClass: "table no-border" },
                                  [
                                    _c(
                                      "tbody",
                                      [
                                        (!_vm.hasEdit ||
                                          _vm.userData.user.employeeId !==
                                            _vm.scoreData.createEmpId) &&
                                        _vm.scoreData.files &&
                                        _vm.scoreData.files.length === 0
                                          ? _c("tr", [_c("td", [_vm._v("－")])])
                                          : _vm._l(
                                              _vm.scoreData.files,
                                              function (file, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _vm.hasEdit &&
                                                    _vm.userData.user
                                                      .employeeId ===
                                                      _vm.scoreData.createEmpId
                                                      ? _c(
                                                          "td",
                                                          {
                                                            staticClass: "w90",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-danger",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteFile(
                                                                        file.applyId,
                                                                        _vm.scoreData
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              刪除\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c("td", [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                file.name
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "table-responsive",
                          staticStyle: {
                            "max-width": "1400px",
                            margin: "auto",
                          },
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "table table-bordered min-800",
                              staticStyle: { margin: "5px auto" },
                            },
                            [
                              _c(
                                "tbody",
                                [
                                  _vm._m(3),
                                  _vm.loading
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tc",
                                            attrs: { colspan: "6" },
                                          },
                                          [_vm._v("查詢中")]
                                        ),
                                      ])
                                    : _vm.scoreDatas.length === 0
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tc",
                                            attrs: { colspan: "6" },
                                          },
                                          [_vm._v("尚無評分人員")]
                                        ),
                                      ])
                                    : _vm._l(
                                        _vm.scoreDatas,
                                        function (scoreData, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", {
                                              staticClass: "tc",
                                              domProps: {
                                                textContent: _vm._s(
                                                  scoreData.createEmpName
                                                ),
                                              },
                                            }),
                                            (_vm.scoreDatas[0] &&
                                              _vm.scoreDatas[0].status === 0 &&
                                              _vm.userData.user.employeeId ===
                                                _vm.id &&
                                              _vm.userData.user.employeeId ===
                                                scoreData.createEmpId) ||
                                            (_vm.data.status != 2 &&
                                              _vm.userData.user.employeeId !==
                                                _vm.id &&
                                              _vm.userData.user.employeeId ===
                                                scoreData.createEmpId)
                                              ? _c(
                                                  "td",
                                                  { staticClass: "tc" },
                                                  [
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.trim",
                                                          value:
                                                            scoreData.privateNote,
                                                          expression:
                                                            "scoreData.privateNote",
                                                          modifiers: {
                                                            trim: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        rows: "4",
                                                        type: "text",
                                                      },
                                                      domProps: {
                                                        value:
                                                          scoreData.privateNote,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            scoreData,
                                                            "privateNote",
                                                            $event.target.value.trim()
                                                          )
                                                        },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c("td", {
                                                  staticClass: "pre-wrap",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      scoreData.privateNote
                                                    ),
                                                  },
                                                }),
                                            (_vm.scoreDatas[0] &&
                                              _vm.scoreDatas[0].status === 0 &&
                                              _vm.userData.user.employeeId ===
                                                _vm.id &&
                                              _vm.userData.user.employeeId ===
                                                scoreData.createEmpId) ||
                                            (_vm.data.status != 2 &&
                                              _vm.userData.user.employeeId !==
                                                _vm.id &&
                                              _vm.userData.user.employeeId ===
                                                scoreData.createEmpId)
                                              ? _c(
                                                  "td",
                                                  { staticClass: "tc" },
                                                  [
                                                    _vm.userData.user
                                                      .employeeId ===
                                                    scoreData.createEmpId
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateScoreData(
                                                                  scoreData
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        儲存\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      : _c("span", [
                                                          _vm._v("－"),
                                                        ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.person.level !== 9
                        ? _c(
                            "div",
                            [
                              _c("SignLog", {
                                attrs: {
                                  datas: _vm.datas,
                                  data: _vm.data,
                                  hasEdit:
                                    _vm.scoreDatas[0].status === 0
                                      ? true
                                      : false,
                                  backList: _vm.backList,
                                  scoreDatas: _vm.scoreDatas,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
          ]),
        ]),
      ]),
      _c("SignCancel", {
        ref: "signCancel",
        attrs: { backList: _vm.backList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("考核說明")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_c("hr", { staticStyle: { margin: "20px auto" } })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [_c("th", [_vm._v("附件")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc mw150", staticStyle: { width: "180px" } }, [
        _vm._v("評分人員"),
      ]),
      _c("th", { staticClass: "tc" }, [_vm._v("給主管說明")]),
      _c("th", { staticClass: "tc", staticStyle: { width: "100px" } }, [
        _vm._v("管理"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }