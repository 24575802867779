<template>
  <div id="AttendanceRecord">
    <section class="content-header">
      <h1>出勤記錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>人資專區</li>
        <li class="active">出勤記錄</li>
      </ol>
    </section>

    <section class="content order-management">
      <div class="box box-warning max1000">
        <div class="box-body">
          <div class="top-box box-header with-border">
            <div class="date-group">
              <DatePicker v-model="searchData.startDate" :propDisplay="true" />
            </div>
            <div class="date-group">
              <DatePicker v-model="searchData.endDate" :propDisplay="true" />
            </div>

            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.companyId"
                v-on:change="selectCompany()"
                v-if="hasSearchOtherCompany"
              >
                <option value>所有公司</option>
                <option
                  :key="index"
                  :value="company.companyId"
                  v-for="(company, index) in companyOptions"
                >{{company.companyName}}</option>
              </select>
            </div>

            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.deptId"
                @change="onSearch()"
              >
                <option value>全部部門</option>
                <option
                  :key="index"
                  :value="dept.deptId"
                  v-for="(dept, index) in depts"
                >{{ dept.deptIdName }}</option>
              </select>
            </div>

            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋員工編號"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="onSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <form class="form-horizontal batchCancelOrder clearfix">
            <button
              @click="exportExcel()"
              class="btn btn-info btn-import mr-10 mr-0-w600"
              type="button"
            >匯出Excel</button>
          </form>

          <div class="top-box">
            <label>顯示筆數 ：</label>
            <select v-model="searchData.pageSize" @change="onSearch">
              <option value="9999">全部</option>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
            <p class="data-num" v-text="'資料總筆數：'+record.total"></p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 100px;" width="10%">部門</th>
                  <th style="min-width: 100px;" class="text-nowrap" width="10%">工號</th>
                  <th style="min-width: 100px;" class="text-nowrap" width="10%">姓名</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">日期</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">時間</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">打卡地點</th>
                </tr>
                <template v-if="record.total > 0">
                  <tr :key="index" v-for="(item, index) in record.datas">
                    <td>{{ item.deptName }}</td>
                    <td>{{ item.employeeId }}</td>
                    <td>{{ item.employeeName }}</td>
                    <td>{{ item.showDay }}</td>
                    <td>{{ item.showTime }}</td>
                    <td>{{ item.punchLocation }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="7">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div class="box-footer text-center no-border" v-show="record.total > 0">
            <Pagination
              ref="pagination"
              :total="record.total"
              :pageLimitCount="searchData.pageSize"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import config from "@/assets/js/app-config";

export default {
  components: {
    DateRangePicker,
    Pagination,
    DatePicker
  },
  delimiters: ["${", "}"],
  data() {
    return {
      hasSearchOtherCompany: false,
      record: [],
      depts: [],
      companyOptions: [],
      searchData: {
        companyId: this.$store.state.loginUser.companyId,
        deptId: "",
        keyword: "",
        pageSize: 10,
        pageNum: 1,
        startDate: new moment().format("YYYY-MM-DD"),
        endDate: new moment().format("YYYY-MM-DD")
      }
    };
  },
  created() {
    this.workShowAll();
    this.hasSearchOtherCompany = this.$user.hasPermission(
      config.Permission.AllowCheckOtherCompany
    );
  },
  mounted() {},
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.workShowAll();
    },
    onSearch() {
      this.searchData.pageNum = 1;
      this.workShowAll();
    },
    // 顯示列表
    async workShowAll() {
      this.searchData.startDate = utils.formatTime(
        this.searchData.startDate,
        "YYYY-MM-DD 00:00:00"
      );
      this.searchData.endDate = utils.formatTime(
        this.searchData.endDate,
        "YYYY-MM-DD 23:59:59"
      );
      var task1 = api.punch.queryAll(this.searchData);
      var response = await task1;
      this.record = response.data.result;

      var task_company = api.organization.getCompanyList();
      var response_company = await task_company;
      this.companyOptions = response_company.data.result;

      this.selectCompany();

      this.$refs.pagination.setPage(this.searchData.pageNum, this.record.total);
    },
    async selectCompany() {
      var task_deps = api.organization.getDeptList({
        companyId: this.searchData.companyId
      });
      var response_desps = await task_deps;
      this.depts = response_desps.data.result;
    },
    formatTime: function(t) {
      return utils.formatTime(t, "YYYY-MM-DD");
    },
    async exportExcel() {
      this.searchData.startDate = utils.formatTime(
        this.searchData.startDate,
        "YYYY-MM-DD 00:00:00"
      );
      this.searchData.endDate = utils.formatTime(
        this.searchData.endDate,
        "YYYY-MM-DD 23:59:59"
      );

      var temp = JSON.parse(JSON.stringify(this.searchData));
      temp.pageNum = 1;
      temp.pageSize = 100000;

      let task_QueryAll = api.punch.queryAll(temp);
      let response = await task_QueryAll;
      if (response.data.status != "1" || response.data.result.total == 0) {
        this.$eventBus.$emit("showAlert", "查詢資料失敗，請重新操作!");
        return;
      }

      let data = [];
      response.data.result.datas.forEach(res => {
        data.push({
          部門: res.deptName,
          工號: res.employeeId,
          姓名: res.employeeName,
          日期: res.showDay,
          時間: res.showTime,
          狀態: res.status,
          打卡地點: res.punchLocation
        });
      });

      utils.exportData(
        data,
        utils.formatTime(new moment(), "YYYYMMDD") + "_出勤記錄.xlsx"
      );
    }
  }
};
</script>

<style scoped>
input[type="checkbox"] {
  position: relative;
  opacity: 1;
}

.max1000 {
  max-width: 1000px;
}
</style>