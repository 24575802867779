<template>
  <div id="ScoreBalance">
    <section class="content-header">
      <h1>小考平衡分數</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">小考平衡分數</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-primary"
                type="button"
                @click="exportExcel()"
              >
                <i class="fa fa-download"></i> 最終分數匯出
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢分數：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="-1">月份</option>
                  <option
                    v-for="(month, index) in 12"
                    :key="index"
                    :value="month"
                  >
                    {{ month }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-if="permission.isHR"
                  v-model="searchData.divisionId"
                  @change="getDeptList(true)"
                >
                  <option value="">處別</option>
                  <option
                    v-for="(item, index) in divisionList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="resetDataList()"
                >
                  <option value="" v-if="permission.isHR">部別</option>
                  <option
                    v-for="(item, index) in deptList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw80">年份</th>
                  <th class="tc mw50">月份</th>
                  <th class="tc mw100">部別</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw100">部主管評分</th>
                  <th class="tc mw300">部級調整</th>
                  <th class="tc mw300">處級調整</th>
                  <th class="tc mw150">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="9">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="9">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.month"></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td class="tc" v-text="data.totalScore"></td>
                  <td class="tc">
                    <div class="flex">
                      <input
                        type="number"
                        class="form-control w90"
                        min="0"
                        placeholder="分數"
                        :disabled="
                          !permission.deptIds.includes(data.deptId) ||
                          permission.hasOver
                        "
                        v-model="data.deptBalanceScore"
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="說明"
                        :disabled="
                          !permission.deptIds.includes(data.deptId) ||
                          permission.hasOver
                        "
                        v-model="data.deptBalanceNote"
                      />
                    </div>
                  </td>
                  <td class="tc">
                    <div class="flex">
                      <input
                        type="number"
                        class="form-control w90"
                        min="0"
                        placeholder="分數"
                        :disabled="!permission.hasOver"
                        v-model="data.divisionBalanceScore"
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="說明"
                        :disabled="!permission.hasOver"
                        v-model="data.divisionBalanceNote"
                      />
                    </div>
                  </td>
                  <td
                    class="tc"
                    v-if="
                      permission.deptIds.includes(data.deptId) ||
                      permission.hasOver
                    "
                  >
                    <button class="btn btn-primary" @click="updateData(data)">
                      儲存
                    </button>
                  </td>
                  <td class="tc" v-else>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        divisionId: "",
        deptId: "",
      },
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      divisionList: [],
      deptList: [],
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.updateMonthSearch();
    this.getDivisionList();
  },
  methods: {
    getDivisionList() {
      api.okr.goal.department.list({ deptLevel: 1 }).then((res) => {
        if (res.data.status === "1") {
          this.divisionList = res.data.result || [];
          this.updateDivisionListSearch();
          this.getDeptList();
        }
      });
    },
    getDeptList(reset) {
      if (this.permission.isHR || this.permission.hasOver) {
        api.okr.goal.department
          .list({ deptLevel: 2, parentDeptId: this.searchData.divisionId })
          .then((res) => {
            if (res.data.status === "1") {
              this.deptList = res.data.result || [];
              this.updateDeptListSearch(reset);
              if (reset) this.resetDataList();
              else this.getDataList();
            }
          });
      } else {
        this.deptList = this.permission.deptList || [];
        this.updateDeptListSearch(reset);
        if (reset) this.resetDataList();
        else this.getDataList();
      }
    },
    getDataList() {
      api.okr.score.min.balance.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateMonthSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.month = this.global.getMonth();
      }
    },
    updateDivisionListSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.divisionId = this.global.getDeptId(
          this.divisionList,
          this.userData,
          this.permission
        );
      }
    },
    updateDeptListSearch(reset) {
      if (reset) this.searchData.deptId = "";
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.deptId = this.global.getDeptId(
          this.deptList,
          this.userData,
          this.permission
        );
        if (!this.searchData.deptId && this.deptList.length) {
          this.searchData.deptId = this.deptList[0].deptId || "";
        }
      }
    },
    updateData(data) {
      data.deptType = this.userData.user.deptType;
      api.okr.score.min.balance.update(data).then((res) => {
        this.global.showResponse(this, res, "update");
      });
    },
    exportExcel() {
      const data = utils.deepCloneObj(this.searchData);
      data.pageNum = 0;
      data.pageSize = 0;
      api.okr.score.min.balance.queryAll(data).then((res) => {
        if (res.data.status === "1") {
          if (res.data.result.total <= 0) {
            this.$eventBus.$emit("showAlert", "查無資料");
          } else {
            const items = [];
            res.data.result.datas.forEach((item) => {
              items.push({
                年份: item.year,
                月份: item.month,
                部別: item.deptName,
                員工編號: item.employeeId,
                姓名: item.employeeName,
                部主管評分: item.totalScore || 0,
                部級調整: item.deptBalanceScore || "-",
                處級調整: item.divisionBalanceScore || "-",
                最終分數:
                  item.divisionBalanceScore ||
                  item.deptBalanceScore ||
                  item.totalScore ||
                  0,
              });
            });
            const title =
              this.searchData.month > 0
                ? `${utils.formatTime(
                    new Date(this.searchData.year, this.searchData.month - 1),
                    "YYYYMM"
                  )}_工作績效考核.xlsx`
                : `${this.searchData.year}_工作績效考核.xlsx`;
            utils.exportData(items, title, "工作績效考核");
          }
        } else {
          this.$eventBus.$emit("showAlert", res.data.message);
        }
      });
    },
  },
};
</script>
