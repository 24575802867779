var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-page" }, [
    _c(
      "div",
      { staticClass: "login-box", class: _vm.compony() },
      [
        _vm._m(0),
        _c("div", { staticClass: "login-box-body" }, [
          _c("div", { staticClass: "h3 login-box-msg" }, [
            _vm._v("內部管理系統"),
          ]),
          _c("form", [
            _c("div", { staticClass: "form-group has-feedback" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userName,
                    expression: "userName",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "帳號", type: "text" },
                domProps: { value: _vm.userName },
                on: {
                  change: function ($event) {
                    return _vm.getMailStatus()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.userName = $event.target.value
                  },
                },
              }),
              _c("span", {
                staticClass: "glyphicon glyphicon-user form-control-feedback",
              }),
            ]),
            _c("div", { staticClass: "form-group has-feedback" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "密碼", type: "password" },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _c("span", {
                staticClass: "glyphicon glyphicon-lock form-control-feedback",
              }),
            ]),
            _vm.verifyStatus == 1
              ? _c("div", { staticClass: "form-group has-feedback" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.verify,
                        expression: "verify",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "驗證碼", type: "text" },
                    domProps: { value: _vm.verify },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.verify = $event.target.value
                      },
                    },
                  }),
                  _c("span", {
                    staticClass:
                      "glyphicon glyphicon-lock form-control-feedback",
                  }),
                ])
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block btn-flat",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.login()
                  },
                },
              },
              [_vm._v("登入")]
            ),
            _vm.isOutsider == 1 || _vm.isADUser == 1
              ? _c(
                  "div",
                  {
                    staticClass: "link text-center mt-10",
                    staticStyle: { "text-decoration": "underline" },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javaScript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.forgetPwd()
                          },
                        },
                      },
                      [_vm._v("忘記密碼?")]
                    ),
                  ]
                )
              : _vm._e(),
            _c("p", { staticClass: "text-red mt-15" }, [
              _vm._v(
                "建議使用Chrome瀏覽器，並且將螢幕解析度調整為1280x900以上！"
              ),
            ]),
          ]),
          _c("p", [_vm._v("網銀國際(股)有限公司 研發處開發")]),
        ]),
        _vm._m(1),
        _c(
          "div",
          {
            staticClass: "modal fade",
            staticStyle: { "z-index": "999" },
            attrs: { id: "attention-date" },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _vm.islogin
                    ? _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            "aria-label": "Close",
                            "data-dismiss": "modal",
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goLogin()
                            },
                          },
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._m(2),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _vm.days > 0
                    ? _c("p", [
                        _vm._v(
                          "\r\n              您的密碼將在\r\n              "
                        ),
                        _c("span", { staticClass: "text-red" }, [
                          _vm._v(_vm._s(_vm.days)),
                        ]),
                        _vm._v("天後到期，請盡快更新密碼！\r\n            "),
                      ])
                    : _c("p", [_vm._v("您的密碼已到期，請更新密碼！")]),
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.midify()
                        },
                      },
                    },
                    [_vm._v("前往更改密碼")]
                  ),
                  _vm.islogin
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-default pull-right",
                          attrs: {
                            id: "attention-dismiss",
                            "data-dismiss": "modal",
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goLogin()
                            },
                          },
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
        _c("midify", { ref: "midify", attrs: { proplogin: false } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-logo" }, [
      _c("div", { staticClass: "logo" }, [
        _c("h1", { staticClass: "sr-only" }, [
          _vm._v("網銀國際 EIP 內部管理系統"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-center mt-10" }, [
      _vm._v("\r\n      Copyright © 2023\r\n      "),
      _c("a", { attrs: { href: "https://www.wanin.tw", target: "_blank" } }, [
        _vm._v("WANIN"),
      ]),
      _vm._v(" All Rights Reserved.\r\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "modal-title text-red" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(" 注意\r\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }