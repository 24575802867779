/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { stat } from 'fs';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser: "",
    loginUserCompany: "",
    loginUserMenu: "",
    loginUserPermission: "",
    loginDeptEmp: "",
    loginMealAssign: "",
    changePassword: false,
    orderMenu: [],
    orderTableList: [],
    flowNoticeTotal: 0,
    okrNotice: [],
    educationNotice: [],
    tables: [{ //預定餐點測試使用
      openorderstopTime: "2019-02-12 16:00",
      data: 560,
      item: [{
        no: '01',
        date: '',
        day: "",
        shopName: "",
        shopName_score: '',
        shopName_selfscore: '',
        meals: '',
        meals_score: "",
        meals_selfscore: '',
        statue: "",
        feature: "",
        // btnClass:'btn btn-primary',
      }, {
        no: '02',
        date: '2019-02-12',
        day: "二",
        shopName: "未選擇",
        shopName_score: '-1',
        shopName_selfscore: '-1',
        meals: '未選擇',
        meals_score: "-1",
        meals_selfscore: '-1',
        statue: "未訂餐",
        feature: "瀏覽菜單",
        // btnClass:'btn btn-primary',
      }, {
        no: '03',
        date: '2019-02-13',
        day: "三",
        shopName: "",
        shopName_score: "",
        shopName_selfscore: "",
        meals: '',
        meals_score: "",
        meals_selfscore: "",
        statue: "不訂餐",
        feature: "取消不訂餐",
        // btnClass:'btn btn-primary',
      }, {
        no: '04',
        date: '2019-02-14',
        day: "四",
        shopName: "盧意牛肉麵食館",
        shopName_score: 3.5,
        shopName_selfscore: 3,
        meals: '牛肉炸醬飯',
        meals_score: "尚未評分",
        meals_selfscore: 3,
        statue: "已訂餐",
        feature: "取消訂餐",
        // btnClass:'btn btn-danger',
      }, {
        no: '05',
        date: '2019-02-15',
        day: "五",
        shopName: "盧意牛肉麵食館",
        shopName_score: 3.5,
        shopName_selfscore: 3,
        meals: '牛肉炸醬飯',
        meals_score: "尚未評分",
        meals_selfscore: 3,
        statue: "已訂餐",
        feature: "取消訂餐",
        // btnClass:'btn btn-danger',
      }]
    }],
    //0未評分,-1部評分
    orderMenuTest: [{//選擇餐點測試使用
      openorderstopTime: "2019-02-12 16:00",
      data: 560,
      back: '返回列表',
      forward: '前往下週',
      week: {
        item: [{
          date: "2/1",
          day: "週一",
          mealdate: "2019-02-01",
          shopNo: [{
            shopName: '盧意牛肉麵食館',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '牛肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: false,
            meals_selfscore: '3',
            meals_score: "10",
            amount: '20',

          }, {
            shopName: '盧意牛肉麵食館',
            shopName_score: '0',
            shopName_selfscore: '3',
            meals: '肌肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '20',
          }, {
            shopName: '盧意牛肉麵食館',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '肌肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '20',
          }, {
            shopName: '盧意牛肉麵食館',
            shopName_score: '0',
            shopName_selfscore: '3',
            meals: '肌肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '20',
          }, {
            shopName: '盧意牛肉麵食館',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '肌肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '20',
          }, {
            shopName: '盧意牛肉麵食館',
            shopName_score: '0',
            shopName_selfscore: '3',
            meals: '牛肉麵',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '20',
          }, {
            shopName: '盧意牛肉麵食館',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '肌肉炸醬面',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '20',
          }],
          shopNo: [
            {
              shopName: '八方雲集',
              shopName_score: '3.5',
              shopName_selfscore: '3',
              meals: '鍋貼',
              mealpic: '../assets/img.png',
              mealNew: true,
              meals_selfscore: '3',
              meals_score: "0",
              amount: '20',
            }, {
              shopName: '八方雲集',
              shopName_score: '3.5',
              shopName_selfscore: '3',
              meals: '水煮水餃',
              mealpic: '../assets/img.png',
              mealNew: true,
              meals_selfscore: '3',
              meals_score: "0",
              amount: '20',
            }, {
              shopName: '八方雲集',
              shopName_score: '3.5',
              shopName_selfscore: '3',
              meals: '田園水餃',
              mealpic: '../assets/img.png',
              mealNew: true,
              meals_selfscore: '3',
              meals_score: "0",
              amount: '20',
            }],
          shopNo: [{
            shopName: '素食',
            shopName_score: '-1',
            shopName_selfscore: '-1',
            meals: '素食便當',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '-1',
            meals_score: "-1",
            amount: '',
          }]
        }, {
          date: "2/2",
          day: "週二",
          mealdate: "2019-02-02",
          shopNo: [{
            shopName: '八方雲集',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '牛肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "10",
            amount: '10',
          }, {
            shopName: '八方雲集',
            shopName: '腳踏實地',
            shopName_score: '尚未評分',
            shopName_selfscore: '3',
            meals: '香酥雞排飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "尚未評分",
            amount: '5',
          }, {
            shopName: '八方雲集',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '鍋貼',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '6',
          }],
          shopNo: [{
            shopName: '腳踏實地',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '雞肉便當',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '6',
          }],
          shopNo: [{
            shopName: '素食便當',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '素食便當',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '6',
          }]
        }, {
          date: "2/3",
          day: "週三",
          mealdate: "2019-02-04",
          shopNo: [{
            shopName: '八方雲集',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '牛肉炸醬飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "10",
            amount: '10',
          }, {
            shopName: '八方雲集',
            shopName: '腳踏實地',
            shopName_score: '尚未評分',
            shopName_selfscore: '3',
            meals: '香酥雞排飯',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "尚未評分",
            amount: '5',
          }, {
            shopName: '八方雲集',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '鍋貼',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '6',
          }],
          shopNo: [{
            shopName: '腳踏實地',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '雞肉便當',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '6',
          }],
          shopNo: [{
            shopName: '素食便當',
            shopName_score: '3.5',
            shopName_selfscore: '3',
            meals: '素食便當',
            mealpic: '../assets/img.png',
            mealNew: true,
            meals_selfscore: '3',
            meals_score: "0",
            amount: '6',
          }]
        }],
      },
    }]
  },
  mutations: {
    ORDERTABLELIST(state, payload) {//預定餐點列表
      state.orderTableList = payload;
    },
    ORDERMENU(state, payload) {//選擇餐點
      state.orderMenu = payload;
    },
    setLoginUser(state, user) {
      state.loginUser = user;
      state.loginUserCompany = user.companyChangeList;
    },
    setLoginUserMenu(state, menu) {
      state.loginUserMenu = menu;
    },
    setLoginPermission(state, permission) {
      state.loginUserPermission = permission;
    },
    setLoginDeptEmp(state, deptEmp) {
      state.loginDeptEmp = deptEmp;
    },
    setLoginMealAssign(state, mealAssign) {
      state.loginMealAssign = mealAssign;
    },
    setChangePassword(state, isChange) {
      state.changePassword = isChange;
    },
    setFlowNotice(state, notice) {
      state.flowNoticeTotal = notice;
    },
    setOkrNotice(state, notice) {
      state.okrNotice = notice;
    },
    setEducationNotice(state, notice) {
      state.educationNotice = notice;
    },
  },
  actions: {
    //取得預定餐點列表
    getOrderTableList(context) {
      var vm = this;
      vm.orderTableList = vm.state.tables;
      context.commit('ORDERTABLELIST', vm.orderTableList);
      //console.log(vm.orderTableList)
    },

    //選擇餐點
    orderMenu(context) {
      var vm = this;
      vm.orderMenu = vm.state.orderMenuTest;
      context.commit('ORDERMENU', vm.orderMenu);
      //console.log(vm.orderMenu)
    }

    // getProducts(){
    //   const url = `${process.env.APIPATH}/api/${process.env.CUSTOMPATH}/products/all`;
    //     context.commit('LOADING',true);//action裡面的方法
    //     axios.$http.get(url).then((response) => {
    //         context.commit('PRODUCTS',response.data.products);//儲存資料的行為
    //         context.commit('CATEGORIES',response.data.products);
    //         console.log('取得產品列表:', response);
    //         context.commit('LOADING',false);
    //   });
    // }

  }
})
