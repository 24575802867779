var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("批量課程紀錄")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", [_vm._v("課程管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("批量課程紀錄")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-success btn-add mr-10",
                staticStyle: { "min-width": "auto" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.exportList()
                  },
                },
              },
              [_vm._v("\n            匯出\n          ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.companyId,
                      expression: "searchData.companyId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "companyId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.getDeptList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.companyList, function (company, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: company.companyId } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(company.companyName) +
                            "\n              "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.deptId,
                      expression: "searchData.deptId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit2", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "deptId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.getDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部部門")]),
                  _vm._l(_vm.deptList, function (dept, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: dept.deptId } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(dept.deptIdName) +
                            "\n              "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _vm._m(1),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(2)]
                      : _vm.list.length == 0
                      ? [_vm._m(3)]
                      : _vm._l(_vm.list, function (data, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.courseType)),
                            ]),
                            _c("td", [_vm._v(_vm._s(data.courseName))]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.requireFinishCount)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(data.notRequireFinishCount) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.notFinishedCount)),
                            ]),
                            data.finishTestCount == -1
                              ? _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                    無測驗\n                  "
                                  ),
                                ])
                              : _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(data.finishTestCount) +
                                      "人\n                  "
                                  ),
                                ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("p", { staticClass: "data-num text-red" }, [
        _vm._v("*未完成人數僅統計課程為必修的人"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "5%" },
          },
          [_vm._v("#")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "8%" },
          },
          [_vm._v("\n                  類型\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap",
            staticStyle: { width: "15%", "min-width": "180px" },
          },
          [_vm._v("\n                  課程名稱\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "5%" },
          },
          [_vm._v("\n                  必修完課人數\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "5%" },
          },
          [_vm._v("\n                  選修完課人數\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "5%" },
          },
          [_vm._v("\n                  未完課人數\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "5%" },
          },
          [_vm._v("\n                  完成測驗人數\n                ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("查無資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }