<template>
  <div id="YearList">
    <section class="content-header">
      <h1>年度目標</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">年度目標</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="updateData()"
              >
                <i class="fa fa-plus"></i>
                新增目標
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢目標：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.code"
                  @change="resetDataList()"
                >
                  <option value="">核心目標</option>
                  <option value="F">財務(F)</option>
                  <option value="C">顧客價值(C)</option>
                  <option value="P">內部流程(P)</option>
                  <option value="L">學習與成長(L)</option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="請輸入目標"
                  type="text"
                  v-model.trim="searchData.keyword"
                />
                <div class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="resetDataList()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">年份</th>
                  <th class="tc">編碼</th>
                  <th class="tc wp40">年度目標</th>
                  <th class="tc">狀態</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="5">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="5">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.code"></td>
                  <td>{{ data.objective | ellipsis(80) }}</td>
                  <td class="tc" v-text="define.sendStatus[data.status]"></td>
                  <td class="tc">
                    <button class="btn btn-primary" @click="updateData(data)">
                      {{ data.status === 2 ? "檢視" : "修改" }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        code: "",
      },
      years: this.global.getYearList(),
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      api.okr.goal.year.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateData(data) {
      this.$router.push({
        name: "YearEdit",
        params: {
          id: data ? data.objectiveId : null,
          year: data ? data.year : this.searchData.year,
          edit: data ? (data.status === 2 ? 0 : 1) : 1,
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
