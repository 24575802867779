<template>
  <div id="TimeClock">
    <section class="content-header">
      <h1>打卡鐘資訊</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">打卡鐘資訊</li>
      </ol>
    </section>

    <section class="content order-management">
      <div class="loading fade"></div>
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box box-header with-border">
            <div class="input-group date">
              <div class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </div>
              <div class="pull-right">
                <DatePicker v-model="searchData.runTime" />
              </div>
            </div>

            <button class="btn btn-block btn-warning btn-add" type="button" @click="onSearch()">重新收集</button>

            <div class="text">
              <p class="data-num">目前觸發日期：{{watchTime}}</p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="min-width: 20px;" width="10%">打卡鐘</th>
                  <th class="text-nowrap text-center" style="min-width: 20px;" width="10%">狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">錯誤訊息</th>
                </tr>
                <template v-if="timeclock != null && timeclock.length > 0">
                  <tr :key="index" v-for="(item, index) in timeclock">
                    <td class="text-center">{{ item.message }}</td>
                    <td class="text-center">{{ item.status=="1"?"成功":"失敗" }}</td>
                    <td class="text-center">{{ item.status=="1"? "" : item.result }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="3">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import config from "@/assets/js/app-config";
import moment from "moment";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    DateRangePicker,
    Pagination,
    DatePicker
  },
  delimiters: ["${", "}"],
  data() {
    return {
      hasEditPermission: false,
      timeclock: [],
      watchTime: "",
      searchData: {
        runTime: new moment().format("YYYY/MM/DD")
      }
    };
  },
  created() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.TimeClockSystem
    );
    if (!this.hasEditPermission) {
      this.backList();
      return;
    }
  },
  mounted() {},
  methods: {
    backList() {
      this.$router.push({
        name: "Home"
      });
      return;
    },
    onSearch() {
      if (new moment(this.searchData.runTime).isAfter(new moment())) {
        this.$eventBus.$emit("showAlert", "時間不能超過今天!");
        return;
      }
      this.watchTime = this.searchData.runTime;
      this.workShowAll();
    },
    // 顯示列表
    async workShowAll() {
      $(".loading").addClass("in");
      let data = new FormData();
      data.append("runTime", this.searchData.runTime);
      var task1 = api.punch.punchRun(data);
      var response = await task1;
      this.timeclock = response.data.result;
      if (this.timeclock == null && response.data.message != "") {
        this.$eventBus.$emit("showAlert", response.data.message);
      }
      $(".loading").removeClass("in");
    }
  }
};
</script>

<style scoped>
@-webkit-keyframes loading {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes loading {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes loading {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  border: 3px solid #3a3;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-animation: loading 1s infinite linear;
  -moz-animation: loading 1s infinite linear;
  -o-animation: loading 1s infinite linear;
  animation: loading 1s infinite linear;
}
</style>