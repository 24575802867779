<template>
  <!--預定會議室-->
  <div class="modal fade" ref="booking">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">預約會議室</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>會議室名稱</td>
                  <td>{{ room.roomName }}</td>
                </tr>
                <tr>
                  <td>設備</td>
                  <td>{{ room.tools }}</td>
                </tr>
                <tr>
                  <td>座位</td>
                  <td>{{ room.seatCount }}人</td>
                </tr>
                <tr>
                  <td>會議日期</td>
                  <td>{{ booking.meetingDay }}</td>
                </tr>
                <tr>
                  <td>會議時段</td>
                  <td class="form-inline">
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="booking.startTime"
                        :disabled="booked.length > 0"
                      >
                        <option value="0900">09:00</option>
                        <option value="1000">10:00</option>
                        <option value="1100">11:00</option>
                        <option value="1200">12:00</option>
                        <option value="1300">13:00</option>
                        <option value="1400">14:00</option>
                        <option value="1500">15:00</option>
                        <option value="1600">16:00</option>
                        <option value="1700">17:00</option>
                        <option value="1800">18:00</option>
                      </select>
                    </div>
                    &nbsp;至&nbsp;
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="booking.endTime"
                        :disabled="booked.length > 0"
                      >
                        <option value="1000">10:00</option>
                        <option value="1100">11:00</option>
                        <option value="1200">12:00</option>
                        <option value="1300">13:00</option>
                        <option value="1400">14:00</option>
                        <option value="1500">15:00</option>
                        <option value="1600">16:00</option>
                        <option value="1700">17:00</option>
                        <option value="1800">18:00</option>
                        <option value="1900">19:00</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>預約人</td>
                  <td>{{ employeeName }}({{ empEmail }})</td>
                </tr>
                <tr>
                  <td>會議主題</td>
                  <td>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="booking.meetingTitle"
                      :disabled="booked.length > 0"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <p class="text-red pull-left mt-5">
            <i class="fa fa-warning"></i> 已過的時段無法取消預約。
          </p>
          <button
            class="btn btn-danger"
            type="button"
            v-if="booked.length > 0 && cancelBooking"
            @click="cancelBooked()"
          >
            取消預約
          </button>
          <button
            class="btn btn-primary"
            type="button"
            v-else-if="booked.length == 0"
            @click="bookingMeetingRoom()"
          >
            確定預約
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            取消
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  data() {
    return {
      room: {},
      employeeId: "",
      employeeName: "",
      empEmail: "",
      cancelBooking: true,
      booked: {},
      booking: {
        roomId: "",
        startTime: "",
        endTime: "",
        meetingDay: "",
        meetingTitle: "",
        personCount: ""
      }
    };
  },
  created() {},
  methods: {
    showMeetingRoomBooking(booked, room, startTime, endTime, meetingDay) {
      this.employeeId = this.$store.state.loginUser.employeeId;
      this.employeeName = this.$store.state.loginUser.employeeName;
      this.empEmail = this.$store.state.loginUser.empEmail;

      this.booked = JSON.parse(JSON.stringify(booked));
      if (booked.length > 0) {
        this.booking.meetingTitle = booked[0].meetingTitle;
        if (this.employeeId != booked[0].createEmpId) {
          this.empEmail = booked[0].createEmpEmail;
          this.employeeName = booked[0].createEmpName;
          this.employeeId = booked[0].createEmpId;
          this.cancelBooking = false;
        } else this.cancelBooking = true;
      } else this.booking.meetingTitle = "";

      this.room = room;
      this.booking.roomId = room.roomId;
      this.booking.startTime = startTime;
      this.booking.endTime = endTime;
      this.booking.meetingDay = meetingDay;
      this.booking.personCount = room.seatCount;

      if (this.employeeId == "" || this.employeeId == undefined) {
        this.refreshList();
        $(this.$refs.booking).modal("hide");
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      // await this.getEmpHabitsInfo();

      $(this.$refs.booking).modal("show");
      // $(this.$refs.editSaveHabitsButton).click(() => {
      //   $(this.$refs.editSaveHabitsButton).off("click");
      //   $(this.$refs.food_habits).modal("hide");
      //   this.updateHabits(this.edit);
      // });
    },
    async bookingMeetingRoom() {
      if (this.booking.startTime == "" || this.booking.endTime == "") {
        this.$eventBus.$emit("showAlert", "預約時間不可為空");
        return;
      }

      if (parseInt(this.booking.endTime) <= parseInt(this.booking.startTime)) {
        this.$eventBus.$emit("showAlert", "開始時間不可大於結束時間");
        return;
      }

      if (this.booking.meetingTitle == "") {
        this.$eventBus.$emit("showAlert", "活動主題不可為空!");
        return;
      }

      var response = await api.meetingBooking.checkMeetingBookingPass({
        roomId: this.booking.roomId,
        meetingDay: this.booking.meetingDay,
        startTime: this.booking.startTime,
        endTime: this.booking.endTime
      });

      if (response.data.status != "1") {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        this.refreshList();
        return;
      }
      if (response.data.result == 0) {
        this.$eventBus.$emit(
          "showAlert",
          "此段時間已有人預訂，請重新選擇時段!!"
        );
        this.refreshList();
        return;
      }
      api.meetingBooking.create(this.booking).then(res => {
        if (res.data.status == "1") {
          $(this.$refs.booking).modal("hide");
        } else if (res.data.status == "2") {
          this.$eventBus.$emit("showAlert", res.data.message);
        } else {
          this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        }
        this.refreshList();
      });
    },
    cancelBooked() {
      api.meetingBooking
        .delete({
          bookingId: this.booked[0].bookingId
        })
        .then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
          } else {
            $(this.$refs.booking).modal("hide");
          }
          this.refreshList();
        });
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>
