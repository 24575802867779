<template>
  <div id="DivisionList">
    <section class="content-header">
      <h1>處級目標</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">處級目標</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns" v-if="permission.deptIds.length">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="updateData()"
              >
                <i class="fa fa-plus"></i>
                新增目標
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢目標：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="getObjs(true)"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="-1">全部</option>
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.parentObjectiveId"
                  @change="resetDataList()"
                >
                  <option value="">年度目標</option>
                  <option value="-1">未定</option>
                  <option
                    v-for="(obj, index) in objs"
                    :key="index"
                    :value="obj.objectiveId"
                  >
                    {{ obj.objective }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="resetDataList()"
                >
                  <option value="">處別</option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="請輸入目標"
                  type="text"
                  v-model.trim="searchData.keyword"
                />
                <div class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="resetDataList()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">處別</th>
                  <th class="tc">年份</th>
                  <th class="tc">年度</th>
                  <th class="tc wp30">年度目標</th>
                  <th class="tc wp30">處級目標</th>
                  <th class="tc">狀態</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.year"></td>
                  <td
                    class="tc"
                    v-text="define.halfYearStatus[data.month]"
                  ></td>
                  <td>{{ data.parentObjective | ellipsis(60) }}</td>
                  <td>{{ data.objective | ellipsis(60) }}</td>
                  <td
                    class="tc"
                    v-text="define.sendStatus[data.objectiveStatus]"
                  ></td>
                  <td class="tc">
                    <button class="btn btn-primary" @click="updateData(data)">
                      {{
                        !data.objectiveStatus &&
                        permission.deptIds.includes(data.deptId)
                          ? "修改"
                          : "檢視"
                      }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        parentObjectiveId: "",
        deptId: "",
      },
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      list: [],
      objs: [],
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 50);
    this.getList();
  },
  methods: {
    getList() {
      api.okr.goal.department.list({ deptLevel: 1 }).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.updateListSearch();
          this.getObjs();
        }
      });
    },
    getObjs(reset) {
      api.okr.goal.year
        .queryAll({
          year: this.searchData.year,
          status: 2,
        })
        .then((res) => {
          if (res.data.status === "1") {
            const datas = res.data.result.datas || [];
            this.objs = this.global.getDistinctData(datas, "objective");
            if (reset) this.resetDataList();
            else this.getDataList();
          }
        });
    },
    getDataList() {
      api.okr.goal.division.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateListSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.deptId = this.global.getDeptId(
          this.list,
          this.userData,
          this.permission
        );
      }
    },
    updateData(data) {
      this.$router.push({
        name: "DivisionEdit",
        params: {
          id: data ? data.deptId : null,
          year: data ? data.year : this.searchData.year,
          month: data ? data.month : this.searchData.month,
          formType: data ? data.formTypeId : null,
          formId: data ? data.formId : null,
          createId: data ? data.createEmpId : null,
          signId: data ? data.signGetId : null,
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
