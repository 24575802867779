<template>
  <div id="ActivityCalendar">
    <section class="content-header">
      <h1>活動行事曆</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li class="active">活動行事曆</li>
      </ol>
    </section>
    <section class="content activity-calendar">
      <h2 class="sr-only">活動行事曆</h2>
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-col5">
            <button
              @click="goActivityAllList()"
              class="btn btn-block btn-success btn-add"
              type="button"
            >
              <i class="fa fa-bars"></i> 列表
            </button>
            <div class="col-5">
              <div class="select-group">
                <select class="form-control" id="selectYear" v-model="years">
                  <option
                    :key="index"
                    :value="2019 + year"
                    v-for="(year, index) in yearRange"
                    >{{ 2019 + year }}</option
                  >
                </select>
              </div>
              <div class="select-group">
                <select class="form-control" id="selectMonth" v-model="months">
                  <option value="01">01月</option>
                  <option value="02">02月</option>
                  <option value="03">03月</option>
                  <option value="04">04月</option>
                  <option value="05">05月</option>
                  <option value="06">06月</option>
                  <option value="07">07月</option>
                  <option value="08">08月</option>
                  <option value="09">09月</option>
                  <option value="10">10月</option>
                  <option value="11">11月</option>
                  <option value="12">12月</option>
                </select>
              </div>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  @change="queryAllKinds"
                  class="form-control"
                  id="selectType"
                  v-model="searchDatas.typeId"
                >
                  <option value="-1">所有活動類別</option>
                  <option
                    :key="index"
                    :value="item.typeId"
                    v-for="(item, index) in activityTypes"
                    >{{ item.typeName }}</option
                  >
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectName"
                  v-model="searchDatas.kindId"
                >
                  <option value="-1">所有活動項目</option>
                  <option
                    :key="index"
                    :value="item.kindId"
                    v-for="(item, index) in activityKinds"
                    >{{ item.kindName }}</option
                  >
                </select>
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="搜尋活動名稱"
                type="text"
                v-model="searchDatas.keyword"
              />
              <div class="input-group-btn">
                <button
                  @click="queryAll(0)"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="btns pre-next mt-10 bg-gray-light">
            <button @click="queryAll(-1)" class="btn btn-default" type="button">
              <i class="fa fa-chevron-circle-left"></i>&nbsp;上個月
            </button>
            <h3 class="date">{{ years }}年{{ months }}月</h3>
            <button @click="queryAll(1)" class="btn btn-default" type="button">
              下個月&nbsp;
              <i class="fa fa-chevron-circle-right"></i>
            </button>
          </div>
          <div class="table-responsive">
            <table class="table order-table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th>星期一</th>
                  <th>星期二</th>
                  <th>星期三</th>
                  <th>星期四</th>
                  <th>星期五</th>
                  <th>星期六</th>
                  <th>星期日</th>
                </tr>
                <tr :key="index" v-for="(week, index) in weekObj">
                  <td
                    :class="data.class"
                    :key="index"
                    v-for="(data, index) in week"
                  >
                    <div class="date">
                      {{ data.date }}&nbsp;
                      <span class="week">
                        {{
                          data.date != "" ? formatTime(data.date, "dddd") : ""
                        }}
                      </span>
                    </div>
                    <div class="day">
                      {{ data.date != "" ? formatTime(data.date, "D") : "" }}
                    </div>
                    <!-- 有參加 class 加 is-join -->
                    <div
                      :class="
                        item.joinStatus >= 0
                          ? 'event btn btn-default is-join'
                          : 'event btn btn-default'
                      "
                      :key="index"
                      @click="openActivityBooking(item)"
                      v-for="(item, index) in data.datas"
                    >
                      <div class="name">
                        {{ item.typeName }}-{{ item.kindName }}-{{
                          item.activityName
                        }}
                      </div>
                      <div class="time">
                        {{ formatTime(item.activitySTime, "HH:mm") }}~{{
                          formatTime(item.activityETime, "HH:mm")
                        }}
                      </div>
                      <div class="note">{{ item.location }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-red mt-10">
            <i class="fa fa-warning"></i>
            點擊活動區塊即可查看明細、進行報名參加，已過的時段無法參加。
          </p>
        </div>
      </div>
    </section>
    <activityBooking ref="activityBooking" v-on:refresh="queryAll(0)" />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import activityBooking from "@/views/Activity/ActivityBooking";
import date_fns from "date-fns";

export default {
  components: {
    DatePicker,
    activityBooking
  },
  data() {
    return {
      total: 0,
      activityTypes: [],
      activityKinds: [],
      activitys: [],
      yearRange: 101,
      years: date_fns.getYear(new Date()), //年份
      months: this.formatTime(new Date(), "M"), //月份
      days: 31,
      weekObj: [],
      searchDatas: {
        typeId: "-1",
        kindId: "-1",
        keyword: "",
        startTime: this.formatTime(new Date(), "YYYY-MM"),
        endTime: this.formatTime(new Date(), "YYYY-MM")
      }
    };
  },
  created() {
    if (this.$route.params.searchDatas != undefined) {
      this.searchDatas = this.$route.params.searchDatas;
      this.months = this.$route.params.months;
      this.years = this.$route.params.years;
    }
    this.queryAll(0);
    this.queryAllTypes();
    this.queryAllKinds();
  },
  methods: {
    initCalendar(datetime) {
      let week = date_fns.getISODay(datetime + "-01"); //1~7 7 is Sunday
      this.weekObj = [];
      let dayObj = [];

      //每月第一週
      for (let i = 1; i <= 7; i++) {
        let data = {
          date: "",
          datas: [],
          class: ""
        };
        if (i >= week) {
          data.date = this.formatTime(
            date_fns.addDays(datetime + "-01", i - week),
            "YYYY-MM-DD"
          );
        }
        dayObj.push(data);
      }

      this.weekObj.push(dayObj);

      let limitDay = this.days - (7 - week + 1);
      let weeks = Math.ceil(limitDay / 7);

      for (let o = 0; o < weeks; o++) {
        dayObj = [];
        for (let d = 1; d <= 7; d++) {
          let data = {
            date: "",
            datas: [],
            class: ""
          };
          if (limitDay == 0) break;
          data.date = this.formatTime(
            date_fns.addDays(
              this.weekObj[o][this.weekObj[o].length - 1].date,
              d
            ),
            "YYYY-MM-DD"
          );
          limitDay--;

          dayObj.push(data);
        }

        this.weekObj.push(dayObj);
      }
    },
    queryAll(add) {
      if (
        date_fns.isBefore(
          date_fns.addMonths(this.years + "-" + this.months, add)
        )
      )
        return;

      let tempDate = this.formatTime(
        date_fns.addMonths(this.years + "-" + this.months, add),
        "YYYY-MM"
      );

      this.months = this.formatTime(tempDate, "MM");

      this.years = date_fns.getYear(tempDate);

      this.searchDatas.startTime = this.formatTime(
        this.years + "-" + this.months,
        "YYYY-MM-01"
      );
      this.days = date_fns.getDaysInMonth(this.searchDatas.startTime);
      this.searchDatas.endTime = this.formatTime(
        this.searchDatas.startTime,
        "YYYY-MM-" + this.days
      );
      this.initCalendar(tempDate);
      api.activity.queryAll(this.searchDatas).then(res => {
        if (res.data.status == "1") {
          this.total = res.data.result.total;
          this.activitys = res.data.result.datas;
        }
        this.getActivityDate();
      });
    },
    getActivityDate() {
      //取得當天活動資料
      for (let i = 0; i < this.weekObj.length; i++) {
        for (let o = 0; o < this.weekObj[i].length; o++) {
          let temp = [];
          let date = this.weekObj[i][o].date;
          if (this.total > 0)
            temp = this.activitys.filter(
              activity =>
                this.formatTime(activity.activitySTime, "YYYY-MM-DD") == date &&
                activity.status != 0
            );

          this.weekObj[i][o].datas = temp;
          if (temp.length == 0) this.weekObj[i][o].class = "no-event";
          if (date_fns.isSameDay(new Date(), date))
            this.weekObj[i][o].class = "today";
        }
      }
    },
    async queryAllTypes() {
      // 活動類別
      let activityTypes = await api.activity.queryAllType({
        isStop: 0
      });
      this.activityTypes = activityTypes.data.result.datas;
    },
    async queryAllKinds() {
      // 活動類別細項
      this.searchDatas.kindId = "-1";
      let activityKinds = await api.activity.queryAllManagerKind({
        typeId: this.searchDatas.typeId,
        isStop: 0
      });
      this.activityKinds = activityKinds.data.result.datas;
    },
    openActivityBooking(item) {
      this.$refs.activityBooking.showActivityBooking(item);
    },
    goActivityAllList() {
      this.$router.push({
        name: "ActivityAllList",
        params: {
          months: this.months,
          years: this.years,
          searchDatas: this.searchDatas
        }
      });
    },
    formatTime: function(t, format) {
      return date_fns.format(t, format);
    }
  }
};
</script>
