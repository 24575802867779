<template>
  <div id="ScoreAddFlow">
    <section class="content-header">
      <h1>工作績效考核表 (例外)</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/OKR/ScorePersonalList/Except">
            工作績效考核表 (例外)
          </router-link>
        </li>
        <li class="active">新增考核</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">新增考核</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <form
            class="form-horizontal"
            method="post"
            @submit.prevent="saveData()"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  年度：
                </label>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    v-model.number="data.year"
                    @change="getCycleMonths(true)"
                  >
                    <option
                      v-for="(year, index) in years"
                      :key="index"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    v-model.number="data.month"
                  >
                    <option v-if="data.deptLevel === 2" value="0">
                      上半年
                    </option>
                    <option v-if="data.deptLevel === 2" value="1">
                      下半年
                    </option>
                    <option
                      v-show="data.deptLevel !== 2"
                      v-for="(month, index) in months"
                      :key="index"
                      :value="month"
                    >
                      {{ month }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  考核單位：
                </label>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    v-model="data.deptId"
                    @change="getCycleMonths(true)"
                  >
                    <option
                      v-for="(item, index) in list"
                      :key="index"
                      :value="item.deptId"
                    >
                      {{ item.deptName }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    v-model.number="data.deptLevel"
                    @change="getCycleMonths(true)"
                  >
                    <option value="2">部級主管</option>
                    <option value="3">一般員工</option>
                    <option value="4">新進員工</option>
                    <option value="5">實習生</option>
                  </select>
                </div>
              </div>
              <div class="form-group" style="margin: 40px 0">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-8">
                  <div class="flowContent x_content">
                    <ul class="process-map list-unstyled">
                      <li class="start bg-green">開始</li>
                      <li class="add">
                        <button
                          type="button"
                          class="btn btn-default"
                          @click="showContent(null, 0.5)"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </li>
                      <template v-for="(item, index) in datas">
                        <li :key="index">
                          <div class="level">第{{ item.orderNum }}關</div>
                          <p class="name">{{ item.empName }}</p>
                          <div class="tools">
                            <button
                              type="button"
                              class="bg-blue"
                              @click="showContent(item, item.orderNum)"
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              class="bg-red ml10"
                              @click="deleteData(item)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                          <span class="name" v-if="index === 0">
                            （考核人員）
                          </span>
                        </li>
                        <li :key="item.groupFlowId" class="add">
                          <button
                            type="button"
                            class="btn btn-default"
                            @click="showContent(null, item.orderNum + 0.5)"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </li>
                      </template>
                      <li class="end bg-gray">結束</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <input type="submit" value="新增" class="btn btn-primary" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <FlowContent ref="flowContent" v-on:updateList="updateData" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import FlowContent from "./FlowContent";

export default {
  components: {
    FlowContent,
  },
  data() {
    return {
      loading: true,
      department: false,
      total: 0,
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      data: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        deptId: this.$route.params.id,
        deptLevel: this.$route.params.deptLevel,
        adminAdd: 1,
        empList: [],
      },
      addData: {},
      list: [],
      months: [],
      datas: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      api.okr.goal.department.list({ deptLevel: 2 }).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.getCycleMonths();
        }
      });
    },
    getCycleMonths(reset) {
      if (this.department || this.data.deptLevel === 2) {
        this.months = [];
        this.data.month = -1;
      }
      this.department = this.data.deptLevel === 2 ? true : false;
      api.okr.score.min.cycleQuery(this.data).then((res) => {
        if (res.data.status === "1") {
          this.months = res.data.result || [];
          this.loading = false;
          this.updateMonthSearch(reset);
        }
      });
    },
    updateMonthSearch(reset) {
      if (
        (reset && !this.months.includes(this.data.month)) ||
        (this.loading && !this.$route.params.searchData)
      ) {
        if (this.months.includes(this.data.month)) {
          this.data.month = this.global.getMonth(this.months);
        } else {
          this.data.month = -1;
        }
      }
    },
    showContent(data, orderNum) {
      this.addData.orderNum = orderNum;
      this.addData.deptId = this.data.deptId;
      this.$refs.flowContent.showContent(data, this.addData);
    },
    updateData(data) {
      const orderNum = Math.floor(data.orderNum);
      if (orderNum === 0) {
        this.datas.splice(0, 0, data);
      } else {
        for (let i = 0; i < this.datas.length; i++) {
          if (data.orderNum === this.datas[i].orderNum) {
            this.datas.splice(i, 1, data);
            break;
          } else if (orderNum === this.datas[i].orderNum) {
            this.datas.splice(i + 1, 0, data);
            break;
          }
        }
      }
      this.sortData();
    },
    deleteData(data) {
      const index = this.datas.indexOf(data);
      if (index !== -1) {
        this.datas.splice(index, 1);
        this.sortData();
      }
    },
    sortData() {
      this.datas.forEach((data, index) => {
        data.orderNum = index + 1;
      });
    },
    saveData() {
      if (this.datas.length) {
        this.$router.push({
          name: "ScorePersonalEdit",
          params: {
            type: this.person.type,
            id: this.datas[0].empId,
            year: this.data.year,
            month: this.data.month,
            deptId: this.data.deptId,
            deptLevel: this.data.deptLevel,
            adminAdd: this.data.adminAdd,
            empList: this.datas,
            searchData: this.$route.params.searchData,
          },
        });
      } else {
        this.$eventBus.$emit("showAlert", "請先設定考核流程！");
      }
    },
    backList() {
      this.$router.push({
        name: "ScorePersonalList",
        params: {
          type: this.person.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
