<template>
  <div id="PermissionList">
    <section class="content-header">
      <h1>功能選單設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li>權限管理</li>
        <li class="active">功能選單設定</li>
      </ol>
    </section>
    <section class="content groups">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button class="btn btn-block btn-warning btn-add" type="button" data-target="#add-features" data-toggle="modal">
              <i class="fa fa-plus"></i> 新增功能
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered table-striped min-600">
              <tbody>
                <tr>
                  <th>功能代號</th>
                  <th>功能名稱</th>
                  <th class="text-nowrap">排序</th>
                  <th class="text-center">網頁MENU</th>
                  <th>頁面路徑</th>
                  <th class="text-center">開放設定</th>
                  <th class="text-nowrap text-center" style="min-width: 260px;">功能</th>
                </tr>
                <tr>
                  <td>S0</td>
                  <td>簽核管理</td>
                  <td>100</td>
                  <td class="text-center"><i class="fa fa-check text-green"></i></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button class="btn btn-info">子功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>C0</td>
                  <td>績效考核</td>
                  <td>200</td>
                  <td class="text-center"><i class="fa fa-check text-green"></i></td>
                  <td></td>
                  <td class="text-center"><i class="fa fa-check text-green"></i></td>
                  <td class="text-center">
                    <button class="btn btn-info">子功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>A0</td>
                  <td>帳號管理</td>
                  <td>300</td>
                  <td class="text-center"><i class="fa fa-check text-green"></i></td>
                  <td></td>
                  <td class="text-center"><i class="fa fa-check text-green"></i></td>
                  <td class="text-center">
                    <button class="btn btn-info">子功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>P0</td>
                  <td>權限管理</td>
                  <td>500</td>
                  <td class="text-center"><i class="fa fa-check text-green"></i></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button class="btn btn-info">子功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>X0</td>
                  <td>個別權限定義</td>
                  <td>600</td>
                  <td class="text-center"></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button class="btn btn-info">子功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="box-footer clearfix text-center">
          <Pagination />
        </div> -->
      </div>
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="h4 pull-left">X0 個別權限定義 - 子功能設定</h4>
            <button class="btn btn-block btn-default btn-cog" type="button"><i class="fa fa-reply"></i> 返回列表</button>
            <button class="btn btn-block btn-warning btn-add" type="button" data-target="#add-features" data-toggle="modal">
              <i class="fa fa-plus"></i> 新增子功能
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered table-striped min-600">
              <tbody>
                <tr>
                  <th>功能代號</th>
                  <th>功能名稱</th>
                  <th class="text-nowrap">排序</th>
                  <th class="text-center">網頁MENU</th>
                  <th>頁面路徑</th>
                  <th class="text-center">開放設定</th>
                  <th class="text-nowrap text-center" style="min-width: 260px;">功能</th>
                </tr>
                <tr>
                  <td>X0-001</td>
                  <td>可新增主辦單位</td>
                  <td>100</td>
                  <td class="text-center"></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button class="btn btn-info">次項功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
                <tr>
                  <td>X0-888</td>
                  <td>SystemAdmin行使的權限</td>
                  <td>500</td>
                  <td class="text-center"></td>
                  <td></td>
                  <td class="text-center"></td>
                  <td class="text-center">
                    <button class="btn btn-info">次項功能設定</button>
                    &nbsp;
                    <button class="btn btn-primary" data-target="#add-features" data-toggle="modal">修改</button>
                    &nbsp;
                    <button class="btn btn-danger" data-target="#attention" data-toggle="modal">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="box-footer clearfix text-center">
          <Pagination />
        </div> -->
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import Pagination from '../components/Pagination';
import AlertBox from '../components/AlertBox';

export default {
  components: {
    Pagination,
    AlertBox,
  },
};
</script>
