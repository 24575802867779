export default{
    Permission:{
        Home:"P0",
        CompanyDocumentEdit:"F0-03",
        CompanyDocumentSelectTarget:"F0-04",
        AnnouncementEdit:"AN-03",
        AnnouncementEditAll:"AN-04",
        SeatingSetting:"D0-310", // 座位表維護
        ExtensionSetting:"D0-410", // 分機表維護
        SiteSetting: "B0-900", //場地維護
        ProductsManager:"J0-900", //公司產品管理
        OfficeEdit: "Z0-03", //辨公室管理
        ActionWorkSystem: "R0-01", //行動作業管理
        TimeClockSystem: "T0-001", //打卡鐘資訊
        AllowCheckOtherCompany: "T0-003", //允許檢視其他公司出勤紀錄
        ActivityAddmemberCheck: "AT-013", //活動參加人員檢核

    }
}