<template>
  <div id="ScoreDeptEdit">
    <div class="table-responsive" style="max-width: 1400px; margin: auto">
      <div
        class="btns"
        v-if="hasEdit && (permission.isDeptManager || person.level === 9)"
      >
        <button
          class="btn btn-warning"
          style="float: right; margin: 10px 0px"
          type="button"
          @click="showContent()"
        >
          <i class="fa fa-plus"></i>
          新增目標
        </button>
      </div>
      <table
        class="table table-hover table-bordered min-800"
        style="margin: 5px auto"
      >
        <tbody>
          <tr class="bg-gray">
            <th class="tc">#</th>
            <th class="tc">目標種類</th>
            <th class="tc mw150">部級目標</th>
            <th class="tc mw150">目標說明</th>
            <template v-for="(title, index) in titles">
              <th
                class="tc pre-wrap"
                :key="index"
                v-if="hasView"
                v-text="
                  `${title.employeeName}\n${
                    index === 0
                      ? '（自評）'
                      : title.isManager === '0' &&
                        title.deptType.includes('0060')
                      ? '（上層主管）'
                      : title.isManager === '1' &&
                        title.deptType.includes('0060')
                      ? '（部門主管）'
                      : '（高層主管）'
                  }`
                "
              ></th>
              <th
                class="tc pre-wrap"
                :key="title.employeeId"
                v-text="
                  `${!hasView ? `${title.employeeName}\n` : ''}${
                    data.employeeId === title.employeeId ? '說明' : '考核意見'
                  }`
                "
              ></th>
            </template>
            <th class="tc">不列入考核</th>
            <th class="tc mw80" v-if="hasEdit">管理</th>
          </tr>
          <tr v-if="loading">
            <td class="tc" :colspan="6 + 2 * titles.length">查詢中</td>
          </tr>
          <tr v-else-if="datas.length === 0">
            <td class="tc" :colspan="6 + 2 * titles.length">尚無設定目標</td>
          </tr>
          <tr v-else v-for="(data, index) in datas" :key="index">
            <td
              class="tc"
              v-if="data.objectiveType !== 2"
              v-text="index + 1"
            ></td>
            <td
              class="tc"
              :colspan="data.objectiveType === 2 ? 4 : 0"
              v-text="define.objectiveType[data.objectiveType]"
            ></td>
            <td
              class="pre-wrap"
              v-if="data.objectiveType !== 2"
              v-text="data.objective"
            ></td>
            <td
              class="pre-wrap"
              v-if="data.objectiveType !== 2"
              v-text="data.note || '-'"
            ></td>
            <template v-for="(scoreItem, index) in data.scores">
              <td
                class="tc"
                :key="index"
                v-if="hasView"
                v-text="scoreItem.score || scoreItem.totalScore || 0"
              ></td>
              <td
                class="pre-wrap"
                :key="scoreItem.scoreId"
                v-text="scoreItem.note"
              ></td>
            </template>
            <td class="tc" v-if="data.objectiveType === 2">－</td>
            <td class="tc" v-else>
              <iCheckCheckBox
                :propNone="true"
                :propChecked="data.checked"
                :propDisabled="data.disabled"
              />
            </td>
            <td class="tc" v-if="hasEdit && data.objectiveType === 2">
              <button
                class="btn btn-primary"
                @click="showFinalScoreContent(data)"
              >
                評分
              </button>
            </td>
            <td class="tc" v-else-if="hasEdit">
              <button class="btn btn-primary" @click="showScoreContent(data)">
                評分
              </button>
              <span v-show="data.objectiveType">&nbsp;</span>
              <button
                class="btn btn-danger"
                v-show="data.objectiveType"
                @click="deleteData(data)"
              >
                刪除
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <DeptContent ref="deptContent" v-on:updateList="getDataList" />
    <ScoreDeptContent ref="scoreDeptContent" v-on:updateList="getDataList" />
    <ScorePersonalContent
      ref="scorePersonalContent"
      v-on:updateList="getDataList"
    />
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import api from "@/assets/js/app-api";
import DeptContent from "./DeptContent";
import ScoreDeptContent from "./ScoreDeptContent";
import ScorePersonalContent from "./ScorePersonalContent";

export default {
  components: {
    iCheckCheckBox,
    DeptContent,
    ScoreDeptContent,
    ScorePersonalContent,
  },
  data() {
    return {
      hasAll: false,
      hasEdit: false,
      hasView: false,
      hasHR: false,
      hasExcept: false,
      loading: true,
      total: 0,
      addData: {
        type: this.$route.params.type,
        employeeId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.deptId,
        deptLevel: this.$route.params.deptLevel,
        empList: this.$route.params.empList,
        adminAdd: this.$route.params.adminAdd || 0,
        formId: this.$route.params.formId,
        scoreGroupId: this.$route.params.formId,
        back: this.$route.params.back,
        searchData: this.$route.params.searchData,
      },
      view: this.$route.params.back === "SignHistory",
      person: this.global.getPerson(this.$route.params.type),
      id: this.$route.params.deptId,
      userData: {},
      permission: {},
      scoreDatas: [],
      scoreData: {},
      titles: [],
      datas: [],
      data: {},
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasAll = !this.view;
    this.hasEdit = this.hasAll;
    this.getDataList();
  },
  methods: {
    getDataList() {
      if (this.id) {
        api.okr.score.min.queryAll(this.addData).then((res) => {
          this.data = res.data;
          if (res.data.status === "1") {
            if (res.data.result) {
              this.titles = res.data.result.empList || [];
              this.datas = res.data.result.scoreList || [];
              this.data = res.data.result.group || this.datas[0];
              this.total = res.data.result.total;
              this.hasHR = this.permission.isHR && this.data.deptId !== "132";
              this.hasExcept = this.permission.isHR && this.person.level === 9;
              this.hasEdit =
                this.hasEdit &&
                (this.$route.params.back === "Sign" ||
                  this.hasExcept ||
                  (this.data.scoreStatus <= 0 &&
                    this.userData.user.employeeId === this.data.employeeId));
              this.hasView =
                this.view ||
                this.hasEdit ||
                this.hasHR ||
                this.permission.hasOver ||
                this.userData.user.employeeId === this.data.employeeId ||
                (this.permission.deptIds.includes(this.data.deptId) &&
                  this.userData.user.employeeId ===
                    this.userData.user.deptManagerId);
              this.getScoreDataList();
            }
          }
        });
      } else {
        this.loading = false;
      }
    },
    getScoreDataList() {
      this.data.monthStatus = this.hasView ? 1 : 0;
      api.okr.score.min.monthQueryAll(this.data).then((res) => {
        if (res.data.status === "1") {
          if (res.data.result) {
            this.scoreDatas = res.data.result.datas || [];
          }
          this.updateDataList();
        }
      });
    },
    updateDataList() {
      this.datas.forEach((data) => {
        data.checked = data.noCount ? 1 : 0;
        setTimeout(() => {
          data.checked = data.noCount ? true : false;
          data.disabled = true;
          this.$forceUpdate();
        }, 300);
      });
      this.loading = false;
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.goal.newDelete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    showContent(data) {
      this.addData.objectiveType = 1;
      this.addData.hasHR = this.hasHR;
      this.addData.userData = this.userData;
      this.addData.permission = this.permission;
      this.addData.deptId = this.id || this.userData.user.deptId;
      this.$refs.deptContent.showContent(data, this.addData);
    },
    showScoreContent(data) {
      data.hasHR = this.hasHR;
      data.userData = this.userData;
      data.permission = this.permission;
      data.deptLevel = this.addData.deptLevel;
      data.scoreGroupId = data.scoreGroupId || this.data.scoreGroupId;
      this.$refs.scorePersonalContent.showContent(data);
    },
    showFinalScoreContent(data) {
      data.userData = this.userData;
      data.permission = this.permission;
      this.$refs.scoreDeptContent.showContent(data);
    },
  },
};
</script>
