<template>
  <div id="ActivityAllList">
    <section class="content-header">
      <h1>活動行事曆</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li class="active">活動行事曆</li>
      </ol>
    </section>
    <section class="content activity-alllist">
      <h2 class="sr-only">活動行事曆</h2>
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-col5">
            <button
              @click="goActivityCalendar()"
              class="btn btn-block btn-success btn-add"
              type="button"
            >
              <i class="fa fa-calendar"></i> 行事曆
            </button>
            <div class="col-5">
              <div class="select-group">
                <select class="form-control" id="selectYear" v-model="years">
                  <option
                    :key="index"
                    :value="2019 + year"
                    v-for="(year, index) in yearRange"
                    >{{ 2019 + year }}</option
                  >
                </select>
              </div>
              <div class="select-group">
                <select class="form-control" id="selectMonth" v-model="months">
                  <option value="01">01月</option>
                  <option value="02">02月</option>
                  <option value="03">03月</option>
                  <option value="04">04月</option>
                  <option value="05">05月</option>
                  <option value="06">06月</option>
                  <option value="07">07月</option>
                  <option value="08">08月</option>
                  <option value="09">09月</option>
                  <option value="10">10月</option>
                  <option value="11">11月</option>
                  <option value="12">12月</option>
                </select>
              </div>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  @change="queryAllKinds"
                  class="form-control"
                  id="selectType"
                  v-model="searchDatas.typeId"
                >
                  <option value="-1">所有活動類別</option>
                  <option
                    :key="index"
                    :value="item.typeId"
                    v-for="(item, index) in activityTypes"
                    >{{ item.typeName }}</option
                  >
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectName"
                  v-model="searchDatas.kindId"
                >
                  <option value="-1">所有活動項目</option>
                  <option
                    :key="index"
                    :value="item.kindId"
                    v-for="(item, index) in activityKinds"
                    >{{ item.kindName }}</option
                  >
                </select>
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="搜尋活動名稱"
                type="text"
                v-model="searchDatas.keyword"
              />
              <div class="input-group-btn">
                <button
                  @click="queryAll(0)"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">活動類別</th>
                  <th>活動項目</th>
                  <th>活動名稱</th>
                  <th>活動時間</th>
                  <th>活動地點</th>
                  <th>聯絡人</th>
                  <th class="text-center">是否報名</th>
                  <th>附件</th>
                  <th class="text-center" style="min-width: 200px">功能</th>
                </tr>
                <tr :key="index" v-for="(item, index) in activitys">
                  <td>{{ item.typeName }}</td>
                  <td>{{ item.kindName }}</td>
                  <td>{{ item.activityName }}</td>
                  <td>
                    {{ getDuration(item.activitySTime, item.activityETime) }}
                  </td>
                  <td>{{ item.location }}</td>
                  <td>{{ item.contacter }}</td>
                  <td class="text-center">{{item.joinStatus >= 0?"是":"否"}}</td>
                  <td class="text-center">
                    <a
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      :key="file.id"
                      :title="file.name"
                      class="file-icon"
                      v-for="file in item.files"
                      v-show="item.files.length > 0"
                    >
                      <i :class="'icon icon-' + file.mine.substring(0, 3)"></i>
                    </a>
                    <span v-show="item.files.length == 0">-</span>
                  </td>
                  <td class="text-center">
                    <button
                      @click="openActivityBooking(item)"
                      class="btn btn-primary mr-10"
                    >
                      詳細內容
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <activityBooking ref="activityBooking" v-on:refresh="queryAll(0)" />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import activityBooking from "@/views/Activity/ActivityBooking";
import date_fns from "date-fns";

export default {
  components: {
    DatePicker,
    activityBooking
  },
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      total: 0,
      activityTypes: [],
      activityKinds: [],
      activitys: [],
      yearRange: 101,
      years: date_fns.getYear(new Date()), //年份
      months: this.formatTime(new Date(), "M"), //月份
      days: 31,
      searchDatas: {
        typeId: "-1",
        kindId: "-1",
        keyword: "",
        startTime: this.formatTime(new Date(), "YYYY-MM"),
        endTime: this.formatTime(new Date(), "YYYY-MM")
      }
    };
  },
  created() {
    if (this.$route.params.searchDatas != undefined) {
      this.searchDatas = this.$route.params.searchDatas;
      this.months = this.$route.params.months;
      this.years = this.$route.params.years;
    }
    this.queryAll(0);
    this.queryAllTypes();
    this.queryAllKinds();
  },
  methods: {
    queryAll(add) {
      if (
        date_fns.isBefore(
          date_fns.addMonths(this.years + "-" + this.months, add)
        )
      )
        return;

      let tempDate = this.formatTime(
        date_fns.addMonths(this.years + "-" + this.months, add),
        "YYYY-MM"
      );

      this.months = this.formatTime(tempDate, "MM");

      this.years = date_fns.getYear(tempDate);

      this.searchDatas.startTime = this.formatTime(
        this.years + "-" + this.months,
        "YYYY-MM-01"
      );
      this.days = date_fns.getDaysInMonth(this.searchDatas.startTime);
      this.searchDatas.endTime = this.formatTime(
        this.searchDatas.startTime,
        "YYYY-MM-" + this.days
      );
      api.activity.queryAll(this.searchDatas).then(res => {
        if (res.data.status == "1") {
          this.total = res.data.result.total;
          this.activitys = res.data.result.datas;
        }
      });
    },
    async queryAllTypes() {
      // 活動類別
      let activityTypes = await api.activity.queryAllType({
        isStop: 0
      });
      this.activityTypes = activityTypes.data.result.datas;
    },
    async queryAllKinds() {
      // 活動類別細項
      this.searchDatas.kindId = "-1";
      let activityKinds = await api.activity.queryAllManagerKind({
        typeId: this.searchDatas.typeId,
        isStop: 0
      });
      this.activityKinds = activityKinds.data.result.datas;
    },
    getDuration(start, end) {
      if (moment(start).isSame(end, "day")) {
        return (
          utils.formatTime(start, "YYYY-MM-DD") +
          " " +
          utils.formatTime(start, "HH:mm") +
          "~" +
          utils.formatTime(end, "HH:mm")
        );
      } else {
        return (
          utils.formatTime(start, "YYYY-MM-DD HH:mm") +
          "~" +
          utils.formatTime(end, "YYYY-MM-DD HH:mm")
        );
      }
    },
    chkCancelBtn(bookingETime) {
      return new moment().isBefore(bookingETime);
    },
    openActivityBooking(item) {
      this.$refs.activityBooking.showActivityBooking(item);
    },
    goActivityCalendar() {
      this.$router.push({
        name: "ActivityCalendar",
        params: {
          months: this.months,
          years: this.years,
          searchDatas: this.searchDatas
        }
      });
    },
    formatTime: function(t, format) {
      return date_fns.format(t, format);
    }
  }
};
</script>
