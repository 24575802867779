var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ScoreDeptEdit" } },
    [
      _c(
        "div",
        {
          staticClass: "table-responsive",
          staticStyle: { "max-width": "1400px", margin: "auto" },
        },
        [
          _vm.hasEdit &&
          (_vm.permission.isDeptManager || _vm.person.level === 9)
            ? _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    staticStyle: { float: "right", margin: "10px 0px" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.showContent()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\n        新增目標\n      "),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "table",
            {
              staticClass: "table table-hover table-bordered min-800",
              staticStyle: { margin: "5px auto" },
            },
            [
              _c(
                "tbody",
                [
                  _c(
                    "tr",
                    { staticClass: "bg-gray" },
                    [
                      _c("th", { staticClass: "tc" }, [_vm._v("#")]),
                      _c("th", { staticClass: "tc" }, [_vm._v("目標種類")]),
                      _c("th", { staticClass: "tc mw150" }, [
                        _vm._v("部級目標"),
                      ]),
                      _c("th", { staticClass: "tc mw150" }, [
                        _vm._v("目標說明"),
                      ]),
                      _vm._l(_vm.titles, function (title, index) {
                        return [
                          _vm.hasView
                            ? _c("th", {
                                key: index,
                                staticClass: "tc pre-wrap",
                                domProps: {
                                  textContent: _vm._s(
                                    `${title.employeeName}\n${
                                      index === 0
                                        ? "（自評）"
                                        : title.isManager === "0" &&
                                          title.deptType.includes("0060")
                                        ? "（上層主管）"
                                        : title.isManager === "1" &&
                                          title.deptType.includes("0060")
                                        ? "（部門主管）"
                                        : "（高層主管）"
                                    }`
                                  ),
                                },
                              })
                            : _vm._e(),
                          _c("th", {
                            key: title.employeeId,
                            staticClass: "tc pre-wrap",
                            domProps: {
                              textContent: _vm._s(
                                `${
                                  !_vm.hasView ? `${title.employeeName}\n` : ""
                                }${
                                  _vm.data.employeeId === title.employeeId
                                    ? "說明"
                                    : "考核意見"
                                }`
                              ),
                            },
                          }),
                        ]
                      }),
                      _c("th", { staticClass: "tc" }, [_vm._v("不列入考核")]),
                      _vm.hasEdit
                        ? _c("th", { staticClass: "tc mw80" }, [_vm._v("管理")])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.loading
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "tc",
                            attrs: { colspan: 6 + 2 * _vm.titles.length },
                          },
                          [_vm._v("查詢中")]
                        ),
                      ])
                    : _vm.datas.length === 0
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "tc",
                            attrs: { colspan: 6 + 2 * _vm.titles.length },
                          },
                          [_vm._v("尚無設定目標")]
                        ),
                      ])
                    : _vm._l(_vm.datas, function (data, index) {
                        return _c(
                          "tr",
                          { key: index },
                          [
                            data.objectiveType !== 2
                              ? _c("td", {
                                  staticClass: "tc",
                                  domProps: { textContent: _vm._s(index + 1) },
                                })
                              : _vm._e(),
                            _c("td", {
                              staticClass: "tc",
                              attrs: {
                                colspan: data.objectiveType === 2 ? 4 : 0,
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.define.objectiveType[data.objectiveType]
                                ),
                              },
                            }),
                            data.objectiveType !== 2
                              ? _c("td", {
                                  staticClass: "pre-wrap",
                                  domProps: {
                                    textContent: _vm._s(data.objective),
                                  },
                                })
                              : _vm._e(),
                            data.objectiveType !== 2
                              ? _c("td", {
                                  staticClass: "pre-wrap",
                                  domProps: {
                                    textContent: _vm._s(data.note || "-"),
                                  },
                                })
                              : _vm._e(),
                            _vm._l(data.scores, function (scoreItem, index) {
                              return [
                                _vm.hasView
                                  ? _c("td", {
                                      key: index,
                                      staticClass: "tc",
                                      domProps: {
                                        textContent: _vm._s(
                                          scoreItem.score ||
                                            scoreItem.totalScore ||
                                            0
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _c("td", {
                                  key: scoreItem.scoreId,
                                  staticClass: "pre-wrap",
                                  domProps: {
                                    textContent: _vm._s(scoreItem.note),
                                  },
                                }),
                              ]
                            }),
                            data.objectiveType === 2
                              ? _c("td", { staticClass: "tc" }, [_vm._v("－")])
                              : _c(
                                  "td",
                                  { staticClass: "tc" },
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propNone: true,
                                        propChecked: data.checked,
                                        propDisabled: data.disabled,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                            _vm.hasEdit && data.objectiveType === 2
                              ? _c("td", { staticClass: "tc" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showFinalScoreContent(data)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              評分\n            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm.hasEdit
                              ? _c("td", { staticClass: "tc" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showScoreContent(data)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              評分\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: data.objectiveType,
                                          expression: "data.objectiveType",
                                        },
                                      ],
                                    },
                                    [_vm._v(" ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: data.objectiveType,
                                          expression: "data.objectiveType",
                                        },
                                      ],
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteData(data)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              刪除\n            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _c("DeptContent", {
        ref: "deptContent",
        on: { updateList: _vm.getDataList },
      }),
      _c("ScoreDeptContent", {
        ref: "scoreDeptContent",
        on: { updateList: _vm.getDataList },
      }),
      _c("ScorePersonalContent", {
        ref: "scorePersonalContent",
        on: { updateList: _vm.getDataList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }