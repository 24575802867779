<template>
  <!-- 餐點評比 -->
  <div class="modal fade food-score" ref="food_score">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">餐點評比</h4>
        </div>
        <div class="modal-body">
          <h4>店家名稱: {{ meal.storeName }}</h4>
          <div class="score-info">
            <div class="pic imgFill">
              <!-- 430 * 330 -->
              <img
                :key="index"
                :src="
                  meal.files.length > 0
                    ? 'https://dev-eip.wanin.tw/' + file.filesUrl
                    : ''
                "
                draggable="false"
                v-for="(file, index) in meal.files"
              />
            </div>

            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="bg-gray-light">餐點名稱</td>
                    <td
                      class="bg-gray-light text-center"
                      v-if="!admin"
                      style="min-width: 150px"
                    >
                      個人評分
                    </td>
                    <td class="bg-gray-light text-center text-nowrap">
                      平均評分
                    </td>
                  </tr>
                  <tr>
                    <td>{{ meal.mealName }}</td>
                    <td class="text-center" v-if="!admin">
                      <div class="score-adjust">
                        <button class="btn" ref="btn_minus" type="button">
                          <i class="fa fa-minus"></i>
                        </button>
                        <input
                          class="form-control"
                          type="text"
                          v-model="editComment.score"
                          readonly
                        />
                        <button class="btn" ref="btn_plus" type="button">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </td>
                    <td class="text-center">
                      {{
                        meal.averageScore == null
                          ? "尚未評分"
                          : meal.averageScore
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-10">
            <div class="form-group" v-if="!admin">
              <label class="control-label" for="inputFoodScore"
                >個人評論(實名制，上限200字)：</label
              >
              <textarea
                class="form-control"
                id="inputFoodScore"
                maxlength="200"
                placeholder="請輸入您的評論"
                rows="4"
                v-model="editComment.note"
                :disabled="onlyRead"
              ></textarea>
            </div>
            <p v-if="admin">所有評論(實名制)：</p>
            <div class="feedback" v-if="admin">
              <table class="table table-bordered">
                <tbody>
                  <tr :key="index" v-for="(comment, index) in allComment.datas">
                    <td class="text-nowrap" style="width: 10%">
                      {{ comment.employeeName }}
                    </td>
                    <td>{{ comment.note }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            ref="food_score_save"
            type="button"
            v-if="!admin && !onlyRead"
            data-dismiss="modal"
            v-on:click="updateComment"
          >
            送出評比
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
// import utils from "@/assets/js/app-utils"; 沒使用到

export default {
  components: {},
  data() {
    return {
      meal: "",
      eatDay: "",
      editComment: {
        mealId: "",
        eatDay: "",
        employeeId: this.$store.state.loginUser.employeeId,
        score: 0,
        note: "",
      },
      allComment: {},
      admin: false,
      onlyRead: false,
    };
  },
  mounted() {
    $(this.$refs.btn_plus).click(() => {
      if (this.editComment.score == 5 || this.onlyRead) return;
      this.editComment.score++;
    });

    $(this.$refs.btn_minus).click(() => {
      if (this.editComment.score == 0 || this.onlyRead) return;
      this.editComment.score--;
    });
  },
  methods: {
    showMealScoreBox(order, admin = false, onlyRead = false) {
      this.eatDay = order.eatDay;
      this.admin = admin;
      this.onlyRead = onlyRead;

      if (!admin) {
        const mealId = order.mealId;
        this.getMealComment(mealId);
        this.getMealInfo(mealId);

        // $(this.$refs.food_score_save).click(() => {
        //   $(this.$refs.food_score_save).off("click");
        //   $(this.$refs.food_score).modal("hide");
        //   this.updateComment();
        // });
      } else {
        const mealId = order;
        this.getMealAllComment(mealId);
        this.getMealInfo(mealId);
      }

      $(this.$refs.food_score).modal("show");
    },
    getMealAllComment(mealId) {
      api.mealComment
        .queryAll({
          mealId: mealId,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.allComment = JSON.parse(JSON.stringify(res.data.result));
          }
        });
    },
    getMealComment(mealId) {
      api.mealComment
        .employeeQuery({
          mealId: mealId,
          employeeId: this.editComment.employeeId,
        })
        .then((res) => {
          if (res.data.status == "1" && res.data.result != null) {
            this.editComment = JSON.parse(JSON.stringify(res.data.result));
          } else if (res.data.result == null || res.data.status != "1") {
            this.editComment = {
              mealId: mealId,
              employeeId: this.$store.state.loginUser.employeeId,
              score: 0,
              note: "",
            };
          }
        });
    },
    getMealInfo(mealId) {
      api.storeMenu
        .query({
          mealId: mealId,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.meal = JSON.parse(JSON.stringify(res.data.result));
          }
        })
        .finally(() => {
          // imgLiquid
          $(".jqimgFill").imgLiquid();
        });
    },
    updateComment() {
      this.editComment.eatDay = this.eatDay;
      api.mealComment.save(this.editComment).then((res) => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        }

        this.$emit("refreshList");
      });
    },
  },
};
</script>
