var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ActivityEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("社團活動")]),
        _c("li", [_vm._v("活動設定")]),
        _c("li", [_vm._v("活動列表管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("編輯活動")]),
      ]),
    ]),
    _c("section", { staticClass: "content activity-edit" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯活動"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-5" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.typeId,
                          expression: "edit.typeId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectType" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "typeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.changeType,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v("請選擇活動類別"),
                      ]),
                      _vm._l(_vm.activityTypes, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.typeId } },
                          [_vm._v(_vm._s(item.typeName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "col-sm-5" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.kindId,
                          expression: "edit.kindId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectName" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "kindId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v("請選擇活動項目"),
                      ]),
                      _vm._l(_vm.activityKinds, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.kindId } },
                          [_vm._v(_vm._s(item.kindName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.activityName,
                        expression: "edit.activityName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputTitle",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.edit.activityName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "activityName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("p", { staticClass: "mt-7" }, [_vm._v("開始時間：")]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "input-group date" },
                        [
                          _vm._m(3),
                          _c("DatePicker", {
                            attrs: { propValue: _vm.activitySTime },
                            model: {
                              value: _vm.activitySTime,
                              callback: function ($$v) {
                                _vm.activitySTime = $$v
                              },
                              expression: "activitySTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._m(4),
                  ]),
                  _c("p", { staticClass: "mt-10" }, [_vm._v("截止時間：")]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "input-group date" },
                        [
                          _vm._m(5),
                          _c("DatePicker", {
                            attrs: { propValue: _vm.activityETime },
                            model: {
                              value: _vm.activityETime,
                              callback: function ($$v) {
                                _vm.activityETime = $$v
                              },
                              expression: "activityETime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._m(6),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(7),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.location,
                        expression: "edit.location",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputPlace",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.edit.location },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "location", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(8),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.contacter,
                        expression: "edit.contacter",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "Contacter",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.edit.contacter },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "contacter", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(9),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "radio" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "不限制人數",
                          name: "radioLimit",
                        },
                        model: {
                          value: _vm.edit.maxLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "maxLimit", $$v)
                          },
                          expression: "edit.maxLimit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "radio-select" }, [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "限制人數：",
                            name: "radioLimit",
                          },
                          model: {
                            value: _vm.edit.maxLimit,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "maxLimit", $$v)
                            },
                            expression: "edit.maxLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.maxCount,
                            expression: "edit.maxCount",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "Contacter",
                          required: "required",
                          type: "number",
                        },
                        domProps: { value: _vm.edit.maxCount },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "maxCount", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(10),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "radio" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "開放全公司報名",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.isLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "isLimit", $$v)
                          },
                          expression: "edit.isLimit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "radio-select" }, [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "對象範圍：",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isLimit,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isLimit", $$v)
                            },
                            expression: "edit.isLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "select-group" },
                      [
                        _c("select2-multiple", {
                          attrs: {
                            options: _vm.deptOptions,
                            "data-placeholder": "請選擇對象",
                          },
                          model: {
                            value: _vm.depts,
                            callback: function ($$v) {
                              _vm.depts = $$v
                            },
                            expression: "depts",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(11),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("p", { staticClass: "mt-7" }, [_vm._v("開始時間：")]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "input-group date" },
                        [
                          _vm._m(12),
                          _c("DatePicker", {
                            attrs: { propValue: _vm.bookingSTime },
                            model: {
                              value: _vm.bookingSTime,
                              callback: function ($$v) {
                                _vm.bookingSTime = $$v
                              },
                              expression: "bookingSTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._m(13),
                  ]),
                  _c("p", { staticClass: "mt-10" }, [_vm._v("截止時間：")]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "input-group date" },
                        [
                          _vm._m(14),
                          _c("DatePicker", {
                            attrs: { propValue: _vm.bookingETime },
                            model: {
                              value: _vm.bookingETime,
                              callback: function ($$v) {
                                _vm.bookingETime = $$v
                              },
                              expression: "bookingETime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._m(15),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(16),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "editor" },
                    [
                      _c("tinymce", {
                        model: {
                          value: _vm.edit.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "note", $$v)
                          },
                          expression: "edit.note",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "inputFile" },
                  },
                  [_vm._v("上傳檔案：")]
                ),
                _c("div", { staticClass: "col-sm-10 text" }, [
                  _c("input", {
                    attrs: {
                      accept:
                        ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip",
                      id: "inputFile",
                      maxlength: "5",
                      name: "file1",
                      type: "file",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeFile($event)
                      },
                    },
                  }),
                  _vm._m(17),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "inputFile" },
                  },
                  [_vm._v("已有檔案：")]
                ),
                _c("div", { staticClass: "col-sm-10 text" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.edit.files, function (file, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(file.name))]),
                          _vm.edit.status != 9
                            ? _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFile(file.applyId)
                                      },
                                    },
                                  },
                                  [_vm._v("刪除")]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                !_vm.editor || _vm.edit.status == 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.save(0)
                          },
                        },
                      },
                      [_vm._v("儲存為草稿")]
                    )
                  : _vm._e(),
                _vm.edit.status != 9 && _vm.editor
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v("儲存")]
                    )
                  : _vm._e(),
                _vm.edit.status == 0 || !_vm.editor
                  ? _c(
                      "button",
                      {
                        staticClass: "btn bg-purple",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.save(1)
                          },
                        },
                      },
                      [_vm._v("發佈")]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動類別：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動名稱：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "inputDateRange" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動時間：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "input-group time" }, [
        _c("div", { staticClass: "input-group-addon" }, [
          _c("i", { staticClass: "fa fa-clock-o" }),
        ]),
        _c("input", {
          staticClass: "form-control timepicker",
          attrs: { id: "activitySTime", type: "text" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "input-group time" }, [
        _c("div", { staticClass: "input-group-addon" }, [
          _c("i", { staticClass: "fa fa-clock-o" }),
        ]),
        _c("input", {
          staticClass: "form-control timepicker",
          attrs: { id: "activityETime", type: "text" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputPlace" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動地點：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "Contacter" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 聯絡人：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "MaxLimit" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 人數限制：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "selectCompany" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 報名對象：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "inputDateRange" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 報名期間：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "input-group time" }, [
        _c("div", { staticClass: "input-group-addon" }, [
          _c("i", { staticClass: "fa fa-clock-o" }),
        ]),
        _c("input", {
          staticClass: "form-control timepicker",
          attrs: { id: "bookingSTime", type: "text" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "input-group time" }, [
        _c("div", { staticClass: "input-group-addon" }, [
          _c("i", { staticClass: "fa fa-clock-o" }),
        ]),
        _c("input", {
          staticClass: "form-control timepicker",
          attrs: { id: "bookingETime", type: "text" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 備註：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _vm._v("\n                  文件檔案上傳格式：\n                  "),
      _c("span", { staticClass: "text-red" }, [_vm._v("(上限20MB)")]),
      _c("br"),
      _vm._v("1.WORD：DOC、DOCX。\n                  "),
      _c("br"),
      _vm._v("2.EXCEL：XLS、XLSX。\n                  "),
      _c("br"),
      _vm._v("3.POWERPOINT：PPT、PPTX。\n                  "),
      _c("br"),
      _vm._v("4.PDF。\n                  "),
      _c("br"),
      _vm._v("5.TXT。\n                  "),
      _c("br"),
      _vm._v("6.XPS。\n                  "),
      _c("br"),
      _vm._v("7.ZIP。\n                  "),
      _c("br"),
      _vm._v("(每個檔案上限20MB，最多可以選3個檔案)\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }