<template>
  <div id="CourseList">
    <section class="content-header">
      <h1>{{ courseName }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">{{ courseName }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isFinished"
              >
                <option
                  v-for="(item, index) in classList.finishedType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.value == "" ? "全部狀態" : item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 5%">#</th>
                  <th class="text-nowrap" style="width: 15%; min-width: 180px">
                    課程名稱
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    課程狀態
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    剩餘時數/成績
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    建立時間
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    功能
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="isNoData">
                  <tr>
                    <td class="text-center" colspan="6">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <template v-if="list.length > 0">
                    <tr
                      v-for="(data, index) in list"
                      v-show="checkFinishedShow(data)"
                      :key="index"
                    >
                      <td class="text-center">
                        {{
                          (searchData.pageNum - 1) * searchData.pageSize +
                          index +
                          1
                        }}
                      </td>
                      <td>{{ data.chapterName }}</td>
                      <td class="text-center">
                        {{ data.isFinished == 1 ? "已完成" : "未完成" }}
                      </td>
                      <td class="text-center">
                        {{ convertTimeToMinutes(data.leftLearnTime) }}
                      </td>
                      <td class="text-center">
                        {{ data.createTime | dateFormat() }}
                      </td>
                      <td class="text-center">
                        <button class="btn btn-warning" @click="goDetail(data)">
                          上課
                        </button>
                      </td>
                    </tr>
                  </template>
                  <tr
                    v-if="testData.testId"
                    v-show="checkFinishedShow(testData)"
                  >
                    <td class="text-center">{{ list.length + 1 }}</td>
                    <td>課程測驗</td>
                    <td class="text-center">
                      {{ testData.isFinished == 1 ? "已完成" : "未完成" }}
                    </td>
                    <td class="text-center">{{ Number(testData.score) }}分</td>
                    <td class="text-center">
                      {{ testData.createTime | dateFormat() }}
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-warning"
                        @click="goDetail(testData, 'test')"
                      >
                        測驗
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        employeeId: "",
        courseId: this.$route.params.id, // 課程id
        // chapterId: "20240124111048453", // 章節id
        // chapterName: "設計", // 章節名稱
        isFinished: "",
        chapterStatus: 1, // 章節狀態 0：停用、1：啟用
        isDel: 0, // 是否刪除 0：啟用、1：刪除
        order: 1, // 排序 1：ChapterOrder 小到大
        pageNum: 1, // 頁碼
        pageSize: 999, // 頁數
      },
      classList: {},
      courseName: this.$route.params.name,
      list: [],
      testData: {},
      isNoData: true,
    };
  },
  async created() {
    this.searchData.employeeId = this.$user.getUserData().user.employeeId;
    if (this.$route.params.chaterSearchData != undefined) {
      this.searchData = this.$route.params.chaterSearchData;
    }
    this.getClassList();
    this.getDataList();
  },
  methods: {
    getClassList() {
      api.education.class(this.searchData).then(({ data }) => {
        this.classList = data.result;
      });
    },
    getDataList() {
      this.loading = true;
      api.education.chapter.queryAll(this.searchData).then((res) => {
        this.list = res.data.result.datas;
        if (res.data.status == 1) {
          if (this.list[0]) {
            this.courseName = this.list[0].courseName;
          }
          this.testData = res.data.result.testData;
          this.isNoData = this.list.length == 0 && !this.testData.testId;
        } else {
          this.isNoData = true;
        }
        this.loading = false;
        this.$forceUpdate();
      });
    },
    goDetail(item, type) {
      if (!item) return;
      const params = {
        id: this.searchData.courseId,
        name: this.$route.params.name,
        type: this.$route.params.type,
        searchData: this.$route.params.searchData,
        chapterId: item.chapterId,
        chapterName: item.chapterName,
        chaterSearchData: this.chaterSearchData,
      };

      if (type == "test" && item.testId) {
        this.$router.push({
          name: "ETStudentCourseDetail",
          params: params,
          query: {
            quiz: item.testId,
          },
        });
      } else {
        this.$router.push({
          name: "ETStudentCourseDetail",
          params: params,
        });
      }
    },
    back() {
      this.$router.push({
        name: "ETStudentCourseList",
        params: {
          type: this.$route.params.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
    convertTimeToMinutes(time) {
      const parts = time.split(":");
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const second = parseInt(parts[2], 10);
      const totalMinutes = hours * 60 + minutes;
      let finalString = 0;
      if (totalMinutes > 0 && second > 0) {
        finalString = `${totalMinutes}分 ${second}秒`;
      } else {
        if (second > 0) finalString = `${second}秒鐘`;
        else finalString = `${totalMinutes}分鐘`;
      }
      return finalString;
    },
    checkFinishedShow(data) {
      if (
        this.searchData.isFinished == "" ||
        (this.searchData.isFinished != "" &&
          this.searchData.isFinished == data.isFinished)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
