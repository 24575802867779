var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CompanyFilesEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("公司文件")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公司公告")]),
        _c("li", { staticClass: "active" }, [_vm._v("公司文件")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "box box-warning company-files-edit" },
        [
          _c("div", { staticClass: "box-header with-border" }, [
            _c("h3", {
              staticClass: "box-title",
              domProps: { textContent: _vm._s(_vm.pageTitle) },
            }),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c(
                "div",
                { staticClass: "box-body" },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.fileDatas.typeId,
                              expression: "fileDatas.typeId",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectType", required: "required" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.fileDatas,
                                "typeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.fileTypes, function (type, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: type.tId } },
                            [_vm._v(_vm._s(type.typeName))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("CompanyList", {
                              attrs: {
                                propCompanyIdx: _vm.fileDatas.issuedCompanyId,
                              },
                              on: {
                                "update:companyIdx": function ($event) {
                                  _vm.fileDatas.issuedCompanyId = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 unit" },
                          [
                            _c("DepartmentList", {
                              attrs: {
                                propCompanyIdx: _vm.fileDatas.issuedCompanyId,
                                propDepartmentIdx: _vm.fileDatas.issuedUnit,
                              },
                              on: {
                                "update:departmentIdx": function ($event) {
                                  _vm.fileDatas.issuedUnit = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasSelectTargetPermission,
                          expression: "hasSelectTargetPermission",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "col-sm-10" },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.fileDatas.target,
                                  expression: "fileDatas.target",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { required: "required" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.fileDatas,
                                    "target",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "0" } }, [
                                _vm._v("網銀國際"),
                              ]),
                              _vm._l(_vm.issueTargets, function (item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: item.targetId },
                                  },
                                  [_vm._v(_vm._s(item.targetName))]
                                )
                              }),
                            ],
                            2
                          ),
                          _c("ICheckCheckBox", {
                            attrs: {
                              message:
                                "不開放其他公司瀏覽(選「網銀國際」時此欄位唯讀)",
                              propDisabled: _vm.fileDatas.target == "0",
                              value: _vm.fileDatas.isTargetOnly,
                            },
                            on: {
                              "update:checked": function ($event) {
                                _vm.fileDatas.isTargetOnly = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fileDatas.documentName,
                            expression: "fileDatas.documentName",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                        },
                        domProps: { value: _vm.fileDatas.documentName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.fileDatas,
                              "documentName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("CompanyFileUploader", {
                    attrs: { propFileId: _vm.fileDatas.tId },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(_vm._s(_vm.isCreateMode ? "新增" : "儲存"))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "CompanyFiles",
                            params: {
                              searchData: _vm.$route.params.searchData,
                            },
                          })
                        },
                      },
                    },
                    [_vm._v("返回列表")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("AlertBox", { ref: "alertBox" }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 文件分類：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "selectUnit" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 歸屬單位：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "selectCompany" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告對象：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 文件名稱：\n              "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }