var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ExpatList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("人員派駐設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("人員派駐設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content seating" }, [
      _c(
        "div",
        { staticClass: "box box-warning", staticStyle: { width: "1050px" } },
        [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.createData()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\n              新增設定\n            "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", {
                  staticClass: "data-num",
                  domProps: { textContent: _vm._s(`資料總筆數：${_vm.total}`) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "7" } },
                              [_vm._v("查詢中")]
                            ),
                          ])
                        : _vm.datas.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "7" } },
                              [_vm._v("查詢無任何資料")]
                            ),
                          ])
                        : _vm._l(_vm.datas, function (data, index) {
                            return _c("tr", { key: index }, [
                              _c("td", {
                                staticClass: "tc",
                                domProps: { textContent: _vm._s(index + 1) },
                              }),
                              data.isCreate
                                ? _c("td", { staticClass: "tc" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: data.deptId,
                                            expression: "data.deptId",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        staticStyle: { width: "fit-content" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                data,
                                                "deptId",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              return _vm.getUserList(data)
                                            },
                                          ],
                                        },
                                      },
                                      _vm._l(_vm.list, function (item, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: item.deptId },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  item.companyName +
                                                    " - " +
                                                    item.deptName
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ])
                                : _c("td", { staticClass: "tc text-nowrap" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          data.companyName +
                                            " - " +
                                            data.deptName
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                              data.isCreate
                                ? _c("td", { staticClass: "tc" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: data.employeeId,
                                            expression: "data.employeeId",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              data,
                                              "employeeId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("人員"),
                                        ]),
                                        _vm._l(
                                          _vm.users,
                                          function (user, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value: user.employeeId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(user.username) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _c("td", {
                                    staticClass: "tc",
                                    domProps: {
                                      textContent: _vm._s(data.username),
                                    },
                                  }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(
                                    data.companyId === "J" ? "台北" : ""
                                  ),
                                },
                              }),
                              data.isEdit
                                ? _c("td", { staticClass: "tc" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: data.office,
                                            expression: "data.office",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              data,
                                              "office",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("辦公室"),
                                        ]),
                                        _vm._l(
                                          _vm.offices,
                                          function (item, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value: item.office,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.office) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _c("td", {
                                    staticClass: "tc",
                                    domProps: {
                                      textContent: _vm._s(data.office),
                                    },
                                  }),
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: { align: "center" },
                                },
                                [
                                  data.isEdit
                                    ? _c("p", [_vm._v("–")])
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ckbx-style-8 ckbx toggleInput",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: data.isLong,
                                                expression: "data.isLong",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "ckbx-style-8",
                                              id: `check${index}`,
                                              "false-value": 0,
                                              "true-value": 1,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                data.isLong
                                              )
                                                ? _vm._i(data.isLong, null) > -1
                                                : _vm._q(data.isLong, 1),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a = data.isLong,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked ? 1 : 0
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          data,
                                                          "isLong",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          data,
                                                          "isLong",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      data,
                                                      "isLong",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.toggle(data)
                                                },
                                              ],
                                            },
                                          }),
                                          _c("label", {
                                            attrs: { for: `check${index}` },
                                          }),
                                        ]
                                      ),
                                ]
                              ),
                              _c("td", { staticClass: "tc" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !data.isEdit,
                                        expression: "!data.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateData(data)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    修改\n                  "
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !data.isEdit,
                                        expression: "!data.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-danger ml10",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteData(data)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    刪除\n                  "
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: data.isEdit,
                                        expression: "data.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-success",
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveData(data)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    儲存\n                  "
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: data.isEdit,
                                        expression: "data.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-default ml10",
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelData(data)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    取消\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc" }, [_vm._v("#")]),
      _c("th", { staticClass: "tc", attrs: { width: "15%" } }, [
        _vm._v("單位"),
      ]),
      _c("th", { staticClass: "tc", attrs: { width: "15%" } }, [
        _vm._v("姓名"),
      ]),
      _c("th", { staticClass: "tc" }, [_vm._v("派駐單位")]),
      _c("th", { staticClass: "tc", attrs: { width: "15%" } }, [
        _vm._v("派駐辦公室"),
      ]),
      _c("th", { staticClass: "tc" }, [_vm._v("長期派駐")]),
      _c("th", { staticClass: "tc" }, [_vm._v("管理")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }