var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "YearEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("作業時間限制設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("作業時間限制設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _vm.loading
              ? _c("div", { staticClass: "box-body tc" }, [_vm._v("查詢中")])
              : _c(
                  "div",
                  {
                    staticClass: "box-body",
                    staticStyle: { "max-width": "1010px" },
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.timeYearId) +
                            "：\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeYearLimit,
                                    expression: "data.timeYearLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "1",
                                  name: _vm.data.timeYearId,
                                },
                                domProps: {
                                  checked: _vm._q(_vm.data.timeYearLimit, "1"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeYearLimit",
                                        "1"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeYearId,
                                        _vm.data.timeYearLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      限制\n                    "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeYearLimit,
                                    expression: "data.timeYearLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "0",
                                  name: _vm.data.timeYearId,
                                },
                                domProps: {
                                  checked: _vm._q(_vm.data.timeYearLimit, "0"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeYearLimit",
                                        "0"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeYearId,
                                        _vm.data.timeYearLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      開放\n                      "
                              ),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "\n                        【控管時間】全年度：\n                        " +
                                    _vm._s(_vm.data.timeYearS) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeYearE) +
                                    "\n                      "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.timeDivisionId) +
                            "：\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeDivisionLimit,
                                    expression: "data.timeDivisionLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "1",
                                  name: _vm.data.timeDivisionId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timeDivisionLimit,
                                    "1"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeDivisionLimit",
                                        "1"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeDivisionId,
                                        _vm.data.timeDivisionLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      限制\n                    "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeDivisionLimit,
                                    expression: "data.timeDivisionLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "0",
                                  name: _vm.data.timeDivisionId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timeDivisionLimit,
                                    "0"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeDivisionLimit",
                                        "0"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeDivisionId,
                                        _vm.data.timeDivisionLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      開放\n                      "
                              ),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "\n                        【控管時間】上半年：\n                        " +
                                    _vm._s(_vm.data.timeDivisionS1) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeDivisionE1) +
                                    "，下半年：\n                        " +
                                    _vm._s(_vm.data.timeDivisionS2) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeDivisionE2) +
                                    "\n                      "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.timeDeptId) +
                            "：\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeDeptLimit,
                                    expression: "data.timeDeptLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "1",
                                  name: _vm.data.timeDeptId,
                                },
                                domProps: {
                                  checked: _vm._q(_vm.data.timeDeptLimit, "1"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeDeptLimit",
                                        "1"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeDeptId,
                                        _vm.data.timeDeptLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      限制\n                    "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeDeptLimit,
                                    expression: "data.timeDeptLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "0",
                                  name: _vm.data.timeDeptId,
                                },
                                domProps: {
                                  checked: _vm._q(_vm.data.timeDeptLimit, "0"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeDeptLimit",
                                        "0"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeDeptId,
                                        _vm.data.timeDeptLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      開放\n                      "
                              ),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "\n                        【控管時間】上半年：\n                        " +
                                    _vm._s(_vm.data.timeDeptS1) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeDeptE1) +
                                    "，下半年：\n                        " +
                                    _vm._s(_vm.data.timeDeptS2) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeDeptE2) +
                                    "\n                      "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.timePersonalId) +
                            "：\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timePersonalLimit,
                                    expression: "data.timePersonalLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "1",
                                  name: _vm.data.timePersonalId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timePersonalLimit,
                                    "1"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timePersonalLimit",
                                        "1"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timePersonalId,
                                        _vm.data.timePersonalLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      限制\n                    "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timePersonalLimit,
                                    expression: "data.timePersonalLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "0",
                                  name: _vm.data.timePersonalId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timePersonalLimit,
                                    "0"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timePersonalLimit",
                                        "0"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timePersonalId,
                                        _vm.data.timePersonalLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      開放\n                      "
                              ),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "\n                        【控管時間】每個月：\n                        " +
                                    _vm._s(_vm.data.timePersonalS) +
                                    "日 ~\n                        " +
                                    _vm._s(_vm.data.timePersonalE) +
                                    "日，逾期最後開放：\n                        " +
                                    _vm._s(_vm.data.timePersonalF) +
                                    "日\n                      "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.timeScoreYId) +
                            "：\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeScoreYLimit,
                                    expression: "data.timeScoreYLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "1",
                                  name: _vm.data.timeScoreYId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timeScoreYLimit,
                                    "1"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeScoreYLimit",
                                        "1"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeScoreYId,
                                        _vm.data.timeScoreYLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      限制\n                    "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeScoreYLimit,
                                    expression: "data.timeScoreYLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "0",
                                  name: _vm.data.timeScoreYId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timeScoreYLimit,
                                    "0"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeScoreYLimit",
                                        "0"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeScoreYId,
                                        _vm.data.timeScoreYLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      開放\n                      "
                              ),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "\n                        【控管時間】上半年：\n                        " +
                                    _vm._s(_vm.data.timeScoreYS1) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeScoreYE1) +
                                    "，逾期最後開放：\n                        " +
                                    _vm._s(_vm.data.timeScoreYF1) +
                                    "；下半年：\n                        " +
                                    _vm._s(_vm.data.timeScoreYS2) +
                                    " ~\n                        " +
                                    _vm._s(_vm.data.timeScoreYE2) +
                                    "，逾期最後開放：\n                        " +
                                    _vm._s(_vm.data.timeScoreYF2) +
                                    "\n                      "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.timeScoreMId) +
                            "：\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeScoreMLimit,
                                    expression: "data.timeScoreMLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "1",
                                  name: _vm.data.timeScoreMId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timeScoreMLimit,
                                    "1"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeScoreMLimit",
                                        "1"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeScoreMId,
                                        _vm.data.timeScoreMLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      限制\n                    "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-inline" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.timeScoreMLimit,
                                    expression: "data.timeScoreMLimit",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "0",
                                  name: _vm.data.timeScoreMId,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.data.timeScoreMLimit,
                                    "0"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.data,
                                        "timeScoreMLimit",
                                        "0"
                                      )
                                    },
                                    function ($event) {
                                      return _vm.updateData(
                                        _vm.data.timeScoreMId,
                                        _vm.data.timeScoreMLimit
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      開放\n                      "
                              ),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "\n                        【控管時間】每個月：\n                        " +
                                    _vm._s(_vm.data.timeScoreMS) +
                                    "日 ~\n                        " +
                                    _vm._s(_vm.data.timeScoreME) +
                                    "日，逾期最後開放：\n                        " +
                                    _vm._s(_vm.data.timeScoreMF) +
                                    "日\n                      "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }