var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ActivityParticipants" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.activity.typeName) +
              "-" +
              _vm._s(_vm.activity.kindName) +
              "-" +
              _vm._s(_vm.activity.activityName) +
              "-參加人員\n    "
          ),
        ]),
        _c("p", { staticClass: "mt-10 text-red" }, [
          _vm._v(
            _vm._s(
              _vm.getDuration(
                _vm.activity.activitySTime,
                _vm.activity.activityETime
              )
            )
          ),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("社團活動")]),
          _c("li", [_vm._v("活動設定")]),
          _c("li", [_vm._v("活動列表管理")]),
          _c("li", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.title) + "-參加人員"),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content activity-participants" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box has-select" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-default btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.goBack()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-reply" }),
                    _vm._v(" 返回列表\n            "),
                  ]
                ),
                _vm.status != 9
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-warning btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openAddActivityMember()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" 新增人員\n            "),
                      ]
                    )
                  : _vm._e(),
                _vm.status != 9 && _vm.hasEditPermission
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-success btn-cog",
                        attrs: { type: "button" },
                        on: { click: _vm.checkFinish },
                      },
                      [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v(" 完成檢核\n            "),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "export-group" }, [
                _c(
                  "div",
                  { staticClass: "select-group" },
                  [
                    _c("select2Single", {
                      attrs: { options: _vm.depts, "data-placeholder": "全部" },
                      model: {
                        value: _vm.searchDatas.deptId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchDatas, "deptId", $$v)
                        },
                        expression: "searchDatas.deptId",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchDatas.keyword,
                        expression: "searchDatas.keyword",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: { placeholder: "搜尋姓名或工號", type: "text" },
                    domProps: { value: _vm.searchDatas.keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchDatas,
                          "keyword",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.queryAll },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-export",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportExcel()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-download" }),
                    _vm._v(" 匯出\n            "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數：" + _vm._s(_vm.total)),
                ]),
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("出席人數：" + _vm._s(_vm.activity.joinCountCheckin)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered min-800" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm._l(_vm.members, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.rowid)),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.deptName))]),
                        _c("td", [_vm._v(_vm._s(item.joinEmpId))]),
                        _c("td", [_vm._v(_vm._s(item.joinEmpName))]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.status,
                                  expression: "item.status",
                                },
                              ],
                              attrs: {
                                disabled: _vm.status == 9,
                                "false-value": 0,
                                id: "check" + index,
                                "true-value": 1,
                                name: "ckbx-style-8",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(item.status)
                                  ? _vm._i(item.status, null) > -1
                                  : _vm._q(item.status, 1),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.status,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "status",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "status",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "status", $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.save(item)
                                  },
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "check" + index } }),
                          ]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.managerAdd == 1
                            ? _c("i", {
                                staticClass: "fa fa-check text-yellow font20",
                              })
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.editor,
                                  expression: "!item.editor",
                                },
                              ],
                              staticClass: "m-0",
                            },
                            [_vm._v(_vm._s(item.note))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.note,
                                expression: "item.note",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.editor,
                                expression: "item.editor",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              placeholder: "填寫備註",
                              required: "required",
                              type: "text",
                            },
                            domProps: { value: item.note },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "note", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "min-width": "150px" },
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.editor && _vm.status != 9,
                                    expression: "!item.editor && status != 9",
                                  },
                                ],
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.change(item)
                                  },
                                },
                              },
                              [_vm._v("編輯")]
                            ),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.editor,
                                    expression: "item.editor",
                                  },
                                ],
                                staticClass: "btn btn-success btn-save",
                                on: {
                                  click: function ($event) {
                                    return _vm.save(item)
                                  },
                                },
                              },
                              [_vm._v("儲存")]
                            ),
                            _vm._v("\n                   \n                  "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.editor,
                                    expression: "item.editor",
                                  },
                                ],
                                staticClass: "btn btn-default",
                                on: {
                                  click: function ($event) {
                                    return _vm.queryAll()
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchDatas.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("addActivityMember", {
        ref: "addActivityMember",
        on: {
          refresh: function ($event) {
            return _vm.queryAll()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("編號")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("部門")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("工號")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("姓名")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("參加狀態")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("追加人員")]),
      _c("th", { staticStyle: { "min-width": "200px", width: "10%" } }, [
        _vm._v("備註"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }