<template>
  <div id="ScorePersonalEdit">
    <section class="content-header">
      <h1>
        {{ person.level === 4 ? "新進員工" : "工作績效" }}考核表
        {{ person.level === 4 ? "" : `(${person.text})` }}
      </h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link :to="`/OKR/ScorePersonalList/${person.type}`">
            {{ person.level === 4 ? "新進員工" : "工作績效" }}考核表
            {{ person.level === 4 ? "" : `(${person.text})` }}
          </router-link>
        </li>
        <li class="active">
          {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}考核
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}考核
            </h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
            <button
              v-if="
                data.scoreStatus === 1 &&
                data.createId === userData.user.employeeId
              "
              @click="signCancel()"
              class="btn btn-danger btn-cog"
              type="button"
              style="width: auto;"
            >
              抽單
            </button>
          </div>
          <div class="tc" v-if="data.message">
            {{ data.message }}
          </div>
          <div class="tc" v-else-if="!data.year">查詢中</div>
          <div v-else>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td>
                      <table class="table no-border" style="width: 400px">
                        <tbody>
                          <tr>
                            <th class="tr w90">員工編號：</th>
                            <td v-text="data.employeeId"></td>
                            <th class="tr">部別：</th>
                            <td v-text="data.deptName"></td>
                          </tr>
                          <tr>
                            <th class="tr">員工姓名：</th>
                            <td v-text="data.employeeName"></td>
                            <th
                              class="tr"
                              v-if="
                                person.level === 2 || addData.deptLevel === 2
                              "
                            >
                              年度：
                            </th>
                            <th class="tr" v-else>狀態：</th>
                            <td
                              v-if="
                                person.level === 2 || addData.deptLevel === 2
                              "
                              v-text="
                                `${addData.year}年(${
                                  define.halfYearStatus[addData.month]
                                })`
                              "
                            ></td>
                            <td
                              v-else
                              v-text="define.sendStatus[data.scoreStatus]"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <ScoreNote />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box-footer mt-15"></div>
            <div v-if="person.level === 2 || addData.deptLevel === 2">
              <ScoreDeptEdit />
            </div>
            <div
              v-else
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <div
                class="btns"
                v-if="hasEdit && (permission.isEmployee || person.level === 9)"
              >
                <button
                  class="btn btn-warning"
                  style="float: right; margin: 10px 0px"
                  type="button"
                  @click="showContent()"
                >
                  <i class="fa fa-plus"></i>
                  新增目標
                </button>
              </div>
              <table
                class="table table-hover table-bordered min-800"
                style="margin: 5px auto"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc">#</th>
                    <th class="tc mw50">年份</th>
                    <th class="tc mw50">月份</th>
                    <th class="tc mw90">目標種類</th>
                    <th class="tc mw150">部級目標</th>
                    <th class="tc mw150">人員目標</th>
                    <template v-for="(title, index) in titles">
                      <th
                        class="tc mw90 pre-wrap"
                        :key="index"
                        v-if="hasView"
                        v-text="
                          `${title.employeeName}\n${
                            index === 0
                              ? '（自評）'
                              : title.isManager === '0' &&
                                title.deptType.includes('0060')
                              ? '（上層主管）'
                              : title.isManager === '1' &&
                                title.deptType.includes('0060')
                              ? '（部門主管）'
                              : '（高層主管）'
                          }`
                        "
                      ></th>
                      <th
                        class="tc mw90 pre-wrap"
                        :key="title.employeeId"
                        v-text="
                          `${!hasView ? `${title.employeeName}\n` : ''}${
                            data.employeeId === title.employeeId
                              ? '說明'
                              : '考核意見'
                          }`
                        "
                      ></th>
                    </template>
                    <th class="tc mw90">不列入考核</th>
                    <th class="tc mw150" v-if="hasEdit">管理</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" :colspan="8 + 2 * titles.length">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td class="tc" :colspan="8 + 2 * titles.length">
                      尚無設定目標
                    </td>
                  </tr>
                  <tr v-else v-for="(data, index) in datas" :key="index">
                    <td class="tc" v-text="index + 1"></td>
                    <td class="tc" v-text="data.year"></td>
                    <td class="tc" v-text="data.month"></td>
                    <td
                      class="tc"
                      v-text="define.objectiveType[data.objectiveType]"
                    ></td>
                    <td class="pre-wrap" v-text="data.parentObjective"></td>
                    <td class="pre-wrap" v-text="data.objective"></td>
                    <template v-for="(scoreItem, index) in data.scores">
                      <td
                        class="tc"
                        :key="index"
                        v-if="hasView"
                        v-text="scoreItem.score"
                      ></td>
                      <td
                        class="pre-wrap"
                        :class="[
                          scoreItem.note && scoreItem.note.length >= 300
                            ? 'mw300'
                            : 'mw90',
                        ]"
                        :key="scoreItem.scoreId"
                        v-if="hasView || (!hasView && index === 0)"
                        v-text="scoreItem.note"
                      ></td>
                    </template>
                    <td class="tc">
                      <iCheckCheckBox
                        :propNone="true"
                        :propChecked="data.checked"
                        :propDisabled="data.disabled"
                      />
                    </td>
                    <td class="tc" v-if="hasEdit">
                      <button
                        class="btn btn-primary"
                        @click="showScoreContent(data)"
                      >
                        評分
                      </button>
                      <span v-show="data.objectiveType">&nbsp;</span>
                      <button
                        class="btn btn-danger"
                        v-show="data.objectiveType"
                        @click="deleteData(data)"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box-footer no-border mt-10"></div>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table
                class="table table-bordered min-800"
                style="margin: 5px auto"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc mw150" style="width: 180px">評分人員</th>
                    <th class="tc mw150" style="width: 150px" v-if="hasView">
                      績效分數
                    </th>
                    <th
                      class="tc"
                      :class="userData.user.employeeId === id ? 'wp40' : 'wp25'"
                    >
                      說明
                    </th>
                    <th
                      class="tc wp25"
                      v-if="hasView && userData.user.employeeId !== id"
                    >
                      給主管說明
                    </th>
                    <th class="tc mw300">附件</th>
                    <th class="tc mw80" v-if="hasEdit">管理</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" colspan="6">查詢中</td>
                  </tr>
                  <tr v-else-if="scoreDatas.length === 0">
                    <td class="tc" colspan="6">尚無評分人員</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(scoreData, index) in scoreDatas"
                    :key="index"
                  >
                    <td class="tc" v-text="scoreData.createEmpName"></td>
                    <td
                      class="tc"
                      v-if="
                        hasExcept ||
                        (hasEdit &&
                          userData.user.employeeId === scoreData.createEmpId)
                      "
                    >
                      <input
                        type="number"
                        style="height: auto"
                        class="form-control fw-b fz25"
                        min="0"
                        v-model="scoreData.totalScore"
                      />
                    </td>
                    <td
                      class="tc fw-b fz25"
                      v-else-if="hasView"
                      v-text="scoreData.totalScore"
                    ></td>
                    <td
                      class="tc"
                      v-if="
                        hasExcept ||
                        (hasEdit &&
                          userData.user.employeeId === scoreData.createEmpId)
                      "
                    >
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        v-model.trim="scoreData.note"
                      ></textarea>
                    </td>
                    <td class="pre-wrap" v-else v-text="scoreData.note"></td>
                    <td
                      class="tc"
                      v-if="
                        hasExcept ||
                        (hasEdit &&
                          userData.user.employeeId !== id &&
                          userData.user.employeeId === scoreData.createEmpId)
                      "
                    >
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        v-model.trim="scoreData.privateNote"
                      ></textarea>
                    </td>
                    <td
                      class="pre-wrap"
                      v-else-if="hasView && userData.user.employeeId !== id"
                      v-text="scoreData.privateNote"
                    ></td>
                    <td class="tc">
                      <label
                        class="btn btn-success"
                        v-if="
                          hasExcept ||
                          (hasEdit &&
                            userData.user.employeeId === scoreData.createEmpId)
                        "
                      >
                        <input
                          id="inputFile"
                          type="file"
                          accept="*"
                          style="display: none"
                          @change="changeFile($event, scoreData)"
                        />
                        <i class="fa fa-upload"></i> 上傳檔案
                      </label>
                      <table class="table no-border">
                        <tbody>
                          <tr
                            v-if="
                              !hasExcept &&
                              (!hasEdit ||
                                userData.user.employeeId !==
                                  scoreData.createEmpId) &&
                              scoreData.files &&
                              scoreData.files.length === 0
                            "
                          >
                            <td class="tc">－</td>
                          </tr>
                          <tr
                            v-else
                            v-for="(file, index) in scoreData.files"
                            :key="index"
                          >
                            <td class="tr">
                              <a target="_blank" :href="file.filesUrl">
                                {{ file.name }}
                              </a>
                            </td>
                            <td
                              class="w90"
                              v-if="
                                hasExcept ||
                                (hasEdit &&
                                  userData.user.employeeId ===
                                    scoreData.createEmpId)
                              "
                            >
                              <button
                                class="btn btn-danger"
                                @click="deleteFile(file.applyId, scoreData)"
                              >
                                刪除
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td class="tc" v-if="hasExcept || hasEdit">
                      <button
                        class="btn btn-primary"
                        v-if="
                          hasExcept ||
                          userData.user.employeeId === scoreData.createEmpId
                        "
                        @click="updateScoreData(scoreData)"
                      >
                        儲存
                      </button>
                      <span v-else>－</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <BigScoreNote />
            <div v-if="person.level !== 9">
              <SignLog
                :datas="datas"
                :data="data"
                :hasEdit="hasEdit"
                :backList="backList"
                :scoreDatas="scoreDatas"
              />
            </div>
          </div>
          <div
            class="box-footer no-border mt-10"
            v-if="datas.length === 0"
          ></div>
        </div>
      </div>
    </section>
    <PersonalContent ref="personalContent" v-on:updateList="getDataList" />
    <ScorePersonalContent
      ref="scorePersonalContent"
      v-on:updateList="getDataList"
    />
    <SignCancel ref="signCancel" :backList="backList" />
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import SignLog from "@/components/SignLog";
import api from "@/assets/js/app-api";
import ScoreNote from "./ScoreNote";
import BigScoreNote from "./BigScoreNote";
import PersonalContent from "./PersonalContent";
import ScorePersonalContent from "./ScorePersonalContent";
import ScoreDeptEdit from "./ScoreDeptEdit";
import SignCancel from "./SignCancel";

export default {
  components: {
    iCheckCheckBox,
    SignLog,
    ScoreNote,
    BigScoreNote,
    PersonalContent,
    ScorePersonalContent,
    ScoreDeptEdit,
    SignCancel,
  },
  data() {
    return {
      hasAll: false,
      hasEdit: false,
      hasView: false,
      hasHR: false,
      hasExcept: false,
      loading: true,
      total: 0,
      addData: {
        type: this.$route.params.type,
        employeeId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.deptId,
        deptLevel: this.$route.params.deptLevel,
        empList: this.$route.params.empList,
        adminAdd: this.$route.params.adminAdd || 0,
        formId: this.$route.params.formId,
        scoreGroupId: this.$route.params.formId,
        back: this.$route.params.back,
        searchData: this.$route.params.searchData,
      },
      view: this.$route.params.back === "SignHistory",
      person: this.global.getPerson(this.$route.params.type),
      id: this.$route.params.id,
      userData: {},
      permission: {},
      scoreDatas: [],
      scoreData: {},
      titles: [],
      datas: [],
      data: {},
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasAll = !this.view;
    this.hasEdit = this.hasAll;
    this.getDataList();
  },
  methods: {
    getDataList() {
      if (this.id) {
        api.okr.score.min.queryAll(this.addData).then((res) => {
          this.data = res.data;
          if (res.data.status === "1") {
            if (res.data.result) {
              this.titles = res.data.result.empList || [];
              this.datas = res.data.result.scoreList || [];
              this.data = res.data.result.group || this.datas[0];
              this.total = res.data.result.total;
              this.hasHR = this.permission.isHR && this.data.deptId !== "132";
              this.hasExcept = this.permission.isHR && this.person.level === 9;
              this.hasEdit =
                this.hasEdit &&
                (this.$route.params.back === "Sign" ||
                  this.hasExcept ||
                  (this.data.scoreStatus <= 0 &&
                    this.userData.user.employeeId === this.data.employeeId));
              this.hasView =
                this.view ||
                this.hasEdit ||
                this.hasHR ||
                this.permission.hasOver ||
                this.userData.user.employeeId === this.data.employeeId ||
                (this.permission.deptIds.includes(this.data.deptId) &&
                  this.userData.user.employeeId ===
                    this.userData.user.deptManagerId);
              this.getScoreDataList();
            }
          }
        });
      } else {
        this.loading = false;
      }
    },
    getScoreDataList() {
      this.data.monthStatus = this.hasView ? 1 : 0;
      api.okr.score.min.monthQueryAll(this.data).then((res) => {
        if (res.data.status === "1") {
          if (res.data.result) {
            this.scoreDatas = res.data.result.datas || [];
          }
          this.updateDataList();
        }
      });
    },
    updateDataList() {
      this.datas.forEach((data) => {
        data.checked = data.noCount ? 1 : 0;
        setTimeout(() => {
          data.checked = data.noCount ? true : false;
          data.disabled = true;
          this.$forceUpdate();
        }, 300);
      });
      this.updateViewData();
      this.loading = false;
    },
    updateViewData() {
      if (
        this.person.level === 4 &&
        this.userData.user.employeeId === this.data.employeeId
      ) {
        if (this.scoreDatas.length) {
          this.scoreDatas = [this.scoreDatas[0]];
        }
      }
      if (!this.hasView) {
        if (this.titles.length) {
          this.titles = [this.titles[0]];
        }
        if (this.scoreDatas.length) {
          this.scoreDatas = [this.scoreDatas[0]];
        }
      }
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.goal.newDelete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    updateScoreData(scoreData) {
      if (scoreData.totalScore || scoreData.totalScore === 0) {
        api.okr.score.min.monthUpdate(scoreData).then((res) => {
          this.global.showResponse(this, res, "update");
        });
      } else {
        this.$eventBus.$emit("showAlert", "請輸入績效分數！");
      }
    },
    showContent(data) {
      this.data.objectiveType = 1;
      this.data.hasHR = this.hasHR;
      this.data.userData = this.userData;
      this.data.permission = this.permission;
      this.$refs.personalContent.showContent(data, this.data);
    },
    showScoreContent(data) {
      data.hasHR = this.hasHR;
      data.userData = this.userData;
      data.permission = this.permission;
      data.deptLevel = this.addData.deptLevel;
      data.scoreGroupId = data.scoreGroupId || this.data.scoreGroupId;
      this.$refs.scorePersonalContent.showContent(data);
    },
    backList() {
      this.$router.push({
        name: this.$route.params.back || "ScorePersonalList",
        params: {
          type: this.person.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
    signCancel() {
      let id = this.data.formId
      let type = this.data.formTypeId
      this.$refs.signCancel.showContent(id, type);
    },
    // 上傳檔案
    changeFile(e, scoreData) {
      this.scoreData = scoreData;
      this.scoreData.files = this.scoreData.files || [];
      var files = e.target.files;
      if (files.length + this.scoreData.files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您檔案數量超過上限5個，請重新選擇。"
        );
        this.removeFile();
        return;
      } else if (files.length > 0) {
        var isCheckType = false; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (fileSize > 20000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 20 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }
      this.uploadFile(
        files[0],
        "OKRScoreMonth",
        this.scoreData.scoreMonthId,
        true
      );
    },
    removeFile() {
      document.getElementById("inputFile").value = "";
    },
    // 檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      this.scoreData.id = response_upload.data.result.id;
      this.refreshFileList();
    },
    // 刪除檔案
    deleteFile(applyId, scoreData) {
      this.scoreData = scoreData;
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.file
            .deleteApply({
              id: applyId,
            })
            .then((res) => {
              this.global.showResponse(
                this,
                res,
                "delete",
                this.refreshFileList
              );
            });
        }
      });
    },
    // 檔案列表刷新
    async refreshFileList() {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.scoreData.scoreMonthId,
        module: "OKRScoreMonth",
      });
      var response_queryall = await task_queryall;
      this.scoreData.files = response_queryall.data.result;
      this.$forceUpdate();
    },
  },
};
</script>
