<template>
  <div id="Score">
    <section class="content-header">
      <h1>考核人員設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">考核人員設定</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="showContent()"
              >
                <i class="fa fa-plus"></i>
                新增
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="getUserList()"
									style="margin-left: 0;"
                >
                  <option
                    v-for="(item, index) in deptList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw100">部門</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw100">加分人員</th>
                  <th class="tc mw100">加分人員部門</th>
                  <th class="tc mw100">設定人員</th>
                  <th class="tc mw100">設定時間</th>
                  <th class="tc mw150">備註</th>
                  <th class="tc mw150">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="8">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in list" :key="index">
                  <td class="tc">{{ data.deptName }}</td>
                  <td class="tc">{{ data.employeeId }}</td>
                  <td class="tc">{{ data.employeeName }}</td>
                  <td class="tc">{{ data.raterEmpName }}</td>
                  <td class="tc">{{ data.raterDeptName }}</td>
                  <td class="tc">{{ data.createEmpName }}</td>
                  <td class="tc">{{ new Date(data.createTime) | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                  <td class="tc">{{ data.note }}</td>
                  <td class="tc">
                    <button
                      class="btn btn-danger"
                      @click="deleteItem(data)"
                      v-if="(userData.user.employeeId == data.createEmpId) || permission.isHR"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <AddPersonContent ref="addPersonContent" :users="users" v-on:updateList="resetDataList" />
  </div>
</template>

<script>
import AddPersonContent from "./AddPersonContent";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";

export default {
  components: {
    AddPersonContent,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 10,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: 0,
        status: -1,
        employeeId: "",
        deptId: "",
      },
      list: [],
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      months: [],
      userData: {},
      permission: {},
      deptList: [],
      users: [],
    };
  },
  watch: {
    deptList() {
      // this.searchData.deptId = this.deptList[0].deptId
    },
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(
      this.userData,
      this.person.limit
    );
    this.permission.isManager =
      !this.permission.isEmployee && this.person.level === 3;
    this.searchData.parentDeptId = this.permission.isDivisionManager
      ? this.userData.user.deptId
      : null;
    this.searchData.deptId = this.userData.deptEmp[0].deptId;
    this.getDepList();
  },
  methods: {
    getDepList() {
      if(!this.permission.isHR) {
        this.deptList = this.userData.deptEmp
      } else {
        this.searchData.deptId = this.userData.user.deptId;
        api.organization 
        .getDeptList({
            companyId: this.$store.state.loginUser.companyId,
          })
          .then((res) => {
            if (res.data.status === "1") {
              this.deptList = res.data.result || [];
            }
          });
        }
      this.getUserList();
    },
    getUserList() {
      this.searchData.pageSize = 200;
      api.organization.queryEmployeeAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.users = res.data.result.datas || [];
          this.getDataList()
        }
      });
    },
    getDataList() {
      this.searchData.pageSize = 10;
      this.loading = true;
      api.okr.v2.rater.queryAll(this.searchData).then(({data}) => {
        this.list = data.result.datas;
        this.total = data.result.total;
        this.loading = false;
      })
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", `確定要刪除嗎?`, (isOK) => {
        if (isOK) {
          api.okr.v2.rater.delete(item)
            .then((res) => {
              if(res.data.status == '1') {
                this.global.showResponse(this, res, 'delete');
                this.resetDataList();
              }
            })
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    showContent() {
      this.$refs.addPersonContent.showContent();
    },
  },
};
</script>