<template>
  <div class="modal fade" ref="personalContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ this.isEdit ? "編輯" : "新增" }}目標</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label w90">
                      <span class="text-red">*</span>
                      部別
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        :disabled="exist || data.objectiveStatus"
                        v-model="data.deptId"
                        @change="getUserList()"
                      >
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      所屬人員
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        :disabled="
                          (isEdit || isEmployee || disabled) && trainee
                        "
                        v-model="data.employeeId"
                      >
                        <option
                          v-for="(user, index) in users"
                          :key="index"
                          :value="user.employeeId"
                        >
                          {{ user.employeeName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      年度
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.year"
                        @change="getObjs()"
                      >
                        <option
                          v-for="(year, index) in years"
                          :key="index"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.month"
                        @change="getObjs()"
                      >
                        <option
                          v-for="(month, index) in 12"
                          :key="index"
                          :value="month"
                        >
                          {{ month }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      目標種類
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        disabled
                        v-model="data.objectiveType"
                      >
                        <option value="0">一般目標</option>
                        <option value="1">新增任務</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      部級目標
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        v-model="data.parentObjectiveId"
                      >
                        <option value="-1">未定</option>
                        <option value="-2">例行工作</option>
                        <option
                          v-for="(obj, index) in objs"
                          :key="index"
                          :value="obj.objectiveId"
                        >
                          {{ obj.objective }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      人員目標
                    </td>
                    <td colspan="2">
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                        :disabled="data.objStatus"
                        v-model.trim="data.objective"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">儲存</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      disabled: false,
      isEmployee: false,
      isEdit: false,
      exist: false,
      trainee: false,
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      list: [],
      users: [],
      objs: [],
      data: {},
    };
  },
  methods: {
    async showContent(data, addData) {
      this.disabled = addData.month != -1 ? true : false;
      this.isEmployee = addData.permission.isEmployee;
      this.isEdit = data ? true : false;
      this.data = data ? data : addData;
      this.data = JSON.parse(JSON.stringify(this.data));
      this.data.objStatus =
        (!this.data.objectiveStatus ||
          (this.data.objectiveStatus && this.data.scoreStatus <= 0)) &&
        (!this.isEdit ||
          addData.hasHR ||
          addData.userData.user.employeeId === this.data.employeeId ||
          (!this.isEmployee &&
            addData.permission.deptIds.includes(this.data.deptId) &&
            (this.person.level === 4 || this.person.level === 5)))
          ? false
          : true;
      this.getList(addData);
      $(this.$refs.personalContent).modal("show");
    },
    getList(addData) {
      this.exist = addData.permission.deptIds.includes(this.data.deptId);
      this.trainee =
        (this.person.level === 5 && addData.userData.user.traineeDeptId) ||
        (this.person.level === 4 && this.isEmployee) ||
        (this.person.level === 3 && this.exist);
      if (
        (this.exist || !this.isEdit) &&
        !addData.hasHR &&
        !addData.userData.user.traineeDeptId
      ) {
        this.list = addData.permission.deptList;
        this.getUserList();
      } else {
        api.okr.goal.department.list({ deptLevel: 2 }).then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
            this.exist = true;
            this.getUserList();
          }
        });
      }
    },
    getUserList() {
      if (this.person.level === 5) {
        api.okr.mgmt.assign.queryAll().then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result.datas || [];
            this.getObjs();
          }
        });
      } else {
        api.organization.queryEmployeeAll(this.data).then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result.datas || [];
            this.getObjs();
          }
        });
      }
    },
    getObjs() {
      api.okr.goal.department
        .queryAll({
          deptId: this.data.deptId,
          year: this.data.month == 12 ? parseInt(this.data.year) + 1 : this.data.year,
          month: this.global.getHalfYear(this.data.month),
          isDeptFollow: 1,
        })
        .then((res) => {
          if (res.data.status === "1") {
            const datas = res.data.result.datas || [];
            this.objs = this.global.getDistinctData(datas, "objective");
          }
        });
    },
    submit() {
      const action = this.isEdit ? "update" : "create";
      if (this.data.objectiveType && !this.isEdit) {
        api.okr.goal.newCreate(this.data).then((res) => {
          this.global.showResponse(this, res, action);
          $(this.$refs.personalContent).modal("hide");
          this.$emit("updateList");
        });
      } else {
        api.okr.goal[this.person.api][action](this.data).then((res) => {
          this.global.showResponse(this, res, action);
          $(this.$refs.personalContent).modal("hide");
          if (this.$route.params.id) {
            this.$emit("updateList");
          } else {
            this.data.id = this.data.employeeId;
            this.data.parentObjectiveId = "";
            this.data.objective = "";
            this.$router.push({
              name: "PersonalEdit",
              params: this.data,
            });
          }
        });
      }
    },
  },
};
</script>
