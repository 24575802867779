<template>
  <div id="HrScoreBalance">
    <section class="content-header">
      <h1>小考總表匯出</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">小考總表匯出</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-primary"
                type="button"
                @click="exportExcel()"
              >
                <i class="fa fa-download"></i> 最終分數匯出
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢分數：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw80">年份</th>
                  <th class="tc mw50">月份</th>
                  <th class="tc mw100">部別</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw80">考核身份</th>
                  <template v-for="(title, index) in titles">
                    <th
                      class="tc mw90"
                      :key="index"
                      v-text="
                        `${
                          title.employeeId === 0
                            ? '人員自評'
                            : title.employeeId === 5
                            ? '部主管評分'
                            : title.employeeId === 10
                            ? '部級調整'
                            : title.employeeId === 20
                            ? '處級調整'
                            : title.employeeId > 0
                            ? `第${title.employeeId}層`
                            : '－'
                        }`
                      "
                    ></th>
                  </template>
                  <th class="tc mw80">狀態</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="12">查詢中</td>
                </tr>
                <tr v-else-if="groups.length === 0">
                  <td class="tc" colspan="12">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in groups" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.month"></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td class="tc" v-text="define.deptLevel[data.deptLevel]"></td>
                  <template v-for="(scoreItem, index) in data.monthItems">
                    <td
                      class="tc"
                      :key="index"
                      v-text="
                        scoreItem.displayEmpty
                          ? '－'
                          : scoreItem.totalScore || 0
                      "
                    ></td>
                  </template>
                  <td
                    class="tc"
                    v-text="define.sendStatus[data.scoreStatus]"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";

export default {
  data() {
    return {
      loading: true,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
      },
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      deptment: {},
      titles: [],
      groups: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.updateMonthSearch();
    this.getDataList();
  },
  methods: {
    getDataList() {
      api.okr.score.min.balance.hrQueryAll(this.searchData).then((res) => {
        this.loading = false;
        if (res.data.status === "1") {
          this.deptment = res.data.result.deptment || {};
          this.titles = res.data.result.empList || [];
          this.groups = res.data.result.groups || [];
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    updateMonthSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.month = this.global.getHalfYear();
      }
    },
    exportExcel() {
      if (this.groups.length <= 0) {
        this.$eventBus.$emit("showAlert", "查無資料");
      } else {
        const items = [];
        this.groups.forEach((item) => {
          const data = {
            年份: item.year,
            月份: item.month,
            部別: item.deptName,
            員工編號: item.employeeId,
            姓名: item.employeeName,
            考核身份: this.define.deptLevel[item.deptLevel],
          };
          let finalScore = 0;
          this.titles.forEach((title, index) => {
            const scoreItem = item.monthItems[index];
            if (title.employeeId === 0) {
              finalScore = scoreItem.totalScore || finalScore || 0;
              data["人員自評"] = scoreItem.displayEmpty
                ? "－"
                : scoreItem.totalScore || 0;
            } else if (title.employeeId === 5) {
              finalScore = scoreItem.totalScore || finalScore || 0;
              data["部主管評分"] = scoreItem.displayEmpty
                ? "－"
                : scoreItem.totalScore || 0;
            } else if (title.employeeId === 10) {
              finalScore = scoreItem.totalScore || finalScore || 0;
              data["部級調整"] = scoreItem.displayEmpty
                ? "－"
                : scoreItem.totalScore || 0;
            } else if (title.employeeId === 20) {
              finalScore = scoreItem.totalScore || finalScore || 0;
              data["處級調整"] = scoreItem.displayEmpty
                ? "－"
                : scoreItem.totalScore || 0;
            } else if (title.employeeId > 0) {
              finalScore = scoreItem.totalScore || finalScore || 0;
              data[`第${index}層`] = scoreItem.displayEmpty
                ? "－"
                : scoreItem.totalScore || 0;
            }
          });
          data["最終分數"] = finalScore || 0;
          data["狀態"] = this.define.sendStatus[item.scoreStatus];
          items.push(data);
        });
        const title =
          this.searchData.month >= 0
            ? `${
                this.searchData.year +
                this.define.halfYearStatus[this.searchData.month]
              }_工作績效考核總表.xlsx`
            : `${this.searchData.year}_工作績效考核總表.xlsx`;
        utils.exportData(items, title, "工作績效考核總表");
      }
    },
  },
};
</script>
