<template>
  <div class="checkbox">
    <label>
      <input class="flat" type="checkbox" :value="isChecked" />
    </label>
    {{message}}
  </div>
</template>

<script>
export default {
  props: {
    value: "",
    message:"",
    propDisabled:false,
  },
  data() {
    return {
      isChecked: 0,
      isDisabled: false
    };
  },
  mounted() {
    var vm = this;

    this.isDisabled = this.propDisabled;

    vm.setStatus(this.value);

    $(vm.$el).on("ifUnchecked", function(e) {
      vm.setStatus(0);
    });
    $(vm.$el).on("ifChecked", function(e) {
      vm.setStatus(1);
    });
  },
  watch: {
    value(value) {
      this.setStatus(value);
    },
    propDisabled(value) {
      this.setDisabled(value);
    }
  },
  methods: {
    setStatus(isChecked) {
      if (isChecked != 1 && isChecked != 0) return;
      this.isChecked = isChecked;
      if (this.isChecked == 1) $(this.$el).iCheck("check");
      this.$emit("input", this.isChecked);
      this.$emit("update:checked", this.isChecked);
    },
    setDisabled(value){
      // console.log("setDisabled:"+value);
      if( value === true ) $(this.$el).iCheck("disable");
      else $(this.$el).iCheck("enable");
    }
  }
};
</script>
