<template>
  <div id="YearList">
    <section class="content-header">
      <h1>收件匣</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">收件匣</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="請輸入主旨"
                  type="text"
                  v-model.trim="searchData.keyword"
                />
                <div class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="resetDataList()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">表單</th>
                  <th class="tc">主旨</th>
                  <th class="tc">送件狀態</th>
                  <th class="tc">填表人</th>
                  <th class="tc mw150">送件時間</th>
                  <th class="tc">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="6">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="6">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.formTypeId"></td>
                  <td class="tc" v-text="data.formTitle"></td>
                  <td class="tc" v-text="define.signState[data.signState]"></td>
                  <td class="tc" v-text="data.createEmpName"></td>
                  <td class="tc">
                    {{ data.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="tc">
                    <button class="btn btn-primary" @click="updateData(data)">
                      {{ define.stepState[data.stepState] }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
      },
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      api.okr.mgmt.sign.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          if(this.datas.length == 0 && this.total != 0) {
            this.searchData.pageNum = this.searchData.pageNum - 1;
            this.getDataList();
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateData(data) {
      if(data.formTypeId == "考核加分") {
        this.$router.push({
          name: "ScoreEdit",
          params: {
            formId: data.formId,
            formType: data.formTypeId,
            back: 'Sign',
            data: data,
            edit: 1,
            searchData: this.searchData,
          },
        });
        return
      }
      const signData = this.global.getSignData(data);
      const form = data.formId.split("-");
      this.$router.push({
        name: signData.name,
        params: {
          type: signData.type,
          id: signData.id,
          year: form[0],
          month: form[1],
          deptId: data.createDeptId,
          deptLevel: form[2],
          formType: data.formTypeId,
          formId: data.formId,
          createId: data.createEmpId,
          signId: data.signGetId,
          back: "Sign",
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
