var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "flashInfo", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-bordered table-habits" }, [
            _c(
              "tbody",
              _vm._l(_vm.flashInfos.datas, function (info, index) {
                return _c("tr", { key: index }, [
                  _c("td", { domProps: { innerHTML: _vm._s(info.note) } }),
                ])
              }),
              0
            ),
          ]),
        ]),
        _vm._m(1),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("最新消息")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("\n          關閉\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }