<template>
  <div id="OrderMenu">
    <section class="content-header">
      <h1>選擇餐點</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務</li>
        <li>
          <router-link to="/OrderDinner">今天晚餐</router-link>
        </li>
        <li class="active">預訂今天晚餐</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="top-box box-header with-border">
          <!-- <h4 class="pull-left">選擇餐點</h4> -->
          <button
            class="btn btn-block btn-default btn-cog"
            type="button"
            @click="goBack()"
          >
            <i class="fa fa-reply"></i> 返回列表
          </button>
        </div>
        <div class="box-body" v-if="menuList != undefined">
          <p v-if="habits != null">
            個人飲食習慣：{{ getMealType(habits.mealType) }}
            <span v-if="habits.notEat != ''">(</span>
            {{ habits.notEat }}
            <span v-if="habits.notEat != ''">)</span>
          </p>
          <p v-else>個人飲食習慣：</p>
          <p>
            用餐日期：{{ formatDateTime(menuList.eatDay, "YYYY/MM/DD (dd)") }}
            晚餐
          </p>
          <!-- <div class="switch-block">
            <p>本日不訂餐：</p>
            <div class="ckbx-style-8 ckbx">
              <input
                :false-value="null"
                id="check-order"
                :true-value="0"
                @change="noOrderChange()"
                name="ckbx-style-8"
                type="checkbox"
                v-model="menuList.eatStatus"
              />
              <label for="check-order"></label>
            </div>
          </div>-->
          <div
            class="store"
            :key="store.storeId"
            v-for="store in menuList.stores"
          >
            <h5 class="name">
              <i class="fa fa-circle text-yellow"></i>
              {{ store.storeName }}
            </h5>
            <p>描述：{{ store.storeInfo }}</p>
            <ul class="list list-unstyled">
              <li :key="meal.mealId" v-for="meal in store.meal">
                <div class="list-box">
                  <div class="pic jqimgFill">
                    <!-- 430 * 330 -->
                    <img
                      :src="'https://dev-eip.wanin.tw/' + file.filesUrl"
                      :key="index"
                      v-for="(file, index) in meal.files"
                      draggable="false"
                      :alt="file.name"
                    />
                  </div>
                  <p>{{ meal.mealName }}</p>
                  <label
                    v-show="meal.eatScore != null && meal.averageScore != null"
                    class="text-blue"
                    v-on:click="openOrderScore(meal)"
                    >({{ meal.eatScore == null ? "尚未評分" : meal.eatScore }} /
                    {{
                      meal.averageScore == null
                        ? "尚未評分"
                        : meal.averageScore
                    }})</label
                  >
                  <div class="bottom">
                    <span class="number" v-show="meal.amount != null"
                      >可訂數量：{{
                        Math.max(meal.amount - meal.eatCount, 0)
                      }}</span
                    >
                    <!-- <span class="number">已訂數量：{{meal.eatCount}}</span> -->
                    <button
                      :class="[
                        meal.mealId == menuList.eatMealId
                          ? 'btn btn-default active'
                          : 'btn  btn-default',
                      ]"
                      :disabled="
                        (meal.eatCount >= meal.amount && meal.amount != null) ||
                        menuList.eatStatus == 0
                      "
                      @click="orderDine(meal)"
                    >
                      訂購
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <orderScore ref="orderScore" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import orderScore from "@/views/OrderSystem/OrderScore";
// import moment from "moment"; 沒使用到

export default {
  components: { orderScore },
  data() {
    return {
      expatId: this.$user.getUserExpatId(),
      menuList: {},
      habits: {},
      startDay: "",
      endDay: "",
      state: 2,
      employeeId: this.$store.state.loginUser.employeeId,
    };
  },
  async created() {
    this.startDay = this.formatDateTime(new Date(), "YYYY/MM/DD");
    this.endDay = this.formatDateTime(new Date(), "YYYY/MM/DD");
    this.getOrderMenu();
    this.getEmpHabitsInfo();
  },
  mounted() {
    $(".tabs-order").each(function () {
      $(this)
        .find(".btn")
        .click(function () {
          $(this).addClass("btn-warning").removeClass("btn-default");
          $(this).siblings().removeClass("btn-warning").addClass("btn-default");
        });
    });

    // var clicks = 0;
    // $(".ckbx").click(function() {
    //   if (clicks % 2 == 1) {
    //     $(".store .btn").attr("disabled", false);
    //     $(".store .disabled").attr("disabled", true);
    //   } else {
    //     $(".store .btn")
    //       .removeClass("active")
    //       .attr("disabled", true);
    //   }
    //   ++clicks;
    // });
  },
  methods: {
    getOrderMenu() {
      //取得訂餐列表
      api.empOrder
        .orderDayMenu({
          companyId: this.expatId,
          employeeId: this.employeeId,
          eatStartDay: this.startDay,
          eatEndDay: this.endDay,
          eatTime: this.state,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.menuList = res.data.result[0];
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        })
        .finally(() => {
          // imgLiquid
          $(".jqimgFill").imgLiquid();

          $(".store").each(function () {
            $(this)
              .find(".btn")
              .click(function () {
                $(this)
                  .toggleClass("active")
                  .parents()
                  .parents()
                  .parents()
                  .siblings()
                  .find(".btn")
                  .removeClass("active");
              });
          });
        });
    },
    getEmpHabitsInfo() {
      api.mealHabits
        .query({
          employeeId: this.employeeId,
        })
        .then((res) => {
          if (res.data.status == "1")
            this.habits = JSON.parse(JSON.stringify(res.data.result));
          else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getMealType(state) {
      let typeName = "";
      switch (state) {
        case 0:
          typeName = "素食";
          break;
        case 1:
          typeName = "葷食";
          break;
      }

      return typeName;
    },
    orderDine(dine) {
      if (dine.mealType == 1 && this.habits != null) {
        if (this.habits.mealType != 1) {
          this.$eventBus.$emit("showAlert", "該餐點是葷食喔!!");
        } else {
          if (
            this.habits.notEat != "" &&
            dine.mealContain != "" &&
            dine.mealContain != null
          ) {
            dine.mealContain.split(",").some((mealContain) => {
              this.habits.notEat.split(",").some((notEat) => {
                if (mealContain == notEat.substring(2)) {
                  this.$eventBus.$emit(
                    "showAlert",
                    "該餐點含有" + mealContain + "喔!!"
                  );
                  return true;
                }
              });
            });
          }
        }
      } else {
        if (this.habits != null && this.habits.mealType != 0)
          this.$eventBus.$emit("showAlert", "該餐點是素食喔!!");
      }

      if (
        dine.mealId == this.menuList.eatMealId &&
        this.menuList.eatStatus == 1
      ) {
        this.$eventBus.$emit("showAlert", "已訂購此餐點");
      } else {
        //訂餐
        api.empOrder
          .order({
            companyId: this.expatId,
            employeeId: this.employeeId,
            mealId: dine.mealId,
            eatDay: this.startDay,
            eatStatus: 1,
            eatTime: this.state,
          })
          .then((res) => {
            if (res.data.status != "1") {
              this.$eventBus.$emit("showAlert", res.data.message);
            }

            this.getOrderMenu();
          });
      }
    },
    noOrderChange() {
      //不訂餐
      api.empOrder
        .order({
          companyId: this.expatId,
          employeeId: this.employeeId,
          eatDay: this.startDay,
          eatTime: this.state,
          eatStatus: this.menuList.eatStatus,
        })
        .then((res) => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", res.data.message);
          }

          this.getOrderMenu();
        });
    },
    goBack() {
      // this.$router.push({
      //   name: "OrderDinner"
      // });
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push({
            name: "OrderDinner",
          });
      return;
    },
    formatDateTime(datetime, format) {
      return utils.formatTime(datetime, format, "zh-tw");
    },
    openOrderScore(meal) {
      if (meal.eatScore == null) return;
      this.$refs.orderScore.showMealScoreBox(meal, false, true);
    },
  },
};
</script>
