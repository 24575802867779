var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", [
    _c("input", {
      staticClass: "flat",
      attrs: { name: _vm.name, type: "radio", disabled: _vm.disabled },
      domProps: { value: _vm.checkValue },
    }),
    _c("span", [_vm._v(_vm._s(_vm.message))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }