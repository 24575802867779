var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "OrderDinner" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("預訂今天晚餐")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("訂餐服務")]),
          _c("li", { staticClass: "active" }, [_vm._v("預訂今天晚餐")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box two-btn" }, [
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "text-red" }, [
                  _c("i", { staticClass: "fa fa-warning" }),
                  _vm._v(
                    "\n              訂餐截止時間：" +
                      _vm._s(_vm.orderDeadline) +
                      "。\n            "
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-primary btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-list" }),
                  _vm._v(" 訂餐歷程\n          "),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openEmployeeHabits()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-gear" }),
                  _vm._v(" 個人飲食習慣\n          "),
                ]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered min-600" }, [
                _c("tbody", [
                  _vm._m(0),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.formatDateTime(new Date(), "YYYY/MM/DD"))
                      ),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.formatDateTime(new Date(), "dd")) +
                          "\n                "
                      ),
                    ]),
                    _c("td", [
                      _vm.datas != undefined
                        ? _c(
                            "ul",
                            { staticClass: "store-name list-unstyled" },
                            _vm._l(_vm.datas.stores, function (store) {
                              return _c("li", { key: store.storeId }, [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(store.storeName) +
                                    "\n                    "
                                ),
                              ])
                            }),
                            0
                          )
                        : _c("span", { staticClass: "text-red" }, [
                            _c("i", { staticClass: "fa fa-warning text-red" }),
                            _vm._v(" 尚未設定\n                  "),
                          ]),
                    ]),
                    _vm.total == 0 ||
                    !_vm.checkDateIsToday(_vm.orderList.datas[0].eatDay)
                      ? _c("td", [_vm._m(1)])
                      : _c("td", [
                          _vm._v(_vm._s(_vm.orderList.datas[0].mealName)),
                        ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm.datas != undefined
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "button", title: "瀏覽菜單" },
                              on: {
                                click: function ($event) {
                                  return _vm.goOrderMenu()
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-book" })]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center" },
            [
              _c("Pagination", {
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("employeeHabits", { ref: "employeeHabits" }),
      _c("orderScore", {
        ref: "orderScore",
        on: { refreshList: _vm.getOrderList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("用餐日期")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("星期")]),
      _c("th", [_vm._v("店家名稱")]),
      _c("th", [_vm._v("餐點名稱")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("瀏覽菜單")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text-red" }, [
      _c("i", { staticClass: "fa fa-warning text-red" }),
      _vm._v(" 尚未選擇\n                  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }