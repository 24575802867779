<template>
  <div id="CourseEdit">
    <section class="content-header">
      <h1>課程管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li class="active">課程管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning company-files-edit">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">課程新增/編輯</h4>
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  <span class="text-red">*</span> 課程名稱：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="data.courseName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 課程類型：
                </label>
                <div class="col-sm-8">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="年度課程"
                        message="年度課程"
                        name="radioMode"
                        v-model="data.courseType"
                      />
                    </div>
                    <div class="input-group date">
                      <select
                        class="form-control"
                        id="selectUnit"
                        required="required"
                        v-model="chapterType"
                      >
                        <option
                          :value="item.value"
                          v-for="(item, index) in chapterTypeList"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="常態課程"
                      message="常態課程"
                      name="radioMode"
                      v-model="data.courseType"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 課程分類：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    name="selectCourseType"
                    required="required"
                    v-model="selectCourseType"
                    style="max-width: 220px"
                  >
                    <option value="" :selected="!selectCourseType">
                      請選擇
                    </option>
                    <option
                      :value="item.className"
                      v-for="(item, index) in courseTypeList"
                      :key="index"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <p style="color: #999">*如需新增課程分類請聯繫開發單位</p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label"> 必修部門： </label>
                <div class="col-sm-8">
                  <multiselect
                    placeholder="請選擇公司或部門"
                    label="deptIdName"
                    track-by="deptIdName"
                    required
                    :options="deptList"
                    :hide-selected="true"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="selected"
                    max-elements="已達標籤上限"
                  >
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.deptIdName }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                  <p class="text-red">
                    *選擇台中總公司=設定所有部門為必修單位
                    <br />*未設定=該課程為全公司選修課程
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  僅限必修部門顯示：
                </label>
                <div class="col-sm-8">
                  <div class="checkbox">
                    <label>
                      <iCheckCheckBox
                        v-bind:propChecked="data.showInRequireDept"
                        v-on:update:checked="
                          data.showInRequireDept = $event['checked']
                        "
                      />
                      <span style="color: #999">
                        *未勾選的話，會顯示在其他部門的選修課程之中
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 排程發送通知信：
                </label>
                <div class="col-sm-8">
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="0"
                      message="不設定"
                      name="radioNotice"
                      v-model="data.scheduleSendMailType"
                    />
                  </div>
                  <div class="radio-date" style="padding-top: 7px">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="1"
                        message="單次"
                        name="radioNotice"
                        v-model="data.scheduleSendMailType"
                      />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker
                          v-model="scheduleSendMailDate"
                          :propValue="scheduleSendMailDate"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="radio-date" style="padding-top: 7px">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="2"
                        message="每月"
                        name="radioNotice"
                        v-model="data.scheduleSendMailType"
                      />
                    </div>
                    <div class="input-group date">
                      <select
                        class="form-control"
                        v-model="scheduleSendMailDay"
                      >
                        <option :value="index" v-for="index in 28" :key="index">
                          {{ index }}號
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-8">
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="啟用"
                      name="radioMode3"
                      v-model="data.courseStatus"
                    />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="停用"
                      name="radioMode3"
                      v-model="data.courseStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary" type="button" @click="save()">
                  {{ isEdit ? "更新" : "新增" }}課程
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import multiselect from "vue-multiselect";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    multiselect,
    iCheckRedioButton,
    iCheckCheckBox,
    DatePicker,
  },
  data() {
    return {
      isEdit: this.$route.params.id ? true : false,
      classList: {},
      chapterTypeList: [],
      data: {
        courseId: this.$route.params.id || utils.getTimeId(),
        courseName: "",
        courseType: "常態課程",
        courseStatus: 1,
        isRequire: 0,
        isDel: 0,
        createTime: "2024-01-19 00:00:00",
        updateTime: "2024-01-19 00:00:00.000",
        showInRequireDept: 0,
        scheduleSendMailType: 0,
      },
      chapterType: "",
      deptList: [],
      selected: [],
      courseTypeList: [],
      selectCourseType: "",
      scheduleSendMailDate: "",
      scheduleSendMailDay: 1,
    };
  },
  async created() {
    this.getDeptList();
    this.getClassList();
    this.getCourseTypeList();
  },
  mounted() {
    if (this.isEdit) this.getData();

    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });
  },
  methods: {
    getCourseTypeList() {
      const searchData = {
        className: "", // 類別名稱
        module: "教育訓練課程分類",
        // classStatus: 1, // 類別狀態
        isDel: 0, // 刪除狀態 1:已刪除、0:未刪除
        order: 2,
        pageNum: 1,
        pageSize: 999,
      };
      api.system.typeAll(searchData).then((res) => {
        res.data.result.datas.forEach((item) => {
          if (item.classStatus == 1) {
            this.courseTypeList.push(item);
          }
        });
        this.$forceUpdate();
      });
    },

    getData() {
      api.education.course
        .query({
          courseId: this.data.courseId,
        })
        .then(({ data }) => {
          this.data = data.result;
          this.selected = data.result.doCourseDeptList;
          if (this.data.courseType != "常態課程") {
            this.chapterType = this.data.courseType;
            this.data.courseType = "年度課程";
          }

          if (this.data.scheduleSendMailDate) {
            this.scheduleSendMailDate = new moment(
              this.data.scheduleSendMailDate
            ).format("YYYY/MM/DD");
          } else {
            this.scheduleSendMailDate = new moment().format(
              "YYYY-MM-DD 00:00:00"
            );
          }

          this.scheduleSendMailDay = this.data.scheduleSendMailDay || 1;
          this.selectCourseType = this.data.courseClass;

          data.result.doCourseDeptList.forEach((item) => {
            item.deptIdName = item.doCourseDept;
            item.deptId = item.doCourseDeptId;
          });
          this.selected = data.result.doCourseDeptList;
          this.$forceUpdate();
        });
    },
    getClassList() {
      api.education.class().then(({ data }) => {
        data.result.chapterType.forEach((item) => {
          if (item.value != "" && item.value != "常態課程") {
            this.chapterTypeList.push(item);
          }
        });
        this.chapterType = new Date().getFullYear(); // 當前年份
      });
    },
    getDeptList() {
      api.education
        .deptList({
          deptType: "", // 部門層級
          parentDeptId: "",
          companyId: "", // 公司代號
          deptName: "", // 部門名稱
          isShowCompany: 1, // 是否顯示公司類別 0:不顯示、1:顯示(可不傳 預設0)
        })
        .then(({ data }) => {
          this.deptList = [];
          data.result.forEach((item, index) => {
            item.courseId = this.data.courseId;
            item.doCourseDept = item.deptIdName;
            item.doCourseDeptId = item.deptId;
            this.deptList.push(item);
          });
        });
    },
    save() {
      if (this.data.courseName == "") {
        this.$eventBus.$emit("showAlert", "課程名稱不能為空");
        return;
      }

      if (!this.selectCourseType) {
        this.$eventBus.$emit("showAlert", "請選擇課程分類");
        return;
      }

      if (this.data.courseType == "年度課程") {
        this.data.courseType = this.chapterType;
      }

      // 0:不設定 1:單次 2:每月固定
      this.data.scheduleSendMailDate = "";
      this.data.scheduleSendMailDay = "";
      if (this.data.scheduleSendMailType == 1) {
        this.data.scheduleSendMailDate = this.scheduleSendMailDate;
      }
      if (this.data.scheduleSendMailType == 2) {
        this.data.scheduleSendMailDay = this.scheduleSendMailDay;
      }

      this.data.courseClass = this.selectCourseType;
      this.data.doCourseDeptList = this.selected;
      let type = this.isEdit ? "update" : "create";
      api.education.course[type](this.data).then((res) => {
        this.global.showResponse(this, res, type, this.back);
      });
    },
    back() {
      this.$router.push({
        name: "ETCourseList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>

<style>
#CourseEdit
  .form-horizontal
  .form-group
  .multiselect.multiselect--disabled
  .multiselect__tags {
  background: #ededed !important;
}
#CourseEdit .icheckbox_flat-blue {
  background: white;
  border: 2px solid #d2d6de;
  border-radius: 5px;
}
#CourseEdit .icheckbox_flat-blue.checked {
  background: #3498db;
  border-color: #3498db;
}
#CourseEdit .icheckbox_flat-blue.checked::before {
  content: "✔";
  color: white;
  font-size: 17px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: 1px;
}
</style>