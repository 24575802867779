<template>
  <div id="CourseRecordDept">
    <section class="content-header">
      <h1>上課紀錄(公司)</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">上課紀錄(公司)</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.companyId"
              >
                <option
                  :value="company.companyId"
                  v-for="(company, index) in companyList"
                  :key="index"
                >
                  {{ company.companyName }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋部門"
                type="text"
                v-model="searchData.deptName"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="getDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 15%">
                    部門
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    已完成必修課程人數
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    未完成必修課程人數
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    功能
                  </th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">{{ data.deptName }}</td>
                    <td class="text-center">{{ data.finishedCourseCount }}</td>
                    <td class="text-center">
                      {{ data.notFinishedCourseCount }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-warning" @click="goDetail(data)">
                        詳細
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        companyId: "A",
        deptName: "",
      },
      companyList: [],
      list: [],
      total: 0,
    };
  },
  async created() {
    if (this.$route.params.chaterSearchData != undefined) {
      this.searchData = this.$route.params.chaterSearchData;
    }

    this.getCompanyList();
    this.getDataList();
  },
  methods: {
    getCompanyList() {
      api.education.company().then(({ data }) => {
        this.companyList = data.result;
      });
    },
    getDataList() {
      this.loading = true;
      api.education.record.all.dept(this.searchData).then((res) => {
        this.list = res.data.result;
        this.loading = false;
      });
    },
    goDetail(item) {
      this.$router.push({
        name: "ETCourseRecordPersonal",
        params: {
          companyId: this.searchData.companyId,
          id: item.deptId,
          searchData: this.$route.params.searchData,
        },
      });
    },
    back() {
      this.$router.push({
        name: "ETStudentCourseList",
        params: {
          type: this.$route.params.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
