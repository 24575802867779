<template>
  <!--預定活動-->
  <div class="modal fade" ref="booking_activity">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">活動資訊</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td class="text-nowrap">活動類別</td>
                  <td>
                    {{ data.typeName }}-{{ data.kindName }}-{{
                      data.activityName
                    }}
                  </td>
                </tr>
                <tr>
                  <td>活動時間</td>
                  <td>
                    {{ getDuration(data.activitySTime, data.activityETime) }}
                  </td>
                </tr>
                <tr>
                  <td>活動地點</td>
                  <td>{{ data.location }}</td>
                </tr>
                <tr>
                  <td>報名時間</td>
                  <td>
                    {{ getDuration(data.bookingSTime, data.bookingETime) }}
                  </td>
                </tr>
                <tr>
                  <td>聯絡人</td>
                  <td>{{ data.contacter }}</td>
                </tr>
                <tr>
                  <td>報名對象</td>
                  <td>
                    {{
                      data.isLimit == 1
                        ? getJoinLimit(data.limitList)
                        : "全公司"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>備註</td>
                  <td>
                    <div class="editor" v-html="data.note"></div>
                  </td>
                </tr>
                <tr>
                  <td>人數限制</td>
                  <td>{{ getMaxLimit(data) }}</td>
                </tr>
                <tr>
                  <td>附件</td>
                  <td>
                    <a
                      :key="file.id"
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      class="file-icon"
                      v-for="file in data.files"
                      v-show="data.files.length > 0"
                    >
                      <i :class="'icon icon-' + file.mine.substring(0, 3)"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <p class="text-red pull-left mt-5">
            <i class="fa fa-warning"></i> 已過的時段無法取消報名。
          </p>
          <button
            class="btn btn-danger"
            type="button"
            v-if="cancelBooking"
            @click="cancelBookingActivity(data)"
          >
            取消報名
          </button>
          <button
            @click="bookingActivity(data.activityId)"
            class="btn btn-primary"
            type="button"
            v-else-if="booking"
          >
            確定報名
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      data: {},
      cancelBooking: false,
      booking: false
    };
  },
  methods: {
    showActivityBooking(item) {
      if (item == null || item.activityId == "") {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      api.activity
        .query({
          activityId: item.activityId
        })
        .then(res => {
          if (res.data.status == "1") {
            this.data = res.data.result;

            if (this.data.status != 9) {
              this.cancelBooking =
                new moment().isBefore(this.data.bookingETime) &&
                this.data.joinStatus >= 0;
              this.booking =
                new moment().isBefore(this.data.bookingETime) &&
                this.data.joinStatus == -1 &&
                ((this.data.maxLimit == 1 &&
                  this.data.maxCount > this.data.joinCount) ||
                  this.data.isLimit == 0 ||
                  this.data.maxLimit == 0);
            }
            $(this.$refs.booking_activity).modal("show");
          } else {
            this.$eventBus.$emit("showAlert", "查無資料!");
            return;
          }
        });
    },
    getDuration(start, end) {
      if (moment(start).isSame(end, "day")) {
        return (
          utils.formatTime(start, "YYYY-MM-DD") +
          " " +
          utils.formatTime(start, "HH:mm") +
          "~" +
          utils.formatTime(end, "HH:mm")
        );
      } else {
        return (
          utils.formatTime(start, "YYYY-MM-DD HH:mm") +
          "~" +
          utils.formatTime(end, "YYYY-MM-DD HH:mm")
        );
      }
    },
    getJoinLimit(limits) {
      let arr = [];
      limits.forEach(limit => {
        arr.push(limit.setName);
      });

      return arr.join(",");
    },
    getMaxLimit(item) {
      switch (item.maxLimit) {
        case 0:
          return "不限";
        case 1:
          if (item.maxCount == item.joinCount)
            return item.maxCount + "人(已額滿)";
          else {
            let peopleCount = item.maxCount - item.joinCount;
            return (
              item.maxCount +
              "人(名額剩餘：" +
              (peopleCount < 0 ? 0 : peopleCount) +
              "人)"
            );
          }
      }
    },
    bookingActivity(activityId) {
      api.activity
        .createJoin({
          activityId: activityId
        })
        .then(res => {
          if (res.data.status == "1") {
            this.$eventBus.$emit(
              "showAlert",
              "您已報名成功！可至「社團活動 > 我的活動歷程」查看。"
            );
            this.$emit("refresh");
            $(this.$refs.booking_activity).modal("hide");
            return;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        });
    },

    cancelBookingActivity(data) {
      api.activity.cancelJoin(data).then(res => {
        if (res.data.status == "1") {
          this.$eventBus.$emit(
            "showAlert",
            "您已成功取消報名！可至「社團活動 > 我的活動歷程」查看。"
          );
          this.$emit("refresh");
          $(this.$refs.booking_activity).modal("hide");
          return;
        } else {
          this.$eventBus.$emit("showAlert", res.data.message);
        }
      });
    }
  }
};
</script>
