<template>
  <div id="ActivitySetting">
    <section class="content-header">
      <h1>維護活動分類</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li class="active">維護活動分類</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-cog"
              type="button"
              @click="AddActivityType"
            >
              <i class="fa fa-plus"></i> 新增細項
            </button>
            <button
              class="btn btn-block bg-purple btn-add"
              type="button"
              @click="goActivityType()"
            >
              <i class="fa fa-plus"></i> 新增類別
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min-600">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" width="20%">活動類別</th>
                  <th class="text-nowrap" width="20%">活動細項</th>
                  <th class="text-nowrap" width="20%" colspan="2">承辦人員</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%;"
                  >
                    啟用狀態
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 20%;"
                  >
                    功能
                  </th>
                </tr>
                <tr v-for="(item, index) in activityKinds" :key="index">
                  <td>
                    <p class="m-0" v-show="!item.editor">{{ item.typeName }}</p>
                    <select
                      class="form-control"
                      v-show="item.editor"
                      v-model="item.typeId"
                    >
                      <option
                        :value="activityType.typeId"
                        :key="index"
                        v-for="(activityType, index) in activityTypes"
                        >{{ activityType.typeName }}</option
                      >
                    </select>
                  </td>
                  <td>
                    <p class="m-0" v-show="!item.editor">{{ item.kindName }}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-show="item.editor"
                      v-model="item.kindName"
                    />
                  </td>
                  <td class="text-nowrap">{{ item.manager }}</td>
                  <td class="text-center">
                    <button
                      title="編輯"
                      class="btn btn-primary"
                      :disabled="item.isNew"
                      @click="openActivityMember(item)"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        name="ckbx-style-8"
                        :id="'check' + index"
                        type="checkbox"
                        :false-value="1"
                        :true-value="0"
                        @change="save(item)"
                        v-model="item.isStop"
                      />
                      <label :for="'check' + index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="!item.editor"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <!-- <button
                      @click="deleteType()"
                      class="btn btn-danger"
                      v-show="editor==0"
                    >刪除</button>-->
                    <button
                      v-on:click="QueryAll()"
                      class="btn btn-default"
                      v-show="item.editor"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <activityMember ref="activityMember" v-on:refresh="QueryAll()" />
  </div>
</template>

<script>
import activityMember from "@/views/Activity/ActivityMember";
import api from "../../assets/js/app-api";

export default {
  delimiters: ["${", "}"],
  components: {
    activityMember
  },
  data() {
    return {
      activityKinds: [],
      activityTypes: []
    };
  },
  created() {
    this.QueryAll();
  },
  methods: {
    async QueryAll() {
      // 活動類別
      let activityTypes = await api.activity.queryAllTypeEdit({
        isStop: 0
      });
      this.activityTypes = activityTypes.data.result.datas;

      let activityManager = await api.activity.queryAllManager({});

      // 活動類別細項
      let activityKinds = await api.activity.queryAllKind({});
      let tmp = activityKinds.data.result.datas;
      for (var i = 0; i < tmp.length; i++) {
        let managers = activityManager.data.result.datas.filter(
          data => data.kindId == tmp[i].kindId
        );
        let manager = [];

        if (managers.length > 0) {
          for (var o = 0; o < managers.length; o++) {
            manager.push(managers[o].managerEmpName);
          }
        }

        tmp[i].editor = false;
        tmp[i].isNew = false;
        tmp[i].manager = manager.join(", ");
      }
      this.activityKinds = JSON.parse(JSON.stringify(tmp));
    },
    AddActivityType() {
      if (this.activityKinds.some(type => type.editor === true)) {
        this.$eventBus.$emit("showAlert", "請先儲存上一筆資料!");
        return;
      }

      let data = {
        kindId: "",
        kindName: "",
        typeId: "",
        orderNum: 1,
        isStop: 0,
        editor: true,
        isNew: true
      };

      this.activityKinds.push(data);
    },
    // 切換文字或輸入框
    change(item) {
      if (this.activityKinds.some(type => type.editor === true)) {
        this.$eventBus.$emit("showAlert", "請先儲存上一筆資料!");
        return;
      }

      item.editor = item.editor ? false : true;
    },
    // 儲存
    async save(item) {
      if (item.typeId == "" || item.typeId == null) {
        this.$eventBus.$emit("showAlert", "請選擇活動類型!");
        return;
      }

      if (item.kindName == "" || item.kindName == null) {
        this.$eventBus.$emit("showAlert", "請填寫活動細項!");
        return;
      }

      let response;

      if (item.isNew) {
        response = await api.activity.createKind(item);
      } else response = await api.activity.updateKind(item);

      if (response.data.status == "1") {
        this.$eventBus.$emit(
          "showAlert",
          item.isNew ? "新增成功!" : "儲存成功!"
        );
        this.QueryAll();
      } else this.$eventBus.$emit("showAlert", response.data.message);
    },
    openActivityMember(item) {
      this.$refs.activityMember.showActivityMember(item);
    },
    goActivityType() {
      this.$router.push({
        name: "ActivityType"
      });
    }
  }
};
</script>
