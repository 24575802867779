var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.empSelectedId,
          expression: "empSelectedId",
        },
      ],
      staticClass: "form-control",
      attrs: { required: "required" },
      on: {
        change: [
          function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.empSelectedId = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          function ($event) {
            return _vm.$emit("update:selectedId", _vm.empSelectedId)
          },
        ],
      },
    },
    _vm._l(_vm.empOptions, function (dept, index) {
      return _c(
        "option",
        { key: index, domProps: { value: dept.employeeId } },
        [_vm._v(_vm._s(dept.employeeName))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }