<template>
  <div class="modal fade" ref="scoreAddContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增加分人員</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label mw150">
                      被加分人員
                    </td>
                    <td colspan="4">
                      <select
                        class="form-control mw150"
                        v-model="submitData.employee"
                      >
                        <option value="">請選擇人員</option>
                        <option
                          v-for="user in users"
                          :value="user"
                          > 
                          {{ user.employeeName }}
                        </option>
                  </select>
                </td>
                  </tr>
                  <tr>
                    <td class="control-label mw150">
                      加分人員
                    </td>
                    <td colspan="2">
                      <select
                      class="form-control mw150"
                      v-model="searchData.deptId"
                      @change="getUserList()"
                      >
                        <option value="">全部</option>
                        <option
                          v-for="item in deptList"
                          :value="item.deptId"
                        > 
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                    <td colspan="2">
                      <div class="input-group">
                        <input
                          class="form-control pull-right"
                          name="table_search"
                          placeholder="關鍵字"
                          type="text"
                          v-model="searchData.keyword"
                        />
                        <div class="input-group-btn">
                          <button class="btn btn-default" type="button" @click="getUserList()">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label mw150"></td>
                    <td colspan="4">
                      <select
                        class="form-control mw150"
                        v-model="submitData.rater"
                      >
                        <option value="">請選擇人員</option>
                        <option
                          v-for="user in userList"
                          :value="user"
                          > 
                          {{ user.employeeName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label mw150">
                      備註
                    </td>
                    <td colspan="4">
                      <textarea class="form-control" v-model="submitData.note"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">新增</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
              @click="resetData()"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props:['users'],
  data() {
    return {
      years: this.global.getYearList(),
      months: [],
      deptList: [],
      userList: [],
      searchData: {
        deptId: "",
        keyword: "",
        pageNum: 1,
        pageSize: 200,
      },
      submitData: {
        employee: "",
        employeeId: "", //被加分人工號
        employeeName: "", //被加分人姓名
        rater: "",
        raterEmpId: "", //加分人工號
        raterEmpName: "", //加分人姓名
        note: '',
      },
      userData: [],
    };
  },
  created() {
    this.userData = this.$user.getUserData();
  },
  mounted() {
    this.getList();
  },
  methods: {
    async showContent() {
      this.getList();
      $(this.$refs.scoreAddContent).modal("show");
    },
    getList() {
      api.okr.goal.department
        .list({
          deptLevel: 2,
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.deptList = res.data.result || [];
            this.getUserList();
          }
        });
    },
    getUserList() {
      this.searchData.companyId = this.userData.user.companyId;
      api.organization.queryEmployeeAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.userList = res.data.result.datas || [];
        }
      });
    },
    resetData() {
      this.searchData.keyword = '';
      this.searchData.deptId = '';
    },
    submit() {
      if(this.submitData.employee == '') {
        this.$eventBus.$emit("showAlert", "請選擇被加分人員");
        return
      }
      if(this.submitData.rater == '') {
        this.$eventBus.$emit("showAlert", "請選擇加分人員");
        return
      }
      this.submitData.employeeId = this.submitData.employee.employeeId;
      this.submitData.employeeName = this.submitData.employee.employeeName;
      this.submitData.raterEmpId = this.submitData.rater.employeeId;
      this.submitData.raterEmpName = this.submitData.rater.employeeName;

      api.okr.v2.rater.create(this.submitData)
        .then((res) => {
          this.global.showResponse(this, res);
          if(res.data.status == '1') {
            this.global.showResponse(this, res, 'create');
            $(this.$refs.scoreAddContent).modal("hide");
            this.$emit("updateList");
          }
        })
      return
    },
  },
};
</script>