<template>
  <div id="ScorePersonalEdit">
    <section class="content-header">
      <h1>加分考核表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <!-- <router-link > -->
          <!-- <router-link :to="`/OKR/ScorePersonalList/${person.type}`"> -->
            加分考核表
          <!-- </router-link> -->
        </li>
        <li class="active">
          {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}考核
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}考核
            </h4>
            <button
              class="btn btn-block btn-default btn-cog"
              type="button"
							@click="backList()"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <div>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td>
                      <table class="table no-border" style="width: 400px">
                        <tbody>
                          <tr>
                            <th class="tr w90">員工編號：</th>
                            <td v-text="data.employeeId"></td>
                            <th class="tr">部別：</th>
                            <td v-text="data.deptName"></td>
                          </tr>
                          <tr>
                            <th class="tr">員工姓名：</th>
                            <td v-text="data.employeeName"></td>
                            <th class="tr">狀態：</th>
                            <td v-text="define.sendStatus[data.scoreStatus || 0]"></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
										<td>
											<table class="table table-bordered">
												<tbody>
													<tr>
														<th class="tc mw100 bg-gray">考核說明</th>
														<td class="mw50">
															1.請於『說明』欄位中描述清楚人員加減分之緣由。<br>
															2.單次加減分分數範圍以正負1~3分為限。<br>
															3.考核加減分可隨時進行，且無次數限制，人員可能會有多筆考核紀錄。
														</td>
													</tr>
												</tbody>
											</table>
                    </td>
                  </tr>
                </tbody>
              </table>
							<div>
								<div class="box-footer mt-15"></div>
								<form class="form-horizontal">
									<div class="box-body">
										<div class="form-group mb0">
											<div class="col-sm-12">
												<div class="table-responsive">
													<table
														class="table table-hover table-bordered"
														style="margin: 10px auto 20px;"
													>
														<tbody>
															<tr class="bg-gray">
																<th class="tc mw50">加分人員</th>
																<th class="tc mw50" style="width: 150px;">分數</th>
																<th class="tc mw150">說明</th>
															</tr>
															<tr v-for="item in scoreList" v-if="scoreList.length > 0">
																<td class="tc">{{ item.createEmpName }}</td>
																<td class="tc">
																	<input
																		v-if="hasEdit && (item.createEmpId == userData.user.employeeId)"
																		type="number"
																		class="form-control fw-b fz25"
																		v-model="item.totalScore"
																		max="3"
																		min="-3"
																	>
																	<div v-else>
																		{{ item.totalScore }}
																	</div>
																</td>
																<td class="tc">
																	<textarea
																		v-if="hasEdit && (item.createEmpId == userData.user.employeeId)"
																		class="form-control"
																		v-model="item.note"
																	></textarea>
																	<div v-else>
																		{{ item.note }}
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div>
								<SignLog
									:datas="scoreList"
									:data="data"
									:hasEdit="hasEdit"
									:backList="backList"
									:scoreDatas="scoreList"
								/>
							</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SignLog from "@/components/SignLog";

import api from "@/assets/js/app-api";

export default {
	components: {
		SignLog,
  },
  data() {
    return {
      id: this.$route.params.id,
			hasEdit: this.$route.params.edit || 0,
			searchData: {
				year : Number(this.$route.params.year) || 0,
				month: Number(this.$route.params.month) || 0,//0:上半年，1:下半年
				employeeId: this.$route.params.id,
				ScoreGroupId: this.$route.params.formId || '',
				back: '',
			},
			data: this.$route.params.data,
			scoreList: [],
			userData: {},
			hasAdd: false,
    };
  },
	watch: {
		"addData.TotalScore"(val) {
			if(val > 3) this.addData.TotalScore = 3;
			if(val < -3) this.addData.TotalScore = -3;
		}
	},
  created() {
    this.userData = this.$user.getUserData();
		if(this.searchData.ScoreGroupId == '') this.hasEdit = true;
		this.data.deptLevel = 30;
		if(this.searchData.ScoreGroupId != '') this.getData();
		this.getScoreList();
  },
	mounted() {
	},
  methods: {
		getData() {
			api.okr.v2.big.query(this.searchData)
				.then((res) => {
					this.data = res.data.result.group
					this.addData.ScoreGroupId = res.data.result.group.scoreGroupId
				})
		},
		getScoreList() {
			api.okr.score.min.monthQueryAll({
				ScoreGroupId: this.searchData.ScoreGroupId,
				deptLevel: 30,
				monthStatus: 1, //0:隱藏分數 1:顯示分數
			})
				.then((res) => {
					if(this.$route.params.listType == 'read') {
						this.scoreList = res.data.result.datas
						return
					}
					if(res.data.result == null) {
						this.scoreList.push({
							createEmpName: this.userData.user.employeeName,
							createEmpId: this.userData.user.employeeId
						})
					} else {
						this.scoreList = res.data.result.datas
						let isNew = this.scoreList.findIndex(item => item.createEmpId == this.userData.user.employeeId)
						if(isNew < 0) {
							this.scoreList.push({
								createEmpName: this.userData.user.employeeName,
								createEmpId: this.userData.user.employeeId
							})
						}
					}
				})
		},
		backList() {
      this.$router.push({
        name: this.$route.params.back || "Score",
				params: {
          year: this.$route.params.back == 'ScoreList' ? this.$route.params.year : '',
          month: this.$route.params.back == 'ScoreList' ? this.$route.params.month : '',
          listType: this.$route.params.listType || '',
          searchData: this.$route.params.searchData
        },
      });
    },
  },
};
</script>