<template>
  <div id="StoreMenu">
    <section class="content-header">
      <h1>店家管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li>
          <router-link to="/Stores">店家管理</router-link>
        </li>
        <li class="active">菜單管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning store-menu max850">
        <div class="box-body">
          <div class="top-box two-btn">
            <h4 class="pull-left">{{ storeName }} - 菜單列表</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              @click="openMealInfo('0')"
              class="btn btn-block btn-warning btn-cog"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增餐點
            </button>
            <div class="text">
              <p class="data-num">資料總筆數：{{ total }}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered"
              style="min-width: 650px"
            >
              <tbody>
                <tr>
                  <th class="text-nowrap" style="width: 10%">圖片</th>
                  <th class="text-nowrap">名稱</th>
                  <th class="text-nowrap">售價</th>
                  <th class="text-nowrap">可訂數量</th>
                  <th class="text-nowrap">種類</th>
                  <th class="text-nowrap">平均評分</th>
                  <th class="text-center text-nowrap">上架狀態</th>
                  <th class="text-center" style="min-width: 160px">功能</th>
                </tr>
                <tr :key="meal.mealId" v-for="meal in menuList">
                  <td>
                    <div class="pic jqimgFill">
                      <img
                        :key="file.filesId"
                        :src="'https://dev-eip.wanin.tw/' + file.filesUrl"
                        draggable="false"
                        v-for="file in meal.files"
                      />
                    </div>
                  </td>
                  <td>{{ meal.mealName }}</td>
                  <td>{{ meal.price }}</td>
                  <td>{{ meal.amount == null ? "不限" : meal.amount }}</td>
                  <td>{{ meal.mealType == "1" ? "葷" : "素" }}</td>
                  <td>{{ meal.averageScore }}</td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'meal' + meal.mealId"
                        :true-value="1"
                        @change="changeStatus(meal)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="meal.mealStatus"
                      />
                      <label :for="'meal' + meal.mealId"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-warning"
                      title="餐點評比"
                      @click="openOrderScore(meal.mealId)"
                    >
                      <i class="fa fa-star-half-o"></i>
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-primary"
                      title="編輯"
                      @click="openMealInfo(meal.mealId)"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                    &nbsp;
                    <button
                      @click="deleteDine(meal)"
                      class="btn btn-danger"
                      title="刪除"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <storeEditMenu ref="storeEditMenu" v-on:getStoreMenu="getStoreMenu" />
    <orderScore ref="orderScore" v-on:refreshList="getStoreMenu" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import storeEditMenu from "@/views/OrderSystem/StoreEditMenu";
import orderScore from "@/views/OrderSystem/OrderScore";

export default {
  components: {
    storeEditMenu,
    orderScore,
  },
  data() {
    return {
      storeId: this.$route.params.id,
      storeName: "",
      total: 0,
      menuList: [],
    };
  },
  created() {
    this.getStoreMenu();
  },
  methods: {
    getStoreMenu() {
      // 取得店家菜單
      api.storeMenu
        .queryAll({
          storeId: this.storeId,
        })
        .then((res) => {
          this.menuList = res.data.result.datas;
          this.total = res.data.result.total;
          if (this.menuList.length > 0)
            this.storeName = this.menuList[0].storeName;
        })
        .finally(() => {
          // imgLiquid
          $(".jqimgFill").imgLiquid();
        });
    },
    addDine(dine) {
      // 添加店家餐點

      if (!this.checkDineInfo(dine)) return;

      api.storeMenu.create(dine).then((res) => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        }
        this.getStoreMenu();
      });
    },
    updateDine(dine) {
      // 更新店家餐點

      if (!this.checkDineInfo(dine)) return;

      api.storeMenu.update(dine).then((res) => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        }
        this.getStoreMenu();
      });
    },
    deleteDine(dine) {
      // 移除店家餐點
      let that = this;

      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", function (res) {
        if (res) {
          api.storeMenu.delete(dine).then((res) => {
            if (res.data.status != "1") {
              that.$eventBus.$emit("showAlert", "Fail!");
            }
            that.getStoreMenu();
          });
        }
      });
    },
    openMealInfo(mealId) {
      this.$refs.storeEditMenu.removeFile();
      this.$refs.storeEditMenu.showMealEditBox(this.storeId, mealId);
    },
    openOrderScore(mealId) {
      this.$refs.orderScore.showMealScoreBox(mealId, true);
    },
    changeStatus(item) {
      this.updateDine(item);
    },
    backList() {
      this.$router.push({
        name: "Stores",
        params: { searchData: this.$route.params.searchData },
      });
    },
    formatDateTime(datetime, format) {
      return utils.formatTime(datetime, format);
    },
    checkDineInfo(dine) {
      if (dine == null || dine == undefined) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return false;
      }
      if (dine.mealName == undefined || dine.mealName == "") {
        this.$eventBus.$emit("showAlert", "餐點名稱不可為空!!");
        return false;
      }

      if (dine.price == undefined || dine.price == 0 || dine.price == "") {
        this.$eventBus.$emit("showAlert", "請填寫餐點售價!!");
        return false;
      }

      if (dine.mealType == undefined || dine.mealType.toString() == "") {
        this.$eventBus.$emit("showAlert", "餐點種類不可為空!!");
        return false;
      }

      return true;
    },
  },
};
</script>
