<template>
  <div id="Score">
    <section class="content-header">
      <h1>考核作業</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">考核作業</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="getDataList()"
                  style="margin-left: 0"
                >
                  <option value="">全部</option>
                  <option
                    v-for="(item, index) in deptList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model.number="searchData.year"
                  @change="getDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model.number="searchData.month"
                  @change="getDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
              </div>
              <!-- <div class="text">
                年分：
                <p class="data-num">{{ searchData.year }}</p>
                <p class="data-num">{{ searchData.month == 0 ? '上半年' : '下半年' }}</p>
              </div> -->
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw100">部門</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw90">總分</th>
                  <th class="tc mw150">最新加分時間</th>
                  <th class="tc mw150">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="8">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in list" :key="index">
                  <td class="tc">{{ data.deptName }}</td>
                  <td class="tc">{{ data.employeeId }}</td>
                  <td class="tc">{{ data.employeeName }}</td>
                  <td class="tc">
                    <span
                      v-if="data.isManagerForm == 0 || data.totalScore == null"
                    >
                      -
                    </span>
                    <span
                      v-else
                      style="cursor: pointer; text-decoration: underline"
                      class="text-primary"
                      @click="showContent(data)"
                    >
                      {{ data.totalScore ? data.totalScore : "0" }}
                    </span>
                  </td>
                  <td class="tc">
                    <span v-if="data.createTime">{{
                      new Date(data.createTime)
                        | dateFormat("YYYY/MM/DD HH:mm:ss")
                    }}</span>
                    <span v-else> - </span>
                  </td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      @click="updateData(data)"
                      v-if="isEdit"
                    >
                      加分
                    </button>
                    <span v-else> - </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <ScorePersonalHistory ref="ScorePersonalHistory" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import ScorePersonalHistory from "./ScorePersonalHistory";
import Pagination from "@/components/Pagination";

export default {
  components: {
    ScorePersonalHistory,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getNowHalfYear(),
        month: this.global.getHalfYear(),
        status: -1,
        employeeId: "",
        deptId: "",
      },
      list: [],
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      // addData: {},
      userData: {},
      permission: {},
      deptList: [],
      users: [],
      datas: [],
      isEdit: false,
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(
      this.userData,
      this.person.limit
    );
    this.permission.isManager =
      !this.permission.isEmployee && this.person.level === 3;
    this.searchData.parentDeptId = this.permission.isDivisionManager
      ? this.userData.user.deptId
      : null;
    this.getList();
  },
  methods: {
    thisYear() {
      if (
        this.searchData.year == this.global.getNowHalfYear() &&
        this.searchData.month == this.global.getHalfYear()
      )
        return true;
    },
    getList() {
      api.okr.v2.rater.deptQueryAll().then((res) => {
        if (res.data.status === "1") {
          this.deptList = res.data.result || [];
          this.updateListSearch();
          this.getDataList();
        }
      });
    },
    getDataList() {
      this.loading = true;
      api.okr.v2.rater.ScoreQueryAll(this.searchData).then(({ data }) => {
        this.list = data.result.datas;
        this.isEdit = data.result.isUpdate == 1;
        this.loading = false;
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateListSearch() {
      if (
        this.person.level !== 9 &&
        this.loading &&
        !this.$route.params.searchData
      ) {
        this.searchData.deptId = this.global.getDeptId(
          this.deptList,
          this.userData,
          this.permission
        );
        if (!this.searchData.deptId && this.deptList.length) {
          this.searchData.deptId = this.deptList[0].deptId || "";
        }
      }
    },
    showContent(data) {
      this.$refs.ScorePersonalHistory.showContent(data, this.searchData);
    },
    updateData(item) {
      this.$router.push({
        name: "ScoreEdit",
        params: {
          id: item.employeeId,
          year: this.searchData.year,
          month: this.searchData.month,
          back: "Score",
          data: item,
          edit: 1,
        },
      });
    },
  },
};
</script>