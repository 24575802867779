<template>
  <div id="FlowEdit">
    <section class="content-header">
      <h1>簽核群組流程設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/OKR/GroupSet">簽核群組流程設定</router-link>
        </li>
        <li class="active">流程設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">流程設定</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <form
            class="form-horizontal"
            method="post"
            @submit.prevent="saveData()"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  流程名稱：
                </label>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    disabled
                    v-model="data.flowId"
                  >
                    <option value="績效考核流程">績效考核流程</option>
                  </select>
                </div>
                <!-- <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  是否啟用：
                </label>
                <div class="col-sm-3">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox
                      propValue="1"
                      :propNone="!hasEdit"
                      :propChecked="data.checked"
                      :propDisabled="data.disabled"
                      v-on:update:checked="checkChange"
                    />
                  </label>
                </div> -->
              </div>
              <div class="form-group" style="margin: 40px 0">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-8">
                  <div class="flowContent x_content">
                    <ul class="process-map list-unstyled">
                      <li class="start bg-green">開始</li>
                      <li class="add">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-if="hasEdit"
                          @click="showContent(null, 0.5)"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </li>
                      <template v-for="(item, index) in datas">
                        <li :key="index">
                          <div class="level">第{{ item.orderNum }}關</div>
                          <p class="name">{{ item.empName }}</p>
                          <div class="tools" v-if="hasEdit">
                            <button
                              type="button"
                              class="bg-blue"
                              @click="showContent(item, item.orderNum)"
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              class="bg-red ml10"
                              @click="deleteData(item)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                          <div class="stop-type">
                            <div>
                              <label class="checkbox checkbox-inline">
                                <iCheckCheckBox
                                  propValue="0"
                                  :propItem="[item, 'min']"
                                  :propChecked="checkFlowStops(item, '小考')"
                                  v-on:update:checked="checkChange2"
                                />小考核決人員
                              </label>
                            </div>
                            <div>
                              <label class="checkbox checkbox-inline">
                                <iCheckCheckBox
                                  propValue="1"
                                  :propItem="[item, 'max']"
                                  :propChecked="checkFlowStops(item, '大考')"
                                  v-on:update:checked="checkChange2"
                                />
                                大考核決人員
                              </label>
                            </div>
                          </div>
                          <span class="name" v-if="item.note" >
                            （{{ item.note }}）
                          </span>
                        </li>
                        <li :key="item.groupFlowId" class="add">
                          <button
                            type="button"
                            class="btn btn-default"
                            v-if="hasEdit"
                            @click="showContent(null, item.orderNum + 0.5)"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </li>
                      </template>
                      <li class="end bg-gray">結束</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <input
                  type="button"
                  value="確認"
                  class="btn btn-primary"
                  v-if="!hasEdit"
                  @click="backList()"
                />
                <input
                  type="submit"
                  value="確認送出"
                  class="btn btn-primary"
                  v-if="hasEdit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <FlowContent ref="flowContent" v-on:updateList="updateData" />
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import api from "@/assets/js/app-api";
import FlowContent from "./FlowContent";

export default {
  components: {
    iCheckCheckBox,
    FlowContent,
  },
  data() {
    return {
      hasEdit: false,
      loading: true,
      total: 0,
      addData: {
        groupId: this.$route.params.groupId,
      },
      id: this.$route.params.id,
      userData: {},
      permission: {},
      datas: [],
      data: {},
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasEdit =
      !this.id ||
      this.permission.deptIds.includes(this.id) ||
      this.permission.isHR;
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        api.okr.mgmt.group.query(this.addData).then((res) => {
          if (res.data.status === "1") {
            this.data = res.data.result || {};
            this.getDataList();
          }
        });
      } else {
        this.loading = false;
      }
    },
    getDataList() {
      api.okr.mgmt.flow.queryAll(this.data).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result || [];
          this.data.checked = this.data.isRunFlow ? true : false;
          this.data.disabled =
            !this.permission.deptIds.includes(this.id) && !this.permission.isHR;
          this.loading = false;
          this.datas.forEach(data => {
            if(data.flowStops.length > 0) {
              data.FlowStops = []
              data.flowStops.forEach(item => {
                if(item.stopType == '小考') data.min = 1
                if(item.stopType == '大考') data.max = 1
              })
            }
          })
        }
      });
    },
    resetDataList() {
      this.datas = [];
      this.data = [];
      this.getData();
    },
    checkChange(data) {
      this.data.isRunFlow = data.checked;
    },
    checkChange2(data) {
      if(data.item[1] == 'min') {
        data.item[0].min = data.checked
      }
      if(data.item[1] == 'max') {
        data.item[0].max = data.checked
      }
    },
    showContent(data, orderNum) {
      this.addData.orderNum = orderNum;
      this.addData.deptId = this.id || this.userData.user.deptId;
      this.$refs.flowContent.showContent(data, this.addData);
    },
    updateData(data) {
      const orderNum = Math.floor(data.orderNum);
      if (orderNum === 0) {
        this.datas.splice(0, 0, data);
      } else {
        for (let i = 0; i < this.datas.length; i++) {
          if (data.orderNum === this.datas[i].orderNum) {
            this.datas.splice(i, 1, data);
            break;
          } else if (orderNum === this.datas[i].orderNum) {
            this.datas.splice(i + 1, 0, data);
            break;
          }
        }
      }
      this.sortData();
    },
    deleteData(data) {
      const index = this.datas.indexOf(data);
      if (index !== -1) {
        this.datas.splice(index, 1);
        this.sortData();
      }
    },
    sortData() {
      this.datas.forEach((data, index) => {
        data.orderNum = index + 1;
      });
    },
    checkFlowStops(data, type) {
      if(!data.flowStops) return
      var state = false
      data.flowStops.forEach(item => {
        if(item.stopType == type) state = true
      })
      return state
    },
    saveData() {
      this.datas.forEach(item => {
        item.flowStops = []
        if(item.min == 1) item.flowStops.push({StopType:"小考"})
        if(item.max == 1) item.flowStops.push({StopType:"大考"})
      })
      this.data.groupFlows = this.datas;
      api.okr.mgmt.flow.update(this.data).then((res) => {
        this.global.showResponse(this, res, "update");
        this.resetDataList();
      });
    },
    backList() {
      this.$router.push({
        name: "GroupSet",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
