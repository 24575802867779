<template>
  <div id="OrderDinner">
    <section class="content-header">
      <h1>預訂今天晚餐</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務</li>
        <li class="active">預訂今天晚餐</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box two-btn">
            <div class="text">
              <p class="text-red">
                <i class="fa fa-warning"></i>
                訂餐截止時間：{{ orderDeadline }}。
              </p>
            </div>
            <button
              class="btn btn-block btn-primary btn-cog"
              type="button"
              @click="goBack()"
            >
              <i class="fa fa-list"></i> 訂餐歷程
            </button>
            <button
              class="btn btn-block btn-default btn-cog"
              type="button"
              @click="openEmployeeHabits()"
            >
              <i class="fa fa-gear"></i> 個人飲食習慣
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min-600">
              <tbody>
                <tr class="bg-gray">
                  <th>用餐日期</th>
                  <th class="text-center">星期</th>
                  <th>店家名稱</th>
                  <th>餐點名稱</th>
                  <th class="text-center">瀏覽菜單</th>
                </tr>
                <tr>
                  <td>{{ formatDateTime(new Date(), "YYYY/MM/DD") }}</td>
                  <td class="text-center">
                    {{ formatDateTime(new Date(), "dd") }}
                  </td>
                  <td>
                    <ul
                      class="store-name list-unstyled"
                      v-if="datas != undefined"
                    >
                      <li :key="store.storeId" v-for="store in datas.stores">
                        {{ store.storeName }}
                      </li>
                    </ul>
                    <span class="text-red" v-else>
                      <i class="fa fa-warning text-red"></i> 尚未設定
                    </span>
                  </td>
                  <td
                    v-if="
                      total == 0 || !checkDateIsToday(orderList.datas[0].eatDay)
                    "
                  >
                    <span class="text-red">
                      <i class="fa fa-warning text-red"></i> 尚未選擇
                    </span>
                  </td>
                  <td v-else>{{ orderList.datas[0].mealName }}</td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-success"
                      title="瀏覽菜單"
                      @click="goOrderMenu()"
                      v-if="datas != undefined"
                    >
                      <i class="fa fa-book"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center">
          <Pagination
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <employeeHabits ref="employeeHabits" />
    <orderScore ref="orderScore" v-on:refreshList="getOrderList" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";
import employeeHabits from "@/views/OrderSystem/EmployeeHabits";
import orderScore from "@/views/OrderSystem/OrderScore";

export default {
  components: {
    orderScore,
    Pagination,
    employeeHabits,
  },
  data() {
    return {
      expatId: this.$user.getUserExpatId(),
      orderList: {},
      datas: {},
      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數
      page: 1,
      orderDeadline: "2019/07/25 18:00",
    };
  },
  created() {
    this.getDinner();
    this.getDeadline();
    this.getOrderList();
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.page = pageIdx;
      this.pageSize = pageSize;
      this.getOrderList();
    },
    async getDinner() {
      var res = await api.mealDailySetting.queryAll({
        eatStartDay: this.formatDateTime(new Date(), "YYYY/MM/DD"),
        eatEndDay: this.formatDateTime(new Date(), "YYYY/MM/DD"),
        eatTime: "2",
      });
      this.datas = res.data.result.datas[0];
    },
    getOrderList() {
      api.empOrder
        .queryAll({
          companyId: this.expatId,
          employeeId: this.$store.state.loginUser.employeeId,
          eatTime: 2,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.orderList = res.data.result;
            this.total = this.orderList.total;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getDeadline() {
      api.mealTimeSet.queryDinner({}).then((res) => {
        if (res.data.status == "1") {
          this.orderDeadline = new moment().format(
            "YYYY/MM/DD " + res.data.result.hourEnd + ":00:00"
          );
        }
      });
    },
    openEmployeeHabits() {
      this.$refs.employeeHabits.showHabitsEditBox();
    },
    openOrderScore(mealId) {
      this.$refs.orderScore.showMealScoreBox(mealId);
    },
    async goOrderMenu() {
      if (this.expatId !== "J") {
        var task_check = api.mealTimeSet.checkDinnerTime({});
        var response = await task_check;
        if (
          response.data.status != "1" ||
          response.data.result.checkStatus != "1"
        ) {
          this.$eventBus.$emit("showAlert", "已超過訂餐時間!!");
          return;
        }
      }

      this.$router.push({
        name: "OrderDinnerMenu",
      });
      return;
    },
    goBack() {
      this.$router.push({
        name: "Order",
        params: { expatId: this.expatId },
      });
      return;
    },
    formatDateTime(datetime, format) {
      return utils.formatTime(datetime, format, "zh-tw");
    },
    checkDateIsToday(date) {
      return (
        new moment(date).format("YYYY/MM/DD") ==
        new moment().format("YYYY/MM/DD")
      );
    },
  },
};
</script>
