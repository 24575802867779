<template>
  <div id="ActivityType">
    <section class="content-header">
      <h1>活動類別設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li class="active">維護活動分類</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button class="btn btn-block btn-default btn-cog" type="button" @click="goBack()">
              <i class="fa fa-reply"></i> 返回
            </button>
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="AddActivityType"
            >
              <i class="fa fa-plus"></i> 新增類別
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min-600">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" width="70%">活動類別名稱</th>
                  <th class="text-nowrap text-center" style="min-width: 90px; width:10%">排序</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <tr v-for="(item, index) in activityTypes" :key="index">
                  <td>
                    <p class="m-0" v-show="!item.editor">{{item.typeName}}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-show="item.editor"
                      v-model="item.typeName"
                    />
                  </td>
                  <td class="text-center">
                    <p class="m-0" v-show="!item.editor">{{item.orderNum}}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="number"
                      v-show="item.editor"
                      v-model="item.orderNum"
                    />
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        name="ckbx-style-8"
                        :id="'check'+index"
                        type="checkbox"
                        :false-value="1"
                        :true-value="0"
                        @change="save(item)"
                        v-model="item.isStop"
                      />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button @click="change(item)" class="btn btn-primary" v-show="!item.editor">修改</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="QueryAll"
                      class="btn btn-default"
                      v-show="item.editor"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../../assets/js/app-api";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      activityTypes: [],
    };
  },
  created() {
    this.QueryAll();
  },
  methods: {
    async QueryAll() {
      let response = await api.activity.queryAllTypeEdit({});
      let tmp = response.data.result.datas;
      for (var i = 0; i < tmp.length; i++) {
        tmp[i].editor = false;
        tmp[i].isNew = false;
      }
      this.activityTypes = JSON.parse(JSON.stringify(tmp));
    },
    AddActivityType() {
      if (this.activityTypes.some((type) => type.editor === true)) {
        this.$eventBus.$emit("showAlert", "請先儲存上一筆資料!");
        return;
      }

      let data = {
        typeName: "",
        typeId: "",
        orderNum: 1,
        isStop: 0,
        editor: true,
        isNew: true,
      };

      this.activityTypes.push(data);
    },
    // 切換文字或輸入框
    change(item) {
      if (this.activityTypes.some((type) => type.editor === true)) {
        this.$eventBus.$emit("showAlert", "請先儲存上一筆資料!");
        return;
      }

      item.editor = item.editor ? false : true;
    },
    // 儲存
    async save(item) {
      if (item.typeName == "" || item.typeName == null) {
        this.$eventBus.$emit("showAlert", "活動類別名稱不可為空!");
        return;
      }

      let response;

      if (item.isNew) {
        response = await api.activity.createType(item);
      } else response = await api.activity.updateType(item);

      if (response.data.status == "1") {
        this.$eventBus.$emit(
          "showAlert",
          item.isNew ? "新增成功!" : "儲存成功!"
        );
        this.QueryAll();
      } else this.$eventBus.$emit("showAlert", "儲存失敗!");
    },
    goBack() {
      this.$router.push({
        name: "ActivitySetting",
      });
    },
  },
};
</script>
