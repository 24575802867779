var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("課程紀錄")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", [_vm._v("課程管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("課程紀錄")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left" }, [
              _vm._v(_vm._s(_vm.$route.params.name)),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-success btn-add mr-10",
                staticStyle: { "min-width": "auto" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.exportList()
                  },
                },
              },
              [_vm._v("\n            匯出\n          ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c("p", [
              _vm._v(
                "時數：" + _vm._s(_vm.course.courseTotalTime || 0) + "分鐘"
              ),
            ]),
            _c("p", [
              _vm._v(
                "測驗及格分數：" + _vm._s(_vm.course.passingScore || 0) + "分"
              ),
            ]),
            _c("p", [_vm._v("講師：" + _vm._s(_vm.course.teachers))]),
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800 mt-10" },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(1)]
                      : _vm.course.learnRecordList.length == 0
                      ? [_vm._m(2)]
                      : _vm._l(
                          _vm.course.learnRecordList,
                          function (data, index) {
                            return _c("tr", { key: index }, [
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(data.deptName)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(data.employeeName)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(data.empTitleName)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                data.startTime
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            data.startTime,
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" - ")]),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                data.endTime
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            data.endTime,
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" - ")]),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                data.score
                                  ? _c("span", [
                                      _vm._v(_vm._s(data.score) + "分"),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]),
                            ])
                          }
                        ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  部門\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "15%" },
          },
          [_vm._v("\n                  姓名\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "8%" },
          },
          [_vm._v("\n                  職位\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  簽到時間\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  簽退時間\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "8%" },
          },
          [_vm._v("\n                  測驗分數\n                ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("查無資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }