<template>
  <div id="Stores">
    <section class="content-header">
      <h1>店家管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">店家管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="openStoreInfo('')"
            >
              <i class="fa fa-plus"></i> 新增店家
            </button>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋店家名稱"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button class="btn btn-default" type="button" @click="onPageChange(1,10)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">資料總筆數：{{total}}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">店家名稱</th>
                  <th style="min-width: 110px;">電話</th>
                  <th style="min-width: 110px;">傳真</th>
                  <th>地址</th>
                  <th>平均評分</th>
                  <th class="text-center text-nowrap">上架狀態</th>
                  <th class="text-center" style="min-width: 150px;">功能</th>
                </tr>
                <tr :key="store.storeId" v-for="store in storeList.datas">
                  <td>{{store.storeName}}</td>
                  <td>{{store.phone}}</td>
                  <td>{{store.fax}}</td>
                  <td>{{store.address}}</td>
                  <td>{{store.averageScore}}</td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'store'+store.storeId"
                        :true-value="1"
                        @change="updateStore(store)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="store.storeStatus"
                      />
                      <label :for="'store'+store.storeId"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-success"
                      title="菜單"
                      @click="openStoreMenu(store.storeId)"
                    >
                      <i class="fa fa-book"></i>
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-primary"
                      title="編輯"
                      @click="openStoreInfo(store.storeId)"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger"
                      @click="deleteStore(store)"
                      title="刪除"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <storeEdit ref="storeEdit" v-on:getStoreList="getStoreList" />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import storeEdit from "@/views/OrderSystem/StoresEdit";

export default {
  components: {
    Pagination,
    CKEditor,
    DatePicker,
    storeEdit
  },
  data() {
    return {
      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數

      storeList: [],

      searchData: {
        keyword: "",
        page: 1
      }
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getStoreList();
  },
  methods: {
    onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.pageSize = pageSize;
      this.getStoreList();
    },
    async getStoreList() {
      // 取得店家列表
      let task_queryAll = api.store.queryAll({
        keyword: this.searchData.keyword,
        pageSize: this.pageSize,
        pageNum: this.searchData.page
      });

      let response_queryAll = await task_queryAll;
      var tmpStoreList = response_queryAll.data.result;
      this.storeList = JSON.parse(JSON.stringify(tmpStoreList));
      this.total = this.storeList.total;
      this.$refs.pagination.setPage(this.searchData.page, this.total);
    },
    openStoreInfo(storeId) {
      this.$refs.storeEdit.showStoreEditBox(storeId);
    },
    addStore(store) {
      // 添加店家
      if (!this.checkStoreInfo(store)) return;

      api.store.create(store).then(res => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        }
        this.getStoreList();
      });
    },
    updateStore(store) {
      // 更新店家資訊
      if (!this.checkStoreInfo(store)) return;

      api.store.update(store).then(res => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        }
        this.getStoreList();
      });
    },
    deleteStore(store) {
      // 移除店家
      let that = this;
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", function(res) {
        if (res) {
          api.store.delete(store).then(res => {
            if (res.data.status != "1") {
              that.$eventBus.$emit("showAlert", "Fail!");
            }
            that.getStoreList();
          });
        }
      });
    },
    openStoreMenu(storeId) {
      this.$router.push({
        name: "StoreMenu",
        params: { id: storeId, searchData: this.searchData }
      });
    },
    formatDateTime(datetime, format) {
      return utils.formatTime(datetime, format);
    },
    checkStoreInfo(store) {
      if (store == null || store == undefined) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return false;
      }
      if (store.storeName == undefined || store.storeName == "") {
        this.$eventBus.$emit("showAlert", "店家名稱不可為空!!");
        return false;
      }

      if (store.phone == undefined || store.phone == "") {
        this.$eventBus.$emit("showAlert", "店家電話不可為空!!");
        return false;
      }

      if (store.address == undefined || store.address == "") {
        this.$eventBus.$emit("showAlert", "店家地址不可為空!!");
        return false;
      }

      return true;
    }
  }
};
</script>
