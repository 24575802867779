var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "OrderManagement" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("每日訂餐設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("訂餐服務管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("每日訂餐設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content daily-setting" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: { click: _vm.onAddClick },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增\n          "),
                ]
              ),
              _c("form", { staticClass: "form-horizontal" }, [
                _c("div", { staticClass: "form-group d-block" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "selectType" },
                    },
                    [_vm._v("用餐類別：")]
                  ),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "radio-inline" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchDatas.eatTime,
                              expression: "searchDatas.eatTime",
                            },
                          ],
                          staticClass: "flat",
                          attrs: {
                            name: "orderType",
                            type: "radio",
                            value: "1",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchDatas.eatTime, "1"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.searchDatas, "eatTime", "1")
                            },
                          },
                        }),
                        _vm._v(" 午餐\n                  "),
                      ]),
                    ]),
                    _c("div", { staticClass: "radio-inline" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchDatas.eatTime,
                              expression: "searchDatas.eatTime",
                            },
                          ],
                          staticClass: "flat",
                          attrs: {
                            name: "orderType",
                            type: "radio",
                            value: "2",
                          },
                          domProps: {
                            checked: _vm._q(_vm.searchDatas.eatTime, "2"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.searchDatas, "eatTime", "2")
                            },
                          },
                        }),
                        _vm._v(" 晚餐\n                  "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("用餐日期："),
                  ]),
                  _c("div", { staticClass: "input-group date" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            propEndDate: _vm.searchDatas.eatEndDay,
                            propStartDate: _vm.searchDatas.eatStartDay,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(
                                _vm.searchDatas,
                                "eatEndDay",
                                $event
                              )
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(
                                _vm.searchDatas,
                                "eatEndDay",
                                $event
                              )
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.searchDatas,
                                "eatStartDay",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.searchDatas,
                                "eatStartDay",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-search",
                    attrs: { type: "button" },
                    on: { click: _vm.loadPageData },
                  },
                  [_vm._v("查詢")]
                ),
              ]),
              _c("p", {
                staticClass: "data-num",
                domProps: { textContent: _vm._s("資料總筆數：" + _vm.total) },
              }),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-hover table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm._l(_vm.datas, function (data, index) {
                      return _c("tr", { key: index }, [
                        _c("td", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$utils.formatTime(data.eatDay)
                            ),
                          },
                        }),
                        _c("td", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$utils.getWeekDay(data.eatDay)
                            ),
                          },
                        }),
                        _c("td", [
                          _c(
                            "ul",
                            { staticClass: "store-name list-unstyled" },
                            _vm._l(data.stores, function (store, index) {
                              return _c("li", {
                                key: index,
                                domProps: {
                                  textContent: _vm._s(store.storeName),
                                },
                              })
                            }),
                            0
                          ),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.onEditClick(
                                    data.eatDay,
                                    data.eatTime
                                  )
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                          _vm._v(" \n                  "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.onDeleteClick(
                                    data.eatDay,
                                    data.eatTime
                                  )
                                },
                              },
                            },
                            [_vm._v("刪除")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center" },
            [
              _c("Pagination", {
                attrs: {
                  pageLimitCount: _vm.searchDatas.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("DailyMealSettingEdit", {
        ref: "editDialog",
        on: {
          refresh: function ($event) {
            return _vm.loadPageData()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("用餐日期")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("星期")]),
      _c("th", [_vm._v("店家名稱")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }