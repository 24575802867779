<template>
  <div id="YearEdit">
    <section class="content-header">
      <h1>作業時間限制設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">作業時間限制設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body tc" v-if="loading">查詢中</div>
            <div v-else class="box-body" style="max-width: 1010px">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ data.timeYearId }}：
                </label>
                <div class="col-sm-10">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="1"
                          :name="data.timeYearId"
                          v-model="data.timeYearLimit"
                          @change="
                            updateData(data.timeYearId, data.timeYearLimit)
                          "
                        />
                        限制
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="0"
                          :name="data.timeYearId"
                          v-model="data.timeYearLimit"
                          @change="
                            updateData(data.timeYearId, data.timeYearLimit)
                          "
                        />
                        開放
                        <span class="ml10">
                          【控管時間】全年度：
                          {{ data.timeYearS }} ~
                          {{ data.timeYearE }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ data.timeDivisionId }}：
                </label>
                <div class="col-sm-10">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="1"
                          :name="data.timeDivisionId"
                          v-model="data.timeDivisionLimit"
                          @change="
                            updateData(
                              data.timeDivisionId,
                              data.timeDivisionLimit
                            )
                          "
                        />
                        限制
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="0"
                          :name="data.timeDivisionId"
                          v-model="data.timeDivisionLimit"
                          @change="
                            updateData(
                              data.timeDivisionId,
                              data.timeDivisionLimit
                            )
                          "
                        />
                        開放
                        <span class="ml10">
                          【控管時間】上半年：
                          {{ data.timeDivisionS1 }} ~
                          {{ data.timeDivisionE1 }}，下半年：
                          {{ data.timeDivisionS2 }} ~
                          {{ data.timeDivisionE2 }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ data.timeDeptId }}：
                </label>
                <div class="col-sm-10">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="1"
                          :name="data.timeDeptId"
                          v-model="data.timeDeptLimit"
                          @change="
                            updateData(data.timeDeptId, data.timeDeptLimit)
                          "
                        />
                        限制
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="0"
                          :name="data.timeDeptId"
                          v-model="data.timeDeptLimit"
                          @change="
                            updateData(data.timeDeptId, data.timeDeptLimit)
                          "
                        />
                        開放
                        <span class="ml10">
                          【控管時間】上半年：
                          {{ data.timeDeptS1 }} ~
                          {{ data.timeDeptE1 }}，下半年：
                          {{ data.timeDeptS2 }} ~
                          {{ data.timeDeptE2 }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ data.timePersonalId }}：
                </label>
                <div class="col-sm-10">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="1"
                          :name="data.timePersonalId"
                          v-model="data.timePersonalLimit"
                          @change="
                            updateData(
                              data.timePersonalId,
                              data.timePersonalLimit
                            )
                          "
                        />
                        限制
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="0"
                          :name="data.timePersonalId"
                          v-model="data.timePersonalLimit"
                          @change="
                            updateData(
                              data.timePersonalId,
                              data.timePersonalLimit
                            )
                          "
                        />
                        開放
                        <span class="ml10">
                          【控管時間】每個月：
                          {{ data.timePersonalS }}日 ~
                          {{ data.timePersonalE }}日，逾期最後開放：
                          {{ data.timePersonalF }}日
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ data.timeScoreYId }}：
                </label>
                <div class="col-sm-10">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="1"
                          :name="data.timeScoreYId"
                          v-model="data.timeScoreYLimit"
                          @change="
                            updateData(data.timeScoreYId, data.timeScoreYLimit)
                          "
                        />
                        限制
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="0"
                          :name="data.timeScoreYId"
                          v-model="data.timeScoreYLimit"
                          @change="
                            updateData(data.timeScoreYId, data.timeScoreYLimit)
                          "
                        />
                        開放
                        <span class="ml10">
                          【控管時間】上半年：
                          {{ data.timeScoreYS1 }} ~
                          {{ data.timeScoreYE1 }}，逾期最後開放：
                          {{ data.timeScoreYF1 }}；下半年：
                          {{ data.timeScoreYS2 }} ~
                          {{ data.timeScoreYE2 }}，逾期最後開放：
                          {{ data.timeScoreYF2 }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  {{ data.timeScoreMId }}：
                </label>
                <div class="col-sm-10">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="1"
                          :name="data.timeScoreMId"
                          v-model="data.timeScoreMLimit"
                          @change="
                            updateData(data.timeScoreMId, data.timeScoreMLimit)
                          "
                        />
                        限制
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          value="0"
                          :name="data.timeScoreMId"
                          v-model="data.timeScoreMLimit"
                          @change="
                            updateData(data.timeScoreMId, data.timeScoreMLimit)
                          "
                        />
                        開放
                        <span class="ml10">
                          【控管時間】每個月：
                          {{ data.timeScoreMS }}日 ~
                          {{ data.timeScoreME }}日，逾期最後開放：
                          {{ data.timeScoreMF }}日
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      loading: true,
      data: {},
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      api.okr.mgmt.limit.queryAll().then((res) => {
        if (res.data.status === "1") {
          this.data = res.data.result || {};
          this.loading = false;
        }
      });
    },
    updateData(id, value) {
      api.okr.mgmt.limit
        .update({
          setId: id,
          setValue: value,
        })
        .then((res) => {
          this.global.showResponse(this, res, "update");
        });
    },
  },
};
</script>
