<template>
  <div id="Products">
    <section class="content-header">
      <h1>公司產品</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">公司產品</li>
      </ol>
    </section>
    <section class="content products">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box" v-if="hasEditPermission">
            <button class="btn btn-block btn-default btn-cog" type="button" v-on:click="manager()">
              <i class="fa fa-cog"></i> 公司產品管理
            </button>
          </div>
          <div class="block" :key="index" v-for="(product, index) in products">
            <h4>
              <a :href="product.productUrl" target="_blank" v-if="product.productUrl !=''">
                {{product.productName}}&emsp;
                <i class="fa fa-link"></i>
              </a>
              <p v-else>
                {{product.productName}}&emsp;
                <i class="fa fa-link"></i>
              </p>
            </h4>
            <p>{{product.productInfo}}</p>
            <div class="table-responsive">
              <table class="table table-bordered table-striped text-center">
                <tbody>
                  <tr>
                    <th>Android</th>
                    <th>IOS</th>
                  </tr>
                  <tr>
                    <td>
                      <div class="pic" :key="index" v-for="(file, index) in product.androidFiles">
                        <img :alt="file.name" draggable="false" :src="'https://dev-eip.wanin.tw/' + file.filesUrl" width="200" />
                      </div>
                      <a
                        class="btn btn-default"
                        :href="product.androidUrl"
                        v-if="product.androidUrl !=''"
                        target="_blank"
                      >
                        連結
                        <i class="fa fa-external-link"></i>
                      </a>
                    </td>
                    <td>
                      <div class="pic" :key="index" v-for="(file, index) in product.iosFiles">
                        <img :alt="file.name" draggable="false" :src="'https://dev-eip.wanin.tw/' + file.filesUrl" width="200" />
                      </div>
                      <a
                        class="btn btn-default"
                        :href="product.iosUrl"
                        v-if="product.iosUrl !=''"
                        target="_blank"
                      >
                        連結
                        <i class="fa fa-external-link"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import config from "@/assets/js/app-config";

export default {
  data() {
    return {
      hasEditPermission: false,
      products: {}
    };
  },
  mounted() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.ProductsManager
    );
    this.workShowAll();
  },
  methods: {
    //*---- 顯示列表
    async workShowAll() {
      api.product.queryList({}).then(res => {
        if (res.data.status == "1") {
          this.products = res.data.result.datas;
        }
      });
    },
    manager() {
      this.$router.push({
        name: "ProductsManager"
      });
      return;
    }
  }
};
</script>
