<template>
  <div class="modal fade" ref="divisionContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ this.isEdit ? "編輯" : "新增" }}目標</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label w90">
                      <span class="text-red">*</span>
                      處別
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        :disabled="(single && exist) || data.objectiveStatus"
                        v-model="data.deptId"
                      >
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      年度
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.year"
                        @change="getObjs()"
                      >
                        <option
                          v-for="(year, index) in years"
                          :key="index"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.month"
                      >
                        <option value="0">上半年</option>
                        <option value="1">下半年</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      年度目標
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        v-model="data.parentObjectiveId"
                      >
                        <option value="-1">未定</option>
                        <option
                          v-for="(obj, index) in objs"
                          :key="index"
                          :value="obj.objectiveId"
                        >
                          {{ obj.objective }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      處級目標
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        class="form-control"
                        required
                        :disabled="!exist && data.objectiveStatus"
                        v-model.trim="data.objective"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      目標說明
                    </td>
                    <td colspan="2">
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                        :disabled="!exist && data.objectiveStatus"
                        v-model.trim="data.note"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">儲存</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      disabled: false,
      isEdit: false,
      exist: false,
      single: false,
      years: this.global.getYearList(),
      list: [],
      objs: [],
      data: {},
    };
  },
  methods: {
    async showContent(data, addData) {
      this.disabled = addData.month != -1 ? true : false;
      this.isEdit = data ? true : false;
      this.data = data ? data : addData;
      this.data = JSON.parse(JSON.stringify(this.data));
      this.getList(addData);
      $(this.$refs.divisionContent).modal("show");
    },
    getList(addData) {
      this.exist = addData.permission.deptIds.includes(this.data.deptId);
      if (this.exist || !this.isEdit) {
        if (addData.permission.deptIds.length === 1) this.single = true;
        this.list = addData.permission.deptList;
        this.getObjs();
      } else {
        api.okr.goal.department.list({ deptLevel: 1 }).then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
            this.getObjs();
          }
        });
      }
    },
    getObjs() {
      api.okr.goal.year
        .queryAll({
          year: this.data.year,
          status: 2,
        })
        .then((res) => {
          if (res.data.status === "1") {
            const datas = res.data.result.datas || [];
            this.objs = this.global.getDistinctData(datas, "objective");
          }
        });
    },
    submit() {
      const action = this.isEdit ? "update" : "create";
      api.okr.goal.division[action](this.data).then((res) => {
        this.global.showResponse(this, res, action);
        $(this.$refs.divisionContent).modal("hide");
        if (this.$route.params.id) {
          this.$emit("updateList");
        } else {
          this.data.id = this.data.deptId;
          this.data.parentObjectiveId = "";
          this.data.objective = "";
          this.$router.push({
            name: "DivisionEdit",
            params: this.data,
          });
        }
      });
    },
  },
};
</script>
