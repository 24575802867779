<template>
  <div id="CourseList">
    <section class="content-header">
      <h1>課程紀錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">課程紀錄</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">{{ $route.params.name }}</h4>
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              class="btn btn-block btn-success btn-add mr-10"
              type="button"
              style="min-width: auto"
              @click="exportList()"
            >
              匯出
            </button>
          </div>
          <div class="table-responsive">
            <p>時數：{{ course.courseTotalTime || 0 }}分鐘</p>
            <p>測驗及格分數：{{ course.passingScore || 0 }}分</p>
            <p>講師：{{ course.teachers }}</p>
            <table class="table table-hover table-bordered min-800 mt-10">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 10%">
                    部門
                  </th>
                  <th class="text-nowrap text-center" style="width: 15%">
                    姓名
                  </th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    職位
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    簽到時間
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    簽退時間
                  </th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    測驗分數
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="course.learnRecordList.length == 0">
                  <tr>
                    <td class="text-center" colspan="6">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-for="(data, index) in course.learnRecordList"
                    :key="index"
                  >
                    <td class="text-center">{{ data.deptName }}</td>
                    <td class="text-center">{{ data.employeeName }}</td>
                    <td class="text-center">{{ data.empTitleName }}</td>
                    <td class="text-center">
                      <span v-if="data.startTime">{{
                        data.startTime | dateFormat("YYYY-MM-DD HH:mm")
                      }}</span>
                      <span v-else> - </span>
                    </td>
                    <td class="text-center">
                      <span v-if="data.endTime">{{
                        data.endTime | dateFormat("YYYY-MM-DD HH:mm")
                      }}</span>
                      <span v-else> - </span>
                    </td>
                    <td class="text-center">
                      <span v-if="data.score">{{ data.score }}分</span>
                      <span v-else>-</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      loading: false,
      searchData: {
        courseId: this.$route.params.id || "", // 課程id
        companyId: this.$store.state.loginUser.companyId, // 公司id
        deptId: "", // 部門id(可不傳)
      },
      course: {
        createTime: "",
        courseTotalTime: "",
        teachers: "",
        learnRecordList: [],
      },
    };
  },
  async created() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      api.education.course.detail(this.searchData).then(({ data }) => {
        if (data.status == "1") this.course = data.result;
        this.loading = false;
      });
    },
    async exportList() {
      let data = [];
      this.course.learnRecordList.forEach((res) => {
        let startTime = res.startTime
          ? new moment(res.startTime).format("YYYY-MM-DD HH:ss")
          : "-";
        let endTime = res.endTime
          ? new moment(res.endTime).format("YYYY-MM-DD HH:ss")
          : "-";
        data.push({
          部門: res.deptName,
          姓名: res.employeeName,
          職位: res.empTitleName,
          簽到時間: startTime,
          簽退時間: endTime,
        });
      });

      utils.exportData(
        data,
        this.$route.params.name + "_" + "課程紀錄" + ".xlsx"
      );
    },
    back() {
      this.$router.push({
        name: "ETCourseList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
