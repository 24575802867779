<template>
  <ul class="pagination pagination-sm no-margin">
    <li @click="onPageChange(1)">
      <a href="javascript:void(0)">
        <i class="fa fa-angle-double-left"></i>
      </a>
    </li>
    <li @click="onPageChange('shiftPrev')">
      <a href="javascript:void(0)">
        <i class="fa fa-angle-left"></i>
      </a>
    </li>
    <li
      :class="{ active: pageStart + n - 1 == page }"
      :key="n"
      @click="onPageChange(pageStart + n - 1)"
      v-for="n in pageCount || 1"
    >
      <a href="javascript:void(0)">{{ pageStart + n - 1 }}</a>
    </li>
    <li @click="onPageChange('shiftNext')">
      <a href="javascript:void(0)">
        <i class="fa fa-angle-right"></i>
      </a>
    </li>
    <li @click="onPageChange(pageTotal)">
      <a href="javascript:void(0)">
        <i class="fa fa-angle-double-right"></i>
      </a>
    </li>
  </ul>
</template>

<script>
//import api from '../assets/js/app-api';

export default {
  props: ["total", "pageLimitCount"],
  data() {
    return {
      page: 1,
      pageTotal: 1,
      pageStart: 1,
      pageCount: 1,
      pageListSize: 10,
    };
  },
  watch: {
    total(value) {
      this.onPageInitial(value);
    },
  },
  methods: {
    setPage(page, pageTotal) {
      //返回時，設定page
      this.onPageInitial(pageTotal);
      this.page = page;
      if (this.page % this.pageListSize != 0) {
        this.pageStart =
          Math.floor(this.page / this.pageListSize) * this.pageListSize + 1;
      } else {
        this.pageStart = this.page - this.pageListSize + 1;
      }

      if (
        Math.ceil(this.page / this.pageListSize) * this.pageListSize <=
        this.pageTotal
      ) {
        this.pageCount = this.pageListSize;
      } else {
        this.pageCount =
          this.pageTotal -
          Math.floor(this.page / this.pageListSize) * this.pageListSize;
      }
    },
    updatePage() {
      this.$emit("pageMethod", this.page, this.pageLimitCount);
    },
    onPageSizeChange() {
      this.page = 1;
      this.searchShow = 0;
      this.updatePage();
    },
    onPageChange(value) {
      if (value == this.page || value < 1 || value > this.pageTotal) return;

      if (value == "shiftNext") {
        //往後10頁
        if (
          this.pageCount < this.pageListSize &&
          this.page + this.pageLimitCount >= this.total
        )
          return;
        if (this.pageStart + this.pageListSize <= this.pageTotal) {
          this.pageStart =
            Math.floor(
              (this.page + this.pageListSize - 1) / this.pageListSize
            ) *
              this.pageListSize +
            1;

          this.page = this.pageStart;
        }
      } else if (value == "shiftPrev") {
        //往前10頁
        if (this.page == 1 || this.page <= this.pageListSize) return;
        if (this.page > this.pageListSize) {
          this.page =
            Math.floor((this.page - 1) / this.pageListSize) * this.pageListSize;

          this.pageStart = this.page - this.pageListSize + 1;
        }
      } else {
        this.page = value;
        if (this.page == 1) this.pageStart = this.page;
      }

      if (
        Math.ceil(this.page / this.pageListSize) * this.pageListSize <=
        this.pageTotal
      ) {
        this.pageCount = this.pageListSize;
      } else {
        this.pageCount =
          this.pageTotal -
          Math.floor(this.page / this.pageListSize) * this.pageListSize;
      }

      if (this.page == this.pageTotal)
        this.pageStart = this.pageTotal - this.pageCount + 1;

      this.updatePage();
    },
    onPageInitial(rowCount) {
      this.pageTotal = Math.ceil(rowCount / this.pageLimitCount);
      if (this.page > this.pageTotal) this.page = 1;
      if (this.page == 1) {
        this.pageStart = 1;
        this.pageCount = this.pageTotal;
        if (this.pageTotal > this.pageListSize)
          this.pageCount = this.pageListSize;
      }
    },
  },
};
</script>
