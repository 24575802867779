var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Score" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("考核紀錄")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("考核紀錄")]),
      ]),
    ]),
    _c("section", { staticClass: "content seating" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "div",
              {
                staticClass: "btns",
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-around",
                },
              },
              [
                _c(
                  "button",
                  {
                    class: [
                      "btn btn-block btn-add",
                      _vm.raterType == "other"
                        ? "btn-primary active"
                        : "btn-default",
                    ],
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.raterType = "other"
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("受評紀錄")]),
                    _vm.okrNotice.receiveCount != 0
                      ? _c(
                          "span",
                          {
                            staticClass: "menuNum hide",
                            staticStyle: { "margin-left": "15px" },
                            attrs: { id: "menuNum" },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.okrNotice.receiveCount) +
                                "\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "button",
                  {
                    class: [
                      "btn btn-block btn-add",
                      _vm.raterType == "self"
                        ? "btn-primary active"
                        : "btn-default",
                    ],
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.raterType = "self"
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("考評紀錄")]),
                    _vm.okrNotice.addCount != 0
                      ? _c(
                          "span",
                          {
                            staticClass: "menuNum hide",
                            staticStyle: { "margin-left": "15px" },
                            attrs: { id: "menuNum" },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.okrNotice.addCount) +
                                "\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "col-5" }, [
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.year,
                        expression: "searchData.year",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control w90",
                    staticStyle: { "margin-left": "0" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "year",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.years, function (year, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: year } },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(year) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.month,
                        expression: "searchData.month",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control w90",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "month",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("上半年")]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("下半年")]),
                  ]
                ),
                _vm.loginUserPermission.indexOf("C0-102A") > -1
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.QueryDeptId,
                            expression: "searchData.QueryDeptId",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "QueryDeptId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.getDataList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("請選擇部別"),
                        ]),
                        _vm._l(_vm.deptList, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.deptId } },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item.deptName) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _c(
                      "tr",
                      { staticClass: "bg-gray" },
                      [
                        _c("th", { staticClass: "tc mw50" }, [_vm._v("年分")]),
                        _c("th", { staticClass: "tc mw50" }, [_vm._v("月份")]),
                        _c("th", { staticClass: "tc mw100" }, [_vm._v("部門")]),
                        _c("th", { staticClass: "tc mw100" }, [
                          _vm._v("員工編號"),
                        ]),
                        _c("th", { staticClass: "tc mw100" }, [_vm._v("姓名")]),
                        _vm.raterType == "other"
                          ? _c("th", { staticClass: "tc mw100" }, [
                              _vm._v("時間"),
                            ])
                          : _vm._e(),
                        _vm.raterType == "self" || _vm.raterType == "read"
                          ? [
                              _c("th", { staticClass: "tc mw50" }, [
                                _vm._v("加分"),
                              ]),
                            ]
                          : _vm._e(),
                        _c("th", { staticClass: "tc mw150" }, [_vm._v("說明")]),
                        _vm.raterType == "self" || _vm.raterType == "read"
                          ? [
                              _c("th", { staticClass: "tc mw100" }, [
                                _vm._v("狀態"),
                              ]),
                              _c("th", { staticClass: "tc mw150" }, [
                                _vm._v("送簽時間"),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.loginUserPermission.indexOf("C0-102A") > -1 ||
                        _vm.raterType == "self" ||
                        _vm.raterType == "read"
                          ? _c("th", { staticClass: "tc mw100" }, [
                              _vm._v(
                                "\n                  管理\n                "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.loading
                      ? _c("tr", [
                          _c(
                            "td",
                            { staticClass: "tc", attrs: { colspan: "10" } },
                            [_vm._v("查詢中")]
                          ),
                        ])
                      : _vm.list.length === 0
                      ? _c("tr", [
                          _c(
                            "td",
                            { staticClass: "tc", attrs: { colspan: "10" } },
                            [_vm._v("查詢無任何資料")]
                          ),
                        ])
                      : _vm._l(_vm.list, function (data, index) {
                          return _c(
                            "tr",
                            { key: index },
                            [
                              _c(
                                "td",
                                { staticClass: "tc" },
                                [
                                  data.isEdit
                                    ? [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: data.year,
                                                expression: "data.year",
                                              },
                                            ],
                                            staticClass: "form-control w90",
                                            staticStyle: { "margin-left": "0" },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  data,
                                                  "year",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.years,
                                            function (year, index) {
                                              return _c(
                                                "option",
                                                {
                                                  key: index,
                                                  domProps: { value: year },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(year) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    : [_vm._v(_vm._s(data.year))],
                                ],
                                2
                              ),
                              _c(
                                "td",
                                { staticClass: "tc" },
                                [
                                  data.isEdit
                                    ? [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: data.month,
                                                expression: "data.month",
                                              },
                                            ],
                                            staticClass: "form-control w90",
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  data,
                                                  "month",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "0" } },
                                              [_vm._v("上半年")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "1" } },
                                              [_vm._v("下半年")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.define.halfYearStatus[
                                                data.month
                                              ]
                                            ) +
                                            "\n                  "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm.raterType == "other"
                                ? [
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.createDeptName)),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.createEmpId)),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.createEmpName)),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            data.createTime ||
                                              _vm.dateFormat("YYYY/MM/DD hh:mm")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.raterType == "self" || _vm.raterType == "read"
                                ? [
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.deptName)),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.employeeId)),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.employeeName)),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(_vm._s(data.totalScore)),
                                    ]),
                                  ]
                                : _vm._e(),
                              _c("td", { staticClass: "tc" }, [
                                _vm._v(_vm._s(data.note)),
                              ]),
                              _vm.raterType == "self" || _vm.raterType == "read"
                                ? [
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.define.sendStatus[
                                              data.scoreStatus
                                            ]
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "tc" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              new Date(data.createTime),
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "tc",
                                        style:
                                          data.scoreStatus == 0 ||
                                          (_vm.loginUserPermission.indexOf(
                                            "C0-102A"
                                          ) > -1 &&
                                            _vm.raterType == "read")
                                            ? "min-width: 140px;"
                                            : "",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary mr-10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateData(data)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      檢視\n                    "
                                            ),
                                          ]
                                        ),
                                        data.scoreStatus == 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-danger",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteData(data)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      刪除\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.loginUserPermission.indexOf(
                                          "C0-102A"
                                        ) > -1 && _vm.raterType == "read"
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn",
                                                class: data.isEdit
                                                  ? "btn-success"
                                                  : "btn-purple",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editItem(data)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      data.isEdit
                                                        ? "儲存"
                                                        : "編輯"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _vm.total > 0
          ? _c(
              "div",
              { staticClass: "box-footer text-center no-border" },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    total: _vm.total,
                    pageLimitCount: _vm.searchData.pageSize,
                  },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }