<template>
  <div id="MyActivity">
    <section class="content-header">
      <h1>我的活動歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li class="active">我的活動歷程</li>
      </ol>
    </section>
    <section class="content activity-list">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-col5">
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectType"
                  v-model="searchDatas.typeId"
                  @change="queryAllKinds"
                >
                  <option value="-1">所有活動類別</option>
                  <option
                    v-for="(item, index) in activityTypes"
                    :key="index"
                    :value="item.typeId"
                  >{{item.typeName}}</option>
                </select>
              </div>
              <div class="select-group">
                <select class="form-control" id="selectName" v-model="searchDatas.kindId">
                  <option value="-1">所有活動項目</option>
                  <option
                    v-for="(item, index) in activityKinds"
                    :key="index"
                    :value="item.kindId"
                  >{{item.kindName}}</option>
                </select>
              </div>
            </div>
            <div class="input-group date-between mr-10">
              <div class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </div>
              <DateRangePicker
                id="inputDateRange"
                v-bind:propEndDate.sync="searchDatas.endTime"
                v-bind:propStartDate.sync="searchDatas.startTime"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="搜尋活動名稱"
                type="text"
                v-model="searchDatas.keyword"
              />
              <div class="input-group-btn">
                <button class="btn btn-default" type="button" @click="queryAll">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">活動類別</th>
                  <th>活動項目</th>
                  <th>活動名稱</th>
                  <th>活動時間</th>
                  <th>活動地點</th>
                  <th>聯絡人</th>
                  <th>附件</th>
                  <th class="text-center" style="min-width: 200px;">功能</th>
                </tr>
                <tr v-for="(activity, index) in activitys" :key="index">
                  <td>{{activity.typeName}}</td>
                  <td>{{activity.kindName}}</td>
                  <td>{{activity.activityName}}</td>
                  <td>{{getDuration(activity.activitySTime, activity.activityETime)}}</td>
                  <td>{{activity.location}}</td>
                  <td>{{activity.contacter}}</td>
                  <td>
                    <a
                      :key="file.id"
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      class="file-icon"
                      v-for="file in activity.files"
                      v-show="activity.files.length > 0"
                    >
                      <i :class="'icon icon-'+file.mine.substring(0,3)"></i>
                    </a>
                  </td>
                  <td class="text-center">
                    <button
                      @click="openActivityBooking(activity)"
                      class="btn btn-primary mr-10"
                    >詳細內容</button>
                    <button
                      @click="cancelJoin(activity)"
                      class="btn btn-danger"
                      v-if="chkCancelBtn(activity.bookingETime) && activity.activityStatus != 9"
                    >取消報名</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination
            :pageLimitCount="searchDatas.pageSize"
            :total="total"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <activityBooking ref="activityBooking" v-on:refresh="queryAll()" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import activityBooking from "@/views/Activity/ActivityBooking";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    DateRangePicker,
    Pagination,
    activityBooking,
  },
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      total: 0, //總筆數
      activityTypes: [],
      activityKinds: [],
      activitys: [],
      searchDatas: {
        typeId: "-1",
        kindId: "-1",
        keyword: "",
        pageNum: 1,
        pageSize: 30,
        startTime: new moment().format("YYYY/MM/DD"),
        endTime: new moment().add("1", "months").format("YYYY/MM/DD"),
      },
    };
  },
  created() {
    this.queryAllTypes();
    this.queryAllKinds();
    this.queryAll();
  },
  methods: {
    queryAll() {
      api.activity.queryAllUserJoin(this.searchDatas).then((res) => {
        if (res.data.status == "1") {
          this.activitys = res.data.result.datas;
          this.total = res.data.result.total;
        }
      });
    },
    async queryAllTypes() {
      // 活動類別
      let activityTypes = await api.activity.queryAllType({
        isStop: 0,
      });
      this.activityTypes = activityTypes.data.result.datas;
    },
    async queryAllKinds() {
      // 活動類別細項
      this.searchDatas.kindId = "-1";
      let activityKinds = await api.activity.queryAllManagerKind({
        typeId: this.searchDatas.typeId,
        isStop: 0,
      });
      this.activityKinds = activityKinds.data.result.datas;
    },
    onPageChange(pageIdx, pageSize) {
      this.searchDatas.pageNum = pageIdx;
      this.searchDatas.pageSize = pageSize;
    },

    getDuration(start, end) {
      if (moment(start).isSame(end, "day")) {
        return (
          utils.formatTime(start, "YYYY-MM-DD") +
          " " +
          utils.formatTime(start, "HH:mm") +
          "~" +
          utils.formatTime(end, "HH:mm")
        );
      } else {
        return (
          utils.formatTime(start, "YYYY-MM-DD HH:mm") +
          "~" +
          utils.formatTime(end, "YYYY-MM-DD HH:mm")
        );
      }
    },
    cancelJoin(activity) {
      this.$eventBus.$emit("showConfirm", "確定要取消嗎?", (isOK) => {
        if (isOK) {
          api.activity.cancelJoin(activity).then((res) => {
            if (res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "取消成功!");
            } else {
              this.$eventBus.$emit("showAlert", res.data.message);
            }
            this.queryAll();
          });
        }
      });
    },
    chkCancelBtn(bookingETime) {
      return new moment().isBefore(bookingETime);
    },
    openActivityBooking(activity) {
      this.$refs.activityBooking.showActivityBooking(activity);
    },
  },
};
</script>
