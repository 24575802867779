var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "MarqueeList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("跑馬燈管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("跑馬燈管理")]),
          _c("li", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.announcementtypes.typeName)),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addNew()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增跑馬燈訊息\n          "),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isStop,
                        expression: "searchData.isStop",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "isStop",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.clickSearch()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [_vm._v("全部")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("啟用")]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("停用")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.keyword,
                      expression: "searchData.keyword",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋跑馬燈訊息",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.keyword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "keyword", $event.target.value)
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  (_vm.searchShow = 1)
                    ? _c("span", [_vm._v("資料總筆數：")])
                    : _vm._e(),
                  _vm.searchShow != 1
                    ? _c("span", [_vm._v("搜尋結果筆數：")])
                    : _vm._e(),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.marquees.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.marquees.total > 0
                        ? _vm._l(_vm.marquees.datas, function (item) {
                            return _c("tr", { key: item.id }, [
                              _c("td", {
                                domProps: { innerHTML: _vm._s(item.note) },
                              }),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.formatTime(item.startTime)) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.formatTime(item.endTime)) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.orderNum))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        item.isStop == 0 ? "啟用" : "停用"
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.formatTime(item.createTime)) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editorItem(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      修改\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  "\n                     \n                    "
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      刪除\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          })
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.marquees.total > 0,
                  expression: "marquees.total > 0",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("MarqueeEdit", {
        ref: "marqueeEdit",
        on: { getMarqueeList: _vm.clickSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "300px" },
        },
        [_vm._v("\n                  跑馬燈訊息\n                ")]
      ),
      _c("th", { staticClass: "text-nowrap text-center" }, [
        _vm._v("開始時間"),
      ]),
      _c("th", { staticClass: "text-nowrap text-center" }, [
        _vm._v("結束時間"),
      ]),
      _c("th", { staticClass: "text-nowrap text-center" }, [_vm._v("排序")]),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "min-width": "60px", width: "5%" },
        },
        [_vm._v("\n                  狀態\n                ")]
      ),
      _c("th", { staticClass: "text-nowrap text-center" }, [
        _vm._v("發布日期"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v("\n                  功能\n                ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }