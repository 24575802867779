<template>
  <span>
    <input class="flat" type="checkbox" :value="propValue" :checked="isChecked" />
  </span>
</template>

<script>
export default {
  props: {
    propValue: "",
    propChecked: false,
    propDisabled: false
  },
  data() {
    return {
      isChecked: 0,
      isDisabled: false
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue"
    });
    var vm = this;

    this.isDisabled = this.propDisabled;

    vm.setStatus(this.propChecked);

    $(vm.$el).on("ifUnchecked", function(e) {
      vm.setStatus(0);
    });
    $(vm.$el).on("ifChecked", function(e) {
      vm.setStatus(1);
    });
  },
  watch: {
    propChecked(value) {
      this.setStatus(value);
    },
    propDisabled(value) {
      this.setDisabled(value);
    }
  },
  methods: {
    setStatus(isChecked) {
      if (isChecked != 1 && isChecked != 0) return;
      this.isChecked = isChecked;
      if (this.isChecked == 1) $(this.$el).iCheck("check");
      else $(this.$el).iCheck("uncheck");
      this.$emit("input", this.isChecked);
      this.$emit("update:checked", {
        value: this.propValue,
        checked: this.isChecked
      });
    },
    setDisabled(value) {
      if (value === true) $(this.$el).iCheck("disable");
      else $(this.$el).iCheck("enable");
    }
  }
};
</script>
