<template>
  <div id="PersonalList">
    <section class="content-header">
      <h1>
        {{ person.level === 4 ? "新進員工" : "工作績效" }}目標
        {{ person.level === 4 ? "" : `(${person.text})` }}
      </h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          {{ person.level === 4 ? "新進員工" : "工作績效" }}目標
          {{ person.level === 4 ? "" : `(${person.text})` }}
        </li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div
              class="btns"
              v-if="
                permission.isEmployee ||
                (permission.deptIds.includes(searchData.deptId) &&
                  (person.level === 4 || person.level === 5))
              "
            >
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="updateData()"
              >
                <i class="fa fa-plus"></i>
                新增目標
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢目標：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="-1" v-if="!permission.isManager">月份</option>
                  <option
                    v-for="(month, index) in 12"
                    :key="index"
                    :value="month"
                  >
                    {{ month }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-show="!permission.isEmployee || permission.isHR"
                  v-model="searchData.deptId"
                  @change="getUserList(true)"
                >
                  <option value="" v-if="permission.isHR">部別</option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-show="!permission.isEmployee || permission.isHR"
                  v-model="searchData.employeeId"
                  @change="resetDataList()"
                >
                  <option value="">人員</option>
                  <option
                    v-for="(user, index) in users"
                    :key="index"
                    :value="user.employeeId"
                  >
                    {{ user.employeeName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.objectiveStatus"
                  @change="resetDataList()"
                >
                  <option value="-1">狀態</option>
                  <option value="-9" v-if="permission.isManager">未填寫</option>
                  <option value="0">草稿</option>
                  <option value="1">送簽中</option>
                  <option value="2">送簽完成</option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw50">#</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw80">年份</th>
                  <th class="tc mw50">月份</th>
                  <th class="tc mw100">部別</th>
                  <th class="tc mw90">狀態</th>
                  <th class="tc mw150">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="8">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="index + 1"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.month"></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td
                    class="tc"
                    v-text="define.sendStatus[data.objectiveStatus]"
                  ></td>
                  <td class="tc" v-if="data.objectiveStatus !== -9">
                    <template
                      v-if="
                        !data.objectiveStatus &&
                        (userData.user.employeeId === data.employeeId ||
                          (!permission.isEmployee &&
                            permission.deptIds.includes(data.deptId) &&
                            (person.level === 4 || person.level === 5)))
                      "
                    >
                      <button class="btn btn-primary" @click="updateData(data)">
                        修改
                      </button>
                      &nbsp;
                      <button class="btn btn-danger" @click="deleteData(data)">
                        刪除
                      </button>
                    </template>
                    <template v-else>
                      <button class="btn btn-primary" @click="updateData(data)">
                        檢視
                      </button>
                    </template>
                  </td>
                  <td class="tc" v-else>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        objectiveStatus: -1,
        employeeId: "",
        deptId: "",
      },
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      list: [],
      users: [],
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.permission.isManager =
      !this.permission.isEmployee && this.person.level === 3;
    this.searchData.deptLevel = this.person.level;
    this.searchData.parentDeptId = this.permission.isDivisionManager
      ? this.userData.user.deptId
      : null;
    this.updateMonthSearch();
    this.getList();
  },
  methods: {
    getList() {
      if (this.permission.isHR || this.permission.hasOver) {
        api.okr.goal.department
          .list({ deptLevel: 2, parentDeptId: this.searchData.parentDeptId })
          .then((res) => {
            if (res.data.status === "1") {
              this.list = res.data.result || [];
              this.updateListSearch();
              this.getUserList();
            }
          });
      } else {
        this.list = this.permission.deptList || [];
        this.updateListSearch();
        this.getUserList();
      }
    },
    getUserList(reset) {
      if (this.person.level === 5) {
        this.searchData.assignDeptId = this.searchData.deptId;
        api.okr.mgmt.assign.queryAll(this.searchData).then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result.datas || [];
            this.updateUserSearch(reset);
            if (reset) this.resetDataList();
            else this.getDataList();
          }
        });
      } else {
        api.okr.goal.personal.list(this.searchData).then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result || [];
            this.updateUserSearch(reset);
            if (reset) this.resetDataList();
            else this.getDataList();
          }
        });
      }
    },
    getDataList() {
      const action = this.permission.isManager
        ? "deptQueryAll"
        : "userQueryAll";
      api.okr.goal.personal[action](this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateMonthSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.month = this.permission.isManager
          ? this.global.getMonth()
          : this.searchData.month;
      }
    },
    updateListSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.deptId = this.global.getDeptId(
          this.list,
          this.userData,
          this.permission
        );
        if (!this.searchData.deptId && this.list.length) {
          this.searchData.deptId = this.list[0].deptId || "";
        }
      }
    },
    updateUserSearch(reset) {
      if (reset) this.searchData.employeeId = "";
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.employeeId = this.global.getEmployeeId(
          this.users,
          this.userData
        );
      }
    },
    updateData(data) {
      this.$router.push({
        name: "PersonalEdit",
        params: {
          type: this.person.type,
          id: data ? data.employeeId : null,
          year: data ? data.year : this.searchData.year,
          month: data ? data.month : this.searchData.month,
          deptId: data ? data.deptId : this.searchData.deptId || this.userData.user.deptId,
          formType: data ? data.formTypeId : null,
          formId: data ? data.formId : null,
          createId: data ? data.createEmpId : null,
          signId: data ? data.signGetId : null,
          searchData: this.searchData,
        },
      });
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.goal.groupDelete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
  },
};
</script>
