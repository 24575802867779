<template>
  <div id="ActivityEdit">
    <section class="content-header">
      <h1>{{title}}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li>活動設定</li>
        <li>活動列表管理</li>
        <li class="active">編輯活動</li>
        <!-- 新增活動 -->
      </ol>
    </section>
    <section class="content activity-edit">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯活動</h4>
            <!-- 新增活動 -->
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectType">
                  <span class="text-red">*</span> 活動類別：
                </label>
                <div class="col-sm-5">
                  <select
                    class="form-control"
                    id="selectType"
                    v-model="edit.typeId"
                    @change="changeType"
                  >
                    <option value disabled>請選擇活動類別</option>
                    <option
                      v-for="(item, index) in activityTypes"
                      :key="index"
                      :value="item.typeId"
                    >{{item.typeName}}</option>
                  </select>
                </div>
                <div class="col-sm-5">
                  <select class="form-control" id="selectName" v-model="edit.kindId">
                    <option value disabled>請選擇活動項目</option>
                    <option
                      v-for="(item, index) in activityKinds"
                      :key="index"
                      :value="item.kindId"
                    >{{item.kindName}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 活動名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.activityName"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputDateRange">
                  <span class="text-red">*</span> 活動時間：
                </label>
                <div class="col-sm-10">
                  <p class="mt-7">開始時間：</p>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <DatePicker v-model="activitySTime" v-bind:propValue="activitySTime" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group time">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input class="form-control timepicker" id="activitySTime" type="text" />
                      </div>
                    </div>
                  </div>
                  <p class="mt-10">截止時間：</p>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <DatePicker v-model="activityETime" v-bind:propValue="activityETime" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group time">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input class="form-control timepicker" id="activityETime" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputPlace">
                  <span class="text-red">*</span> 活動地點：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputPlace"
                    required="required"
                    type="text"
                    v-model="edit.location"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="Contacter">
                  <span class="text-red">*</span> 聯絡人：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="Contacter"
                    required="required"
                    type="text"
                    v-model="edit.contacter"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="MaxLimit">
                  <span class="text-red">*</span> 人數限制：
                </label>
                <div class="col-sm-10">
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="0"
                      message="不限制人數"
                      name="radioLimit"
                      v-model="edit.maxLimit"
                    />
                  </div>
                  <div class="radio-select">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="1"
                        message="限制人數："
                        name="radioLimit"
                        v-model="edit.maxLimit"
                      />
                    </div>
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        id="Contacter"
                        required="required"
                        type="number"
                        v-model="edit.maxCount"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectCompany">
                  <span class="text-red">*</span> 報名對象：
                </label>
                <div class="col-sm-10">
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="0"
                      message="開放全公司報名"
                      name="radioMode"
                      v-model="edit.isLimit"
                    />
                  </div>
                  <div class="radio-select">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="1"
                        message="對象範圍："
                        name="radioMode"
                        v-model="edit.isLimit"
                      />
                    </div>
                    <div class="select-group">
                      <select2-multiple
                        :options="deptOptions"
                        data-placeholder="請選擇對象"
                        v-model="depts"
                      ></select2-multiple>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputDateRange">
                  <span class="text-red">*</span> 報名期間：
                </label>
                <div class="col-sm-10">
                  <p class="mt-7">開始時間：</p>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <DatePicker v-model="bookingSTime" v-bind:propValue="bookingSTime" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group time">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input class="form-control timepicker" id="bookingSTime" type="text" />
                      </div>
                    </div>
                  </div>
                  <p class="mt-10">截止時間：</p>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <DatePicker v-model="bookingETime" v-bind:propValue="bookingETime" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-group time">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input class="form-control timepicker" id="bookingETime" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 備註：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce v-model="edit.note" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile">上傳檔案：</label>
                <div class="col-sm-10 text">
                  <input
                    @change="changeFile($event)"
                    accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip"
                    id="inputFile"
                    maxlength="5"
                    name="file1"
                    type="file"
                  />
                  <div class="help-block">
                    文件檔案上傳格式：
                    <span class="text-red">(上限20MB)</span>
                    <br />1.WORD：DOC、DOCX。
                    <br />2.EXCEL：XLS、XLSX。
                    <br />3.POWERPOINT：PPT、PPTX。
                    <br />4.PDF。
                    <br />5.TXT。
                    <br />6.XPS。
                    <br />7.ZIP。
                    <br />(每個檔案上限20MB，最多可以選3個檔案)
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile">已有檔案：</label>
                <div class="col-sm-10 text">
                  <table class="table table-bordered">
                    <tbody>
                      <tr :key="index" v-for="(file, index) in edit.files">
                        <td>{{file.name}}</td>
                        <td v-if="edit.status !=9">
                          <button
                            class="btn btn-danger"
                            type="button"
                            @click="deleteFile(file.applyId)"
                          >刪除</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="save(0)"
                  v-if="!editor || edit.status ==1"
                >儲存為草稿</button>
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="save()"
                  v-if="edit.status !=9 && editor"
                >儲存</button>
                <button
                  class="btn bg-purple"
                  type="button"
                  @click="save(1)"
                  v-if="edit.status ==0 || !editor"
                >發佈</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker";
import DatePicker from "@/components/DatePicker";
import select2Multiple from "@/components/select2Multiple";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckRedioButton: iCheckRedioButton,
    "select2-multiple": select2Multiple,
    DatePicker,
    DateRangePicker,
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      title: "新增活動",
      editor: false,
      activityTypes: [],
      activityKinds: [],
      depts: [],
      deptOptions: [],
      activitySTime: "",
      activityETime: "",
      bookingSTime: "",
      bookingETime: "",
      edit: {
        activityId: utils.getTimeId(),
        typeId: "",
        kindId: "",
        activityName: "",
        location: "",
        contacter: "",
        activitySTime: "",
        activityETime: "",
        bookingSTime: "",
        bookingETime: "",
        files: [],
        note: "",
        status: 0,
        isLimit: 0,
        maxLimit: 0,
        maxCount: 0,
        limitList: [],
      },
    };
  },
  async created() {
    // let getData = await this.getDepts();
    if (this.$route.params.id) {
      this.editor = true;
      this.queryActivity();
    } else {
      this.queryAllTypes();
    }
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });

    $(".timepicker").timepicker({
      showInputs: false,
    });
  },
  methods: {
    async getDepts() {
      //公司部門
      var task_deps = api.organization.getDeptList({
        companyId: this.$store.state.loginUser.companyId,
      });
      var response_desps = await task_deps;
      let temp = response_desps.data.result;
      temp.forEach((dept) => {
        this.deptOptions.push({
          id: dept.deptId,
          text: dept.deptIdName,
        });
      });
    },
    async queryAllTypes() {
      // 活動類別
      let activityTypes = await api.activity.queryAllManagerType({
        isStop: 0,
      });
      this.activityTypes = activityTypes.data.result.datas;
    },
    async queryAllKinds() {
      // 活動類別細項
      let activityKinds = await api.activity.queryAllManagerKind({
        typeId: this.edit.typeId,
        isStop: 0,
      });
      this.activityKinds = activityKinds.data.result.datas;
    },
    changeType() {
      this.edit.kindId = "";
      this.queryAllKinds();
    },
    queryActivity() {
      api.activity
        .query({
          activityId: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.edit = res.data.result;
            this.title = this.edit.activityName;
            if (
              this.edit.isLimit == 1 &&
              this.edit.limitList &&
              this.edit.limitList.length > 0
            ) {
              this.edit.limitList.forEach((data) => {
                this.depts.push(data.setId);
              });
            }

            this.updateDatetime();

            this.queryAllTypes();
            this.queryAllKinds();
          } else {
            this.$eventBus.$emit("showAlert", "查無資料!");
            this.backList();
            return;
          }
        });
    },
    async save(status) {
      if (!this.checkAllOptions()) return;

      if (status != null) this.edit.status = status;
      this.edit.status = parseInt(this.edit.status);
      this.edit.isLimit = parseInt(this.edit.isLimit);

      let type = "";

      switch (this.edit.status) {
        case 0:
          type = "儲存為草稿";
          break;
        case 1:
          type = "發佈";
          break;
        default:
          type = "儲存";
          break;
      }

      let response;
      if (this.editor) {
        response = await api.activity.update(this.edit);
      } else {
        response = await api.activity.create(this.edit);
      }

      if (response.data.status == "1") {
        this.$eventBus.$emit("showAlert", type + "成功!");
        this.backList();
        return;
      } else {
        this.$eventBus.$emit("showAlert", response.data.message);
        return;
      }
    },

    //更新時間
    updateDatetime() {
      if (this.edit.activitySTime != null || this.edit.activitySTime != "") {
        $("#activitySTime").val(
          new Date(this.edit.activitySTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
        this.activitySTime = new Date(
          this.edit.activitySTime
        ).toLocaleDateString();
      }

      if (this.edit.activityETime != null || this.edit.activityETime != "") {
        $("#activityETime").val(
          new Date(this.edit.activityETime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
        this.activityETime = new Date(
          this.edit.activityETime
        ).toLocaleDateString();
      }

      if (this.edit.bookingSTime != null || this.edit.bookingSTime != "") {
        $("#bookingSTime").val(
          new Date(this.edit.bookingSTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
        this.bookingSTime = new Date(
          this.edit.bookingSTime
        ).toLocaleDateString();
      }

      if (this.edit.bookingETime != null || this.edit.bookingETime != "") {
        $("#bookingETime").val(
          new Date(this.edit.bookingETime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
        this.bookingETime = new Date(
          this.edit.bookingETime
        ).toLocaleDateString();
      }
    },

    //檢查各欄位是否正確選填
    checkAllOptions() {
      if (!this.edit.typeId || this.edit.typeId == "-1") {
        this.$eventBus.$emit("showAlert", "請選擇活動類別!");
        return false;
      }

      if (!this.edit.kindId || this.edit.kindId == "-1") {
        this.$eventBus.$emit("showAlert", "請選擇活動項目!");
        return false;
      }

      if (!this.edit.activityName) {
        this.$eventBus.$emit("showAlert", "請填寫活動名稱!");
        return false;
      }

      if (!this.activitySTime || !$("#activitySTime").val()) {
        this.$eventBus.$emit("showAlert", "請確實填寫活動開始時間!");
        return false;
      }
      if (!this.activityETime || !$("#activityETime").val()) {
        this.$eventBus.$emit("showAlert", "請確實填寫活動結束時間!");
        return false;
      }

      this.edit.activitySTime =
        utils.formatTime(this.activitySTime, "YYYY-MM-DD") +
        " " +
        $("#activitySTime").val();

      this.edit.activityETime =
        utils.formatTime(this.activityETime, "YYYY-MM-DD") +
        " " +
        $("#activityETime").val();


      //添加舊資料，不卡控
      // if (new moment().isAfter(this.edit.activitySTime)) {
      //   this.$eventBus.$emit("showAlert", "活動開始時間不可小於現在時間!");
      //   return false;
      // }
      // if (
      //   new moment(this.edit.activitySTime).isAfter(this.edit.activityETime)
      // ) {
      //   this.$eventBus.$emit("showAlert", "活動結束時間不可小於開始時間!");
      //   return false;
      // }

      if (!this.edit.location) {
        this.$eventBus.$emit("showAlert", "請填寫活動地點!");
        return false;
      }

      if (!this.edit.contacter) {
        this.$eventBus.$emit("showAlert", "請填寫聯絡人!");
        return false;
      }

      if (this.edit.maxLimit == 1) {
        if (this.edit.maxCount <= 0) {
          this.$eventBus.$emit("showAlert", "限制人數不可小於0!");
          return false;
        }
      }

      if (this.edit.isLimit == 1) {
        if (!this.depts || this.depts.length == 0) {
          this.$eventBus.$emit("showAlert", "請選擇限制對象!");
          return false;
        }
        this.edit.limitList = [];
        this.depts.forEach((deptId) => {
          let limit = {
            setId: deptId,
          };
          this.edit.limitList.push(limit);
        });
      } else this.edit.limitList = [];

      if (!this.bookingSTime || !$("#bookingSTime").val()) {
        this.$eventBus.$emit("showAlert", "請確實填寫報名開始時間!");
        return false;
      }
      if (!this.bookingETime || !$("#bookingETime").val()) {
        this.$eventBus.$emit("showAlert", "請確實填寫報名結束時間!");
        return false;
      }

      this.edit.bookingSTime =
        utils.formatTime(this.bookingSTime, "YYYY-MM-DD") +
        " " +
        $("#bookingSTime").val();

      this.edit.bookingETime =
        utils.formatTime(this.bookingETime, "YYYY-MM-DD") +
        " " +
        $("#bookingETime").val();

      //添加舊資料，不卡控
      // if (new moment(this.edit.bookingSTime).isAfter(this.edit.bookingETime)) {
      //   this.$eventBus.$emit("showAlert", "報名結束時間不可小於開始時間!");
      //   return false;
      // }

      return true;
    },

    formatTime: function (t, format) {
      return utils.formatTime(t, format);
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "ActivityList",
        params: {
          searchDatas: this.$route.params.searchDatas,
        },
      });
    },
    //*---- 上傳檔案
    changeFile(e) {
      var files = e.target.files;
      if (files.length + this.edit.files.length > 3) {
        this.$eventBus.$emit(
          "showAlert",
          "您檔案數量超過上限3個，請重新選擇。"
        );
        this.removeFile();
        return;
      } else if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (fileSize > 20000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 20 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }
      //檔案上傳
      this.uploadFile(files[0], "Activity", this.edit.activityId, true);
    },
    removeFile() {
      document.getElementById("inputFile").value = "";
    },
    //檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      this.edit.id = response_upload.data.result.id;
      this.refreshFileList();
    },
    //刪除檔案
    deleteFile(applyId) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.file
            .deleteApply({
              id: applyId,
            })
            .then((res) => {
              if (res.data.status == "1") {
                this.$eventBus.$emit("showAlert", "刪除成功!");
              } else {
                this.$eventBus.$emit("showAlert", "刪除失敗!");
              }
              this.refreshFileList();
            });
        }
      });
    },
    //檔案列表刷新
    async refreshFileList() {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.edit.activityId,
        module: "Activity",
      });
      var response_queryall = await task_queryall;
      this.edit.files = response_queryall.data.result;
    },
  },
};
</script>
