var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ScoreTotalEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("總表分數修改")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: `/OKR/ScorePersonalList` } }, [
              _vm._v("總表分數修改"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(
            "\n        " +
              _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
              "總表\n      "
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left" }, [
              _vm._v(
                "\n            " +
                  _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
                  "總表\n          "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v("\n            返回列表\n          "),
              ]
            ),
          ]),
          _vm.data.message
            ? _c("div", { staticClass: "tc" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.message) + "\n        "
                ),
              ])
            : _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "table-responsive",
                    staticStyle: { "max-width": "1400px", margin: "auto" },
                  },
                  [
                    _c("table", { staticClass: "table no-border" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [
                            _c(
                              "table",
                              {
                                staticClass: "table no-border",
                                staticStyle: { width: "400px" },
                              },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c("th", { staticClass: "tr w90" }, [
                                      _vm._v("部別："),
                                    ]),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.deptment.deptName
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c("tr", [
                                    _c("th", { staticClass: "tr" }, [
                                      _vm._v("年度："),
                                    ]),
                                    _vm.person.level <= 2
                                      ? _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              `${_vm.addData.year}年(${
                                                _vm.define.halfYearStatus[
                                                  _vm.addData.month
                                                ]
                                              })`
                                            ),
                                          },
                                        })
                                      : _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              `${_vm.addData.year}年${_vm.addData.month}月`
                                            ),
                                          },
                                        }),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _c("td", [_c("ScoreNote"), _c("BigScoreNote")], 1),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "box-footer mt-15" }),
                _c(
                  "div",
                  {
                    staticClass: "table-responsive",
                    staticStyle: { "max-width": "1400px", margin: "auto" },
                  },
                  _vm._l(_vm.groups, function (group, index) {
                    return _c(
                      "table",
                      {
                        key: index,
                        staticClass: "table table-hover table-bordered min-800",
                        staticStyle: { margin: "5px auto" },
                      },
                      [
                        _c(
                          "tbody",
                          [
                            _c(
                              "tr",
                              { staticClass: "bg-gray" },
                              [
                                _c("th", { staticClass: "tc mw50 wp5" }, [
                                  _vm._v("#"),
                                ]),
                                _c("th", { staticClass: "tc mw50 wp5" }, [
                                  _vm._v("年份"),
                                ]),
                                _c("th", { staticClass: "tc mw50 wp5" }, [
                                  _vm._v("月份"),
                                ]),
                                _c("th", { staticClass: "tc mw90 wp5" }, [
                                  _vm._v("目標種類"),
                                ]),
                                _c("th", { staticClass: "tc mw150 wp20" }, [
                                  _vm._v("人員目標"),
                                ]),
                                _vm._l(group.titles, function (title, index) {
                                  return [
                                    title.displayEmpty
                                      ? _c("th", {
                                          key: index,
                                          staticClass: "tc mw90 wp10 pre-wrap",
                                          domProps: {
                                            textContent: _vm._s("－"),
                                          },
                                        })
                                      : _c("th", {
                                          key: index,
                                          staticClass: "tc mw90 wp10 pre-wrap",
                                          domProps: {
                                            textContent: _vm._s(
                                              `${title.employeeName}\n${
                                                index === 0
                                                  ? "（自評）"
                                                  : title.isManager === "0" &&
                                                    title.deptType.includes(
                                                      "0060"
                                                    )
                                                  ? "（上層主管）"
                                                  : title.isManager === "1" &&
                                                    title.deptType.includes(
                                                      "0060"
                                                    )
                                                  ? "（部門主管）"
                                                  : "（高層主管）"
                                              }`
                                            ),
                                          },
                                        }),
                                  ]
                                }),
                                _c("th", { staticClass: "tc mw90 wp10" }, [
                                  _vm._v("不列入考核"),
                                ]),
                                _vm.hasEdit
                                  ? _c("th", { staticClass: "tc mw150 wp10" }, [
                                      _vm._v("管理"),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.loading
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "tc",
                                      attrs: { colspan: "8" },
                                    },
                                    [_vm._v("查詢中")]
                                  ),
                                ])
                              : group.objectiveScores.length === 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "tc",
                                      attrs: { colspan: "8" },
                                    },
                                    [_vm._v("尚無設定目標")]
                                  ),
                                ])
                              : _vm._l(
                                  group.objectiveScores,
                                  function (data, index) {
                                    return _c(
                                      "tr",
                                      { key: index },
                                      [
                                        _c("td", {
                                          staticClass: "tc",
                                          domProps: {
                                            textContent: _vm._s(index + 1),
                                          },
                                        }),
                                        _c("td", {
                                          staticClass: "tc",
                                          domProps: {
                                            textContent: _vm._s(data.year),
                                          },
                                        }),
                                        _c("td", {
                                          staticClass: "tc",
                                          domProps: {
                                            textContent: _vm._s(data.month),
                                          },
                                        }),
                                        _c("td", {
                                          staticClass: "tc",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.define.objectiveType[
                                                data.objectiveType
                                              ]
                                            ),
                                          },
                                        }),
                                        _c("td", {
                                          staticClass: "pre-wrap",
                                          domProps: {
                                            textContent: _vm._s(data.objective),
                                          },
                                        }),
                                        _vm._l(
                                          data.scores,
                                          function (scoreItem, index) {
                                            return [
                                              _vm.hasEdit &&
                                              group.scoreStatus === 1 &&
                                              _vm.userData.user.employeeId ===
                                                scoreItem.createEmpId
                                                ? _c(
                                                    "td",
                                                    {
                                                      key: index,
                                                      staticClass: "tc",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              scoreItem.score,
                                                            expression:
                                                              "scoreItem.score",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control w90",
                                                        staticStyle: {
                                                          margin: "auto",
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                          min: "0",
                                                        },
                                                        domProps: {
                                                          value:
                                                            scoreItem.score,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              scoreItem,
                                                              "score",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c("td", {
                                                    key: index,
                                                    staticClass: "tc",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        scoreItem.displayEmpty
                                                          ? "－"
                                                          : scoreItem.score || 0
                                                      ),
                                                    },
                                                  }),
                                            ]
                                          }
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "tc" },
                                          [
                                            _c("iCheckCheckBox", {
                                              attrs: {
                                                propNone: true,
                                                propChecked: data.checked,
                                                propDisabled: data.disabled,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", { staticClass: "tc" }, [
                                          _vm.hasEdit &&
                                          group.scoreStatus === 1 &&
                                          group.edit
                                            ? _c(
                                                "div",
                                                { staticClass: "btns" },
                                                [
                                                  _c("input", {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    attrs: {
                                                      type: "submit",
                                                      value: "儲存",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateScoreData(
                                                          group,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c("span", [_vm._v("－")]),
                                        ]),
                                      ],
                                      2
                                    )
                                  }
                                ),
                            _c(
                              "tr",
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "tc fw-b fz18",
                                    attrs: { colspan: "3" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(group.employeeName) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "tc",
                                    attrs: { colspan: "2" },
                                  },
                                  [_vm._v("績效分數")]
                                ),
                                _vm._l(
                                  group.monthItems,
                                  function (scoreItem, index) {
                                    return [
                                      _vm.hasEdit &&
                                      group.scoreStatus === 1 &&
                                      (_vm.userData.user.employeeId ===
                                        scoreItem.createEmpId ||
                                        _vm.userData.user.employeeId ===
                                          scoreItem.employeeId)
                                        ? _c(
                                            "td",
                                            { key: index, staticClass: "tc" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: scoreItem.totalScore,
                                                    expression:
                                                      "scoreItem.totalScore",
                                                  },
                                                ],
                                                staticClass: "form-control w90",
                                                staticStyle: { margin: "auto" },
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                },
                                                domProps: {
                                                  value: scoreItem.totalScore,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      scoreItem,
                                                      "totalScore",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("td", {
                                            key: index,
                                            staticClass: "tc",
                                            domProps: {
                                              textContent: _vm._s(
                                                scoreItem.displayEmpty
                                                  ? "－"
                                                  : scoreItem.totalScore || 0
                                              ),
                                            },
                                          }),
                                    ]
                                  }
                                ),
                                _c("td", { staticClass: "tc" }, [_vm._v("－")]),
                                _c("td", { staticClass: "tc" }, [
                                  _vm.hasEdit &&
                                  group.scoreStatus === 1 &&
                                  group.edit
                                    ? _c("div", { staticClass: "btns" }, [
                                        _c("input", {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            type: "submit",
                                            value: "儲存",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateTotalScoreData(
                                                group,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : group.scoreStatus === 0
                                    ? _c("span", [_vm._v("草稿")])
                                    : group.scoreStatus === 1
                                    ? _c("span", [_vm._v("送簽中")])
                                    : group.scoreStatus === 2
                                    ? _c("span", [_vm._v("送簽完成")])
                                    : _c("span", [_vm._v("－")]),
                                ]),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }