var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "main-header" },
    [
      _c(
        "div",
        {
          staticClass: "logo-box",
          class:
            _vm.user.companyId == "C"
              ? "CTESA"
              : _vm.user.companyId == "T"
              ? "TESL"
              : "",
        },
        [_vm._m(0)]
      ),
      _c("nav", { staticClass: "navbar navbar-static-top" }, [
        _vm._m(1),
        _vm.hasEditPermission
          ? _c("div", { attrs: { id: "top10prizes" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-headline btn-default",
                  on: {
                    click: function ($event) {
                      return _vm.news()
                    },
                  },
                },
                [_vm._v("\n        全部\n      ")]
              ),
              _vm.marquees.total > 0
                ? _c(
                    "div",
                    {
                      staticClass: "ah-headline",
                      attrs: { title: "瀏覽更多最新消息..." },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "ah-words-wrapper" },
                        _vm._l(_vm.marquees.datas, function (item, index) {
                          return _c(
                            "b",
                            {
                              key: index,
                              class: index == 0 ? "is-visible" : "",
                            },
                            [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(item.note) },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "navbar-custom-menu" },
          [
            _c("ul", { staticClass: "nav navbar-nav" }, [
              _c("li", { staticClass: "dropdown user user-menu" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-toggle",
                    attrs: { "data-toggle": "dropdown", href: "#" },
                  },
                  [
                    _c("img", {
                      staticClass: "user-image",
                      attrs: {
                        alt: "User Image",
                        src: require("../assets/user-160x160.jpg"),
                      },
                    }),
                    _c("span", { staticClass: "hidden-xs" }, [
                      _vm._v(_vm._s(_vm.user.employeeName)),
                    ]),
                  ]
                ),
                _c("ul", { staticClass: "dropdown-menu" }, [
                  _c("li", { staticClass: "user-header" }, [
                    _c("img", {
                      staticClass: "img-circle",
                      attrs: {
                        alt: "User Image",
                        src: require("../assets/user-160x160.jpg"),
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.user.deptName) +
                          " - " +
                          _vm._s(_vm.user.employeeName) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.user.empTitleName) +
                          "\n              "
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "user-footer" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default btn-flat mr-10",
                          on: { click: _vm.logout },
                        },
                        [_vm._v("\n                  登出\n                ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-flat",
                          on: { click: _vm.midify },
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.user.isOutsider == 1 ? "編輯" : "更改密碼"
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("midify", { ref: "midify", attrs: { proplogin: true } }),
          ],
          1
        ),
      ]),
      _c("flashInfo", { ref: "flashInfo" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "logo", attrs: { href: "/" } }, [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("網銀國際股份有限公司")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-toggle",
        attrs: { "data-toggle": "push-menu", href: "#", role: "button" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }