import axios from 'axios';
import Vue from 'vue'
import App from '@/App.vue'

const apiPath = process.env.VUE_APP_EIPAPI_PATH;

const systemRequest = axios.create({
  baseURL: apiPath + '/SystemClass',
  withCredentials: true
});
const organizationRequest = axios.create({
  baseURL: apiPath + '/Organization',
  withCredentials: true
});
const announcementRequest = axios.create({
  baseURL: apiPath + '/Announcement',
  withCredentials: true
});
const loginRequest = axios.create({
  baseURL: apiPath + '/Login',
  withCredentials: true
});
const uploadRequest = axios.create({
  baseURL: apiPath + '/UploadFile',
  withCredentials: true
});
const companyRequest = axios.create({
  baseURL: apiPath + '/Company',
  withCredentials: true
});
const fileRequest = axios.create({
  baseURL: apiPath + '/File',
  withCredentials: true
});
const companyFilesRequest = axios.create({
  baseURL: apiPath + '/CompanyDocument',
  withCredentials: true
});
const storeRequest = axios.create({
  baseURL: apiPath + '/MealStore',
  withCredentials: true
});
const storeMenuRequest = axios.create({
  baseURL: apiPath + '/MealList',
  withCredentials: true
});
const empOrderRequest = axios.create({
  baseURL: apiPath + '/MealEat',
  withCredentials: true
});
const mealHabitsRequest = axios.create({
  baseURL: apiPath + '/MealHabits',
  withCredentials: true
});
const officeRequest = axios.create({
  baseURL: apiPath + '/OfficeArea',
  withCredentials: true
});
const officeExtRequest = axios.create({
  baseURL: apiPath + '/officeExtType',
  withCredentials: true
});
const mealTimeSetRequest = axios.create({
  baseURL: apiPath + '/MealTimeSet',
  withCredentials: true
});
const mealCommentRequest = axios.create({
  baseURL: apiPath + '/MealComment',
  withCredentials: true
});
const mealManageRequest = axios.create({
  baseURL: apiPath + '/MealManage',
  withCredentials: true
});
const mealDailySettingRequest = axios.create({
  baseURL: apiPath + '/MealOrder',
  withCredentials: true
});
const meetingRoomRequest = axios.create({
  baseURL: apiPath + '/MeetingRoom',
  withCredentials: true
});
const meetingBookingRequest = axios.create({
  baseURL: apiPath + '/MeetingBooking',
  withCredentials: true
});
const productRequest = axios.create({
  baseURL: apiPath + '/Product',
  withCredentials: true
});
const rollcallRequest = axios.create({
  baseURL: apiPath + '/HomeCheckin',
  withCredentials: true
});
const punchRequest = axios.create({
  baseURL: apiPath + '/Punch',
  withCredentials: true
});
const activityRequest = axios.create({
  baseURL: apiPath + '/Activity',
  withCredentials: true
});
const marqueeRequest = axios.create({
  baseURL: apiPath + '/Marquee',
  withCredentials: true
});
const educationRequest = axios.create({
  baseURL: apiPath + '/Education',
  withCredentials: true
});
const okrRequest = axios.create({
  baseURL: apiPath,
  withCredentials: true
});
const permissionRequest = axios.create({
  baseURL: apiPath + '/Permission',
  withCredentials: true
});

var loginOut = false;
var sessionTiming;

function callAPI(promise) {
  // var promise = axios.post(apiPath + path, data,{withCredentials:true});
  promise.then(res => {
    if (res.data.message != "網頁Timeout，請重新登入~") LoadTimeOutTiming();
    else clearTimeout(sessionTiming);
    if (res.data.status == "-999" && !loginOut) {
      loginOut = true;
      callAPI(loginRequest.post('/UserLogout'));
      alert("閒置過久，請重新登入系統");
      window.location.href = "/";
    }
  }).catch(err => {
    // console.log("bbb");
  });
  return promise;
  // return axios.post(apiPath + path, data,{withCredentials:true}).then(function(res){
  //         res.status = -99
  // });
}

function callAPIPath(request, path, data) {
  if (data == undefined) data = {};
  let promise = request.post(path, data);
  promise.then(res => {
    clearTimeout(sessionTiming);
    if (res.data.status === '-999' && !loginOut) {
      loginOut = true;
      callAPI(loginRequest.post('/UserLogout'));
      alert("閒置過久，請重新登入系統");
      window.location.href = "/";
    }
  }).catch(err => {
    console.log(err);
  });
  return promise;
}

function LoadTimeOutTiming() {
  clearTimeout(sessionTiming);
  sessionTiming = setTimeout(() => {
    vueCtrl.SessionExpired();
  }, 1000 * 60 * 25);
}

var vueCtrl = new Vue({
  render: h => h(App),
  methods: {
    SessionExpired() {
      this.$eventBus.$emit("showConfirm", "閒置過久，請重新登入系統", function (ok) {
        window.location.href = "/";
      }, "警告", true);
    }
  }
});

export default {
  system: {
    typeAll: data => callAPI(systemRequest.post('/QueryAllClass', data)),
    typeCreate: data => callAPI(systemRequest.post('/CreateClass', data)),
    typeUpdate: data => callAPI(systemRequest.post('/UpdateClass', data)),
  },
  department: {
    queryall: data => callAPI(announcementRequest.post('/getDeptList', data)),
    queryEmployees: data => callAPI(announcementRequest.post('/getDeptEmpList', data)),
  },
  organization: {
    getCompanyList: data => callAPI(organizationRequest.post('/GetCompanyList', data)),
    getDeptList: data => callAPI(organizationRequest.post('/GetDeptList', data)),
    getDeptEmpList: data => callAPI(organizationRequest.post('/GetDeptEmpList', data)),
    queryEmployeeAll: data => callAPI(organizationRequest.post('/QueryEmployeeAll', data)),
    queryEmployeeAllEdit: data => callAPI(organizationRequest.post('/QueryEmployeeAllEdit', data)),
    queryEmployeeOne: data => callAPI(organizationRequest.post('/QueryEmployeeOne', data)),
    updateEmployee: data => callAPI(organizationRequest.post('/UpdateEmployee', data)),
  },
  announcement: {
    create: data => callAPI(announcementRequest.post('/create', data)),
    update: data => callAPI(announcementRequest.post('/update', data)),
    query: data => callAPI(announcementRequest.post('/query', data)),
    queryall: data => callAPI(announcementRequest.post('/queryall', data)),
    targetList: data => callAPI(announcementRequest.post('/QueryAnnouncementTargetList', data)),
    delete: data => callAPI(announcementRequest.post('/delete', data)),
    queryIssuedUnit: data => callAPI(announcementRequest.post('/QueryIssuedUnit', data))
  },
  announcementtype: {
    create: data => callAPI(announcementRequest.post('/createType', data)),
    update: data => callAPI(announcementRequest.post('/updateType', data)),
    query: data => callAPI(announcementRequest.post('/queryType', data)),
    queryEdit: data => callAPI(announcementRequest.post('/queryTypeEdit', data)),
    queryall: data => callAPI(announcementRequest.post('/queryAllType', data)),
    delete: data => callAPI(announcementRequest.post('/deleteType', data)),
    queryAllTypeEdit: data => callAPI(announcementRequest.post('/queryAllTypeEdit', data)),
  },
  login: {
    login: data => loginRequest.post('/UserLogin', data),
    logout: data => callAPI(loginRequest.post('/UserLogout')),
    check: data => callAPI(loginRequest.post('/getUserData')),
    syncempdata: data => callAPI(loginRequest.post('/SyncEmpData', data)),
    changePassword: data => callAPI(loginRequest.post('/ChangePassword', data)),
    forgetPassword: data => callAPI(okrRequest.post('/LoginCenter/ForgetPassword', data)),
    updateProfile: data => callAPI(loginRequest.post('/UpdateProfile', data)),
    checkADOutsider: data => callAPI(loginRequest.post('/CheckIsADOutsider', data)),
    mailStatus: data => callAPI(okrRequest.post('/LoginCenter/GetMailVerifyStatus', data)),
  },
  file: {
    upload: data => callAPI(uploadRequest.post('/', data)),
    queryall: data => callAPI(fileRequest.post('/queryall', data)),
    queryApplyAll: data => callAPI(fileRequest.post('/queryApplyAll', data)),
    deleteApply: data => callAPI(fileRequest.post('/DeleteFileApply', data)),
    getCompanyFile: data => callAPI(fileRequest.post('/GetCompanyFile', data)),
  },
  company: {
    queryall: data => callAPI(companyRequest.post('/queryAll'))
  },
  companyFilesType: {
    create: data => callAPI(companyFilesRequest.post('/createType', data)),
    update: data => callAPI(companyFilesRequest.post('/updateType', data)),
    query: data => callAPI(companyFilesRequest.post('/queryType', data)),
    queryall: data => callAPI(companyFilesRequest.post('/queryAllType', data)),
    queryallTypeEdit: data => callAPI(companyFilesRequest.post('/queryAllTypeEdit', data)),
    delete: data => callAPI(companyFilesRequest.post('/deleteType', data)),
    updateOrder: data => callAPI(companyFilesRequest.post('/UpdateTypeShowNumber', data)),
  },
  companyFiles: {
    create: data => callAPI(companyFilesRequest.post('/create', data)),
    update: data => callAPI(companyFilesRequest.post('/update', data)),
    query: data => callAPI(companyFilesRequest.post('/query', data)),
    queryall: data => callAPI(companyFilesRequest.post('/queryall', data)),
    queryIssueTargets: data => callAPI(companyFilesRequest.post('/queryCompanyDocumentTargetList', data)),
    delete: data => callAPI(companyFilesRequest.post('/delete', data)),
    deleteAll: data => callAPI(companyFilesRequest.post('/DeletePatch', data)),
    downloadAll: data => callAPI(companyFilesRequest.post('/AttachsDownload', data)),
    queryIssuedUnit: data => callAPI(companyFilesRequest.post('/QueryIssuedUnit', data))
  },
  store: {
    create: data => callAPI(storeRequest.post('create', data)),
    update: data => callAPI(storeRequest.post('update', data)),
    query: data => callAPI(storeRequest.post('query', data)),
    queryAll: data => callAPI(storeRequest.post('queryall', data)),
    delete: data => callAPI(storeRequest.post('delete', data))
  },
  storeMenu: {
    create: data => callAPI(storeMenuRequest.post('create', data)),
    update: data => callAPI(storeMenuRequest.post('update', data)),
    query: data => callAPI(storeMenuRequest.post('query', data)),
    queryAll: data => callAPI(storeMenuRequest.post('queryAll', data)),
    delete: data => callAPI(storeMenuRequest.post('delete', data))
  },
  empOrder: {
    queryAll: data => callAPI(empOrderRequest.post('queryAll', data)),
    orderWeek: data => callAPI(empOrderRequest.post('QueryOrderNextWeek', data)),
    delete: data => callAPI(empOrderRequest.post('delete', data)),
    order: data => callAPI(empOrderRequest.post('employeeOrder', data)),
    orderDayMenu: data => callAPI(empOrderRequest.post('QueryEmployeeSelect', data)),
    queryOrderWeek: data => callAPI(empOrderRequest.post('QueryOrderWeek', data)),
  },
  mealHabits: {
    query: data => callAPI(mealHabitsRequest.post('query', data)),
    queryAll: data => callAPI(mealHabitsRequest.post('queryAll', data)),
    save: data => callAPI(mealHabitsRequest.post('save', data))
  },
  mealTimeSet: {
    queryLunch: data => callAPI(mealTimeSetRequest.post('queryLunch', data)),
    queryLunchEdit: data => callAPI(mealTimeSetRequest.post('queryLunchEdit', data)),
    queryDinner: data => callAPI(mealTimeSetRequest.post('queryDinner', data)),
    timeSetLunch: data => callAPI(mealTimeSetRequest.post('timeSetLunch', data)),
    timeSetDinner: data => callAPI(mealTimeSetRequest.post('timeSetDinner', data)),
    checkLunchTime: data => callAPI(mealTimeSetRequest.post('checkTimeLunch', data)),
    checkDinnerTime: data => callAPI(mealTimeSetRequest.post('checkTimeDinner', data)),
  },
  mealComment: {
    queryAll: data => callAPI(mealCommentRequest.post('queryAll', data)),
    employeeQuery: data => callAPI(mealCommentRequest.post('employeeQuery', data)),
    save: data => callAPI(mealCommentRequest.post('save', data))
  },
  mealManage: {
    queryMealTotal: data => callAPI(mealManageRequest.post('queryEatMealCount', data)),
    queryEatAll: data => callAPI(mealManageRequest.post('QueryEatAll', data)),
    deleteEat: data => callAPI(mealManageRequest.post('DeleteEat', data)),
    recoveryCancel: data => callAPI(mealManageRequest.post('DeleteEatCancel', data)),
    batchCancel: data => callAPI(mealManageRequest.post('BatchCancel', data)),
    leavesCancel: data => callAPI(mealManageRequest.post('LeavesCancel', data)),
    keepEat: data => callAPI(mealManageRequest.post('KeepEat', data)),
    manageStatus: data => callAPI(mealManageRequest.post('ManageStatus', data)),
    queryEatTotalPrice: data => callAPI(mealManageRequest.post('QueryEatTotalPrice', data)),
  },
  office: {
    create: data => callAPI(officeRequest.post('create', data)),
    update: data => callAPI(officeRequest.post('update', data)),
    delete: data => callAPI(officeRequest.post('delete', data)),
    queryAll: data => callAPI(officeRequest.post('queryAll', data)),
    mealqueryAll: data => callAPI(officeRequest.post('queryAll', data)),
    queryAllEdit: data => callAPI(officeRequest.post('queryAllEdit', data)),
    officeList: data => callAPI(officeRequest.post('officeList', data)),
    officeListEdit: data => callAPI(officeRequest.post('officeListEdit', data)),
    CompanyAreaList: data => callAPI(officeRequest.post('CompanyAreaList', data))
  },
  extType: {
    create: data => callAPI(officeExtRequest.post('create', data)),
    update: data => callAPI(officeExtRequest.post('update', data)),
    delete: data => callAPI(officeExtRequest.post('delete', data)),
    queryAll: data => callAPI(officeExtRequest.post('queryAll', data)),
  },
  mealDailySetting: {
    queryAll: data => callAPI(mealDailySettingRequest.post('QueryOrderDailys', data)),
    update: data => callAPI(mealDailySettingRequest.post('OrderDailyMeal', data)),
    delete: data => callAPI(mealDailySettingRequest.post('OrderDelete', data))
  },
  meetingRoom: {
    queryAll: data => callAPI(meetingRoomRequest.post('QueryAll', data)),
    create: data => callAPI(meetingRoomRequest.post('Create', data)),
    delete: data => callAPI(meetingRoomRequest.post('Delete', data)),
    restInfoSet: data => callAPI(meetingRoomRequest.post('RestInfoSet', data)),
    update: data => callAPI(meetingRoomRequest.post('Update', data)),
    queryRestInfo: data => callAPI(meetingRoomRequest.post('QueryRestInfo', data))
  },
  meetingBooking: {
    queryBookingAll: data => callAPI(meetingBookingRequest.post('QueryMeetingBookingList', data)),
    create: data => callAPI(meetingBookingRequest.post('Create', data)),
    applyList: data => callAPI(meetingBookingRequest.post('QueryApplyList', data)),
    restBookingList: data => callAPI(meetingBookingRequest.post('QueryRestBookingList', data)),
    delete: data => callAPI(meetingBookingRequest.post('Delete', data)),
    checkMeetingBookingPass: data => callAPI(meetingBookingRequest.post('CheckMeetingBookingPass', data)),
    checkRestBookingPass: data => callAPI(meetingBookingRequest.post('CheckRestBookingPass', data))
  },
  product: {
    queryAll: data => callAPI(productRequest.post('queryAll', data)),
    queryList: data => callAPI(productRequest.post('queryList', data)),
    create: data => callAPI(productRequest.post('create', data)),
    update: data => callAPI(productRequest.post('update', data)),
    delete: data => callAPI(productRequest.post('delete', data))
  },
  rollcall: {
    checkcreate: data => callAPI(rollcallRequest.post('CheckinCreate', data)),
    queryAll: data => callAPI(rollcallRequest.post('CheckinQueryAll', data)),
    employeeCheck: data => callAPI(rollcallRequest.post('EmployeeCheck', data)),

  },
  actionwork: {
    queryAll: data => callAPI(rollcallRequest.post('EmployeeQueryAll', data)),
    create: data => callAPI(rollcallRequest.post('EmployeeCreate', data)),
    update: data => callAPI(rollcallRequest.post('EmployeeUpdate', data)),
    delete: data => callAPI(rollcallRequest.post('EmployeeDelete', data)),
    import: data => callAPI(rollcallRequest.post('EmployeeBatchCreate', data)),
    batchDelete: data => callAPI(rollcallRequest.post('EmployeeBatchDelete', data)),

  },
  punch: {
    punchRun: data => callAPI(punchRequest.post('PunchRun', data)),
    queryAll: data => callAPI(punchRequest.post('QueryAll', data)),
  },
  activity: {
    createType: data => callAPI(activityRequest.post('TypeCreate', data)),
    updateType: data => callAPI(activityRequest.post('TypeUpdate', data)),
    queryAllType: data => callAPI(activityRequest.post('TypeQueryAll', data)),
    queryAllTypeEdit: data => callAPI(activityRequest.post('TypeQueryAllEdit ', data)),
    queryAllManagerType: data => callAPI(activityRequest.post('ManagerTypeQueryAll', data)),

    createKind: data => callAPI(activityRequest.post('KindCreate', data)),
    updateKind: data => callAPI(activityRequest.post('KindUpdate', data)),
    queryAllKind: data => callAPI(activityRequest.post('KindQueryAll', data)),
    queryAllManagerKind: data => callAPI(activityRequest.post('ManagerKindQueryAll', data)),

    createManager: data => callAPI(activityRequest.post('ManagerCreate', data)),
    deleteManager: data => callAPI(activityRequest.post('ManagerDelete', data)),
    queryAllManager: data => callAPI(activityRequest.post('ManagerQueryAll', data)),


    create: data => callAPI(activityRequest.post('ActivityCreate', data)),
    update: data => callAPI(activityRequest.post('ActivityUpdate', data)),
    query: data => callAPI(activityRequest.post('ActivityQuery', data)),
    queryAll: data => callAPI(activityRequest.post('ActivityQueryAll', data)),


    addJoin: data => callAPI(activityRequest.post('JoinCreateArrive', data)),
    createJoin: data => callAPI(activityRequest.post('JoinCreate', data)),
    cancelJoin: data => callAPI(activityRequest.post('JoinDelete', data)),
    updateJoin: data => callAPI(activityRequest.post('JoinUpdate', data)),
    queryAllJoin: data => callAPI(activityRequest.post('JoinQueryAll', data)),


    queryAllUserJoin: data => callAPI(activityRequest.post('UserJoinQueryAll', data)),

    checkFinish: data => callAPI(activityRequest.post('ActivityCheckFinish', data)),
  },
  marquee: {
    create: data => callAPI(marqueeRequest.post('Create', data)),
    update: data => callAPI(marqueeRequest.post('Update', data)),
    query: data => callAPI(marqueeRequest.post('QueryOne', data)),
    queryAll: data => callAPI(marqueeRequest.post('QueryList', data)),
    showList: data => callAPI(marqueeRequest.post('ShowList', data)),
    delete: data => callAPI(marqueeRequest.post('Delete', data)),
  },
  expat: {
    queryAll: data => callAPIPath(okrRequest, '/MealAssign/QueryAll', data),
    query: data => callAPIPath(okrRequest, '/MealAssign/Query', data),
    create: data => callAPIPath(okrRequest, '/MealAssign/Create', data),
    update: data => callAPIPath(okrRequest, '/MealAssign/Update', data),
    delete: data => callAPIPath(okrRequest, '/MealAssign/Delete', data),
  },
  okr: {
    goal: {
      newCreate: data => callAPIPath(okrRequest, '/OKRScore/CreateNewObjective', data),
      newDelete: data => callAPIPath(okrRequest, '/OKRScore/DeleteNewObjective', data),
      groupDelete: data => callAPIPath(okrRequest, '/OKRObjective/DeleteGroup', data),
      query: data => callAPIPath(okrRequest, '/OKRObjective/QueryOne', data),
      delete: data => callAPIPath(okrRequest, '/OKRObjective/DeleteObjective', data),
      year: {
        queryCode: data => callAPIPath(okrRequest, '/OKRObjective/GetAutoCode', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryYearList', data),
        create: data => callAPIPath(okrRequest, '/OKRObjective/CreateYearData', data),
        update: data => callAPIPath(okrRequest, '/OKRObjective/UpdateYearData', data),
      },
      division: {
        queryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryDivisionList', data),
        create: data => callAPIPath(okrRequest, '/OKRObjective/CreateDivisionData', data),
        update: data => callAPIPath(okrRequest, '/OKRObjective/UpdateDivisionData', data),
      },
      department: {
        list: data => callAPIPath(okrRequest, '/OKRObjective/GetOrgDeptList', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryDeptmentList', data),
        create: data => callAPIPath(okrRequest, '/OKRObjective/CreateDeptmentData', data),
        update: data => callAPIPath(okrRequest, '/OKRObjective/UpdateDeptmentData', data),
      },
      personal: {
        deptQueryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryGroupCheckList', data),
        userQueryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryGroupList', data),
        list: data => callAPIPath(okrRequest, '/OKRObjective/GetOrgEmployeeList', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryEmployeeList', data),
        create: data => callAPIPath(okrRequest, '/OKRObjective/CreateEmployeeData', data),
        update: data => callAPIPath(okrRequest, '/OKRObjective/UpdateEmployeeData', data),
      },
      newCrew: {
        queryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryNewJoinList', data),
        create: data => callAPIPath(okrRequest, '/OKRObjective/CreateNewJoinData', data),
        update: data => callAPIPath(okrRequest, '/OKRObjective/UpdateNewJoinData', data),
      },
      trainee: {
        queryAll: data => callAPIPath(okrRequest, '/OKRObjective/QueryTraineeList', data),
        create: data => callAPIPath(okrRequest, '/OKRObjective/CreateTraineeData', data),
        update: data => callAPIPath(okrRequest, '/OKRObjective/UpdateTraineeData', data),
      }
    },
    score: {
      job: {
        queryAll: data => callAPIPath(okrRequest, '/OKRScore/NewJobGroupList', data),
        query: data => callAPIPath(okrRequest, '/OKRScore/QueryNewJobData', data),
        update: data => callAPIPath(okrRequest, '/OKRScore/NewJobUpdate', data),
      },
      min: {
        cycleQuery: data => callAPIPath(okrRequest, '/OKRScore/GetCycleMonths', data),
        historyQueryAll: data => callAPIPath(okrRequest, '/OKRScore/GroupHistoryList', data),
        crossDeptQueryAll: data => callAPIPath(okrRequest, '/OKRScore/CrossDept', data),
        crossUserQueryAll: data => callAPIPath(okrRequest, '/OKRScore/CrossDeptEmp', data),
        deptQueryAll: data => callAPIPath(okrRequest, '/OKRScore/GroupCheckQueryList', data),
        groupQueryAll: data => callAPIPath(okrRequest, '/OKRScore/GroupQueryList', data),
        groupDelete: data => callAPIPath(okrRequest, '/OKRScore/DeleteGroup', data),
        monthQueryAll: data => callAPIPath(okrRequest, '/OKRScore/ScoreMonthQueryList', data),
        monthUpdate: data => callAPIPath(okrRequest, '/OKRScore/ScoreMonthUpdate', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRScore/QueryScoreData', data),
        update: data => callAPIPath(okrRequest, '/OKRScore/ScoreUpdate', data),
        balance: {
          hrQueryAll: data => callAPIPath(okrRequest, '/OKRScore/ScoreTotalList', data),
          queryAll: data => callAPIPath(okrRequest, '/OKRScore/ScoreMonthBalanceList', data),
          update: data => callAPIPath(okrRequest, '/OKRScore/ScoreMonthBalanceUpdate', data),
        },
        total: {
          queryAll: data => callAPIPath(okrRequest, '/OKRScore/ScoreMapList', data),
          update: data => callAPIPath(okrRequest, '/OKRScore/ScoreUpdatePatch', data),
        },
      },
      max: {
        historyQueryAll: data => callAPIPath(okrRequest, '/OKRBig/GroupHistoryList', data),
        deptQueryAll: data => callAPIPath(okrRequest, '/OKRBig/GroupCheckQueryList', data),
        groupQueryAll: data => callAPIPath(okrRequest, '/OKRBig/GroupQueryList', data),
        monthQueryAll: data => callAPIPath(okrRequest, '/OKRBig/BigScoreQueryList', data),
        monthUpdate: data => callAPIPath(okrRequest, '/OKRBig/BigScoreUpdate', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRBig/QueryScoreData', data),
        update: data => callAPIPath(okrRequest, '/OKRBig/ItemScoreUpdate', data),
        add: data => callAPIPath(okrRequest, '/OKRBig/BigGroupClose', data),
        balance: {
          queryAll: data => callAPIPath(okrRequest, '/OKRBig/TotalBalanceList', data),
          update: data => callAPIPath(okrRequest, '/OKRBig/TotalBalanceUpdate', data),
        },
        total: {
          queryAll: data => callAPIPath(okrRequest, '/OKRBig/ScoreMapList', data),
        },
      },
    },
    mgmt: {
      sign: {
        send: data => callAPIPath(okrRequest, '/OKRFlow/SendSign', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRFlow/SignList', data),
        queryAllLog: data => callAPIPath(okrRequest, '/OKRFlow/SignLogQueryList', data),
        queryAllHistory: data => callAPIPath(okrRequest, '/OKRFlow/SignHistoryList', data),
        count: data => callAPIPath(okrRequest, '/OKRFlow/SignCount', data),
        cancel: data => callAPIPath(okrRequest, '/OKRFlow/SignCancel', data),
      },
      group: {
        query: data => callAPIPath(okrRequest, '/OKRFlow/GroupQueryOne', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRFlow/GroupQueryList', data),
        create: data => callAPIPath(okrRequest, '/OKRFlow/GroupCreate', data),
        update: data => callAPIPath(okrRequest, '/OKRFlow/GroupUpdate', data),
        delete: data => callAPIPath(okrRequest, '/OKRFlow/GroupDelete', data),
      },
      flow: {
        setScore: data => callAPIPath(okrRequest, '/OKRFlow/GroupUpdateNonScore', data),
        addScore: data => callAPIPath(okrRequest, '/OKRFlow/GroupUpdateAddScore', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRFlow/GroupFlowList', data),
        update: data => callAPIPath(okrRequest, '/OKRFlow/GroupFlowUpdate', data),
      },
      cycle: {
        query: data => callAPIPath(okrRequest, '/OKRSet/CycleQueryOne', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRSet/CycleQueryList', data),
        create: data => callAPIPath(okrRequest, '/OKRSet/CycleCreate', data),
        update: data => callAPIPath(okrRequest, '/OKRSet/CycleUpdate', data),
        delete: data => callAPIPath(okrRequest, '/OKRSet/CycleDelete', data),
      },
      open: {
        query: data => callAPIPath(okrRequest, '/OKRSet/OpenTimeQueryOne', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRSet/OpenTimeQueryList', data),
        create: data => callAPIPath(okrRequest, '/OKRSet/OpenTimeCreate', data),
        update: data => callAPIPath(okrRequest, '/OKRSet/OpenTimeUpdate', data),
        delete: data => callAPIPath(okrRequest, '/OKRSet/OpenTimeDelete', data),
      },
      balance: {
        queryAll: data => callAPIPath(okrRequest, '/OKRSet/CloseTimeQueryList', data),
        create: data => callAPIPath(okrRequest, '/OKRSet/CloseTimeCreate', data),
        update: data => callAPIPath(okrRequest, '/OKRSet/CloseTimeUpdate', data),
        delete: data => callAPIPath(okrRequest, '/OKRSet/CloseTimeDelete', data),
      },
      history: {
        queryAll: data => callAPIPath(okrRequest, '/OKRSet/OpenTimeHistoryList', data),
      },
      limit: {
        queryAll: data => callAPIPath(okrRequest, '/OKRSet/TimeLimitQueryAll', data),
        update: data => callAPIPath(okrRequest, '/OKRSet/TimeLimitUpdate', data),
      },
      assign: {
        query: data => callAPIPath(okrRequest, '/OKRSet/TraineeAssignQueryOne', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRSet/TraineeAssignQueryList', data),
        create: data => callAPIPath(okrRequest, '/OKRSet/TraineeAssignCreate', data),
        update: data => callAPIPath(okrRequest, '/OKRSet/TraineeAssignUpdate', data),
        delete: data => callAPIPath(okrRequest, '/OKRSet/TraineeAssignDelete', data),
      },
    },
    v2: {
      rater: {
        queryAll: data => callAPIPath(okrRequest, '/OKRRater/RaterQueryList', data),
        query: data => callAPIPath(okrRequest, '/OKRRater/RaterQueryOne', data),
        create: data => callAPIPath(okrRequest, '/OKRRater/RaterCreate', data),
        delete: data => callAPIPath(okrRequest, '/OKRRater/RaterDelete', data),
        ScoreQueryAll: data => callAPIPath(okrRequest, '/OKRRater/RaterDeptAddList', data),
        notice: data => callAPIPath(okrRequest, '/OKRRater/OKRNoticeCount', data),
        deptQueryAll: data => callAPIPath(okrRequest, '/OKRRater/RaterDeptList ', data),
        DeptHistoryQueryAll: data => callAPIPath(okrRequest, '/OKRRater/DeptHistoryAddList ', data),
        updateRaterMonth: data => callAPIPath(okrRequest, '/OKRRater/UpdateRaterMonth ', data),
      },
      big: {
        query: data => callAPIPath(okrRequest, '/OKRRater/QueryRaterData', data),
        queryAll: data => callAPIPath(okrRequest, '/OKRRater/RaterBigList', data),
        hrQueryAll: data => callAPIPath(okrRequest, '/OKRRater/RaterCloseList', data),
        deptQueryAll: data => callAPIPath(okrRequest, '/OKRRater/RaterBigDeptList ', data),
        totalBalanceList: data => callAPIPath(okrRequest, '/OKRRater/TotalBalanceList', data),
        update: data => callAPIPath(okrRequest, '/OKRRater/RaterBigUpdate', data),
        create: data => callAPIPath(okrRequest, '/OKRRater/RaterCloseCreate', data),
      },
      history: {
        queryReceive: data => callAPIPath(okrRequest, '/OKRRater/HistoryReceiveList', data),
        queryAdd: data => callAPIPath(okrRequest, '/OKRRater/HistoryAddList', data),
        queryRater: data => callAPIPath(okrRequest, '/OKRRater/HistoryRaterList', data),
      }
    }
  },
  xinStarts: {
    queryAll: data => callAPIPath(okrRequest, '/Xinstar/QueryXinstarUser', data),
    updateQueryAll: data => callAPIPath(okrRequest, '/Xinstar/UpdateAllXinstarUser', data),
    create: data => callAPIPath(okrRequest, '/Xinstar/AddXinstarUser', data),
    delete: data => callAPIPath(okrRequest, '/Xinstar/DeleteXinstarUser', data),
    update: data => callAPIPath(okrRequest, '/Xinstar/UpdateXinstarUser', data),
  },
  shop: {
    queryAll: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryAllStore', data),
    query: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryStore', data),
    create: data => callAPIPath(okrRequest, '/AffiliatedStore/CreateStore', data),
    update: data => callAPIPath(okrRequest, '/AffiliatedStore/UpdateStore', data),
    file: data => callAPIPath(okrRequest, '/UploadFile', data),
    admin: {
      queryAll: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryAllStoreAdmin', data),
      query: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryStoreAdmin', data),
    }
  },
  shopClass: {
    queryAll: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryAllClass', data),
    query: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryClass', data),
    create: data => callAPIPath(okrRequest, '/AffiliatedStore/CreateClass', data),
    update: data => callAPIPath(okrRequest, '/AffiliatedStore/UpdateClass', data),
    admin: {
      queryAll: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryAllClassAdmin', data),
      query: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryClassAdmin', data),
    }
  },
  shopFeeback: {
    queryAll: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryAllComment', data),
    query: data => callAPIPath(okrRequest, '/AffiliatedStore/QueryComment', data),
    create: data => callAPIPath(okrRequest, '/AffiliatedStore/CreateComment', data),
    update: data => callAPIPath(okrRequest, '/AffiliatedStore/UpdateComment', data),
  },
  permission: {
    companyChange: data => callAPI(permissionRequest.post('/CompanyChange', data)),
  },
  education: {
    company: data => callAPIPath(educationRequest, '/GetCompanyList', data),
    deptList: data => callAPIPath(educationRequest, '/GetDeptList', data),
    class: data => callAPIPath(educationRequest, '/GetClassList', data),
    employee: data => callAPIPath(educationRequest, '/GetAllCompanyEmployeeList', data),
    manager: data => callAPIPath(educationRequest, '/GetAllCompanySupervisorList', data),
    notice: data => callAPIPath(educationRequest, '/CourseNoticeCount', data),
    course: {
      queryAll: data => callAPIPath(educationRequest, '/QueryAllCourse', data),
      query: data => callAPIPath(educationRequest, '/QueryCourse', data),
      create: data => callAPIPath(educationRequest, '/CreateCourse', data),
      update: data => callAPIPath(educationRequest, '/UpdateCourse', data),
      detail: data => callAPIPath(educationRequest, '/getCourseRecordDetail', data),
      record: data => callAPIPath(educationRequest, '/getAllCourseRecord', data),
      student: data => callAPIPath(educationRequest, '/GetDoCourseList', data),
      mail: data => callAPIPath(educationRequest, '/SendNotifyMail', data),
      check: data => callAPIPath(educationRequest, '/CheckCourseIsFinish', data),
      copy: data => callAPIPath(educationRequest, '/CopyCourse', data),
    },
    chapter: {
      queryAll: data => callAPIPath(educationRequest, '/QueryAllChapter', data),
      query: data => callAPIPath(educationRequest, '/QueryChapter', data),
      create: data => callAPIPath(educationRequest, '/CreateChapter', data),
      update: data => callAPIPath(educationRequest, '/UpdateChapter', data),
    },
    record: {
      main: {
        queryAll: data => callAPIPath(educationRequest, '/QueryAllLearningRecord', data),
        query: data => callAPIPath(educationRequest, '/QueryLearningRecord', data),
        create: data => callAPIPath(educationRequest, '/CreateLearningRecord', data),
        update: data => callAPIPath(educationRequest, '/UpdateLearningRecord', data),
      },
      detail: {
        queryAll: data => callAPIPath(educationRequest, '/QueryAllLearningDetail', data),
        query: data => callAPIPath(educationRequest, '/QueryLearningDetail', data),
        create: data => callAPIPath(educationRequest, '/CreateOrUpdateLearningDetail', data),
        update: data => callAPIPath(educationRequest, '/UpdateLearningDetail', data),
      },
      dept: {
        queryAll: data => callAPIPath(educationRequest, '/QueryAllCourseRelationDept', data),
        query: data => callAPIPath(educationRequest, '/QueryCourseRelationDept', data),
        create: data => callAPIPath(educationRequest, '/CreateCourseRelationDept', data),
        delete: data => callAPIPath(educationRequest, '/DeleteCourseRelationDept', data),
      },
      all: {
        dept: data => callAPIPath(educationRequest, '/QueryAllDeptLearningRecord', data),
        personal: data => callAPIPath(educationRequest, '/QueryAllEmpLearningRecord', data),
        detail: data => callAPIPath(educationRequest, '/QueryEmpLearningRecord', data),
      },
    },
    quiz: {
      queryAll: data => callAPIPath(educationRequest, '/QueryAllTests', data),
      create: data => callAPIPath(educationRequest, '/CreateTestWithQuesOpts', data),
      update: data => callAPIPath(educationRequest, '/UpdateTestWithQuesOpts', data),
      result: data => callAPIPath(educationRequest, '/QueryAllTestResult', data),
      submitExam: data => callAPIPath(educationRequest, '/GetCourseTestResult', data),
    }
  }
}
