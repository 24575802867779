<template>
  <div id="TraineeList">
    <section class="content-header">
      <h1>小考週期設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">小考週期設定</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning" style="width: 1050px">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="createData()"
              >
                <i class="fa fa-plus"></i>
                新增設定
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.halfYear"
                  @change="resetDataList()"
                >
                  <option value="-1">全部</option>
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="resetDataList()"
                >
                  <option value="">部別</option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc" width="20%">年份</th>
                  <th class="tc" width="15%">年度</th>
                  <th class="tc" width="30%">部別</th>
                  <th class="tc" width="15%">週期 (月)</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="5">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="5">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-if="data.isCreate">
                    <select class="form-control" v-model.number="data.year">
                      <option
                        v-for="(year, index) in years"
                        :key="index"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </td>
                  <td class="tc" v-else v-text="data.year"></td>
                  <td class="tc" v-if="data.isCreate">
                    <select class="form-control" v-model.number="data.halfYear">
                      <option value="0">上半年</option>
                      <option value="1">下半年</option>
                    </select>
                  </td>
                  <td
                    class="tc"
                    v-else
                    v-text="define.halfYearStatus[data.halfYear]"
                  ></td>
                  <td class="tc" v-if="data.isCreate">
                    <select class="form-control" v-model="data.deptId">
                      <option value="">部別</option>
                      <option
                        v-for="(item, index) in list"
                        :key="index"
                        :value="item.deptId"
                      >
                        {{ item.deptName }}
                      </option>
                    </select>
                  </td>
                  <td class="tc" v-else v-text="data.deptName"></td>
                  <td class="tc" v-if="data.isEdit">
                    <select
                      class="form-control"
                      v-model.number="data.cycleMonth"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="6">6</option>
                    </select>
                  </td>
                  <td class="tc" v-else v-text="data.cycleMonth"></td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      v-show="!data.isEdit"
                      @click="updateData(data)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-danger ml10"
                      v-show="!data.isEdit"
                      @click="deleteData(data)"
                    >
                      刪除
                    </button>
                    <button
                      class="btn btn-success"
                      v-show="data.isEdit"
                      @click="saveData(data)"
                    >
                      儲存
                    </button>
                    <button
                      class="btn btn-default ml10"
                      v-show="data.isEdit"
                      @click="cancelData(data)"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        halfYear: -1,
        deptId: "",
      },
      years: this.global.getYearList(),
      list: [],
      datas: [],
      data: {},
      orginData: {},
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getList();
  },
  methods: {
    getList() {
      api.okr.goal.department.list({ deptLevel: 2 }).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.getDataList();
        }
      });
    },
    getDataList() {
      api.okr.mgmt.cycle.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          this.data.isCreate = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    createData() {
      if (this.datas.length && this.datas[0].isCreate) {
        this.$eventBus.$emit("showAlert", "您尚未儲存新增項目！");
        return;
      }
      this.cancelData(this.data);
      const nextHalfYear = this.global.getNextHalfYear();
      this.data = {
        year: nextHalfYear ? this.global.getYear() : this.global.getNextYear(),
        halfYear: nextHalfYear,
        deptId: this.searchData.deptId,
        cycleMonth: 1,
        isEdit: true,
        isCreate: true,
      };
      this.datas.splice(0, 0, this.data);
    },
    updateData(data) {
      this.cancelData(this.data);
      data.isEdit = true;
      this.data = data;
      this.orginData.cycleMonth = data.cycleMonth;
      this.$forceUpdate();
    },
    cancelData(data) {
      data.isEdit = false;
      if (data.isCreate) {
        this.datas.splice(0, 1);
        data.isCreate = false;
      } else {
        data.cycleMonth = this.orginData.cycleMonth;
        this.$forceUpdate();
      }
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.mgmt.cycle.delete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    saveData(data) {
      if (data.deptId) {
        const action = data.isCreate ? "create" : "update";
        api.okr.mgmt.cycle[action](data).then((res) => {
          this.global.showResponse(this, res, action, this.getDataList);
        });
      } else {
        this.$eventBus.$emit("showAlert", "請選擇部別！");
      }
    },
  },
};
</script>
