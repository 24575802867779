<template>
  <header class="main-header">
    <div
      class="logo-box"
      :class="
        user.companyId == 'C' ? 'CTESA'
        : user.companyId == 'T' ? 'TESL' : ''
      "
    >
      <a class="logo" href="/">
        <h1 class="sr-only">網銀國際股份有限公司</h1>
      </a>
    </div>
    <nav class="navbar navbar-static-top">
      <a class="sidebar-toggle" data-toggle="push-menu" href="#" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>
      <div id="top10prizes" v-if="hasEditPermission">
        <button
          class="btn btn-headline btn-default"
          @click="news()"
        >
          全部
        </button>
        <div
          class="ah-headline"
          v-if="marquees.total > 0"
          title="瀏覽更多最新消息..."
        >
          <span class="ah-words-wrapper">
            <b
              :class="index == 0 ? 'is-visible' : ''"
              :key="index"
              v-for="(item, index) in marquees.datas"
            >
              <span v-html="item.note"></span>
            </b>
          </span>
        </div>
      </div>

      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown user user-menu">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#">
              <img
                alt="User Image"
                class="user-image"
                src="../assets/user-160x160.jpg"
              />
              <span class="hidden-xs">{{ user.employeeName }}</span>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <img
                  alt="User Image"
                  class="img-circle"
                  src="../assets/user-160x160.jpg"
                />
                <p>
                  {{ user.deptName }} - {{ user.employeeName }}
                  <br />
                  {{ user.empTitleName }}
                </p>
              </li>
              <li class="user-footer">
                <div class="text-center">
                  <button
                    class="btn btn-default btn-flat mr-10"
                    v-on:click="logout"
                  >
                    登出
                  </button>
                  <button
                    class="btn btn-danger btn-flat"
                    v-on:click="midify"
                  >
                    {{ user.isOutsider == 1 ? "編輯" : "更改密碼" }}
                  </button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <midify :proplogin="true" ref="midify" />
      </div>
    </nav>
    <flashInfo ref="flashInfo" />
  </header>
</template>

<script>
import api from "../assets/js/app-api";
import midify from "@/views/Login/midify";
import flashInfo from "@/views/Marquee/FlashInfo";
import config from "@/assets/js/app-config";
export default {
  components: {
    midify,
    flashInfo,
  },
  data() {
    return {
      isChange: this.$store.state.changePassword,
      user: [],
      marquees: [],
      marqueesNote: "",
      showText: false,
      isPause: false,
      hasEditPermission: false
    };
  },
  created(){
    this.hasEditPermission = this.$user.hasPermission(config.Permission.Home);
  },
  mounted() {
    if (this.$store.state.loginUser == "") {
      this.logout();
      return;
    }
    if(this.$store.state.loginUser.companyId == "N") $('#App').addClass('skin-newtalk');
    this.user = this.$store.state.loginUser;
    this.marquee();
  },
  methods: {
    logout() {
      this.$router.push("/login");
      api.login.logout();
    },
    async marquee() {
      await api.marquee.showList().then((res) => {
        if (res.data.status == "1") {
          this.marquees = res.data.result;
        }
      });

      if (this.marquees.total > 1) {
        $("#top10prizes").animatedHeadline({
          animationType: "slide",
        });
      }
    },
    news() {
      this.$refs.flashInfo.showFlashInfoBox();
    },
    midify() {
      this.$refs.midify.showMidifyBox();
    },
  },
};
</script>
