var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Newcomers" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("本月新進同仁")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("公司公告")]),
          _c("li", { staticClass: "active" }, [_vm._v("本月新進同仁")]),
        ]),
      ]),
      _vm._m(0),
      _c("AlertBox"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "box-body no-padding" }, [
            _c("ul", { staticClass: "users-list clearfix" }, [
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("企劃處-產品企劃部"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("廖OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("研發處-研發四部"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("謝OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("企劃處-測試部"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("林OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("企劃處-測試部"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("黃OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("研發處-研發二部"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("林OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("研發處-研發二部"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("陳OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("行政管理處-資訊部(技術客服組)"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("王OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
              _c("li", [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    attrs: {
                      "data-target": "#newcomers-info",
                      "data-toggle": "modal",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic jqimgFill" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: require("../assets/user-160x160.jpg"),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "department" }, [
                      _vm._v("行政管理處-資訊部(技術客服組)"),
                    ]),
                    _c("h4", { staticClass: "users-list-name" }, [
                      _vm._v("高OO"),
                    ]),
                    _c("p", { staticClass: "users-list-date" }, [
                      _vm._v("2019/07/01"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }