var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.courseName))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.courseName))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isFinished,
                      expression: "searchData.isFinished",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "isFinished",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.classList.finishedType, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.value } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.value == "" ? "全部狀態" : item.name) +
                          "\n              "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(1)]
                      : _vm.isNoData
                      ? [_vm._m(2)]
                      : [
                          _vm.list.length > 0
                            ? _vm._l(_vm.list, function (data, index) {
                                return _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.checkFinishedShow(data),
                                        expression: "checkFinishedShow(data)",
                                      },
                                    ],
                                    key: index,
                                  },
                                  [
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            (_vm.searchData.pageNum - 1) *
                                              _vm.searchData.pageSize +
                                              index +
                                              1
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(data.chapterName)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            data.isFinished == 1
                                              ? "已完成"
                                              : "未完成"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.convertTimeToMinutes(
                                              data.leftLearnTime
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              data.createTime
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning",
                                          on: {
                                            click: function ($event) {
                                              return _vm.goDetail(data)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        上課\n                      "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              })
                            : _vm._e(),
                          _vm.testData.testId
                            ? _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkFinishedShow(
                                        _vm.testData
                                      ),
                                      expression: "checkFinishedShow(testData)",
                                    },
                                  ],
                                },
                                [
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.list.length + 1)),
                                  ]),
                                  _c("td", [_vm._v("課程測驗")]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.testData.isFinished == 1
                                            ? "已完成"
                                            : "未完成"
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(Number(_vm.testData.score)) + "分"
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            _vm.testData.createTime
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(
                                              _vm.testData,
                                              "test"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      測驗\n                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "5%" },
          },
          [_vm._v("#")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap",
            staticStyle: { width: "15%", "min-width": "180px" },
          },
          [_vm._v("\n                  課程名稱\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  課程狀態\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  剩餘時數/成績\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  建立時間\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  功能\n                ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
        _vm._v("查無資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }