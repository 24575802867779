var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "PermissionList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("功能選單設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("系統管理")]),
          _c("li", [_vm._v("權限管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("功能選單設定")]),
        ]),
      ]),
      _vm._m(0),
      _c("AlertBox"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content groups" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: {
                  type: "button",
                  "data-target": "#add-features",
                  "data-toggle": "modal",
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" 新增功能\n          "),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered table-striped min-600",
              },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("功能代號")]),
                    _c("th", [_vm._v("功能名稱")]),
                    _c("th", { staticClass: "text-nowrap" }, [_vm._v("排序")]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("網頁MENU"),
                    ]),
                    _c("th", [_vm._v("頁面路徑")]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("開放設定"),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { "min-width": "260px" },
                      },
                      [_vm._v("功能")]
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("S0")]),
                    _c("td", [_vm._v("簽核管理")]),
                    _c("td", [_vm._v("100")]),
                    _c("td", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fa fa-check text-green" }),
                    ]),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("子功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("C0")]),
                    _c("td", [_vm._v("績效考核")]),
                    _c("td", [_vm._v("200")]),
                    _c("td", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fa fa-check text-green" }),
                    ]),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fa fa-check text-green" }),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("子功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("A0")]),
                    _c("td", [_vm._v("帳號管理")]),
                    _c("td", [_vm._v("300")]),
                    _c("td", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fa fa-check text-green" }),
                    ]),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fa fa-check text-green" }),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("子功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("P0")]),
                    _c("td", [_vm._v("權限管理")]),
                    _c("td", [_vm._v("500")]),
                    _c("td", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fa fa-check text-green" }),
                    ]),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("子功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("X0")]),
                    _c("td", [_vm._v("個別權限定義")]),
                    _c("td", [_vm._v("600")]),
                    _c("td", { staticClass: "text-center" }),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("子功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "h4 pull-left" }, [
              _vm._v("X0 個別權限定義 - 子功能設定"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: {
                  type: "button",
                  "data-target": "#add-features",
                  "data-toggle": "modal",
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" 新增子功能\n          "),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered table-striped min-600",
              },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("功能代號")]),
                    _c("th", [_vm._v("功能名稱")]),
                    _c("th", { staticClass: "text-nowrap" }, [_vm._v("排序")]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("網頁MENU"),
                    ]),
                    _c("th", [_vm._v("頁面路徑")]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("開放設定"),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { "min-width": "260px" },
                      },
                      [_vm._v("功能")]
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("X0-001")]),
                    _c("td", [_vm._v("可新增主辦單位")]),
                    _c("td", [_vm._v("100")]),
                    _c("td", { staticClass: "text-center" }),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("次項功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("X0-888")]),
                    _c("td", [_vm._v("SystemAdmin行使的權限")]),
                    _c("td", [_vm._v("500")]),
                    _c("td", { staticClass: "text-center" }),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }),
                    _c("td", { staticClass: "text-center" }, [
                      _c("button", { staticClass: "btn btn-info" }, [
                        _vm._v("次項功能設定"),
                      ]),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            "data-target": "#add-features",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v("\n                   \n                  "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: {
                            "data-target": "#attention",
                            "data-toggle": "modal",
                          },
                        },
                        [_vm._v("刪除")]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }