<template>
  <div id="BigScoreTotalEdit">
    <section class="content-header">
      <h1>總表分數修改</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link :to="`/OKR/BigScorePersonalList`">
            總表分數修改
          </router-link>
        </li>
        <li class="active">
          {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}總表
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}總表
            </h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <div class="tc" v-if="data.message">
            {{ data.message }}
          </div>
          <div v-else>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td>
                      <table class="table no-border" style="width: 400px">
                        <tbody>
                          <tr>
                            <th class="tr w90">部別：</th>
                            <td v-text="deptment.deptName"></td>
                          </tr>
                          <tr>
                            <th class="tr">年度：</th>
                            <td
                              v-text="
                                `${addData.year}年(${
                                  define.halfYearStatus[addData.month]
                                })`
                              "
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <BigScoreNote />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box-footer mt-15"></div>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table
                class="table table-hover table-bordered min-800"
                style="margin: 5px auto"
                v-for="(group, index) in groups"
                :key="index"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc mw100 wp10">#</th>
                    <th class="tc mw150 wp20">核心行為價值觀評分</th>
                    <template v-for="(title, index) in group.titles">
                      <th
                        class="tc mw90 wp10 pre-wrap"
                        :key="index"
                        v-if="title.displayEmpty"
                        v-text="'－'"
                      ></th>
                      <th
                        class="tc mw90 wp10 pre-wrap"
                        :key="index"
                        v-else
                        v-text="
                          `${title.employeeName}\n${
                            index === 0
                              ? '（自評）'
                              : title.isManager === '0' &&
                                title.deptType.includes('0060')
                              ? '（上層主管）'
                              : title.isManager === '1' &&
                                title.deptType.includes('0060')
                              ? '（部門主管）'
                              : '（高層主管）'
                          }`
                        "
                      ></th>
                    </template>
                    <th class="tc mw150 wp10" v-if="hasEdit">管理</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" colspan="4">查詢中</td>
                  </tr>
                  <tr v-else-if="group.bigItems.length === 0">
                    <td class="tc" colspan="4">尚無設定目標</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(data, index) in group.bigItems"
                    :key="index"
                  >
                    <td class="tc" v-text="index + 1"></td>
                    <td class="tc" v-text="data.itemName"></td>
                    <template v-for="(scoreItem, index) in data.scores">
                      <td
                        class="tc"
                        :key="index"
                        v-if="
                          hasEdit &&
                          group.status === 1 &&
                          userData.user.employeeId === scoreItem.createEmpId
                        "
                      >
                        <input
                          type="number"
                          style="margin: auto"
                          class="form-control w90"
                          min="0"
                          v-model="scoreItem.score"
                        />
                      </td>
                      <td
                        class="tc"
                        :key="index"
                        v-else
                        v-text="
                          scoreItem.displayEmpty ? '－' : scoreItem.score || 0
                        "
                      ></td>
                    </template>
                    <td class="tc">
                      <div
                        class="btns"
                        v-if="hasEdit && group.status === 1 && group.edit"
                      >
                        <input
                          type="submit"
                          value="儲存"
                          class="btn btn-primary"
                          @click="updateScoreData(group, index)"
                        />
                      </div>
                      <span v-else>－</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="tc fw-b fz18">
                      {{ group.employeeName }}
                    </td>
                    <td class="tc">大考績效分數</td>
                    <template v-for="(scoreItem, index) in group.bigScores">
                      <td
                        class="tc"
                        :key="index"
                        v-text="
                          scoreItem.displayEmpty
                            ? '－'
                            : scoreItem.totalScore || 0
                        "
                      ></td>
                    </template>
                    <td class="tc">
                      <span v-if="group.status === 0">草稿</span>
                      <span v-else-if="group.status === 1">送簽中</span>
                      <span v-else-if="group.status === 2">送簽完成</span>
                      <span v-else>－</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import BigScoreNote from "./BigScoreNote";

export default {
  components: {
    BigScoreNote,
  },
  data() {
    return {
      hasAll: false,
      hasEdit: false,
      loading: true,
      total: 0,
      addData: {
        type: this.$route.params.type,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.id,
        searchData: this.$route.params.searchData,
      },
      person: this.global.getPerson(this.$route.params.type),
      id: this.$route.params.id,
      userData: {},
      permission: {},
      deptment: {},
      titles: [],
      groups: [],
      data: {},
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasAll = true;
    this.hasEdit = this.hasAll;
    this.getDataList();
  },
  methods: {
    getDataList() {
      if (this.id) {
        this.addData.deptLevel = this.person.level;
        api.okr.score.max.total.queryAll(this.addData).then((res) => {
          this.data = res.data;
          if (res.data.status === "1") {
            if (res.data.result) {
              this.deptment = res.data.result.deptment || {};
              this.titles = res.data.result.empList || [];
              this.groups = res.data.result.groups || [];
              this.updateDataList();
            }
          }
        });
      } else {
        this.loading = false;
      }
    },
    updateDataList() {
      this.groups.forEach((group) => {
        group.titles = JSON.parse(JSON.stringify(this.titles));
        group.titles.splice(0, 0, {
          employeeId: group.employeeId,
          employeeName: group.employeeName,
          createEmpId: group.employeeId,
          createEmpName: group.employeeName,
        });
        if (group.bigScores) {
          group.titles.forEach((title, index) => {
            if (group.bigScores[index]) {
              title.displayEmpty = group.bigScores[index].displayEmpty;
            }
          });
        }
        group.bigItems.forEach((data) => {
          data.scores.splice(0, 0, {
            createEmpId: group.employeeId,
            createEmpName: group.employeeName,
            score: data.score,
          });
          data.scores.forEach((scoreItem) => {
            if (this.userData.user.employeeId === scoreItem.createEmpId) {
              group.edit = true;
            }
          });
        });
        this.updateTotalScore(group);
      });
      this.loading = false;
    },
    updateTotalScore(group) {
      group.bigScores =
        group.bigScores && group.bigScores.length
          ? group.bigScores
          : group.titles;
      group.bigScores.forEach((scoreData) => {
        let totalScore = 0;
        group.bigItems.forEach((data) => {
          const scoreItem = data.scores.find(
            (x) => scoreData.createEmpId === x.createEmpId
          );
          if (scoreItem) {
            totalScore += scoreItem.score || 0;
          }
        });
        scoreData.totalScore = totalScore;
      });
    },
    updateScoreData(group, index) {
      const scores = group.bigItems[index].scores;
      const scoreItem = scores.find(
        (x) => this.userData.user.employeeId === x.createEmpId
      );
      if (scoreItem.score || scoreItem.score === 0) {
        api.okr.score.max.update(scoreItem).then((res) => {
          this.global.showResponse(this, res, "update");
          // TODO: 待優化
          this.getDataList();
        });
      } else {
        this.$eventBus.$emit("showAlert", "請輸入核心分數！");
      }
    },
    backList() {
      this.$router.push({
        name: this.$route.params.back || "BigScorePersonalList",
        params: {
          type: this.person.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
