var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "OrderManagement" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("訂單管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("訂餐服務管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("訂單管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content order-management" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("用餐日期："),
                ]),
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DatePicker", {
                        model: {
                          value: _vm.eatDay,
                          callback: function ($$v) {
                            _vm.eatDay = $$v
                          },
                          expression: "eatDay",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "selectOffice" },
                  },
                  [_vm._v("辦公室：")]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.office,
                        expression: "office",
                      },
                    ],
                    staticClass: "form-control right",
                    attrs: { id: "selectOffice" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.office = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("請選擇")]),
                    _vm._l(_vm.officeList, function (office, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: office.office } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(office.office) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "inputOrderer" },
                  },
                  [_vm._v("訂購人：")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.employeeName,
                      expression: "employeeName",
                    },
                  ],
                  staticClass: "form-control right",
                  attrs: { id: "inputOrderer", type: "text" },
                  domProps: { value: _vm.employeeName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.employeeName = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("用餐類別："),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "午餐",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.eatTime,
                          callback: function ($$v) {
                            _vm.eatTime = $$v
                          },
                          expression: "eatTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "2",
                          message: "晚餐",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.eatTime,
                          callback: function ($$v) {
                            _vm.eatTime = $$v
                          },
                          expression: "eatTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onSearch()
                      },
                    },
                  },
                  [_vm._v("\n                查詢\n              ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportExcel()
                      },
                    },
                  },
                  [_vm._v("\n                匯出\n              ")]
                ),
              ]),
            ]),
            _c("form", { staticClass: "form-horizontal batchCancelOrder" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("批次取消訂餐："),
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("input", {
                    ref: "batchCancelOrder",
                    attrs: { id: "batchCancelOrder", type: "file" },
                  }),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  staticStyle: { "margin-right": "10px" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.readExcel()
                    },
                  },
                },
                [_vm._v("\n              確定批次取消\n            ")]
              ),
              _vm.showLeavesUpdateBtn || _vm.expatId == "G"
                ? _c(
                    "button",
                    {
                      staticClass: "btn bg-purple",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.leavesCancelUpdate()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.expatId == "G" ? "打卡" : "請假") +
                          "資料轉入\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                _vm._v("資料總筆數：" + _vm._s(_vm.total)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-1000" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "12" },
                            },
                            [_vm._v("查詢中")]
                          ),
                        ])
                      : _vm._l(_vm.queryAll.datas, function (eat, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class: { "text-red": eat.leaveIsDiff == 1 },
                            },
                            [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$utils.formatTime(
                                      eat.eatDay,
                                      "YYYY/MM/DD"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$utils.getWeekDay(eat.eatDay))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(eat.storeName))]),
                              _c("td", [_vm._v(_vm._s(eat.mealName))]),
                              _c(
                                "td",
                                { staticStyle: { "word-break": "keep-all" } },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        eat.eatTime == 1 ? "午餐" : "晚餐"
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _c("td", [_vm._v(_vm._s(eat.price))]),
                              _c("td", [_vm._v(_vm._s(eat.employeeName))]),
                              _c("td", [_vm._v(_vm._s(eat.office))]),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.getOrderStatus(eat)) +
                                    "\n                "
                                ),
                              ]),
                              _c(
                                "td",
                                { class: { "text-blue": eat.isNoPunch == 1 } },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(eat.punchNote) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _c("td", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(eat.leaveNote) +
                                    "\n                "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                eat.manageStatus != "0"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeManageStatus(
                                              eat,
                                              "cancel"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    取消\n                  "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeManageStatus(
                                              eat,
                                              "recover"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    恢復\n                  "
                                        ),
                                      ]
                                    ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeManageStatus(
                                          eat,
                                          "keep"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    保留\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center" },
          [
            _c("Pagination", {
              attrs: { pageLimitCount: _vm.pageSize, total: _vm.total },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("用餐日期")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("星期")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("店家名稱")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("菜單名稱")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("類別")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("售價")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("訂購人")]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("辦公室")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("訂餐狀態")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("打卡狀態")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("請假說明")]),
      _c(
        "th",
        { staticClass: "text-center", staticStyle: { "min-width": "137px" } },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }