var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "OrderMenu" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("選擇餐點")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("訂餐服務")]),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/Order" } }, [
                _vm._v("預定下周餐點"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: `/All/OrderNextWeekMeal/${_vm.expatId}` } },
                [_vm._v("\n          下週表排店家\n        ")]
              ),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("選擇餐點")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "top-box box-header with-border" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.goBack()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n        "),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "box-body" },
            [
              _vm.habits != null
                ? _c("p", [
                    _vm._v(
                      "\n          個人飲食習慣：" +
                        _vm._s(_vm.getMealType(_vm.habits.mealType)) +
                        "\n          "
                    ),
                    _vm.habits.notEat != ""
                      ? _c("span", [_vm._v("(")])
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.habits.notEat) +
                        "\n          "
                    ),
                    _vm.habits.notEat != ""
                      ? _c("span", [_vm._v(")")])
                      : _vm._e(),
                  ])
                : _c("p", [_vm._v("個人飲食習慣：")]),
              _c("p", [
                _vm._v(
                  "\n          用餐日期：" +
                    _vm._s(
                      _vm.formatDateTime(_vm.menuList.eatDay, "YYYY/MM/DD (dd)")
                    ) +
                    "\n          中餐\n        "
                ),
              ]),
              _c("div", { staticClass: "switch-block" }, [
                _c("p", [_vm._v("本日不訂餐：")]),
                _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.menuList.eatStatus,
                        expression: "menuList.eatStatus",
                      },
                    ],
                    attrs: {
                      "false-value": null,
                      id: "check-order",
                      "true-value": 0,
                      name: "ckbx-style-8",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.menuList.eatStatus)
                        ? _vm._i(_vm.menuList.eatStatus, null) > -1
                        : _vm._q(_vm.menuList.eatStatus, 0),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.menuList.eatStatus,
                            $$el = $event.target,
                            $$c = $$el.checked ? 0 : null
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.menuList,
                                  "eatStatus",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.menuList,
                                  "eatStatus",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.menuList, "eatStatus", $$c)
                          }
                        },
                        function ($event) {
                          return _vm.noOrderChange()
                        },
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "check-order" } }),
                ]),
              ]),
              _vm._l(_vm.menuList.stores, function (store) {
                return _c("div", { key: store.storeId, staticClass: "store" }, [
                  _c("h5", { staticClass: "name" }, [
                    _c("i", { staticClass: "fa fa-circle text-yellow" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(store.storeName) +
                        "\n          "
                    ),
                  ]),
                  _c("p", [_vm._v("描述：" + _vm._s(store.storeInfo))]),
                  _c(
                    "ul",
                    { staticClass: "list list-unstyled" },
                    _vm._l(store.meal, function (meal) {
                      return _c("li", { key: meal.mealId }, [
                        _c("div", { staticClass: "list-box" }, [
                          _c(
                            "div",
                            { staticClass: "pic jqimgFill" },
                            _vm._l(meal.files, function (file, index) {
                              return _c("img", {
                                key: index,
                                attrs: {
                                  src:
                                    "https://dev-eip.wanin.tw/" + file.filesUrl,
                                  draggable: "false",
                                  alt: file.name,
                                },
                              })
                            }),
                            0
                          ),
                          _c("p", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(meal.mealName) +
                                "\n                  "
                            ),
                            _vm.expatId === "J" || _vm.expatId === "G"
                              ? _c("span", [
                                  _vm._v("(" + _vm._s(meal.price) + "元)"),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    meal.eatScore != null &&
                                    meal.averageScore != null,
                                  expression:
                                    "meal.eatScore != null && meal.averageScore != null",
                                },
                              ],
                              staticClass: "text-blue",
                              on: {
                                click: function ($event) {
                                  return _vm.openOrderScore(meal)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    meal.eatScore == null
                                      ? "尚未評分"
                                      : meal.eatScore
                                  ) +
                                  " /\n                  " +
                                  _vm._s(
                                    meal.averageScore == null
                                      ? "尚未評分"
                                      : meal.averageScore
                                  ) +
                                  ")"
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "bottom" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: meal.amount != null,
                                    expression: "meal.amount != null",
                                  },
                                ],
                                staticClass: "number",
                              },
                              [
                                _vm._v(
                                  "可訂數量：" +
                                    _vm._s(
                                      Math.max(meal.amount - meal.eatCount, 0)
                                    )
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                class: [
                                  meal.mealId == _vm.menuList.eatMealId
                                    ? "btn btn-default active"
                                    : "btn btn-default",
                                ],
                                attrs: {
                                  disabled:
                                    (meal.eatCount >= meal.amount &&
                                      meal.amount != null) ||
                                    _vm.menuList.eatStatus == 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderDine(meal)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    訂購\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ])
              }),
            ],
            2
          ),
          _c("div", { staticClass: "box-footer" }, [
            _c(
              "div",
              { staticClass: "tabs tabs-order" },
              _vm._l(_vm.weekList, function (item, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    class: [
                      _vm.formatDateTime(item.eatDay, "YYYY/MM/DD") ==
                      _vm.startDay
                        ? "btn btn-warning"
                        : "btn btn-default",
                    ],
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.goDateRefresh(item.eatDay)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.formatDateTime(item.eatDay, "(MM/DD)dd")) +
                        "\n          "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("orderScore", { ref: "orderScore" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }