var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "editorProduct", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.title) + "產品"),
          ]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.product.productName,
                      expression: "product.productName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "inputName",
                    required: "required",
                    type: "text",
                  },
                  domProps: { value: _vm.product.productName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.product, "productName", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 control-label",
                  attrs: { for: "inputDescription" },
                },
                [_vm._v("描述：")]
              ),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.product.productInfo,
                      expression: "product.productInfo",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "inputDescription", type: "text" },
                  domProps: { value: _vm.product.productInfo },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.product, "productInfo", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 control-label",
                  attrs: { for: "inputLink" },
                },
                [_vm._v("連結：")]
              ),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.product.productUrl,
                      expression: "product.productUrl",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "inputLink", type: "text" },
                  domProps: { value: _vm.product.productUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.product, "productUrl", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 control-label",
                  attrs: { for: "inputAndroidLink" },
                },
                [_vm._v("Android：")]
              ),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _vm._l(_vm.product.androidFiles, function (file, index) {
                    return _c("div", { key: index, staticClass: "pic" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: "https://dev-eip.wanin.tw/" + file.filesUrl,
                          width: "100",
                        },
                      }),
                    ])
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { for: "inputFile" },
                    },
                    [
                      _c("input", {
                        ref: "seatPic",
                        staticStyle: { display: "none" },
                        attrs: {
                          accept: ".jpg, .jpeg, .png",
                          id: "inputFile",
                          onclick: "this.value=null;",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeFile($event)
                          },
                        },
                      }),
                      _c("i", { staticClass: "fa fa-picture-o" }),
                      _vm._v(" 更新圖片\n              "),
                    ]
                  ),
                  _vm._v("\n               \n              "),
                  _vm.edit &&
                  _vm.product.androidFiles.length > 0 &&
                  _vm.product.androidFiles[0].applyId != undefined
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteFile(
                                _vm.product.androidFiles[0].applyId,
                                "android"
                              )
                            },
                          },
                        },
                        [_vm._v("刪除圖片")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFile("inputFile")
                            },
                          },
                        },
                        [_vm._v("刪除圖片")]
                      ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.androidUrl,
                        expression: "product.androidUrl",
                      },
                    ],
                    staticClass: "form-control mt-10",
                    attrs: {
                      id: "inputAndroidLink",
                      placeholder: "請輸入連結",
                      type: "text",
                    },
                    domProps: { value: _vm.product.androidUrl },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.product, "androidUrl", $event.target.value)
                      },
                    },
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 control-label",
                  attrs: { for: "inputIosLink" },
                },
                [_vm._v("iOS：")]
              ),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _vm._l(_vm.product.iosFiles, function (file, index) {
                    return _c("div", { key: index, staticClass: "pic" }, [
                      _c("img", {
                        attrs: {
                          draggable: "false",
                          src: "https://dev-eip.wanin.tw/" + file.filesUrl,
                          width: "100",
                        },
                      }),
                    ])
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { for: "inputFile2" },
                    },
                    [
                      _c("input", {
                        ref: "seatPic",
                        staticStyle: { display: "none" },
                        attrs: {
                          accept: ".jpg, .jpeg, .png",
                          id: "inputFile2",
                          onclick: "this.value=null;",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeFile($event)
                          },
                        },
                      }),
                      _c("i", { staticClass: "fa fa-picture-o" }),
                      _vm._v(" 更新圖片\n              "),
                    ]
                  ),
                  _vm._v("\n               \n              "),
                  _vm.edit &&
                  _vm.product.iosFiles.length > 0 &&
                  _vm.product.iosFiles[0].applyId != undefined
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteFile(
                                _vm.product.iosFiles[0].applyId,
                                "ios"
                              )
                            },
                          },
                        },
                        [_vm._v("刪除圖片")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFile("inputFile2")
                            },
                          },
                        },
                        [_vm._v("刪除圖片")]
                      ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.iosUrl,
                        expression: "product.iosUrl",
                      },
                    ],
                    staticClass: "form-control mt-10",
                    attrs: {
                      id: "inputIosLink",
                      placeholder: "請輸入連結",
                      type: "text",
                    },
                    domProps: { value: _vm.product.iosUrl },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.product, "iosUrl", $event.target.value)
                      },
                    },
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 control-label",
                  attrs: { for: "inputOrderNum" },
                },
                [_vm._v("排序：")]
              ),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.product.orderNum,
                      expression: "product.orderNum",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "inputOrderNum", type: "text" },
                  domProps: { value: _vm.product.orderNum },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.product, "orderNum", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.saveProduct },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("關閉")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputName" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 名稱：\n            "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }