<template>
  <!-- 編輯跑馬燈 -->
  <div class="modal fade" ref="marquee_setting" id="marqueeEdit">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">編輯跑馬燈資訊</h4>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMarqueeName">
                <span class="text-red">*</span> 跑馬燈訊息：
              </label>
              <div class="col-sm-9">
                <!-- <input
                  class="form-control"
                  id="inputMarqueeName"
                  required="required"
                  type="text"
                  v-model="edit.note"
                /> -->
                <tinymce v-model="edit.note" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMarqueeStartTime">
                <span class="text-red">*</span> 開始時間：
              </label>
              <div class="col-sm-3">
                <DatePicker
                  v-model="startTime"
                  v-bind:propValue="edit.startTime"
                />
              </div>
              <div class="col-sm-3 time">
                <input
                  class="form-control timepicker"
                  ref="startTimer"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMarqueeEndTime"
                ><span class="text-red">*</span> 結束時間：</label
              >
              <div class="col-sm-3">
                <DatePicker v-model="endTime" v-bind:propValue="edit.endTime" />
              </div>
              <div class="col-sm-3 time">
                <input
                  class="form-control timepicker"
                  ref="endTimer"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMarqueeOrderNum">
                <span class="text-red">*</span> 排序：
              </label>
              <div class="col-sm-1">
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  v-model="edit.orderNum"
                  style="width: 80px; text-align: center"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">停用：</label>
              <div class="col-sm-9">
                <div class="checkbox-inline">
                  <iCheckCheckBox
                    v-bind:propChecked="edit.isStop"
                    v-on:update:checked="edit.isStop = $event['checked']"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" ref="editSaveButton">
            儲存
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
    tinymce,
  },
  data() {
    return {
      editMode: false,
      responseWait: false,
      startTime: "",
      endTime: "",
      edit: {
        marqueeId: utils.getTimeId(),
        note: "",
        orderNum: 1,
        startTime: "",
        endTime: "",
        isStop: 0,
      },
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });

    $(".timepicker").timepicker({
      showInputs: false,
      minuteStep: 1, //Specify a step for the minute field.
    });
  },
  methods: {
    async showMarqueeEditBox(marqueeId) {
      if (marqueeId != undefined && marqueeId != "") {
        this.editMode = true;
        await this.getMarqueeInfo(marqueeId);
        $("#marqueeEdit .modal-title").html("編輯跑馬燈資訊");
      } else {
        this.editMode = false;
        this.edit = {
          marqueeId: utils.getTimeId(),
          note: "",
          orderNum: 0,
          startTime: "",
          endTime: "",
          isStop: 0,
        };
        $("#marqueeEdit .modal-title").html("新增跑馬燈資訊");
      }
      $(this.$refs.marquee_setting).modal("show");
      $(this.$refs.editSaveButton).click(() => {
        if (this.editMode) this.updateMarquee(this.edit);
        else {
          this.addMarquee(this.edit);
        }
      });
    },
    getMarqueeInfo(marqueeId) {
      api.marquee
        .query({
          marqueeId: marqueeId,
        })
        .then((res) => {
          if (res.data.status == "1")
            this.edit = JSON.parse(JSON.stringify(res.data.result));
          $(this.$refs.startTimer).val(
            new Date(this.edit.startTime).toLocaleTimeString("en-US", {
              timeStyle: "short",
            })
          );
          $(this.$refs.endTimer).val(
            new Date(this.edit.endTime).toLocaleTimeString("en-US", {
              timeStyle: "short",
            })
          );
        });
    },
    updateMarquee() {
      if (!this.checkMarqueeInfo()) return;

      if (this.responseWait) return;
      this.responseWait = true;

      api.marquee.update(this.edit).then((res) => {
        this.responseWait = false;
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        } else {
          $(this.$refs.marquee_setting).modal("hide");
        }
        this.$emit("getMarqueeList");
      });
    },
    addMarquee() {
      // 添加店家
      if (!this.checkMarqueeInfo()) return;

      if (this.responseWait) return;
      this.responseWait = true;

      api.marquee.create(this.edit).then((res) => {
        this.responseWait = false;
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        } else {
          $(this.$refs.marquee_setting).modal("hide");
        }
        this.$emit("getMarqueeList");
      });
    },
    checkMarqueeInfo() {
      if (this.edit == null || this.edit == undefined) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return false;
      }

      if (this.edit.note == undefined || this.edit.note == "") {
        this.$eventBus.$emit("showAlert", "跑馬燈訊息不可為空!!");
        return false;
      }

      //時間判斷
      let startTimer = $.trim($(this.$refs.startTimer).val());
      let endTimer = $.trim($(this.$refs.endTimer).val());

      if (startTimer == "") {
        this.$eventBus.$emit("showAlert", "開始時間不可為空!!");
        return false;
      }

      if (endTimer == "") {
        this.$eventBus.$emit("showAlert", "結束時間不可為空!!");
        return false;
      }

      this.edit.startTime =
        utils.formatTime(this.startTime, "YYYY-MM-DD") + " " + startTimer;

      this.edit.endTime =
        utils.formatTime(this.endTime, "YYYY-MM-DD") + " " + endTimer;

      // if (new moment().isAfter(this.edit.startTime)) {
      //   this.$eventBus.$emit("showAlert", "開始時間不可小於現在時間!");
      //   return false;
      // }

      if (new moment(this.edit.startTime).isAfter(this.edit.endTime)) {
        this.$eventBus.$emit("showAlert", "結束時間不可小於開始時間!");
        return false;
      }

      this.edit.orderNum = parseInt(this.edit.orderNum);

      if (this.edit.orderNum == undefined) {
        this.$eventBus.$emit("showAlert", "排序不可為空!!");
        return false;
      }

      if (this.edit.orderNum < 0) {
        this.$eventBus.$emit("showAlert", "排序不可為負值!!");
        return false;
      }

      return true;
    },
  },
};
</script>