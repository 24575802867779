var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "activity_member", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v("承辦人員設定-" + _vm._s(_vm.title)),
          ]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("tbody", [
              _c("tr", [
                _vm._m(1),
                _c(
                  "td",
                  [
                    _c("select2Single", {
                      attrs: {
                        options: _vm.depts,
                        "data-placeholder": "請選擇部門",
                      },
                      model: {
                        value: _vm.deptId,
                        callback: function ($$v) {
                          _vm.deptId = $$v
                        },
                        expression: "deptId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _vm._m(2),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.employee,
                          expression: "employee",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.employee = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v("請選擇人員"),
                      ]),
                      _vm._l(_vm.memberOptions, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item } },
                          [_vm._v(_vm._s(item.employeeName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td"),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { title: "新增人員", type: "button" },
                      on: { click: _vm.addManager },
                    },
                    [_vm._v("\n                  新增人員\n                ")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "table",
            { staticClass: "table table-bordered table-striped mt-10" },
            [
              _c(
                "tbody",
                [
                  _vm._m(3),
                  _vm._l(_vm.activityManagers, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.deptName))]),
                      _c("td", [_vm._v(_vm._s(item.managerEmpId))]),
                      _c("td", [_vm._v(_vm._s(item.managerEmpName))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: { type: "button", title: "刪除" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteManager(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
        _vm._m(4),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "text-nowrap", staticStyle: { width: "10%" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 選擇部門\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 選擇人員"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("部門")]),
      _c("th", [_vm._v("工號")]),
      _c("th", [_vm._v("姓名")]),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("\n          關閉\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }