var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasEdit || _vm.edit
      ? _c("div", { staticClass: "box-footer mt-15" })
      : _vm._e(),
    _vm.hasEdit || _vm.edit
      ? _c(
          "form",
          {
            staticClass: "form-horizontal",
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.saveData()
              },
            },
          },
          [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group mb0" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _vm._v("備註："),
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.data.signNote,
                        expression: "data.signNote",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { rows: "4", type: "text" },
                    domProps: { value: _vm.data.signNote },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.data,
                          "signNote",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer no-border" }, [
              (_vm.edit && !_vm.hasSame) || (_vm.edit && _vm.newJob)
                ? _c("div", { staticClass: "btns" }, [
                    _c("input", {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit", value: "同意" },
                      on: {
                        click: function ($event) {
                          return _vm.updateData(2)
                        },
                      },
                    }),
                    !_vm.newJob
                      ? _c("input", {
                          staticClass: "btn btn-danger",
                          attrs: { type: "submit", value: "不同意" },
                          on: {
                            click: function ($event) {
                              return _vm.updateData(0)
                            },
                          },
                        })
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "btns" }, [
                    !_vm.newJob
                      ? _c("input", {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit", value: "完成送簽" },
                          on: {
                            click: function ($event) {
                              return _vm.updateData(1)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "box-footer mt-10" }),
    _c("form", { staticClass: "form-horizontal" }, [
      _c("div", { staticClass: "box-body" }, [
        _c("div", { staticClass: "form-group mb0" }, [
          _c("label", { staticClass: "col-sm-2 control-label" }, [
            _vm._v("簽核歷程："),
          ]),
          _c("div", { staticClass: "col-sm-8" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass: "table table-hover table-bordered",
                  staticStyle: { margin: "10px auto 20px auto" },
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.logs.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "4" } },
                              [_vm._v("尚無歷程")]
                            ),
                          ])
                        : _vm._l(_vm.logs, function (log, index) {
                            return _c("tr", { key: index }, [
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(log.signEmpName),
                                },
                              }),
                              _c("td", { staticClass: "tc" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        log.signTime,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    ) +
                                    "\n                  "
                                ),
                              ]),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(log.signNote || "-"),
                                },
                              }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.define.logState[index] ||
                                      _vm.define.logState[log.signState] ||
                                      _vm.define.logState[log.stepState]
                                  ),
                                },
                              }),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc mw90" }, [_vm._v("簽核人員")]),
      _c("th", { staticClass: "tc mw150" }, [_vm._v("簽核時間")]),
      _c("th", { staticClass: "tc mw150" }, [_vm._v("簽核意見")]),
      _c("th", { staticClass: "tc mw90" }, [_vm._v("簽核結果")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }