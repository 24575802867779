export default class {
    static companyId = 'A';
    static halfYearStatus = {
        0: '上半年',
        1: '下半年',
    };
    static objectiveType = {
        0: '一般目標',
        1: '新增任務',
    };
    static openType = {
        '0': '人員目標',
        '1': '小考',
        '2': '大考',
    };
    static openStatus = {
        '0': '關閉',
        '1': '開放',
    };
    static openReasonType = {
        '1': '員工目標未填',
        '2': '員工小考未自評',
        '3': '員工大考未自評',
        '4': '主管逾期',
    };
    static sendStatus = {
        '-9': '未填寫',
        '0': '草稿',
        '1': '送簽中',
        '2': '送簽完成',
        '9': '撤銷',
    };
    static signState = {
        'next': '審核',
        'back': '退回',
    };
    static stepState = {
        'start': '編輯',
        'step': '審核',
        'end': '完成',
    };
    static historyState = {
        'back': '退回',
        'start': '審核',
        'step': '審核',
        'end': '完成',
    };
    static logState = {
        '0': '起單',
        'back': '退回',
        'start': '同意',
        'step': '同意',
        'end': '同意(完成)',
    };
    static jobStatus = {
        '1': '不予任用',
        '2': '延長任用',
        '3': '正式任用',
    };
    static deptLevel = {
        '2': '部級主管',
        '3': '一般員工',
        '4': '新進員工',
        '5': '實習生',
    };
    static personalType = {
        'Top': { type: 'Top', api: 'division', text: '高層', level: -3, limit: 50 },
        'Center': { type: 'Center', api: 'division', text: '中心', level: 0, limit: 50 },
        'Division': { type: 'Division', api: 'division', text: '處級', level: 1, limit: 50 },
        'Department': { type: 'Department', api: 'department', text: '部級', level: 2, limit: 60 },
        'Personal': { type: 'Personal', api: 'personal', text: '人員', level: 3, limit: 60 },
        'NewCrew': { type: 'NewCrew', api: 'newCrew', text: '新人', level: 4, limit: 60 },
        'Trainee': { type: 'Trainee', api: 'trainee', text: '實習生', level: 5, limit: 60 },
        'Except': { type: 'Except', api: 'personal', text: '例外', level: 9, limit: 60 },
    };
    static scoreType = {
        s1: { score: '90分以上', text: '超越現職要求' },
        s2: { score: '80分~89分', text: '優於現職要求' },
        s3: { score: '70分~79分', text: '符合現職要求' },
        s4: { score: '60分~69分', text: '需加強要求' },
        s5: { score: '未達60分', text: '不合現職要求' },
    };
    static bigType = {
        '責任感': {
            s1: { score: '18~20', text: '具有積極責任心，能徹底達成任務，可以放心交付工作 (傑出)' },
            s2: { score: '16~18', text: '具有責任心，能順利完成任務，可以交付工作 (優秀)' },
            s3: { score: '14~16', text: '尚有責任心，能如期完成任務 (達到標準)' },
            s4: { score: '12~14', text: '責任心不強，需有人督促，方能完成工作 (尚可)' },
            s5: { score: '10~12', text: '欠缺責任心，時時督促，亦不能如期完成工作 (有待改進)' },
        },
        '溝通協調': {
            s1: { score: '18~20', text: '善於協調，能自動自發與人合作 (傑出)' },
            s2: { score: '16~18', text: '樂意與人協調溝通，順利達成任務 (優秀)' },
            s3: { score: '14~16', text: '能與人合作，達成工作要求 (達到標準)' },
            s4: { score: '12~14', text: '協調能力不佳，致使工作發生困難 (尚可)' },
            s5: { score: '10~12', text: '無法與人協調，致使工作無法進行 (有待改進)' },
        },
        '改革創新': {
            s0: { text: '具革新、研究精神，對工作規劃與執行能改進措施、提昇效率；能主動發掘問題、解決問題，有具體表現' },
            s1: { score: '18~20', text: '很高' },
            s2: { score: '16~18', text: '高' },
            s3: { score: '14~16', text: '尚可' },
            s4: { score: '12~14', text: '差' },
            s5: { score: '10~12', text: '很差' },
        },
        '應變能力': {
            s1: { score: '18~20', text: '遇到僵局或必須取捨的處境中，能夠權衡得失，做出最有利的決策，並且採取積極行動力 (傑出)' },
            s2: { score: '16~18', text: '面臨突發事件或情況時能保持冷靜，並根據事件危急程度，彈性應變迅速採取適當行動，以降低危機可能造成的傷害 (優秀)' },
            s3: { score: '14~16', text: '面對工作內容或環境的改變能夠保持鎮定，嘗試瞭解改變的原因並以開放態度面對，有效調整行為且維持應有的工作表現 (達到標準)' },
            s4: { score: '12~14', text: '對於情勢掌握度不佳，無法即時反應處理，靈活度較低 (尚可)' },
            s5: { score: '10~12', text: '有待改進：無法掌握情勢，並導致整體工作難以順利進行 (有待改進)' },
        },
        '團隊合作': {
            s1: { score: '18~20', text: '能有效界定團隊成員各自扮演的角色，促使團隊成員彼此分享，共同學習成長進而產生高度團隊向心力 (傑出)' },
            s2: { score: '16~18', text: '能激勵團隊成員積極參與、使其充分表達自己的想法與意見，並鼓舞團隊成員士氣共同完成任務 (優秀)' },
            s3: { score: '14~16', text: '能協調他人共同解決團隊面臨的問題或困難，使工作任務得以順利達成 (達到標準)' },
            s4: { score: '12~14', text: '能順從並配合團隊的決議，並依循團隊的共識來執行任務 (尚可)' },
            s5: { score: '10~12', text: '常常堅持個人立場，無法聽取他人建議，使得工作完成度降低，影響團隊效益 (有待改進)' },
        },
    };
}
