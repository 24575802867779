<template>
  <div id="ShopList">
    <section class="content-header">
      <h1>特約商店</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">特約商店</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="add()"
              >
                <i class="fa fa-plus"></i>
                新增
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢特約商店：</label>
                <select
                  class="form-control w90"
                  @change="resetDataList()"
                  v-model="searchData.storeType"
                >
                  <option :value="null">全部分類</option>
                  <option
                    :value="category.classId"
                    v-for="(category, index) in categoryList"
                  >
                    {{ category.className }}
                  </option>
                </select>
                <select
                  class="form-control"
                  @change="resetDataList()"
                  v-model="searchData.companyType"
                >
                  <option :value="null">全部地區</option>
                  <option
                    :value="area.classId"
                    v-for="(area, index) in areaList"
                  >
                    {{ area.className }}
                  </option>
                </select>
                <select
                  class="form-control"
                  @change="resetDataList()"
                  v-model="searchData.order"
                >
                  <option :value="1">依合約期限排序</option>
                  <option :value="2">依評論數排序</option>
                </select>
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="請輸入關鍵字"
                type="text"
                v-model="searchData.storeName"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc" style="min-width: 120px;">圖示</th>
                  <th class="tc" style="min-width: 150px;">商店名稱</th>
                  <th class="tc" style="min-width: 100px;">分店名稱</th>
                  <th class="tc" style="min-width: 100px;">分類</th>
                  <th class="tc" style="min-width: 100px;">使用區域</th>
                  <th class="tc mw150">內容</th>
                  <th class="tc" style="min-width: 100px;">合約期限</th>
                  <th class="tc" style="min-width: 180px;">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-for="(shop, index) in list">
                  <td class="tc">
                    <img :src="getImage(shop.files)" class="logo" alt="">
                  </td>
                  <td class="tc">{{ shop.storeName }}</td>
                  <td class="tc">{{ shop.applyArea }}</td>
                  <td class="tc">{{ shop.storeTypeName }}</td>
                  <td class="tc">{{ shop.companyTypeName }}</td>
                  <td>
                    <div v-html="shop.discountOffers" class="text-ellipsis" />
                  </td>
                  <td class="tc">
                    <span v-if="shop.isNoLimitContract == 1">永久</span>
                    <span v-else>{{ shop.contractTime | dateFormat("YYYY-MM-DD") }}</span>
                  </td>
                  <td class="tc">
                    <button
                      class="btn btn-primary mr-10"
                      @click="editItem(shop)"
                    >
                      修改
                    </button>   
                    <button
                      class="btn btn-danger"
                      @click="deleteItem(shop)"
                    >
                      刪除
                    </button>                 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        storeName: "",
        // isNoLimitContract: 0,
        storeType: null,
        companyType: null,
        storeStatus: 1,
        isDel: 0,
        isFrontend: 1,
        order: 1, // 排序 1:依照合約日期大到小排序、2:依評論數大到小排序 不帶會預設1
        pageNum: 1,
        pageSize: 10
      },
      list: [],
      total: 0,
      areaList: [],
      categoryList: []
    }
  },
  mounted() {
    if(this.$route.params.searchData) {
      this.searchData = this.$route.params.searchData;
    }
    this.getClassList('特約商店地區');
    this.getClassList('特約商店類別');
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      api.shop.admin.queryAll(this.searchData)
        .then((res) => {
          this.list = res .data.result.datas;
          this.total = res .data.result.total;
          this.loading = false;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getClassList(type) {
      api.shopClass.queryAll({
        className: "", // 類別名稱
        module: type, // 類別模組 ex: 特約商店地區、特約商店類別
        order: 2,
        pageNum: 1,
        pageSize: 999
      })
        .then((res) => {
          if(type == '特約商店地區') this.areaList = res.data.result.datas;
          if(type == '特約商店類別') this.categoryList = res.data.result.datas;
        })
    },
    getImage(files) {
      const Index = files.findIndex(item => this.global.isImage(item.mine));
      return Index > -1 ? process.env.VUE_APP_EIPIMG_PATH + files[Index].filesUrl : ''
    },
    getClassName(id, list) {
      const Index = list.findIndex(item => item.classId == id)
      return Index > -1 ? list[Index].className : ''
    },
    add() {
      this.$router.push({
        name: 'ShopEdit',
        params: {
          searchData: this.searchData,
        }
      })
    },
    editItem(data) {
      this.$router.push({
        name: 'ShopEdit',
        params: {
          id: data.storeId,
          searchData: this.searchData,
        }
      })
    },
    deleteItem(data) {
      this.$eventBus.$emit("showConfirm", `確定要刪除嗎?`, (isOK) => {
        if (isOK) {
          data.isDel = 1;
          api.shop.update(data)
            .then((res) => {
              this.global.showResponse(this, res, 'delete', this.resetDataList)
            })
        }
      })
    },
    showDetail(item) {
      this.$router.push({
        name: 'ShopDetail',
        params: {
          id: item.storeId,
          Status: item.storeStatus,
          searchData: this.searchData,
        }
      })
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
}
</script>
<style scoped lang="scss">
#ShopList {
  .logo {
    min-width: 80px;
    height: 80px;
  }

  .text-ellipsis {
    display: -webkit-box;
    height: 42px;
    margin: 0;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    overflow: hidden;
  }
}
</style>