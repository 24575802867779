<template>
  <!--新增(修改)會議室-->
  <div class="modal fade" ref="edit_room" id="meetingRoomEdit">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增(修改)會議室</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <form>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td>會議室名稱</td>
                    <td>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                        v-model="edit.roomName"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>設備</td>
                    <td>
                      <div class="form-group">
                        <iCheckMultiCheckBox
                          :propOptions="tools"
                          :propSelecteds.sync="edit.toolsContainAry"
                        />
                      </div>
                      <p class="text-orange">
                        <i class="fa fa-warning"></i>
                        如需新增設備請洽系統開發人員。
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>座位(人)</td>
                    <td>
                      <div class="form-group">
                        <!-- has-error -->
                        <input
                          class="form-control"
                          max="100"
                          min="1"
                          required="required"
                          type="number"
                          v-model="edit.seatCount"
                        />
                        <span class="help-block">不得為空</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>停用</td>
                    <td>
                      <div class="form-group checkbox-inline">
                        <!-- has-error -->
                        <iCheckCheckBox
                          v-bind:propChecked="edit.isStop"
                          v-on:update:checked="
                            edit.isStop = $event['checked']
                          "
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            type="button"
            @click="editMeetingRoom()"
            id="submit"
          >
            確定新增(修改)
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            取消
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import iCheckMultiCheckBox from "@/components/iCheckMultiCheckBox";
import iCheckCheckBox from "@/components/iCheck_CheckBox";

export default {
  components: {
    iCheckMultiCheckBox,
    iCheckCheckBox
  },
  data() {
    return {
      tools: ["投影機", "主機(內網)", "主機(外網)", "白板"],
      edit: {}
    };
  },
  created() {},
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue"
    });
  },
  methods: {
    showMeetingRoomEdit(room) {
      if (room != null && room != undefined) {
        this.edit = JSON.parse(JSON.stringify(room));
        $("#meetingRoomEdit #submit").html("確定修改");
        $("#meetingRoomEdit .modal-title").html("修改會議室");
      } else {
        this.edit = {
          roomId: "",
          roomName: "",
          seatCount: 1,
          tools: "",
          toolsContainAry: [],
          isStop: 0
        };
        $("#meetingRoomEdit #submit").html("確定新增");
        $("#meetingRoomEdit .modal-title").html("新增會議室");
      }

      if (this.edit != null && this.edit.tools != "") {
        this.edit.toolsContainAry = this.edit.tools.split(",");
      } else {
        this.edit.toolsContainAry = [];
      }

      $(this.$refs.edit_room).modal("show");
    },
    async editMeetingRoom() {
      if (this.edit.roomName == "") {
        this.$eventBus.$emit("showAlert", "會議室名稱不可為空!!");
        return;
      }

      if (this.edit.toolsContainAry != "")
        this.edit.tools = this.edit.toolsContainAry.join();
      else this.edit.tools = "";

      if (this.edit.roomId != "") {
        await api.meetingRoom.update(this.edit).then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
            return;
          }
        });
      } else {
        await api.meetingRoom.create(this.edit).then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
            return;
          }
        });
      }

      this.$emit("refreshList");
      $(this.$refs.edit_room).modal("hide");
    }
  }
};
</script>
