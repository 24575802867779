<template>
  <div class="modal fade" ref="deptContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ this.isEdit ? "編輯" : "新增" }}目標</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label w90">
                      <span class="text-red">*</span>
                      部別
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        :disabled="(single && exist) || data.objectiveStatus"
                        v-model="data.deptId"
                      >
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      年度
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.year"
                      >
                        <option
                          v-for="(year, index) in years"
                          :key="index"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.month"
                      >
                        <option value="0">上半年</option>
                        <option value="1">下半年</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      目標種類
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        disabled
                        v-model="data.objectiveType"
                      >
                        <option value="0">一般目標</option>
                        <option value="1">新增任務</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label"></td>
                    <td class="tr" colspan="2">
                      <label
                        class="checkbox checkbox-inline fw-b"
                        style="color: cornflowerblue"
                      >
                        <iCheckCheckBox
                          propValue="1"
                          :propChecked="data.checked"
                          v-on:update:checked="checkChange"
                        />&nbsp;設定為部屬方向
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      部級目標
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        class="form-control"
                        required
                        :disabled="!exist && data.objectiveStatus"
                        v-model.trim="data.objective"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      目標說明
                    </td>
                    <td colspan="2">
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                        :disabled="!exist && data.objectiveStatus"
                        v-model.trim="data.note"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">儲存</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      disabled: false,
      isEdit: false,
      exist: false,
      single: false,
      years: this.global.getYearList(),
      list: [],
      data: {},
    };
  },
  methods: {
    async showContent(data, addData) {
      this.disabled = addData.month != -1 ? true : false;
      this.isEdit = data ? true : false;
      this.data = data ? data : addData;
      this.data = JSON.parse(JSON.stringify(this.data));
      this.data.isDeptFollow = this.isEdit ? this.data.isDeptFollow : 1;
      this.getList(addData);
      this.updateData();
      $(this.$refs.deptContent).modal("show");
    },
    getList(addData) {
      this.exist = addData.permission.deptIds.includes(this.data.deptId);
      if ((this.exist || !this.isEdit) && !addData.hasHR) {
        if (addData.permission.deptIds.length === 1) this.single = true;
        this.list = addData.permission.deptList;
      } else {
        api.okr.goal.department.list({ deptLevel: 2 }).then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
          }
        });
      }
    },
    updateData() {
      this.data.checked = this.data.isDeptFollow ? 1 : 0;
      setTimeout(() => {
        this.data.checked = this.data.isDeptFollow ? true : false;
        this.$forceUpdate();
      }, 300);
    },
    checkChange(data) {
      this.data.isDeptFollow = data.checked;
    },
    submit() {
      const action = this.isEdit ? "update" : "create";
      if (this.data.objectiveType && !this.isEdit) {
        api.okr.goal.newCreate(this.data).then((res) => {
          this.global.showResponse(this, res, action);
          $(this.$refs.deptContent).modal("hide");
          this.$emit("updateList");
        });
      } else {
        api.okr.goal.department[action](this.data).then((res) => {
          this.global.showResponse(this, res, action);
          $(this.$refs.deptContent).modal("hide");
          if (this.$route.params.id) {
            this.$emit("updateList");
          } else {
            this.data.id = this.data.deptId;
            this.data.parentObjectiveId = "";
            this.data.objective = "";
            this.$router.push({
              name: "DeptEdit",
              params: this.data,
            });
          }
        });
      }
    },
  },
};
</script>
