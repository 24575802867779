<template>
  <div id="ScorePersonalEdit">
    <section class="content-header">
      <h1>新進員工轉正表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link :to="`/OKR/NewJobList/${person.type}`">
            新進員工轉正表
          </router-link>
        </li>
        <li class="active">
          {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}表單
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}表單
            </h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <div class="tc" v-if="data.message">
            {{ data.message }}
          </div>
          <div class="tc" v-else-if="!data.year">查詢中</div>
          <div v-else>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td>
                      <table class="table no-border" style="width: 400px">
                        <tbody>
                          <tr>
                            <th class="tr w90">員工編號：</th>
                            <td v-text="data.employeeId"></td>
                            <th class="tr">部別：</th>
                            <td v-text="data.deptName"></td>
                          </tr>
                          <tr>
                            <th class="tr">員工姓名：</th>
                            <td v-text="data.employeeName"></td>
                            <th class="tr">狀態：</th>
                            <td
                              v-text="define.sendStatus[data.scoreStatus]"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
              v-if="titles.length > 0"
            >
              <table
                class="table table-hover table-bordered min-800"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc">#</th>
                    <th class="tc mw50">年份</th>
                    <th class="tc mw50">月份</th>
                    <th class="tc mw90">目標種類</th>
                    <th class="tc mw150">部級目標</th>
                    <th class="tc mw150">人員目標</th>
                    <template v-for="(title, index) in titles">
                      <th
                        class="tc mw90 pre-wrap"
                        :key="index"
                        v-text="
                          `${title.employeeName}\n${
                            index === 0
                              ? '（自評）'
                              : title.isManager === '0' &&
                                title.deptType.includes('0060')
                              ? '（上層主管）'
                              : title.isManager === '1' &&
                                title.deptType.includes('0060')
                              ? '（部門主管）'
                              : '（高層主管）'
                          }`
                        "
                      ></th>
                      <th
                        class="tc mw90 pre-wrap"
                        :key="title.employeeId"
                        v-text="
                          `${
                            data.employeeId === title.employeeId
                              ? '說明'
                              : '考核意見'
                          }`
                        "
                      ></th>
                    </template>
                    <th class="tc mw90">不列入考核</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" :colspan="8 + 2 * titles.length">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td class="tc" :colspan="8 + 2 * titles.length">
                      尚無設定目標
                    </td>
                  </tr>
                  <tr v-else v-for="(data, index) in datas" :key="index">
                    <td class="tc" v-text="index + 1"></td>
                    <td class="tc" v-text="data.year"></td>
                    <td class="tc" v-text="data.month"></td>
                    <td
                      class="tc"
                      v-text="define.objectiveType[data.objectiveType]"
                    ></td>
                    <td class="pre-wrap" v-text="data.parentObjective"></td>
                    <td class="pre-wrap" v-text="data.objective"></td>
                    <template v-for="(scoreItem, index) in data.scores">
                      <td
                        class="tc"
                        :key="index"
                        v-text="scoreItem.score"
                      ></td>
                      <td
                        class="pre-wrap"
                        :class="[
                          scoreItem.note && scoreItem.note.length >= 300
                            ? 'mw300'
                            : 'mw90',
                        ]"
                        :key="scoreItem.scoreId"
                        v-text="scoreItem.note"
                      ></td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="table-responsive mt-10"
              style="max-width: 1400px; margin: auto"
            >
              <table
                class="table table-bordered min-800"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc mw50">年份</th>
                    <th class="tc mw50">月份</th>
                    <th class="tc mw150" style="width: 180px">評分人員</th>
                    <th class="tc mw150" style="width: 150px">績效分數</th>
                    <th
                      class="tc"
                      :class="userData.user.employeeId === id ? 'wp40' : 'wp25'"
                    >
                      說明
                    </th>
                    <th
                      class="tc wp25"
                      v-if="userData.user.employeeId !== id"
                    >
                      給主管說明
                    </th>
                    <th class="tc mw300">附件</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" colspan="6">查詢中</td>
                  </tr>
                  <tr v-else-if="scoreDataList.length === 0">
                    <td class="tc" colspan="6">尚無評分人員</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(scoreData, index) in scoreDataList"
                    :key="index"
                  >
                    <td class="tc">{{ scoreData.year }}</td>
                    <td class="tc">{{ scoreData.month }}</td>
                    <td class="tc" v-text="scoreData.createEmpName"></td>
                    <td
                      class="tc fw-b fz25"
                      v-text="scoreData.totalScore"
                    ></td>
                    <td class="pre-wrap" v-text="scoreData.note"></td>
                    <td
                      class="pre-wrap"
                      v-if="userData.user.employeeId !== id"
                      v-text="scoreData.privateNote"
                    ></td>
                    <td class="tc">
                      <table class="table no-border">
                        <tbody>
                          <tr
                            v-if="
                              userData.user.employeeId !==
                                  scoreData.createEmpId &&
                              scoreData.files &&
                              scoreData.files.length === 0
                            "
                          >
                            <td class="tc">－</td>
                          </tr>
                          <tr
                            v-else
                            v-for="(file, index) in scoreData.files"
                            :key="index"
                          >
                            <td class="tr">
                              <a target="_blank" :href="file.filesUrl">
                                {{ file.name }}
                              </a>
                            </td>
                            <td
                              class="w90"
                              v-if="
                                userData.user.employeeId ===
                                    scoreData.createEmpId
                              "
                            >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>



            <div class="box-footer no-border mt-10"></div>
            <div
              class="table-responsive"
              style="max-width: 1400px; margin: auto"
            >
              <table
                class="table table-bordered min-800"
                style="margin: 5px auto"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc mw150" style="width: 180px">評定人員</th>
                    <th class="tc mw150" style="width: 150px" v-if="hasView">
                      評定結果
                    </th>
                    <th class="tc wp40">說明</th>
                    <th class="tc mw300">附件</th>
                    <th class="tc mw80" v-if="hasEdit">管理</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" colspan="5">查詢中</td>
                  </tr>
                  <tr v-else-if="scoreDatas.length === 0">
                    <td class="tc" colspan="5">尚無評定人員</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(scoreData, index) in scoreDatas"
                    :key="index"
                  >
                    <td class="tc" v-text="scoreData.createEmpName"></td>
                    <td
                      class="tc"
                      v-if="
                        hasExcept ||
                        (hasEdit &&
                          userData.user.employeeId === scoreData.createEmpId)
                      "
                    >
                      <select
                        style="height: auto"
                        class="form-control fz18"
                        v-model.number="scoreData.totalScore"
                      >
                        <option value="1">不予任用</option>
                        <option value="2">延長任用</option>
                        <option value="3">正式任用</option>
                      </select>
                    </td>
                    <td
                      class="tc fz18"
                      v-else-if="hasView"
                      v-text="define.jobStatus[scoreData.totalScore]"
                    ></td>
                    <td
                      class="tl"
                      v-if="
                        hasExcept ||
                        (hasEdit &&
                          userData.user.employeeId === scoreData.createEmpId)
                      "
                    >
                      <div v-show="scoreData.totalScore === 2">
                        <div>延長試用時間至：</div>
                        <input
                          type="text"
                          class="form-control"
                          v-model.trim="scoreData.privateNote"
                        />
                      </div>
                      <div>
                        <div v-show="scoreData.totalScore === 2">說明：</div>
                        <textarea
                          rows="4"
                          type="text"
                          class="form-control"
                          v-model.trim="scoreData.note"
                        ></textarea>
                      </div>
                    </td>
                    <td v-else>
                      <div v-show="scoreData.totalScore === 2">
                        延長試用時間至：{{ scoreData.privateNote }}
                      </div>
                      <div class="pre-wrap" v-text="scoreData.note"></div>
                    </td>
                    <td class="tc">
                      <div
                        class="text-red"
                        style="margin-bottom: 10px;"
                        v-if="
                        scoreData.totalScore != 3 &&
                          (hasExcept ||
                          (hasEdit &&
                            userData.user.employeeId === scoreData.createEmpId))
                        "
                      >
                        若需要延長考核，請檢附雙方簽名同意之「面談紀錄表」PDF檔，紙本再繳回HR歸檔
                      </div>
                      <label
                        class="btn btn-success"
                        v-if="
                          hasExcept ||
                          (hasEdit &&
                            userData.user.employeeId === scoreData.createEmpId)
                        "
                      >
                        <input
                          id="inputFile"
                          type="file"
                          accept="*"
                          style="display: none"
                          @change="changeFile($event, scoreData)"
                        />
                        <i class="fa fa-upload"></i> 上傳檔案
                      </label>
                      <table class="table no-border">
                        <tbody>
                          <tr
                            v-if="
                              !hasExcept &&
                              (!hasEdit ||
                                userData.user.employeeId !==
                                  scoreData.createEmpId) &&
                              scoreData.files &&
                              scoreData.files.length === 0
                            "
                          >
                            <td class="tc">－</td>
                          </tr>
                          <tr
                            v-else
                            v-for="(file, index) in scoreData.files"
                            :key="index"
                          >
                            <td class="tr">
                              <a target="_blank" :href="file.filesUrl">
                                {{ file.name }}
                              </a>
                            </td>
                            <td
                              class="w90"
                              v-if="
                                hasExcept ||
                                (hasEdit &&
                                  userData.user.employeeId ===
                                    scoreData.createEmpId)
                              "
                            >
                              <button
                                class="btn btn-danger"
                                @click="deleteFile(file.applyId, scoreData)"
                              >
                                刪除
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td class="tc" v-if="hasExcept || hasEdit">
                      <button
                        class="btn btn-primary"
                        v-if="
                          hasExcept ||
                          userData.user.employeeId === scoreData.createEmpId
                        "
                        @click="updateScoreData(scoreData)"
                      >
                        儲存
                      </button>
                      <span v-else>－</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="person.level !== 9">
              <SignLog
                :datas="datas"
                :data="data"
                :hasEdit="hasEdit"
                :backList="backList"
                :scoreDatas="scoreDatas"
                :newJob="true"
              />
            </div>
          </div>
          <div
            class="box-footer no-border mt-10"
            v-if="datas.length === 0"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SignLog from "@/components/SignLog";
import ScroeTable from "./NewJobScoreTable.vue";

import api from "@/assets/js/app-api";

export default {
  components: {
    SignLog,
    ScroeTable
  },
  data() {
    return {
      hasAll: false,
      hasEdit: false,
      hasView: false,
      hasHR: false,
      hasExcept: false,
      loading: true,
      total: 0,
      addData: {
        type: this.$route.params.type,
        employeeId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.deptId,
        deptLevel: this.$route.params.deptLevel,
        empList: this.$route.params.empList,
        adminAdd: this.$route.params.adminAdd || 0,
        formId: this.$route.params.formId,
        scoreGroupId: this.$route.params.formId,
        back: this.$route.params.back,
        searchData: this.$route.params.searchData,
      },
      view: this.$route.params.back === "SignHistory",
      person: this.global.getPerson(this.$route.params.type),
      id: this.$route.params.id,
      userData: {},
      permission: {},
      scoreDatas: [],
      scoreData: {},
      titles: [],
      datas: [],
      data: {},
      minScoreList: [],
      formIdData: {
        pageSize: 10,
        pageNum: 1,
        year: this.$route.params.year || this.global.getYear(),
        month: this.$route.params.month,
        deptLevel: 4,
        deptId: "",
        employeeId: "",
        status: -1,//-1:不帶條件 0:草稿 1:送簽 2:送簽完成 -9:未填寫
      },
      scoreDataList: [],
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasAll = !this.view;
    this.hasEdit = this.hasAll;
    this.getDataList();
    // this.getFormId();
  },
  methods: {
    getFormId() {
      this.loading = true;
      let month = this.formIdData.month - 1
      this.formIdData.employeeId = this.data.employeeId
      this.formIdData.deptId = this.data.deptId
      this.formIdData.year = month <= 0 ? Number(this.formIdData.year) - 1 : this.formIdData.year
      this.formIdData.month = month <= 0 ? 12 : month
      api.okr.score.min.groupQueryAll(this.formIdData).then(({data}) => {
        if(data.result.total > 0) {
          let num = ""
          num = data.result.datas.findIndex(item => item.employeeId == this.data.employeeId)
          if(num > -1) this.getScoreData(data.result.datas[num]); 
          else this.loading = false;
        } else {
          this.loading = false;
        }
      })
    },
    getScoreData(data) {
      let searchData = {
        deptLevel: this.formIdData.deptLevel,
        year: this.formIdData.year,
        month: this.formIdData.month,
        deptId: this.formIdData.deptId,
        employeeId: this.addData.employeeId,
        adminAdd: this.formIdData.adminAdd,
        formId: data.formId,
        scoreGroupId: data.formId,
      }
      api.okr.score.min.queryAll(searchData)
        .then((res) => {
          if(this.datas.length <= 0) {
            this.titles = res.data.result.empList || [];
            this.datas = res.data.result.scoreList || [];
          } else {
            this.datas = this.datas.concat(res.data.result.scoreList);
          }
          this.getScoreDataList(data.formId)
        })
    },
    // 評分說明列表
    getScoreDataList(data) {

      api.okr.score.min
      .monthQueryAll({
        scoreGroupId: data,
        monthStatus: 1,
      })
      .then((res) => {
        this.scoreDataList = this.scoreDataList.concat(res.data.result.datas);
        this.getFormId();
      })
    },
    getDataList() {
      if (this.id) {
        api.okr.score.job.query(this.addData).then((res) => {
          this.data = res.data;
          if (res.data.status === "1") {
            if (res.data.result) {
              this.data = res.data.result.group || {};
              this.scoreDatas = res.data.result.scoreList || [];
              this.hasHR = this.permission.isHR && this.data.deptId !== "132";
              this.hasExcept = this.permission.isHR && this.person.level === 9;
              this.hasEdit =
                this.hasEdit &&
                (this.$route.params.back === "Sign" ||
                  this.hasExcept ||
                  (this.data.scoreStatus <= 0 &&
                    this.userData.user.employeeId === this.data.employeeId));
              this.hasView =
                this.view ||
                this.hasEdit ||
                this.hasHR ||
                this.permission.hasOver ||
                this.userData.user.employeeId === this.data.employeeId ||
                (this.permission.deptIds.includes(this.data.deptId) &&
                  this.userData.user.employeeId ===
                    this.userData.user.deptManagerId);
              this.updateDataList();
              this.getFormId();
            }
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            this.backList();
          }
        });
      } else {
        this.loading = false;
      }
    },
    updateDataList() {
      this.updateViewData();
      this.autoSendData();
      this.loading = false;
    },
    updateViewData() {
      if (
        this.person.level === 4 &&
        this.userData.user.employeeId === this.data.employeeId
      ) {
        if (this.scoreDatas.length) {
          this.scoreDatas = [this.scoreDatas[0]];
        }
      }
      if (!this.hasView) {
        if (this.titles.length) {
          this.titles = [this.titles[0]];
        }
        if (this.scoreDatas.length) {
          this.scoreDatas = [this.scoreDatas[0]];
        }
      }
    },
    updateScoreData(scoreData) {
      if(scoreData.totalScore == 2 && !scoreData.privateNote) {
        this.$eventBus.$emit("showAlert", "請輸入延長時間");
        return;
      }
      if(!scoreData.note) {
        this.$eventBus.$emit("showAlert", "請輸入說明");
        return;
      } 
      if (scoreData.totalScore) {
        api.okr.score.job.update(scoreData).then((res) => {
          this.global.showResponse(this, res, "update");
        });
      } else {
        this.$eventBus.$emit("showAlert", "請選擇評定結果！");
      }
    },
    autoSendData() {
      if (this.data.scoreStatus === 0) {
        this.data.signStatus = 1;
        this.data.signGetId = this.$route.params.formId;
        api.okr.mgmt.sign.send(this.data).then((res) => {
          this.global.showResponse(this, res, "create", this.backList);
        });
      }
    },
    backList() {
      this.$router.push({
        name: this.$route.params.back || "NewJobList",
        params: {
          type: this.person.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
    // 上傳檔案
    changeFile(e, scoreData) {
      this.scoreData = scoreData;
      this.scoreData.files = this.scoreData.files || [];
      var files = e.target.files;
      if (files.length + this.scoreData.files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您檔案數量超過上限5個，請重新選擇。"
        );
        this.removeFile();
        return;
      } else if (files.length > 0) {
        var isCheckType = false; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (fileSize > 20000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 20 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }
      this.uploadFile(
        files[0],
        "OKRScoreMonth",
        this.scoreData.scoreMonthId,
        true
      );
    },
    removeFile() {
      document.getElementById("inputFile").value = "";
    },
    // 檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      this.scoreData.id = response_upload.data.result.id;
      this.refreshFileList();
    },
    // 刪除檔案
    deleteFile(applyId, scoreData) {
      this.scoreData = scoreData;
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.file
            .deleteApply({
              id: applyId,
            })
            .then((res) => {
              this.global.showResponse(
                this,
                res,
                "delete",
                this.refreshFileList
              );
            });
        }
      });
    },
    // 檔案列表刷新
    async refreshFileList() {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.scoreData.scoreMonthId,
        module: "OKRScoreMonth",
      });
      var response_queryall = await task_queryall;
      this.scoreData.files = response_queryall.data.result;
      this.$forceUpdate();
    },
  },
};
</script>
