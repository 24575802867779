<template>
  <div id="CourseQuizPreview">
    <section class="content-header">
      <h1>{{ datas.testName }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">測驗預覽</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">測驗預覽</h4>
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回
            </button>
          </div>
        </div>
        <quiz :isPreview="true" :datas="datas" />
      </div>
    </section>
  </div>
</template>

<script>
import quiz from "@/components/CourseQuiz";
import iCheckRedioButton from "@/components/iCheckRedioButton";

export default {
  components: {
    quiz,
    iCheckRedioButton,
  },
  data() {
    return {
      datas: {},
    };
  },
  mounted() {
    const previewDatas = JSON.parse(localStorage.getItem("previewDatas"));
    if (previewDatas && this.$route.params.id == previewDatas.courseId) {
      this.datas = previewDatas;
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: "ETCourseQuizEdit",
        params: {
          id: this.datas.courseId,
          name: this.datas.courseName,
          searchData: this.$route.params.searchData,
          datas: this.datas,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#CourseQuizPreview {
  .total-score {
    display: inline-block;
    font-size: 18px;
    padding: 14px 8px;
    margin-right: 10px;
    border: 1px solid;
  }
  .form-group {
    .question {
      .tilte {
        font-size: 16px;
      }
      .score {
        color: #bfbfbf !important;
      }
    }
    .answer {
      margin-bottom: 25px;
    }
  }
}
</style>