<template>
  <!-- 新增(修改)產品 -->
  <div class="modal fade" ref="editorProduct">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{title}}產品</h4>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputName">
                <span class="text-red">*</span> 名稱：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputName"
                  required="required"
                  type="text"
                  v-model="product.productName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputDescription">描述：</label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputDescription"
                  type="text"
                  v-model="product.productInfo"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputLink">連結：</label>
              <div class="col-sm-9">
                <input class="form-control" id="inputLink" type="text" v-model="product.productUrl" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputAndroidLink">Android：</label>
              <div class="col-sm-9">
                <div class="pic" :key="index" v-for="(file, index) in product.androidFiles">
                  <img draggable="false" :src="'https://dev-eip.wanin.tw/' + file.filesUrl" width="100" />
                </div>
                <label class="btn btn-primary" for="inputFile">
                  <input
                    @change="changeFile($event)"
                    accept=".jpg, .jpeg, .png"
                    id="inputFile"
                    onclick="this.value=null;"
                    ref="seatPic"
                    style="display:none;"
                    type="file"
                  />
                  <i class="fa fa-picture-o"></i> 更新圖片
                </label>
                &nbsp;
                <button
                  class="btn btn-danger"
                  type="button"
                  v-if="edit && product.androidFiles.length > 0 && product.androidFiles[0].applyId != undefined"
                  @click="deleteFile(product.androidFiles[0].applyId, 'android')"
                >刪除圖片</button>
                <button
                  @click="removeFile('inputFile')"
                  class="btn btn-danger"
                  type="button"
                  v-else
                >刪除圖片</button>
                <input
                  class="form-control mt-10"
                  id="inputAndroidLink"
                  placeholder="請輸入連結"
                  type="text"
                  v-model="product.androidUrl"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputIosLink">iOS：</label>
              <div class="col-sm-9">
                <div class="pic" :key="index" v-for="(file, index) in product.iosFiles">
                  <img draggable="false" :src="'https://dev-eip.wanin.tw/' + file.filesUrl" width="100" />
                </div>
                <label class="btn btn-primary" for="inputFile2">
                  <input
                    @change="changeFile($event)"
                    accept=".jpg, .jpeg, .png"
                    id="inputFile2"
                    onclick="this.value=null;"
                    ref="seatPic"
                    style="display:none;"
                    type="file"
                  />
                  <i class="fa fa-picture-o"></i> 更新圖片
                </label>
                &nbsp;
                <button
                  class="btn btn-danger"
                  type="button"
                  v-if="edit && product.iosFiles.length > 0 && product.iosFiles[0].applyId != undefined"
                  @click="deleteFile(product.iosFiles[0].applyId, 'ios')"
                >刪除圖片</button>
                <button
                  @click="removeFile('inputFile2')"
                  class="btn btn-danger"
                  type="button"
                  v-else
                >刪除圖片</button>
                <input
                  class="form-control mt-10"
                  id="inputIosLink"
                  placeholder="請輸入連結"
                  type="text"
                  v-model="product.iosUrl"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputOrderNum">排序：</label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputOrderNum"
                  type="text"
                  v-model="product.orderNum"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" v-on:click="saveProduct">{{title}}</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";

export default {
  data() {
    return {
      product: {},
      title: "",
      edit: false
    };
  },
  created() {},
  methods: {
    async openProductEdit(product) {
      if (product != "") {
        this.edit = true;
        this.title = "修改";
        this.product = JSON.parse(JSON.stringify(product));
      } else {
        this.edit = false;
        this.title = "新增";
        this.product = {
          productId: utils.getTimeId(),
          productName: "",
          productInfo: "",
          productUrl: "",
          androidFiles: [],
          androidUrl: "",
          iosFiles: [],
          iosUrl: "",
          orderNum: 0,
          status: 1
        };
      }

      $(this.$refs.editorProduct).modal("show");
    },
    async saveProduct() {
      if (this.product.productName == "") {
        this.$eventBus.$emit("showAlert", "產品名稱不可為空!!");
        return;
      }

      let androidPic = document.getElementById("inputFile");
      let iosPic = document.getElementById("inputFile2");

      if (androidPic.files.length >= 1) {
        //檔案上傳
        await this.uploadFile(
          androidPic.files[0],
          "ProductAndroid",
          this.product.productId
        );
      }

      if (iosPic.files.length >= 1) {
        //檔案上傳
        await this.uploadFile(
          iosPic.files[0],
          "ProductIOS",
          this.product.productId
        );
      }

      if (this.edit) {
        await api.product.update(this.product).then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
            return;
          } else {
            $(this.$refs.editorProduct).modal("hide");
            this.refreshList();
          }
        });
      } else {
        await api.product.create(this.product).then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
            return;
          } else {
            $(this.$refs.editorProduct).modal("hide");
            this.refreshList();
          }
        });
      }
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    },
    changeFile(e) {
      var files = e.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(PNG|JPG|JPEG|GIF)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName.toUpperCase())) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          e.target.value = "";
          return;
        }

        if (fileSize > 2000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          e.target.value = "";
          return;
        }
      }

      this.preview(files, e.target.id);

      // //檔案上傳
      // this.uploadFile(files[0], "MealList", this.edit.mealId);
    },
    //圖片預覽
    preview(input, id) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          if (id == "inputFile") {
            that.product.androidFiles = [];
            that.product.androidFiles.push({
              filesUrl: e.target.result
            });
          }
          if (id == "inputFile2") {
            that.product.iosFiles = [];
            that.product.iosFiles.push({
              filesUrl: e.target.result
            });
          }
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi); //多圖片 true

      api.file.upload(formdata);
    },

    //*---- 刪除檔案
    deleteFile(id, type) {
      let that = this;
      if (that.edit && id != undefined && id != "") {
        this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
          if (isOK) {
            api.file.deleteApply({ id: id }).then(res => {
              if (res.data.status == "1") {
                if (type == "ios") that.product.iosFiles = [];
                else if (type == "android") that.product.androidFiles = [];
                this.$eventBus.$emit("showAlert", "刪除成功!");
                that.refreshList();
              } else {
                this.$eventBus.$emit("showAlert", "刪除失敗!");
              }
            });
          }
        });
      }
    },
    removeFile(id) {
      if (id == "inputFile") {
        this.product.androidFiles = [];
      }
      if (id == "inputFile2") {
        this.product.iosFiles = [];
      }
      $("#" + id).val("");
    }
  }
};
</script>