var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.announcementtypes.typeName))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公司公告")]),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.announcementtypes.typeName)),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯公告"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.docNo,
                          expression: "edit.docNo",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputDocNum",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.docNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "docNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.issuedCompanyId,
                            expression: "edit.issuedCompanyId",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "issuedCompanyId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.selectCompany()
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇公司")]
                        ),
                        _vm._l(_vm.companyOptions, function (company, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: company.companyId },
                            },
                            [_vm._v(_vm._s(company.companyName))]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.issuedUnit,
                            expression: "edit.issuedUnit",
                          },
                        ],
                        staticClass: "form-control mt-10",
                        attrs: { id: "selectUnit", required: "required" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "issuedUnit",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.selectDept()
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇單位")]
                        ),
                        _vm._l(_vm.depts, function (dept, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: dept.deptId } },
                            [_vm._v(_vm._s(dept.deptIdName))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.contactName,
                            expression: "edit.contactName",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "contactName",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇")]
                        ),
                        _vm._l(_vm.empOptions, function (emp, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: emp.employeeId } },
                            [_vm._v(_vm._s(emp.employeeName))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm.userData.companyId == "A"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.target,
                                expression: "edit.target",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "selectUnit", required: "required" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.edit,
                                    "target",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                _vm.targetChange,
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "disabled", value: "" } },
                              [_vm._v("請選擇")]
                            ),
                            _c("option", { attrs: { value: "0" } }, [
                              _vm._v("網銀國際"),
                            ]),
                            _vm._l(_vm.targetOptions, function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: item.targetId },
                                },
                                [_vm._v(_vm._s(item.targetName))]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "checkbox" }, [
                          _c(
                            "label",
                            [
                              _c("iCheckCheckBox", {
                                attrs: {
                                  propDisabled: _vm.edit.target == "0",
                                  propChecked: _vm.edit.isReadOnly,
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    _vm.edit.isReadOnly = $event["checked"]
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(
                            "\n                  不開放其他公司瀏覽(選「網銀國際」時此欄位唯讀)\n                "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.edit.contractorName)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.title,
                          expression: "edit.title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("tinymce", {
                          model: {
                            value: _vm.edit.contents,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "contents", $$v)
                            },
                            expression: "edit.contents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputFile" },
                    },
                    [_vm._v("上傳檔案：")]
                  ),
                  _c("div", { staticClass: "col-sm-10 text" }, [
                    _c("input", {
                      attrs: {
                        accept:
                          ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip",
                        id: "inputFile",
                        maxlength: "5",
                        multiple: "multiple",
                        name: "file1",
                        type: "file",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeFile($event)
                        },
                      },
                    }),
                    _vm._m(7),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputFile" },
                    },
                    [_vm._v("已有檔案：")]
                  ),
                  _c("div", { staticClass: "col-sm-10 text" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c(
                        "tbody",
                        _vm._l(_vm.edit.files, function (file, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [_vm._v(_vm._s(file.name))]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFile(file.applyId)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                          刪除\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(8),
                  _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    [
                      _c("select2-multiple", {
                        attrs: {
                          options: _vm.sendOptions,
                          "data-placeholder": "請選擇群組",
                        },
                        model: {
                          value: _vm.sendSelected,
                          callback: function ($$v) {
                            _vm.sendSelected = $$v
                          },
                          expression: "sendSelected",
                        },
                      }),
                      _vm._m(9),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "2",
                              message: "排程發送：",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.mode,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "mode", $$v)
                              },
                              expression: "edit.mode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(11),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [_c("DatePicker")],
                          1
                        ),
                      ]),
                      _vm._m(12),
                    ]),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "立即發送",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.mode,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "mode", $$v)
                            },
                            expression: "edit.mode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _vm.edit.status < 2
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Confirm(1)
                            },
                          },
                        },
                        [_vm._v("\n                儲存為草稿\n              ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Confirm(2)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.saveButton) +
                          "\n              "
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn bg-purple",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Confirm(3)
                        },
                      },
                    },
                    [_vm._v("\n                發佈寄信\n              ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputDocNum" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告文號：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "selectUnit" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告單位：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "AnnouncementName" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告聯絡人：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "selectCompany" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告對象：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 承辦人：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 主旨：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _vm._v("\n                  文件檔案上傳格式：\n                  "),
      _c("span", { staticClass: "text-red" }, [_vm._v("(上限10MB)")]),
      _c("br"),
      _vm._v("1.WORD：DOC、DOCX。 "),
      _c("br"),
      _vm._v("2.EXCEL：XLS、XLSX。\n                  "),
      _c("br"),
      _vm._v("3.POWERPOINT：PPT、PPTX。 "),
      _c("br"),
      _vm._v("4.PDF。 "),
      _c("br"),
      _vm._v("5.TXT。\n                  "),
      _c("br"),
      _vm._v("6.XPS。 "),
      _c("br"),
      _vm._v("7.ZIP。\n                  "),
      _c("br"),
      _vm._v("(每個檔案上限10MB，最多可以選5個檔案)\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "selectMailGroup" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送群組：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-orange mt-10" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(
        "\n                  如需新增群組，請洽系統開發人員。\n                "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "datetimepicker" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送公告：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }