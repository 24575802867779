<template>
  <!-- 編輯店家 -->
  <div class="modal fade order-setting" ref="modal" id="order-setting">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title" v-text="modalTile"></h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td class="text-nowrap">選擇日期</td>
                  <td>
                    <div class="form-group date-group">
                      <button type="button" class="btn btn-default" @click="changeDay(-1)">上一日</button>
                      <div class="date">
                        <div class="input-group">
                          <div class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                          </div>
                          <DatePicker
                            v-bind:propValue="orderDate"
                            v-on:update:propValue="onOrderDateChange"
                          />
                        </div>
                      </div>
                      <button type="button" class="btn btn-default" @click="changeDay(1)">下一日</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">選擇店家</td>
                  <td>
                    <UIMutipleSelect
                      v-bind:propOptions.sync="storeOptions"
                      :propSelecteds.sync="orderStores"
                      v-on:onSelectChange="onSelectChange"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="save">儲存</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import UIMutipleSelect from "@/components/UIMutipleSelect";
// import Pagination from "@/components/Pagination"; 沒使用到
import api from "@/assets/js/app-api";
import moment from "moment";

export default {
  components: {
    DatePicker,
    UIMutipleSelect
  },
  data() {
    return {
      eatTime: "1",
      orderDate: "",
      vegeStore: "",
      orderStores: "",
      storeOptions: []
    };
  },
  created() {
    this.queryStores();
  },
  watch: {},
  methods: {
    changeDay(offset) {
      this.orderDate = new moment(this.orderDate)
        .add(offset, "d")
        .format("YYYY/MM/DD");
      this.queryDailySetting();
    },
    show(orderDate, eatTime) {
      this.orderDate = orderDate;
      this.eatTime = eatTime;
      this.orderStores = "";
      this.queryDailySetting();
      $(this.$refs.modal).modal("show");
    },
    async queryStores() {
      //拉店家資料
      var res = await api.store.queryAll({});
      if (res.data.status != "1") {
        return;
      }
      var tmpStores = res.data.result.datas;
      for (var i = 0; i < tmpStores.length; i++) {
        if (tmpStores[i]["isVegetarian"] == "1")
          this.vegeStore = {
            id: tmpStores[i]["storeId"],
            text: tmpStores[i]["storeName"]
          };
      }
      this.storeOptions = this.getStoreOptions(res.data.result.datas, true);
    },
    async queryDailySetting() {
      //拉當日訂餐店家資料
      var res = await api.mealDailySetting.queryAll({
        eatStartDay: this.orderDate,
        eatEndDay: this.orderDate,
        eatTime: this.eatTime
      });
      if (res.data.status != "1") {
        return;
      }
      var tmpOrders = [];
      if (res.data.result.datas.length > 0) {
        //當天有資料了
        res.data.result.datas[0].stores.forEach(store => {
          tmpOrders.push(store.storeId);
        });
      }
      if (tmpOrders.length == 0 && this.vegeStore != "") {
        tmpOrders.push(this.vegeStore.id);
      }
      this.orderStores = tmpOrders;
    },
    getStoreOptions(stores, checkStatus = false) {
      //如果是空的就額外把素食加進去
      var tmp = [];
      stores.forEach(store => {
        if (checkStatus && store["storeStatus"] != "1") {
          // console.log();
        } else {
          tmp.push({ id: store.storeId, text: store.storeName });
        }
      });
      return tmp;
    },
    onOrderDateChange(date) {
      this.orderDate = date;
      this.queryDailySetting();
    },
    onSelectChange(selects) {
      this.orderStores = selects;
    },
    async save() {
      if (this.orderStores.length <= 0) {
        this.$eventBus.$emit("showAlert", "請選擇店家");
        return;
      }

      var res = await api.mealDailySetting.update({
        StoreId: this.orderStores.join(";"),
        EatDay: this.orderDate,
        EatTime: this.eatTime
      });
      if (res.data.status != "1") {
        this.$eventBus.$emit("showAlert", "更新失敗");
      } else {
        this.$eventBus.$emit("showAlert", "更新成功");
        this.$emit("refresh");
      }
    }
  },
  computed: {
    modalTile() {
      return "每日訂餐設定(" + (this.eatTime == 1 ? "午餐" : "晚餐") + ")";
    }
  }
};
</script>
