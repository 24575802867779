var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "YearEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _vm.type
        ? _c("h1", [_vm._v("大考開放時間")])
        : _c("h1", [_vm._v("目標、小考開放時間")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              { attrs: { to: `/OKR/PersonalTimeList/${_vm.type}` } },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.type ? "大考" : "目標、小考") +
                    "開放時間\n        "
                ),
              ]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(!_vm.id ? "新增" : "編輯") + "開放時間"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left" }, [
              _vm._v(_vm._s(!_vm.id ? "新增" : "編輯") + "開放時間"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v("\n            返回列表\n          "),
              ]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveData()
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.deptId,
                            expression: "data.deptId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "", disabled: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "deptId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("部別"),
                        ]),
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.deptId } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.deptName) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.year,
                            expression: "data.year",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "", disabled: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.data,
                              "year",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.years, function (year, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: year } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(year) +
                                "\n                  "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.month,
                            expression: "data.month",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "", disabled: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.data,
                              "month",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(12, function (month, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: month } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(month) +
                                "\n                  "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.employeeId,
                            expression: "data.employeeId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "", disabled: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "employeeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("人員"),
                        ]),
                        _vm._l(_vm.users, function (user, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: user.employeeId },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(user.employeeName) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.openType,
                            expression: "data.openType",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "", disabled: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.data,
                              "openType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("人員目標"),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("小考"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("大考"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "radio-inline" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.data.status,
                                expression: "data.status",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "status",
                              value: "1",
                            },
                            domProps: {
                              checked: _vm._q(_vm.data.status, _vm._n("1")),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(_vm.data, "status", _vm._n("1"))
                              },
                            },
                          }),
                          _vm._v(
                            "\n                      開放\n                    "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "radio-inline" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.data.status,
                                expression: "data.status",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: {
                              type: "radio",
                              name: "status",
                              value: "0",
                            },
                            domProps: {
                              checked: _vm._q(_vm.data.status, _vm._n("0")),
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(_vm.data, "status", _vm._n("0"))
                              },
                            },
                          }),
                          _vm._v(
                            "\n                      關閉\n                    "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.reason,
                            expression: "data.reason",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.data,
                              "reason",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("請選擇"),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("員工目標未填"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("員工小考未自評"),
                        ]),
                        _c("option", { attrs: { value: "3" } }, [
                          _vm._v("員工大考未自評"),
                        ]),
                        _c("option", { attrs: { value: "4" } }, [
                          _vm._v("主管逾期"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(7),
                        _c(
                          "div",
                          {
                            staticClass: "pull-right",
                            attrs: { id: "startTime" },
                          },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.startTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(8),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(10),
                        _c(
                          "div",
                          {
                            staticClass: "pull-right",
                            attrs: { id: "endTime" },
                          },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.endTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.endTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.endTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(11),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(12),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                部門：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                年度：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                人員：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                類別：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                狀態：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                開放原因：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                開放開始時間：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", autocomplete: "off", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                開放結束時間：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", autocomplete: "off", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c("input", {
          staticClass: "btn btn-primary",
          attrs: { type: "submit", value: "儲存" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }