<template>
  <div class="modal fade" ref="signCancel">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">抽單說明</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <textarea
              rows="4"
              type="text"
              class="form-control"
              required
              v-model="data.cancelNote"
            ></textarea>
            <!-- <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      人員目標
                    </td>
                    <td colspan="2">
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                        :disabled="data.objStatus"
                        v-model.trim="data.objective"
                      ></textarea>
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">送出</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props: ["backList"],
  data() {
    return {
      data: {
        formId: '',
        formTypeId: '',
        cancelNote: ''
      }
    };
  },
  methods: {
    async showContent(formId, formTypeId) {
      this.data.formId = formId;
      this.data.formTypeId = formTypeId;
      $(this.$refs.signCancel).modal("show");
    },
    submit() {
      this.$eventBus.$emit("showConfirm", `確定要抽單嗎?`, (isOK) => {
        if (isOK) {
          api.okr.mgmt.sign.cancel(this.data).then((res) => {
            this.global.showResponse(this, res, 'cancel', this.backList);
            $(this.$refs.signCancel).modal("hide");
          });
        }
      });
    },
  },
};
</script>
