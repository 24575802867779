var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ScorePersonalEdit" } }, [
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box-body" }, [
        _vm.data.message
          ? _c("div", { staticClass: "tc" }, [
              _vm._v("\n        " + _vm._s(_vm.data.message) + "\n      "),
            ])
          : !_vm.data.year
          ? _c("div", { staticClass: "tc" }, [_vm._v("查詢中")])
          : _c("div", [
              _c("div", {
                staticClass: "table-responsive",
                staticStyle: { "max-width": "1400px", margin: "auto" },
              }),
              _c(
                "div",
                {
                  staticClass: "table-responsive",
                  staticStyle: { "max-width": "1400px", margin: "auto" },
                },
                [
                  _c(
                    "table",
                    { staticClass: "table table-hover table-bordered min-800" },
                    [
                      _c(
                        "tbody",
                        [
                          _c(
                            "tr",
                            { staticClass: "bg-gray" },
                            [
                              _c("th", { staticClass: "tc" }, [_vm._v("#")]),
                              _c("th", { staticClass: "tc mw50" }, [
                                _vm._v("年份"),
                              ]),
                              _c("th", { staticClass: "tc mw50" }, [
                                _vm._v("月份"),
                              ]),
                              _c("th", { staticClass: "tc mw90" }, [
                                _vm._v("目標種類"),
                              ]),
                              _c("th", { staticClass: "tc mw150" }, [
                                _vm._v("部級目標"),
                              ]),
                              _c("th", { staticClass: "tc mw150" }, [
                                _vm._v("人員目標"),
                              ]),
                              _vm._l(_vm.titles, function (title, index) {
                                return [
                                  _c("th", {
                                    key: index,
                                    staticClass: "tc mw90 pre-wrap",
                                    domProps: {
                                      textContent: _vm._s(
                                        `${title.employeeName}\n${
                                          index === 0
                                            ? "（自評）"
                                            : title.isManager === "0" &&
                                              title.deptType.includes("0060")
                                            ? "（上層主管）"
                                            : title.isManager === "1" &&
                                              title.deptType.includes("0060")
                                            ? "（部門主管）"
                                            : "（高層主管）"
                                        }`
                                      ),
                                    },
                                  }),
                                  _c("th", {
                                    key: title.employeeId,
                                    staticClass: "tc mw90 pre-wrap",
                                    domProps: {
                                      textContent: _vm._s(
                                        `${
                                          _vm.data.employeeId ===
                                          title.employeeId
                                            ? "說明"
                                            : "考核意見"
                                        }`
                                      ),
                                    },
                                  }),
                                ]
                              }),
                              _c("th", { staticClass: "tc mw90" }, [
                                _vm._v("不列入考核"),
                              ]),
                            ],
                            2
                          ),
                          _vm.loading
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "tc",
                                    attrs: {
                                      colspan: 8 + 2 * _vm.titles.length,
                                    },
                                  },
                                  [_vm._v("查詢中")]
                                ),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "tc",
                                    attrs: {
                                      colspan: 8 + 2 * _vm.titles.length,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  尚無設定目標\n                "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._l(_vm.datas, function (data, index) {
                                return _c(
                                  "tr",
                                  { key: index },
                                  [
                                    _c("td", {
                                      staticClass: "tc",
                                      domProps: {
                                        textContent: _vm._s(index + 1),
                                      },
                                    }),
                                    _c("td", {
                                      staticClass: "tc",
                                      domProps: {
                                        textContent: _vm._s(data.year),
                                      },
                                    }),
                                    _c("td", {
                                      staticClass: "tc",
                                      domProps: {
                                        textContent: _vm._s(data.month),
                                      },
                                    }),
                                    _c("td", {
                                      staticClass: "tc",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.define.objectiveType[
                                            data.objectiveType
                                          ]
                                        ),
                                      },
                                    }),
                                    _c("td", {
                                      staticClass: "pre-wrap",
                                      domProps: {
                                        textContent: _vm._s(
                                          data.parentObjective
                                        ),
                                      },
                                    }),
                                    _c("td", {
                                      staticClass: "pre-wrap",
                                      domProps: {
                                        textContent: _vm._s(data.objective),
                                      },
                                    }),
                                    _vm._l(
                                      data.scores,
                                      function (scoreItem, index) {
                                        return [
                                          _c("td", {
                                            key: index,
                                            staticClass: "tc",
                                            domProps: {
                                              textContent: _vm._s(
                                                scoreItem.score
                                              ),
                                            },
                                          }),
                                          _c("td", {
                                            key: scoreItem.scoreId,
                                            staticClass: "pre-wrap",
                                            class: [
                                              scoreItem.note &&
                                              scoreItem.note.length >= 300
                                                ? "mw300"
                                                : "mw90",
                                            ],
                                            domProps: {
                                              textContent: _vm._s(
                                                scoreItem.note
                                              ),
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "table-responsive",
                  staticStyle: { "max-width": "1400px", margin: "auto" },
                },
                [
                  _c("table", { staticClass: "table table-bordered min-800" }, [
                    _c(
                      "tbody",
                      [
                        _c("tr", { staticClass: "bg-gray" }, [
                          _c(
                            "th",
                            {
                              staticClass: "tc mw150",
                              staticStyle: { width: "180px" },
                            },
                            [_vm._v("評分人員")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "tc mw150",
                              staticStyle: { width: "150px" },
                            },
                            [
                              _vm._v(
                                "\n                  績效分數\n                "
                              ),
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "tc",
                              class:
                                _vm.userData.user.employeeId === _vm.id
                                  ? "wp40"
                                  : "wp25",
                            },
                            [
                              _vm._v(
                                "\n                  說明\n                "
                              ),
                            ]
                          ),
                          _vm.userData.user.employeeId !== _vm.id
                            ? _c("th", { staticClass: "tc wp25" }, [
                                _vm._v(
                                  "\n                  給主管說明\n                "
                                ),
                              ])
                            : _vm._e(),
                          _c("th", { staticClass: "tc mw300" }, [
                            _vm._v("附件"),
                          ]),
                        ]),
                        _vm.loading
                          ? _c("tr", [
                              _c(
                                "td",
                                { staticClass: "tc", attrs: { colspan: "6" } },
                                [_vm._v("查詢中")]
                              ),
                            ])
                          : _vm.scoreDatas.length === 0
                          ? _c("tr", [
                              _c(
                                "td",
                                { staticClass: "tc", attrs: { colspan: "6" } },
                                [_vm._v("尚無評分人員")]
                              ),
                            ])
                          : _vm._l(_vm.scoreDatas, function (scoreData, index) {
                              return _c("tr", { key: index }, [
                                _c("td", {
                                  staticClass: "tc",
                                  domProps: {
                                    textContent: _vm._s(
                                      scoreData.createEmpName
                                    ),
                                  },
                                }),
                                _c("td", {
                                  staticClass: "tc fw-b fz25",
                                  domProps: {
                                    textContent: _vm._s(scoreData.totalScore),
                                  },
                                }),
                                _c("td", {
                                  staticClass: "pre-wrap",
                                  domProps: {
                                    textContent: _vm._s(scoreData.note),
                                  },
                                }),
                                _vm.userData.user.employeeId !== _vm.id
                                  ? _c("td", {
                                      staticClass: "pre-wrap",
                                      domProps: {
                                        textContent: _vm._s(
                                          scoreData.privateNote
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _c("td", { staticClass: "tc" }, [
                                  _c(
                                    "table",
                                    { staticClass: "table no-border" },
                                    [
                                      _c(
                                        "tbody",
                                        [
                                          _vm.userData.user.employeeId !==
                                            scoreData.createEmpId &&
                                          scoreData.files &&
                                          scoreData.files.length === 0
                                            ? _c("tr", [
                                                _c(
                                                  "td",
                                                  { staticClass: "tc" },
                                                  [_vm._v("－")]
                                                ),
                                              ])
                                            : _vm._l(
                                                scoreData.files,
                                                function (file, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "td",
                                                        { staticClass: "tr" },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                target:
                                                                  "_blank",
                                                                href: file.filesUrl,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    file.name
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.userData.user
                                                        .employeeId ===
                                                      scoreData.createEmpId
                                                        ? _c("td", {
                                                            staticClass: "w90",
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }