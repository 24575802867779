var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "CompanyFiles" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("公司文件")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("公司文件")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box has-select has-col5" }, [
              _vm.hasEditPermission
                ? _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-success btn-cog mr-10",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadAll()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-download" }),
                        _vm._v(" 批次下載\n            "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-danger btn-cog mr-10",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAll()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-trash" }),
                        _vm._v(" 批次刪除\n            "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-warning btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/CompanyFilesEdit")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" 新增文件\n            "),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchDatas.target,
                          expression: "searchDatas.target",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchDatas,
                              "target",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.onSearch(true)
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.issueTargets, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item.targetId } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.targetName) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchDatas.issuedUnit,
                          expression: "searchDatas.issuedUnit",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchDatas,
                              "issuedUnit",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.onSearch()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("全部單位"),
                      ]),
                      _vm._l(_vm.issuedUnitList, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.issuedUnit } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.issuedUnitName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchDatas.typeId,
                          expression: "searchDatas.typeId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectType", required: "required" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchDatas,
                              "typeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.onSearch()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("請選擇文件分類"),
                      ]),
                      _vm._l(_vm.fileTypes, function (type, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: type.tId } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(type.typeName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchDatas.keyword,
                        expression: "searchDatas.keyword",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: {
                      name: "table_search",
                      placeholder: "搜尋文件名稱",
                      type: "text",
                    },
                    domProps: { value: _vm.searchDatas.keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchDatas,
                          "keyword",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onSearch()
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", {
                  staticClass: "data-num",
                  domProps: { textContent: _vm._s("資料總筆數：" + _vm.total) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _vm.hasEditPermission
                          ? _c("th", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "checkbox-inline" }, [
                                _c(
                                  "label",
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propValue: true,
                                        propChecked: _vm.isCheckAll,
                                      },
                                      on: { "update:checked": _vm.checkAll },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "90px", width: "10%" },
                          },
                          [_vm._v("上傳日期")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { width: "10%" },
                          },
                          [_vm._v("文件分類")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { width: "10%" },
                          },
                          [_vm._v("歸屬單位")]
                        ),
                        _c("th", [_vm._v("文件名稱")]),
                        _c("th", { staticClass: "text-nowrap text-center" }, [
                          _vm._v("附件"),
                        ]),
                        _vm.hasEditPermission
                          ? _c(
                              "th",
                              {
                                staticClass: "text-nowrap text-center",
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "10%",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  功能\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "7" },
                              },
                              [_vm._v("查詢中")]
                            ),
                          ])
                        : _vm.files.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "7" },
                              },
                              [_vm._v("查詢無任何資料")]
                            ),
                          ])
                        : _vm._l(_vm.files, function (file, index) {
                            return _c(
                              "tr",
                              { key: index, attrs: { value: file.tId } },
                              [
                                _vm.hasEditPermission
                                  ? _c("td", { staticClass: "text-center" }, [
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-inline" },
                                        [
                                          _c(
                                            "label",
                                            [
                                              _c("iCheckCheckBox", {
                                                attrs: {
                                                  propValue: file,
                                                  propChecked: file.checked,
                                                },
                                                on: {
                                                  "update:checked":
                                                    _vm.checkChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.formatTime(file.createTime)
                                    ),
                                  },
                                }),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(file.typeName),
                                  },
                                }),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(file.issuedUnitName),
                                  },
                                }),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(file.documentName),
                                  },
                                }),
                                _c(
                                  "td",
                                  { staticClass: "text-center" },
                                  [
                                    _vm._l(file.files, function (file) {
                                      return _c(
                                        "a",
                                        {
                                          key: file.id,
                                          staticClass: "file-icon",
                                          attrs: {
                                            href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            class:
                                              "icon icon-" +
                                              file.mine.substring(0, 3),
                                          }),
                                        ]
                                      )
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: file.files.length == 0,
                                            expression:
                                              "file.files.length == 0",
                                          },
                                        ],
                                      },
                                      [_vm._v("-")]
                                    ),
                                  ],
                                  2
                                ),
                                _vm.hasEditPermission
                                  ? _c("td", { staticClass: "text-center" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push({
                                                name: "CompanyFilesEdit",
                                                params: {
                                                  id: file.tId,
                                                  searchData: _vm.searchDatas,
                                                },
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    修改\n                  "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer clearfix text-center" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchDatas.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("companyFileAlert", {
        ref: "companyFileAlert",
        attrs: { checkDatas: _vm.checkDatas },
        on: { updateList: _vm.refreshFiles },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }