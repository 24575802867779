var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Extension" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("分機表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公司通訊錄")]),
        _c("li", { staticClass: "active" }, [_vm._v("分機表")]),
      ]),
    ]),
    _c("section", { staticClass: "content seating" }, [
      _vm.isShow == 1
        ? _c("div", { staticClass: "box box-warning" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "top-box has-select box-header with-border" },
                [
                  _vm.hasEditPermission
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-default btn-cog",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.goEdit()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-cog" }),
                          _vm._v(" 分機表管理\n          "),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "form-group select-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "selectUnit" },
                      },
                      [_vm._v("單位：")]
                    ),
                    _vm.extension.length > 0
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.key,
                                expression: "key",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "selectUnit" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.key = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.changeUnit(
                                    $event.target.selectedIndex
                                  )
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.extension, function (item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item.typeId } },
                              [_vm._v(_vm._s(item.typeName))]
                            )
                          }),
                          0
                        )
                      : _c(
                          "select",
                          {
                            staticClass: "form-control",
                            attrs: { id: "selectUnit" },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("請選擇"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm.extension.length > 0
                    ? [
                        _vm.hasEditPermission
                          ? _c(
                              "label",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { for: "inputFile" },
                              },
                              [
                                _c("input", {
                                  ref: "seatPic",
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    accept: ".jpg, .jpeg, .png",
                                    id: "inputFile",
                                    onclick: "this.value=null;",
                                    type: "file",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeFile($event)
                                    },
                                  },
                                }),
                                _c("i", { staticClass: "fa fa-picture-o" }),
                                _vm._v(" 更新圖片\n            "),
                              ]
                            )
                          : _vm._e(),
                        _vm._v("\n             \n            "),
                        _vm.hasEditPermission
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile()
                                  },
                                },
                              },
                              [_vm._v("刪除圖片")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.extension.length > 0
                ? _c("div", { staticClass: "pic text-center" }, [
                    _c("img", {
                      attrs: { src: _vm.fileUrl, draggable: "false" },
                    }),
                  ])
                : _c("p", { staticStyle: { "margin-top": "10px" } }, [
                    _vm._v("尚未新增任何單位。"),
                  ]),
            ]),
          ])
        : _c("div", { staticClass: "box box-warning max850" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box" }, [
                _c("h4", { staticClass: "pull-left" }, [_vm._v("分機表管理")]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-default btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-reply" }),
                    _vm._v(" 返回\n          "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" 新增分機表\n          "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-bordered",
                    attrs: { id: "extension" },
                  },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(0),
                        _vm._l(_vm.extension, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "m-0",
                                  attrs: { id: "name" + item.typeId },
                                },
                                [_vm._v(_vm._s(item.typeName))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.typeName,
                                    expression: "item.typeName",
                                  },
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.editor != 0,
                                    expression: "item.editor!=0",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { required: "required", type: "text" },
                                domProps: { value: item.typeName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "typeName",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "m-0",
                                  attrs: { id: "name" + item.typeId },
                                },
                                [_vm._v(_vm._s(item.orderNum))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.orderNum,
                                    expression: "item.orderNum",
                                  },
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.editor != 0,
                                    expression: "item.editor!=0",
                                  },
                                ],
                                staticClass: "form-control text-center",
                                attrs: { required: "required", type: "number" },
                                domProps: { value: item.orderNum },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "orderNum",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.status,
                                      expression: "item.status",
                                    },
                                  ],
                                  attrs: {
                                    "false-value": 0,
                                    id: "check" + item.typeId,
                                    "true-value": 1,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.status)
                                      ? _vm._i(item.status, null) > -1
                                      : _vm._q(item.status, 1),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.status,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "status",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "status",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "status", $$c)
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    ],
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "check" + item.typeId },
                                }),
                              ]),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.change(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor != 0,
                                      expression: "item.editor!=0",
                                    },
                                  ],
                                  staticClass: "btn btn-success btn-save",
                                  on: {
                                    click: function ($event) {
                                      return _vm.save(item)
                                    },
                                  },
                                },
                                [_vm._v("儲存")]
                              ),
                              _vm._v(
                                "\n                   \n                  "
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor == 0,
                                      expression: "item.editor==0",
                                    },
                                  ],
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteType(item, index)
                                    },
                                  },
                                },
                                [_vm._v("刪除")]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.editor != 0,
                                      expression: "item.editor!=0",
                                    },
                                  ],
                                  staticClass: "btn btn-default",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel(item, index)
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "30%" } },
        [_vm._v("名稱")]
      ),
      _c("th", { staticClass: "text-center" }, [_vm._v("排序")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("啟用狀態")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "20%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }