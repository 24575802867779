var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ReceptionArea" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("交誼廳")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("場地申請")]),
          _c("li", { staticClass: "active" }, [_vm._v("交誼廳")]),
        ]),
      ]),
      _c("section", { staticClass: "content reception-area" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "input-group date" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DatePicker", {
                      model: {
                        value: _vm.meetingDay,
                        callback: function ($$v) {
                          _vm.meetingDay = $$v
                        },
                        expression: "meetingDay",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("目前查看：" + _vm._s(_vm.meetingDay)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table order-table table-bordered" }, [
                _c("tbody", [
                  _c("tr", { staticClass: "bg-gray" }, [
                    _c("th", [_vm._v("時段")]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(0, "MM/DD dddd"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(1, "MM/DD dddd"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(2, "MM/DD dddd"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(3, "MM/DD dddd"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(4, "MM/DD dddd"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(5, "MM/DD dddd"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.GetOneWeek(6, "MM/DD dddd"))),
                    ]),
                  ]),
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("18:00-19:00")]),
                      _vm._l(_vm.days, function (day) {
                        return _c("td", {
                          key: day,
                          class: {
                            booking: _vm.checkUserBooked(
                              _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                              "1800",
                              "1900"
                            ),
                            disabled: _vm.checkCanBooking(
                              _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                              "1800",
                              "1900"
                            ),
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.PrintBookedInfo(
                                _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                                "1800",
                                "1900"
                              )
                            ),
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("19:00-20:00")]),
                      _vm._l(_vm.days, function (day) {
                        return _c("td", {
                          key: day,
                          class: {
                            booking: _vm.checkUserBooked(
                              _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                              "1900",
                              "2000"
                            ),
                            disabled: _vm.checkCanBooking(
                              _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                              "1900",
                              "2000"
                            ),
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.PrintBookedInfo(
                                _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                                "1900",
                                "2000"
                              )
                            ),
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    [
                      _c("td", [_vm._v("20:00-21:00")]),
                      _vm._l(_vm.days, function (day) {
                        return _c("td", {
                          key: day,
                          class: {
                            booking: _vm.checkUserBooked(
                              _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                              "2000",
                              "2100"
                            ),
                            disabled: _vm.checkCanBooking(
                              _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                              "2000",
                              "2100"
                            ),
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.PrintBookedInfo(
                                _vm.GetOneWeek(day - 1, "YYYY/MM/DD"),
                                "2000",
                                "2100"
                              )
                            ),
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _vm._m(1),
          ]),
        ]),
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "title" }, [
                _vm._v("交誼廳下班時間使用規則說明"),
              ]),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkByUserPermission(),
                      expression: "checkByUserPermission()",
                    },
                  ],
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: { click: _vm.openReceptionEdit },
                },
                [
                  _c("i", { staticClass: "fa fa-cog" }),
                  _vm._v(" 修改使用規則\n          "),
                ]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("div", {
                staticClass: "editor",
                domProps: { innerHTML: _vm._s(_vm.areaRuler.roomInfo) },
              }),
            ]),
          ]),
        ]),
      ]),
      _c("receptionBooking", {
        ref: "receptionBooking",
        on: { refreshList: _vm.GetRestBookingList },
      }),
      _c("receptionEdit", {
        ref: "receptionEdit",
        on: { refreshList: _vm.GetInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red mt-10" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(" 點擊空白時段即可進行預約或取消，已過的時段無法預約。\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }