var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "booking_area", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("活動日期")]),
                  _c("td", [_vm._v(_vm._s(_vm.booking.meetingDay))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("會議時段")]),
                  _c("td", { staticClass: "form-inline" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.booking.startTime,
                              expression: "booking.startTime",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.booked.length > 0 },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.booking,
                                "startTime",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "1800" } }, [
                            _vm._v("18:00"),
                          ]),
                          _c("option", { attrs: { value: "1900" } }, [
                            _vm._v("19:00"),
                          ]),
                          _c("option", { attrs: { value: "2000" } }, [
                            _vm._v("20:00"),
                          ]),
                          _c("option", { attrs: { value: "2100" } }, [
                            _vm._v("21:00"),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" 至 \n                  "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.booking.endTime,
                              expression: "booking.endTime",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.booked.length > 0 },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.booking,
                                "endTime",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "1900" } }, [
                            _vm._v("19:00"),
                          ]),
                          _c("option", { attrs: { value: "2000" } }, [
                            _vm._v("20:00"),
                          ]),
                          _c("option", { attrs: { value: "2100" } }, [
                            _vm._v("21:00"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("預約人")]),
                  _c("td", [_vm._v(_vm._s(_vm.employeeName))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("活動主題")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.booking.meetingTitle,
                          expression: "booking.meetingTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        required: "required",
                        type: "text",
                        disabled: _vm.booked.length > 0,
                      },
                      domProps: { value: _vm.booking.meetingTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.booking,
                            "meetingTitle",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("參與人數")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.booking.personCount,
                          expression: "booking.personCount",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        max: "100",
                        min: "1",
                        required: "required",
                        type: "number",
                        disabled: _vm.booked.length > 0,
                      },
                      domProps: { value: _vm.booking.personCount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.booking,
                            "personCount",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _vm._m(1),
          _vm.booked.length > 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelBooked()
                    },
                  },
                },
                [_vm._v("取消預約")]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.bookingMeetingRoom()
                    },
                  },
                },
                [_vm._v("確定預約")]
              ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("關閉")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("預約交誼廳")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red pull-left mt-5" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(" 已過的時段無法取消預約。\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }