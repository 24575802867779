<template>
  <div id="OrderTime">
    <section class="content-header">
      <h1>訂餐時間</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">訂餐時間</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-header with-border">
          <h3 class="box-title">設定午餐</h3>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="openOrder">
                <span class="text-red">*</span> 開放訂餐時間：
              </label>
              <div class="col-sm-9">
                <select class="form-control" id="openOrder" v-model="weekStart">
                  <option value="1">週一</option>
                  <option value="2">週二</option>
                  <option value="3">週三</option>
                  <option value="4">週四</option>
                  <option value="5">週五</option>
                  <option value="6">週六</option>
                  <option value="7">週日</option>
                </select>
                <select class="form-control mt-10" v-model="lunchHourStart">
                  <option value="9">09:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="orderDeadLine">
                <span class="text-red">*</span> 截止訂餐時間：
              </label>
              <div class="col-sm-9">
                <select class="form-control" id="orderDeadLine" v-model="weekEnd">
                  <option value="1">週一</option>
                  <option value="2">週二</option>
                  <option value="3">週三</option>
                  <option value="4">週四</option>
                  <option value="5">週五</option>
                  <option value="6">週六</option>
                  <option value="7">週日</option>
                </select>
                <select class="form-control mt-10" v-model="lunchHourEnd">
                  <option value="9">09:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="box-footer">
          <div class="btns">
            <button class="btn btn-primary" type="button" @click="setLunchTime">更新</button>
            <button class="btn btn-default" type="button" @click="getLunchTime">取消</button>
          </div>
        </div>
      </div>
      <div class="box box-warning max850">
        <div class="box-header with-border">
          <h3 class="box-title">設定晚餐</h3>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="openOrder">
                <span class="text-red">*</span> 開放訂餐時間：
              </label>
              <div class="col-sm-9">
                <select class="form-control" v-model="dinnerHourStart">
                  <option value="9">09:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="orderDeadLine">
                <span class="text-red">*</span> 截止訂餐時間：
              </label>
              <div class="col-sm-9">
                <select class="form-control" v-model="dinnerHourEnd">
                  <option value="9">09:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="box-footer">
          <div class="btns">
            <button class="btn btn-primary" type="button" @click="setDinnerTime">更新</button>
            <button class="btn btn-default" type="button" @click="getDinnerTime">取消</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  data() {
    return {
      weekStart: "1",
      weekEnd: "5",
      lunchHourStart: "9",
      lunchHourEnd: "18",
      dinnerHourStart: "9",
      dinnerHourEnd: "18"
    };
  },
  created() {
    this.getLunchTime();
    this.getDinnerTime();
  },
  methods: {
    getLunchTime() {
      api.mealTimeSet.queryLunchEdit({}).then(res => {
        if (res.data.status == "1") {
          let result = res.data.result;
          this.weekStart = result.weekStart;
          this.weekEnd = result.weekEnd;
          this.lunchHourStart = result.hourStart;
          this.lunchHourEnd = result.hourEnd;
        }
      });
    },
    setLunchTime() {
      api.mealTimeSet
        .timeSetLunch({
          weekStart: this.weekStart,
          weekEnd: this.weekEnd,
          hourStart: this.lunchHourStart,
          hourEnd: this.lunchHourEnd
        })
        .then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
          } else {
            this.$eventBus.$emit("showAlert", "更新完畢!!");
            return;
          }
        });
    },
    getDinnerTime() {
      api.mealTimeSet.queryDinner({}).then(res => {
        if (res.data.status == "1") {
          let result = res.data.result;
          this.dinnerHourStart = result.hourStart;
          this.dinnerHourEnd = result.hourEnd;
        }
      });
    },
    setDinnerTime() {
      api.mealTimeSet
        .timeSetDinner({
          hourStart: this.dinnerHourStart,
          hourEnd: this.dinnerHourEnd
        })
        .then(res => {
          if (res.data.status != "1") {
            this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
          } else {
            this.$eventBus.$emit("showAlert", "更新完畢!!");
            return;
          }
        });
    }
  }
};
</script>
