<template>
  <!--預定會議室-->
  <div class="modal fade" ref="receptionEdit">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title"><span class="text-red">*</span> 修改使用規則</h4>
        </div>
        <div class="modal-body">
          <CKEditor
            :content="areaRuler.roomInfo"
            @onContentChagne="onEditorContentChange"
            ref="CKEditor"
          />
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="saveReceptionArea()">儲存</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">取消</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import CKEditor from "@/components/CKEditor";

export default {
  components: {
    CKEditor
  },
  data() {
    return {
      areaRuler: {}
    };
  },
  methods: {
    async showReceptionEdit(roomId) {
      if (roomId == "" || roomId == undefined) {
        $(this.$refs.receptionEdit).modal("hide");
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      await api.meetingRoom.queryRestInfo({}).then(res => {
        if (res.data.status == "1") {
          this.areaRuler = res.data.result;
          $(this.$refs.receptionEdit).modal("show");
        }
      });
    },
    onEditorContentChange(content) {
      this.areaRuler.roomInfo = content;
    },
    saveReceptionArea() {
      this.areaRuler.roomInfo = this.$refs.CKEditor.SetData();

      if (this.areaRuler.roomInfo == "") {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        $(this.$refs.receptionEdit).modal("hide");
        this.refreshList();
        return;
      }

      api.meetingRoom.restInfoSet(this.areaRuler).then(res => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
          return;
        } else {
          $(this.$refs.receptionEdit).modal("hide");
          this.refreshList();
          return;
        }
      });
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>