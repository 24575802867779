var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ScoreAddFlow" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("工作績效考核表 (例外)")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: "/OKR/ScorePersonalList/Except" } },
                [_vm._v("\n          工作績效考核表 (例外)\n        ")]
              ),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("新增考核")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left" }, [_vm._v("新增考核")]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v("\n            返回列表\n          "),
                ]
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveData()
                  },
                },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.data.year,
                              expression: "data.year",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "year",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.getCycleMonths(true)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.years, function (year, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: year } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(year) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.data.month,
                              expression: "data.month",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.data,
                                "month",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _vm.data.deptLevel === 2
                            ? _c("option", { attrs: { value: "0" } }, [
                                _vm._v(
                                  "\n                    上半年\n                  "
                                ),
                              ])
                            : _vm._e(),
                          _vm.data.deptLevel === 2
                            ? _c("option", { attrs: { value: "1" } }, [
                                _vm._v(
                                  "\n                    下半年\n                  "
                                ),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.months, function (month, index) {
                            return _c(
                              "option",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.data.deptLevel !== 2,
                                    expression: "data.deptLevel !== 2",
                                  },
                                ],
                                key: index,
                                domProps: { value: month },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(month) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.deptId,
                              expression: "data.deptId",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "deptId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.getCycleMonths(true)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.deptId } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.deptName) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.data.deptLevel,
                              expression: "data.deptLevel",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "deptLevel",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.getCycleMonths(true)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "2" } }, [
                            _vm._v("部級主管"),
                          ]),
                          _c("option", { attrs: { value: "3" } }, [
                            _vm._v("一般員工"),
                          ]),
                          _c("option", { attrs: { value: "4" } }, [
                            _vm._v("新進員工"),
                          ]),
                          _c("option", { attrs: { value: "5" } }, [
                            _vm._v("實習生"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      staticStyle: { margin: "40px 0" },
                    },
                    [
                      _c("label", { staticClass: "col-sm-2 control-label" }),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c("div", { staticClass: "flowContent x_content" }, [
                          _c(
                            "ul",
                            { staticClass: "process-map list-unstyled" },
                            [
                              _c("li", { staticClass: "start bg-green" }, [
                                _vm._v("開始"),
                              ]),
                              _c("li", { staticClass: "add" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showContent(null, 0.5)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-plus" })]
                                ),
                              ]),
                              _vm._l(_vm.datas, function (item, index) {
                                return [
                                  _c("li", { key: index }, [
                                    _c("div", { staticClass: "level" }, [
                                      _vm._v(
                                        "第" + _vm._s(item.orderNum) + "關"
                                      ),
                                    ]),
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.empName)),
                                    ]),
                                    _c("div", { staticClass: "tools" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "bg-blue",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showContent(
                                                item,
                                                item.orderNum
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-pencil",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "bg-red ml10",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteData(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    index === 0
                                      ? _c("span", { staticClass: "name" }, [
                                          _vm._v(
                                            "\n                          （考核人員）\n                        "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "li",
                                    {
                                      key: item.groupFlowId,
                                      staticClass: "add",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-default",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showContent(
                                                null,
                                                item.orderNum + 0.5
                                              )
                                            },
                                          },
                                        },
                                        [_c("i", { staticClass: "fa fa-plus" })]
                                      ),
                                    ]
                                  ),
                                ]
                              }),
                              _c("li", { staticClass: "end bg-gray" }, [
                                _vm._v("結束"),
                              ]),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _vm._m(2),
              ]
            ),
          ]),
        ]),
      ]),
      _c("FlowContent", {
        ref: "flowContent",
        on: { updateList: _vm.updateData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                年度：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                考核單位：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c("input", {
          staticClass: "btn btn-primary",
          attrs: { type: "submit", value: "新增" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }