<template>
  <!-- 快訊 -->
  <div class="modal fade" ref="flashInfo">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">最新消息</h4>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-habits">
            <tbody>
              <tr :key="index" v-for="(info, index) in flashInfos.datas">
                <td v-html="info.note">
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  data() {
    return {
      flashInfos: {},
    };
  },
  mounted() {},
  methods: {
    async showFlashInfoBox() {
      await this.GetFlashInfo();

      $(this.$refs.flashInfo).modal("show");
    },
    async GetFlashInfo() {
      await api.marquee.showList().then((res) => {
        if (res.data.status == "1") {
          this.flashInfos = res.data.result;
        }
      });
    },
  },
};
</script>