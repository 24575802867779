<template>
  <!-- 個人飲食習慣 -->
  <div class="modal fade" ref="food_habits">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">個人飲食習慣</h4>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-habits">
            <tbody>
              <tr>
                <td>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="葷食"
                      name="radioMode"
                      v-model="edit.mealType"
                    />
                  </div>
                  <iCheckMultiCheckBox
                    :propOptions="foodType"
                    :propSelecteds.sync="edit.mealContainAry"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="0"
                      message="素食"
                      name="radioMode"
                      v-model="edit.mealType"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" ref="editSaveHabitsButton" 
          type="button"
          v-on:click="updateHabits(edit)"
          data-dismiss="modal"
          >儲存</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
// import utils from "@/assets/js/app-utils"; 沒使用到
import iCheckMultiCheckBox from "@/components/iCheckMultiCheckBox";

export default {
  components: {
    iCheckMultiCheckBox,
    iCheckRedioButton
  },
  data() {
    return {
      edit: {},
      foodType: ["不吃牛", "不吃豬", "不吃魚"],
      employeeId: ""
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue"
    });
  },
  methods: {
    async showHabitsEditBox() {
      this.employeeId = this.$store.state.loginUser.employeeId;
      var that = this;

      if (this.employeeId == "" || this.employeeId == undefined) {
        $(that.$refs.food_habits).modal("hide");
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      await this.getEmpHabitsInfo();

      $(this.$refs.food_habits).modal("show");
      // $(this.$refs.editSaveHabitsButton).click(() => {
      //   $(this.$refs.editSaveHabitsButton).off("click");
      //   $(this.$refs.food_habits).modal("hide");
      //   this.updateHabits(this.edit);
      // });
    },
    getEmpHabitsInfo() {
      api.mealHabits
        .query({
          employeeId: this.employeeId
        })
        .then(res => {
          if (res.data.status == "1") {
            this.edit = JSON.parse(JSON.stringify(res.data.result));
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }

          if (this.edit != null) {
            if (this.edit.notEat != "") {
              this.edit.mealContainAry = this.edit.notEat.split(",");
            } else {
              this.edit.mealContainAry = [];
            }
          } else if (this.edit == null) {
            this.edit = {
              employeeId: this.employeeId,
              mealType: 1,
              notEat: "",
              mealContainAry: []
            };
          }
        });
    },
    updateHabits(habits) {
      if (habits.mealType == "0") {
        habits.notEat = "";
        habits.mealContainAry = [];
      }

      if (habits.mealContainAry != [] && habits.mealContainAry != undefined)
        habits.notEat = habits.mealContainAry.join();

      api.mealHabits.save(habits).then(res => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        }
      });
    }
  }
};
</script>