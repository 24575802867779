<template>
  <div id="Newcomers">
    <section class="content-header">
      <h1>本月新進同仁</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司公告</li>
        <li class="active">本月新進同仁</li>
      </ol>
    </section>
    <section class="content">
      <div class="row">
        <div class="box box-warning max850">
          <div class="box-body no-padding">
            <ul class="users-list clearfix">
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">企劃處-產品企劃部</p>
                  <h4 class="users-list-name">廖OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">研發處-研發四部</p>
                  <h4 class="users-list-name">謝OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">企劃處-測試部</p>
                  <h4 class="users-list-name">林OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">企劃處-測試部</p>
                  <h4 class="users-list-name">黃OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">研發處-研發二部</p>
                  <h4 class="users-list-name">林OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">研發處-研發二部</p>
                  <h4 class="users-list-name">陳OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">行政管理處-資訊部(技術客服組)</p>
                  <h4 class="users-list-name">王OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
              <li>
                <div class="item" data-target="#newcomers-info" data-toggle="modal">
                  <div class="pic jqimgFill">
                    <img draggable="false" src="../assets/user-160x160.jpg" />
                  </div>
                  <p class="department">行政管理處-資訊部(技術客服組)</p>
                  <h4 class="users-list-name">高OO</h4>
                  <p class="users-list-date">2019/07/01</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import AlertBox from '../components/AlertBox'

export default {
  components: {
    AlertBox
  }
}
</script>
