var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "scoreDeptContent", staticClass: "modal fade" }, [
    _c(
      "div",
      { staticClass: "modal-dialog", staticStyle: { width: "900px" } },
      [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("th", { staticClass: "control-label w90" }, [
                            _vm._v("考核項目"),
                          ]),
                          _c("td", {
                            attrs: { colspan: "2" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.define.objectiveType[_vm.data.objectiveType]
                              ),
                            },
                          }),
                        ]),
                        _vm._l(5, function (num, index) {
                          return [
                            _c("tr", { key: index }, [
                              _c("th", { staticClass: "control-label" }, [
                                index === 0
                                  ? _c("span", [_vm._v("評分參考")])
                                  : _vm._e(),
                              ]),
                              _c("td", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.define.scoreType[`s${num}`].text
                                    ) +
                                    "\n                    "
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm.define.scoreType[`s${num}`].score
                                    ) +
                                    "\n                    "
                                ),
                              ]),
                            ]),
                          ]
                        }),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "control-label",
                              attrs: { colspan: "3" },
                            },
                            _vm._l(
                              _vm.data.scores,
                              function (scoreItem, index) {
                                return _c(
                                  "table",
                                  {
                                    key: index,
                                    staticClass: "table table-bordered",
                                  },
                                  [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", {
                                          staticClass: "tc bg-dark",
                                          attrs: { colspan: "2" },
                                          domProps: {
                                            textContent: _vm._s(
                                              `${scoreItem.createEmpName}${
                                                index === 0
                                                  ? "（自評）"
                                                  : scoreItem.createIsManager ===
                                                      "0" &&
                                                    scoreItem.createDeptType.includes(
                                                      "0060"
                                                    )
                                                  ? "（上層主管）"
                                                  : scoreItem.createIsManager ===
                                                      "1" &&
                                                    scoreItem.createDeptType.includes(
                                                      "0060"
                                                    )
                                                  ? "（部門主管）"
                                                  : "（高層主管）"
                                              }`
                                            ),
                                          },
                                        }),
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "tc bg-dark-gray",
                                            attrs: { width: "200px" },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: scoreItem.totalScore,
                                                  expression:
                                                    "scoreItem.totalScore",
                                                },
                                              ],
                                              staticClass:
                                                "form-control fw-b fz50",
                                              staticStyle: {
                                                width: "130px",
                                                height: "auto",
                                                margin: "auto",
                                              },
                                              attrs: {
                                                type: "number",
                                                min: "0",
                                              },
                                              domProps: {
                                                value: scoreItem.totalScore,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    scoreItem,
                                                    "totalScore",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("td", [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: scoreItem.note,
                                                expression: "scoreItem.note",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              rows: "6",
                                              type: "text",
                                              placeholder: "輸入文字",
                                              disabled:
                                                _vm.data.userData.user
                                                  .employeeId !==
                                                scoreItem.createEmpId,
                                            },
                                            domProps: { value: scoreItem.note },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  scoreItem,
                                                  "note",
                                                  $event.target.value.trim()
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _vm._m(1),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("評分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("儲存")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("\n            取消\n          ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }