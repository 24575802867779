<template>
  <div id="GroupSet">
    <section class="content-header">
      <h1>簽核群組流程設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">簽核群組流程設定</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-success btn-add"
                type="button"
                @click="exportList()"
              >
                匯出
              </button>
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="showGroupContent()"
              >
                <i class="fa fa-plus"></i>
                新增群組
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢群組：</label>
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="resetDataList()"
                >
                  <option value="">部別</option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="請輸入群組名稱"
                  type="text"
                  v-model.trim="searchData.keyword"
                />
                <div class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="resetDataList()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">部別</th>
                  <th class="tc mw150">群組名稱</th>
                  <th class="tc mw150">成員</th>
                  <th class="tc">核決人員</th>
                  <th class="tc">需考核</th>
                  <th class="tc">功能</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.groupName"></td>
                  <td class="tc">
                    <div v-for="member in data.groupMembers">
                      {{ member.empName }}
                    </div>
                  </td>
                  <td class="tc">
                    <div>
                      小考： 
                      <span v-for="item in data.flowStops" v-if="item.stopType == '小考'">
                        {{ item.stopEmpName }}
                      </span>
                    </div>
                    <div>
                      大考： 
                      <span v-for="item in data.flowStops" v-if="item.stopType == '大考'">
                        {{ item.stopEmpName }}
                      </span>
                    </div>
                  </td>
                  <td
                    class="tc"
                    v-if="
                      permission.deptIds.includes(data.deptId) ||
                      permission.isHR
                    "
                  >
                    <iCheckCheckBox
                      :propItem="data"
                      :propChecked="data.checked"
                      v-on:update:checked="setScore"
                    />
                  </td>
                  <td
                    class="tc"
                    v-else
                    v-text="data.checked ? '是' : '否'"
                  ></td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      @click="showGroupContent(data)"
                    >
                      成員{{
                        permission.deptIds.includes(data.deptId) ||
                        permission.isHR
                          ? "設定"
                          : "檢視"
                      }}
                    </button>
                    <button
                      class="btn btn-primary ml10"
                      @click="updateFlowData(data)"
                    >
                      流程{{
                        permission.deptIds.includes(data.deptId) ||
                        permission.isHR
                          ? "設定"
                          : "檢視"
                      }}
                    </button>
                  </td>
                  <td
                    class="tc"
                    v-if="
                      permission.deptIds.includes(data.deptId) ||
                      permission.isHR
                    "
                  >
                    <button class="btn btn-danger" @click="deleteData(data)">
                      刪除
                    </button>
                  </td>
                  <td class="tc" v-else>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <GroupContent ref="groupContent" v-on:updateList="getDataList" />
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import GroupContent from "./GroupContent";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    iCheckCheckBox,
    Pagination,
    GroupContent,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        deptId: "",
      },
      addData: {},
      userData: {},
      permission: {},
      list: [],
      datas: [],
      allDatas: []
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.getList();
  },
  methods: {
    getList() {
      api.okr.goal.department.list({deptLevel: 0}).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.updateListSearch();
          this.getDataList();
          this.getAllDataList();
        }
      });
    },
    getAllDataList() {
      api.okr.mgmt.group.queryAll({
        pageNum: 1,
        pageSize: 999,
        target: this.searchData.target,
        keyword: this.searchData.keyword,
        deptId: this.searchData.deptId,
      }).then((res) => {
        if (res.data.status === "1") {
          this.allDatas = res.data.result.datas || [];
        }
      });
    },
    getDataList() {
      api.okr.mgmt.group.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.updateDataList();
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    updateDataList() {
      this.datas.forEach((data) => {
        data.checked = data.nonScore ? 0 : 1;
        setTimeout(() => {
          data.checked = data.nonScore ? false : true;
          this.$forceUpdate();
        }, 300);
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
      this.getAllDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateListSearch() {
      if (
        this.loading &&
        !this.$route.params.searchData &&
        !this.permission.isHR
      ) {
        this.searchData.deptId = this.global.getDeptId(
          this.list,
          this.userData,
          this.permission
        );
      }
    },
    showGroupContent(data) {
      this.addData.userData = this.userData;
      this.addData.permission = this.permission;
      this.addData.deptId = data ? data.deptId : this.searchData.deptId;
      this.$refs.groupContent.showContent(data, this.addData);
    },
    updateFlowData(data) {
      this.$router.push({
        name: "FlowEdit",
        params: {
          id: data ? data.deptId : null,
          groupId: data ? data.groupId : null,
          searchData: this.searchData,
        },
      });
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.mgmt.group.delete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    setScore(data) {
      if (data.item.nonScore === data.checked) {
        data.item.nonScore = data.checked ? 0 : 1;
        api.okr.mgmt.flow.setScore(data.item).then((res) => {
          this.global.showResponse(this, res, "update");
        });
      }
    },
    async exportList() {
      let data = [];
      this.allDatas.forEach((res) => {
        let members = res.groupMembers.map(member => { return member.empName })
        members = members.toString().replace(/,/g, `\r\n`);

        let flowStops = res.flowStops.map((flop) => {
          if(flop.stopType == '小考') return `小考：${flop.stopEmpName}`
          else return `大考：${flop.stopEmpName}`
        })
        flowStops = flowStops.toString().replace(/,/g, `\r\n`);

        data.push({
          部別: res.deptName,
          群組名稱: res.groupName,
          成員:`${members}`,
          核決人員: flowStops,
        });
      });

      utils.exportData(
        data,
        utils.formatTime(this.eatDay, "YYYYMMDD") + "_" + '簽核群組流程設定' + ".xlsx"
      );
		},
  },
};
</script>
