<template>
  <div id="Booked">
    <section class="content-header">
      <h1>我的預約歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>場地申請</li>
        <li class="active">我的預約歷程</li>
      </ol>
    </section>
    <section class="content reception-area">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group date">
              <div class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </div>
              <div class="pull-right">
                <DatePicker v-model="bookedDay" :propDisplay="true" />
              </div>
              <!-- <div class="input-group-btn">
                <button class="btn btn-default" type="button" @click="GetBookedList()">
                  <i class="fa fa-search"></i>
                </button>
              </div>-->
            </div>
            <!--<div class="text">
              <p class="data-num">目前查看：{{bookedDay}}</p>
            </div>-->
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px">預約日期</th>
                  <th style="min-width: 90px">預約時段</th>
                  <th>預約場地</th>
                  <th>預約主題</th>
                  <th class="text-nowrap">狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px;">功能</th>
                </tr>
                <tr :key="index" v-for="(booked, index) in bookedList.datas">
                  <td>{{formatTime(booked.meetingDay)}}</td>
                  <td>{{booked.startTime+"-"+booked.endTime}}</td>
                  <td>{{booked.roomName}}</td>
                  <td>{{booked.meetingTitle}}</td>
                  <td>{{GetState(booked.isDelete,booked.updateTime)}}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-danger"
                      v-if="checkCanCancelBooked(booked)"
                      @click="deleteBooked(booked)"
                    >取消預約</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    DatePicker,
    Pagination
  },
  data() {
    return {
      keyword: "",
      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數
      page: 1,

      employeeId: this.$store.state.loginUser.employeeId,

      bookedList: {},
      bookedDay: ""
    };
  },
  created() {
    this.GetBookedList();
  },
  watch: {
    bookedDay() {
      this.page = 1;
      this.GetBookedList();
    }
  },
  methods: {
    onPageChange(pageIdx, pageSize) {
      this.page = pageIdx;
      this.pageSize = pageSize;
      this.GetBookedList();
    },
    GetBookedList() {
      api.meetingBooking
        .applyList({
          keyword: this.keyword,
          startDay: this.bookedDay,
          endDay: this.bookedDay,
          employeeId: this.employeeId,
          pageSize: this.pageSize,
          pageNum: this.page
        })
        .then(res => {
          if (res.data.status == "1") {
            this.bookedList = res.data.result;
            this.total = this.bookedList.total;
          }
        });
    },
    deleteBooked(booked) {
      let that = this;
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", function(res) {
        if (res) {
          api.meetingBooking.delete(booked).then(res => {
            if (res.data.status != "1") {
              that.$eventBus.$emit("showAlert", "Fail!");
            }
            that.GetBookedList();
          });
        }
      });
    },
    GetState(status, updateTime) {
      switch (status) {
        case 1:
          return utils.formatTime(updateTime, "YYYY/MM/DD") + " 取消";
          break;
        case 0:
          return "正常";
          break;
      }
    },
    checkCanCancelBooked(booked) {
      if (
        booked.isDelete == 0 &&
        new moment().isBefore(
          this.formatTime(booked.meetingDay) +
            " " +
            booked.endTime.substring(0, 2) +
            ":00"
        )
      )
        return true;
      return false;
    },
    formatTime: function(t) {
      return utils.formatTime(t, "YYYY/MM/DD");
    }
  }
};
</script>
