<template>
<div class="login-page">
  <div class="login-box" :class="compony()">
    <div class="login-logo">
      <div class="logo">
        <h1 class="sr-only">網銀國際 EIP 內部管理系統</h1>
      </div>
    </div>
    <div class="login-box-body">
      <div class="h3 login-box-msg">內部管理系統</div>
      <form>
        <div class="form-group has-feedback">
          <input class="form-control" placeholder="帳號" type="text" v-model="userName" @change="getMailStatus()" />
          <span class="glyphicon glyphicon-user form-control-feedback"></span>
        </div>
        <div class="form-group has-feedback">
          <input class="form-control" placeholder="密碼" type="password" v-model="password" />
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
        <div class="form-group has-feedback" v-if="verifyStatus == 1">
          <input class="form-control" placeholder="驗證碼" type="text" v-model="verify" />
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
        <button
          class="btn btn-primary btn-block btn-flat"
          type="submit"
          v-on:click.stop.prevent="login()"
        >登入</button>
        <div
          class="link text-center mt-10"
          style="text-decoration: underline"
          v-if="isOutsider == 1 || isADUser == 1"
        >
          <a href="javaScript:;" @click="forgetPwd()">忘記密碼?</a>
        </div>
        <p class="text-red mt-15">建議使用Chrome瀏覽器，並且將螢幕解析度調整為1280x900以上！</p>
      </form>
      <p>網銀國際(股)有限公司 研發處開發</p>
    </div>
    <p class="text-center mt-10">
      Copyright © 2023
      <a href="https://www.wanin.tw" target="_blank">WANIN</a> All Rights Reserved.
    </p>

    <!-- 密碼到期  提醒彈窗 -->
    <div class="modal fade" id="attention-date" style="z-index: 999;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              @click="goLogin()"
              type="button"
              v-if="islogin"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> 注意
            </h4>
          </div>
          <div class="modal-body">
            <p v-if="days > 0">
              您的密碼將在
              <span class="text-red">{{days}}</span>天後到期，請盡快更新密碼！
            </p>
            <p v-else>您的密碼已到期，請更新密碼！</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success" type="button" @click="midify()">前往更改密碼</button>
            <button
              class="btn btn-default pull-right"
              id="attention-dismiss"
              data-dismiss="modal"
              @click="goLogin()"
              type="button"
              v-if="islogin"
            >取消</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <midify ref="midify" :proplogin="false" />
  </div>
</div>
</template>

<script>
import api from "../assets/js/app-api";
import moment from "moment";
import midify from "@/views/Login/midify";

export default {
  inject: ["reload"],
  components: {
    midify,
  },
  data() {
    return {
      userName: "",
      password: "",
      verify: "",
      days: 10,
      islogin: true,
      isOutsider: 0,
      verifyStatus: 0,
      isVerify : false,
      isADUser : 0,
    };
  },
  mounted() {
    $("body").removeClass().addClass("hold-transition login-page");
  },
  methods: {
    compony() {
      let company = localStorage.getItem("company")
      if(company){
        switch (company) {
          case 't':
            return "TESL";
          case 'c':
            return "CTESA";
          default:
            return "";
        }
      } else {
        return ''
      }
    },
    getMailStatus(login = false) {
      api.login
        .mailStatus({userid: this.userName})
        .then((res) => {
          this.isVerify = true
          this.isOutsider = res.data.result.isOutsider;
          this.verifyStatus = res.data.result.verifyStatus;
          this.isADUser = res.data.result.isADUser;
          this.$forceUpdate();
          
          //verifyStatus => 0：無啟動，1：有啟動且已發送mail驗證碼，2：無mail
          if(this.verifyStatus == 1) {
            this.$eventBus.$emit("showAlert", `
              先前輸入錯誤密碼達三次，提醒此次登入需輸入驗證碼<br>
              驗證碼已送到您的mail：${res.data.result.empEmail}
            `);
          }

          if(login && this.verifyStatus == 0) this.login();
        })
    },
    login() {
      if(!this.isVerify) {
        this.getMailStatus(true)
        return
      }
      if(this.verifyStatus == 1 && this.verify == '') {
        this.$eventBus.$emit("showAlert", "請輸入驗證碼");
        return
      }
      api.login
        .login({
          userid: this.userName,
          password: this.password,
          VerifyCode: this.verify,
        })
        .then((res) => {
          if (res.data.status == "1") {
            let route = res.data.result.user.companyId == 'N' ? '/?Com=N' : '/'
            this.$store.commit("setLoginUser", res.data.result.user);
            this.$store.commit("setLoginUserMenu", res.data.result.menu);
            this.$store.commit(
              "setLoginPermission",
              res.data.result.permission
            );
            this.$store.commit("setLoginDeptEmp", res.data.result.deptEmp);
            this.$store.commit("setLoginMealAssign", res.data.result.mealAssign);

            if (res.data.result.user.changePwdDate != "") {
              this.$store.commit("setChangePassword", true);
              this.days = new moment(res.data.result.user.changePwdDate).diff(
                new moment(),
                "days"
              );

              if (this.days > 0 && this.days <= 10) {
                this.islogin = true;
                $("#attention-date").modal("show");
              } else if (this.days <= 0) {
                this.islogin = false;
                $("#attention-date").modal("show");
              } else {
                this.$router.push(route);
              }
            } else if (res.data.result.user.isOutsider == 1) {
              this.$store.commit("setChangePassword", true);
              this.$router.push(route);
            } else {
              this.$store.commit("setChangePassword", false);
              this.$router.push(route);
            }
            this.getOkrNotice();
          } else {
            $("#attention-delete").hide();
            if(res.data.message == '安全性的疑慮，已寄送驗證碼至您的mail') {
              this.$eventBus.$emit("showAlert", res.data.message + '：' + res.data.result.empEmail);
              this.verifyStatus = res.data.result.verifyStatus;
            } else {
              this.$eventBus.$emit("showAlert", res.data.message);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getOkrNotice() {
      api.okr.v2.rater.notice()
        .then((res) => {
          this.$store.commit("setOkrNotice", res.data.result);
        })
    },
    goLogin() {
      this.$router.push("/");
    },
    midify() {
      this.$refs.midify.showMidifyBox();
    },
    forgetPwd() {
      api.login.forgetPassword({userid: this.userName})
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", `信件已寄出至您的mail：${res.data.result}`);
            return
          }
          if(res.data.message == "無設定email寄送通知") {
            this.$eventBus.$emit("showAlert", "未設定信箱無法寄送新密碼，請聯繫相關人員處理");
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        })
    },
  },
};
</script>
