<template>
  <!-- 更改密碼 -->
  <div class="modal fade" ref="midify_password" id="midify_password">
    <div class="modal-dialog" style="overflow: hidden">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>

          <div class="nav-tabs-custom" id="pwTabs">
            <ul class="nav nav-tabs">
              <li
                v-on:click="changeTab('pwIn')"
                v-bind:class="{ active: tab == 'pwIn' }"
                v-if="isADInsider"
              >
                <a href="javascript:;">
                  {{ user.isOutsider != 1 ? '內網' : '修改' }}
                  密碼
                </a>
              </li>
              <li
                v-on:click="changeTab('pwOut')"
                v-bind:class="{ active: tab == 'pwOut' }"
                v-if="isADOutsider == 1"
              >
                <a href="javascript:;">外網密碼</a>
              </li>
              <li
                v-on:click="changeTab('info')"
                v-bind:class="{ active: tab == 'info' }"
                v-if="user.isOutsider == 1"
                >
                <a href="javascript:;">個人資料修改</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="login-box-body">
          <form>
            <div class="form-group has-feedback">
              <div>
                <input
                  class="form-control"
                  placeholder="帳號"
                  type="text"
                  v-model="user.userid"
                  disabled="disabled"
                />
                <span
                  class="glyphicon glyphicon-user form-control-feedback"
                ></span>
              </div>
            </div>
            <template v-if="tab == 'info'">
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="信箱"
                  type="email"
                  v-model="email"
                />
                <span
                  class="glyphicon glyphicon-lock form-control-feedback"
                ></span>
              </div>
            </template>
            <template v-else>
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  :placeholder="passwordTip"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                />
                <span
                  :class="['feedback-btn glyphicon glyphicon-eye-'+(showPassword? 'open' : 'close')]"
                  @click="showPassword = !showPassword"
                ></span>
              </div>
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="新密碼"
                  :type="showNewPassword ? 'text' : 'password'"
                  v-model="newPassword"
                  />
                  <span
                  :class="['feedback-btn glyphicon glyphicon-eye-'+(showNewPassword? 'open' : 'close')]"
                  @click="showNewPassword = !showNewPassword"
                ></span>
              </div>
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="再次輸入新密碼"
                  :type="showChkPassword ? 'text' : 'password'"
                  v-model="chkPassword"
                />
                <span
                  :class="['feedback-btn glyphicon glyphicon-eye-'+(showChkPassword? 'open' : 'close')]"
                  @click="showChkPassword = !showChkPassword"
                ></span>
              </div>
            </template>
          </form>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-success btn-block btn-flat"
            type="button"
            @click="changePassword()"
          >
            確定更改
          </button>
          <button
            class="btn btn-default btn-block btn-flat"
            type="button"
            data-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props: {
    proplogin: true,
  },
  data() {
    return {
      isADInsider: false,
      isADOutsider: false,
      password: "",
      newPassword: "",
      chkPassword: "",
      user: [],
      tab: "pwIn",
      passwordTip: "原內網密碼",
      email: '',
      showPassword: false,
      showNewPassword: false,
      showChkPassword: false,
    };
  },
  watch: {
    proplogin(value) {
      this.proplogin = value;
    },
  },
  mounted() {},
  methods: {
    checkADOutsider() {
      api.login.checkADOutsider().then((res) => {
        this.isADOutsider = res.data.result  == "1" ? true : false;
      })
    },
    showMidifyBox(tab) {
      this.checkADOutsider();
      this.tab = tab || "pwIn";
      this.newPassword = "";
      this.chkPassword = "";
      this.password = "";
      this.user = this.$store.state.loginUser;
      this.isADInsider = this.$store.state.changePassword;
      this.email = this.user.empEmail;
      if (!this.isADInsider) {
        this.tab = "pwOut";
        this.passwordTip = "原外網密碼";
      }
      if(this.user.isOutsider) this.passwordTip = "原密碼";
      $(this.$refs.midify_password).modal("show");
    },
    changeTab(tab) {
      this.newPassword = "";
      this.chkPassword = "";
      this.password = "";
      switch(tab){
        case "pwOut":
        this.passwordTip = "原外網密碼";
          break;
        case "pwIn":
        this.passwordTip = this.user.isOutsider ? "原密碼" : "原內網密碼";
          break;
        case "info":
        this.passwordTip = "個人資料修改";
          break;
      }
      this.tab = tab;
    },
    //確定更改
    changePassword() {
      if (
        this.tab != 'info' &&
        (this.newPassword == "" ||
        this.chkPassword == "" ||
        this.password == "")
      ) {
        this.$eventBus.$emit("showAlert", "請確認欄位是否都有填寫!!");
        return;
      }

      if (this.tab != 'info' && (this.newPassword != this.chkPassword)) {
        this.$eventBus.$emit("showAlert", "新密碼與確認新密碼不一致");
        return;
      }

      if(this.tab == 'info' && this.email == "") {
        this.$eventBus.$emit("showAlert", "信箱不得為空");
        return;
      }

      if (this.tab == "pwIn") {
        api.login
          .changePassword({
            userid: this.user.userid,
            password: this.password,
            newPassword: this.newPassword,
          })
          .then((res) => {
            if (res.data.status == "1") {
              this.newPassword = "";
              this.chkPassword = "";
              this.password = "";
              this.$eventBus.$emit("showAlert", "密碼修改成功!!");
              $(".modal-backdrop").hide();
              $(this.$refs.midify_password).modal("hide");
              // if (this.proplogin) this.$router.push("/login");
              // else this.$router.push("/");
            } else {
              this.$eventBus.$emit("showAlert", res.data.message);
              $("#attention-delete").hide();
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      } else if (this.tab == "pwOut") {
        api.login
          .changePassword({
            userid: this.user.userid,
            password: this.password,
            newPassword: this.newPassword,
            OutsideADPwd: 1,
          })
          .then((res) => {
            if (res.data.status == "1") {
              this.$eventBus.$emit(
                "showConfirm",
                "更密碼修改成功!!",
                () => {
                  this.newPassword = "";
                  this.chkPassword = "";
                  this.password = "";
                  $(this.$refs.midify_password).modal("hide");
                }
              );
            } else {
              this.$eventBus.$emit("showAlert", res.data.message);
              $("#attention-delete").hide();
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      } else if (this.tab == "info") {
        api.login
          .updateProfile({
            email: this.email
          })
          .then((res) => {
            if (res.data.status == "1") {
              this.user.empEmail = this.email;
              this.$store.commit("setLoginUser", this.user);
              this.$eventBus.$emit("showAlert", "信箱修改成功!!");
            } else {
              this.$eventBus.$emit("showAlert", res.data.message);
              $("#attention-delete").hide();
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    },
  },
};
</script>