var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "OfficeSetting" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("辦公室管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("系統管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("辦公室管理")]),
      ]),
    ]),
    !_vm.hasEditPermission
      ? _c("section", { staticClass: "content seating" }, [_vm._m(0)])
      : _vm._e(),
    _vm.hasEditPermission
      ? _c("section", { staticClass: "content seating" }, [
          _c("div", { staticClass: "box box-warning max850" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" 新增辦公室\n          "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-bordered",
                    attrs: { id: "office" },
                  },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(1),
                        _vm._l(_vm.office, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              item.editor == 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "m-0",
                                      attrs: { id: "name" + item.id },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.office) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.editor != 0
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.office,
                                        expression: "item.office",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      required: "required",
                                      type: "text",
                                    },
                                    domProps: { value: item.office },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "office",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("td", [
                              item.editor == 0
                                ? _c("p", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.companyNameList[
                                            item.companyAreaId
                                          ]
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                              item.editor != 0
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.companyAreaId,
                                          expression: "item.companyAreaId",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            item,
                                            "companyAreaId",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.companyAreaList,
                                      function (area, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: {
                                              value: area.companyAreaId,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(area.companyAreaName) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              item.editor == 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "m-0",
                                      attrs: { id: "name" + item.id },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.orderNum) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.editor != 0
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.orderNum,
                                        expression: "item.orderNum",
                                      },
                                    ],
                                    staticClass: "form-control text-center w90",
                                    attrs: {
                                      required: "required",
                                      type: "number",
                                    },
                                    domProps: { value: item.orderNum },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "orderNum",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.status,
                                      expression: "item.status",
                                    },
                                  ],
                                  attrs: {
                                    "false-value": 0,
                                    id: "check" + item.id,
                                    "true-value": 1,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.status)
                                      ? _vm._i(item.status, null) > -1
                                      : _vm._q(item.status, 1),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.status,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "status",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "status",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "status", $$c)
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    ],
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "check" + item.id },
                                }),
                              ]),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.isMealCount,
                                      expression: "item.isMealCount",
                                    },
                                  ],
                                  attrs: {
                                    "false-value": 0,
                                    id: "checkMeal" + item.id,
                                    "true-value": 1,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.isMealCount)
                                      ? _vm._i(item.isMealCount, null) > -1
                                      : _vm._q(item.isMealCount, 1),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.isMealCount,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "isMealCount",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "isMealCount",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "isMealCount", $$c)
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    ],
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "checkMeal" + item.id },
                                }),
                              ]),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              item.editor == 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.change(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    修改\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.editor != 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success btn-save",
                                      on: {
                                        click: function ($event) {
                                          return _vm.save(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    儲存\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                   \n                  "
                              ),
                              item.editor == 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteType(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    刪除\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.editor != 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-default",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancel(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    取消\n                  "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _c(
                "p",
                { staticClass: "mt-15" },
                [
                  _c("i", { staticClass: "text-orange fa fa-warning" }),
                  _vm._v(" 友善連結：【"),
                  _c("router-link", { attrs: { to: "Seating" } }, [
                    _vm._v("座位表"),
                  ]),
                  _vm._v("】、【"),
                  _c("router-link", { attrs: { to: "Account" } }, [
                    _vm._v("帳號設定"),
                  ]),
                  _vm._v("】。\n        "),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red mt-10" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v("\n      無管理權限\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "30%" } },
        [_vm._v("名稱")]
      ),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("位置")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("排序")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("\n                  啟用狀態\n                ")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("\n                  使用訂餐統計\n                ")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "20%" },
        },
        [_vm._v("\n                  功能\n                ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }