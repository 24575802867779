var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.announcementtypes.typeName == "內部公告"
                ? _vm.announcementtypes.typeName +
                    "(行政公告/其他公告/EIP留存公告)"
                : _vm.announcementtypes.typeName
            ) +
            "\n    "
        ),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公司公告")]),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.announcementtypes.typeName)),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _vm.checkPermission("AN-03")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" 新增公告\n          "),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.target,
                      expression: "searchData.target",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "target",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.workShowAll(true)
                      },
                    ],
                  },
                },
                _vm._l(_vm.targetList, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.targetId } },
                    [_vm._v(_vm._s(item.targetName))]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.issuedUnit,
                      expression: "searchData.issuedUnit",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "issuedUnit",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.workShowAll()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部單位")]),
                  _vm._l(_vm.issuedUnitList, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.issuedUnit } },
                      [_vm._v(_vm._s(item.issuedUnitName))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.keyword,
                    expression: "searchData.keyword",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋公告主旨",
                  type: "text",
                },
                domProps: { value: _vm.searchData.keyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "keyword", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.announcements.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "5%" } },
                        [_vm._v("發布日期")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "5%" } },
                        [_vm._v("發布時間")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v("公告文號")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v("公告單位")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "5%" },
                        },
                        [_vm._v("公告聯絡人")]
                      ),
                      _vm.checkPermission("AN-03")
                        ? _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "5%" },
                            },
                            [
                              _vm._v(
                                "\n                  承辦人\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("th", { staticStyle: { "min-width": "150px" } }, [
                        _vm._v("公告主旨"),
                      ]),
                      _c("th", { staticClass: "text-nowrap text-center" }, [
                        _vm._v("詳閱檔案"),
                      ]),
                      _vm.checkPermission("AN-03")
                        ? _c(
                            "th",
                            {
                              staticClass: "text-center",
                              staticStyle: { "min-width": "60px", width: "5%" },
                            },
                            [
                              _vm._v(
                                "\n                  狀態\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.checkPermission("AN-03")
                        ? _c(
                            "th",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: {
                                "min-width": "150px",
                                width: "10%",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  功能\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm.announcements.total > 0
                      ? _vm._l(_vm.announcements.datas, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    item.status != "1"
                                      ? item.publishTime.split("T")[0]
                                      : "--"
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    item.status != "1"
                                      ? item.publishTime.split(/[T.]/)[1]
                                      : "--"
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.docNo))]),
                            _c("td", [_vm._v(_vm._s(item.issuedUnitName))]),
                            _c("td", [_vm._v(_vm._s(item.contactEmpName))]),
                            _vm.checkPermission("AN-03")
                              ? _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(item.contractorName) +
                                      "\n                  "
                                  ),
                                ])
                              : _vm._e(),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAnnouncement(item.id)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _vm._l(item.files, function (file) {
                                  return _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.files.length > 0,
                                          expression: "item.files.length > 0",
                                        },
                                      ],
                                      key: file.id,
                                      staticClass: "file-icon",
                                      attrs: {
                                        href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        class:
                                          "icon icon-" +
                                          file.mine.substring(0, 3),
                                      }),
                                    ]
                                  )
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.files.length == 0,
                                        expression: "item.files.length == 0",
                                      },
                                    ],
                                  },
                                  [_vm._v("-")]
                                ),
                              ],
                              2
                            ),
                            _vm.checkPermission("AN-03")
                              ? _c("td", { staticClass: "text-center" }, [
                                  item.status < 2
                                    ? _c("span", { staticClass: "text-red" }, [
                                        _vm._v("未發布"),
                                      ])
                                    : _c("span", [_vm._v("已發布")]),
                                ])
                              : _vm._e(),
                            _vm.checkPermission("AN-03")
                              ? _c("td", { staticClass: "text-center" }, [
                                  _vm.checkUserInfo(item.target)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editorItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      修改\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                     \n                    "
                                  ),
                                  _vm.checkUserInfo(item.target)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      刪除\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        })
                      : [_vm._m(0)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.announcements.total > 0,
                expression: "announcements.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }