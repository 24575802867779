<template>
  <div id="Account">
    <section class="content-header">
      <h1>帳號設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li>帳號管理</li>
        <li class="active">帳號設定</li>
      </ol>
    </section>
    <section class="content account">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-select">
            <div class="right clearfix">
              <button
                type="button"
                class="btn btn-block btn-danger btn-add"
                @click="searchNoSetOffice('1')"
                v-if="searchKey.checkOffice == ''"
              >
                <i class="fa fa-warning"></i> 未設定辦公室
              </button>
              <button
                type="button"
                class="btn btn-block btn-info btn-add"
                @click="searchNoSetOffice('')"
                v-else
              >
                關閉尚未設定
                <!-- <i class="fa fa-warning"></i>  -->
              </button>

              <div class="synchronize">
                <input
                  type="text"
                  class="form-control"
                  placeholder="輸入員工編號"
                  v-model="employeeId"
                />
                <button class="btn btn-success" type="button" @click="SyncData">
                  <i class="fa fa-arrow-circle-down"></i> 同步人事資料
                </button>
              </div>
            </div>

            <div class="left clearfix">
              <div class="select-group" v-if="$user.getUserCompanyId() === 'A'">
                <select
                  class="form-control"
                  v-model="searchKey.companyId"
                  v-on:change="selectCompany()"
                >
                  <option
                    :key="index"
                    :value="company.companyId"
                    v-for="(company, index) in companyOptions"
                  >
                    {{ company.companyName }}
                  </option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectUnit"
                  required="required"
                  v-model="searchKey.deptId"
                  v-on:change="selectDept()"
                >
                  <option value>請選擇部門</option>
                  <option
                    :key="index"
                    :value="dept.deptId"
                    v-for="(dept, index) in depts"
                  >
                    {{ dept.deptIdName }}
                  </option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  name="table_search"
                  placeholder="搜尋姓名"
                  type="text"
                  v-model="searchKey.keyword"
                />
                <div class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="search">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>

              <div class="checkbox" style="display: inline-block; margin: 6px 10px;">
                <label>
                  <iCheckCheckBox
                    :propValue="true"
                    :propChecked="searchKey.includeLeft"
                    v-on:update:checked="checkAll"
                  />
                </label>
                包含離職
              </div>
            </div>
            <div class="text">
              <p class="data-num">資料總筆數：{{ total }}</p>
              <!-- <p class="data-num">搜尋結果：211</p> -->
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered table-striped min-800"
            >
              <tbody>
                <tr class="bg-gray">
                  <!-- <th style="min-width: 90px">到職日期</th> -->
                  <th style="min-width: 90px">員工狀態</th>
                  <th style="min-width: 90px">帳號</th>
                  <th style="min-width: 80px">工號</th>
                  <th style="min-width: 80px">姓名</th>
                  <!-- <th>公司</th> -->
                  <th>部門</th>
                  <th>辦公室</th>
                  <!-- <th>職等</th> -->
                  <th>職稱</th>
                  <th>電子郵件</th>
                  <th style="min-width: 150px">最後登入時間</th>
                  <!-- <th class="text-center">帳號狀態</th> -->
                  <th class="text-nowrap text-center" style="min-width: 100px">
                    功能
                  </th>
                </tr>
                <tr :key="index" v-for="(emp, index) in queryEmployeeAll.datas">
                  <!-- <td>2019-01-01</td> -->
                  <td>{{ emp.employeeTypeName }}</td>
                  <td>{{ emp.userid }}</td>
                  <td>{{ emp.employeeId }}</td>
                  <td>{{ emp.employeeName }}</td>
                  <!-- <td>網銀國際</td> -->
                  <td>{{ emp.deptName }}</td>
                  <td v-if="emp.office != null && emp.office != ''">
                    {{ emp.office }}
                  </td>
                  <td class="text-red" v-else>尚未設定</td>
                  <!-- <td>2</td> -->
                  <td>{{ emp.empTitleName }}</td>
                  <td>{{ emp.empEmail }}</td>
                  <td>{{ formatTime(emp.loginTime) }}</td>
                  <!-- <td class="text-center">
                    <span class="text-green">
                      <i class="fa fa-check"></i> 啟用
                    </span>
                  </td>-->
                  <td class="text-center">
                    <button
                      class="btn btn-primary"
                      @click="openEditWindow(emp.employeeId)"
                    >
                      編輯
                    </button>
                    <!-- &nbsp;
                    <button class="btn btn-info">
                      <i class="fa fa-lock"></i> 個人權限
                    </button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination
            :total="total"
            :pageLimitCount="searchKey.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!-- <div class="box box-warning">
        <div class="box-header with-border">
          <h3 class="box-title">編輯個人權限</h3>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body">
              <h4>網銀國際／ 企劃部／星寶／2職等／組長</h4>
              <div class="table-responsive mt-10">
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>
                        公告管理
                        <br />
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 人事公告
                          </label>
                        </div>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 行政公告
                          </label>
                        </div>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 福委會公告
                          </label>
                        </div>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 其他公告
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 會議室管理
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 公司通訊錄
                          </label>
                        </div>
                        <ol>
                          <li>座位表</li>
                          <li>分機表</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 訂餐管理
                          </label>
                        </div>
                        <ol>
                          <li>店家管理</li>
                          <li>訂單管理</li>
                          <li>訂餐設定</li>
                          <li>訂餐統計</li>
                          <li>個人飲食習慣</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 公司文件
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 公司產品
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        E-Learning
                        <br />
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 新人教育訓練
                          </label>
                        </div>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 網銀人成長講座
                          </label>
                        </div>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> e訂餐管理
                          </label>
                        </div>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> e店家管理
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="checkbox-inline">
                          <label>
                            <input class="flat" type="checkbox" /> 系統管理
                          </label>
                        </div>
                        <ol>
                          <li>帳號管理</li>
                          <li>部門管理</li>
                          <li>職稱管理</li>
                          <li>財編管理</li>
                          <li>辦公室管理</li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary" type="submit">儲存</button>
                <button class="btn btn-default" type="button">返回列表</button>
              </div>
            </div>
          </form>
        </div>
      </div>-->
    </section>
    <accountEdit ref="accountEdit" v-on:refreshList="getQueryEmployeeAll" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import accountEdit from "@/views/AccountEdit";
import iCheckCheckBox from "@/components/iCheckBox";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    Pagination,
    accountEdit,
    iCheckCheckBox,
  },
  data() {
    return {
      depts: [],
      companyOptions: [],
      searchKey: {
        companyId: "", //公司id
        deptId: "", //部門Id
        keyword: "",
        checkOffice: "", //1:查詢未設定辦公室
        pageNum: 1,
        pageSize: 10,
        includeLeft: 0, // 1：包含離職 0：不包含
      },

      employeeId: "",
      queryEmployeeAll: {},

      total: 0, //總筆數
    };
  },
  async created() {
    await this.workShowAll();
    this.getQueryEmployeeAll();
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchKey.pageNum = pageIdx;
      this.searchKey.pageSize = pageSize;
      this.getQueryEmployeeAll();
    },
    async workShowAll() {
      //*----公司
      var task_company = api.organization.getCompanyList();
      var response_company = await task_company;
      this.companyOptions = response_company.data.result;
      this.searchKey.companyId = this.$user.getUserCompanyId();
      this.selectCompany();
    },
    async selectCompany() {
      this.empOptions = [];
      var task_deps = api.organization.getDeptList({
        companyId: this.searchKey.companyId,
      });
      var response_desps = await task_deps;
      this.depts = response_desps.data.result;
      if (this.depts.length == 0) {
        this.searchKey.deptId = "";
        return;
      }
      await this.selectDept();
    },
    //*---- 切換部門時替換員工資料
    async selectDept() {
      var task_emps = api.organization.getDeptEmpList({
        deptId: this.searchKey.deptId,
      });
      var response_emps = await task_emps;
      this.empOptions = response_emps.data.result;
    },
    search() {
      this.searchKey.pageNum = 1;
      this.searchKey.pageSize = 10;
      this.getQueryEmployeeAll();
    },
    getQueryEmployeeAll() {
      api.organization.queryEmployeeAllEdit(this.searchKey).then((res) => {
        if (res.data.status == "1") {
          this.queryEmployeeAll = res.data.result;
          this.total = this.queryEmployeeAll.total;
        }
      });
    },
    searchNoSetOffice(value) {
      this.searchKey.pageNum = 1;
      this.searchKey.checkOffice = value;
      this.getQueryEmployeeAll();
    },
    openEditWindow(id) {
      this.$refs.accountEdit.openEditWindow(id);
    },
    formatTime: function (t) {
      return utils.formatTime(t, "YYYY/MM/DD hh:mm:ss");
    },
    checkAll(data) {
      this.searchKey.includeLeft = data.checked
      this.getQueryEmployeeAll();
    },
    SyncData() {
      api.login
        .syncempdata({
          employeeId: this.employeeId,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.$eventBus.$emit("showAlert", "同步成功");
            this.getQueryEmployeeAll();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        });
    },
  },
};
</script>
