<template>
  <!-- Left side column. contains the logo and sidebar -->
  <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <div class="company-group" v-if="companyChangeList.length > 0">
        <label for="currentCompany">目前公司：</label>
        <select
          class="form-control"
          id="currentCompany"
          v-model="currentCompany"
        >
          <option
            :value="item.companyId"
            v-for="(item, index) in companyChangeList"
            :key="index"
          >
            {{ item.companyName }}
          </option>
        </select>
      </div>
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul class="sidebar-menu" data-widget="tree">
        <li>
          <router-link to="/Home">
            <i class="fa fa-home"></i>
            <span>首頁</span>
          </router-link>
        </li>
        <li
          :class="subMenu(item)"
          :data-v="item.isVueMenu"
          :key="index"
          v-for="(item, index) in menus"
        >
          <template v-if="item.isVueMenu == 1">
            <a href="#" v-if="item.subMenu.length > 0">
              <!-- 	公司公告 -->
              <i class="fa fa-bullhorn" v-if="item.menuId == 'AN'"></i>
              <!-- 公司文件 -->
              <i class="fa fa-file-text-o" v-else-if="item.menuId == 'F0'"></i>
              <!-- 簽核管理 -->
              <i
                class="fa fa-pencil-square-o"
                v-else-if="item.menuId == 'S0'"
              ></i>
              <!-- 績效考核 -->
              <i class="fa fa-paste" v-else-if="item.menuId == 'C0'"></i>
              <!-- 場地申請 -->
              <i class="fa fa-building-o" v-else-if="item.menuId == 'B0'"></i>
              <!-- 公司通訊錄 -->
              <i class="fa fa-book" v-else-if="item.menuId == 'D0'"></i>
              <!-- 訂餐服務 -->
              <i class="fa fa-cutlery" v-else-if="item.menuId == 'E0'"></i>
              <!-- 社團活動 -->
              <i class="fa fa-futbol-o" v-else-if="item.menuId == 'K0'"></i>
              <!-- 系統管理 -->
              <i class="fa fa-cogs" v-else-if="item.menuId == 'Z0'"></i>
              <!-- 打卡鐘 -->
              <i class="fa fa-clock-o" v-else-if="item.menuId == 'T0'"></i>
              <!-- 社團活動 -->
              <i class="fa fa-futbol-o" v-else-if="item.menuId == 'AT'"></i>
              <!-- 教育訓練 -->
              <i class="fa fa-graduation-cap" v-else-if="item.menuId == 'ET'"></i>
              <!-- 公司文件 -->
              <i class="fa fa-book" v-else-if="item.menuId == 'CT'"></i>
              <!-- 其他 / 預設 -->
              <i class="fa fa-circle" v-else></i>
              <span>{{ item.menuName }}</span>
              <span
                class="pull-right-container"
                style="transform: scale(0.7); color: #0073b7; right: 30px"
                v-if="showNotice(item)"
              >
                <i class="fa fa-circle pull-right"></i>
              </span>
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <router-link :to="{ path: item.menuUrl }" v-else>
              <!-- 公司文件 -->
              <i class="fa fa-file-text-o" v-if="item.menuId == 'F0'"></i>
              <!-- 公司郵件 -->
              <i class="fa fa-envelope-o" v-else-if="item.menuId == 'G0'"></i>
              <!-- 電子簽核 -->
              <i
                class="fa fa-calendar-check-o"
                v-else-if="item.menuId == 'H0'"
              ></i>
              <!-- 公司產品 -->
              <i class="fa fa-star" v-else-if="item.menuId == 'J0'"></i>
              <!-- 社團活動 -->
              <i class="fa fa-futbol-o" v-else-if="item.menuId == 'K0'"></i>
              <!-- E-Learning -->
              <i class="fa fa-lightbulb-o" v-else-if="item.menuId == 'L0'"></i>
              <!-- 線上點名 -->
              <i class="fa fa-user" v-else-if="item.menuId == 'R0'"></i>
              <!-- 打卡鐘 -->
              <i class="fa fa-clock-o" v-else-if="item.menuId == 'T0'"></i>
              <!-- 特約商店 -->
              <i
                class="fa fa-shopping-cart"
                v-else-if="item.menuId == 'SP'"
              ></i>
              <!-- 其他 / 預設 -->
              <i class="fa fa-circle-o" v-else></i>
              <span>{{ item.menuName }}</span>
            </router-link>
          </template>
          <template v-else>
            <a href="#" v-if="item.subMenu && item.subMenu.length > 0">
              <!-- 	公司公告 -->
              <i class="fa fa-bullhorn" v-if="item.menuId == 'AN'"></i>
              <!-- 簽核管理 -->
              <i
                class="fa fa-pencil-square-o"
                v-else-if="item.menuId == 'S0'"
              ></i>
              <!-- 績效考核 -->
              <i class="fa fa-paste" v-else-if="item.menuId == 'C0'"></i>
              <!-- 場地申請 -->
              <i class="fa fa-building-o" v-else-if="item.menuId == 'B0'"></i>
              <!-- 公司通訊錄 -->
              <i class="fa fa-book" v-else-if="item.menuId == 'D0'"></i>
              <!-- 訂餐服務 -->
              <i class="fa fa-cutlery" v-else-if="item.menuId == 'E0'"></i>
              <!-- 社團活動 -->
              <i class="fa fa-futbol-o" v-else-if="item.menuId == 'K0'"></i>
              <!-- 系統管理 -->
              <i class="fa fa-cogs" v-else-if="item.menuId == 'Z0'"></i>
              <!-- 打卡鐘 -->
              <i class="fa fa-clock-o" v-else-if="item.menuId == 'T0'"></i>
              <!-- 其他 / 預設 -->
              <i class="fa fa-circle" v-else></i>
              <span>{{ item.menuName }}</span>
              <span
                class="pull-right-container"
                style="transform: scale(0.7); color: #0073b7; right: 30px"
                v-if="showNotice(item)"
              >
                <i class="fa fa-circle pull-right"></i>
              </span>
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <template v-else>
              <a
                :href="item.menuUrl"
                v-if="item.menuUrl.substr(0, 4).toLowerCase() == 'http'"
                target="_blank"
              >
                <!-- 公司郵件 -->
                <i class="fa fa-envelope-o" v-if="item.menuId == 'G0'"></i>
                <!-- 電子簽核 -->
                <i
                  class="fa fa-calendar-check-o"
                  v-else-if="item.menuId == 'H0'"
                ></i>
                <!-- 線上點名 -->
                <i class="fa fa-user" v-else-if="item.menuId == 'R0'"></i>
                <!-- 其他 / 預設 -->
                <i class="fa fa-circle-o" v-else></i>
                <span>{{ item.menuName }}</span>
              </a>
              <a :href="item.menuUrl" v-else>
                <!-- 公司文件 -->
                <i class="fa fa-file-text-o" v-if="item.menuId == 'F0'"></i>
                <!-- 公司產品 -->
                <i class="fa fa-star" v-else-if="item.menuId == 'J0'"></i>
                <!-- 社團活動 -->
                <i class="fa fa-futbol-o" v-else-if="item.menuId == 'K0'"></i>
                <!-- E-Learning -->
                <i
                  class="fa fa-lightbulb-o"
                  v-else-if="item.menuId == 'L0'"
                ></i>
                <!-- 打卡鐘 -->
                <i class="fa fa-clock-o" v-else-if="item.menuId == 'T0'"></i>
                <!-- 其他 / 預設 -->
                <i class="fa fa-circle-o" v-else></i>
                <span>{{ item.menuName }}</span>
              </a>
            </template>
          </template>

          <!-- 第二層 -->
          <template v-if="item.subMenu && item.subMenu.length > 0">
            <ul class="treeview-menu">
              <li
                :class="subMenu2(item2)"
                :key="index"
                v-for="(item2, index) in item.subMenu"
              >
                <template v-if="item2.isVueMenu == 1">
                  <a href="#" v-if="item2.subMenu.length > 0">
                    <!-- 	訂餐服務管理 -->
                    <i class="fa fa-gear" v-if="item2.menuId == 'E0-01'"></i>
                    <!-- 	帳號管理 -->
                    <i
                      class="fa fa-cog"
                      v-else-if="item2.menuId == 'Z0-01'"
                    ></i>
                    <!-- 	權限管理 -->
                    <i
                      class="fa fa-cog"
                      v-else-if="item2.menuId == 'Z0-02'"
                    ></i>
                    <!-- 活動管理 -->
                    <i class="fa fa-cog" v-else-if="item2.menuId == 'AT-003'"></i>
                    <!-- 其他 / 預設 -->
                    <i class="fa fa-circle" v-else></i>
                    <span>{{ item2.menuName }}</span>
                    <span class="pull-right-container">
                      <i class="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <template v-else>
                    <router-link :to="{ path: item2.menuUrl }" v-show="item2.menuId != 'AN-02'"
                      :data-test="item2.menuUrl"
                      :data-test2="$route.path"
                      :class="{
                        'router-link-active':
                          (item2.menuUrl.includes('/All/') &&
                            item2.menuUrl.includes($route.path) &&
                            $route.path != '/OKR/Score') ||
                          (item2.menuName == '必修課程' &&
                            $route.path.indexOf('/Student/Course/Required') > -1) ||
                          (item2.menuName == '選修課程' &&
                            $route.path.indexOf('/Student/Course/Elective') > -1),
                      }"
                    >
                      <!-- 	公告類別設定 -->
                      <i
                        :menuId="item2.menuId"
                        class="fa fa-cog"
                        v-if="item2.menuId == 'AN-01'"
                      ></i>
                      <!-- 	文件類別設定 -->
                      <i
                        class="fa fa-cog"
                        v-else-if="item2.menuId == 'F0-01'"
                      ></i>
                      <!-- 	辦公室管理 -->
                      <i
                        class="fa fa-cog"
                        v-else-if="item2.menuId == 'Z0-03'"
                      ></i>
                      <!-- 	辦公室管理 -->
                      <i class="fa fa-gear" v-else-if="item2.menuId == 'ET-0' || item2.menuId == 'ET-5'"></i>
                      <!-- 其他 / 預設 -->
                      <i class="fa fa-circle-o" v-else></i>
                      <span>{{ item2.menuName }}</span>
                      <span
                        v-if="item2.menuName === '收件匣'"
                        class="menuNum"
                        :class="{ hide: flowNoticeTotal === 0 }"
                      >
                        {{ flowNoticeTotal }}
                      </span>
                      <span
                        v-if="item2.menuName === '考核紀錄'"
                        class="menuNum"
                        :class="{ hide: okrNoticeTotal == 0 }"
                      >
                        {{ okrNoticeTotal }}
                      </span>
                      <span
                        v-if="item2.menuName === '必修課程'"
                        class="menuNum"
                        :class="{ hide: educationNoticeTotal == 0 }"
                      >
                        {{ educationNoticeTotal }}
                      </span>
                    </router-link>
                  </template>
                </template>
                <template v-else>
                  <a href="#" v-if="item2.subMenu && item2.subMenu.length > 0">
                    <!-- 	帳號管理 -->
                    <i class="fa fa-cog" v-if="item2.menuId == 'Z0-01'"></i>
                    <!-- 	權限管理 -->
                    <i
                      class="fa fa-cog"
                      v-else-if="item2.menuId == 'Z0-02'"
                    ></i>
                    <!-- 其他 / 預設 -->
                    <i class="fa fa-circle" v-else></i>
                    <span>{{ item2.menuName }}</span>
                    <span class="pull-right-container">
                      <i class="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <a :href="item2.menuUrl" v-else>
                    <!-- 	帳號管理 -->
                    <i class="fa fa-cog" v-if="item2.menuId == 'Z0-01'"></i>
                    <!-- 	權限管理 -->
                    <i
                      class="fa fa-cog"
                      v-else-if="item2.menuId == 'Z0-02'"
                    ></i>
                    <!-- 其他 / 預設 -->
                    <i class="fa fa-circle-o" v-else></i>
                    <span>{{ item2.menuName }}</span>
                  </a>
                </template>

                <!-- 第三層 -->
                <template v-if="item2.subMenu && item2.subMenu.length > 0">
                  <ul class="treeview-menu">
                    <li :key="index" v-for="(item3, index) in item2.subMenu">
                      <router-link :to="{ path: item3.menuUrl }" v-if="item3.isVueMenu == 1"
                        :class="{ 'router-link-active': item3.menuUrl.includes('/All/') && item3.menuUrl.includes($route.path) }">
                        <i class="fa fa-dollar" v-if="item3.menuId == 'E0-017'"></i>
                        <i class="fa fa-circle-o" v-else></i>
                        <span>{{ item3.menuName }}</span>
                      </router-link>
                      <a :href="item3.menuUrl" v-else>
                        <i class="fa fa-circle-o"></i>
                        <span>{{ item3.menuName }}</span>
                      </a>
                    </li>
                  </ul>
                </template>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import api from "@/assets/js/app-api";
export default {
  data() {
    return {
      menus: [],
      flowNoticeTotal: 0,
      okrNotice: [],
      okrNoticeTotal: 0,
      educationNotice: [],
      educationNoticeTotal: 0,
      currentCompany: "",
      companyChangeList: [],
    };
  },
  watch: {
    $route() {
      this.getMenuNotice();
    },
    currentCompany(newVal, oldVal) {
      if (newVal && oldVal) {
        this.changeCurrentCompany(newVal);
      }
    },
  },
  mounted() {
    var Selector = {
      wrapper: ".wrapper",
      contentWrapper: ".content-wrapper",
      layoutBoxed: ".layout-boxed",
      mainFooter: ".main-footer",
      mainHeader: ".main-header",
      mainSidebar: ".main-sidebar",
      slimScrollDiv: "slimScrollDiv",
      sidebar: ".sidebar",
      controlSidebar: ".control-sidebar",
      fixed: ".fixed",
      sidebarMenu: ".sidebar-menu",
      logo: ".main-header .logo",
    };
    if ($(Selector.mainSidebar).find(Selector.slimScrollDiv).length === 0) {
      $(Selector.sidebar).slimScroll({
        height: $(window).height() - $(Selector.mainHeader).height() + "px",
      });
    }

    if (this.$store.state.loginUserMenu == "") {
      return;
    }
    //馬上初始化會有問題.
    setTimeout(() => {
      $("ul").tree();
      this.menus = this.$store.state.loginUserMenu;
      this.okrNotice = this.$store.state.okrNotice;
      this.okrNoticeTotal =
        this.okrNotice.addCount + this.okrNotice.receiveCount;
      this.setCurrentCompanyDatas();
      this.setDefaultMenu();
    }, 500);
    this.getMenuNotice();
  },
  methods: {
    changeCurrentCompany(val) {
      api.permission.companyChange({
          CompanyId: val,
        }).then((res) => {
          if (res.data.status) window.location.href = "/";
        });
    },
    setCurrentCompanyDatas() {
      this.currentCompany = this.$store.state.loginUser.companyId;
      this.companyChangeList = this.$store.state.loginUserCompany;
      this.$forceUpdate();
    },
    subMenu(item) {
      if (item.subMenu.length > 0) {
        return "treeview";
      }
    },
    subMenu2(item2) {
      if (item2.subMenu.length > 0) {
        return "treeview";
      }
    },
    setDefaultMenu() {
      this.$nextTick(() => {
        if (
          this.$route.name.includes("ETCourse") ||
          this.$route.name.includes("ETChapter") ||
          this.$route.name.includes("ETStudent")
        ) {
          // 教育訓練
          $(".fa-graduation-cap").trigger("click");
        } else {
          $(".fa-pencil-square-o").trigger("click");
        }
      });
    },
    getMenuNotice() {
      // 簽核管理
      if (this.$user.hasPermission("S0")) {
        api.okr.mgmt.sign.count().then((res) => {
          if (res.data.status == "1") {
            this.$store.commit("setFlowNotice", res.data.result);
            this.flowNoticeTotal = this.$store.state.flowNoticeTotal;
          }
        });
      }

      // 績效考核
      if (this.$user.hasPermission("C0")) {
        api.okr.v2.rater.notice().then((res) => {
          this.$store.commit("setOkrNotice", res.data.result);
          this.okrNotice = this.$store.state.okrNotice;
          this.okrNoticeTotal =
            this.okrNotice.addCount + this.okrNotice.receiveCount;
        });
      }

      // 教育訓練
      if (this.$user.hasPermission("ET")) {
        api.education.notice().then((res) => {
          if (res.data.status == 1) {
            const courseRecord = res.data.result.courseRecord;
            if (courseRecord) {
              this.$store.commit("setEducationNotice", courseRecord);
              this.educationNotice = this.$store.state.educationNotice;
              this.educationNoticeTotal = this.educationNotice.notFinishedCount;
            }
          }
        });
      }
    },
    showNotice(item) {
      if (
        (item.menuName == "簽核管理" && this.flowNoticeTotal > 0) ||
        (item.menuName == "績效考核" && this.okrNoticeTotal > 0) ||
        (item.menuName == "教育訓練" && this.educationNoticeTotal > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>