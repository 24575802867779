<template>
  <div id="YearEdit">
    <section class="content-header">
      <h1>年度目標</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/OKR/YearList">年度目標</router-link>
        </li>
        <li class="active">
          {{ !id ? "新增" : disabled ? "檢視" : "編輯" }}目標
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : disabled ? "檢視" : "編輯" }}目標
            </h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <form
            class="form-horizontal"
            method="post"
            @submit.prevent="saveData()"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  年度：
                </label>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    :disabled="disabled"
                    v-model.number="data.year"
                  >
                    <option
                      v-for="(year, index) in years"
                      :key="index"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  核心目標：
                </label>
                <div class="col-sm-3">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox
                      propValue="F"
                      :propNone="!edit"
                      :propChecked="data.codeAry.includes('F')"
                      :propDisabled="disabled"
                      v-on:update:checked="codeChange"
                    />&nbsp;財務(F)
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-3">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox
                      propValue="C"
                      :propNone="!edit"
                      :propChecked="data.codeAry.includes('C')"
                      :propDisabled="disabled"
                      v-on:update:checked="codeChange"
                    />&nbsp;顧客價值(C)
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-3">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox
                      propValue="P"
                      :propNone="!edit"
                      :propChecked="data.codeAry.includes('P')"
                      :propDisabled="disabled"
                      v-on:update:checked="codeChange"
                    />&nbsp;內部流程(P)
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-3">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox
                      propValue="L"
                      :propNone="!edit"
                      :propChecked="data.codeAry.includes('L')"
                      :propDisabled="disabled"
                      v-on:update:checked="codeChange"
                    />&nbsp;學習與成長(L)
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    required
                    disabled
                    v-model.trim="data.code"
                  />
                </div>
                <div class="col-sm-1" style="padding: 0">
                  <input
                    type="button"
                    value="取得"
                    class="form-control"
                    v-if="!disabled"
                    @click="getQueryCode()"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  年度目標：
                </label>
                <div class="col-sm-8">
                  <textarea
                    rows="4"
                    type="text"
                    class="form-control"
                    required
                    :disabled="disabled"
                    v-model.trim="data.objective"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <input
                  type="button"
                  value="確認"
                  class="btn btn-primary"
                  v-if="disabled"
                  @click="backList()"
                />
                <input
                  type="submit"
                  value="儲存"
                  class="btn btn-warning"
                  v-if="!disabled"
                  @click="updateData(0)"
                />
                <input
                  type="submit"
                  value="確認送出"
                  class="btn btn-primary"
                  v-if="!disabled"
                  @click="updateData(2)"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      loading: true,
      disabled: false,
      id: this.$route.params.id,
      edit: this.$route.params.edit,
      years: this.global.getYearList(),
      data: { year: this.$route.params.year, codeAry: [] },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        api.okr.goal.query({ objectiveId: this.id }).then((res) => {
          if (res.data.status === "1") {
            this.data = res.data.result || {};
            this.loading = false;
            this.disabled = this.data.status === 2;
          }
        });
      }
    },
    getQueryCode() {
      if (this.data.codeAry.length > 0) {
        if (this.data.codeAry.length <= 2) {
          api.okr.goal.year.queryCode(this.data).then((res) => {
            this.global.showResponse(this, res, null, () => {
              this.data.code = res.data.result;
              this.$forceUpdate();
            });
          });
        } else {
          this.$eventBus.$emit("showAlert", "核心目標最多選取2個！");
        }
      } else {
        this.$eventBus.$emit("showAlert", "請選取核心目標！");
      }
    },
    updateData(status) {
      this.data.status = status;
    },
    saveData() {
      if (this.data.code) {
        const action = this.id ? "update" : "create";
        api.okr.goal.year[action](this.data).then((res) => {
          this.global.showResponse(this, res, action, this.backList);
        });
      } else {
        this.$eventBus.$emit("showAlert", "請先取得核心目標編碼！");
      }
    },
    codeChange(data) {
      if (data.checked) {
        if (!this.data.codeAry.includes(data.value)) {
          this.data.code = "";
          this.data.codeAry.push(data.value);
        }
      } else {
        const index = this.data.codeAry.indexOf(data.value);
        if (index !== -1) {
          this.data.code = "";
          this.data.codeAry.splice(index, 1);
        }
      }
    },
    backList() {
      this.$router.push({
        name: "YearList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
