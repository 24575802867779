<template>
  <div id="ExpatList">
    <section class="content-header">
      <h1>人員派駐設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">人員派駐設定</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning" style="width: 1050px">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="createData()"
              >
                <i class="fa fa-plus"></i>
                新增設定
              </button>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">#</th>
                  <th class="tc" width="15%">單位</th>
                  <th class="tc" width="15%">姓名</th>
                  <th class="tc">派駐單位</th>
                  <th class="tc" width="15%">派駐辦公室</th>
                  <th class="tc">長期派駐</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="index + 1"></td>
                  <td class="tc" v-if="data.isCreate">
                    <select
                      class="form-control"
                      v-model="data.deptId"
                      @change="getUserList(data)"
                      style="width: fit-content;"
                    >
                      <option
                        v-for="(item, index) in list"
                        :key="index"
                        :value="item.deptId"
                      >
                        {{ item.companyName + " - " + item.deptName }}
                      </option>
                    </select>
                  </td>
                  <td class="tc text-nowrap" v-else>
                    {{ data.companyName + " - " + data.deptName }}
                  </td>
                  <td class="tc" v-if="data.isCreate">
                    <select class="form-control" v-model="data.employeeId">
                      <option value="">人員</option>
                      <option
                        v-for="(user, index) in users"
                        :key="index"
                        :value="user.employeeId"
                      >
                        {{ user.username }}
                      </option>
                    </select>
                  </td>
                  <td class="tc" v-else v-text="data.username"></td>
                  <td
                    class="tc"
                    v-text="data.companyId === 'J' ? '台北' : ''"
                  ></td>
                  <td class="tc" v-if="data.isEdit">
                    <select class="form-control" v-model="data.office">
                      <option value="">辦公室</option>
                      <option
                        v-for="(item, index) in offices"
                        :key="index"
                        :value="item.office"
                      >
                        {{ item.office }}
                      </option>
                    </select>
                  </td>
                  <td class="tc" v-else v-text="data.office"></td>
                  <td class="text-center" align="center">
                    <p v-if="data.isEdit">–</p>
                    <div class="ckbx-style-8 ckbx toggleInput" v-else>
                      <input
                        type="checkbox"
                        name="ckbx-style-8"
                        :id="`check${index}`"
                        :false-value="0"
                        :true-value="1"
                        v-model="data.isLong"
                        @change="toggle(data)"
                      />
                      <label :for="`check${index}`"></label>
                    </div>
                  </td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      v-show="!data.isEdit"
                      @click="updateData(data)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-danger ml10"
                      v-show="!data.isEdit"
                      @click="deleteData(data)"
                    >
                      刪除
                    </button>
                    <button
                      class="btn btn-success"
                      v-show="data.isEdit"
                      @click="saveData(data)"
                    >
                      儲存
                    </button>
                    <button
                      class="btn btn-default ml10"
                      v-show="data.isEdit"
                      @click="cancelData(data)"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
      },
      list: [],
      users: [],
      offices: [],
      datas: [],
      data: {},
      orginData: {},
      userData: {},
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getList();
  },
  methods: {
    getList() {
      api.organization.getDeptList({}).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.getUserList({});
          this.getOfficeList();
          this.getDataList();
        }
      });
    },
    getUserList(data) {
      api.organization
        .queryEmployeeAll({
          deptId: data.deptId || "",
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result.datas || [];
            data.employeeId = "";
          }
        });
    },
    getOfficeList() {
      api.office
        .officeList({
          companyId: "J",
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.offices = res.data.result.datas || [];
          }
        });
    },
    getDataList() {
      api.expat.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          this.data.isCreate = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    toggle(item) {
      const action = item.isLong ? "設定" : "取消";
      this.$eventBus.$emit(
        "showConfirm",
        `確定要${action}長期派駐嗎?`,
        (isOK) => {
          if (isOK) {
            api.expat.update(item).then(() => {
              this.getDataList();
            });
          } else {
            item.isLong = item.isLong ? 0 : 1;
          }
        }
      );
    },
    createData() {
      if (this.datas.length && this.datas[0].isCreate) {
        this.$eventBus.$emit("showAlert", "您尚未儲存新增項目！");
        return;
      }
      this.cancelData(this.data);
      this.data = {
        deptId: this.userData.user.deptId,
        employeeId: "",
        companyId: "J",
        office: "",
        isLong: 0,
        isEdit: true,
        isCreate: true,
      };
      this.datas.splice(0, 0, this.data);
      this.getUserList(this.data);
    },
    updateData(data) {
      this.cancelData(this.data);
      data.isEdit = true;
      this.data = data;
      this.orginData.office = data.office;
      this.$forceUpdate();
    },
    cancelData(data) {
      data.isEdit = false;
      if (data.isCreate) {
        this.datas.splice(0, 1);
        data.isCreate = false;
      } else {
        data.office = this.orginData.office;
        this.$forceUpdate();
      }
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.expat.delete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    saveData(data) {
      if (!data.employeeId) {
        this.$eventBus.$emit("showAlert", "請選擇員工！");
        return;
      }
      if (!data.office) {
        this.$eventBus.$emit("showAlert", "請選擇派駐辦公室！");
        return;
      }
      const action = data.isCreate ? "create" : "update";
      api.expat[action](data).then((res) => {
        this.global.showResponse(this, res, action, this.getDataList);
      });
    },
  },
};
</script>
