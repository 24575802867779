<template>
  <div class="modal fade" ref="flowContent">
    <div class="modal-dialog" style="width: 800px">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ this.isEdit ? "編輯" : "新增" }}階層</h4>
        </div>
        <div class="form-horizontal">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label">查詢人員：</td>
                    <td>
                      <select
                        class="form-control"
                        v-model="data.deptId"
                        @change="getDataList()"
                      >
                        <option value="">全部</option>
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                    <td class="tr">
                      <div class="input-group wp90">
                        <input
                          class="form-control pull-right"
                          placeholder="請輸入關鍵字"
                          type="text"
                          v-model.trim="data.keyword"
                        />
                        <div class="input-group-btn">
                          <button
                            class="btn btn-default"
                            type="button"
                            @click="getDataList()"
                          >
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div class="table-responsive mt-10 mh650">
                        <table class="table table-hover table-bordered">
                          <tbody>
                            <tr class="bg-gray">
                              <th class="tc">單位</th>
                              <th class="tc">員工編號</th>
                              <th class="tc">姓名</th>
                              <th class="tc">功能</th>
                            </tr>
                            <tr v-if="loading">
                              <td class="tc" colspan="4">查詢中</td>
                            </tr>
                            <tr v-else-if="datas.length === 0">
                              <td class="tc" colspan="4">查詢無任何資料</td>
                            </tr>
                            <tr
                              v-else
                              v-for="(data, index) in datas"
                              :key="index"
                            >
                              <td class="tc" v-text="data.deptName"></td>
                              <td class="tc" v-text="data.employeeId"></td>
                              <td class="tc" v-text="data.employeeName"></td>
                              <td class="tc">
                                <button
                                  class="btn btn-primary"
                                  @click="selectData(data)"
                                >
                                  選擇
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" data-dismiss="modal" type="button">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      loading: true,
      isEdit: false,
      list: [],
      datas: [],
      data: {},
    };
  },
  methods: {
    async showContent(data, addData) {
      this.isEdit = data ? true : false;
      this.data = data ? data : addData;
      this.data = JSON.parse(JSON.stringify(this.data));
      this.getList();
      $(this.$refs.flowContent).modal("show");
    },
    getList() {
      api.organization
        .getDeptList({
          companyId: this.$store.state.loginUser.companyId,
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
            this.getDataList();
          }
        });
    },
    getDataList() {
      api.organization.queryEmployeeAll(this.data).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.loading = false;
        }
      });
    },
    selectData(data) {
      $(this.$refs.flowContent).modal("hide");
      this.data.empId = data.employeeId;
      this.data.empName = data.employeeName;
      this.$emit("updateList", this.data);
    },
  },
};
</script>