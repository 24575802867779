<template>
  <div id="ShopDetail">
    <section class="content-header">
      <h1>特約商店</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">特約商店</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="back">
            <button class="btn btn-default" @click="back()">返回商店列表</button>
          </div>
          <div class="top-box mt-10">
            <div class="shop-detail">
              <div class="card">
                <div class="photo">
                  <img :src="getImage(data.files)" alt="">
                </div>
                <div class="info">
                  <div class="name">{{ data.storeName }}</div>
                  <div class="area">{{ data.applyArea }}</div>
                  <div class="time">
                    合約期限：
                    <span v-if="data.isNoLimitContract == 1">永久</span>
                    <span v-else>{{ data.contractTime | dateFormat("YYYY-MM-DD") }}</span>
                  </div>
                  <div class="count">評論數：{{ data.commentCount }}</div>
                  <div class="starCount">平均分數：{{ data.averageStar }}顆星</div>
                  <div class="category">類別：{{ data.storeTypeName }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <li :class="{'active' : tab == 'file'}">
                <a href="javascript:;" @click="tab = 'file'">
                  說明
                </a>
              </li>  
              <li :class="{'active' : tab == 'feeback'}">
                <a href="javascript:;" @click="tab = 'feeback'">
                  評論
                </a>
              </li>             
            </ul>
          </div>
          <div class="feeback" v-if="tab == 'feeback'">
            <div class="feeback-edit">
              <div class="star-list">
                <span class="star" @click="comment.star = 1">{{ comment.star >= 1 ? '★' : '☆' }}</span>
                <span class="star" @click="comment.star = 2">{{ comment.star >= 2 ? '★' : '☆' }}</span>
                <span class="star" @click="comment.star = 3">{{ comment.star >= 3 ? '★' : '☆' }}</span>
                <span class="star" @click="comment.star = 4">{{ comment.star >= 4 ? '★' : '☆' }}</span>
                <span class="star" @click="comment.star = 5">{{ comment.star >= 5 ? '★' : '☆' }}</span>
              </div>
              <textarea class="form-control mt-10" placeholder="請輸入文字" v-model="comment.content"></textarea>
              <button class="btn btn-warning mt-10" @click="sendComment()">送出</button>
            </div>
            <button 
              class="btn btn-default mr-10"
              :class="{'btn-active': commentSearch.star == -1}"
              @click="commentSearch.star = -1"
            >
              全部
            </button>
            <button
              v-for="star in 5"
              class="btn btn-default mr-10"
              :class="{'btn-active': commentSearch.star == star}"
              @click="commentSearch.star = star"
            >
              {{ star }}★
            </button>
            <div class="feeback-list empty" v-if="loading">查詢中</div>
            <div class="feeback-list empty" v-else-if="total == 0">尚無任何回饋</div>
            <div class="feeback-list" v-else>
              <div
                class="item"
                v-for="(comment, index) in list"
              >
                <div class="info mr-10">
                  <div class="name">{{ comment.createEmpName }}</div>
                  <div class="time">{{ comment.updateTime | dateFormat('YYYY-MM-DD hh:mm:ss') }}</div>
                  <div class="star-list">
                    <span class="star" v-if="Number(comment.star) >= 1">★</span>
                    <span class="star" v-if="Number(comment.star) >= 2">★</span>
                    <span class="star" v-if="Number(comment.star) >= 3">★</span>
                    <span class="star" v-if="Number(comment.star) >= 4">★</span>
                    <span class="star" v-if="Number(comment.star) >= 5">★</span>
                  </div>
                </div>
                <p>{{ comment.content }}</p>
              </div>
            </div>
            <div class="box-footer text-center no-border" v-show="total > 0">
              <Pagination
                :total="total"
                ref="pagination"
                :pageLimitCount="commentSearch.pageSize"
                v-on:pageMethod="onPageChange"
              />
            </div>
          </div>
          <div class="fileList" v-if="tab == 'file'">
            <div v-html="data.discountOffers" />
            <br>
            <h4>合約內容：</h4>
            <div v-for="file in data.contracts" class="mt-10">
              <a
                v-if="!global.isImage(file.mine)"
                :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
              >
                {{ file.name }}
              </a>
              <img
                :src="hostname + file.filesUrl"
                v-else
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      host: process.env.VUE_APP_EIPAPI_PATH,
      tab: 'file',
      searchData: {
        storeId: this.$route.params.id, // id
        storeStatus: 1, // 商店狀態 1:啟用、0:停用
        // storeName: "", // 商店名稱
        // isNoLimitContract: 0, // 是否為無限期 1:無期限、0:有期限
        // storeType: "123123", // 商店類型
        // companyType: "456456", // 公司類型
        // isDel: 0, // 刪除狀態 1:已刪除、0:未刪除
        // isFrontend: -1, // 前端顯示狀態 1:前端頁面用、或不帶會預設-1
        // order: 1, // 排序 1:依照合約日期大到小排序、2:依評論數大到小排序 不帶會預設1
        // pageNum: 1, // 頁碼
        // pageSize: 30 // 頁數
      },
      commentSearch: {
        storeId: this.$route.params.id,
        star: -1,
        order: 2, // 排序 1:星數大到小排序、2:創立時間大到小排序 不帶會預設1
        pageNum: 1,
        pageSize: 10
      },
      comment: {
        storeId: this.$route.params.id,
        star: 0,
        content: ""
      },
      data: {},
      list: [],
      total: 0,
      hostname: process.env.VUE_APP_EIPIMG_PATH
    }
  },
  watch: {
    'commentSearch.star'(val) {
      this.getCommentList();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api.shop.query(this.searchData)
        .then((res) => {
          this.data = res.data.result;
          this.getCommentList();
        })
    },
    getImage(files) {
      if(!files) return ""
      const Index = files.findIndex(item => this.global.isImage(item.mine));
      return Index > -1 ? this.hostname + files[Index].filesUrl : ''
    },
    getCommentList() {
      this.loading = true;
      api.shopFeeback.queryAll(this.commentSearch)
        .then((res) => {
          this.list = res.data.result.datas;
          this.total = res.data.result.total || 0;
          this.loading = false;
        })
    },
    resetCommentList() {
      this.commentSearch.pageNum = 1;
      this.getCommentList();
    },
    resetComment() {
      this.comment.star = 0;
      this.comment.content = "";
    },
    sendComment() {
      if(this.comment.star == 0) {
        this.$eventBus.$emit("showAlert",`評論星數至少為1顆`);
        return
      }
      api.shopFeeback.create(this.comment)
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert",`新增成功`);
            this.commentSearch.star = this.comment.star;
            this.data.commentCount = res.data.result.commentCount;
            this.data.averageStar = res.data.result.averageStar;
            this.resetComment();
            this.resetCommentList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message || `新增失敗`);
          }
        })
    },
    back() {
      this.$router.push({
        name: 'Shop',
        params: {
          searchData: this.$route.params.searchData,
        }
      })
    },
    async onPageChange(pageIdx, pageSize) {
      this.commentSearch.pageNum = pageIdx;
      this.commentSearch.pageSize = pageSize;
      this.getCommentList();
    },
  },
}
</script>
<style scoped lang="scss">
#ShopDetail {
  .back {
    margin-right: 10px;
    text-align: right;
  }
  .shop-detail {
    display: flex;

    @media (max-width: 992px) {
      display: block;

      .card,
      .content {
        width: 100% !important;
      }
    }
    .card {
      display: flex;
      width: 100%;
      .photo {
        width: 150px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        
        img {
          max-width: 150px;
          max-height: 150px;
        }
      }
      .info {
        font-size: 16px;
        .name {
          font-size: 24px;
          font-weight: bolder;
          margin-bottom: 15px;
        }
      }
    }
  }
  .box {
    .nav-tabs-custom {
      margin-top: 30px;
      > .nav-tabs {
        > li.active {
          border-top-color: #3c8dbc;
        } 
      }
    }
  }
  .feeback {
    padding: 0 20px;
    .feeback-edit {
      margin-bottom: 30px;

      .star-list {
        >.star {
          font-size: 28px;
          color: #FBBC04;
          cursor: pointer;
        }
      }

      >button {
        float: right;
      }
    }
    .feeback-list {
      padding: 20px 0;

      .item {
        .info {
          display: flex;
          align-items: center;
          .name {
            margin-right: 10px;
            font-weight: bold;
          }
          .star-list {
            font-size: 20px;
            .star {
              color: #e1cb2b;
              margin-left: 5px;
            }
          }
        }
  
        >p {
          word-break: break-all;
        }
      }

      &.empty {
        text-align: center;
        padding-top: 20px;
      }
    }
  }
  .fileList {
    padding: 10px 20px;
  }
  .btn-active {
    background: #E8F0FE;
    color: #5284E2;
    border-color: #5284E2;
  }
}
</style>