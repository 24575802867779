<template>
  <div id="BalanceTimeList">
    <section class="content-header">
      <h1>總分平衡關閉作業</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">總分平衡關閉作業</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning" style="width: 1050px">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
                <select
                  class="form-control"
                  style="width: auto"
                  v-model="searchData.closeType"
                  @change="resetDataList()"
                >
                  <option value="處主管總分平衡">處主管總分平衡</option>
                  <option value="中心主管總分平衡">中心主管總分平衡</option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">年份</th>
                  <th class="tc">年度</th>
                  <th class="tc">單位</th>
                  <th class="tc">分類</th>
                  <th class="tc">執行關閉</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="5">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="5">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td
                    class="tc"
                    v-text="define.halfYearStatus[data.month]"
                  ></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.closeType"></td>
                  <td class="tc">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :id="'check' + index"
                        :true-value="1"
                        :false-value="0"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model.number="data.isClose"
                        @change="updateData(data)"
                      />
                      <label :for="'check' + index"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        closeType: "處主管總分平衡",
      },
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.updateMonthSearch();
    this.getDataList();
  },
  methods: {
    getDataList() {
      api.okr.mgmt.balance.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateMonthSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.month = this.global.getHalfYear();
      }
    },
    updateData(data) {
      const action = data.id ? "update" : "create";
      api.okr.mgmt.balance[action](data).then((res) => {
        this.global.showResponse(this, res, action);
        if (res.data.status !== "1") {
          data.isClose = data.isClose ? 0 : 1;
        }
      });
    },
  },
};
</script>
