var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Score" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("年度考核作業(處主管)")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("年度考核作業(處主管)")]),
        ]),
      ]),
      _c("section", { staticClass: "content seating" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.year,
                          expression: "searchData.year",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control w90",
                      staticStyle: { "margin-left": "0" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "year",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getDataList()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.years, function (year, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: year } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(year) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.month,
                          expression: "searchData.month",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control w90",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "month",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("上半年"),
                      ]),
                      _c("option", { attrs: { value: "1" } }, [
                        _vm._v("下半年"),
                      ]),
                    ]
                  ),
                  _vm.divisionList.length > 0
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.divisionId,
                              expression: "divisionId",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.divisionId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.getList()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.divisionList, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.deptId } },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item.deptName) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.deptId,
                          expression: "searchData.deptId",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "deptId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getDataList(true)
                          },
                        ],
                      },
                    },
                    [
                      _vm.divisionList.length > 0
                        ? _c("option", { attrs: { value: "" } }, [
                            _vm._v("全部"),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.deptList, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.deptId } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.deptName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "8" } },
                              [_vm._v("查詢中")]
                            ),
                          ])
                        : _vm.list.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "8" } },
                              [_vm._v("查詢無任何資料")]
                            ),
                          ])
                        : _vm._l(_vm.list, function (data, index) {
                            return _c("tr", { key: index }, [
                              _c("td", { staticClass: "tc" }, [
                                _vm._v(_vm._s(data.deptName)),
                              ]),
                              _c("td", { staticClass: "tc" }, [
                                _vm._v(_vm._s(data.employeeId)),
                              ]),
                              _c("td", { staticClass: "tc" }, [
                                _vm._v(_vm._s(data.employeeName)),
                              ]),
                              _c("td", { staticClass: "tc" }, [
                                data.totalScore == null
                                  ? _c("span", [_vm._v(" - ")])
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "text-primary",
                                        staticStyle: {
                                          cursor: "pointer",
                                          "text-decoration": "underline",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showContent(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              data.totalScore
                                                ? data.totalScore
                                                : "0"
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("td", { staticClass: "tc" }, [
                                !data.isEdit
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(data.oneBalanceScore) +
                                          "\n                  "
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "tc",
                                        staticStyle: {
                                          width: "80px",
                                          margin: "auto",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: data.oneBalanceScore,
                                              expression:
                                                "data.oneBalanceScore",
                                            },
                                          ],
                                          staticClass:
                                            "form-control text-center",
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: data.oneBalanceScore,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                data,
                                                "oneBalanceScore",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                              ]),
                              _c("td", { staticClass: "tc" }, [
                                !data.isEdit
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(data.oneBalanceNote) +
                                          "\n                  "
                                      ),
                                    ])
                                  : _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: data.oneBalanceNote,
                                            expression: "data.oneBalanceNote",
                                          },
                                        ],
                                        staticClass: "form-control text-center",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: data.oneBalanceNote,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              data,
                                              "oneBalanceNote",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                              ]),
                              _c("td", { staticClass: "tc" }, [
                                !data.isEdit && !_vm.isClose
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    修改\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                data.isEdit
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateDate(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    儲存\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          !_vm.isClose && _vm.list.length > 0
            ? _c("div", { staticClass: "box-footer text-center no-border" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [_vm._v("完成送出")]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("ScorePersonalHistory", { ref: "ScorePersonalHistory" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc mw100" }, [_vm._v("部門")]),
      _c("th", { staticClass: "tc mw100" }, [_vm._v("員工編號")]),
      _c("th", { staticClass: "tc mw100" }, [_vm._v("姓名")]),
      _c("th", { staticClass: "tc mw90" }, [_vm._v("加分合計")]),
      _c("th", { staticClass: "tc mw90" }, [_vm._v("最後總分")]),
      _c("th", { staticClass: "tc mw200", staticStyle: { width: "200px" } }, [
        _vm._v("說明"),
      ]),
      _c("th", { staticClass: "tc mw150" }, [_vm._v("管理")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }