import moment from 'moment';
import store from '@/store.js';
import xlsx from "xlsx";

const monentLocale = "zh-tw";

function getTimeId() {
    return new moment().format("YYYYMMDDHHmmssSSS");
}

function checkByUserPermission(permission) {
    let userPermissionList = store.state.loginUserPermission;
    let num = -1;

    let isCheck = false;
    permission.split(",").some(element => {
        num = userPermissionList.indexOf(element);
        switch (num) {
            case -1:
                isCheck = false;
                break;
            default:
                isCheck = true;
                break;
        }
        if (isCheck) return true;
    });

    return isCheck;
}

export default {
    install(Vue, options) {
        if (this.isInstalled) return;
        this.isInstalled = true;
        Vue.prototype.$utils = this;
    },
    store,
    getTimeId: getTimeId,
    checkByUserPermission: checkByUserPermission,
    formatTime: function (datetime, format, locale) {
        if (format == undefined) format = "YYYY/MM/DD";
        if (locale == undefined) locale = monentLocale;
        return new moment(datetime).locale(locale).format(format);
    },
    getWeekDay: function (datetime, locale) {
        return this.formatTime(datetime, 'dd', locale);
    },
    exportData(exportData, fileName, sheetName) {
        if (
            exportData == undefined ||
            exportData.length == undefined ||
            exportData.length == 0
        ) {
            this.$eventBus.$emit("showAlert", "無資料!!");
            return;
        }

        if (sheetName == undefined) sheetName = "sheet1";
        if (fileName == undefined || fileName == "") fileName = getTimeId() + ".xlsx";

        let worksheet = xlsx.utils.json_to_sheet(exportData);
        let new_workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(new_workbook, worksheet, sheetName);
        xlsx.writeFile(new_workbook, fileName);
    },
    deepCloneObj(data){
        return JSON.parse( JSON.stringify(data) );
    }
}