var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Order" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("訂餐歷程")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("訂餐服務")]),
          _c("li", { staticClass: "active" }, [_vm._v("訂餐歷程")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box two-btn" }, [
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "text-red" }, [
                  _c("i", { staticClass: "fa fa-warning" }),
                  _vm._v(
                    "\n              訂餐截止時間：" +
                      _vm._s(_vm.orderDeadline) +
                      "。\n            "
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-purple btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goOrderDinner()
                    },
                  },
                },
                [_vm._v("\n            預訂今天晚餐\n          ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goOrderNextWeekMeal()
                    },
                  },
                },
                [_vm._v("\n            預訂下週午餐\n          ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openEmployeeHabits()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-gear" }),
                  _vm._v(" 個人飲食習慣\n          "),
                ]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered min-600" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm._l(_vm.orderList.datas, function (order) {
                      return _c("tr", { key: order.eatId }, [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTime(order.eatDay, "YYYY/MM/DD")
                            )
                          ),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.formatDateTime(order.eatDay, "dd")) +
                              "\n                "
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(order.storeName))]),
                        _c("td", [_vm._v(_vm._s(order.mealName))]),
                        order.eatStatus != 0
                          ? _c("td", { staticClass: "text-center" }, [
                              order.eatScore != null
                                ? _c("span", [_vm._v(_vm._s(order.eatScore))])
                                : _c("span", { staticClass: "text-red" }, [
                                    _vm._v("尚未評分"),
                                  ]),
                              _vm._v(" /\n                  "),
                              order.mealScore != null
                                ? _c("span", [_vm._v(_vm._s(order.mealScore))])
                                : _c("span", [_vm._v("0")]),
                            ])
                          : _c("td"),
                        order.eatStatus != 0
                          ? _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button", title: "評分" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openOrderScore(order)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-pencil" })]
                              ),
                            ])
                          : _c("td"),
                        _c("td", [_vm._v(_vm._s(_vm.getOrderStatus(order)))]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.getOrderDine(order.eatTime))),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm.checkDateCanShowMenu(order.eatDay, order.eatTime)
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "button", title: "瀏覽菜單" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goOrderMenu(
                                        order.eatDay,
                                        order.eatTime
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-book" })]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center" },
            [
              _c("Pagination", {
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("employeeHabits", { ref: "employeeHabits" }),
      _c("orderScore", {
        ref: "orderScore",
        on: { refreshList: _vm.getOrderList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("用餐日期")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("星期")]),
      _c("th", [_vm._v("店家名稱")]),
      _c("th", [_vm._v("餐點名稱")]),
      _c("th", { staticClass: "text-center" }, [
        _vm._v("\n                  餐點評分\n                  "),
        _c("br"),
        _vm._v("(個人/平均)\n                "),
      ]),
      _c("th", { staticClass: "text-center" }, [_vm._v("評比")]),
      _c("th", [_vm._v("狀態")]),
      _c("th", [_vm._v("類別")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("瀏覽菜單")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }