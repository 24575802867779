<template>
  <div id="Score">
    <section class="content-header">
      <h1>年度考核作業(人資)</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">年度考核作業(人資)</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-success"
                type="button"
                @click="exportList()"
              >
                匯出
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="getDataList()"
                  style="margin-left: 0"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="getDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw100">單位</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw100">完成時間</th>
                  <th class="tc mw100">作業完成</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in list" :key="index">
                  <td class="tc">{{ data.deptName }}</td>
                  <td class="tc">{{ data.createId }}</td>
                  <td class="tc">{{ data.createName }}</td>
                  <td class="tc">
                    {{
                      new Date(data.createTime) | dateFormat("YYYY/MM/DD HH:mm")
                    }}
                  </td>
                  <td class="tc">
                    <i class="fa fa-check" v-if="data.isClose == 1"></i>
                  </td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      @click="cancelFinished(data.id)"
                      v-if="data.isClose == 1"
                    >
                      取消完成
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";

export default {
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        year: this.global.getNowHalfYear(),
        month: this.global.getHalfYear(),
        deptLevel: 1, //1:為處主管平衡加分 2:為部主管平衡加分(人資部、人才培訓部、法務部)
        divisionId: "", //要帶處單位，因有兼職狀況
        deptId: "", //部主管平衡，要帶部單位，也有兼職狀況
      },
      list: [],
      years: this.global.getYearList(),
      months: [],
      userData: {},
      permission: {},
      deptList: [],
      users: [],
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.searchData.divisionId = this.userData.user.parentDeptId;
    this.searchData.deptId = this.userData.user.deptId;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      api.okr.v2.big.hrQueryAll(this.searchData).then(({ data }) => {
        if (data.status == "1") {
          this.list = data.result.datas;
          this.loading = false;
        }
      });
    },
    edit(item) {
      item.isEdit = true;
      this.$forceUpdate();
    },
    updateDate(item) {
      item.isEdit = false;
      api.okr.v2.big.update(item).then((res) => {
        this.global.showResponse(this, res, "update");
        this.getDataList();
      });
      this.$forceUpdate();
    },
    save() {
      api.okr.v2.big.create(this.searchData).then((res) => {
        this.global.showResponse(this, res, "send", this.getDataList());
      });
    },
    async exportList() {
      let QueryAll = api.okr.v2.big.totalBalanceList(this.searchData);
      let response = await QueryAll;
      if (response.data.status != "1" || response.data.result.total == 0) {
        this.$eventBus.$emit("showAlert", "查詢資料失敗，請重新操作!");
        return;
      }

      let data = [];
      response.data.result.datas.forEach((res) => {
        data.push({
          部門: res.deptName,
          員工編號: res.employeeId,
          姓名: res.employeeName,
          總分: res.totalScore,
          加分: res.oneBalanceScore,
          說明: res.oneBalanceNote,
        });
      });

      utils.exportData(
        data,
        utils.formatTime(this.eatDay, "YYYYMMDD") +
          "_" +
          "年度考核作業" +
          ".xlsx"
      );
    },

    cancelFinished(id) {
      api.okr.mgmt.balance
        .update({
          id: id,
          isClose: 0, //0:開放 1:關閉
        })
        .then(({ data }) => {
          if (data.status == 1) {
            this.$eventBus.$emit("showAlert", "取消成功！");
            this.getDataList();
          } else if (data.message) {
            this.$eventBus.$emit("showAlert", data.message);
          }
        });
    },
  },
};
</script>