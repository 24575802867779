<template>
  <div id="Shop">
    <section class="content-header">
      <h1>特約商店分類</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">特約商店分類</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="addItem()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
            <div class="select-group">
              <select
                class="form-control w90"
                style="margin-left: 0;"
                v-model="searchData.module"
                @change="resetDataList()"
              >
                <option value="特約商店地區">特約商店地區</option>
                <option value="特約商店類別">特約商店類別</option>
              </select>
            </div>

            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="請輸入關鍵字"
                type="text"
                v-model="searchData.className"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="getDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc" style="width: 35%;">分類名稱</th>
                  <th class="tc" style="width: 15%;">排序</th>
                  <th class="tc" style="width: 20%;">啟用狀態</th>
                  <th class="tc" style="width: 30%;">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="4">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="4">查詢無任何資料</td>
                </tr>
                <tr v-for="(item, index) in list" :key="index">
                  <td class="tc">
                    <p class="m-0" v-show="isEdit != index">{{ item.className }}</p>
                    <input
                      class="form-control text-center"
                      required="required"
                      type="text"
                      v-model="item.className"
                      v-show="isEdit == index"
                    />
                  </td>
                  <td class="tc">
                    <p class="m-0" v-show="isEdit != index">{{ item.classOrder }}</p>
                    <input
                      class="form-control text-center"
                      required="required"
                      type="number"
                      v-model="item.classOrder"
                      v-show="isEdit == index"
                    />
                  </td>
                  <td class="tc">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :false-value="0"
                        :id="'check'+index"
                        :true-value="1"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.classStatus"
                        disabled
                      />
                      <label :for="'check'+index" @click="changeStatus(item)"></label>
                    </div>
                  </td>
                  <td class="tc">
                    <button
                      class="btn btn-primary mr-10"
                      v-show="isEdit != index"
                      @click="change(index)"
                    >
                      修改
                    </button>  
                    <button
                      class="btn btn-danger"
                      v-show="isEdit != index"
                      @click="deleteItem(item)"
                    >
                      刪除
                    </button>      
                    <button
                      class="btn btn-success mr-10"
                      v-show="isEdit == index"
                      @click="save(item)"
                    >
                      儲存
                    </button>  
                    <button
                      class="btn btn-default"
                      v-show="isEdit == index"
                      @click="cancel(item, index)"
                    >
                      取消
                    </button>           
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        className: "", // 類別名稱
        module: "特約商店地區", // 類別模組 ex: 特約商店地區、特約商店類別
        // classStatus: 1, // 類別狀態
        isDel: 0, // 刪除狀態 1:已刪除、0:未刪除
        order: 2,
        pageNum: 1,
        pageSize: 999,
      },
      list: [],
      isEdit: -1,
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      api.shopClass.admin.queryAll(this.searchData)
        .then((res) => {
          this.list = res.data.result.datas;
          this.loading = false;
        })
    },
    resetDataList() {
      this.searchData.className = "";
      this.getDataList();
    },
    addItem() {
      if(this.isEdit != -1){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }
      this.isEdit = this.list.length;

      this.list.push({
        className: "",
        classOrder: 1,
        module: this.searchData.module,
        classStatus: 1,
        isDel: 0,
        isNew: true,
      });
    },
    change(index) {
      if(this.isEdit != -1){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }
      this.isEdit = index;
    },
    save(item) {
      let type = item.isNew ? "create" : "update";
      let message = item.isNew ? "新增" : "修改";

      api.shopClass[type](item)
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert",`${message}成功`);
            this.isEdit = -1;
            this.getDataList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message || `${message}失敗`);
          }
        })
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", 
      `確認要刪除此分類？`,
      isOK => {
        if (isOK) {
            item.isDel = 1;
            api.shopClass.update(item)
              .then((res) => {
                if(res.data.status == "1") {
                  this.$eventBus.$emit("showAlert", "刪除成功");
                  this.getDataList();
                } else {
                  this.$eventBus.$emit("showAlert", res.data.message || "刪除失敗");
                }
              })
					}
				});
    },
    changeStatus(item) {
      this.$eventBus.$emit("showConfirm", 
      `確認要${item.classStatus == 1 ? '停用' : '啟用'}此分類？
      將影響已建立的相關資料`,
      isOK => {
        if (isOK) {
            item.classStatus = item.classStatus == 1 ? 0 : 1;
						this.save(item)
					}
				});
    },
    cancel(item, index) {
      this.isEdit = -1;
      if (item.classTypeId == "") {
        this.classTypes.datas.splice(index, 1);
      } else {
        this.getDataList();
      }
    },
    showDetail() {
      this.$router.push({
        name: 'ShopDetail'
      })
    }
  },
}
</script>