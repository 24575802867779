<template>
  <select
    class="form-control"
    required="required"
    v-model="empSelectedId"
    @change="$emit('update:selectedId', empSelectedId)"
  >
    <option
      v-for="(dept, index) in empOptions"
      :key="index"
      :value="dept.employeeId"
    >{{ dept.employeeName }}</option>
  </select>
</template>
<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  props: {
    propDeptId: "",
    propEmpId: ""
  },
  data() {
    return {
      empOptions: [],
      empSelectedId: ""
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      if( this.propDeptId == "" || this.propDeptId == undefined ) {
        this.empOptions = [];
        return;
      }

      var res = await api.organization.getDeptEmpList({
        deptId: this.propDeptId
      });

      this.empOptions = res.data.result;
      if( this.empOptions.length == 0 ){
        this.empSelectedId = "";
        this.$emit('update:selectedId', this.empSelectedId);
        return;
      }
      var focusId = this.empOptions[0].employeeId;
      for(var i = 0 ; i < this.empOptions.length ; i++){
          if( this.propEmpId == this.empOptions[i].employeeId){
            focusId = this.empOptions[i].employeeId;
            break;
          }
      }
      this.empSelectedId = focusId;
      this.$emit('update:selectedId', this.empSelectedId);
    }
  },
  watch: {
    propDeptId: function(value) {
      // console.log("propDeptId:"+this.propDeptId);
      this.refresh();
    },
    propEmpId:function (value) {
      this.empSelectedId = value;
    }
  }
};
</script>