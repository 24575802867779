var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "box-footer no-border mt-10" }),
      _c(
        "div",
        {
          staticClass: "table-responsive",
          staticStyle: { "max-width": "1300px", margin: "auto" },
        },
        [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("tbody", [
              _c("tr", { staticClass: "bg-gray" }, [
                _c("th", { staticClass: "tc mw100" }, [
                  _vm._v("績效分數（滿分100分）"),
                ]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("未達60分")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("60分~69分")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("70分~79分")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("80分~89分")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("90分以上")]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "tc mw100 bg-gray" }, [
                  _vm._v("代表意義"),
                ]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("不合現職要求")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("需加強要求")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("符合現職要求")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("優於現職要求")]),
                _c("td", { staticClass: "tc mw50" }, [_vm._v("超越現職要求")]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }