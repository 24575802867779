<template>
  <div class="modal fade normal" ref="companyFileAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span v-if="action === 'download'">是否下載以下文件的檔案？</span>
            <span v-else>
              是否刪除以下文件？(共計 {{ checkDatas.length }} 筆)
            </span>
          </h4>
        </div>
        <div class="modal-body">
          <ul>
            <li v-for="(item, index) in checkDatas" :key="index">
              {{ item.text }}
            </li>
          </ul>
        </div>
        <div class="modal-footer" v-if="loading">
          <div class="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
        </div>
        <div class="modal-footer" v-else-if="downloadStart">
          <a class="btn btn-success" :href="downloadLink" @click="hideAlert()">
            開始下載
          </a>
        </div>
        <div class="modal-footer" v-else>
          <button class="btn btn-primary" type="button" @click="submit()">
            確定
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";

export default {
  props: ["checkDatas"],
  data() {
    return {
      loading: false,
      downloadStart: false,
      downloadLink: "",
      action: "delete",
    };
  },
  methods: {
    showAlert(action) {
      this.action = action || this.action;
      this.downloadStart = false;
      $(this.$refs.companyFileAlert).modal("show");
    },
    hideAlert() {
      $(this.$refs.companyFileAlert).modal("hide");
      if (this.action !== "download") {
        this.$emit("updateList");
      }
    },
    submit() {
      this.loading = true;
      api.companyFiles[`${this.action}All`](this.checkDatas).then((res) => {
        this.loading = false;
        if (this.action === "download" && res.data.status === "1") {
          this.downloadStart = true;
          this.downloadLink = res.data.result;
        } else {
          this.global.showResponse(this, res, this.action);
          this.hideAlert();
        }
      });
    },
  },
};
</script>
