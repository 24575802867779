<template>
<div>
	<section class="content-header">
		<h1>星城體驗服帳號維護</h1>

		<ol class="breadcrumb">
			<li>
				<router-link to="/">Home</router-link>
			</li>
			<li class="active">星城體驗服帳號維護</li>
		</ol>
	</section>
	<section class="content seating">
		<div class="box box-warning">
			<div class="box-body">
				<div class="top-box">
          <button
            class="btn btn-block btn-primary btn-add"
            type="button"
            @click="updateList"
          >
            重取體驗服帳號
          </button>
          <div class="select-group">
            <select
              class="form-control w90"
              v-if="userData.user.companyId === 'A'"
              v-model="searchData.CompanyId"
              @change="resetDataList()"
            >
              <option value="A">台中</option>
              <option value="J">台北</option>
            </select>
            <select
              class="form-control"
              v-if="userData.user.companyId === 'A'"
              @change="resetDataList()"
              v-model="searchData.isOutsider"
            >
              <option value="-1">全部</option>
              <option value="0">員工</option>
              <option value="1">廠商</option>
            </select>
            <select
              class="form-control"
              @change="resetDataList()"
              v-model="searchData.JobType"
              v-if="searchData.isOutsider === '0'"
            >
              <option value="-1">全部</option>
              <option value="1">在職</option>
              <option value="0">離職</option>
            </select>
            <select
              class="form-control"
              v-model="searchData.isPlay"
              @change="resetDataList()"
            >
              <option value="-1">全部</option>
              <option value="1">已開通</option>
              <option value="0">未開通</option>
            </select>
          </div>
          <div class="input-group">
            <input
              class="form-control pull-right"
              name="table_search"
              placeholder="查詢工號"
              type="text"
              v-model="searchData.employeeId"
            />
            <div class="input-group-btn">
              <button
                v-on:click="resetDataList()"
                class="btn btn-default"
                type="button"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-10">
          <table class="table table-hover table-bordered min-800">
            <tbody>
              <tr class="bg-gray">
                <th class="tc mw90">部門</th>
                <th class="tc mw90">員工編號</th>
                <th class="tc mw90">姓名</th>
                <th class="tc mw90">門號</th>
                <th class="tc mw90">狀態</th>
                <th class="tc mw90">到職日</th>
                <th class="tc mw90">開通</th>
                <th class="tc mw150">備註</th>
                <th class="tc mw150">管理</th>
              </tr>
              <tr v-if="loading">
                <td class="tc" colspan="8">查詢中</td>
              </tr>
              <tr v-else-if="list.length === 0">
                <td class="tc" colspan="8">查詢無任何資料</td>
              </tr>
              <tr v-else v-for="(data, index) in list" :key="index">
                <td>{{ data.deptName }}</td>
                <td>{{ data.employeeId }}</td>
                <td>{{ data.employeeName }}</td>
                <td>{{ data.empCellphone }}</td>
                <td>
                  <span v-if="searchData.isOutsider === '0'">
                    {{ data.employeeType === '3001' ?  '離職' : '在職'}}
                  </span>
                </td>
                <td>
                  <span v-if="searchData.isOutsider === '0'">
                    {{ data.jobDate | dateFormat("YYYY/MM/DD") }}
                  </span>
                </td>
                <td>{{ data.isPlay === 0 ? '未開通' : '已開通' }}</td>
                <td>{{data.note}}</td>
                <td class="tc">
                  <template v-if="data.isPlay === 0">
                    <button
                      class="btn btn-primary"
                      @click="showContent(data)"
                    >
                      新增
                    </button>
                  </template>
                  <template v-else>
                    <button
                      class="btn btn-primary"
                      @click="showContent(data)"
                    >
                      修改
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger"
                      @click="deleteData(data)"
                    >
                      刪除
                    </button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
			</div>
		</div>
	</section>
  <XinStarsContent ref="xinStarsContent" :updateList="resetDataList" />
</div>
</template>

<script>
import api from "@/assets/js/app-api";
import XinStarsContent from "./XinStartsContent.vue";

export default {
  components:{
    XinStarsContent
  },
  data(){
    return {
      loading: false,
      userData: {},
      searchData:{
        CompanyId: "A",
        JobType: '1',
        isOutsider: '0',
        isPlay: '-1',
        employeeId : '',
      },
      list:[]
    }
  },
  created() {
    this.userData = this.$user.getUserData();
    this.getList()
  },
  mounted(){
  },
  methods:{
    getList() {
      api.xinStarts.queryAll(this.searchData).then((res) => {
        if(res.data.status === '1') {
          this.list = res.data.result
        }
      })
    },
    resetDataList() {
      this.list = []
      this.getList();
    },
    updateList() {
      api.xinStarts.updateQueryAll().then((res) => {
        this.global.showResponse(this, res, '執行', this.resetDataList());
      })
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.xinStarts.delete(data).then((res) => {
            this.resetDataList()
            this.global.showResponse(this, res, 'delete');
          });
        }
      });
    },
    showContent(data) {
      this.$refs.xinStarsContent.showContent(data);
    },
  },
}
</script>