<template>
  <div id="YearEdit">
    <section class="content-header">
      <h1 v-if="type">大考開放時間</h1>
      <h1 v-else>目標、小考開放時間</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link :to="`/OKR/PersonalTimeList/${type}`">
            {{ type ? "大考" : "目標、小考" }}開放時間
          </router-link>
        </li>
        <li class="active">{{ !id ? "新增" : "編輯" }}開放時間</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">{{ !id ? "新增" : "編輯" }}開放時間</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <form
            class="form-horizontal"
            method="post"
            @submit.prevent="saveData()"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  部門：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    required
                    disabled
                    v-model="data.deptId"
                  >
                    <option value="">部別</option>
                    <option
                      v-for="(item, index) in list"
                      :key="index"
                      :value="item.deptId"
                    >
                      {{ item.deptName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  年度：
                </label>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    disabled
                    v-model.number="data.year"
                  >
                    <option
                      v-for="(year, index) in years"
                      :key="index"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select
                    class="form-control"
                    required
                    disabled
                    v-model.number="data.month"
                  >
                    <option
                      v-for="(month, index) in 12"
                      :key="index"
                      :value="month"
                    >
                      {{ month }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  人員：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    required
                    disabled
                    v-model="data.employeeId"
                  >
                    <option value="">人員</option>
                    <option
                      v-for="(user, index) in users"
                      :key="index"
                      :value="user.employeeId"
                    >
                      {{ user.employeeName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  類別：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    required
                    disabled
                    v-model.number="data.openType"
                  >
                    <option value="0">人員目標</option>
                    <option value="1">小考</option>
                    <option value="2">大考</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  狀態：
                </label>
                <div class="col-sm-8">
                  <div class="right">
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          name="status"
                          value="1"
                          v-model.number="data.status"
                        />
                        開放
                      </label>
                    </div>
                    <div class="radio-inline">
                      <label>
                        <input
                          type="radio"
                          name="status"
                          value="0"
                          v-model.number="data.status"
                        />
                        關閉
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  開放原因：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    required
                    v-model.number="data.reason"
                  >
                    <option value="">請選擇</option>
                    <option value="1">員工目標未填</option>
                    <option value="2">員工小考未自評</option>
                    <option value="3">員工大考未自評</option>
                    <option value="4">主管逾期</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  開放開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right" id="startTime">
                        <DatePicker :propValue.sync="startTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        autocomplete="off"
                        id="startTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>
                  開放結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right" id="endTime">
                        <DatePicker :propValue.sync="endTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        autocomplete="off"
                        id="endTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <input type="submit" value="儲存" class="btn btn-primary" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      loading: true,
      addData: {
        openTimeId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.deptId,
        employeeId: this.$route.params.employeeId,
        openType: this.$route.params.openType,
        status: 0,
        reason: "",
      },
      startTime: new moment().format("YYYY-MM-DD 00:00:00"),
      endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      id: this.$route.params.id,
      type: Number(this.$route.params.type),
      years: this.global.getYearList(),
      list: [],
      users: [],
      data: {},
    };
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    this.getList();
  },
  methods: {
    getList() {
      api.okr.goal.department.list({ deptLevel: 2 }).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.getUserList();
        }
      });
    },
    getUserList() {
      api.organization.queryEmployeeAll(this.addData).then((res) => {
        if (res.data.status === "1") {
          this.users = res.data.result.datas || [];
          this.getData();
        }
      });
    },
    getData() {
      if (this.id) {
        api.okr.mgmt.open.query({ openTimeId: this.id }).then((res) => {
          if (res.data.status === "1") {
            this.data = res.data.result || {};
            this.loading = false;
            this.updateTime();
          }
        });
      } else {
        this.data = this.addData;
        this.loading = false;
        this.updateTime();
      }
    },
    updateTime() {
      this.startTime = this.data.startTime || this.startTime;
      this.endTime = this.data.endTime || this.endTime;
      $("#startTimeOnly").val(this.global.getTime(this.startTime));
      $("#endTimeOnly").val(this.global.getTime(this.endTime));
    },
    saveData() {
      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      this.startTime = new Date(
        `${this.startTime.split(" ")[0]} ${startTimeOnly}`
      );
      this.endTime = new Date(`${this.endTime.split(" ")[0]} ${endTimeOnly}`);

      if (startTimeOnly === "" || !this.global.isValidDay(this.startTime)) {
        this.$eventBus.$emit("showAlert", "請填寫開放開始時間！");
        return;
      }
      if (endTimeOnly === "" || !this.global.isValidDay(this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫開放結束時間！");
        return;
      }
      if (!this.global.isValidDayRange(this.startTime, this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }

      this.data.startTime = new Date(`${this.startTime} UTC`);
      this.data.endTime = new Date(`${this.endTime} UTC`);
      const action = this.id ? "update" : "create";
      api.okr.mgmt.open[action](this.data).then((res) => {
        this.global.showResponse(this, res, action, this.backList);
      });
    },
    backList() {
      this.$router.push({
        name: "PersonalTimeList",
        params: {
          type: this.$route.params.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
