var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "main-sidebar" }, [
    _c("section", { staticClass: "sidebar" }, [
      _vm.companyChangeList.length > 0
        ? _c("div", { staticClass: "company-group" }, [
            _c("label", { attrs: { for: "currentCompany" } }, [
              _vm._v("目前公司："),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentCompany,
                    expression: "currentCompany",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "currentCompany" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.currentCompany = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.companyChangeList, function (item, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: item.companyId } },
                  [
                    _vm._v(
                      "\n          " + _vm._s(item.companyName) + "\n        "
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "sidebar-menu", attrs: { "data-widget": "tree" } },
        [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/Home" } }, [
                _c("i", { staticClass: "fa fa-home" }),
                _c("span", [_vm._v("首頁")]),
              ]),
            ],
            1
          ),
          _vm._l(_vm.menus, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                class: _vm.subMenu(item),
                attrs: { "data-v": item.isVueMenu },
              },
              [
                item.isVueMenu == 1
                  ? [
                      item.subMenu.length > 0
                        ? _c("a", { attrs: { href: "#" } }, [
                            item.menuId == "AN"
                              ? _c("i", { staticClass: "fa fa-bullhorn" })
                              : item.menuId == "F0"
                              ? _c("i", { staticClass: "fa fa-file-text-o" })
                              : item.menuId == "S0"
                              ? _c("i", {
                                  staticClass: "fa fa-pencil-square-o",
                                })
                              : item.menuId == "C0"
                              ? _c("i", { staticClass: "fa fa-paste" })
                              : item.menuId == "B0"
                              ? _c("i", { staticClass: "fa fa-building-o" })
                              : item.menuId == "D0"
                              ? _c("i", { staticClass: "fa fa-book" })
                              : item.menuId == "E0"
                              ? _c("i", { staticClass: "fa fa-cutlery" })
                              : item.menuId == "K0"
                              ? _c("i", { staticClass: "fa fa-futbol-o" })
                              : item.menuId == "Z0"
                              ? _c("i", { staticClass: "fa fa-cogs" })
                              : item.menuId == "T0"
                              ? _c("i", { staticClass: "fa fa-clock-o" })
                              : item.menuId == "AT"
                              ? _c("i", { staticClass: "fa fa-futbol-o" })
                              : item.menuId == "ET"
                              ? _c("i", { staticClass: "fa fa-graduation-cap" })
                              : item.menuId == "CT"
                              ? _c("i", { staticClass: "fa fa-book" })
                              : _c("i", { staticClass: "fa fa-circle" }),
                            _c("span", [_vm._v(_vm._s(item.menuName))]),
                            _vm.showNotice(item)
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "pull-right-container",
                                    staticStyle: {
                                      transform: "scale(0.7)",
                                      color: "#0073b7",
                                      right: "30px",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-circle pull-right",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._m(0, true),
                          ])
                        : _c(
                            "router-link",
                            { attrs: { to: { path: item.menuUrl } } },
                            [
                              item.menuId == "F0"
                                ? _c("i", { staticClass: "fa fa-file-text-o" })
                                : item.menuId == "G0"
                                ? _c("i", { staticClass: "fa fa-envelope-o" })
                                : item.menuId == "H0"
                                ? _c("i", {
                                    staticClass: "fa fa-calendar-check-o",
                                  })
                                : item.menuId == "J0"
                                ? _c("i", { staticClass: "fa fa-star" })
                                : item.menuId == "K0"
                                ? _c("i", { staticClass: "fa fa-futbol-o" })
                                : item.menuId == "L0"
                                ? _c("i", { staticClass: "fa fa-lightbulb-o" })
                                : item.menuId == "R0"
                                ? _c("i", { staticClass: "fa fa-user" })
                                : item.menuId == "T0"
                                ? _c("i", { staticClass: "fa fa-clock-o" })
                                : item.menuId == "SP"
                                ? _c("i", {
                                    staticClass: "fa fa-shopping-cart",
                                  })
                                : _c("i", { staticClass: "fa fa-circle-o" }),
                              _c("span", [_vm._v(_vm._s(item.menuName))]),
                            ]
                          ),
                    ]
                  : [
                      item.subMenu && item.subMenu.length > 0
                        ? _c("a", { attrs: { href: "#" } }, [
                            item.menuId == "AN"
                              ? _c("i", { staticClass: "fa fa-bullhorn" })
                              : item.menuId == "S0"
                              ? _c("i", {
                                  staticClass: "fa fa-pencil-square-o",
                                })
                              : item.menuId == "C0"
                              ? _c("i", { staticClass: "fa fa-paste" })
                              : item.menuId == "B0"
                              ? _c("i", { staticClass: "fa fa-building-o" })
                              : item.menuId == "D0"
                              ? _c("i", { staticClass: "fa fa-book" })
                              : item.menuId == "E0"
                              ? _c("i", { staticClass: "fa fa-cutlery" })
                              : item.menuId == "K0"
                              ? _c("i", { staticClass: "fa fa-futbol-o" })
                              : item.menuId == "Z0"
                              ? _c("i", { staticClass: "fa fa-cogs" })
                              : item.menuId == "T0"
                              ? _c("i", { staticClass: "fa fa-clock-o" })
                              : _c("i", { staticClass: "fa fa-circle" }),
                            _c("span", [_vm._v(_vm._s(item.menuName))]),
                            _vm.showNotice(item)
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "pull-right-container",
                                    staticStyle: {
                                      transform: "scale(0.7)",
                                      color: "#0073b7",
                                      right: "30px",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-circle pull-right",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._m(1, true),
                          ])
                        : [
                            item.menuUrl.substr(0, 4).toLowerCase() == "http"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: item.menuUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    item.menuId == "G0"
                                      ? _c("i", {
                                          staticClass: "fa fa-envelope-o",
                                        })
                                      : item.menuId == "H0"
                                      ? _c("i", {
                                          staticClass: "fa fa-calendar-check-o",
                                        })
                                      : item.menuId == "R0"
                                      ? _c("i", { staticClass: "fa fa-user" })
                                      : _c("i", {
                                          staticClass: "fa fa-circle-o",
                                        }),
                                    _c("span", [_vm._v(_vm._s(item.menuName))]),
                                  ]
                                )
                              : _c("a", { attrs: { href: item.menuUrl } }, [
                                  item.menuId == "F0"
                                    ? _c("i", {
                                        staticClass: "fa fa-file-text-o",
                                      })
                                    : item.menuId == "J0"
                                    ? _c("i", { staticClass: "fa fa-star" })
                                    : item.menuId == "K0"
                                    ? _c("i", { staticClass: "fa fa-futbol-o" })
                                    : item.menuId == "L0"
                                    ? _c("i", {
                                        staticClass: "fa fa-lightbulb-o",
                                      })
                                    : item.menuId == "T0"
                                    ? _c("i", { staticClass: "fa fa-clock-o" })
                                    : _c("i", {
                                        staticClass: "fa fa-circle-o",
                                      }),
                                  _c("span", [_vm._v(_vm._s(item.menuName))]),
                                ]),
                          ],
                    ],
                item.subMenu && item.subMenu.length > 0
                  ? [
                      _c(
                        "ul",
                        { staticClass: "treeview-menu" },
                        _vm._l(item.subMenu, function (item2, index) {
                          return _c(
                            "li",
                            { key: index, class: _vm.subMenu2(item2) },
                            [
                              item2.isVueMenu == 1
                                ? [
                                    item2.subMenu.length > 0
                                      ? _c("a", { attrs: { href: "#" } }, [
                                          item2.menuId == "E0-01"
                                            ? _c("i", {
                                                staticClass: "fa fa-gear",
                                              })
                                            : item2.menuId == "Z0-01"
                                            ? _c("i", {
                                                staticClass: "fa fa-cog",
                                              })
                                            : item2.menuId == "Z0-02"
                                            ? _c("i", {
                                                staticClass: "fa fa-cog",
                                              })
                                            : item2.menuId == "AT-003"
                                            ? _c("i", {
                                                staticClass: "fa fa-cog",
                                              })
                                            : _c("i", {
                                                staticClass: "fa fa-circle",
                                              }),
                                          _c("span", [
                                            _vm._v(_vm._s(item2.menuName)),
                                          ]),
                                          _vm._m(2, true),
                                        ])
                                      : [
                                          _c(
                                            "router-link",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item2.menuId != "AN-02",
                                                  expression:
                                                    "item2.menuId != 'AN-02'",
                                                },
                                              ],
                                              class: {
                                                "router-link-active":
                                                  (item2.menuUrl.includes(
                                                    "/All/"
                                                  ) &&
                                                    item2.menuUrl.includes(
                                                      _vm.$route.path
                                                    ) &&
                                                    _vm.$route.path !=
                                                      "/OKR/Score") ||
                                                  (item2.menuName ==
                                                    "必修課程" &&
                                                    _vm.$route.path.indexOf(
                                                      "/Student/Course/Required"
                                                    ) > -1) ||
                                                  (item2.menuName ==
                                                    "選修課程" &&
                                                    _vm.$route.path.indexOf(
                                                      "/Student/Course/Elective"
                                                    ) > -1),
                                              },
                                              attrs: {
                                                to: { path: item2.menuUrl },
                                                "data-test": item2.menuUrl,
                                                "data-test2": _vm.$route.path,
                                              },
                                            },
                                            [
                                              item2.menuId == "AN-01"
                                                ? _c("i", {
                                                    staticClass: "fa fa-cog",
                                                    attrs: {
                                                      menuId: item2.menuId,
                                                    },
                                                  })
                                                : item2.menuId == "F0-01"
                                                ? _c("i", {
                                                    staticClass: "fa fa-cog",
                                                  })
                                                : item2.menuId == "Z0-03"
                                                ? _c("i", {
                                                    staticClass: "fa fa-cog",
                                                  })
                                                : item2.menuId == "ET-0" ||
                                                  item2.menuId == "ET-5"
                                                ? _c("i", {
                                                    staticClass: "fa fa-gear",
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "fa fa-circle-o",
                                                  }),
                                              _c("span", [
                                                _vm._v(_vm._s(item2.menuName)),
                                              ]),
                                              item2.menuName === "收件匣"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "menuNum",
                                                      class: {
                                                        hide:
                                                          _vm.flowNoticeTotal ===
                                                          0,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.flowNoticeTotal
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item2.menuName === "考核紀錄"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "menuNum",
                                                      class: {
                                                        hide:
                                                          _vm.okrNoticeTotal ==
                                                          0,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.okrNoticeTotal
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item2.menuName === "必修課程"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "menuNum",
                                                      class: {
                                                        hide:
                                                          _vm.educationNoticeTotal ==
                                                          0,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.educationNoticeTotal
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                  ]
                                : [
                                    item2.subMenu && item2.subMenu.length > 0
                                      ? _c("a", { attrs: { href: "#" } }, [
                                          item2.menuId == "Z0-01"
                                            ? _c("i", {
                                                staticClass: "fa fa-cog",
                                              })
                                            : item2.menuId == "Z0-02"
                                            ? _c("i", {
                                                staticClass: "fa fa-cog",
                                              })
                                            : _c("i", {
                                                staticClass: "fa fa-circle",
                                              }),
                                          _c("span", [
                                            _vm._v(_vm._s(item2.menuName)),
                                          ]),
                                          _vm._m(3, true),
                                        ])
                                      : _c(
                                          "a",
                                          { attrs: { href: item2.menuUrl } },
                                          [
                                            item2.menuId == "Z0-01"
                                              ? _c("i", {
                                                  staticClass: "fa fa-cog",
                                                })
                                              : item2.menuId == "Z0-02"
                                              ? _c("i", {
                                                  staticClass: "fa fa-cog",
                                                })
                                              : _c("i", {
                                                  staticClass: "fa fa-circle-o",
                                                }),
                                            _c("span", [
                                              _vm._v(_vm._s(item2.menuName)),
                                            ]),
                                          ]
                                        ),
                                  ],
                              item2.subMenu && item2.subMenu.length > 0
                                ? [
                                    _c(
                                      "ul",
                                      { staticClass: "treeview-menu" },
                                      _vm._l(
                                        item2.subMenu,
                                        function (item3, index) {
                                          return _c(
                                            "li",
                                            { key: index },
                                            [
                                              item3.isVueMenu == 1
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      class: {
                                                        "router-link-active":
                                                          item3.menuUrl.includes(
                                                            "/All/"
                                                          ) &&
                                                          item3.menuUrl.includes(
                                                            _vm.$route.path
                                                          ),
                                                      },
                                                      attrs: {
                                                        to: {
                                                          path: item3.menuUrl,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      item3.menuId == "E0-017"
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fa fa-dollar",
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "fa fa-circle-o",
                                                          }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item3.menuName)
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: item3.menuUrl,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-circle-o",
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item3.menuName)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pull-right-container" }, [
      _c("i", { staticClass: "fa fa-angle-left pull-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pull-right-container" }, [
      _c("i", { staticClass: "fa fa-angle-left pull-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pull-right-container" }, [
      _c("i", { staticClass: "fa fa-angle-left pull-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pull-right-container" }, [
      _c("i", { staticClass: "fa fa-angle-left pull-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }