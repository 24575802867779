<template>
  <select
    class="form-control"
    required="required"
    v-model="companyIdx"
    @change="$emit('update:companyIdx', companyIdx)"
  >
    <option
      v-for="(dept, index) in companys"
      :key="index"
      :value="dept.companyId"
    >{{ dept.companyName }}</option>
  </select>
</template>
<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  props: {
    propCompanyIdx: 0
  },
  data() {
    return {
      companyIdx: 0,
      companys: []
    };
  },
  mounted() {
    this.refreshPage();
  },
  watch: {
    propCompanyIdx() {
      this.companyIdx = this.propCompanyIdx;
    }
  },
  methods: {
    async refreshPage() {
      var res = await api.organization.getCompanyList({});
      this.companys = res.data.result;
      this.companyIdx = this.propCompanyIdx || this.companys[0].companyId;
      // this.propCompanyIdx == ""
      //   ? this.companys[0].deptId
      //   : this.propCompanyIdx;
    }
  }
};
</script>