var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Shop" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("特約商店區域")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("特約商店區域")]),
      ]),
    ]),
    _c("section", { staticClass: "content seating" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm._m(0),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm._l(5, function (data, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "tc" }, [_vm._v("台中")]),
                        _c("td", { staticClass: "tc" }, [_vm._v("台中")]),
                        _c("td", { staticClass: "tc" }, [_vm._v("1")]),
                        _c("td", { staticClass: "tc" }, [_vm._v("啟用")]),
                        _vm._m(2, true),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          staticClass: "form-control pull-right",
          attrs: { placeholder: "請輸入關鍵字", type: "text" },
        }),
        _c("div", { staticClass: "input-group-btn" }, [
          _c(
            "button",
            { staticClass: "btn btn-default", attrs: { type: "button" } },
            [_c("i", { staticClass: "fa fa-search" })]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc" }, [_vm._v("區域ID")]),
      _c("th", { staticClass: "tc" }, [_vm._v("區域名稱")]),
      _c("th", { staticClass: "tc" }, [_vm._v("排序")]),
      _c("th", { staticClass: "tc" }, [_vm._v("啟用狀態")]),
      _c("th", { staticClass: "tc" }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "tc" }, [
      _c("button", { staticClass: "btn btn-primary mr-10" }, [
        _vm._v("\n                    修改\n                  "),
      ]),
      _c("button", { staticClass: "btn btn-danger" }, [
        _vm._v("\n                    刪除\n                  "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }