var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("特約商店")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("特約商店")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯特約商店"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.storeName,
                          expression: "data.storeName",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.data.storeName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "storeName", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputDocNum" },
                    },
                    [_vm._v("\n                分店名稱：\n              ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.applyArea,
                          expression: "data.applyArea",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.data.applyArea },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "applyArea", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.storeType,
                            expression: "data.storeType",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "storeType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", hidden: "" } }, [
                          _vm._v("請選擇分類"),
                        ]),
                        _vm._l(_vm.categoryList, function (category, index) {
                          return _c(
                            "option",
                            { domProps: { value: category.classId } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(category.className) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.companyType,
                            expression: "data.companyType",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "companyType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", hidden: "" } }, [
                          _vm._v("請選擇區域"),
                        ]),
                        _vm._l(_vm.areaList, function (area, index) {
                          return _c(
                            "option",
                            { domProps: { value: area.classId } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(area.className) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: 0,
                              message: "到期時間：",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.data.isNoLimitContract,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "isNoLimitContract", $$v)
                              },
                              expression: "data.isNoLimitContract",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              model: {
                                value: _vm.data.contractTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "contractTime", $$v)
                                },
                                expression: "data.contractTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: 1,
                            message: "永久",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.data.isNoLimitContract,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "isNoLimitContract", $$v)
                            },
                            expression: "data.isNoLimitContract",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("tinymce", {
                          model: {
                            value: _vm.data.discountOffers,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.data,
                                "discountOffers",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "data.discountOffers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputFile" },
                    },
                    [_vm._v("上傳商店主圖：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-10 text" },
                    [
                      !_vm.hasImage()
                        ? _c("input", {
                            attrs: {
                              accept: "image/*",
                              id: "inputPic",
                              type: "file",
                              name: "pic",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeFile($event, "image")
                              },
                            },
                          })
                        : _vm._l(_vm.data.files, function (file) {
                            return _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "end",
                                },
                              },
                              [
                                _vm.global.isImage(file.mine)
                                  ? [
                                      _c("img", {
                                        staticClass: "mt-10",
                                        staticStyle: {
                                          width: "150px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          src:
                                            "https://dev-eip.wanin.tw/" +
                                            file.filesUrl,
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteFile(
                                                file.applyId,
                                                "image"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      刪除\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputFile" },
                    },
                    [_vm._v("上傳合約檔案：")]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-10 text" },
                    [
                      _c("input", {
                        attrs: {
                          id: "inputFile",
                          maxlength: "5",
                          type: "file",
                          name: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeFile($event)
                          },
                        },
                      }),
                      _vm._l(_vm.data.contracts, function (file) {
                        return _c("div", { staticClass: "mt-10" }, [
                          !_vm.global.isImage(file.mine)
                            ? _c(
                                "a",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: {
                                    href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(file.name) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            : _c("img", {
                                staticClass: "mt-10",
                                staticStyle: {
                                  width: "150px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  src:
                                    "https://dev-eip.wanin.tw/" + file.filesUrl,
                                },
                              }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFile(file.applyId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    刪除\n                  "
                              ),
                            ]
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("\n                儲存\n              ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputDocNum" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 商店名稱：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "selectUnit" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 分類：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "selectUnit" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 使用區域：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputDocNum" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 合約期限：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容：\n              "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }