<template>
  <div id="RollCallSystem">
    <section class="content-header">
      <h1>線上點名</h1>

      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">線上點名</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-warning max550">
        <div class="box-body">
          <div class="top-box box-header with-border">
            <button
              @click="AddActionWork()"
              class="btn btn-block btn-warning btn-add btn-set"
              type="button"
              v-if="hasEditPermission"
            >
              <i class="fa fa-cog"></i> 移動式作業人員設定
            </button>
          </div>

          <div class="table-responsive mt-10 tc">
            <div class="dateDate">
              <span class="date">{{formatTime(timenow, "YYYY-MM-DD")}}</span>
              <span class="week">{{formatTime(timenow, "dddd")}}</span>
            </div>
            <!-- 時鐘 -->
            <div class="clock" id="Call-Clock">
              <span>{{formatTime(timenow, "HH").substr(0,1)}}</span>
              <span>{{formatTime(timenow, "HH").substr(1)}}</span>
              <span>{{formatTime(timenow, "mm").substr(0,1)}}</span>
              <span>{{formatTime(timenow, "mm").substr(1)}}</span>
              <span>{{formatTime(timenow, "ss").substr(0,1)}}</span>
              <span>{{formatTime(timenow, "ss").substr(1)}}</span>
            </div>

            <!-- <div style="width:300px;height:20px; margin:40px auto;">
              <label style="font-size: xx-large;">{{timenow}}</label>
            </div>-->

            <button
              @click="RollCall()"
              class="btn btn-block btn-warning btn-add btn-signIn"
              type="button"
              :disabled="!isActionWork"
            >簽到</button>

            <div
              style="width:300px;height:20px; margin:40px auto; text-align: center;"
              v-show="!isActionWork"
            >
              <label style="color:red;">此帳號目前非移動式作業人員</label>
            </div>

            <table class="table table-hover table-bordered table-call" v-if="isActionWork">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="min-width: 90px; width: 5%">今日簽到歷程</th>
                </tr>
                <template v-if="rollcallrecords.total > 0">
                  <tr :key="item.id" v-for="item in rollcallrecords.datas">
                    <td align="center">{{ formatTime(item.checkinTime)}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";

export default {
  components: {},
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  watch: {},
  data() {
    return {
      userData: this.$store.state.loginUser,
      timenow: "",
      rollcallrecords: {},
      hasEditPermission: false,
      isActionWork: false
    };
  },
  created() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.ActionWorkSystem
    );
    this.CheckActionWorkMenber();
  },
  mounted() {
    var _this = this;
    var timer = setInterval(function() {
      _this.timenow = _this.formatTime(
        new moment(_this.timenow).add(1, "second")
      );
    }, 1000);

    window.onfocus = function() {
      if(_this.$route.name != "RollCallSystem") return
      _this.CheckActionWorkMenber();
    }
  },
  methods: {
    async GetRollCallRecord() {
      var result = await api.rollcall.queryAll({
        startTime: new moment().format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59")
      });
      if (result.data.status == "1") this.rollcallrecords = result.data.result;
    },
    CheckActionWorkMenber() {
      api.rollcall.employeeCheck().then(res => {
        if (res.data.status == "1") {
          this.isActionWork = res.data.result.pass;
          this.timenow = this.formatTime(res.data.result.time);

          this.GetRollCallRecord();
        }
      });
    },
    RollCall() {
      api.rollcall.checkcreate().then(res => {
        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", "簽到成功!");
          this.GetRollCallRecord();
        } else {
          this.$eventBus.$emit("showAlert", res.data.message);
        }
      });
    },
    formatTime: function(t, format = "YYYY-MM-DD HH:mm:ss") {
      return utils.formatTime(t, format);
    },
    AddActionWork() {
      if (this.hasEditPermission) {
        this.$router.push({
          name: "ActionWorkSystem"
        });
        return;
      }
    }
  },
};
</script>
