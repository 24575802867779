<template>
  <div>
    <div class="form-group">
      <label class="col-sm-2 control-label" for="inputFile">上傳檔案：</label>
      <div class="col-sm-10 text">
        <input
          @change="onFileChange($event)"
          accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip"
          id="inputFile"
          maxlength="5"
          multiple="multiple"
          name="file1"
          type="file"
        />
        <div class="help-block">
          文件檔案上傳格式：
          <span class="text-red">(上限20MB)</span>
          <br />1.WORD：DOC、DOCX。
          <br />2.EXCEL：XLS、XLSX。
          <br />3.POWERPOINT：PPT、PPTX。
          <br />4.PDF。
          <br />5.TXT。
          <br />6.XPS。
          <br />7.ZIP。
          <br />(每個檔案上限20MB，最多可以選5個檔案)
        </div>
        <!-- <button @click="removeFile()" class="btn btn-danger mt-10" type="button">取消上傳</button> -->
      </div>
    </div>
    <div class="form-group" v-show="files.length > 0">
      <label class="col-sm-2 control-label" for="inputFile">已有檔案：</label>
      <div class="col-sm-10 text">
        <table class="table table-bordered">
          <tbody>
            <tr :key="index" v-for="(file, index) in files">
              <td>{{file.name}}</td>
              <td>
                <button class="btn btn-danger" type="button" @click="deleteFile(file.applyId)">刪除</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
// import utils from "@/assets/js/app-utils"; 尚未使用到

export default {
  components: {},
  props: {
    propFileId: ""
  },
  delimiters: ["${", "}"],
  data() {
    return {
      isShow: 1,
      files: [],
      module: "CompanyDocument"
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      var response_queryall = await api.file.queryApplyAll({
        module: this.module,
        moduleId: this.propFileId
      });
      this.files = response_queryall.data.result;
    },
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length + this.files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您檔案數量超過上限5個，請重新選擇。"
        );
        this.removeFile();
        return;
      } else if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 100000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 100 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }
      this.uploadFile(files[0], this.module, this.propFileId, true);
      this.isShow = 1;
    },
    removeFile() {
      document.getElementById("inputFile").value = "";
    },
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      if (response_upload.data.status == "1") {
        // $("#attention").modal("hide");
        this.refresh();
      } else {
        // console.log("Delete error!");
      }
      //this.edit.id = response_upload.data.result.id;
      //this.refreshFileList();
    },
    async deleteFile(applyId) {
      //刪除檔案
      // $("#attention-message").html("刪除後將無法復原，請確認是否刪除？");
      // $("#attention-delete").show();
      // $("#attention").modal("show");
      // $("#attention-delete").click(async () => {
      //   var response_delete = await api.file.delete({ id: applyId });

      //   if (response_delete.data.status == "1") {
      //     $("#attention").modal("hide");

      //     this.refreshFileList();
      //   } else {
      //     console.log("Delete error!");
      //   }
      // });
      var response_delete = await api.file.deleteApply({ id: applyId });
      if (response_delete.data.status == "1") {
        // $("#attention").modal("hide");
        this.refresh();
      } else {
        // console.log("Delete error!");
      }
    }
  }
};
</script>
