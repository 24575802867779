import Vue from 'vue';
import moment from 'moment';
import define from '@/views/OKR/_Define';

Vue.filter('dateFormat', (value, format = 'YYYY-MM-DD') => {
    const datetime = moment(value);
    return value ? datetime.format(format) : '';
});

Vue.filter('currencyFormat', (value) => {
    return value && !isNaN(value) ? value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : value;
});

Vue.filter('ellipsis', (value, limit) => {
    value = value || '';
    limit = limit || value.length;
    return value.length > limit
        ? `${value.substring(0, limit)}...`
        : value;
});

Vue.prototype.define = define;
Vue.prototype.global = {
    getTime(dateTime) {
        const day = dateTime ? new Date(dateTime) : new Date();
        return day.toLocaleTimeString('en-US', {
            timeStyle: 'short',
        });
    },
    isValidDay(day) {
        return !day ? false : isNaN(day.getTime()) ? false : true;
    },
    isValidFutureDay(day, offset) {
        const today = offset ? moment(new Date().toJSON().replace('Z', '')).utcOffset(offset)._d : new Date();
        return this.isValidDay(day) && day > today ? true : false;
    },
    isValidDayRange(startDay, endDay, required = true) {
        if (this.isValidDay(startDay) && this.isValidDay(endDay)) {
            return endDay >= startDay;
        } else if (!startDay && !endDay && !required) {
            return true;
        }
        return false;
    },
    getDistinctData(items, key) {
        const datas = [];
        const flags = [];
        items.forEach(item => {
            if (!flags.includes(item[key])) {
                flags.push(item[key]);
                datas.push(item);
            }
        });
        return datas;
    },
    getYearList(more) {
        const year = new Date().getFullYear();
        const years = [];
        years.push(year + 1);
        years.push(year);
        years.push(year - 1);
        if (more) {
            years.push(year - 2);
            years.push(year - 3);
        }
        return years;
    },
    getYear() {
        return new Date().getFullYear();
    },
    getNextYear() {
        return new Date().getFullYear() + 1;
    },
    getNowHalfYear(m) {
        const month = m || this.getMonth();
        return month === 12 ? this.getYear() + 1 : this.getYear();
    },
    getHalfYear(m) {
        const month = m || this.getMonth();
        return month === 12 || month < 6 ? 0 : 1;
    },
    getNextHalfYear() {
        return this.getHalfYear() === 0 ? 1 : 0;
    },
    getMonth(months) {
        let month = new Date().getMonth() + 1;
        if (months) {
            const list = months.filter(x => x <= month);
            month = list[list.length - 1];
        }
        return month;
    },
    getPermission(userData, limit) {
        const type = userData.user.deptType.slice(1);
        const allIds = [];
        const allList = [];
        const deptIds = [];
        const deptList = [];
        userData.deptEmp.forEach(dept => {
            // if (dept.companyId === define.companyId) {
                allIds.push(dept.deptId);
                allList.push(dept);
                // if (this.hasSameDept(dept.deptType, limit)) {
                    deptIds.push(dept.deptId);
                    deptList.push(dept);
                // }
            // }
        });
        return {
            allIds: allIds,
            allList: allList,
            deptIds: deptIds,
            deptList: deptList,
            hasOver: isNaN(type) ? true : Number(type) < limit ? true : false,
            hasHigh: isNaN(type) ? true : Number(type) <= limit ? true : false,
            isHR: userData.user.isHR == "1",
            isEmployee: userData.user.isDeptManager === '0' && userData.user.isDivisionManager ==='0',
            isDeptManager: Number(type) === 60 && userData.user.isDeptManager === '1',
            isDivisionManager: userData.user.isDivisionManager ==='1',
        };
    },
    hasSameDept(deptType, limit) {
        const type = deptType.slice(1);
        return isNaN(type) ? false : limit === 60 ? Number(type) >= limit : Number(type) === limit ? true : false;
    },
    getDivisionId(deptId) {
        return deptId ? `${deptId.slice(0, deptId.length - 1)}0` : '';
    },
    getDeptId(list, userData, permission) {
        if (list.find((x) => x.deptId === userData.user.deptId)) {
            return userData.user.traineeDeptId || userData.user.deptId;
        } else if (!permission.hasOver) {
            if (list.find((x) => x.deptId === userData.user.parentDeptId)) {
                return userData.user.parentDeptId;
            }
        } else {
            const dept = userData.deptEmp.find((x) => x.deptId !== userData.user.deptId);
            const deptId = dept ? dept.deptId : `${userData.user.deptId.slice(0, 2)}1`;
            const item = list.find((x) => x.deptId === deptId);
            if (item) return item.deptId;
        }
        return '';
    },
    getEmployeeId(users, userData) {
        if (users.find((x) => x.employeeId === userData.user.employeeId)) {
            return userData.user.employeeId;
        }
        return '';
    },
    getPerson(type) {
        return define.personalType[type || 'Personal'];
    },
    showResponse(self, res, action, callback) {
        if (res.data.status === '1') {
            if (action) {
                self.$eventBus.$emit('showAlert', res.data.message || `${this.getMessage(action)}成功`);
            }
            if (callback) {
                callback();
            }
        } else {
            self.$eventBus.$emit('showAlert', res.data.message || `${this.getMessage(action)}失敗`);
        }
    },
    getMessage(action) {
        switch (action) {
            case 'create':
                return '新增';
            case 'update':
                return '修改';
            case 'delete':
                return '刪除';
            case 'send':
                return '送出';
            default:
                return '執行';
        }
    },
    getSignData(data) {
        const result = {};
        switch (data.formTypeId) {
            case '處級目標': {
                result.id = data.createDeptId;
                result.name = 'DivisionEdit';
                break;
            }
            case '部級目標': {
                result.id = data.createDeptId;
                result.name = 'DeptEdit';
                break;
            }
            case '新人目標': {
                result.id = data.createEmpId;
                result.type = 'NewCrew';
                result.name = 'PersonalEdit';
                break;
            }
            case '實習生目標': {
                result.id = data.createEmpId;
                result.type = 'Trainee';
                result.name = 'PersonalEdit';
                break;
            }
            case '人員目標': {
                result.id = data.createEmpId;
                result.type = 'Personal';
                result.name = 'PersonalEdit';
                break;
            }
            case '新人轉正': {
                result.id = data.createEmpId;
                result.type = 'Personal';
                result.name = 'NewJobEdit';
                break;
            }
            case '新人考核': {
                result.id = data.createEmpId;
                result.type = 'NewCrew';
                result.name = 'ScorePersonalEdit';
                break;
            }
            case '實習生小考': {
                result.id = data.createEmpId;
                result.type = 'Trainee';
                result.name = 'ScorePersonalEdit';
                break;
            }
            case '人員小考': {
                result.id = data.createEmpId;
                result.type = 'Personal';
                result.name = 'ScorePersonalEdit';
                break;
            }
            case '部級小考': {
                result.id = data.createEmpId;
                result.type = 'Department';
                result.name = 'ScorePersonalEdit';
                break;
            }
            case '實習生大考': {
                result.id = data.createEmpId;
                result.type = 'Trainee';
                result.name = 'BigScorePersonalEdit';
                break;
            }
            case '人員大考': {
                result.id = data.createEmpId;
                result.type = 'Personal';
                result.name = 'BigScorePersonalEdit';
                break;
            }
            case '部級大考': {
                result.id = data.createEmpId;
                result.type = 'Department';
                result.name = 'BigScorePersonalEdit';
                break;
            }
            case '處級大考': {
                result.id = data.createEmpId;
                result.type = 'Top';
                result.name = 'BigScoreCenterEdit';
                break;
            }
        }
        return result;
    },
    isImage(type) {
        let imageList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff', 'jfif']
        return imageList.includes(type)
    }
};
