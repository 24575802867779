<template>
  <div class="modal fade" ref="scoreAddContent">
    <div class="modal-dialog  modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            {{ data.employeeName }} - 
            <span>{{ searchData.year }}</span>
            <span>{{ searchData.month == 0 ? '上半年' : '下半年' }}</span>
          </h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-hover table-bordered min-800">
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc mw100">起單人員部門</th>
                    <th class="tc mw100">起單人員員工編號</th>
                    <th class="tc mw100">起單人員</th>
                    <th class="tc mw100">審核人員部門</th>
                    <th class="tc mw100">審核人員員工編號</th>
                    <th class="tc mw100">審核人員</th>
                    <th class="tc mw50">加分</th>
                    <th class="tc mw150">說明</th>
                    <th class="tc mw150">送簽時間</th>
                    <th class="tc mw100">功能</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="tc" colspan="8">查詢中</td>
                  </tr>
                  <tr v-else-if="list.length === 0">
                    <td class="tc" colspan="8">查詢無任何資料</td>
                  </tr>
                  <tr v-else v-for="(item, index) in list" :key="index">
                    <td class="tc">{{ item.startDeptName }}</td>
                    <td class="tc">{{ item.startEmpId }}</td>
                    <td class="tc">{{ item.startEmpName }}</td>
                    <td class="tc">{{ item.createDeptName }}</td>
                    <td class="tc">{{ item.createEmpId }}</td>
                    <td class="tc">{{ item.createEmpName }}</td>
                    <td class="tc">{{ item.totalScore }}</td>
                    <td class="tc text-primary">
                      {{ item.note || '-'}}
                    </td>
                    <td class="tc">
                      <span v-if="item.createTime">{{ new Date(item.createTime) | dateFormat("YYYY/MM/DD HH:mm:ss") }}</span>
                      <span v-else> - </span>
                    </td>
                    <td class="tc">
                      <button
                        class="btn btn-primary"
                        @click="goContent(item)"
                      >
                        檢視
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props:['users'],
  data() {
    return {
      loading: false,
      data: {},
      searchData: {
        year: this.global.getNowHalfYear(),
        month: this.global.getHalfYear(),
        employeeId: '',
      },
      list: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async showContent(data, searchData) {
      this.data = data
      this.searchData.employeeId = data.employeeId
      this.searchData.year = searchData.year
      this.searchData.month = searchData.month
      this.getList();
      $(this.$refs.scoreAddContent).modal("show");
    },
    getList() {
      this.loading = true;
      api.okr.v2.history.queryRater(this.searchData)
        .then((res) => {
          this.loading = false;
          if (res.data.result) {
            this.list = res.data.result.datas
          }
        })
    },
    goContent(item) {
      $(this.$refs.scoreAddContent).modal("hide");
      this.$router.push({
        name: 'ScoreEdit',
        params: {
          id: this.data.employeeId,
          year: this.searchData.year,
          month: this.searchData.month,
          formId: item.formId,
          formType: item.formTypeId,
          back: this.$route.name,
          data: item,
          listType: 'read'
        }
      })
    }
  },
};
</script>
<style scoped>
@media (min-width: 1200px) {
  .modal-dialog {
    width: 1200px;
  }
}
</style>