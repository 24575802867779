<template>
  <div class="modal fade" ref="groupContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ this.isEdit ? "編輯" : "新增" }}群組</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label w90">
                      <span class="text-red">*</span>
                      部別
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="(single && exist) || isEdit"
                        v-model="data.deptId"
                        @change="getUserList()"
                      >
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      選擇成員
                    </td>
                    <td>
                      <select2-multiple
                        data-placeholder="請選擇人員"
                        :options="users"
                        :disabled="!exist && isEdit"
                        v-model="members"
                      ></select2-multiple>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      群組名稱
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        :disabled="!exist && isEdit"
                        v-model="data.groupName"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary"
              type="submit"
              v-if="exist || !isEdit"
            >
              儲存
            </button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import select2Multiple from "@/components/select2Multiple";
import api from "@/assets/js/app-api";

export default {
  components: {
    "select2-multiple": select2Multiple,
  },
  data() {
    return {
      isEdit: false,
      exist: false,
      single: false,
      list: [],
      users: [],
      userIds: [],
      members: [],
      data: {},
    };
  },
  methods: {
    async showContent(data, addData) {
      this.isEdit = data ? true : false;
      this.data = data ? data : addData;
      this.data = JSON.parse(JSON.stringify(this.data));
      this.getList(addData);
      $(this.$refs.groupContent).modal("show");
    },
    getList(addData) {
      this.exist =
        addData.permission.deptIds.includes(this.data.deptId) ||
        addData.permission.isHR;
      if ((this.exist || !this.isEdit) && !addData.permission.isHR) {
        if (addData.permission.deptIds.length === 1) this.single = true;
        this.list = addData.permission.deptList;
        this.getUserList();
      } else {
        api.okr.goal.department.list({ deptLevel: 0 }).then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
            this.getUserList();
          }
        });
      }
    },
    getUserList() {
      api.organization.queryEmployeeAll(this.data).then((res) => {
        if (res.data.status === "1") {
          this.updateUsers(res.data.result.datas);
          this.getData();
        }
      });
    },
    getData() {
      if (this.isEdit) {
        api.okr.mgmt.group.query(this.data).then((res) => {
          if (res.data.status === "1") {
            this.data = res.data.result || {};
            this.updateUserMembers();
            this.updateMembers();
          }
        });
      }
    },
    updateUsers(users) {
      this.users = [];
      this.userIds = [];
      users.forEach((user) => {
        this.userIds.push(user.employeeId);
        this.users.push({
          id: user.employeeId,
          text: user.employeeName,
        });
      });
    },
    updateUserMembers() {
      this.data.groupMembers.forEach((member) => {
        if (!this.userIds.includes(member.empId)) {
          this.userIds.push(member.empId);
          this.users.push({
            id: member.empId,
            text: member.empName,
          });
        }
      });
    },
    updateMembers() {
      setTimeout(() => {
        this.members = [];
        this.data.groupMembers.forEach((member) => {
          this.members.push(member.empId);
        });
      }, 100);
    },
    updateGroupMembers() {
      this.data.groupMembers = [];
      this.members.forEach((member) => {
        this.data.groupMembers.push({
          empId: member,
        });
      });
    },
    submit() {
      this.updateGroupMembers();
      const action = this.isEdit ? "update" : "create";
      api.okr.mgmt.group[action](this.data).then((res) => {
        this.global.showResponse(this, res, "update");
        $(this.$refs.groupContent).modal("hide");
        this.$emit("updateList");
      });
    },
  },
};
</script>
