<template>
  <label>
    <input class="flat" :name="name" type="radio" :value="checkValue" :disabled="disabled" />
    <span>{{ message }}</span>
  </label>
</template>

<script>
export default {
  props: ["checkValue", "message", "name", "value", "disabled"],
  data() {
    return {
      isChecked: "checkValue",
    };
  },
  mounted() {
    const that = this;
    that.setStatus(that.value);
    $(that.$el).on("ifToggled", function (e) {
      that.setStatus(that.checkValue);
    });

    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });
  },
  watch: {
    value(value) {
      this.setStatus(value);
    },
  },
  methods: {
    setStatus(isChecked) {
      this.isChecked = isChecked;
      if (this.checkValue == this.isChecked) {
        $(this.$el).iCheck("toggle");
        this.$emit("input", this.isChecked);
      }
    },
  },
};
</script>
