/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import user from '@/assets/js/app-user';
import utils from '@/assets/js/app-utils';
import eventBus from '@/assets/js/event-bus';

import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh'

import "vue-multiselect/dist/vue-multiselect.min.css";

// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';

import 'font-awesome/css/font-awesome.min.css'

import './assets/js/filter.js';
import './assets/js/imgLiquid.js'

// bootstrap
import 'admin-lte/node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'admin-lte/node_modules/bootstrap/dist/js/bootstrap.js'

// slimscroll
import 'admin-lte/bower_components/jquery-slimscroll/jquery.slimscroll.min.js'

// datepicker
import 'admin-lte/bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
import 'admin-lte/bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'

// daterangepicker
import 'admin-lte/bower_components/bootstrap-daterangepicker/daterangepicker.js'
import 'admin-lte/bower_components/bootstrap-daterangepicker/daterangepicker.css'

// timepicker
import 'admin-lte/plugins/timepicker/bootstrap-timepicker.min.css'
import 'admin-lte/plugins/timepicker/bootstrap-timepicker.min.js'

// iCheck
import 'admin-lte/plugins/iCheck/all.css'
import 'admin-lte/plugins/iCheck/icheck.min.js'

// select2
import 'admin-lte/bower_components/select2/dist/css/select2.min.css'
import 'select2/dist/js/select2.full.min.js'

// datatables
import 'admin-lte/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css'
import 'admin-lte/bower_components/datatables.net/js/jquery.dataTables.js'
import 'admin-lte/bower_components/datatables.net-bs/js/dataTables.bootstrap.min.js'

// switchery
import 'switchery/switchery.css'
import 'switchery/switchery.js'

// css-checkbox-library
import 'css-checkbox-library/dist/css/checkboxes.css'

// multiselect
import "vue-multiselect/dist/vue-multiselect.min.css";

// AdminLTE
import 'admin-lte/dist/css/AdminLTE.min.css'
import 'admin-lte/dist/css/skins/_all-skins.min.css'
// import 'admin-lte/dist/js/adminlte.min.js'
import 'admin-lte/dist/js/adminlte.js'

import "../public/js/jquery.animatedheadline.js";
import "../public/css/jquery.animatedheadline.css";

import './assets/scss/all.scss'

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(user);
Vue.use(eventBus);
Vue.use(utils);
// Vue.component('v-select', vSelect)

Vue.prototype.hideLoading = () => {
  $("#loading").removeClass("is-active");
};

Vue.prototype.showLoading = () => {
  $("#loading").addClass("is-active");
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#App')