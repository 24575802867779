var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "edit_room",
      staticClass: "modal fade",
      attrs: { id: "meetingRoomEdit" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("form", [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-striped" },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v("會議室名稱")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.roomName,
                                expression: "edit.roomName",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "required", type: "text" },
                            domProps: { value: _vm.edit.roomName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "roomName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("設備")]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("iCheckMultiCheckBox", {
                                attrs: {
                                  propOptions: _vm.tools,
                                  propSelecteds: _vm.edit.toolsContainAry,
                                },
                                on: {
                                  "update:propSelecteds": function ($event) {
                                    return _vm.$set(
                                      _vm.edit,
                                      "toolsContainAry",
                                      $event
                                    )
                                  },
                                  "update:prop-selecteds": function ($event) {
                                    return _vm.$set(
                                      _vm.edit,
                                      "toolsContainAry",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._m(1),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("座位(人)")]),
                        _c("td", [
                          _c("div", { staticClass: "form-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit.seatCount,
                                  expression: "edit.seatCount",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                max: "100",
                                min: "1",
                                required: "required",
                                type: "number",
                              },
                              domProps: { value: _vm.edit.seatCount },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.edit,
                                    "seatCount",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "help-block" }, [
                              _vm._v("不得為空"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("停用")]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "form-group checkbox-inline" },
                            [
                              _c("iCheckCheckBox", {
                                attrs: { propChecked: _vm.edit.isStop },
                                on: {
                                  "update:checked": function ($event) {
                                    _vm.edit.isStop = $event["checked"]
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", id: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.editMeetingRoom()
                  },
                },
              },
              [_vm._v("\n          確定新增(修改)\n        ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v("\n          取消\n        ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("新增(修改)會議室")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-orange" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(
        "\n                      如需新增設備請洽系統開發人員。\n                    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }