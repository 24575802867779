var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ScorePersonalEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("加分考核表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("\n            加分考核表\n          ")]),
        _c("li", { staticClass: "active" }, [
          _vm._v(
            "\n          " +
              _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
              "考核\n        "
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left" }, [
              _vm._v(
                "\n              " +
                  _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
                  "考核\n            "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v("\n              返回列表\n            "),
              ]
            ),
          ]),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { "max-width": "1400px", margin: "auto" },
              },
              [
                _c("table", { staticClass: "table no-border" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c(
                          "table",
                          {
                            staticClass: "table no-border",
                            staticStyle: { width: "400px" },
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", { staticClass: "tr w90" }, [
                                  _vm._v("員工編號："),
                                ]),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(_vm.data.employeeId),
                                  },
                                }),
                                _c("th", { staticClass: "tr" }, [
                                  _vm._v("部別："),
                                ]),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(_vm.data.deptName),
                                  },
                                }),
                              ]),
                              _c("tr", [
                                _c("th", { staticClass: "tr" }, [
                                  _vm._v("員工姓名："),
                                ]),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(_vm.data.employeeName),
                                  },
                                }),
                                _c("th", { staticClass: "tr" }, [
                                  _vm._v("狀態："),
                                ]),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.define.sendStatus[
                                        _vm.data.scoreStatus || 0
                                      ]
                                    ),
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._m(0),
                    ]),
                  ]),
                ]),
                _c("div", [
                  _c("div", { staticClass: "box-footer mt-15" }),
                  _c("form", { staticClass: "form-horizontal" }, [
                    _c("div", { staticClass: "box-body" }, [
                      _c("div", { staticClass: "form-group mb0" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-hover table-bordered",
                                staticStyle: { margin: "10px auto 20px" },
                              },
                              [
                                _c(
                                  "tbody",
                                  [
                                    _vm._m(1),
                                    _vm._l(_vm.scoreList, function (item) {
                                      return _vm.scoreList.length > 0
                                        ? _c("tr", [
                                            _c("td", { staticClass: "tc" }, [
                                              _vm._v(
                                                _vm._s(item.createEmpName)
                                              ),
                                            ]),
                                            _c("td", { staticClass: "tc" }, [
                                              _vm.hasEdit &&
                                              item.createEmpId ==
                                                _vm.userData.user.employeeId
                                                ? _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: item.totalScore,
                                                        expression:
                                                          "item.totalScore",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control fw-b fz25",
                                                    attrs: {
                                                      type: "number",
                                                      max: "3",
                                                      min: "-3",
                                                    },
                                                    domProps: {
                                                      value: item.totalScore,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          item,
                                                          "totalScore",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          item.totalScore
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                            ]),
                                            _c("td", { staticClass: "tc" }, [
                                              _vm.hasEdit &&
                                              item.createEmpId ==
                                                _vm.userData.user.employeeId
                                                ? _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: item.note,
                                                        expression: "item.note",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    domProps: {
                                                      value: item.note,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          item,
                                                          "note",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(item.note) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                            ]),
                                          ])
                                        : _vm._e()
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c("SignLog", {
                      attrs: {
                        datas: _vm.scoreList,
                        data: _vm.data,
                        hasEdit: _vm.hasEdit,
                        backList: _vm.backList,
                        scoreDatas: _vm.scoreList,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("table", { staticClass: "table table-bordered" }, [
        _c("tbody", [
          _c("tr", [
            _c("th", { staticClass: "tc mw100 bg-gray" }, [_vm._v("考核說明")]),
            _c("td", { staticClass: "mw50" }, [
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t1.請於『說明』欄位中描述清楚人員加減分之緣由。"
              ),
              _c("br"),
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t2.單次加減分分數範圍以正負1~3分為限。"
              ),
              _c("br"),
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t3.考核加減分可隨時進行，且無次數限制，人員可能會有多筆考核紀錄。\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "tc mw50" }, [_vm._v("加分人員")]),
      _c("th", { staticClass: "tc mw50", staticStyle: { width: "150px" } }, [
        _vm._v("分數"),
      ]),
      _c("th", { staticClass: "tc mw150" }, [_vm._v("說明")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }