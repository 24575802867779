var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Account" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("帳號設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("系統管理")]),
          _c("li", [_vm._v("帳號管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("帳號設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content account" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box has-select" }, [
              _c("div", { staticClass: "right clearfix" }, [
                _vm.searchKey.checkOffice == ""
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-danger btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.searchNoSetOffice("1")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-warning" }),
                        _vm._v(" 未設定辦公室\n            "),
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-info btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.searchNoSetOffice("")
                          },
                        },
                      },
                      [_vm._v("\n              關閉尚未設定\n              ")]
                    ),
                _c("div", { staticClass: "synchronize" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.employeeId,
                        expression: "employeeId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "輸入員工編號" },
                    domProps: { value: _vm.employeeId },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.employeeId = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: { click: _vm.SyncData },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-circle-down" }),
                      _vm._v(" 同步人事資料\n              "),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "left clearfix" }, [
                _vm.$user.getUserCompanyId() === "A"
                  ? _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchKey.companyId,
                              expression: "searchKey.companyId",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchKey,
                                  "companyId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.selectCompany()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.companyOptions, function (company, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: company.companyId },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(company.companyName) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchKey.deptId,
                          expression: "searchKey.deptId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchKey,
                              "deptId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.selectDept()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("請選擇部門"),
                      ]),
                      _vm._l(_vm.depts, function (dept, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: dept.deptId } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(dept.deptIdName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchKey.keyword,
                        expression: "searchKey.keyword",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: {
                      name: "table_search",
                      placeholder: "搜尋姓名",
                      type: "text",
                    },
                    domProps: { value: _vm.searchKey.keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.searchKey, "keyword", $event.target.value)
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.search },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "checkbox",
                    staticStyle: {
                      display: "inline-block",
                      margin: "6px 10px",
                    },
                  },
                  [
                    _c(
                      "label",
                      [
                        _c("iCheckCheckBox", {
                          attrs: {
                            propValue: true,
                            propChecked: _vm.searchKey.includeLeft,
                          },
                          on: { "update:checked": _vm.checkAll },
                        }),
                      ],
                      1
                    ),
                    _vm._v("\n              包含離職\n            "),
                  ]
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數：" + _vm._s(_vm.total)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-hover table-bordered table-striped min-800",
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm._l(_vm.queryEmployeeAll.datas, function (emp, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(emp.employeeTypeName))]),
                          _c("td", [_vm._v(_vm._s(emp.userid))]),
                          _c("td", [_vm._v(_vm._s(emp.employeeId))]),
                          _c("td", [_vm._v(_vm._s(emp.employeeName))]),
                          _c("td", [_vm._v(_vm._s(emp.deptName))]),
                          emp.office != null && emp.office != ""
                            ? _c("td", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(emp.office) +
                                    "\n                "
                                ),
                              ])
                            : _c("td", { staticClass: "text-red" }, [
                                _vm._v("尚未設定"),
                              ]),
                          _c("td", [_vm._v(_vm._s(emp.empTitleName))]),
                          _c("td", [_vm._v(_vm._s(emp.empEmail))]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.formatTime(emp.loginTime))),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.openEditWindow(emp.employeeId)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    編輯\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer clearfix text-center" },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchKey.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("accountEdit", {
        ref: "accountEdit",
        on: { refreshList: _vm.getQueryEmployeeAll },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px" } }, [_vm._v("員工狀態")]),
      _c("th", { staticStyle: { "min-width": "90px" } }, [_vm._v("帳號")]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("工號")]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("姓名")]),
      _c("th", [_vm._v("部門")]),
      _c("th", [_vm._v("辦公室")]),
      _c("th", [_vm._v("職稱")]),
      _c("th", [_vm._v("電子郵件")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [
        _vm._v("最後登入時間"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px" },
        },
        [_vm._v("\n                  功能\n                ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }