<template>
  <div>
    <Header />
    <SideBar />
    <div class="control-sidebar-bg"></div>
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import '../main.js'
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import Footer from '../components/Footer';

export default {
  components: {
    Header,
    SideBar,
    Footer,
  },
};
</script>
