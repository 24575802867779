<template>
  <div id="TraineeList">
    <section class="content-header">
      <h1>實習生指派設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">實習生指派設定</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning" style="width: 1050px">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="createData()"
              >
                <i class="fa fa-plus"></i>
                新增設定
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢：</label>
                <select
                  class="form-control"
                  v-model="selectDeptId"
                  :disabled="searchData.status === 0"
                >
                  <option value="">全部</option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">#</th>
                  <th class="tc">員工編號</th>
                  <th class="tc" width="30%">歸屬部別</th>
                  <th class="tc" width="20%">姓名</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="5">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="5">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="index + 1"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-if="data.isEdit">
                    <select
                      class="form-control"
                      v-model="assignDeptId"
                      @change="changeAssignDeptId(data)"
                    >
                      <option value="">請選擇</option>
                      <option
                        v-for="(item, index) in list"
                        :key="index"
                        :value="item.deptId"
                      >
                        {{ item.deptName }}
                      </option>
                    </select>
                  </td>
                  <td class="tc" v-else>
                    {{ data.assignDeptId ? data.assignDeptName : "未指派" }}
                  </td>
                  <td class="tc" v-if="data.isCreate">
                    <select class="form-control" v-model="data.employeeId">
                      <option value="">人員</option>
                      <option
                        v-for="(user, index) in users"
                        :key="index"
                        :value="user.employeeId"
                      >
                        {{ user.employeeName }}
                      </option>
                    </select>
                  </td>
                  <td class="tc" v-else v-text="data.employeeName"></td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      v-show="!data.isEdit"
                      @click="updateData(data)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-danger ml10"
                      v-show="!data.isEdit"
                      @click="deleteData(data)"
                    >
                      刪除
                    </button>
                    <button
                      class="btn btn-success"
                      v-show="data.isEdit"
                      @click="saveData(data)"
                    >
                      儲存
                    </button>
                    <button
                      class="btn btn-default ml10"
                      v-show="data.isEdit"
                      @click="cancelData(data)"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      selectDeptId: "",
      assignDeptId: "",
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        employeeId: "",
        deptId: this.selectDeptId, //部門別
        assignDeptId: "", //歸屬部門
        status: -1,
      },
      list: [],
      users: [],
      datas: [],
      data: {},
      orginData: {},
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDeptList();
    this.getDataList();
  },
  watch: {
    selectDeptId(val) {
      if (val) this.assignDeptId = val;
      this.getDataList();
    },
    assignDeptId(val) {
      this.getMemberList();
    },
  },
  methods: {
    changeAssignDeptId(data) {
      data.assignDeptId = this.assignDeptId;
    },
    getDeptList() {
      api.okr.goal.department.list({ deptLevel: 0 }).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
        }
      });
    },
    getMemberList() {
      this.searchData.assignDeptId = "";
      this.searchData.deptId = this.assignDeptId;
      const datas = JSON.parse(JSON.stringify(this.searchData));
      api.okr.goal.personal.list(datas).then((res) => {
        if (res.data.status === "1") {
          this.users = res.data.result || [];
        }
      });
    },
    getDataList() {
      this.searchData.deptId = "";
      this.searchData.assignDeptId = this.selectDeptId;
      const datas = JSON.parse(JSON.stringify(this.searchData));
      api.okr.mgmt.assign.queryAll(datas).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          this.data.isCreate = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList(type) {
      this.searchData.pageNum = 1;
      if (type == "dept") this.getMemberList();
      else this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    createData() {
      if (this.datas.length && this.datas[0].isCreate) {
        this.$eventBus.$emit("showAlert", "您尚未儲存新增項目！");
        return;
      }
      this.cancelData(this.data);
      this.data = {
        employeeId: "",
        assignDeptId: this.assignDeptId,
        isEdit: true,
        isCreate: true,
      };
      this.datas.splice(0, 0, this.data);
    },
    updateData(data) {
      this.cancelData(this.data);
      data.isEdit = true;
      this.data = data;
      this.orginData.assignDeptId = data.assignDeptId;
      this.$forceUpdate();
    },
    cancelData(data) {
      data.isEdit = false;
      if (data.isCreate) {
        this.datas.splice(0, 1);
        data.isCreate = false;
      } else {
        data.assignDeptId = this.orginData.assignDeptId;
        this.$forceUpdate();
      }
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.mgmt.assign.delete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    saveData(data) {
      if (!data.assignDeptId) {
        this.$eventBus.$emit("showAlert", "請選擇歸屬部別！");
        return;
      }
      if (!data.employeeId) {
        this.$eventBus.$emit("showAlert", "請選擇員工！");
        return;
      }
      const action = data.isCreate ? "create" : "update";
      data.status = data.assignDeptId ? 1 : 0;
      api.okr.mgmt.assign[action](data).then((res) => {
        this.global.showResponse(this, res, action, () => {
          this.selectDeptId = data.assignDeptId;
          this.getDataList();
        });
      });
    },
  },
};
</script>
