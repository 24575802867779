var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseQuizPreview" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.datas.testName))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", [_vm._v("課程管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("測驗預覽")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "box box-warning" },
        [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v("測驗預覽"),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v(" 返回\n          "),
                ]
              ),
            ]),
          ]),
          _c("quiz", { attrs: { isPreview: true, datas: _vm.datas } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }