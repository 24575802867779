<template>
  <input type="text" class="form-control datepicker" ref="datepicker" />
</template>

<script>
import utils from "@/assets/js/app-utils";

export default {
  props: {
    propValue: "",
    propDisplay: false
  },
  data() {
    return {
      dateTime: new Date()
    };
  },
  watch: {
    value() {
      $(this.$refs.datepicker).datepicker(
        "setDate",
        utils.formatTime(this.value, "YYYY/MM/DD")
      );
    },
    propValue(value) {
      try {
        $(this.$refs.datepicker).datepicker(
          "setDate",
          utils.formatTime(value, "YYYY/MM/DD")
        );
      } catch (err) {
        // console.log(err);
      }
    }
  },
  mounted() {
    var that = this;
    $.fn.datepicker.dates["tw"] = {
      days: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      ],
      daysShort: ["日", "一", "二", "三", "四", "五", "六"],
      daysMin: ["日", "一", "二", "三", "四", "五", "六"],
      months: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月"
      ],
      monthsShort: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月"
      ],
      today: "今天",
      clear: "清除",
      format: "yyyy/mm/dd",
      titleFormat: "yyyy MM" /* Leverages same syntax as 'format' */,
      weekStart: 0
    };

    $(this.$refs.datepicker).datepicker({
      autoclose: true,
      language: "tw",
      todayHighlight: true,
      format: "yyyy/mm/dd"
    });

    if (!that.propDisplay) {
      $(this.$refs.datepicker)
        .datepicker({
          autoclose: true,
          language: "tw",
          todayHighlight: true,
          format: "yyyy/mm/dd"
        })
        .datepicker("setDate", new Date());
      that.$emit("input", $(that.$refs.datepicker).val());
    }

    $(this.$refs.datepicker).on("change", function() {
      that.$emit("input", $(that.$refs.datepicker).val());
      that.$emit("update:propValue", $(that.$refs.datepicker).val());
    });
  }
};
</script>