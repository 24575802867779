<template>
<div class="tinymce-editor">
    <Editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick"></Editor>
</div>
</template>

<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import api from "@/assets/js/app-api";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/codesample";
import "tinymce/plugins/code";
import "tinymce/plugins/preview";
import "tinymce/plugins/link";

export default {
    name: "Tinymce",
    components: {
        Editor
    },
    props: {
        value: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        propLang: {
            type: String,
            default: "zh-tw"
        },

        font_formats: {
            type: String,
            default: "標楷體=標楷體, Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats"
        },

        plugins: {
            type: [String, Array],
            default: "lists code image media table textcolor wordcount contextmenu codesample preview link"
        },
        toolbar1: {
            type: [String, Array],
            default: "undo redo | codesample code | formatselect| fontsizeselect | fontselect | bold italic custom_underline strikethrough"
        },
        toolbar2: {
            type: [String, Array],
            default: "forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists link image media table | preview removeformat "
        }
    },
    data() {
        return {
            //初始化
            init: {
                language_url: "/tinymce/langs/zh_TW.js", // 語系包路徑
                convert_urls: false,
                language: "zh_TW", //語系
                skin_url: "/tinymce/skins/ui/oxide", // skin路徑
                height: 500, //編輯器高度
                plugins: this.plugins,
                toolbar1: this.toolbar1,
                toolbar2: this.toolbar2,
                branding: false, //是否禁用“Powered by TinyMCE”
                menubar: false, //置頂選單顯示
                font_formats: this.font_formats, //字型
                setup: function (editor) {
                    /* example, adding a toolbar menu button */
                    editor.ui.registry.addMenuButton("custom_underline", {
                        icon: "underline",
                        fetch: function (callback) {
                            var items = [{
                                    type: "menuitem",
                                    text: "無",
                                    onAction: function () {
                                        tinymce.activeEditor.formatter.toggle("remove_span");
                                    }
                                },
                                {
                                    type: "menuitem",
                                    text: "underline",
                                    icon: "underline",
                                    onAction: function () {
                                        tinymce.execCommand("underline");
                                    }
                                },
                                {
                                    type: "menuitem",
                                    text: "波浪下劃線",
                                    onAction: function () {
                                        tinymce.activeEditor.formatter.toggle("underline_wavy");
                                    }
                                }
                            ];
                            callback(items);
                        }
                    });
                },
                formats: {
                    underline_wavy: [{
                        inline: "span",
                        styles: {
                            "text-decoration": "underline",
                            "-webkit-text-decoration-style": "wavy",
                            "text-decoration-style": "wavy"
                        },
                        exact: false
                    }],
                    remove_span: [{
                        inline: "span",
                        remove: "all"
                    }]
                },

                // 圖片上傳處理
                images_upload_handler: (blobInfo, success, failure) => {
                    const data = new FormData();
                    data.append("files", blobInfo.blob());
                    data.append("module", "Announcement");
                    data.append("multi", true);

                    api.file
                        .upload(data)
                        .then(result => {
                            success(result.data.result.url);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            },
            myValue: this.value
        };
    },
    mounted() {
        tinymce.init({});
    },
    methods: {
        //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
        //需要什么事件可以自己增加
        onClick(e) {
            this.$emit("onClick", e, tinymce);
        },
        //可以添加一些自己的自定义事件，如清空内容
        clear() {
            this.myValue = "";
        }
    },
    watch: {
        value(newValue) {
            this.myValue = newValue;
        },
        myValue(newValue) {
            this.$emit("input", newValue);
        }
    }
};
</script>
