var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "marquee_setting",
      staticClass: "modal fade",
      attrs: { id: "marqueeEdit" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "col-sm-9" },
                  [
                    _c("tinymce", {
                      model: {
                        value: _vm.edit.note,
                        callback: function ($$v) {
                          _vm.$set(_vm.edit, "note", $$v)
                        },
                        expression: "edit.note",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "col-sm-3" },
                  [
                    _c("DatePicker", {
                      attrs: { propValue: _vm.edit.startTime },
                      model: {
                        value: _vm.startTime,
                        callback: function ($$v) {
                          _vm.startTime = $$v
                        },
                        expression: "startTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-sm-3 time" }, [
                  _c("input", {
                    ref: "startTimer",
                    staticClass: "form-control timepicker",
                    attrs: { type: "text" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "col-sm-3" },
                  [
                    _c("DatePicker", {
                      attrs: { propValue: _vm.edit.endTime },
                      model: {
                        value: _vm.endTime,
                        callback: function ($$v) {
                          _vm.endTime = $$v
                        },
                        expression: "endTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-sm-3 time" }, [
                  _c("input", {
                    ref: "endTimer",
                    staticClass: "form-control timepicker",
                    attrs: { type: "text" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(4),
                _c("div", { staticClass: "col-sm-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.orderNum,
                        expression: "edit.orderNum",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "80px", "text-align": "center" },
                    attrs: { type: "number", min: "0" },
                    domProps: { value: _vm.edit.orderNum },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "orderNum", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-3 control-label" }, [
                  _vm._v("停用："),
                ]),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c(
                    "div",
                    { staticClass: "checkbox-inline" },
                    [
                      _c("iCheckCheckBox", {
                        attrs: { propChecked: _vm.edit.isStop },
                        on: {
                          "update:checked": function ($event) {
                            _vm.edit.isStop = $event["checked"]
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                ref: "editSaveButton",
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
              },
              [_vm._v("\n          儲存\n        ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v("\n          關閉\n        ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("編輯跑馬燈資訊")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputMarqueeName" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 跑馬燈訊息：\n            "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputMarqueeStartTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 開始時間：\n            "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputMarqueeEndTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 結束時間："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputMarqueeOrderNum" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序：\n            "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }