<template>
  <div id="Shop">
    <section class="content-header">
      <h1>特約商店</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">特約商店</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢特約商店：</label>
                <select
                  class="form-control w90"
                  @change="resetDataList()"
                  v-model="searchData.storeType"
                >
                  <option :value="null">全部分類</option>
                  <option
                    :value="category.classId"
                    v-for="(category, index) in categoryList"
                  >
                    {{ category.className }}
                  </option>
                </select>
                <select
                  class="form-control"
                  @change="resetDataList()"
                  v-model="searchData.companyType"
                >
                  <option :value="null">全部地區</option>
                  <option
                    :value="area.classId"
                    v-for="(area, index) in areaList"
                  >
                    {{ area.className }}
                  </option>
                </select>
                <select
                  class="form-control"
                  @change="resetDataList()"
                  v-model="searchData.order"
                >
                  <option :value="1">依合約期限排序</option>
                  <option :value="2">依評論數排序</option>
                </select>
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="請輸入關鍵字"
                type="text"
                v-model="searchData.storeName"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-10">
            <div class="list empty" v-if="loading">查詢中</div>
            <div class="list empty" v-else-if="total == 0">查無資料</div>
            <div class="list" v-else>
              <div class="list-item" v-for="(shop, index) in list" @click="showDetail(shop)">
                <div class="photo">
                  <img :src="getImage(shop.files)" class="logo" alt="">
                </div>
                <div class="name">{{ shop.storeName }}</div>
                <div class="area">{{ shop.applyArea }}</div>
                <div class="time">
                  合約期限：
                  <span v-if="shop.isNoLimitContract == 1">永久</span>
                  <span v-else>{{ shop.contractTime | dateFormat("YYYY-MM-DD") }}</span>
                </div>
                <div class="count">評論數：{{ shop.commentCount }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        storeName: "",
        storeType: null,
        companyType: null,
        isFrontend: 1,
        order: 1, // 排序 1:依照合約日期大到小排序、2:依評論數大到小排序 不帶會預設1
        pageNum: 1,
        pageSize: 21
      },
      list: [],
      total: 0,
      areaList: [],
      categoryList: []
    }
  },
  mounted() {
    if(this.$route.params.searchData) {
      this.searchData = this.$route.params.searchData;
    }
    
    this.getClassList('特約商店地區');
    this.getClassList('特約商店類別');
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      api.shop.queryAll(this.searchData)
        .then((res) => {
          this.list = res .data.result.datas;
          this.total = res .data.result.total;
          this.loading = false;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getClassList(type) {
      api.shopClass.queryAll({
        className: "", // 類別名稱
        module: type, // 類別模組 ex: 特約商店地區、特約商店類別
        order: 2,
        pageNum: 1,
        pageSize: 999
      })
        .then((res) => {
          if(type == '特約商店地區') this.areaList = res.data.result.datas;
          if(type == '特約商店類別') this.categoryList = res.data.result.datas;
        })
    },
    getImage(files) {
      const Index = files.findIndex(item => this.global.isImage(item.mine));
      return Index > -1 ? process.env.VUE_APP_EIPIMG_PATH + files[Index].filesUrl : ''
    },
    getClassName(id, list) {
      const Index = list.findIndex(item => item.classId == id)
      return list[Index].className
    },
    showDetail(item) {
      this.$router.push({
        name: 'ShopDetail',
        params: {
          id: item.storeId,
          Status: item.storeStatus,
          searchData: this.searchData,
        }
      })
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
}
</script>

<style scoped lang="scss">
#Shop {
  .box-body {
    .top-box {
      margin-bottom: 30px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;

    &.empty {
      display: block;
      text-align: center;
    }
    .list-item {
      width: 200px;
      text-align: center;
      font-size: 14px;
      margin: 10px 10px;
      cursor: pointer;
      .photo {
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
        img {
          max-width: 140px;
          max-height: 140px;
        }
      }
      .name {
        margin: 15px 0;
        font-size: 22px;
        font-weight: bolder;
      }
    }
  }
}
</style>