<template>
  <!-- 新增移動式作業人員 -->
  <div class="modal fade order-setting" ref="modal" id="order-setting">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title" v-text="'新增移動式作業人員'"></h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td class="text-nowrap">員工工號</td>
                  <td>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.employeeId"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">選擇時間</td>
                  <td>
                    <DateRangePicker
                      v-bind:propEndDate.sync="item.endDate"
                      v-bind:propStartDate.sync="item.startDate"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="save">儲存</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker";
// import Pagination from "@/components/Pagination"; 沒使用到
import utils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";
// import moment from "moment";

export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      item: {
        employeeId: "",
        startDate: new Date(),
        endDate: new Date()
      }
    };
  },
  created() {},
  watch: {},
  methods: {
    show() {
      this.item.employeeId = "";
      this.item.startDate = new Date();
      this.item.endDate = new Date();
      $(this.$refs.modal).modal("show");
    },
    async save() {

      this.item.startDate = this.formatTime(this.item.startDate);
      this.item.endDate = this.formatTime(this.item.endDate);

      if (this.item.employeeId == "") {
        this.$eventBus.$emit("showAlert", "請填寫員工工號");
        return;
      }

      var res = await api.actionwork.create(this.item);
      if (res.data.status != "1") {
        this.$eventBus.$emit("showAlert", "更新失敗");
      } else {
        this.$eventBus.$emit("showAlert", "更新成功");
        this.$emit("refresh");
        $(this.$refs.modal).modal("hide");
      }
    },
    formatTime: function(t) {
      return utils.formatTime(t, "YYYY-MM-DD");
    },
  }
};
</script>
