<template>
  <div id="CourseRecordDetail">
    <section class="content-header">
      <h1>上課紀錄(人員)</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">上課紀錄(人員)</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectName"
                required="required"
                v-model="searchData.employeeId"
                @change="getDataList()"
              >
                <option
                  v-for="(employee, index) in employeeList"
                  :value="employee.employeeId"
                  :key="index"
                >
                  {{ employee.employeeName }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectType"
                required="required"
                v-model="searchData.isRequire"
                @change="getDataList()"
              >
                <option
                  v-for="(item, index) in classList.learningType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectType2"
                required="required"
                v-model="searchData.courseType"
                @change="getDataList()"
              >
                <option
                  v-for="(item, index) in classList.chapterType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectStatus"
                required="required"
                v-model="searchData.isFinished"
                @change="getDataList()"
              >
                <option
                  v-for="(item, index) in classList.finishedType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.value == "" ? "全部狀態" : item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 15%">
                    必修/選修
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    類型
                  </th>
                  <th
                    class="text-nowrap"
                    style="width: 10%; min-width: 180px"
                  >
                    課程名稱
                  </th>
                  <th class="text-nowrap text-center" style="width: 15%">
                    狀態
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    上課時數
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    測驗分數
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="6">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">
                      {{ data.isRequire == 1 ? "必修" : "選修" }}
                    </td>
                    <td class="text-center">{{ data.courseType }}</td>
                    <td>{{ data.courseName }}</td>
                    <td class="text-center">
                      {{ data.isFinished == 1 ? "完成" : "未完成" }}
                    </td>
                    <td class="text-center">
                      <span
                        >{{
                          data.learningTime
                            ? timeToMinute(data.learningTime)
                            : 0
                        }}分鐘</span
                      >
                    </td>
                    <td class="text-center">{{ checkScore(data) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        companyId: "",
        employeeId: this.$route.params.id || "", // 員編
        isRequire: "", // 修課類型 0：選修、1：必修
        courseType: "", // 課程類別
        isFinished: "", // 完成狀態 0：未完成、1：已完成
      },
      classList: {},
      list: [],
      total: 0,
      employeeList: [],
    };
  },
  async created() {
    if (this.$route.params.chaterSearchData != undefined) {
      this.searchData = this.$route.params.chaterSearchData;
    }

    this.getClassList();
    this.getEmployeeList();
    this.getDataList();
  },
  methods: {
    getClassList() {
      api.education.class().then(({ data }) => {
        this.classList = data.result;
      });
    },
    getEmployeeList() {
      api.education
        .employee({
          companyId: this.$route.params.cid,
          deptId: this.$route.params.depId,
        })
        .then((res) => {
          this.employeeList = res.data.result || [];
        });
    },
    getDataList() {
      this.loading = true;
      api.education.record.all.detail(this.searchData).then((res) => {
        this.list = res.data.result;
        this.loading = false;
      });
    },
    timeToMinute(time) {
      if (!time) return;
      let h = time.split(":")[0];
      let m = time.split(":")[1];

      let minute = 0;
      minute += parseInt(h * 60);
      minute += parseInt(m);
      return minute;
    },
    back() {
      this.$router.push({
        name: "ETCourseRecordPersonal",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    checkScore(data) {
      // 0：課程有測驗未進行、-1：課程無測驗、其他：測驗後分數
      if (data.score == -1) return "無測驗";
      if (data.score == 0 && data.isFinished == 0) return "-";
      else return data.score + "分";
    },
  },
};
</script>
