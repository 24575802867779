var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "FlowEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("簽核群組流程設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/OKR/GroupSet" } }, [
                _vm._v("簽核群組流程設定"),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("流程設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left" }, [_vm._v("流程設定")]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v("\n            返回列表\n          "),
                ]
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveData()
                  },
                },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.flowId,
                              expression: "data.flowId",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "", disabled: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data,
                                "flowId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "績效考核流程" } }, [
                            _vm._v("績效考核流程"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      staticStyle: { margin: "40px 0" },
                    },
                    [
                      _c("label", { staticClass: "col-sm-2 control-label" }),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c("div", { staticClass: "flowContent x_content" }, [
                          _c(
                            "ul",
                            { staticClass: "process-map list-unstyled" },
                            [
                              _c("li", { staticClass: "start bg-green" }, [
                                _vm._v("開始"),
                              ]),
                              _c("li", { staticClass: "add" }, [
                                _vm.hasEdit
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-default",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showContent(null, 0.5)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-plus" })]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._l(_vm.datas, function (item, index) {
                                return [
                                  _c("li", { key: index }, [
                                    _c("div", { staticClass: "level" }, [
                                      _vm._v(
                                        "第" + _vm._s(item.orderNum) + "關"
                                      ),
                                    ]),
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.empName)),
                                    ]),
                                    _vm.hasEdit
                                      ? _c("div", { staticClass: "tools" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "bg-blue",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showContent(
                                                    item,
                                                    item.orderNum
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-pencil",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "bg-red ml10",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteData(item)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-trash",
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "stop-type" }, [
                                      _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "checkbox checkbox-inline",
                                          },
                                          [
                                            _c("iCheckCheckBox", {
                                              attrs: {
                                                propValue: "0",
                                                propItem: [item, "min"],
                                                propChecked: _vm.checkFlowStops(
                                                  item,
                                                  "小考"
                                                ),
                                              },
                                              on: {
                                                "update:checked":
                                                  _vm.checkChange2,
                                              },
                                            }),
                                            _vm._v(
                                              "小考核決人員\n                            "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "checkbox checkbox-inline",
                                          },
                                          [
                                            _c("iCheckCheckBox", {
                                              attrs: {
                                                propValue: "1",
                                                propItem: [item, "max"],
                                                propChecked: _vm.checkFlowStops(
                                                  item,
                                                  "大考"
                                                ),
                                              },
                                              on: {
                                                "update:checked":
                                                  _vm.checkChange2,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                              大考核決人員\n                            "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    item.note
                                      ? _c("span", { staticClass: "name" }, [
                                          _vm._v(
                                            "\n                          （" +
                                              _vm._s(item.note) +
                                              "）\n                        "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "li",
                                    {
                                      key: item.groupFlowId,
                                      staticClass: "add",
                                    },
                                    [
                                      _vm.hasEdit
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-default",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showContent(
                                                    null,
                                                    item.orderNum + 0.5
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-plus",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              }),
                              _c("li", { staticClass: "end bg-gray" }, [
                                _vm._v("結束"),
                              ]),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    !_vm.hasEdit
                      ? _c("input", {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button", value: "確認" },
                          on: {
                            click: function ($event) {
                              return _vm.backList()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.hasEdit
                      ? _c("input", {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit", value: "確認送出" },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("FlowContent", {
        ref: "flowContent",
        on: { updateList: _vm.updateData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                流程名稱：\n              "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }