<template>
  <div class="modal fade" ref="scorePersonalContent">
    <div class="modal-dialog" style="width: 800px">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">評分</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label w90">
                      <span class="text-red">*</span>
                      目標種類
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        disabled
                        v-model="data.objectiveType"
                      >
                        <option value="0">一般目標</option>
                        <option value="1">新增任務</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="person.level === 2 || data.deptLevel === 2">
                    <td class="control-label">
                      <span class="text-red">*</span>
                      部級目標
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        class="form-control"
                        required
                        :disabled="
                          data.objectiveType === 0 ||
                          (data.userData &&
                            data.userData.user.employeeId !== data.employeeId)
                        "
                        v-model.trim="data.objective"
                      />
                    </td>
                  </tr>
                  <tr v-else>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      部級目標
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        :disabled="data.objectiveType === 0"
                        v-model="data.parentObjectiveId"
                      >
                        <option value="-1">未定</option>
                        <option value="-2">例行工作</option>
                        <option
                          v-for="(obj, index) in objs"
                          :key="index"
                          :value="obj.objectiveId"
                        >
                          {{ obj.objective }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="person.level === 2 || data.deptLevel === 2">
                    <td class="control-label">
                      <span class="text-red">*</span>
                      目標說明
                    </td>
                    <td colspan="2">
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                        :disabled="
                          data.objectiveType === 0 ||
                          (data.userData &&
                            data.userData.user.employeeId !== data.employeeId)
                        "
                        v-model.trim="data.note"
                      ></textarea>
                    </td>
                  </tr>
                  <tr v-else>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      人員目標
                    </td>
                    <td colspan="2">
                      <textarea
                        rows="4"
                        type="text"
                        class="form-control"
                        required
                        :disabled="
                          data.objectiveType === 0 ||
                          (data.userData &&
                            data.userData.user.employeeId !== data.employeeId)
                        "
                        v-model.trim="data.objective"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label"></td>
                    <td class="tr" colspan="2">
                      <label
                        class="checkbox checkbox-inline fw-b"
                        style="color: cornflowerblue"
                      >
                        <iCheckCheckBox
                          propValue="1"
                          :propChecked="data.checked"
                          v-on:update:checked="checkChange"
                        />&nbsp;不列入考核（欲勾選此項，麻煩請先於主管討論）
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label" colspan="3">
                      <table
                        class="table table-bordered"
                        v-for="(scoreItem, index) in data.scores"
                        :key="index"
                      >
                        <tbody>
                          <tr>
                            <td
                              colspan="2"
                              class="tc bg-dark"
                              v-text="
                                `${scoreItem.createEmpName}${
                                  index === 0
                                    ? '（自評）'
                                    : scoreItem.createIsManager === '0' &&
                                      scoreItem.createDeptType.includes('0060')
                                    ? '（上層主管）'
                                    : scoreItem.createIsManager === '1' &&
                                      scoreItem.createDeptType.includes('0060')
                                    ? '（部門主管）'
                                    : '（高層主管）'
                                }`
                              "
                            ></td>
                          </tr>
                          <tr>
                            <td class="tc bg-dark-gray" width="200px">
                              <div
                                class="fw-b fz50"
                                v-text="scoreItem.score"
                              ></div>
                              <button
                                class="btn btn-dark btn-lg"
                                type="button"
                                @click="
                                  minusScore(
                                    scoreItem,
                                    data.hasHR ||
                                      data.userData.user.employeeId ===
                                        scoreItem.createEmpId
                                  )
                                "
                              >
                                <i class="fa fa-minus"></i>
                              </button>
                              <button
                                class="btn btn-dark btn-lg"
                                type="button"
                                @click="
                                  plusScore(
                                    scoreItem,
                                    data.hasHR ||
                                      data.userData.user.employeeId ===
                                        scoreItem.createEmpId
                                  )
                                "
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </td>
                            <td>
                              <textarea
                                rows="6"
                                type="text"
                                class="form-control"
                                placeholder="輸入文字"
                                :disabled="
                                  !data.hasHR &&
                                  data.userData.user.employeeId !==
                                    scoreItem.createEmpId
                                "
                                v-model.trim="scoreItem.note"
                              ></textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">儲存</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      person: this.global.getPerson(this.$route.params.type),
      objs: [],
      data: {},
      scoreItem: {},
    };
  },
  methods: {
    async showContent(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.data.checked = this.data.noCount ? 1 : 0;
      this.getObjs();
      $(this.$refs.scorePersonalContent).modal("show");
    },
    getObjs() {
      const result = {
        year: this.data.year,
      };
      switch (this.person.level) {
        case 2: {
          result.action = "division";
          result.deptId = this.global.getDivisionId(this.data.deptId);
          result.month = this.data.month;
          break;
        }
        default: {
          result.action = "department";
          result.deptId = this.data.deptId;
          result.month = this.global.getHalfYear(this.data.month);
          result.isDeptFollow = 1;
          break;
        }
      }
      api.okr.goal[result.action].queryAll(result).then((res) => {
        if (res.data.status === "1") {
          const datas = res.data.result.datas || [];
          this.objs = this.global.getDistinctData(datas, "objective");
          this.data.checked = this.data.noCount ? true : false;
          this.getScoreItem();
        }
      });
    },
    getScoreItem() {
      this.scoreItem =
        this.data.scores.find(
          (x) => this.data.userData.user.employeeId === x.createEmpId
        ) || {};
      this.resetScore();
    },
    resetScore() {
      this.scoreItem.score = this.data.noCount
        ? this.scoreItem.score || 0
        : this.scoreItem.score || 3;
    },
    checkChange(data) {
      this.data.noCount = data.checked;
      this.resetScore();
    },
    minusScore(scoreItem, hasEdit) {
      const min = this.data.noCount ? 0 : 1;
      if (hasEdit && scoreItem.score > min) {
        scoreItem.score--;
      }
    },
    plusScore(scoreItem, hasEdit) {
      if (hasEdit && scoreItem.score < 5) {
        scoreItem.score++;
      }
    },
    submit() {
      if (this.data.objectiveType) {
        api.okr.goal[this.person.api].update(this.data);
      }
      if (this.person.level === 9) {
        api.okr.score.min.total.update(this.data).then((res) => {
          this.global.showResponse(this, res, "update");
          $(this.$refs.scorePersonalContent).modal("hide");
          this.$emit("updateList");
        });
      } else {
        this.scoreItem.noCount = this.data.noCount;
        this.scoreItem.scoreGroupId = this.data.scoreGroupId;
        api.okr.score.min.update(this.scoreItem).then((res) => {
          this.global.showResponse(this, res, "update");
          $(this.$refs.scorePersonalContent).modal("hide");
          this.$emit("updateList");
        });
      }
    },
  },
};
</script>
