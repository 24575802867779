var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Shop" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("特約商店")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("特約商店")]),
      ]),
    ]),
    _c("section", { staticClass: "content seating" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "col-5" }, [
              _c("div", { staticClass: "select-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("查詢特約商店："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.storeType,
                        expression: "searchData.storeType",
                      },
                    ],
                    staticClass: "form-control w90",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "storeType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("全部分類"),
                    ]),
                    _vm._l(_vm.categoryList, function (category, index) {
                      return _c(
                        "option",
                        { domProps: { value: category.classId } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(category.className) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.companyType,
                        expression: "searchData.companyType",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "companyType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("全部地區"),
                    ]),
                    _vm._l(_vm.areaList, function (area, index) {
                      return _c(
                        "option",
                        { domProps: { value: area.classId } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(area.className) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.order,
                        expression: "searchData.order",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "order",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { domProps: { value: 1 } }, [
                      _vm._v("依合約期限排序"),
                    ]),
                    _c("option", { domProps: { value: 2 } }, [
                      _vm._v("依評論數排序"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.storeName,
                    expression: "searchData.storeName",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: { placeholder: "請輸入關鍵字", type: "text" },
                domProps: { value: _vm.searchData.storeName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "storeName", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt-10" }, [
            _vm.loading
              ? _c("div", { staticClass: "list empty" }, [_vm._v("查詢中")])
              : _vm.total == 0
              ? _c("div", { staticClass: "list empty" }, [_vm._v("查無資料")])
              : _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(_vm.list, function (shop, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "list-item",
                        on: {
                          click: function ($event) {
                            return _vm.showDetail(shop)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "photo" }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: { src: _vm.getImage(shop.files), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(shop.storeName)),
                        ]),
                        _c("div", { staticClass: "area" }, [
                          _vm._v(_vm._s(shop.applyArea)),
                        ]),
                        _c("div", { staticClass: "time" }, [
                          _vm._v(
                            "\n                合約期限：\n                "
                          ),
                          shop.isNoLimitContract == 1
                            ? _c("span", [_vm._v("永久")])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      shop.contractTime,
                                      "YYYY-MM-DD"
                                    )
                                  )
                                ),
                              ]),
                        ]),
                        _c("div", { staticClass: "count" }, [
                          _vm._v("評論數：" + _vm._s(shop.commentCount)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }