<template>
  <div id="ActivityParticipants">
    <section class="content-header">
      <h1>
        {{ activity.typeName }}-{{ activity.kindName }}-{{
        activity.activityName
        }}-參加人員
      </h1>
      <p class="mt-10 text-red">{{ getDuration(activity.activitySTime, activity.activityETime) }}</p>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li>活動設定</li>
        <li>活動列表管理</li>
        <li class="active">{{ title }}-參加人員</li>
      </ol>
    </section>
    <section class="content activity-participants">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-select">
            <div class="btns">
              <button @click="goBack()" class="btn btn-block btn-default btn-cog" type="button">
                <i class="fa fa-reply"></i> 返回列表
              </button>
              <button
                @click="openAddActivityMember()"
                class="btn btn-block btn-warning btn-add"
                type="button"
                v-if="status != 9"
              >
                <i class="fa fa-plus"></i> 新增人員
              </button>
              <button
                @click="checkFinish"
                class="btn btn-block btn-success btn-cog"
                type="button"
                v-if="status != 9 && hasEditPermission"
              >
                <i class="fa fa-check-square-o"></i> 完成檢核
              </button>
            </div>
            <div class="export-group">
              <div class="select-group">
                <select2Single :options="depts" data-placeholder="全部" v-model="searchDatas.deptId" />
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="搜尋姓名或工號"
                  type="text"
                  v-model="searchDatas.keyword"
                />
                <div class="input-group-btn">
                  <button @click="queryAll" class="btn btn-default" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <button @click="exportExcel()" class="btn btn-primary btn-export" type="button">
                <i class="fa fa-download"></i> 匯出
              </button>
            </div>
            <div class="text">
              <p class="data-num">資料總筆數：{{ total }}</p>
              <p class="data-num">出席人數：{{ activity.joinCountCheckin }}</p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">編號</th>
                  <th class="text-nowrap">部門</th>
                  <th class="text-nowrap">工號</th>
                  <th class="text-nowrap">姓名</th>
                  <th class="text-nowrap">參加狀態</th>
                  <th class="text-nowrap">追加人員</th>
                  <th style="min-width: 200px; width: 10%">備註</th>
                  <th class="text-center" style="min-width: 150px; width: 10%">功能</th>
                </tr>
                <tr :key="index" v-for="(item, index) in members">
                  <td class="text-center">{{ item.rowid }}</td>
                  <td>{{ item.deptName }}</td>
                  <td>{{ item.joinEmpId }}</td>
                  <td>{{ item.joinEmpName }}</td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :disabled="status == 9"
                        :false-value="0"
                        :id="'check' + index"
                        :true-value="1"
                        @change="save(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.status"
                      />
                      <label :for="'check' + index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <i class="fa fa-check text-yellow font20" v-if="item.managerAdd == 1"></i>
                  </td>
                  <td>
                    <p class="m-0" v-show="!item.editor">{{ item.note }}</p>
                    <input
                      class="form-control"
                      placeholder="填寫備註"
                      required="required"
                      type="text"
                      v-model="item.note"
                      v-show="item.editor"
                    />
                  </td>
                  <td class="text-center" style="min-width: 150px">
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="!item.editor && status != 9"
                    >編輯</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="queryAll()"
                      class="btn btn-default"
                      v-show="item.editor"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchDatas.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <addActivityMember ref="addActivityMember" v-on:refresh="queryAll()" />
  </div>
</template>

<script>
import api from '../../assets/js/app-api';
import Pagination from '@/components/Pagination';
import addActivityMember from '@/views/Activity/AddActivityMember';
import select2Single from '@/components/select2Single';
import config from '@/assets/js/app-config';
import utils from '@/assets/js/app-utils';
import moment from 'moment';

export default {
  components: {
    Pagination,
    addActivityMember,
    select2Single,
  },
  delimiters: ['${', '}'],
  data() {
    return {
      hasEditPermission: false,
      total: 0, //總筆數
      title: decodeURI(this.$route.params.name),
      status: 9,
      depts: [
        {
          id: '-1',
          text: '全部',
        },
      ],
      members: {},
      activity: {},
      searchDatas: {
        activityId: this.$route.params.id,
        deptId: '',
        keyword: '',
        pageNum: 1,
        pageSize: 30,
      },
    };
  },
  created() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.ActivityAddmemberCheck
    );
  },
  mounted() {
    this.getAllDepts();
    this.queryAll();
  },
  methods: {
    onPageChange(pageIdx, pageSize) {
      this.searchDatas.pageNum = pageIdx;
      this.searchDatas.pageSize = pageSize;
      this.queryAll();
    },
    queryAll() {
      let tempRes = JSON.parse(JSON.stringify(this.searchDatas));
      tempRes.deptId = tempRes.deptId == '-1' ? '' : tempRes.deptId;

      api.activity
        .query({
          activityId: this.searchDatas.activityId,
        })
        .then((res) => {
          if (res.data.status == '1') {
            this.activity = res.data.result;
            this.status = res.data.result.status;
          } else {
            this.$eventBus.$emit('showAlert', '查無資料!');
            this.$router.push({
              name: 'ActivityList',
            });
          }
        });

      api.activity.queryAllJoin(tempRes).then((res) => {
        if (res.data.status == '1') {
          let tmp = res.data.result.datas;
          this.total = res.data.result.total;
          if (this.total > 0) {
            for (var i = 0; i < this.total; i++) {
              tmp[i].editor = false;
            }
          }
          this.members = JSON.parse(JSON.stringify(tmp));
        }
      });
    },
    async getAllDepts() {
      var task_deps = api.organization.getDeptList({
        companyId: this.$store.state.loginUser.companyId,
      });
      var response_desps = await task_deps;
      let temp = response_desps.data.result;
      temp.forEach((dept) => {
        this.depts.push({
          id: dept.deptId,
          text: dept.deptIdName,
        });
      });
    },
    // 切換文字或輸入框
    change(item) {
      if (this.members.some((type) => type.editor === true)) {
        this.$eventBus.$emit('showAlert', '請先儲存上一筆資料!');
        return;
      }

      item.editor = item.editor ? false : true;
    },
    // 儲存
    save(item) {
      api.activity.updateJoin(item).then((res) => {
        if (res.data.status == '1') {
          this.queryAll();
        } else {
          this.$eventBus.$emit('showAlert', '操作失敗，請重新操作!');
        }
      });
    },

    checkFinish(item) {
      this.$eventBus.$emit('showConfirm', '確認已完成檢核嗎?', (isOK) => {
        if (isOK) {
          api.activity
            .checkFinish({
              activityId: this.searchDatas.activityId,
            })
            .then((res) => {
              if (res.data.status == '1') {
                this.$router.push({
                  name: 'ActivityList',
                });
              } else {
                this.$eventBus.$emit('showAlert', '檢核失敗，請重新操作!');
              }
            });
        }
      });
    },

    exportExcel() {
      let tempRes = JSON.parse(JSON.stringify(this.searchDatas));
      tempRes.deptId = tempRes.deptId == '-1' ? '' : tempRes.deptId;
      tempRes.pageNum = 0;
      tempRes.pageSize = 0;

      api.activity.queryAllJoin(tempRes).then((res) => {
        let data = [];
        if (res.data.status == '1' && res.data.result.datas.length != 0) {
          res.data.result.datas.forEach((res) => {
            data.push({
              編號: res.rowid,
              部門: res.deptName,
              工號: res.joinEmpId,
              姓名: res.joinEmpName,
              參加狀態: res.status == 1 ? '是' : '否',
              追加人員: res.managerAdd == 1 ? '是' : '否',
              備註: res.note,
            });
          });
          utils.exportData(
            data,
            this.activity.typeName + '_' + this.activity.activityName + '.xlsx'
          );
        } else {
          this.$eventBus.$emit('showAlert', '查無資料!!');
          return;
        }
      });
    },
    getDuration(start, end) {
      if (moment(start).isSame(end, 'day')) {
        return (
          utils.formatTime(start, 'YYYY-MM-DD') +
          ' ' +
          utils.formatTime(start, 'HH:mm') +
          '~' +
          utils.formatTime(end, 'HH:mm')
        );
      } else {
        return (
          utils.formatTime(start, 'YYYY-MM-DD HH:mm') +
          '~' +
          utils.formatTime(end, 'YYYY-MM-DD HH:mm')
        );
      }
    },
    goBack() {
      this.$router.push({
        name: 'ActivityList',
        params: {
          searchDatas: this.$route.params.searchDatas,
        },
      });
    },
    openAddActivityMember() {
      this.$refs.addActivityMember.showAddActivityMember(this.activity);
    },
  },
};
</script>
