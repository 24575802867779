var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CapterEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("章節管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", [_vm._v("課程管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("章節管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning company-files-edit" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(
                "\n            課程" +
                  _vm._s(_vm.isEdit ? "編輯" : "新增") +
                  "\n          "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "box-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.chapterName,
                        expression: "data.chapterName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "required", type: "text" },
                    domProps: { value: _vm.data.chapterName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.data, "chapterName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "selectType" },
                  },
                  [_vm._v("\n                章節說明：\n              ")]
                ),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.chapterDesc,
                        expression: "data.chapterDesc",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "required" },
                    domProps: { value: _vm.data.chapterDesc },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.data, "chapterDesc", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.chapterTime,
                        expression: "data.chapterTime",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: {
                      "max-width": "100px",
                      display: "inline-block",
                      "margin-right": "5px",
                    },
                    attrs: {
                      required: "required",
                      type: "number",
                      min: "0",
                      disabled: _vm.isEdit,
                    },
                    domProps: { value: _vm.data.chapterTime },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.data, "chapterTime", $event.target.value)
                      },
                    },
                  }),
                  _vm._v("\n                分鐘\n                "),
                  _c("p", { staticClass: "text-gray" }, [
                    _vm._v(
                      "\n                  ※在章節頁停留時間達該時數，代表完成章節\n                  (影片建議使用影片時長)\n                "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.teacher,
                        expression: "data.teacher",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "required", type: "text" },
                    domProps: { value: _vm.data.teacher },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.data, "teacher", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 0,
                          message: "影片",
                          name: "radioMode1",
                        },
                        model: {
                          value: _vm.data.learnMaterialType,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "learnMaterialType", $$v)
                          },
                          expression: "data.learnMaterialType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 1,
                          message: "嵌入碼(Youtube影片、Google簡報...適用)",
                          name: "radioMode1",
                        },
                        model: {
                          value: _vm.data.learnMaterialType,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "learnMaterialType", $$v)
                          },
                          expression: "data.learnMaterialType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.data.learnMaterialType == 0 &&
                  _vm.videoFile &&
                  _vm.videoFile.length < 1
                    ? _c("div", { staticClass: "mt-10" }, [
                        _c("input", {
                          attrs: {
                            accept: "video/*",
                            id: "inputVideo",
                            name: "file",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeFile(
                                $event,
                                "EducationChapterVideo"
                              )
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.data.learnMaterialType == 0
                    ? _c("p", { staticClass: "text-red" }, [
                        _vm._v(
                          "\n                  *影片限制150MB，超過大小請剪輯後分段上傳\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm.data.learnMaterialType == 1
                    ? _c("div", { staticClass: "mt-10" }, [
                        _vm._m(4),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.pptUrl,
                              expression: "data.pptUrl",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { rows: "3" },
                          domProps: { value: _vm.data.pptUrl },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "pptUrl", $event.target.value)
                            },
                          },
                        }),
                      ])
                    : _c("div", { staticClass: "mt-10" }, [
                        _c(
                          "ul",
                          { staticClass: "file-list list-unstyled" },
                          _vm._l(_vm.videoFile, function (file, index) {
                            return _c("li", { key: index }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(file.name)),
                              ]),
                              _c("div", { staticClass: "btn-area" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFile(
                                          file.applyId,
                                          "EducationChapterVideo"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                          刪除\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "datetimepicker" },
                  },
                  [_vm._v("\n                上傳附件：\n              ")]
                ),
                _c("div", { staticClass: "col-sm-8 text" }, [
                  _c("input", {
                    attrs: {
                      accept:
                        ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip",
                      id: "inputFile",
                      maxlength: "5",
                      name: "file2",
                      type: "file",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeFile($event, "EducationChapter")
                      },
                    },
                  }),
                  _vm._m(5),
                ]),
              ]),
              _vm.files && _vm.files.length > 0
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "datetimepicker" },
                      },
                      [_vm._v("\n                已有檔案：\n              ")]
                    ),
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "ul",
                        { staticClass: "file-list list-unstyled" },
                        _vm._l(_vm.files, function (file, index) {
                          return _c("li", { key: index }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(file.name)),
                            ]),
                            _c("div", { staticClass: "btn-area" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFile(
                                        file.applyId,
                                        "EducationChapter"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        刪除\n                      "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(6),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 1,
                          message: "啟用",
                          name: "radioMode2",
                        },
                        model: {
                          value: _vm.data.chapterStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "chapterStatus", $$v)
                          },
                          expression: "data.chapterStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 0,
                          message: "停用",
                          name: "radioMode2",
                        },
                        model: {
                          value: _vm.data.chapterStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "chapterStatus", $$v)
                          },
                          expression: "data.chapterStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      type: "button",
                      disabled: _vm.waittingFilesUpload,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.waittingFilesUpload ? "檔案上傳中..." : "送出"
                        ) +
                        "\n              "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 章節名稱：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 時數：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 講師：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "datetimepicker" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 上傳檔案：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v("\n                    請填上嵌入碼\n                    "),
      _c(
        "a",
        {
          staticClass: "pull-right",
          attrs: {
            target: "_blank",
            href: "https://steam.oxxostudio.tw/category/html/example/google-ppt.html#google_vignette",
          },
        },
        [
          _c("i", { staticClass: "fa fa-info-circle" }),
          _vm._v(
            "\n                      Goolge簡報嵌入碼在哪裡\n                    "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block mt-10" }, [
      _vm._v("\n                  文件檔案上傳格式：\n                  "),
      _c("span", { staticClass: "text-red" }, [_vm._v("(上限10MB)")]),
      _c("br"),
      _vm._v("1.WORD：DOC、DOCX。 "),
      _c("br"),
      _vm._v("2.EXCEL：XLS、XLSX。\n                  "),
      _c("br"),
      _vm._v("3.POWERPOINT：PPT、PPTX。 "),
      _c("br"),
      _vm._v("4.PDF。 "),
      _c("br"),
      _vm._v("5.TXT。\n                  "),
      _c("br"),
      _vm._v("6.XPS。 "),
      _c("br"),
      _vm._v("7.ZIP。\n                  "),
      _c("br"),
      _vm._v("(需上傳多個檔案建議使用ZIP檔上傳)\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "datetimepicker" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 狀態：\n              "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }