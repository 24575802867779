var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Stores" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("店家管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("訂餐服務管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("店家管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openStoreInfo("")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增店家\n          "),
                ]
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.keyword,
                      expression: "searchData.keyword",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋店家名稱",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.keyword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "keyword", $event.target.value)
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.onPageChange(1, 10)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數：" + _vm._s(_vm.total)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm._l(_vm.storeList.datas, function (store) {
                        return _c("tr", { key: store.storeId }, [
                          _c("td", [_vm._v(_vm._s(store.storeName))]),
                          _c("td", [_vm._v(_vm._s(store.phone))]),
                          _c("td", [_vm._v(_vm._s(store.fax))]),
                          _c("td", [_vm._v(_vm._s(store.address))]),
                          _c("td", [_vm._v(_vm._s(store.averageScore))]),
                          _c("td", { staticClass: "text-center" }, [
                            _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: store.storeStatus,
                                    expression: "store.storeStatus",
                                  },
                                ],
                                attrs: {
                                  "false-value": 0,
                                  id: "store" + store.storeId,
                                  "true-value": 1,
                                  name: "ckbx-style-8",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(store.storeStatus)
                                    ? _vm._i(store.storeStatus, null) > -1
                                    : _vm._q(store.storeStatus, 1),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = store.storeStatus,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              store,
                                              "storeStatus",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              store,
                                              "storeStatus",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(store, "storeStatus", $$c)
                                      }
                                    },
                                    function ($event) {
                                      return _vm.updateStore(store)
                                    },
                                  ],
                                },
                              }),
                              _c("label", {
                                attrs: { for: "store" + store.storeId },
                              }),
                            ]),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: { title: "菜單" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openStoreMenu(store.storeId)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-book" })]
                            ),
                            _vm._v("\n                   \n                  "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { title: "編輯" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openStoreInfo(store.storeId)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-pencil" })]
                            ),
                            _vm._v("\n                   \n                  "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                attrs: { title: "刪除" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteStore(store)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("storeEdit", {
        ref: "storeEdit",
        on: { getStoreList: _vm.getStoreList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("店家名稱")]),
      _c("th", { staticStyle: { "min-width": "110px" } }, [_vm._v("電話")]),
      _c("th", { staticStyle: { "min-width": "110px" } }, [_vm._v("傳真")]),
      _c("th", [_vm._v("地址")]),
      _c("th", [_vm._v("平均評分")]),
      _c("th", { staticClass: "text-center text-nowrap" }, [
        _vm._v("上架狀態"),
      ]),
      _c(
        "th",
        { staticClass: "text-center", staticStyle: { "min-width": "150px" } },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }