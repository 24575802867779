<template>
  <div id="CourseList">
    <section class="content-header">
      <h1>批量課程紀錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">批量課程紀錄</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              class="btn btn-block btn-success btn-add mr-10"
              type="button"
              style="min-width: auto"
              @click="exportList()"
            >
              匯出
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.companyId"
                @change="getDeptList()"
              >
                <option value="">全部</option>
                <option
                  v-for="(company, index) in companyList"
                  :value="company.companyId"
                  :key="index"
                >
                  {{ company.companyName }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit2"
                required="required"
                v-model="searchData.deptId"
                @change="getDataList()"
              >
                <option value="">全部部門</option>
                <option
                  v-for="(dept, index) in deptList"
                  :value="dept.deptId"
                  :key="index"
                >
                  {{ dept.deptIdName }}
                </option>
              </select>
            </div>
            <div class="text">
              <p class="data-num text-red">*未完成人數僅統計課程為必修的人</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 5%">#</th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    類型
                  </th>
                  <th class="text-nowrap" style="width: 15%; min-width: 180px">
                    課程名稱
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    必修完課人數
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    選修完課人數
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    未完課人數
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    完成測驗人數
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="6">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center">{{ data.courseType }}</td>
                    <td>{{ data.courseName }}</td>
                    <td class="text-center">{{ data.requireFinishCount }}</td>
                    <td class="text-center">
                      {{ data.notRequireFinishCount }}
                    </td>
                    <td class="text-center">{{ data.notFinishedCount }}</td>
                    <td class="text-center" v-if="data.finishTestCount == -1">
                      無測驗
                    </td>
                    <td class="text-center" v-else>
                      {{ data.finishTestCount }}人
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";

export default {
  components: {},
  data() {
    return {
      loading: false,
      searchData: {
        courseId: this.$route.query.ids || "", // 課程id
        companyId: "", // 公司類型
        deptId: "", // 部門id
      },
      companyList: [],
      deptList: [],
      list: [],
    };
  },
  async created() {
    this.getCompanyList();
    this.getDeptList();
    this.getDataList();
  },
  methods: {
    getCompanyList() {
      api.education.company().then(({ data }) => {
        this.companyList = data.result;
      });
    },
    getDeptList() {
      api.education
        .deptList({
          deptType: "0060", // 部門層級
          parentDeptId: "",
          companyId: this.searchData.companyId, // 公司代號
        })
        .then(({ data }) => {
          this.deptList = data.result;
          this.getDataList();
        });
    },
    getDataList() {
      this.loading = true;
      api.education.course.record(this.searchData).then(({ data }) => {
        this.list = data.result || [];
        this.loading = false;
      });
    },
    async exportList() {
      let data = [];
      this.list.forEach((res, index) => {
        data.push({
          "#": index + 1,
          類型: res.courseType,
          課程名稱: res.courseName,
          必修完課人數: res.requireFinishCount,
          選修完課人數: res.notRequireFinishCount,
          未完課人數: res.notFinishedCount,
          完成測驗人數: res.isRequire,
        });
      });

      utils.exportData(
        data,
        utils.formatTime(this.eatDay, "YYYYMMDD") +
          "_" +
          "批量課程紀錄" +
          ".xlsx"
      );
    },
    back() {
      this.$router.push({
        name: "ETCourseList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
