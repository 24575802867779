var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "App" } },
    [
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
      _c("AlertBox", { ref: "alertBox" }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "loading" } }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }