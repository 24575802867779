var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "GroupsPermissionSetting" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("群組權限設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("系統管理")]),
          _c("li", [_vm._v("帳號管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("群組權限設定")]),
        ]),
      ]),
      _vm._m(0),
      _c("AlertBox"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group has-label" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "selectGroup" } },
                [_vm._v("請選擇群組：")]
              ),
              _c(
                "select",
                { staticClass: "form-control", attrs: { id: "selectGroup" } },
                [
                  _c("option", [_vm._v("一般使用者")]),
                  _c("option", [_vm._v("網銀員工")]),
                  _c("option", [_vm._v("部級主管")]),
                  _c("option", [_vm._v("處級主管")]),
                  _c("option", [_vm._v("中心層級主管")]),
                  _c("option", [_vm._v("人資部")]),
                  _c("option", [_vm._v("測試人員")]),
                  _c("option", [_vm._v("SystemAdmin")]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" S0 簽核管理"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" S0-1 收件夾"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" SO-02 簽核紀錄"),
                      ]),
                    ]),
                  ]),
                  _c("td"),
                ]),
                _c("tr", [
                  _c("td", { attrs: { rowspan: "7" } }, [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0 績效考核"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-001 目標設定"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0011 年度目標"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0012 處級目標"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0013 部級目標"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0014 人員目標"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0015 新人目標"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-02 考核設定"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-003 員工每月考核"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-002 新人考核"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-004 員工半年平核"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-005 主管大考"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-03 人員管理設定"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-032 群組設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-033 簽核設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-031 簽核開放時間"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-006 組織設定"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0061 單位設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0062 部門小組設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0069 異動歷程"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-007 作業管理"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" C0-0071 作業時間限制設定"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" A0 帳號管理"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" A0-001 帳號權限管理"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" A0-002 個人資料維護"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" A0-003 修改密碼"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" A0-004 使用紀錄"),
                      ]),
                    ]),
                  ]),
                  _c("td"),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" P0 權限管理"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" P0-001 群組成員管理"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" P0-002 功能選單設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" P0-003 群組權限設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" P0-005 帳號切換設定"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" P0-006 結轉EF的人員組織"),
                      ]),
                    ]),
                  ]),
                  _c("td"),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" X0 個別權限定義"),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" X0-001 可新增主辦單位"),
                      ]),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" X0-888 SystemAdmin行使的權限"),
                      ]),
                    ]),
                  ]),
                  _c("td"),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "box-footer" }, [
          _c("div", { staticClass: "btns" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v("儲存")]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }