<template>
  <div id="EatingHabits">
    <section class="content-header">
      <h1>員工飲食習慣</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">員工飲食習慣</li>
      </ol>
    </section>
    <section class="content eating-habits">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <label class="control-label" for="selectOffice">辦公室：</label>
              <select class="form-control right" id="selectOffice" v-model="office">
                <option value>請選擇</option>
                <option
                  :key="index"
                  :value="office.office"
                  v-for="(office, index) in officeList"
                >{{office.office}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="inputOrderer">姓名：</label>
              <input type="text" class="form-control right" id="inputOrderer" v-model="name" />
            </div>
            <button type="button" class="btn btn-primary" @click="getHabitsList()">查詢</button>
            <div class="text">
              <p class="data-num">資料總筆數：{{total}}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px;">辦公室</th>
                  <th style="min-width: 80px;">姓名</th>
                  <th>飲食習慣</th>
                </tr>
                <tr :key="index" v-for="(item, index) in habitsList.datas">
                  <td>{{item.office}}</td>
                  <td>{{item.employeeName}}</td>
                  <td>{{getMealType(item.mealType)}}<span v-if="item.notEat != ''">；{{item.notEat}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center">
          <Pagination :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數
      page: 1,

      name: "",
      office: "",

      habitsList: {},
      officeList: {}
    };
  },
  created() {
    this.getOfficeList();
    this.getHabitsList();
  },
  methods: {
    onPageChange(pageIdx, pageSize) {
      this.page = pageIdx;
      this.pageSize = pageSize;
      this.getHabitsList();
    },
    getHabitsList() {
      // 取得飲食習慣列表
      api.mealHabits
        .queryAll({
          employeeName: this.name,
          office: this.office,
          page: this.page,
          pageSize: this.pageSize
        })
        .then(res => {
          if (res.data.status == "1") {
            this.habitsList = res.data.result;
            this.total = this.habitsList.total;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getOfficeList() {
      api.office
        .queryAll({
          status: "1"
        })
        .then(res => {
          if (res.data.status == "1") {
            this.officeList = res.data.result.datas;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getMealType(state) {
      let typeName = "";
      switch (state) {
        case 0:
          typeName = "素食";
          break;
        case 1:
          typeName = "葷食";
          break;
      }

      return typeName;
    }
  }
};
</script>
