var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "table table-bordered" }, [
      _c("tbody", [
        _c("tr", { staticClass: "bg-gray" }, [
          _c("th", { staticClass: "tc mw100" }, [
            _vm._v("任務分數（滿分5分）"),
          ]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("1")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("2")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("3")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("4")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("5")]),
        ]),
        _c("tr", [
          _c("th", { staticClass: "tc mw100 bg-gray" }, [_vm._v("代表意義")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("差")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("尚需努力")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("達標")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("超標")]),
          _c("td", { staticClass: "tc mw50" }, [_vm._v("高標")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }