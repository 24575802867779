<template>
  <div id="PersonalEdit">
    <section class="content-header">
      <h1>
        {{ person.level === 4 ? "新進員工" : "工作績效" }}目標
        {{ person.level === 4 ? "" : `(${person.text})` }}
      </h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link :to="`/OKR/PersonalList/${person.type}`">
            {{ person.level === 4 ? "新進員工" : "工作績效" }}目標
            {{ person.level === 4 ? "" : `(${person.text})` }}
          </router-link>
        </li>
        <li class="active">
          {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}目標
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}目標
            </h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
            <button
              v-if="
                data.objectiveStatus === 1 &&
                data.createId === userData.user.employeeId
              "
              @click="signCancel()"
              class="btn btn-danger btn-cog"
              type="button"
              style="width: auto;"
            >
              抽單
            </button>
          </div>
          <div class="table-responsive" style="max-width: 1400px; margin: auto">
            <div class="btns" v-if="hasEdit">
              <button
                class="btn btn-warning"
                style="float: right; margin: 10px 0px"
                type="button"
                @click="showContent()"
              >
                <i class="fa fa-plus"></i>
                新增目標
              </button>
            </div>
            <table
              class="table table-hover table-bordered min-800"
              style="margin: 5px auto"
            >
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">#</th>
                  <th class="tc mw90">人員</th>
                  <th class="tc mw50">年份</th>
                  <th class="tc mw50">月份</th>
                  <th class="tc mw150">部級目標</th>
                  <th class="tc mw150">人員目標</th>
                  <th class="tc mw80">狀態</th>
                  <th class="tc mw150" v-if="hasChange">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="8">尚無設定目標</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="index + 1"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.month"></td>
                  <td class="pre-wrap" v-text="data.parentObjective"></td>
                  <td class="pre-wrap" v-text="data.objective"></td>
                  <td
                    class="tc"
                    v-text="define.sendStatus[data.objectiveStatus]"
                  ></td>
                  <td class="tc" v-if="hasChange">
                    <button class="btn btn-primary" @click="showContent(data)">
                      修改
                    </button>
                    <span v-if="hasEdit">&nbsp;</span>
                    <button
                      class="btn btn-danger"
                      v-if="hasEdit"
                      @click="deleteData(data)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <SignLog
            :datas="datas"
            :data="data"
            :hasEdit="hasEdit"
            :backList="backList"
          />
          <div
            class="box-footer no-border mt-10"
            v-if="datas.length === 0"
          ></div>
        </div>
      </div>
    </section>
    <PersonalContent ref="personalContent" v-on:updateList="getDataList" />
    <SignCancel ref="signCancel" :backList="backList" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import SignLog from "@/components/SignLog";
import PersonalContent from "./PersonalContent";
import SignCancel from "./SignCancel";


export default {
  components: {
    SignLog,
    PersonalContent,
    SignCancel,
  },
  data() {
    return {
      hasAll: false,
      hasEdit: false,
      hasChange: false,
      loading: true,
      total: 0,
      addData: {
        type: this.$route.params.type,
        employeeId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.deptId,
        formId: this.$route.params.formId,
        back: this.$route.params.back,
        searchData: this.$route.params.searchData,
      },
      view: this.$route.params.back === "SignHistory",
      person: this.global.getPerson(this.$route.params.type),
      id: this.$route.params.id,
      userData: {},
      permission: {},
      datas: [],
      data: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.employeeId;
    this.addData.deptId = to.params.deptId;
    this.addData.employeeId = to.params.employeeId;
    this.addData.year = to.params.year;
    this.addData.month = to.params.month;
    this.getDataList();
    next();
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasAll = !this.view;
    this.hasEdit =
      this.hasAll &&
      (!this.id ||
        this.userData.user.employeeId === this.id ||
        (!this.permission.isEmployee &&
          this.permission.deptIds.includes(this.addData.deptId) &&
          (this.person.level === 4 || this.person.level === 5)));
    this.getDataList();
  },
  methods: {
    getDataList() {
      if (this.id) {
        this.addData.objectiveType = -1;
        api.okr.goal[this.person.api].queryAll(this.addData).then((res) => {
          if (res.data.status === "1") {
            this.datas = res.data.result.datas || [];
            this.total = res.data.result.total;
            this.loading = false;
            if (this.datas.length) {
              this.data = this.datas[0];
              this.hasEdit = this.hasEdit && !this.data.objectiveStatus;
              this.hasChange =
                this.hasAll &&
                this.data.objectiveStatus !== 2 &&
                ((!this.permission.isEmployee &&
                  this.permission.deptIds.includes(this.data.deptId)) ||
                  (this.permission.isEmployee &&
                    this.userData.user.employeeId === this.id));
            }
          }
        });
      } else {
        this.loading = false;
      }
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.goal.delete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    showContent(data) {
      this.addData.objectiveType = 0;
      this.addData.userData = this.userData;
      this.addData.permission = this.permission;
      this.addData.employeeId = this.permission.isEmployee
        ? this.id || this.userData.user.employeeId
        : this.id;
      this.$refs.personalContent.showContent(data, this.addData);
    },
    signCancel() {
      let id = this.datas[0].formId
      let type = this.datas[0].formTypeId
      this.$refs.signCancel.showContent(id, type);
    },
    backList() {
      this.$router.push({
        name: this.$route.params.back || "PersonalList",
        params: {
          type: this.person.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
