var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "store_setting",
      staticClass: "modal fade",
      attrs: { id: "storeEdit" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.storeName,
                        expression: "edit.storeName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputStoreName",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.edit.storeName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "storeName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.phone,
                        expression: "edit.phone",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputStoreTel",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.edit.phone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "phone", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "inputStoreFax" },
                  },
                  [_vm._v("傳真：")]
                ),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.fax,
                        expression: "edit.fax",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "inputStoreFax", type: "text" },
                    domProps: { value: _vm.edit.fax },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "fax", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.address,
                        expression: "edit.address",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputStoreAddress",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.edit.address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "address", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "inputStoreEmail" },
                  },
                  [_vm._v("電子郵件：")]
                ),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.email,
                        expression: "edit.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "inputStoreEmail", type: "text" },
                    domProps: { value: _vm.edit.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(4),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.storeInfo,
                        expression: "edit.storeInfo",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "inputStoreInfo" },
                    domProps: { value: _vm.edit.storeInfo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "storeInfo", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(5),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.note,
                        expression: "edit.note",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "inputStoreNote" },
                    domProps: { value: _vm.edit.note },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "note", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-3 control-label" }, [
                  _vm._v("素食："),
                ]),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c(
                    "div",
                    { staticClass: "checkbox-inline" },
                    [
                      _c("iCheckCheckBox", {
                        attrs: { propChecked: _vm.edit.isVegetarian },
                        on: {
                          "update:checked": function ($event) {
                            _vm.edit.isVegetarian = $event["checked"]
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                ref: "editSaveButton",
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
              },
              [_vm._v("儲存")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v("關閉")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("編輯店家")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputStoreName" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 店家名稱：\n            "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputStoreTel" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 電話：\n            "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputStoreAddress" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 地址：\n            "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputStoreInfo" },
      },
      [
        _vm._v("\n              描述：\n              "),
        _c("br"),
        _vm._v("(使用者可見)\n            "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputStoreNote" },
      },
      [
        _vm._v("\n              備註：\n              "),
        _c("br"),
        _vm._v("(僅限管理員)\n            "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }