<template>
  <div id="CompanyFilesEdit">
    <section class="content-header">
      <h1>公司文件</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司公告</li>
        <li class="active">公司文件</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning company-files-edit">
        <div class="box-header with-border">
          <h3 class="box-title" v-text="pageTitle"></h3>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectType">
                  <span class="text-red">*</span> 文件分類：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    id="selectType"
                    required="required"
                    v-model="fileDatas.typeId"
                  >
                    <!-- <option value="0">請選擇文件分類</option> -->
                    <option
                      :key="index"
                      :value="type.tId"
                      v-for="(type, index) in fileTypes"
                    >{{ type.typeName }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectUnit">
                  <span class="text-red">*</span> 歸屬單位：
                </label>
                <div class="col-sm-10">
                  <div class="row">
                    <div class="col-sm-6">
                      <CompanyList
                        v-bind:propCompanyIdx="fileDatas.issuedCompanyId"
                        v-on:update:companyIdx="fileDatas.issuedCompanyId=$event"
                      />
                    </div>
                    <div class="col-sm-6 unit">
                      <DepartmentList
                        v-bind:propCompanyIdx="fileDatas.issuedCompanyId"
                        v-bind:propDepartmentIdx="fileDatas.issuedUnit"
                        v-on:update:departmentIdx="fileDatas.issuedUnit=$event"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 單位聯絡人：
                </label>
                <div class="col-sm-10">
                  <DepartmentEmpolyeeList
                    v-bind:propDeptId="fileDatas.issuedUnit"
                    v-bind:propEmpId="fileDatas.contactEmpId"
                    v-on:update:selectedId="fileDatas.contactEmpId=$event"
                  />
                </div>
              </div>-->
              <!-- <div class="form-group">
            <label class="col-sm-2 control-label" for="inputTitle">
              <span class="text-red">*</span> 承辨人：
            </label>
            <div class="col-sm-10">
              <input
                class="form-control"
                id="inputTitle"
                required="required"
                type="text"
                v-model="fileDatas.DocumentName"
              />
            </div>
              </div>-->
              <div class="form-group" v-show="hasSelectTargetPermission">
                <label class="col-sm-2 control-label" for="selectCompany">
                  <span class="text-red">*</span> 公告對象：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" required="required" v-model="fileDatas.target">
                    <option value="0">網銀國際</option>
                    <option
                      :key="index"
                      :value="item.targetId"
                      v-for="(item, index) in issueTargets"
                    >{{ item.targetName }}</option>
                  </select>
                  <ICheckCheckBox
                    :message="'不開放其他公司瀏覽(選「網銀國際」時此欄位唯讀)'"
                    v-bind:propDisabled="fileDatas.target=='0'"
                    v-bind:value="fileDatas.isTargetOnly"
                    v-on:update:checked="fileDatas.isTargetOnly=$event"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 文件名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="fileDatas.documentName"
                  />
                </div>
              </div>
              <CompanyFileUploader :propFileId="fileDatas.tId" />
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  @click="save"
                  class="btn btn-primary"
                  type="button"
                >{{ isCreateMode ? "新增" : "儲存" }}</button>
                <button
                  @click="$router.push({
                              name: 'CompanyFiles',
                              params: { searchData: $route.params.searchData }
                            })"
                  class="btn btn-default"
                  type="button"
                >返回列表</button>
              </div>
            </div>
          </form>
        </div>
        <AlertBox ref="alertBox" />
      </div>
    </section>
  </div>
</template>

<script>
import AlertBox from "@/components/AlertBox";
import CompanyList from "@/components/CompanyList";
import DepartmentList from "@/components/DepartmentList";
import DepartmentEmpolyeeList from "@/components/DepartmentEmpolyeeList";
import CompanyFileUploader from "@/views/CompanyFiles/CompanyFileUploader";
import ICheckCheckBox from "@/components/iCheckCheckBox";

import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";

export default {
  components: {
    AlertBox,
    CompanyList,
    DepartmentList,
    DepartmentEmpolyeeList,
    CompanyFileUploader,
    ICheckCheckBox
  },
  data() {
    return {
      hasSelectTargetPermission: false,
      isCreateMode: true,
      fileTypes: [],
      issueTargets: [],
      module: "CompanyDocument",
      fileDatas: {
        tId: "",
        typeId: "",
        issuedCompanyId: "",
        issuedUnit: "",
        target: "",
        isTargetOnly: 0,
        contractor: "",
        documentName: ""
      }
    };
  },
  created() {
    this.hasSelectTargetPermission = this.$user.hasPermission(
      config.Permission.CompanyDocumentSelectTarget
    );
    if (this.$route.params.id) {
      this.isCreateMode = false;
      this.fileDatas.tId = this.$route.params.id;
    } else {
      this.fileDatas.tId = utils.getTimeId();
      this.fileDatas.issuedCompanyId = this.$user.getUserCompanyId();
    }
    this.refreshPage();
  },
  mounted() {
    // this.refreshPage();
  },
  methods: {
    async refreshPage() {
      var fileTypeResponse = await api.companyFilesType.queryall({});
      this.fileTypes = fileTypeResponse.data.result.datas;

      var resIssueTargets = await api.companyFiles.queryIssueTargets({});
      this.issueTargets = resIssueTargets.data.result;

      if (this.isCreateMode) {
        this.fileDatas.typeId = this.fileTypes[0].tId;
        this.fileDatas.target = this.issueTargets[0].targetId;
      } else {
        let res = await api.companyFiles.query({ id: this.fileDatas.tId });
        this.fileDatas = res.data.result;
        this.fileDatas.target = this.fileDatas.target;
      }
    },
    async save() {
      var _isCheck = await this.checkField(this.fileDatas);
      if (!_isCheck) return;

      if (this.isCreateMode) {
        api.companyFiles.create(this.fileDatas).then(res => {
          if (res.data.status == "1") {
            this.$refs.alertBox.showAlert("新增成功");
            this.isCreateMode = false;
          } else {
            this.$refs.alertBox.showAlert("新增失敗\n" + res.data.message);
          }
        });
      } else {
        api.companyFiles.update(this.fileDatas).then(res => {
          if (res.data.status == "1") {
            this.$refs.alertBox.showAlert("更新成功");
          } else {
            this.$refs.alertBox.showAlert("更新失敗\n" + res.data.message);
          }
        });
      }
    },

    async checkField(fileDatas) {
      var response_queryall = await api.file.queryApplyAll({
        module: this.module,
        moduleId: this.fileDatas.tId
      });
      var files = response_queryall.data.result;

      if (fileDatas.documentName == "") {
        this.$refs.alertBox.showAlert("文件名稱不可為空!!");
        return false;
      }

      if (files.length < 1) {
        this.$refs.alertBox.showAlert("請上傳檔案!!");
        return false;
      }

      return true;
    }
  },
  computed: {
    pageTitle() {
      return (this.isCreateMode ? "新增" : "編輯") + "公司文件";
    }
  }
};
</script>
