<template>
  <div id="CompanyFiles">
    <section class="content-header">
      <h1>公司文件</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">公司文件</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-select has-col5">
            <div class="btns" v-if="hasEditPermission">
              <button
                @click="downloadAll()"
                class="btn btn-block btn-success btn-cog mr-10"
                type="button"
              >
                <i class="fa fa-download"></i> 批次下載
              </button>
              <button
                @click="deleteAll()"
                class="btn btn-block btn-danger btn-cog mr-10"
                type="button"
              >
                <i class="fa fa-trash"></i> 批次刪除
              </button>
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="$router.push('/CompanyFilesEdit')"
              >
                <i class="fa fa-plus"></i> 新增文件
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  required="required"
                  v-model="searchDatas.target"
                  @change="onSearch(true)"
                >
                  <option
                    v-for="(item, index) in issueTargets"
                    :key="index"
                    :value="item.targetId"
                  >
                    {{ item.targetName }}
                  </option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectUnit"
                  required="required"
                  v-model="searchDatas.issuedUnit"
                  @change="onSearch()"
                >
                  <option value>全部單位</option>
                  <option
                    :key="index"
                    :value="item.issuedUnit"
                    v-for="(item, index) in issuedUnitList"
                  >
                    {{ item.issuedUnitName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectType"
                  required="required"
                  v-model="searchDatas.typeId"
                  @change="onSearch()"
                >
                  <option value="-1">請選擇文件分類</option>
                  <option
                    v-for="(type, index) in fileTypes"
                    :key="index"
                    :value="type.tId"
                  >
                    {{ type.typeName }}
                  </option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  name="table_search"
                  placeholder="搜尋文件名稱"
                  type="text"
                  v-model="searchDatas.keyword"
                />
                <div class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="onSearch()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="'資料總筆數：' + total"></p>
              <!-- <p class="data-num">搜尋結果：211</p> -->
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-center" v-if="hasEditPermission">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 90px; width: 10%">上傳日期</th>
                  <!-- <th style="min-width: 90px">發布時間</th> -->
                  <th class="text-nowrap" style="width: 10%">文件分類</th>
                  <th class="text-nowrap" style="width: 10%">歸屬單位</th>
                  <!-- <th class="text-nowrap" style="width: 5%">單位聯絡人</th>
                  <th class="text-nowrap" style="width: 5%">承辦人</th>-->
                  <th>文件名稱</th>
                  <th class="text-nowrap text-center">附件</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 10%"
                    v-if="hasEditPermission"
                  >
                    功能
                  </th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="files.length === 0">
                  <td class="text-center" colspan="7">查詢無任何資料</td>
                </tr>
                <tr
                  v-else
                  v-for="(file, index) in files"
                  :key="index"
                  :value="file.tId"
                >
                  <td class="text-center" v-if="hasEditPermission">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="file"
                          :propChecked="file.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td v-text="formatTime(file.createTime)"></td>
                  <td v-text="file.typeName"></td>
                  <td v-text="file.issuedUnitName"></td>
                  <!-- <td v-text="file.contactEmpName"></td>
                  <td v-text="file.contractorName"></td>-->
                  <td v-text="file.documentName"></td>
                  <!-- 附件下載 -->
                  <td class="text-center">
                    <a
                      :key="file.id"
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      class="file-icon"
                      v-for="file in file.files"
                    >
                      <i
                        :class="'icon icon-' + file.mine.substring(0, 3)"
                      ></i>
                    </a>
                    <span v-show="file.files.length == 0">-</span>
                  </td>
                  <td class="text-center" v-if="hasEditPermission">
                    <button
                      class="btn btn-primary"
                      @click="
                        $router.push({
                          name: 'CompanyFilesEdit',
                          params: { id: file.tId, searchData: searchDatas },
                        })
                      "
                    >
                      修改
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchDatas.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <companyFileAlert
      ref="companyFileAlert"
      :checkDatas="checkDatas"
      v-on:updateList="refreshFiles"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import companyFileAlert from "@/views/CompanyFiles/CompanyFileAlert";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";

export default {
  components: {
    Pagination,
    DatePicker,
    iCheckCheckBox,
    companyFileAlert,
  },
  data() {
    return {
      loading: true,
      host: process.env.VUE_APP_EIPAPI_PATH,
      total: 0,
      checkIds: [],
      checkDatas: [],
      fileTypes: [],
      issueTargets: [],
      files: [],
      issuedUnitList: [],
      isCheckAll: false,
      hasEditPermission: false,
      searchDatas: {
        pageNum: 1,
        pageSize: 20,
        typeId: "-1",
        issuedUnit: "",
        target: "",
        keyword: "",
      },
    };
  },
  created() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.CompanyDocumentEdit
    );
    this.searchDatas.target = this.$user.getUserCompanyId();
    if (this.$route.params.searchData != undefined) {
      this.searchDatas = this.$route.params.searchData;
    }
    this.refresh();
  },
  methods: {
    async refresh() {
      try {
        //發佈對象
        var resIssueTargets = await api.companyFiles.queryIssueTargets({});
        this.issueTargets = resIssueTargets.data.result;
        //文件類別
        var resFileTypes = await api.companyFilesType.queryall({});
        this.fileTypes = resFileTypes.data.result.datas;

        this.refreshFiles();
      } catch (err) {
        // console.log(err);
      }
    },
    async refreshFiles() {
      try {
        this.clearCheckAll();
        var resFiles = await api.companyFiles.queryall(this.searchDatas);
        this.files = resFiles.data.result.datas;
        this.total = resFiles.data.result.total;
        this.loading = false;

        //歸屬單位
        var task_issuedUnit = await api.companyFiles.queryIssuedUnit({
          target: this.searchDatas.target,
        });
        this.issuedUnitList = task_issuedUnit.data.result.datas;

        this.$refs.pagination.setPage(this.searchDatas.pageNum, this.total);
      } catch (err) {
        // console.log(err);
      }
    },
    onSearch(refresh = false) {
      if (refresh) this.searchDatas.issuedUnit = "";
      this.searchDatas.pageNum = 1;
      this.refreshFiles();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchDatas.pageNum = pageIdx;
      // this.searchDatas.pageSize = pageSize;
      this.refresh();
    },
    formatTime: function (t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },
    clearCheckAll() {
      this.checkIds = [];
      this.checkDatas = [];
      this.checkAll({ checked: 0 });
      this.$forceUpdate();
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.files.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    checkChange(data) {
      const id = data.value.tId;
      if (data.checked && !this.checkIds.includes(id)) {
        this.checkIds.push(id);
        this.checkDatas.push({
          tId: data.value.tId,
          text: data.value.documentName,
        });
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.checkDatas.splice(index, 1);
        }
      }
    },
    downloadAll() {
      if (this.checkIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇下載對象！");
        return;
      }
      this.$refs.companyFileAlert.showAlert("download");
    },
    deleteAll() {
      if (this.checkIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇刪除對象！");
        return;
      }
      this.$refs.companyFileAlert.showAlert("delete");
    },
  },
};
</script>
