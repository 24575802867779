var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Seating" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("座位表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公司通訊錄")]),
        _c("li", { staticClass: "active" }, [_vm._v("座位表")]),
      ]),
    ]),
    _c("section", { staticClass: "content seating" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box has-select box-header with-border" },
            [
              _vm.hasEditPermission
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-default btn-cog",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goOffice()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-cog" }),
                      _vm._v(" 辦公室管理\n          "),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "form-group select-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "selectOffice" },
                  },
                  [_vm._v("辦公室：")]
                ),
                _vm.offices && _vm.offices.length > 0
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.key,
                            expression: "key",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "selectOffice" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.key = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.changeOffice(
                                $event.target.selectedIndex
                              )
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.offices, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.id } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.office) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "select",
                      {
                        staticClass: "form-control",
                        attrs: { id: "selectOffice" },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("請選擇"),
                        ]),
                      ]
                    ),
              ]),
              _vm.offices && _vm.offices.length > 0
                ? [
                    _vm.hasEditPermission
                      ? _c(
                          "label",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { for: "inputFile" },
                          },
                          [
                            _c("input", {
                              ref: "seatPic",
                              staticStyle: { display: "none" },
                              attrs: {
                                accept: ".jpg, .jpeg, .png",
                                id: "inputFile",
                                onclick: "this.value=null;",
                                type: "file",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeFile($event)
                                },
                              },
                            }),
                            _c("i", { staticClass: "fa fa-picture-o" }),
                            _vm._v(" 更新圖片\n            "),
                          ]
                        )
                      : _vm._e(),
                    _vm._v("\n             \n            "),
                    _vm.hasEditPermission
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFile()
                              },
                            },
                          },
                          [_vm._v("\n              刪除圖片\n            ")]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm.offices && _vm.offices.length > 0
            ? _c("div", { staticClass: "pic text-center" }, [
                _c("img", { attrs: { src: _vm.fileUrl, draggable: "false" } }),
              ])
            : _c("p", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v("尚未新增任何辦公室。"),
              ]),
        ]),
        _vm.hasEditPermission
          ? _c("div", { staticClass: "box-footer" })
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }