var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        { staticClass: "col-sm-2 control-label", attrs: { for: "inputFile" } },
        [_vm._v("上傳檔案：")]
      ),
      _c("div", { staticClass: "col-sm-10 text" }, [
        _c("input", {
          attrs: {
            accept:
              ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip",
            id: "inputFile",
            maxlength: "5",
            multiple: "multiple",
            name: "file1",
            type: "file",
          },
          on: {
            change: function ($event) {
              return _vm.onFileChange($event)
            },
          },
        }),
        _vm._m(0),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.files.length > 0,
            expression: "files.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c(
          "label",
          {
            staticClass: "col-sm-2 control-label",
            attrs: { for: "inputFile" },
          },
          [_vm._v("已有檔案：")]
        ),
        _c("div", { staticClass: "col-sm-10 text" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c(
              "tbody",
              _vm._l(_vm.files, function (file, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(file.name))]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteFile(file.applyId)
                          },
                        },
                      },
                      [_vm._v("刪除")]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _vm._v("\n        文件檔案上傳格式：\n        "),
      _c("span", { staticClass: "text-red" }, [_vm._v("(上限20MB)")]),
      _c("br"),
      _vm._v("1.WORD：DOC、DOCX。\n        "),
      _c("br"),
      _vm._v("2.EXCEL：XLS、XLSX。\n        "),
      _c("br"),
      _vm._v("3.POWERPOINT：PPT、PPTX。\n        "),
      _c("br"),
      _vm._v("4.PDF。\n        "),
      _c("br"),
      _vm._v("5.TXT。\n        "),
      _c("br"),
      _vm._v("6.XPS。\n        "),
      _c("br"),
      _vm._v("7.ZIP。\n        "),
      _c("br"),
      _vm._v("(每個檔案上限20MB，最多可以選5個檔案)\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }