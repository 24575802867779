<template>
  <div id="PersonalTimeSetHistory">
    <section class="content-header">
      <h1>逾期開放歷史查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">逾期開放歷史查詢</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="datetime-group">
              <label class="control-label">執行時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="searchData.openType"
                  @change="resetDataList()"
                >
                  <option value="">全部分類</option>
                  <option value="0">人員目標</option>
                  <option value="1">小考</option>
                  <option value="2">大考</option>
                </select>
              </div>
            </div>
            <div class="col-5">
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="請輸入主旨、執行者姓名"
                  type="text"
                  v-model.trim="searchData.keyword"
                />
                <div class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="resetDataList()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">項目</th>
                  <th class="tc">分類</th>
                  <th class="tc">動作</th>
                  <th class="tc">主旨</th>
                  <th class="tc">執行者工號</th>
                  <th class="tc">執行者姓名</th>
                  <th class="tc">執行時間</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td
                    class="tc"
                    v-text="data.item.replace('績效考核', '')"
                  ></td>
                  <td class="tc" v-text="data.type"></td>
                  <td class="tc" v-text="data.action"></td>
                  <td class="tc" v-text="data.title"></td>
                  <td class="tc" v-text="data.empId"></td>
                  <td class="tc" v-text="data.empName"></td>
                  <td class="tc">
                    {{ data.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        openType: "",
        local: {
          startTime: new moment().format("YYYY-MM-DD 00:00:00"),
          endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
      },
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} UTC`
      );
      api.okr.mgmt.history.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
