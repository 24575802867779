<template>
  <div id="GroupsPermissionSetting">
    <section class="content-header">
      <h1>群組權限設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li>帳號管理</li>
        <li class="active">群組權限設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group has-label">
              <label class="control-label" for="selectGroup">請選擇群組：</label>
              <select class="form-control" id="selectGroup">
                <option>一般使用者</option>
                <option>網銀員工</option>
                <option>部級主管</option>
                <option>處級主管</option>
                <option>中心層級主管</option>
                <option>人資部</option>
                <option>測試人員</option>
                <option>SystemAdmin</option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> S0 簽核管理</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> S0-1 收件夾</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> SO-02 簽核紀錄</label>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td rowspan="7">
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0 績效考核</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-001 目標設定</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0011 年度目標</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0012 處級目標</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0013 部級目標</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0014 人員目標</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0015 新人目標</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-02 考核設定</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-003 員工每月考核</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-002 新人考核</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-004 員工半年平核</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-005 主管大考</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-03 人員管理設定</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-032 群組設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-033 簽核設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-031 簽核開放時間</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-006 組織設定</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0061 單位設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0062 部門小組設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0069 異動歷程</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-007 作業管理</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> C0-0071 作業時間限制設定</label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> A0 帳號管理</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> A0-001 帳號權限管理</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> A0-002 個人資料維護</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> A0-003 修改密碼</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> A0-004 使用紀錄</label>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> P0 權限管理</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> P0-001 群組成員管理</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> P0-002 功能選單設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> P0-003 群組權限設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> P0-005 帳號切換設定</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> P0-006 結轉EF的人員組織</label>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> X0 個別權限定義</label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> X0-001 可新增主辦單位</label>
                    </div>
                    <div class="checkbox">
                      <label><input class="flat" type="checkbox"> X0-888 SystemAdmin行使的權限</label>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer">
          <div class="btns">
            <button class="btn btn-primary" type="submit">儲存</button>
          </div>
        </div>
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import Pagination from '../components/Pagination';
import AlertBox from '../components/AlertBox';

export default {
  components: {
    Pagination,
    AlertBox,
  },
};
</script>
