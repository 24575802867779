var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "newJobContent", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c(
          "form",
          {
            staticClass: "form-horizontal",
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit()
              },
            },
          },
          [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table no-border" }, [
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(1),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.assignDeptId,
                                expression: "data.assignDeptId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "", disabled: !_vm.isHR },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "assignDeptId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.getUserList()
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.list, function (item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item.deptId } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.deptName) +
                                    "\n                      "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(2),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.employeeId,
                                expression: "data.employeeId",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "employeeId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.users, function (user, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: user.employeeId },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(user.employeeName) +
                                    "\n                      "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _vm._m(3),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.data.year,
                                expression: "data.year",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "", disabled: _vm.disabled },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "year",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.getCycleMonths()
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.years, function (year, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: year } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(year) +
                                    "\n                      "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.data.month,
                                expression: "data.month",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "", disabled: _vm.disabled },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "month",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(12, function (month, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: month } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(month) +
                                    "\n                      "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(4),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("新增表單")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label w90" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    考核單位\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    所屬人員\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    年度\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("新增")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("\n            取消\n          ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }