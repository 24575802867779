<template>
  <div id="StudentCourseDetail">
    <section class="content-header">
      <h1>{{ courseName }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">{{ courseName }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body left">
          <template v-if="isQuiz">
            <quiz
              v-on:updateStatus="quizCallUpdate"
              :isFinished="testData.isFinished"
            ></quiz>
          </template>
          <template v-else>
            <div class="top-box">
              <p class="pull-left">
                上課進度：
                {{
                  secondToMinute(learningTime) > datas.chapterTime
                    ? datas.chapterTime
                    : secondToMinute(learningTime)
                }}分鐘 / {{ datas.chapterTime }}分鐘
              </p>
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="showInfo()"
              >
                章節介紹
              </button>
            </div>
            <video
              id="video"
              width="100%"
              v-if="
                datas.videoFiles &&
                datas.videoFiles[0] &&
                datas.learnMaterialType == 0
              "
              :src="`${hostName}${datas.videoFiles[0].filesUrl}`"
              controls
            >
              Your browser does not support HTML video.
            </video>
            <div class="ppt" v-if="datas.learnMaterialType == 1">
              <div class="loading">載入中...</div>
              <iframe
                id="iframe"
                :src="pptUrl(datas.pptUrl)"
                frameborder="0"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              >
              </iframe>
            </div>
          </template>
        </div>
        <div class="box-body right">
          <div class="title">
            課程章節
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <div class="chapter">
            <div
              class="item"
              :class="{
                'text-primary': !isQuiz && item.chapterId == datas.chapterId,
              }"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="check">
                <i class="fa fa-check" v-if="item.isFinished == '1'"></i>
              </div>
              <div>
                <div
                  class="name"
                  :class="{
                    'in-class': !isQuiz && datas.chapterId == item.chapterId,
                  }"
                  @click="changeChapter(item)"
                >
                  {{ item.chapterName }}
                </div>
                <div>
                  <button
                    class="btn btn-default"
                    @click="download(item.files)"
                    v-if="item.files.length > 0"
                  >
                    <i class="fa fa-file"></i>
                    附件下載
                  </button>
                </div>
              </div>
            </div>
            <div
              class="item quiz"
              :class="{ 'text-primary': isQuiz }"
              v-if="testData.testId"
            >
              <div class="check">
                <i class="fa fa-check" v-if="testData.isFinished"></i>
              </div>
              <div>
                <div
                  class="name"
                  :class="{ 'in-class': isQuiz }"
                  @click="changeChapter(datas, 'test')"
                >
                  課程測驗
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--章節介紹-->
      <div class="modal fade" ref="info">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-center">章節介紹</h4>
            </div>
            <div class="modal-body">
              <div
                v-if="datas.chapterDesc"
                v-html="changeN(datas.chapterDesc)"
              ></div>
              <div v-else>無章節介紹</div>
            </div>
            <div class="modal-footer text-center">
              <button
                class="btn btn-default"
                data-dismiss="modal"
                type="button"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import quiz from "@/components/CourseQuiz";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    quiz,
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      employeeId: "",
      datas: {
        chapterName: this.$route.params.chapterName || "", // 章節名稱
        chapterDesc: "", // 章節介紹
        courseId: this.$route.params.id || "", // 課程id
        chapterId: this.$route.params.chapterId || "",
        chapterStatus: 1, // 章節狀態
        chapterTime: 0, // 章節時數
        learnMaterialType: 0, // 教材類型 0：影片、1：簡報
        startTime: "", // 上課時間
        teacher: "", // 講師
        // chapterOrder: 3, // 章節排序
        // isDel: 0, // 是否刪除 0：啟用、1：刪除
        // createEmpId: "123123", // 創立人員編
        // createEmpName: "aaa", // 創立人姓名
      },
      courseName: this.$route.params.name,
      list: [],
      testData: [],
      video: "",
      currentTime: 0,
      learningTime: 0,
      counters: "",
      notFinishCount: 0,
      isQuiz: false,
    };
  },
  computed: {
    hostName() {
      const url = location.href;
      if (url.indexOf("localhost") > -1 || url.indexOf("dev") > -1)
        return "https://dev-eip.wanin.tw";
      else return "https://eip.wanin.tw";
    },
  },
  async created() {
    this.employeeId = this.$user.getUserData().user.employeeId;
    if (this.$route.query.quiz) {
      this.isQuiz = true;
    }
    this.getLearningRecord();
    this.checkCourseIsFinish();
    this.getData();
  },
  watch: {
    $route() {
      this.clearChapterCount();
      if (this.$route.query.quiz) {
        this.isQuiz = true;
        this.checkCourseIsFinish();
        return;
      }
      this.isQuiz = false;
      this.datas.chapterId = this.$route.params.chapterId;
      this.datas.chapterName = this.$route.params.chapterName;
      this.getData();
    },
  },
  methods: {
    checkCourseIsFinish() {
      api.education.course
        .check({
          courseId: this.datas.courseId, // 課程id
          employeeId: this.employeeId, // 員編
        })
        .then(({ data }) => {
          if (data.status == 1) {
            this.$eventBus.$emit("showAlert", "已完成課程");
          }
        });
    },
    getLearningRecord() {
      // 查詢是否有建立過明細
      api.education.record.main
        .query({
          courseId: this.datas.courseId, // 課程id
          employeeId: this.employeeId, // 員編
          // isFinished: 0, // 完成狀態 0：未完成、1：已完成
          order: 1, // 1：創建時間新到舊
        })
        .then(({ data }) => {
          if (data.status == "0") {
            this.setLearningRecord();
          }
        });
    },
    setLearningRecord() {
      // 建立明細
      api.education.record.main.create({
        courseId: this.datas.courseId, // 課程id
        employeeId: this.employeeId, // 員編
        // chapterId: this.datas.chapterId, // 章節id
        // learningTime: this.secondToTime(this.learningTime), // 學習時間
        // videoTimeline: this.secondToTime(this.currentTime), // 影片時間軸
      });
    },
    getData() {
      this.notFinishCount = 0;
      this.learningTime = 0;

      api.education.chapter
        .query({
          courseId: this.$route.params.id, // 課程id
          chapterId: this.$route.params.chapterId, // 章節id
          employeeId: this.employeeId,
        })
        .then(({ data }) => {
          if (data.status == 1) {
            this.datas = data.result;
            this.courseName = this.datas.courseName;
            this.list = this.datas.chapterList;
            this.testData = this.datas.testData;
            this.list.forEach((item) => {
              if (item.isFinished == 0) this.notFinishCount += 1;
              console.log("[getData] notFinishCount", this.notFinishCount);
            });
            this.$forceUpdate();

            if (
              (this.datas.videoFiles &&
                this.datas.videoFiles[0] &&
                this.datas.learnMaterialType == 0) ||
              this.datas.pptUrl
            ) {
              this.getTime();
            }
          } else {
            this.$eventBus.$emit("showAlert", "查無課程章節！");
            // this.back();
          }
        });
    },
    getTime() {
      api.education.record.detail
        .query({
          employeeId: this.employeeId, // 員編
          courseId: this.datas.courseId, // 課程id
          chapterId: this.datas.chapterId, // 章節id
        })
        .then(({ data }) => {
          this.video = document.getElementById("video");
          if (data.status == "1") {
            this.learningTime = this.timeToSecond(data.result.learningTime);
            this.currentTime =
              this.timeToSecond(data.result.videoTimeline) || 0;
            if (this.video) {
              this.video.currentTime = this.currentTime || 0;
            }
          } else {
            this.learningTime = 0;
          }
          this.setChapterCount();
          this.$forceUpdate();
        });
    },
    download(files) {
      var a = document.createElement("a");
      a.href = this.hostName + files[0].filesUrl;
      a.download = this.courseName + files[0].name;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getCurrentTime() {
      if (this.datas.learnMaterialType == 0) return this.video.currentTime;
      else return this.currentTime;
    },

    setChapterCount() {
      if (this.$route.query && this.$route.query.quiz) return;
      this.clearChapterCount();
      this.saveTime();
      this.counters = setInterval(() => {
        this.saveTime();
      }, 5000);
    },
    clearChapterCount() {
      clearInterval(this.counters);
      this.counters = "";
    },
    saveTime() {
      if (this.secondToMinute(this.learningTime) >= this.datas.chapterTime) {
        this.clearChapterCount();
        return;
      }

      let currentTime = this.secondToTime(this.getCurrentTime());
      this.learningTime += 5;
      let learningTime = this.secondToTime(Number(this.learningTime));
      const Index = this.list.findIndex(
        (item) => item.chapterId == this.datas.chapterId
      );

      api.education.record.detail
        .create({
          employeeId: this.employeeId,
          courseId: this.datas.courseId,
          chapterId: this.datas.chapterId,
          learningTime: learningTime,
          videoTimeline: currentTime,
        })
        .then((res) => {
          if (this.timeToSecond(learningTime) >= this.datas.chapterTime * 60) {
            if (this.notFinishCount == 1 && this.list[Index].isFinished == 0) {
              this.list[Index].isFinished = 1;
              this.checkCourseIsFinish();
              this.changeChapter(this.datas, "test");
              return;
            }

            if (this.notFinishCount >= 1) {
              for (let i = 1; i <= this.list.length; i++) {
                let num = Index + i;
                let nextIndex =
                  num >= this.list.length ? num - this.list.length : num;

                if (this.list[nextIndex].isFinished == 0) {
                  this.$router.push({
                    name: "ETStudentCourseDetail",
                    params: {
                      type: this.$route.params.type,
                      id: this.$route.params.id,
                      name: this.$route.params.name,
                      chapterId: this.list[nextIndex].chapterId,
                      chapterName: this.list[nextIndex].chapterName,
                      searchData: this.$route.params.searchData,
                    },
                  });
                  return;
                }
              }
            }
          }
        });
    },
    timeToSecond(time) {
      let h = time.split(":")[0];
      let m = time.split(":")[1];
      let s = time.split(":")[2];

      let second = 0;
      second += parseInt(h * 3600);
      second += parseInt(m * 60);
      second += parseInt(s);
      return second;
    },
    secondToMinute(time) {
      let minute = 0;
      minute += Math.floor(parseInt(time / 60));
      return minute;
    },
    secondToTime(time) {
      let hr = String(Math.floor(time / 3600)).padStart(2, 0);
      let min = String(Math.floor((time - hr * 3600) / 60)).padStart(2, 0);
      let sec = String(parseInt(time - hr * 3600 - min * 60)).padStart(2, 0);

      return hr + ":" + min + ":" + sec;
    },
    showInfo() {
      $(this.$refs.info).modal("show");
    },
    goDetail() {
      this.$router.push({
        name: "ETStudentCourseDetail",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    pptUrl(data) {
      return data.split('src="')[1].split('"')[0];
    },
    changeChapter(item, type) {
      this.$router.push({
        name: "ETStudentCourseDetail",
        params: {
          type: this.$route.params.type,
          id: this.$route.params.id,
          name: this.$route.params.name,
          chapterId: item.chapterId,
          chapterName: item.chapterName,
          chaterSearchData: this.$route.params.chaterSearchData,
          searchData: this.$route.params.searchData,
        },
        query: type == "test" ? { quiz: this.testData.testId } : null,
      });
    },
    back() {
      this.$router.push({
        name: "ETStudentCourse",
        params: {
          id: this.$route.params.id,
          type: this.$route.params.type,
          name: this.$route.params.name,
          searchData: this.$route.params.searchData,
        },
      });
    },
    changeN(data) {
      return data.replace(/(\n)/g, "<br>");
    },

    quizCallUpdate(val) {
      this.testData.isFinished = val == true ? 1 : 0;
      this.checkCourseIsFinish();
    },
  },
  beforeDestroy() {
    this.clearChapterCount();
  },
};
</script>

<style lang="scss" scoped>
@mixin max-md {
  @media (max-width: 991px) {
    @content;
  }
}
#StudentCourseDetail {
  .box {
    display: flex;
    @include max-md {
      flex-wrap: wrap;
    }
    .box-body {
      &.left {
        width: calc(100% - 350px);
        padding: 10px;
        @include max-md {
          width: 100%;
          order: 2;
        }

        #video,
        .ppt {
          padding: 5%;
        }

        .ppt {
          position: relative;
          .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          iframe {
            position: relative;
            width: 100% !important;
            height: 38vw !important;
            z-index: 2;
          }
        }
      }
      &.right {
        width: 350px;
        border-left: 1px solid #d2d6de;
        padding: 10px 0 0 0;
        @include max-md {
          width: 100%;
          order: 1;
          border-left: 0;
          border-bottom: 20px solid #ecf0f5;
        }
        .title {
          font-size: 22px;
          font-weight: bold;
          border-bottom: 1px solid #d2d6de;
          padding: 15px 20px;
        }
        .chapter {
          padding: 20px;
          .item {
            font-size: 16px;
            margin-bottom: 20px;
            display: flex;
            align-items: flex-start;
            .check {
              width: 20px;
              height: 20px;
              background-color: #eeeeee;
              margin-right: 10px;
              border: 1px solid #c5c5c5;
              position: relative;

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #000;
              }
            }
            .name {
              cursor: pointer;

              &.in-class {
                font-weight: bold;
              }
            }
            .btn-default {
              background-color: white;
              margin-top: 10px;
            }

            &.quiz {
              margin-bottom: 0;
              padding-top: 20px;
              border-top: 1px solid #d2d6de;
            }
          }
        }
      }

      .top-box {
        margin-bottom: 20px;
        p {
          font-size: 18px;
          margin: 7px auto;
        }
        .btn {
          font-size: 20px;
        }
      }
    }
  }
  .btn-cog {
    width: auto;
    float: right;
  }
}
</style>