import { render, staticRenderFns } from "./YearList.vue?vue&type=template&id=63cff1ae"
import script from "./YearList.vue?vue&type=script&lang=js"
export * from "./YearList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\web\\eipweb\\webpack\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63cff1ae')) {
      api.createRecord('63cff1ae', component.options)
    } else {
      api.reload('63cff1ae', component.options)
    }
    module.hot.accept("./YearList.vue?vue&type=template&id=63cff1ae", function () {
      api.rerender('63cff1ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/OKR/YearList.vue"
export default component.exports