<template>
  <div id="BigScoreBalance">
    <section class="content-header">
      <h1 v-if="person.level !== 5">總分平衡分數 ({{ person.text }})</h1>
      <h1 v-else>總分實習生查詢</h1>

      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active" v-if="person.level !== 5">總分平衡分數 ({{ person.text }})</li>
        <li class="active" v-else>總分實習生查詢</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="btns">
              <button
                class="btn btn-primary"
                type="button"
                @click="exportExcel()"
              >
                <i class="fa fa-download"></i> 最終分數匯出
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢分數：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
                <select
                  class="form-control"
                  v-if="permission.isHR && person.level !== 5"
                  v-model="searchData.centerId"
                  @change="getDivisionList(true)"
                >
                  <option value="">中心別</option>
                  <option
                    v-for="(item, index) in centerList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.divisionId"
                  @change="resetDataList()"
                  v-if="person.level !== 5"
                >
                  <option value="" v-if="permission.isHR">處別</option>
                  <option
                    v-for="(item, index) in divisionList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw80">年份</th>
                  <th class="tc mw50">年度</th>
                  <th class="tc mw100">部別</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th
                    class="tc mw80 pre-wrap"
                    v-text="`工作績效\n考核分數`"
                  ></th>
                  <th
                    class="tc mw80 pre-wrap"
                    v-text="`工作績效\n考核計算`"
                  ></th>
                  <th class="tc mw80" v-if="person.level === 2">堆疊分數</th>
                  <th class="tc mw80" v-if="person.level === 2">堆疊計算</th>
                  <th
                    class="tc mw80 pre-wrap"
                    v-text="`核心行為價值\n考核分數`"
                  ></th>
                  <th
                    class="tc mw80 pre-wrap"
                    v-text="`核心行為價值\n考核計算`"
                  ></th>
                  <th class="tc mw80">個人總分</th>
                  <th class="tc mw80" v-if="person.level === 2">
                    中心調整總分
                  </th>
                  <th class="tc mw80" v-else-if="person.level !== 5">處級調整總分</th>
                  <th class="tc mw300" v-if="person.level === 2">
                    中心調整加減分
                  </th>
                  <th class="tc mw300" v-else-if="person.level !== 5">處級調整加減分</th>
                  <th class="tc mw100" v-if="person.level !== 5">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="15">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="15">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td
                    class="tc"
                    v-text="define.halfYearStatus[data.month]"
                  ></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td
                    class="tc"
                    v-if="person.level === 2"
                    v-text="data.objectiveScoreOrg || 0"
                  ></td>
                  <td
                    class="tc"
                    v-if="person.level === 2"
                    v-text="data.objectiveScore || 0"
                  ></td>
                  <td class="tc" v-text="data.monthScoreOrg || 0"></td>
                  <td class="tc" v-text="data.monthScore || 0"></td>
                  <td class="tc" v-text="data.heartScoreOrg || 0"></td>
                  <td class="tc" v-text="data.heartScore || 0"></td>
                  <td class="tc" v-text="data.totalScore || 0"></td>
                  <td class="tc" v-text="getTotalScore(data)" v-if="person.level !== 5"></td>
                  <td class="tc" v-if="person.level !== 5">
                    <div class="flex">
                      <input
                        type="number"
                        class="form-control w90"
                        placeholder="加減分"
                        :disabled="!permission.hasHigh || isClose == 1"
                        v-model.number="data.oneBalanceAdd"
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="說明"
                        :disabled="!permission.hasHigh || isClose == 1"
                        v-model="data.oneBalanceNote"
                      />
                    </div>
                  </td>
                  <td class="tc" v-if="permission.hasHigh && person.level !== 5">
                    <button class="btn btn-primary" @click="updateData(data)">
                      儲存
                    </button>
                  </td>
                  <td class="tc" v-else-if="person.level !== 5">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        centerId: "",
        divisionId: "",
      },
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      centerList: [],
      divisionList: [],
      datas: [],
      isClose: 0,
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(
      this.userData,
      this.person.level === 2 ? 40 : 50
    );
    this.updateMonthSearch();
    this.getCenterList();
  },
  mounted(){
    setTimeout(() => {
      this.getCloseList();
    }, 500);
  },
  methods: {
    getCenterList() {
      api.organization
        .getDeptList({
          companyId: this.$store.state.loginUser.companyId,
          deptType: "0040",
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.centerList = res.data.result || [];
            this.updateCenterListSearch();
            this.getDivisionList();
          }
        });
    },
    getDivisionList(reset) {
      if (this.permission.isHR) {
        api.okr.goal.department.list({ deptLevel: 1 }).then((res) => {
          if (res.data.status === "1") {
            this.divisionList = res.data.result || [];
            this.updateDivisionListSearch(reset);
            if (reset) this.resetDataList();
            else this.getDataList();
          }
        });
      } else {
        this.divisionList = this.permission.deptList || [];
        this.updateDivisionListSearch(reset);
        if (reset) this.resetDataList();
        else this.getDataList();
      }
    },
    getDataList() {
      this.searchData.deptLevel = this.person.level;
      api.okr.score.max.balance.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
      this.getCloseList();
    },
    getCloseList() {
      let searchData = {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: this.searchData.month,
        closeType: "",
      }
      api.okr.mgmt.balance.queryAll(searchData).then((res) => {
        if (res.data.status === "1") {
          let deptId = this.permission.isHR ? this.searchData.centerId : this.searchData.divisionId
          res.data.result.datas.forEach(item => {
            if(item.deptId == deptId) {
              this.isClose = item.isClose
            }
          })
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateMonthSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.month = this.global.getHalfYear();
      }
    },
    updateCenterListSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.centerId = this.global.getDeptId(
          this.centerList,
          this.userData,
          this.permission
        );
      }
    },
    updateDivisionListSearch(reset) {
      if (reset) this.searchData.divisionId = "";
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.divisionId = this.global.getDeptId(
          this.divisionList,
          this.userData,
          this.permission
        );
        if (!this.searchData.divisionId && this.divisionList.length) {
          this.searchData.divisionId = this.divisionList[0].deptId || "";
        }
      }
    },
    getFloatValue(value) {
      return Number(value.toFixed(2));
    },
    getTotalScore(data) {
      data.totalScore = data.totalScore || 0;
      data.oneBalanceAdd = data.oneBalanceAdd || 0;
      data.oneBalanceScore = this.getFloatValue(
        data.totalScore + data.oneBalanceAdd
      );
      return data.oneBalanceScore;
    },
    updateData(data) {
      data.deptType = this.person.level === 2 ? "0040" : "0050";
      api.okr.score.max.balance.update(data).then((res) => {
        this.global.showResponse(this, res, "update");
      });
    },
    exportExcel() {
      const data = utils.deepCloneObj(this.searchData);
      data.pageNum = 0;
      data.pageSize = 0;
      api.okr.score.max.balance.queryAll(data).then((res) => {
        if (res.data.status === "1") {
          if (res.data.result.total <= 0) {
            this.$eventBus.$emit("showAlert", "查無資料");
          } else {
            const items = [];
            if (this.person.level === 2) {
              res.data.result.datas.forEach((item) => {
                items.push({
                  年份: item.year,
                  年度: this.define.halfYearStatus[item.month],
                  部別: item.deptName,
                  員工編號: item.employeeId,
                  姓名: item.employeeName,
                  工作績效考核分數: item.objectiveScoreOrg || 0,
                  工作績效考核計算: item.objectiveScore || 0,
                  堆疊分數: item.monthScoreOrg || 0,
                  堆疊計算: item.monthScore || 0,
                  核心行為價值考核分數: item.heartScoreOrg || 0,
                  核心行為價值考核計算: item.heartScore || 0,
                  個人總分: item.totalScore || 0,
                  中心調整加減分:
                    item.oneBalanceAdd > 0
                      ? `+${item.oneBalanceAdd}`
                      : item.oneBalanceAdd || "-",
                  中心調整總分: item.oneBalanceScore || "-",
                  最終分數: item.oneBalanceScore || item.totalScore || 0,
                });
              });
            } else {
              res.data.result.datas.forEach((item) => {
                items.push({
                  年份: item.year,
                  年度: this.define.halfYearStatus[item.month],
                  部別: item.deptName,
                  員工編號: item.employeeId,
                  姓名: item.employeeName,
                  工作績效考核分數: item.monthScoreOrg || 0,
                  工作績效考核計算: item.monthScore || 0,
                  核心行為價值考核分數: item.heartScoreOrg || 0,
                  核心行為價值考核計算: item.heartScore || 0,
                  個人總分: item.totalScore || 0,
                });
                if (this.person.level !== 5) {
                  items.push({
                    處級調整加減分:
                      item.oneBalanceAdd > 0
                        ? `+${item.oneBalanceAdd}`
                        : item.oneBalanceAdd || "-",
                    處級調整總分: item.oneBalanceScore || "-",
                    最終分數: item.oneBalanceScore || item.totalScore || 0,
                });
                }
              });
            }
            const title =
              this.searchData.month >= 0
                ? `${
                    this.searchData.year +
                    this.define.halfYearStatus[this.searchData.month]
                  }_總分計算.xlsx`
                : `${this.searchData.year}_總分計算.xlsx`;
            utils.exportData(items, title, "總分計算");
          }
        } else {
          this.$eventBus.$emit("showAlert", res.data.message);
        }
      });
    },
  },
};
</script>
