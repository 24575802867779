var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseQuizEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.quizTitle))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", { staticClass: "active" }, [_vm._v("課程管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning company-files-edit" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isEdit ? "編輯" : "新增") +
                  "測驗\n          "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "box-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.passingScore,
                        expression: "passingScore",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "", type: "number", min: "0" },
                    domProps: { value: _vm.passingScore },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.passingScore = $event.target.value
                      },
                    },
                  }),
                  _c("span", { staticClass: "text-gray" }, [
                    _vm._v("*測驗達及格分數才算完成"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "selectType" },
                  },
                  [_vm._v("\n                每題預設分數：\n              ")]
                ),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newScore,
                        expression: "newScore",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number", min: "0" },
                    domProps: { value: _vm.newScore },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newScore = $event.target.value
                      },
                    },
                  }),
                  _c("div", { staticClass: "mt-10" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning mr-10",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.updateScore("question")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    同步題目分數\n                  "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 1,
                          message: "啟用",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.testStatus,
                          callback: function ($$v) {
                            _vm.testStatus = $$v
                          },
                          expression: "testStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 0,
                          message: "停用",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.testStatus,
                          callback: function ($$v) {
                            _vm.testStatus = $$v
                          },
                          expression: "testStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c(
                    "div",
                    { staticClass: "table-responsive mt-10" },
                    [
                      _vm._l(_vm.list, function (question, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "option-border mb-10" },
                          [
                            _c(
                              "table",
                              { staticClass: "table tc" },
                              [
                                _c("tr", [
                                  _c("th", {
                                    staticStyle: {
                                      "min-width": "60px",
                                      width: "60px",
                                    },
                                  }),
                                  _c("th", {
                                    staticStyle: { "min-width": "150px" },
                                  }),
                                  _c("th", {
                                    staticStyle: { "min-width": "160px" },
                                    style: {
                                      width: "110px",
                                    },
                                  }),
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "question" }, [
                                    _vm._v("Q" + _vm._s(index + 1)),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: question.questionText,
                                          expression: "question.questionText",
                                        },
                                      ],
                                      staticClass: "form-control mt-10",
                                      attrs: {
                                        type: "text",
                                        placeholder: "請輸入題目",
                                        required: "",
                                      },
                                      domProps: {
                                        value: question.questionText,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            question,
                                            "questionText",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("td"),
                                ]),
                                _vm._l(
                                  question.options,
                                  function (option, oIndex) {
                                    return _c("tr", { key: oIndex }, [
                                      _c("td", { staticClass: "tc" }, [
                                        _c(
                                          "div",
                                          { staticClass: "radio-inline" },
                                          [
                                            _c("iCheckRedioButton", {
                                              attrs: {
                                                checkValue: oIndex + 1,
                                                name: `answer${index}`,
                                              },
                                              model: {
                                                value: question.correctAnswer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    question,
                                                    "correctAnswer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "question.correctAnswer",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: option.optionText,
                                              expression: "option.optionText",
                                            },
                                          ],
                                          staticClass: "form-control mt-10",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "選項" +
                                              String.fromCharCode(
                                                64 + (oIndex + 1)
                                              ),
                                            required: "",
                                          },
                                          domProps: {
                                            value: option.optionText,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                option,
                                                "optionText",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-left",
                                          staticStyle: { "min-width": "200px" },
                                        },
                                        [
                                          question.options.length > 1
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-warning mt-10 mr-10 ml10",
                                                  attrs: {
                                                    type: "button",
                                                    disabled: oIndex == 0,
                                                    title: "向上移動",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.sortOption(
                                                        question,
                                                        oIndex,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-arrow-up",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          question.options.length > 1
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-warning mt-10 mr-10",
                                                  attrs: {
                                                    type: "button",
                                                    disabled:
                                                      oIndex ==
                                                      question.options.length -
                                                        1,
                                                    title: "向下移動",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.sortOption(
                                                        question,
                                                        oIndex,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-arrow-down",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          question.options.length > 2
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-danger mt-10",
                                                  attrs: {
                                                    type: "button",
                                                    title: "刪除",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteOptions(
                                                        question,
                                                        oIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-trash",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "table",
                              { staticClass: "table option-bottom" },
                              [
                                _vm._m(3, true),
                                _c("tr", [
                                  _c("td", { staticClass: "tc" }, [
                                    _vm._v("分數"),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: question.questionScore,
                                          expression: "question.questionScore",
                                        },
                                      ],
                                      staticClass: "form-control mt-10",
                                      attrs: {
                                        required: "",
                                        type: "number",
                                        min: "0",
                                      },
                                      domProps: {
                                        value: question.questionScore,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              question,
                                              "questionScore",
                                              $event.target.value
                                            )
                                          },
                                          _vm.updateScore,
                                        ],
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-warning mr-10 ml10",
                                        attrs: {
                                          type: "button",
                                          title: "新增選項",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addOption(question)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-plus" }),
                                        _vm._v(
                                          "\n                            新增選項\n                          "
                                        ),
                                      ]
                                    ),
                                    _vm.list.length > 1
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary mr-10",
                                            attrs: {
                                              type: "button",
                                              disabled: index == 0,
                                              title: "向上移動",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sortQuestion(
                                                  index,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-arrow-up",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.list.length > 1
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary mr-10",
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                index == _vm.list.length - 1,
                                              title: "向下移動",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sortQuestion(
                                                  index,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-arrow-down",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.list.length > 2
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            attrs: {
                                              type: "button",
                                              title: "刪除",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteQuestion(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mt-10",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addQuestion()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(
                            "\n                    新增題目\n                  "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "total-score" }, [
                _c("div", { staticClass: "tc" }, [
                  _vm._v("\n                總分\n                "),
                  _c("p", [_vm._v(_vm._s(_vm.totalScore))]),
                ]),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning mr-10",
                    attrs: { type: "button" },
                    on: { click: _vm.preview },
                  },
                  [_vm._v("\n                預覽\n              ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("\n                送出\n              ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 及格分數：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "datetimepicker" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 測驗狀態：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "datetimepicker" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 測驗內容：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { "min-width": "60px", width: "60px" } }),
      _c("th", { staticStyle: { "min-width": "150px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }