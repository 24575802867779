var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("文件類別設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("公司文件")]),
          _c("li", { staticClass: "active" }, [_vm._v("公司文件類別設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addType()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增類別\n          "),
                ]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm._l(_vm.announcementtypes, function (item, index) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.editor == 0,
                                  expression: "item.editor==0",
                                },
                              ],
                              staticClass: "m-0",
                              attrs: { id: "name" + item.id },
                            },
                            [_vm._v(_vm._s(item.typeName))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.typeName,
                                expression: "item.typeName",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.editor != 0,
                                expression: "item.editor!=0",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "required", type: "text" },
                            domProps: { value: item.typeName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "typeName", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.companyDocumentCount))]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.docStatus,
                                  expression: "item.docStatus",
                                },
                              ],
                              attrs: {
                                "false-value": 0,
                                id: "check" + item.tId,
                                "true-value": 1,
                                name: "check" + item.tId,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(item.docStatus)
                                  ? _vm._i(item.docStatus, null) > -1
                                  : _vm._q(item.docStatus, 1),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.docStatus,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "docStatus",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "docStatus",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "docStatus", $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.changeStatus(item)
                                  },
                                ],
                              },
                            }),
                            _c("label", { attrs: { for: "check" + item.tId } }),
                          ]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.isShowHome > 0 ? "是" : "否") +
                              "\n                "
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.editor == 0,
                                  expression: "item.editor==0",
                                },
                              ],
                              staticClass: "m-0",
                              attrs: { id: "order" + item.id },
                            },
                            [_vm._v(_vm._s(item.isShowHome))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.isShowHome,
                                expression: "item.isShowHome",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.editor != 0,
                                expression: "item.editor!=0",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "required", type: "text" },
                            domProps: { value: item.isShowHome },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  item,
                                  "isShowHome",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.editor == 0,
                                  expression: "item.editor==0",
                                },
                              ],
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.change(item)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.editor != 0,
                                  expression: "item.editor!=0",
                                },
                              ],
                              staticClass: "btn btn-success btn-save",
                              on: {
                                click: function ($event) {
                                  return _vm.saveType(item)
                                },
                              },
                            },
                            [_vm._v("儲存")]
                          ),
                          _vm._v("\n                   \n                  "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.editor == 0,
                                  expression: "item.editor==0",
                                },
                              ],
                              staticClass: "btn btn-danger",
                              attrs: {
                                "data-target": "#attention",
                                "data-toggle": "modal",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteType(item, index)
                                },
                              },
                            },
                            [_vm._v("刪除")]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.editor != 0,
                                  expression: "item.editor!=0",
                                },
                              ],
                              staticClass: "btn btn-default",
                              on: {
                                click: function ($event) {
                                  return _vm.cancel(item, index)
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("AlertBox"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "50%" } },
        [_vm._v("文件類別")]
      ),
      _c("th", { staticClass: "text-nowrap", attrs: { width: "10%" } }, [
        _vm._v("文件數"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("啟用狀態")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("首頁顯示")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("首頁排序")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "20%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }