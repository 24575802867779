<template>
  <div id="MeetingRoom">
    <section class="content-header">
      <h1>會議室</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>場地申請</li>
        <li class="active">會議室</li>
      </ol>
    </section>
    <section class="content meeting-room">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="openMeetingRoomEdit()"
              v-if="checkByUserPermission()"
            >
              <i class="fa fa-plus"></i> 新增會議室
            </button>
            <div class="input-group date">
              <div class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </div>
              <div class="pull-right">
                <DatePicker v-model="meetingDay" />
              </div>
              <!-- <div class="input-group-btn">
                <button class="btn btn-default" type="button" @click="GetMeetingRoomBooked()">
                  <i class="fa fa-search"></i>
                </button>
              </div>-->
            </div>
            <div class="text">
              <p class="data-num">目前查看：{{ meetingDay }}</p>
            </div>
          </div>
          <div style="margin-top: 10px;">
            EIP會議室預約系統已鎖定，改由Office 365 outlook 行事曆或TEAMS行事曆進行預約。<br>
            <span class="text-red">週期預約功能沒有開放，最多可以預約60天後的會議室超過無法預約。</span><br>
            O365預約會議室操作SOP ➡ 
            <a href="/uploads/Office365版會議室預約操作方式.pdf" target="_blank">鏈接</a> 
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th>會議室名稱</th>
                  <th class="text-center">09:00-10:00</th>
                  <th class="text-center">10:00-11:00</th>
                  <th class="text-center">11:00-12:00</th>
                  <th class="text-center">12:00-13:00</th>
                  <th class="text-center">13:00-14:00</th>
                  <th class="text-center">14:00-15:00</th>
                  <th class="text-center">15:00-16:00</th>
                  <th class="text-center">16:00-17:00</th>
                  <th class="text-center">17:00-18:00</th>
                  <th class="text-center">18:00-19:00</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 120px;"
                    v-if="checkByUserPermission()"
                  >
                    功能
                  </th>
                </tr>
                <tr :key="index" v-for="(room, index) in meetingRoomList.datas">
                  <td v-if="room.isStop == 1">
                    {{ room.roomName }}&nbsp;(
                    <span class="text-red">暫停使用</span
                    >)
                  </td>
                   <td v-else>
                    {{ room.roomName }}&nbsp;(
                    <span class="num">{{ room.seatCount }}</span
                    >人)
                  </td>
                  <td
                    :class="{
                      booking: checkUserBooked(
                        room,
                        item + 8 + '00',
                        item + 9 + '00'
                      ),
                      disabled: checkCanBooking(room, item + 8, item + 9),
                      stopping: room.isStop == 1
                    }"
                    v-html="
                      checkMeetingTimeBooked(
                        room.roomId,
                        item + 8 + '00',
                        item + 9 + '00'
                      )
                    "
                    :key="item"
                    v-for="item in period"
                  ></td>
                  <td class="text-center" v-if="checkByUserPermission()">
                    <button
                      class="btn btn-primary"
                      @click="openMeetingRoomEdit(room)"
                    >
                      修改
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger"
                      @click="deleteMeetingRoom(room)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <p class="text-red mt-10">
            <i class="fa fa-warning"></i> 提醒您今日14:00-15:00有預約28F-A1。
          </p>-->
          <p class="text-red mt-10">
            <i class="fa fa-warning"></i>
            點擊空白時段即可進行預約或取消，已過的時段無法預約。
          </p>
        </div>
      </div>
    </section>
    <roomBooking ref="roomBooking" v-on:refreshList="GetMeetingRoomBooked" />
    <meetingRoomEdit
      ref="meetingRoomEdit"
      v-on:refreshList="GetMeetingRoomBooked"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import moment from "moment";
import roomBooking from "@/views/PlaceReservation/RoomBooking";
import meetingRoomEdit from "@/views/PlaceReservation/MeetingRoomEdit";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DatePicker,
    roomBooking,
    meetingRoomEdit
  },
  data() {
    return {
      meetingRoomList: {},
      roomBookingList: {},
      meetingDay: "",
      employeeId: this.$store.state.loginUser.employeeId,
      period: 10 //幾個時段
    };
  },
  created() {
    // this.GetMeetingRoomBooked();
  },
  watch: {
    meetingDay() {
      if(this.meetingDay !="") this.GetMeetingRoomBooked();
    }
  },
  methods: {
    GetMeetingRoomList() {
      api.meetingRoom.queryAll({}).then(res => {
        if (res.data.status == "1") {
          this.meetingRoomList = res.data.result;
        }
      });
    },
    GetMeetingRoomBooked() {
      api.meetingBooking
        .queryBookingAll({
          meetingDay: this.meetingDay
        })
        .then(res => {
          if (res.data.status == "1") {
            this.roomBookingList = res.data.result;
            this.GetMeetingRoomList();
          }
        });
    },
    deleteMeetingRoom(room) {
      let that = this;
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", function(res) {
        if (res) {
          api.meetingRoom.delete(room).then(res => {
            if (res.data.status != "1") {
              that.$eventBus.$emit("showAlert", "Fail!");
            }
            that.GetMeetingRoomBooked();
          });
        }
      });
    },
    checkMeetingTimeBooked(roomId, startTime, endTime) {
      if (startTime.length == 3) startTime = "0" + startTime;

      if (this.roomBookingList.total == 0) return "";

      let query = this.roomBookingList.datas.filter(booked => {
        return (
          booked.roomId == roomId &&
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(this.meetingDay)
        );
      });

      if (query.length > 0) {
        return (
          "預約人：" +
          query[0].createEmpName +
          " <br />主題：" +
          query[0].meetingTitle
        );
      }
      return "";
    },
    checkUserBooked(room, startTime, endTime) {
      if (room.isStop == 1) return false;

      if (startTime.length == 3) startTime = "0" + startTime;

      let query = this.roomBookingList.datas.filter(booked => {
        return (
          booked.roomId == room.roomId &&
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(this.meetingDay) &&
          booked.createEmpId == this.employeeId
        );
      });

      if (query.length > 0) {
        return true;
      }
      return false;
    },
    SeeBookingInfo(room, startTime, endTime) {
      if (room.isStop == 1) return false;

      if (startTime.length == 3) startTime = "0" + startTime;

      let query = this.roomBookingList.datas.filter(booked => {
        return (
          booked.roomId == room.roomId &&
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(this.meetingDay)
        );
      });

      if (query.length > 0) {
        return true;
      }
      return false;
    },
    openMeetingRoomEdit(room) {
      this.$refs.meetingRoomEdit.showMeetingRoomEdit(room);
    },
    openRoomBooking(room, startTime, endTime) {
      if (room.isStop == 1) return;

      if (startTime == 9) startTime = "0" + startTime;
      startTime = startTime + "00";
      endTime = endTime + "00";

      let booked = this.roomBookingList.datas.filter(booked => {
        return (
          booked.roomId == room.roomId &&
          ((booked.startTime >= startTime && booked.startTime < endTime) ||
            (booked.endTime > startTime && booked.endTime <= endTime) ||
            (booked.startTime <= startTime && booked.endTime >= endTime)) &&
          new moment(booked.meetingDay).isSame(this.meetingDay)
        );
      });

      if (
        new moment().isAfter(
          this.meetingDay + " " + endTime.substring(0, 2) + ":00"
        ) ||
        (booked.length > 0 && !this.SeeBookingInfo(room, startTime, endTime))
      ) {
        return;
      }

      if (booked.length != 0) {
        startTime = booked[0].startTime;
        endTime = booked[0].endTime;
      }

      this.$refs.roomBooking.showMeetingRoomBooking(
        booked,
        room,
        startTime,
        endTime,
        this.meetingDay
      );
    },
    checkByUserPermission() {
      return utils.checkByUserPermission("B0-900");
    },
    checkCanBooking(room, startTime, endTime) {
      return true
      // if (room.isStop == 1) return true;

      // let booked = this.roomBookingList.datas.filter(booked => {
      //   return (
      //     booked.roomId == room.roomId &&
      //     ((booked.startTime >= startTime && booked.startTime < endTime) ||
      //       (booked.endTime > startTime && booked.endTime <= endTime) ||
      //       (booked.startTime <= startTime && booked.endTime >= endTime)) &&
      //     new moment(booked.meetingDay).isSame(this.meetingDay)
      //   );
      // });

      // return (
      //   new moment().isAfter(this.meetingDay + " " + endTime + ":00") ||
      //   (booked.length > 0 &&
      //     !this.SeeBookingInfo(room, startTime + "00", endTime + "00"))
      // );
    }
  }
};
</script>
