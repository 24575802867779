<template>
  <div id="Extension">
    <section class="content-header">
      <h1>分機表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司通訊錄</li>
        <li class="active">分機表</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning" v-if="isShow == 1">
        <div class="box-body">
          <div class="top-box has-select box-header with-border">
            <button v-if="hasEditPermission" @click="goEdit()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-cog"></i> 分機表管理
            </button>
            <div class="form-group select-group">
              <label class="control-label" for="selectUnit">單位：</label>
              <select
                @change="changeUnit($event.target.selectedIndex)"
                class="form-control"
                id="selectUnit"
                v-model="key"
                v-if="extension.length > 0"
              >
                <option
                  :key="index"
                  :value="item.typeId"
                  v-for="(item, index) in extension"
                >{{ item.typeName }}</option>
              </select>
              <select class="form-control" id="selectUnit" v-else>
                <option value="">請選擇</option>
              </select>
            </div>
            <template v-if="extension.length > 0">
              <label class="btn btn-primary" for="inputFile" v-if="hasEditPermission">
                <input
                  @change="changeFile($event)"
                  accept=".jpg, .jpeg, .png"
                  id="inputFile"
                  onclick="this.value=null;"
                  ref="seatPic"
                  style="display:none;"
                  type="file"
                />
                <i class="fa fa-picture-o"></i> 更新圖片
              </label>
              &nbsp;
              <button
                v-if="hasEditPermission"
                @click="deleteFile()"
                class="btn btn-danger"
                type="button"
              >刪除圖片</button>
            </template>
          </div>
          <div class="pic text-center" v-if="extension.length > 0">
            <img :src="fileUrl" draggable="false" />
          </div>
          <p style="margin-top: 10px;" v-else>尚未新增任何單位。</p>
        </div>
      </div>
      <div class="box box-warning max850" v-else>
        <div class="box-body">
          <div class="top-box">
            <h4 class="pull-left">分機表管理</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回
            </button>
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增分機表
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered" id="extension">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;" width="30%">名稱</th>
                  <th class="text-center">排序</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <tr :key="index" v-for="(item, index) in extension">
                  <td>
                    <p
                      :id="'name'+item.typeId"
                      class="m-0"
                      v-show="item.editor==0"
                    >{{ item.typeName }}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.typeName"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td class="text-center">
                    <p
                      :id="'name'+item.typeId"
                      class="m-0"
                      v-show="item.editor==0"
                    >{{ item.orderNum }}</p>
                    <input
                      class="form-control text-center"
                      required="required"
                      type="number"
                      v-model="item.orderNum"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'check'+item.typeId"
                        :true-value="1"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.status"
                      />
                      <label :for="'check'+item.typeId"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button @click="change(item)" class="btn btn-primary" v-show="item.editor==0">修改</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor!=0"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-show="item.editor==0"
                    >刪除</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor!=0"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <!--座位表 預覽圖片
    <div class="modal fade" id="preview">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title" id="preview-title">預覽圖片</h4>
          </div>
          <div class="modal-body text-center">
            <img draggable="false" id="preview-img" src="../assets/seat32b1.jpg" />
          </div>
          <div class="modal-footer">
            <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import api from '../assets/js/app-api';
import config from '@/assets/js/app-config';

export default {
  data() {
    return {
      isShow: 1,
      extension: [],
      extensionTemp: [],
      index: 0,
      key: '',
      fileUrl: '',
      hasEditPermission: false
    };
  },
  mounted() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.ExtensionSetting
    );
    this.workShowAll();
  },
  methods: {
    //*---- 顯示列表
    async workShowAll() {
      var task1;
      var isFilterNonFile = this.hasEditPermission == true ? 0 : 1;
      task1 = api.extType.queryAll({
        isFilterNonFile: isFilterNonFile
      });
      var response = await task1;
      var tmp = response.data.result.datas;

      this.fileUrl = '';
      this.extension = [];
      this.extensionTemp = [];
      if (tmp.length > 0) {
        for (var i = 0; i < tmp.length; i++) {
          tmp[i]['editor'] = 0;
        }
        this.extensionTemp = JSON.parse(JSON.stringify(tmp));
        this.extension = tmp;
        this.key = this.extension[this.index].typeId;
        if (this.extension[this.index].files.length > 0) {
          this.fileUrl = process.env.VUE_APP_EIPIMG_PATH + this.extension[this.index].files[0].filesUrl;
        }
      }
      // console.log(tmp);
    },

    //*---- 更換單位
    async changeUnit(index) {
      // console.log(index);
      this.index = index;
      var that = this;
      if (this.extension[index].files.length > 0) {
        that.fileUrl = process.env.VUE_APP_EIPIMG_PATH + that.extension[index].files[0].filesUrl;
      } else {
        that.fileUrl = '';
      }
    },

    //*---- 上傳檔案
    changeFile(e) {
      var files = e.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(PNG|JPG|JPEG|GIF)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            'showAlert',
            '您選擇的文件格式不符，請重新選擇。',
            '注意'
          );
          $('#attention-delete').hide();
          this.removeFile();
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            'showAlert',
            '圖檔超過檔案上限 5 MB，請重新選擇。',
            '注意'
          );
          this.removeFile();
          return;
        }
      }

      this.preview(files); //圖片預覽
      this.uploadFile(files[0], 'OfficeExtType', this.key); //檔案上傳
    },
    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          that.extension.files = [];
          that.fileUrl = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },
    //檔案上傳
    async uploadFile(files, folderName, typeId, multi = false) {
      let formdata = new FormData();
      formdata.append('files', files);
      formdata.append('module', folderName);
      formdata.append('moduleId', typeId);
      formdata.append('multi', multi);

      var task_upload = api.file.upload(formdata);
      await task_upload;
      // this.extension.id = response_upload.data.result.id;
      this.workShowAll();
    },
    // 取消上傳
    removeFile() {
      document.getElementById('inputFile').value = '';
    },

    //*---- 刪除檔案
    async deleteFile() {
      var that = this;
      this.$eventBus.$emit(
        'showAlert',
        '刪除後將無法復原，請確認是否刪除？',
        '注意'
      );
      $('#attention-delete').show();
      $('#attention-delete').click(async () => {
        var id = that.extension[this.index].files[0].applyId;
        var response_delete = await api.file.deleteApply({ id: id });
        if (response_delete.data.status == '1') {
          $('#attention').modal('hide');
          this.workShowAll();
        }
      });
    },

    // 分機表管理
    goEdit() {
      this.isShow = 0;
    },
    // 返回
    backList() {
      this.isShow = 1;
    },

    //*---- 儲存更新
    workUpdate(item) {
      api.extType
        .update(item)
        .then(res => {
          // console.log('workUpdate', res);
          if (res.data.status == '1') {
            item.editor = 0;
          } else {
            this.$eventBus.$emit('showAlert', res.data.message, '注意');
          }
        })
        .catch(err => {
          // console.log('workUpdate', err);
          this.$eventBus.$emit('showAlert', err.data.message, '注意');
        });
    },

    //*---- 切換文字或輸入框
    change(item) {
      item.editor = item.editor == 1 ? 0 : 1;
    },

    //*---- 儲存
    save(item) {
      if(!this.checkField(item)) return;
      var that = this;
      if (item.typeId == '') {
        api.extType
          .create(item)
          .then(res => {
            if (item.typeName == '') {
              this.$eventBus.$emit('showAlert', res.data.message, '注意');
            } else {
              item.editor = item.editor == 1 ? 0 : 1;
              that.workShowAll();
            }
          })
          .catch(err => {
            // console.log('save', err);
            this.$eventBus.$emit('showAlert', err.data.message, '注意');
          });
      } else {
        //item.editor = 0
        this.workUpdate(item);
      }
    },

    //*---- 新增分機表
    addNew() {
      this.extension.push({
        typeId: '',
        typeName: '',
        status: '1',
        editor: '1'
      });
    },
    cancel(item, index) {
      var that = this;
      if (item.typeId == 0) {
        // console.log('splice');
        that.extension.splice(index, 1);
      } else {
        var temp = JSON.parse(JSON.stringify(that.extensionTemp[index]));
        that.extension.splice(index, 1, temp);
        // console.log('cancel');
      }
    },

    //*---- 改變狀態
    changeStatus(item) {
      this.workUpdate(item);
    },

    //*---- 整列刪除
    deleteType(item) {
      var that = this;
      this.$eventBus.$emit('showConfirm', '刪除後將無法復原，請確認是否刪除？');
      $('#attention-delete').click(function() {
        api.extType
          .delete(item)
          .then(res => {
            $('#attention').modal('hide');
            that.workShowAll();
            // console.log(item.typeName, 'Delete success!');
          })
          .catch(err => {
            $('#attention-delete').hide();
            that.$eventBus.$emit('showAlert', err.data.message);
            // console.log(item.typeName, 'Delete error!');
          });
      });
    },

    //檢查欄位
    checkField(item){

      if(item.typeName == ""){
        this.$eventBus.$emit('showAlert', "名稱不可為空!!");
        return false;
      }

      return true;

    }
  }
};
</script>
