<template>
  <div id="OrderManagement">
    <section class="content-header">
      <h1>訂單管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">訂單管理</li>
      </ol>
    </section>
    <section class="content order-management">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="control-label">用餐日期：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right">
                    <DatePicker v-model="eatDay" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label" for="selectOffice">辦公室：</label>
                <select
                  class="form-control right"
                  id="selectOffice"
                  v-model="office"
                >
                  <option value>請選擇</option>
                  <option
                    :key="index"
                    :value="office.office"
                    v-for="(office, index) in officeList"
                  >
                    {{ office.office }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label" for="inputOrderer">訂購人：</label>
                <input
                  class="form-control right"
                  id="inputOrderer"
                  type="text"
                  v-model="employeeName"
                />
              </div>
              <div class="form-group">
                <label class="control-label">用餐類別：</label>
                <div class="right">
                  <div class="radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="午餐"
                      name="radioMode"
                      v-model="eatTime"
                    />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      checkValue="2"
                      message="晚餐"
                      name="radioMode"
                      v-model="eatTime"
                    />
                  </div>
                </div>
              </div>
              <div class="btns">
                <button
                  @click="onSearch()"
                  class="btn btn-primary"
                  type="button"
                >
                  查詢
                </button>
                <button
                  @click="exportExcel()"
                  class="btn btn-default"
                  type="button"
                >
                  匯出
                </button>
              </div>
            </form>
            <form class="form-horizontal batchCancelOrder">
              <div class="form-group">
                <label class="control-label">批次取消訂餐：</label>
                <div class="right">
                  <input
                    id="batchCancelOrder"
                    ref="batchCancelOrder"
                    type="file"
                  />
                </div>
              </div>
              <button
                @click="readExcel()"
                class="btn btn-danger"
                type="button"
                style="margin-right: 10px"
              >
                確定批次取消
              </button>
              <button
                @click="leavesCancelUpdate()"
                class="btn bg-purple"
                type="button"
                v-if="showLeavesUpdateBtn || expatId == 'G'"
              >
                {{ expatId == "G" ? "打卡" : "請假" }}資料轉入
              </button>
            </form>
            <div class="text">
              <p class="data-num">資料總筆數：{{ total }}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 100px">用餐日期</th>
                  <th class="text-nowrap">星期</th>
                  <th class="text-nowrap">店家名稱</th>
                  <th class="text-nowrap">菜單名稱</th>
                  <th class="text-nowrap">類別</th>
                  <th class="text-nowrap">售價</th>
                  <th class="text-nowrap">訂購人</th>
                  <th style="min-width: 80px">辦公室</th>
                  <th class="text-nowrap">訂餐狀態</th>
                  <th class="text-nowrap">打卡狀態</th>
                  <th class="text-nowrap">請假說明</th>
                  <th class="text-center" style="min-width: 137px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="12" class="text-center">查詢中</td>
                </tr>
                <tr
                  :class="{ 'text-red': eat.leaveIsDiff == 1 }"
                  :key="index"
                  v-for="(eat, index) in queryAll.datas"
                  v-else
                >
                  <td>{{ $utils.formatTime(eat.eatDay, "YYYY/MM/DD") }}</td>
                  <td>{{ $utils.getWeekDay(eat.eatDay) }}</td>
                  <td>{{ eat.storeName }}</td>
                  <td>{{ eat.mealName }}</td>
                  <td style="word-break: keep-all">
                    {{ eat.eatTime == 1 ? "午餐" : "晚餐" }}
                  </td>
                  <td>{{ eat.price }}</td>
                  <td>{{ eat.employeeName }}</td>
                  <td>{{ eat.office }}</td>
                  <td class="text-nowrap">
                    {{ getOrderStatus(eat) }}
                  </td>
                  <td :class="{ 'text-blue': eat.isNoPunch == 1 }">
                    {{ eat.punchNote }}
                  </td>
                  <td>
                    {{ eat.leaveNote }}
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      @click="changeManageStatus(eat, 'cancel')"
                      class="btn btn-danger"
                      v-if="eat.manageStatus != '0'"
                    >
                      取消
                    </button>
                    <button
                      type="button"
                      @click="changeManageStatus(eat, 'recover')"
                      class="btn btn-success"
                      v-else
                    >
                      恢復
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      style="margin-left: 10px"
                      @click="changeManageStatus(eat, 'keep')"
                    >
                      保留
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center">
          <Pagination
            :pageLimitCount="pageSize"
            :total="total"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import xlsx from "xlsx";

export default {
  components: {
    Pagination,
    DatePicker,
    iCheckRedioButton,
  },
  data() {
    return {
      expatId: this.$user.getUserExpatId(),
      queryAll: {},
      officeList: {},
      office: "",
      eatDay: "",
      eatTime: "1",
      employeeName: "",

      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數
      page: 1,
      loading: true,
      showLeavesUpdateBtn: false,
    };
  },
  created() {
    this.getOfficeList();
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });
    this.getAllEatList();
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.page = pageIdx;
      this.pageSize = pageSize;
      this.getAllEatList();
    },
    onSearch() {
      this.page = 1;
      this.getAllEatList();
    },
    getAllEatList() {
      this.loading = true;
      api.mealManage
        .queryEatAll({
          office: this.office,
          eatDay: this.eatDay,
          eatTime: this.eatTime,
          employeeName: this.employeeName,
          pageNum: this.page,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.queryAll = res.data.result;
            this.total = this.queryAll.total;
          }
          if (res.data.stateCode != "LeavesOff") {
            this.showLeavesUpdateBtn = true;
          }
          this.loading = false;
          this.$forceUpdate();
        });
    },
    getOfficeList() {
      api.office
        .mealqueryAll({
          status: "1",
          IsMealCount: "1",
        })
        .then((res) => {
          if (res.data.status == "1") {
            let officeList = res.data.result.datas;
            this.officeList = officeList.filter(
              (item) => item.isMealCount != 0
            );
          }
        });
    },
    changeManageStatus(data, type) {
      let msg = "";
      let confirmMsg = "";
      let status = 0;
      if (type == "cancel") {
        msg = "取消";
        confirmMsg = "確定要取消嗎？";
      }
      if (type == "recover") {
        msg = "恢復";
        status = 1;
      }
      if (type == "keep") {
        msg = "保留";
        status = 2;
      }

      if (confirmMsg) {
        this.$eventBus.$emit("showConfirm", confirmMsg, (isOK) => {
          if (isOK) {
            this.changeManageStatusApi(data, status, msg);
          }
        });
      } else {
        this.changeManageStatusApi(data, status, msg);
      }
    },
    changeManageStatusApi(data, status, msg) {
      api.mealManage
        .manageStatus({
          EatId: data.eatId,
          EatDay: data.eatDay,
          EmployeeId: data.employeeId,
          ManageStatus: status, // 0：取消訂餐，1：恢復訂餐，2：保留訂餐
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.$eventBus.$emit("showAlert", msg + "成功！");
          } else {
            this.$eventBus.$emit("showAlert", msg + "失敗！");
          }
          this.getAllEatList();
        });
    },

    getOrderStatus(eat) {
      let manual = eat.manageUpdate == 1 ? "(手動)" : "";
      let keep = eat.manageUpdate == 2 ? "(保留)" : "";
      let txt = "";
      if (eat.manageStatus == "0") {
        txt = "取消";
      } else {
        switch (eat.eatStatus) {
          case 0:
            txt = "不訂餐";
            break;
          case 1:
            txt = "正常";
            break;
          default:
            txt = "未訂餐";
        }
      }
      return txt + manual + keep;
    },
    getMealType(state) {
      let typeName = "";
      switch (state) {
        case 0:
          typeName = "素食";
          break;
        case 1:
          typeName = "葷食";
          break;
      }

      return typeName;
    },
    async exportExcel() {
      let task_QueryAll = api.mealManage.queryEatAll({
        office: this.office,
        eatDay: this.eatDay,
        eatTime: this.eatTime,
        employeeName: this.employeeName,
        pageNum: 0,
        pageSize: 0,
      });
      let response = await task_QueryAll;
      if (response.data.status != "1" || response.data.result.total == 0) {
        this.$eventBus.$emit("showAlert", "查詢資料失敗，請重新操作!");
        return;
      }

      let data = [];
      let index = 0;
      response.data.result.datas.forEach((res) => {
        data.push({
          編號: ++index,
          辦公室: res.office,
          菜單名稱: res.mealName,
          店家名稱: res.storeName,
          訂購人: res.employeeName,
          狀態: this.getOrderStatus(res),
          售價: res.price,
          打卡: res.punchNote,
          請假說明: res.leaveNote,
        });
      });

      utils.exportData(
        data,
        utils.formatTime(this.eatDay, "YYYYMMDD") + "_" + this.office + ".xlsx"
      );
    },
    readExcel() {
      //excel匯入
      var that = this;
      const files = that.$refs.batchCancelOrder.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
          let outputs = []; //清空
          for (var i = 0; i < ws.length; i++) {
            outputs.push(ws[i].工號);
          }

          api.mealManage
            .batchCancel({
              eatDay: this.eatDay,
              eatTime: 1,
              cancelList: outputs,
            })
            .then((res) => {
              if (res.data.status == "1") {
                that.$eventBus.$emit("showAlert", "批次取消成功");
              } else if (res.data.message) {
                that.$eventBus.$emit("showAlert", res.data.message);
              }
              that.getAllEatList();
            });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },

    async leavesCancelUpdate() {
      this.showLoading();
      let task_QueryAll = api.mealManage.leavesCancel({ eatDay: this.eatDay });
      let response = await task_QueryAll;
      let message = response.data.message;
      if (response) this.hideLoading();
      if (response.data.status == "1") {
        this.$eventBus.$emit(
          "showAlert",
          this.expatId == "G" ? "打卡" : "請假" + "資料轉入成功"
        );
        this.onSearch();
      } else {
        this.$eventBus.$emit(
          "showAlert",
          message ? message : "查詢資料失敗，請重新操作!"
        );
      }
    },
  },
};
</script>
