<template>
  <div id="OrderManagement">
    <section class="content-header">
      <h1>每日訂餐設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">每日訂餐設定</li>
      </ol>
    </section>
    <section class="content daily-setting">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="onAddClick" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
            <form class="form-horizontal">
              <div class="form-group d-block">
                <label class="control-label" for="selectType">用餐類別：</label>
                <div class="right">
                  <div class="radio-inline">
                    <label>
                      <input
                        class="flat"
                        name="orderType"
                        type="radio"
                        v-model="searchDatas.eatTime"
                        value="1"
                      /> 午餐
                    </label>
                  </div>
                  <div class="radio-inline">
                    <label>
                      <input
                        class="flat"
                        name="orderType"
                        type="radio"
                        v-model="searchDatas.eatTime"
                        value="2"
                      /> 晚餐
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label">用餐日期：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right">
                    <DateRangePicker
                      v-bind:propEndDate.sync="searchDatas.eatEndDay"
                      v-bind:propStartDate.sync="searchDatas.eatStartDay"
                    />
                  </div>
                </div>
              </div>
              <button @click="loadPageData" class="btn btn-default btn-search" type="button">查詢</button>
            </form>
            <p class="data-num" v-text="'資料總筆數：'+total"></p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 100px;">用餐日期</th>
                  <th class="text-nowrap">星期</th>
                  <th>店家名稱</th>
                  <th class="text-center">功能</th>
                </tr>
                <tr :key="index" v-for="(data, index) in datas">
                  <td v-text="$utils.formatTime(data.eatDay)"></td>
                  <td v-text="$utils.getWeekDay(data.eatDay)"></td>
                  <td>
                    <ul class="store-name list-unstyled">
                      <li :key="index" v-for="(store, index) in data.stores" v-text="store.storeName"></li>
                    </ul>
                  </td>
                  <td class="text-center">
                    <button
                      @click="onEditClick(data.eatDay, data.eatTime)"
                      class="btn btn-primary"
                    >修改</button>&nbsp;
                    <button
                      @click="onDeleteClick(data.eatDay, data.eatTime)"
                      class="btn btn-danger"
                    >刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center">
          <Pagination
            :pageLimitCount="searchDatas.pageSize"
            :total="total"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <DailyMealSettingEdit ref="editDialog" v-on:refresh="loadPageData()" />
  </div>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker';
import DailyMealSettingEdit from '@/views/OrderSystem/DailyMealSettingEdit';
import Pagination from '@/components/Pagination';
import moment from 'moment';
import api from '@/assets/js/app-api';

export default {
  components: {
    Pagination,
    DateRangePicker,
    DailyMealSettingEdit
  },
  data() {
    return {
      total: 0,
      datas: [],
      searchDatas: {
        pageNum: 1,
        pageSize: 30,
        eatStartDay: new moment().isoWeekday(1).format('YYYY/MM/DD'),
        eatEndDay: new moment()
          .isoWeekday(1)
          .add('12', 'd')
          .format('YYYY/MM/DD'),
        eatTime: '1'
      }
    };
  },
  mounted() {
    this.onPageLoad();
  },
  methods: {
    async onPageLoad() {
      this.loadPageData();
    },
    async loadPageData() {
      var res = await api.mealDailySetting.queryAll(this.searchDatas);
      this.datas = res.data.result.datas;
      this.total = res.data.result.total;
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchDatas.pageNum = pageIdx;
      this.loadPageData();
    },
    onEditClick(orderDate, eatTime) {
      this.$refs.editDialog.show(orderDate, eatTime);
    },
    onAddClick() {
      this.$refs.editDialog.show(
        new moment().format('YYYY/MM/DD'),
        this.searchDatas.eatTime
      );
    },
    onDeleteClick(eatDay, eatTime) {
      this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', isOK => {
        if (isOK) {
          api.mealDailySetting
              .delete({
                eatDay: eatDay,
                eatTime: eatTime
              })
              .then(res => {
                if (res.data.status == '1') {
                  this.$eventBus.$emit('showAlert', '刪除成功!');
                } else {
                  this.$eventBus.$emit('showAlert', '刪除失敗!');
                }
                this.loadPageData();
              });
        }
      });
    }
  }
};
</script>
