var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Groups" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("群組設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("系統管理")]),
          _c("li", [_vm._v("權限管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("群組設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning max850" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "box-footer clearfix text-center" },
            [_c("Pagination")],
            1
          ),
        ]),
        _c("div", { staticClass: "box box-warning max850" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "box-footer clearfix text-center" },
            [_c("Pagination")],
            1
          ),
        ]),
      ]),
      _c("AlertBox"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-body" }, [
      _c("div", { staticClass: "top-box" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-block btn-warning btn-add",
            attrs: {
              type: "button",
              "data-target": "#add-group",
              "data-toggle": "modal",
            },
          },
          [
            _c("i", { staticClass: "fa fa-plus" }),
            _vm._v(" 新增群組\n          "),
          ]
        ),
      ]),
      _c("div", { staticClass: "table-responsive mt-10" }, [
        _c(
          "table",
          {
            staticClass:
              "table table-hover table-bordered table-striped min-600",
          },
          [
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("群組名稱")]),
                _c("th", [_vm._v("群組描述")]),
                _c(
                  "th",
                  {
                    staticClass: "text-nowrap text-center",
                    staticStyle: { "min-width": "260px" },
                  },
                  [_vm._v("功能")]
                ),
              ]),
              _c("tr", [
                _c("td", [_vm._v("一般使用者")]),
                _c("td", [_vm._v("一般有帳號的使用者")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("網銀員工")]),
                _c("td", [_vm._v("網銀一般員工")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("部級主管")]),
                _c("td", [_vm._v("擔任部級單位的主管")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("處級主管")]),
                _c("td", [_vm._v("擔任處級單位的主管")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("中心層級主管")]),
                _c("td", [_vm._v("擔任中心層級單位的主管")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("人資部")]),
                _c("td", [_vm._v("人資部管理使用")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("測試人員")]),
                _c("td", [_vm._v("企劃測試人員")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v("SystemAdmin")]),
                _c("td", [_vm._v("系統管理者，開發人員設定使用")]),
                _c("td", { staticClass: "text-center" }, [
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" 成員設定"),
                  ]),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        "data-target": "#add-group",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _vm._v("\n                   \n                  "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-body" }, [
      _c("div", { staticClass: "top-box with-border" }, [
        _c("h4", { staticClass: "h4 pull-left" }, [
          _vm._v("一般使用者 - 成員設定"),
        ]),
        _c(
          "button",
          { staticClass: "btn btn-default btn-add", attrs: { type: "button" } },
          [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表")]
        ),
      ]),
      _c("div", { staticClass: "nav-tabs-custom mt-10" }, [
        _c("ul", { staticClass: "nav nav-tabs" }, [
          _c("li", { staticClass: "active" }, [
            _c("a", { attrs: { href: "#tab_1", "data-toggle": "tab" } }, [
              _vm._v("公司"),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#tab_2", "data-toggle": "tab" } }, [
              _vm._v("角色"),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#tab_3", "data-toggle": "tab" } }, [
              _vm._v("單位"),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#tab_4", "data-toggle": "tab" } }, [
              _vm._v("單位層級主管"),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#tab_5", "data-toggle": "tab" } }, [
              _vm._v("單位人員"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            { staticClass: "tab-pane active", attrs: { id: "tab_1" } },
            [
              _c("form", { staticClass: "form-horizontal" }, [
                _c("div", { staticClass: "form-group member-setting" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "selectCompany" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 選擇公司：\n                    "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        staticClass: "form-control",
                        attrs: { id: "selectCompany", required: "required" },
                      },
                      [_c("option", [_vm._v("請選擇")])]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-down" }),
                      _vm._v(" 新增"),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "tab-pane", attrs: { id: "tab_2" } }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group member-setting" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "selectRole" },
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v(" 選擇角色：\n                    "),
                  ]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "selectRole", required: "required" },
                    },
                    [_c("option", [_vm._v("請選擇")])]
                  ),
                ]),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fa fa-arrow-down" }),
                    _vm._v(" 新增"),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-pane", attrs: { id: "tab_3" } }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group member-setting" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "selectUnit" },
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v(" 選擇單位：\n                    "),
                  ]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                    },
                    [_c("option", [_vm._v("請選擇")])]
                  ),
                ]),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fa fa-arrow-down" }),
                    _vm._v(" 新增"),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-pane", attrs: { id: "tab_4" } }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group member-setting" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "selectLevel" },
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v(" 選擇主管層級：\n                    "),
                  ]
                ),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "selectLevel", required: "required" },
                    },
                    [_c("option", [_vm._v("請選擇")])]
                  ),
                ]),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fa fa-arrow-down" }),
                    _vm._v(" 新增"),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-pane", attrs: { id: "tab_5" } }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group member-setting" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 control-label",
                    attrs: { for: "selectPeople" },
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v(" 單位人員：\n                    "),
                  ]
                ),
                _c("div", { staticClass: "col-sm-10 has-two" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "selectPeople", required: "required" },
                    },
                    [_c("option", [_vm._v("請選擇")])]
                  ),
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "selectPeople2", required: "required" },
                    },
                    [_c("option", [_vm._v("請選擇")])]
                  ),
                ]),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _c("i", { staticClass: "fa fa-arrow-down" }),
                    _vm._v(" 新增"),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "table-responsive mt-10" }, [
        _c(
          "table",
          {
            staticClass:
              "table table-hover table-bordered table-striped min-600",
          },
          [
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("成員類別")]),
                _c("th", [_vm._v("成員ID")]),
                _c("th", [_vm._v("成員名稱")]),
                _c(
                  "th",
                  {
                    staticClass: "text-nowrap text-center",
                    staticStyle: { "min-width": "90px" },
                  },
                  [_vm._v("功能")]
                ),
              ]),
              _c("tr", [
                _c("td", [_vm._v("一般使用者")]),
                _c("td", [_vm._v("starbaby")]),
                _c("td", [_vm._v("星寶")]),
                _c("td", { staticClass: "text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        "data-target": "#attention",
                        "data-toggle": "modal",
                      },
                    },
                    [_vm._v("刪除")]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }