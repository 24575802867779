var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "RoleSwitching" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("帳號切換設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("系統管理")]),
          _c("li", [_vm._v("帳號管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("帳號切換設定")]),
        ]),
      ]),
      _vm._m(0),
      _c("AlertBox"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content role-switching" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "table",
            {
              staticClass: "table table-bordered table-hover dataTable min-800",
              staticStyle: { width: "100%" },
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("員工編號")]),
                  _c("th", [_vm._v("帳號")]),
                  _c("th", [_vm._v("姓名")]),
                  _c("th", [_vm._v("部門")]),
                  _c("th", [_vm._v("職稱")]),
                  _c("th", [_vm._v("E-mail")]),
                  _c(
                    "th",
                    {
                      staticClass: "text-nowrap text-center",
                      staticStyle: { "min-width": "180px" },
                    },
                    [_vm._v("功能")]
                  ),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("104001")]),
                  _c("td", [_vm._v("aaa.aaa")]),
                  _c("td", [_vm._v("李XX")]),
                  _c("td", [_vm._v("研發二部(專2)")]),
                  _c("td", [_vm._v("工程師")]),
                  _c("td", [_vm._v("aaa.aaa@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104002")]),
                  _c("td", [_vm._v("bbb.bbb")]),
                  _c("td", [_vm._v("陳XX")]),
                  _c("td", [_vm._v("研發二部(專2)")]),
                  _c("td", [_vm._v("組長")]),
                  _c("td", [_vm._v("bbb.bbb@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104003")]),
                  _c("td", [_vm._v("ccc.ccc")]),
                  _c("td", [_vm._v("陳XX")]),
                  _c("td", [_vm._v("研發一部")]),
                  _c("td", [_vm._v("組長")]),
                  _c("td", [_vm._v("ccc.ccc@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104004")]),
                  _c("td", [_vm._v("ddd.ddd")]),
                  _c("td", [_vm._v("廖XX")]),
                  _c("td", [_vm._v("營運部")]),
                  _c("td", [_vm._v("專員")]),
                  _c("td", [_vm._v("ddd.ddd@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104005")]),
                  _c("td", [_vm._v("eee.eee")]),
                  _c("td", [_vm._v("紀XX")]),
                  _c("td", [_vm._v("營運設計部")]),
                  _c("td", [_vm._v("專員")]),
                  _c("td", [_vm._v("eee.eee@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104006")]),
                  _c("td", [_vm._v("fff.fff")]),
                  _c("td", [_vm._v("盧XX")]),
                  _c("td", [_vm._v("客服服務組")]),
                  _c("td", [_vm._v("專員")]),
                  _c("td", [_vm._v("fff.fff@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104007")]),
                  _c("td", [_vm._v("ggg.ggg")]),
                  _c("td", [_vm._v("張XX")]),
                  _c("td", [_vm._v("人力資源部")]),
                  _c("td", [_vm._v("高級專員")]),
                  _c("td", [_vm._v("ggg.ggg@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104008")]),
                  _c("td", [_vm._v("hhh.hhh")]),
                  _c("td", [_vm._v("王XX")]),
                  _c("td", [_vm._v("總務部")]),
                  _c("td", [_vm._v("專員")]),
                  _c("td", [_vm._v("hhh.hhh@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104009")]),
                  _c("td", [_vm._v("iii.iii")]),
                  _c("td", [_vm._v("莊XX")]),
                  _c("td", [_vm._v("行銷部")]),
                  _c("td", [_vm._v("專員")]),
                  _c("td", [_vm._v("iii.iii@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104010")]),
                  _c("td", [_vm._v("jjj.jjj")]),
                  _c("td", [_vm._v("蔡XX")]),
                  _c("td", [_vm._v("研發三部(專3)")]),
                  _c("td", [_vm._v("組長")]),
                  _c("td", [_vm._v("jjj.jjj@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104011")]),
                  _c("td", [_vm._v("kkk.kkk")]),
                  _c("td", [_vm._v("蘇XX")]),
                  _c("td", [_vm._v("遊戲維運組")]),
                  _c("td", [_vm._v("專員")]),
                  _c("td", [_vm._v("kkk.kkk@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("104012")]),
                  _c("td", [_vm._v("lll.lll")]),
                  _c("td", [_vm._v("張XX")]),
                  _c("td", [_vm._v("董事長室")]),
                  _c("td", [_vm._v("特別助理(2)")]),
                  _c("td", [_vm._v("lll.lll@wanin.tw")]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-target": "#role-setting",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("角色設定")]
                    ),
                    _vm._v("\n                 \n                "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          "data-target": "#role-attention",
                          "data-toggle": "modal",
                        },
                      },
                      [_vm._v("帳號切換")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }