<template>
  <div id="AccountRecord">
    <section class="content-header">
      <h1>帳號使用紀錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li>帳號管理</li>
        <li class="active">帳號使用紀錄</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group">
              <input class="form-control pull-right" name="table_search" placeholder="請輸入關鍵字" type="text" />
              <div class="input-group-btn">
                <button class="btn btn-default" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">搜尋結果：15</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered table-striped min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px">帳號</th>
                  <th style="min-width: 80px">姓名</th>
                  <th style="min-width: 90px">操作時間</th>
                  <th>操作項目</th>
                  <th>動作</th>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
                <tr>
                  <td>starbaby</td>
                  <td>星寶</td>
                  <td>2019-01-01&emsp;12:00:00</td>
                  <td>操作項目</td>
                  <td>動作</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination />
        </div>
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import Pagination from '../components/Pagination';
import AlertBox from '../components/AlertBox';

export default {
  components: {
    Pagination,
    AlertBox,
  },
};
</script>
