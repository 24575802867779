<template>
  <div id="ScoreHistoryList">
    <section class="content-header">
      <h1>核心行為價值考核歷程 ({{ person.text }})</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">核心行為價值考核歷程 ({{ person.text }})</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢考核：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="getCycleMonths(true)"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option value="-1" v-if="!permission.check">全部</option>
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
                <select
                  class="form-control"
                  v-show="
                    (!permission.isEmployee ||
                    permission.isHR ||
                    person.level === 9)
                  "
                  v-model="searchData.deptId"
                  @change="getUserList(true)"
                >
                  <option value="" v-if="permission.isHR || person.level === 9">
                    {{
                      person.level > 2
                        ? "部別"
                        : person.level === 2 &&
                          (permission.isEmployee || permission.isDeptManager)
                        ? "部別"
                        : person.level >= 1
                        ? "處別"
                        : "單位"
                    }}
                  </option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-show="
                    permission.isDeptManager &&
                    person.level !== 2 &&
                    person.level !== 9
                  "
                  v-model="searchData.employeeId"
                  @change="resetDataList()"
                >
                  <option value="">人員</option>
                  <option
                    v-for="(user, index) in users"
                    :key="index"
                    :value="user.employeeId"
                  >
                    {{ user.employeeName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw80">年份</th>
                  <th class="tc mw80">年度</th>
                  <th class="tc mw100">部別</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw90">狀態</th>
                  <th class="tc mw150">送簽時間</th>
                  <th class="tc mw150">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="8">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td
                    class="tc"
                    v-text="define.halfYearStatus[data.month]"
                  ></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td class="tc" v-text="define.sendStatus[data.status]"></td>
                  <td class="tc" v-show="data.signStartTime">
                    {{ data.signStartTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="tc" v-show="!data.signStartTime">-</td>
                  <td class="tc">
                    <template
                      v-if="
                        (permission.isHR && person.level === 9) ||
                        (data.status <= 0 &&
                          userData.user.employeeId === data.employeeId)
                      "
                    >
                      <template v-if="data.status === -9">
                        <button
                          class="btn btn-primary"
                          @click="updateData(data)"
                        >
                          新增
                        </button>
                      </template>
                      <template v-else>
                        <button
                          class="btn btn-primary"
                          @click="updateData(data)"
                        >
                          修改
                        </button>
                      </template>
                    </template>
                    <template v-else>
                      <button class="btn btn-primary" @click="updateData(data)">
                        檢視
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        status: -1,
        deptLevel: 3,
        employeeId: "",
        deptId: "",
      },
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      months: [],
      addData: {},
      userData: {},
      permission: {},
      list: [],
      users: [],
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(
      this.userData,
      this.person.limit
    );
    this.permission.isManager =
      !this.permission.isEmployee && this.person.level === 3;
    this.searchData.deptLevel = this.person.level >= 2 ? this.person.level : -9;
    this.searchData.parentDeptId = this.permission.isDivisionManager
      ? this.userData.user.deptId
      : null;
    this.getList();
  },
  methods: {
    getList() {
      if (
        (this.permission.isHR || this.permission.hasOver) &&
        this.person.level === -3
      ) {
        api.organization
          .getDeptList({
            companyId: this.$store.state.loginUser.companyId,
          })
          .then((res) => {
            if (res.data.status === "1") {
              this.list = res.data.result || [];
              this.updateListSearch();
              this.getUserList();
            }
          });
      } else if (
        this.person.level === 9 ||
        this.permission.isHR ||
        (this.permission.hasOver && !this.permission.isManager)
      ) {
        api.okr.goal.department
          .list({
            deptLevel: 2,
            parentDeptId: this.searchData.parentDeptId,
          })
          .then((res) => {
            if (res.data.status === "1") {
              this.list = res.data.result || [];
              this.updateListSearch();
              this.getUserList();
            }
          });
      } else if (this.permission.isManager) {
        api.okr.score.min.crossDeptQueryAll().then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
            this.updateListSearch();
            this.getUserList();
          }
        });
      } else {
        this.list = this.permission.deptList || [];
        if (!this.list.length) this.list = this.permission.allList || [];
        this.updateListSearch();
        this.getUserList();
      }
    },
    getUserList(reset) {
      if (this.person.level === 5) {
        this.searchData.assignDeptId = this.searchData.deptId;
        api.okr.mgmt.assign.queryAll(this.searchData).then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result.datas || [];
            this.updateUserSearch(reset);
            this.getCycleMonths(reset);
          }
        });
      } else if (this.person.level !== 2 && this.person.level !== 9) {
        if (this.permission.isManager) {
          api.okr.score.min.crossUserQueryAll(this.searchData).then((res) => {
            if (res.data.status === "1") {
              this.users = res.data.result || [];
              this.updateUserSearch(reset);
              this.getCycleMonths(reset);
            }
          });
        } else {
          api.okr.goal.personal.list(this.searchData).then((res) => {
            if (res.data.status === "1") {
              this.users = res.data.result || [];
              this.updateUserSearch(reset);
              this.getCycleMonths(reset);
            }
          });
        }
      } else {
        this.getCycleMonths(reset);
      }
    },
    getCycleMonths(reset) {
      this.updateMonthSearch(reset);
      if (reset) this.resetDataList();
      else this.getDataList();
    },
    getDataList() {
      const action = "historyQueryAll";
      api.okr.score.max[action](this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateListSearch() {
      if (
        this.person.level !== 9 &&
        this.loading &&
        !this.$route.params.searchData
      ) {
        this.searchData.deptId = this.global.getDeptId(
          this.list,
          this.userData,
          this.permission
        );
        if (!this.searchData.deptId && this.list.length) {
          this.searchData.deptId = this.list[0].deptId || "";
        }
      }
    },
    updateUserSearch(reset) {
      if (reset) this.searchData.employeeId = "";
      if (
        this.person.level !== 9 &&
        this.loading &&
        !this.$route.params.searchData
      ) {
        this.searchData.employeeId = this.global.getEmployeeId(
          this.users,
          this.userData
        );
      }
    },
    updateMonthSearch(reset) {
      this.setPermission();
      if (
        (reset && !this.months.includes(this.searchData.month)) ||
        (this.loading && !this.$route.params.searchData)
      ) {
        this.searchData.month = this.permission.check
          ? this.global.getHalfYear()
          : -1;
      }
    },
    setPermission() {
      this.permission.check =
        this.person.level === 3 &&
        (this.permission.isManager ||
          this.permission.isHR ||
          this.permission.hasOver);
    },
    updateData(data) {
      const name =
        this.person.level >= 2 ? "BigScorePersonalEdit" : "BigScoreCenterEdit";
      this.$router.push({
        name: name,
        params: {
          type: this.person.type,
          id: data ? data.employeeId : null,
          year: data ? data.year : this.searchData.year,
          month: data ? data.month : this.searchData.month,
          deptId: data ? data.deptId : this.searchData.deptId,
          formType: data ? data.formTypeId : null,
          formId: data ? data.formId : null,
          createId: data ? data.createEmpId : null,
          signId: data ? data.signGetId : null,
          back: "BigScoreHistoryList",
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
