<template>
  <div id="ActivityList">
    <section class="content-header">
      <h1>活動列表管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>社團活動</li>
        <li>活動設定</li>
        <li class="active">活動列表管理</li>
      </ol>
    </section>
    <section class="content activity-list">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-col5">
            <button @click="addActivity()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增活動
            </button>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectType"
                  v-model="searchDatas.typeId"
                  @change="queryAllKinds"
                >
                  <option value="-1">所有活動類別</option>
                  <option
                    v-for="(item, index) in activityTypes"
                    :key="index"
                    :value="item.typeId"
                  >{{item.typeName}}</option>
                </select>
              </div>
              <div class="select-group">
                <select class="form-control" id="selectName" v-model="searchDatas.kindId">
                  <option value="-1">所有活動項目</option>
                  <option
                    v-for="(item, index) in activityKinds"
                    :key="index"
                    :value="item.kindId"
                  >{{item.kindName}}</option>
                </select>
              </div>
            </div>
            <div class="col-5">
              <div class="input-group date-between mr-10">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  id="inputDateRange"
                  v-bind:propEndDate.sync="searchDatas.endTime"
                  v-bind:propStartDate.sync="searchDatas.startTime"
                />
              </div>
            </div>
            <div class="col-5">
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  placeholder="搜尋活動名稱"
                  type="text"
                  v-model="searchDatas.keyword"
                />
                <div class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="onSearch">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">活動類別</th>
                  <th>活動項目</th>
                  <th>活動名稱</th>
                  <th>活動時間</th>
                  <th>活動地點</th>
                  <th>聯絡人</th>
                  <th>附件</th>
                  <th style="min-width: 60px;">狀態</th>
                  <th class="text-center" style="min-width: 200px;">功能</th>
                </tr>
                <tr v-for="(item, index) in activitys" :key="index">
                  <td>{{item.typeName}}</td>
                  <td>{{item.kindName}}</td>
                  <td>{{item.activityName}}</td>
                  <td>{{getDuration(item.activitySTime, item.activityETime)}}</td>
                  <td>{{item.location}}</td>
                  <td>{{item.contacter}}</td>
                  <td class="text-center">
                    <a
                      :key="file.id"
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      class="file-icon"
                      v-for="file in item.files"
                      v-show="item.files.length > 0"
                      :title="file.name"
                    >
                      <i :class="'icon icon-'+file.mine.substring(0,3)"></i>
                    </a>
                    <span v-show="item.files.length == 0">-</span>
                  </td>
                  <td>{{getStautsName(item.status)}}</td>
                  <td class="text-center">
                    <button @click="goActivityParticipants(item)" class="btn btn-default mr-10">參加人員</button>
                    <button
                      @click="editorItem(item)"
                      class="btn btn-default"
                      v-if="item.status!=9"
                    >編輯活動</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix text-center">
          <Pagination
            ref="pagination"
            :pageLimitCount="searchDatas.pageSize"
            :total="total"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <activityBooking ref="activityBooking" v-on:refresh="queryAll()" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import activityBooking from "@/views/Activity/ActivityBooking";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    DateRangePicker,
    Pagination,
    activityBooking,
  },
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      total: 0, //總筆數
      activityTypes: {},
      activityKinds: {},
      activitys: {},
      searchDatas: {
        typeId: "-1",
        kindId: "-1",
        keyword: "",
        isManager: "1",
        pageNum: 1,
        pageSize: 30,
        startTime: new moment().format("YYYY/MM/DD"),
        endTime: new moment().add("12", "d").format("YYYY/MM/DD"),
      },
    };
  },
  created() {
    if (this.$route.params.searchDatas != undefined) {
      this.searchDatas = this.$route.params.searchDatas;
    }

    this.queryAllTypes();
    this.queryAll();
  },
  methods: {
    async queryAllTypes() {
      // 活動類別
      let activityTypes = await api.activity.queryAllManagerType({
        isStop: 0,
      });
      this.activityTypes = activityTypes.data.result.datas;
    },
    async queryAllKinds() {
      // 活動類別細項
      this.searchDatas.kindId = "-1";
      let activityKinds = await api.activity.queryAllManagerKind({
        typeId: this.searchDatas.typeId,
        isStop: 0,
      });
      this.activityKinds = activityKinds.data.result.datas;
    },

    async queryAll() {
      let activity = await api.activity.queryAll(this.searchDatas);

      this.activitys = activity.data.result.datas;
      this.total = activity.data.result.total;

      this.$refs.pagination.setPage(this.searchDatas.pageNum, this.total);
    },

    onSearch() {
      this.searchDatas.pageNum = 1;
      this.queryAll();
    },

    getDuration(start, end) {
      if (moment(start).isSame(end, "day")) {
        return (
          utils.formatTime(start, "YYYY-MM-DD") +
          " " +
          utils.formatTime(start, "HH:mm") +
          "~" +
          utils.formatTime(end, "HH:mm")
        );
      } else {
        return (
          utils.formatTime(start, "YYYY-MM-DD HH:mm") +
          "~" +
          utils.formatTime(end, "YYYY-MM-DD HH:mm")
        );
      }
    },

    getStautsName(status) {
      switch (status) {
        case 0:
          return "草稿";
        case 1:
          return "已發佈";
        case 9:
          return "已檢核";
      }
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "ActivityEdit",
        params: {
          id: item.activityId,
          searchDatas: this.searchDatas,
        },
      });
      return;
    },
    onPageChange(pageIdx, pageSize) {
      this.searchDatas.pageNum = pageIdx;
      this.searchDatas.pageSize = pageSize;
      this.queryAll();
    },
    deleteBooked() {
      this.$eventBus.$emit("showConfirm", "確定要取消嗎?");
    },
    openActivityBooking() {
      this.$refs.activityBooking.showActivityBooking();
    },
    goActivityParticipants(item) {
      this.$router.push({
        name: "ActivityParticipants",
        params: {
          name: encodeURI(item.activityName),
          id: item.activityId,
          searchDatas: this.searchDatas,
        },
      });
    },
    addActivity() {
      this.$router.push({
        name: "ActivityEdit",
        params: {
          searchDatas: this.searchDatas,
        },
      });
    },
  },
};
</script>
