<template>
  <div>
    <div class="box-footer mt-15" v-if="hasEdit || edit"></div>
    <form
      class="form-horizontal"
      method="post"
      v-if="hasEdit || edit"
      @submit.prevent="saveData()"
    >
      <div class="box-body">
        <div class="form-group mb0">
          <label class="col-sm-2 control-label">備註：</label>
          <div class="col-sm-8">
            <textarea
              rows="4"
              type="text"
              class="form-control"
              v-model.trim="data.signNote"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="box-footer no-border">
        <div class="btns" v-if="(edit && !hasSame) || (edit && newJob)">
          <input
            type="submit"
            value="同意"
            class="btn btn-primary"
            @click="updateData(2)"
          />
          <input
            type="submit"
            value="不同意"
            class="btn btn-danger"
            v-if="!newJob"
            @click="updateData(0)"
          />
        </div>
        <div class="btns" v-else>
          <input
            type="submit"
            value="完成送簽"
            class="btn btn-primary"
            v-if="!newJob"
            @click="updateData(1)"
          />
        </div>
      </div>
    </form>
    <div class="box-footer mt-10"></div>
    <form class="form-horizontal">
      <div class="box-body">
        <div class="form-group mb0">
          <label class="col-sm-2 control-label">簽核歷程：</label>
          <div class="col-sm-8">
            <div class="table-responsive">
              <table
                class="table table-hover table-bordered"
                style="margin: 10px auto 20px auto"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th class="tc mw90">簽核人員</th>
                    <th class="tc mw150">簽核時間</th>
                    <th class="tc mw150">簽核意見</th>
                    <th class="tc mw90">簽核結果</th>
                  </tr>
                  <tr v-if="logs.length === 0">
                    <td class="tc" colspan="4">尚無歷程</td>
                  </tr>
                  <tr v-else v-for="(log, index) in logs" :key="index">
                    <td class="tc" v-text="log.signEmpName"></td>
                    <td class="tc">
                      {{ log.signTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td class="tc" v-text="log.signNote || '-'"></td>
                    <td
                      class="tc"
                      v-text="
                        define.logState[index] ||
                        define.logState[log.signState] ||
                        define.logState[log.stepState]
                      "
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props: ["datas", "data", "hasEdit", "backList", "scoreDatas", "newJob"],
  data() {
    return {
      hasSame: false,
      logs: [],
      userData: {},
      edit: this.$route.params.back === "Sign",
      createId: this.$route.params.createId,
      id: this.$route.params.id,
    };
  },
  mounted() {
    this.userData = this.$user.getUserData();
    this.hasSame = this.userData.user.employeeId === this.createId;
    this.getLogList();
    // console.log(this.datas);
    // console.log(this.data);
    // console.log(this.hasEdit);
    // console.log(this.scoreDatas);
  },
  methods: {
    getLogList() {
      api.okr.mgmt.sign
        .queryAllLog({
          formTypeId: this.$route.params.formType,
          formId: this.$route.params.formId,
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.logs = res.data.result || [];
          }
        });
    },
    updateData(status) {
      this.data.signStatus = status;
      this.data.signGetId = this.$route.params.signId;
    },
    addScoreSaveData() {
      let signStatus = this.data.signStatus;
      let signNote = this.data.signNote;
      const action = this.data.signStatus ? "送出" : "退回";
      this.$eventBus.$emit("showConfirm", `確定要${action}簽核嗎?`, (isOK) => {
        if (isOK) {
          api.okr.v2.big.query(this.$parent.searchData)
            .then((res) => {
              this.data = res.data.result.group;
              this.data.signStatus = signStatus;
              this.data.signNote = signNote;
              if(this.logs.length > 1) this.data.signGetId = this.logs[0].signGetId
                let id = this.scoreDatas.findIndex(item => item.createEmpId == this.userData.user.employeeId);
                this.scoreDatas[id].ScoreGroupId = res.data.result.group.scoreGroupId;
                api.okr.score.min.monthUpdate(this.scoreDatas[id])
                .then((res) => {
                  this.global.showResponse(this, res);
                  api.okr.mgmt.sign.send(this.data).then((res) => {
                    this.setMenuNum();
                    this.global.showResponse(this, res, "send", this.backList);
                  });
                })
              })
        }
      });
    },
    saveData() {
      if(this.$route.name == 'ScoreEdit') {
        let id = this.scoreDatas.findIndex(item => item.createEmpId == this.userData.user.employeeId);
        if(!this.scoreDatas[id].totalScore) {
          this.$eventBus.$emit("showAlert", "請輸入加分分數");
          return;
        }
        this.addScoreSaveData();
        return
      }
      if(this.$route.name == 'NewJobEdit') {
        let scoreData = this.$parent.scoreDatas[0]
        if(scoreData.totalScore == 2 && !scoreData.privateNote) {
          this.$eventBus.$emit("showAlert", "請輸入延長時間");
          return;
        }
        if(!scoreData.note) {
          this.$eventBus.$emit("showAlert", "請輸入說明");
          return;
        } 
      }
      if (!this.newJob && this.data.signStatus && !this.datas.length && this.$route.name !== 'BigScoreCenterEdit') {
        this.$eventBus.$emit("showAlert", "請先設立目標內容！");
        return;
      }
      if (this.data.signStatus && this.scoreDatas) {
        const scoreData =
          this.scoreDatas.find(
            (x) => x.createEmpId === this.userData.user.employeeId
          ) || {};
        if (
          this.$route.name === 'BigScoreCenterEdit' ||
          (scoreData.totalScore ||
          (scoreData.totalScore === 0 && !this.newJob))
        ) {
          if (this.newJob) {
            api.okr.score.job.update(scoreData).then((res) => {
              this.global.showResponse(this, res);
              this.sendData();
            });
          } else {
            const action = scoreData.bigGroupId ? "max" : "min";
            api.okr.score[action].monthUpdate(scoreData).then((res) => {
              this.global.showResponse(this, res);
              this.sendData();
            });
          }
        } else if(this.$route.name !== 'BigScoreCenterEdit'){
          if (this.newJob) {
            this.$eventBus.$emit("showAlert", "請選擇評定結果！");
          } else {
            this.$eventBus.$emit("showAlert", "請輸入績效分數！");
          }
        }
      } else {
        this.sendData();
      }
    },
    sendData() {
      const action = this.data.signStatus ? "送出" : "退回";
      this.$eventBus.$emit("showConfirm", `確定要${action}簽核嗎?`, (isOK) => {
        if (isOK) {
          api.okr.mgmt.sign.send(this.data).then((res) => {
            this.setMenuNum();
            this.global.showResponse(this, res, "send", this.backList);
          });
        }
      });
    },
    setMenuNum() {
      api.okr.mgmt.sign.count().then((res) => {
        if (res.data.status === "1") {
          $(".menuNum").text(res.data.result);
          if (res.data.result === 0) {
            $(".menuNum").addClass("hide");
          } else {
            $(".menuNum").removeClass("hide");
          }
        }
      });
    },
  },
};
</script>
