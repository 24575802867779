<template>
  <div id="PersonalTimeList">
    <section class="content-header">
      <h1 v-if="type">大考開放時間</h1>
      <h1 v-else>目標、小考開放時間</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active" v-if="type">大考開放時間</li>
        <li class="active" v-else>目標、小考開放時間</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="select-group">
                <label class="control-label">查詢人員：</label>
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="resetDataList()"
                >
                  <option
                    v-for="(month, index) in 12"
                    :key="index"
                    :value="month"
                  >
                    {{ month }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="getUserList(true)"
                >
                  <option value="">部別</option>
                  <option
                    v-for="(item, index) in list"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.employeeId"
                  @change="resetDataList()"
                >
                  <option value="">人員</option>
                  <option
                    v-for="(user, index) in users"
                    :key="index"
                    :value="user.employeeId"
                  >
                    {{ user.employeeName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.openType"
                  @change="resetDataList()"
                >
                  <option value="0" v-if="!type">人員目標</option>
                  <option value="1" v-if="!type">小考</option>
                  <option value="2" v-if="type">大考</option>
                </select>
              </div>
            </div>
            <div class="text">
              <p class="data-num" v-text="`資料總筆數：${total}`"></p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">年份</th>
                  <th class="tc">月份</th>
                  <th class="tc">部別</th>
                  <th class="tc">員工編號</th>
                  <th class="tc">姓名</th>
                  <th class="tc">分類</th>
                  <th class="tc">狀態</th>
                  <th class="tc">開放開始時間</th>
                  <th class="tc">開放結束時間</th>
                  <th class="tc">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="10">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="10">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.month"></td>
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.employeeId"></td>
                  <td class="tc" v-text="data.employeeName"></td>
                  <td
                    class="tc"
                    v-text="
                      define.openType[data.openType || searchData.openType]
                    "
                  ></td>
                  <td class="tc" v-text="define.openStatus[data.status]"></td>
                  <td class="tc">
                    {{ data.startTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="tc">
                    {{ data.endTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="tc">
                    <button class="btn btn-primary" @click="updateData(data)">
                      {{ data.openTimeId ? "修改" : "新增" }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        pageNum: 1,
        pageSize: 20,
        target: "",
        keyword: "",
        year: this.global.getYear(),
        month: -1,
        openType: "",
        employeeId: "",
        deptId: "",
      },
      type: Number(this.$route.params.type),
      years: this.global.getYearList(),
      userData: {},
      permission: {},
      list: [],
      users: [],
      datas: [],
    };
  },
  created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.searchData.openType = this.type ? 2 : 0;
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.updateMonthSearch();
    this.getList();
  },
  methods: {
    getList() {
      api.okr.goal.department.list({ deptLevel: 2 }).then((res) => {
        if (res.data.status === "1") {
          this.list = res.data.result || [];
          this.updateListSearch();
          this.getUserList();
        }
      });
    },
    getUserList(reset) {
      if (this.type) {
        api.organization.queryEmployeeAll(this.searchData).then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result.datas || [];
            this.updateUserSearch(reset);
            if (reset) this.resetDataList();
            else this.getDataList();
          }
        });
      } else {
        api.okr.goal.personal.list(this.searchData).then((res) => {
          if (res.data.status === "1") {
            this.users = res.data.result || [];
            this.updateUserSearch(reset);
            if (reset) this.resetDataList();
            else this.getDataList();
          }
        });
      }
    },
    getDataList() {
      api.okr.mgmt.open.queryAll(this.searchData).then((res) => {
        if (res.data.status === "1") {
          this.datas = res.data.result.datas || [];
          this.total = res.data.result.total;
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateMonthSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.month = this.global.getMonth();
      }
    },
    updateListSearch() {
      if (this.loading && !this.$route.params.searchData) {
        this.searchData.deptId = this.global.getDeptId(
          this.list,
          this.userData,
          this.permission
        );
      }
    },
    updateUserSearch(reset) {
      if (reset) this.searchData.employeeId = "";
    },
    updateData(data) {
      this.$router.push({
        name: "PersonalTimeEdit",
        params: {
          type: this.type,
          openType: this.searchData.openType,
          id: data ? data.openTimeId : null,
          deptId: data ? data.deptId : this.searchData.deptId,
          employeeId: data ? data.employeeId : this.searchData.employeeId,
          year: data ? data.year : this.searchData.year,
          month: data ? data.month : this.searchData.month,
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
