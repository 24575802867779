var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "scorePersonalContent", staticClass: "modal fade" }, [
    _c(
      "div",
      { staticClass: "modal-dialog", staticStyle: { width: "800px" } },
      [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table no-border" }, [
                    _c("tbody", [
                      _c("tr", [
                        _vm._m(1),
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.objectiveType,
                                  expression: "data.objectiveType",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { required: "", disabled: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "objectiveType",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "0" } }, [
                                _vm._v("一般目標"),
                              ]),
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("新增任務"),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm.person.level === 2 || _vm.data.deptLevel === 2
                        ? _c("tr", [
                            _vm._m(2),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.data.objective,
                                    expression: "data.objective",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "",
                                  disabled:
                                    _vm.data.objectiveType === 0 ||
                                    (_vm.data.userData &&
                                      _vm.data.userData.user.employeeId !==
                                        _vm.data.employeeId),
                                },
                                domProps: { value: _vm.data.objective },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      "objective",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ])
                        : _c("tr", [
                            _vm._m(3),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.parentObjectiveId,
                                      expression: "data.parentObjectiveId",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    required: "",
                                    disabled: _vm.data.objectiveType === 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.data,
                                        "parentObjectiveId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "-1" } }, [
                                    _vm._v("未定"),
                                  ]),
                                  _c("option", { attrs: { value: "-2" } }, [
                                    _vm._v("例行工作"),
                                  ]),
                                  _vm._l(_vm.objs, function (obj, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: obj.objectiveId },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(obj.objective) +
                                            "\n                      "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                      _vm.person.level === 2 || _vm.data.deptLevel === 2
                        ? _c("tr", [
                            _vm._m(4),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.data.note,
                                    expression: "data.note",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  rows: "4",
                                  type: "text",
                                  required: "",
                                  disabled:
                                    _vm.data.objectiveType === 0 ||
                                    (_vm.data.userData &&
                                      _vm.data.userData.user.employeeId !==
                                        _vm.data.employeeId),
                                },
                                domProps: { value: _vm.data.note },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      "note",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ])
                        : _c("tr", [
                            _vm._m(5),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.data.objective,
                                    expression: "data.objective",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  rows: "4",
                                  type: "text",
                                  required: "",
                                  disabled:
                                    _vm.data.objectiveType === 0 ||
                                    (_vm.data.userData &&
                                      _vm.data.userData.user.employeeId !==
                                        _vm.data.employeeId),
                                },
                                domProps: { value: _vm.data.objective },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      "objective",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                      _c("tr", [
                        _c("td", { staticClass: "control-label" }),
                        _c(
                          "td",
                          { staticClass: "tr", attrs: { colspan: "2" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "checkbox checkbox-inline fw-b",
                                staticStyle: { color: "cornflowerblue" },
                              },
                              [
                                _c("iCheckCheckBox", {
                                  attrs: {
                                    propValue: "1",
                                    propChecked: _vm.data.checked,
                                  },
                                  on: { "update:checked": _vm.checkChange },
                                }),
                                _vm._v(
                                  " 不列入考核（欲勾選此項，麻煩請先於主管討論）\n                    "
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "control-label",
                            attrs: { colspan: "3" },
                          },
                          _vm._l(_vm.data.scores, function (scoreItem, index) {
                            return _c(
                              "table",
                              {
                                key: index,
                                staticClass: "table table-bordered",
                              },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", {
                                      staticClass: "tc bg-dark",
                                      attrs: { colspan: "2" },
                                      domProps: {
                                        textContent: _vm._s(
                                          `${scoreItem.createEmpName}${
                                            index === 0
                                              ? "（自評）"
                                              : scoreItem.createIsManager ===
                                                  "0" &&
                                                scoreItem.createDeptType.includes(
                                                  "0060"
                                                )
                                              ? "（上層主管）"
                                              : scoreItem.createIsManager ===
                                                  "1" &&
                                                scoreItem.createDeptType.includes(
                                                  "0060"
                                                )
                                              ? "（部門主管）"
                                              : "（高層主管）"
                                          }`
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "tc bg-dark-gray",
                                        attrs: { width: "200px" },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "fw-b fz50",
                                          domProps: {
                                            textContent: _vm._s(
                                              scoreItem.score
                                            ),
                                          },
                                        }),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-dark btn-lg",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.minusScore(
                                                  scoreItem,
                                                  _vm.data.hasHR ||
                                                    _vm.data.userData.user
                                                      .employeeId ===
                                                      scoreItem.createEmpId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-minus",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-dark btn-lg",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.plusScore(
                                                  scoreItem,
                                                  _vm.data.hasHR ||
                                                    _vm.data.userData.user
                                                      .employeeId ===
                                                      scoreItem.createEmpId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-plus",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("td", [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: scoreItem.note,
                                            expression: "scoreItem.note",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          rows: "6",
                                          type: "text",
                                          placeholder: "輸入文字",
                                          disabled:
                                            !_vm.data.hasHR &&
                                            _vm.data.userData.user
                                              .employeeId !==
                                              scoreItem.createEmpId,
                                        },
                                        domProps: { value: scoreItem.note },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              scoreItem,
                                              "note",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(6),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("評分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label w90" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    目標種類\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    部級目標\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    部級目標\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    目標說明\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("\n                    人員目標\n                  "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("儲存")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("\n            取消\n          ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }