var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal fade order-setting",
      attrs: { id: "order-setting" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h4", {
              staticClass: "modal-title",
              domProps: { textContent: _vm._s("新增移動式作業人員") },
            }),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "text-nowrap" }, [
                      _vm._v("員工工號"),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.employeeId,
                            expression: "item.employeeId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "required", type: "text" },
                        domProps: { value: _vm.item.employeeId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.item,
                              "employeeId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "text-nowrap" }, [
                      _vm._v("選擇時間"),
                    ]),
                    _c(
                      "td",
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            propEndDate: _vm.item.endDate,
                            propStartDate: _vm.item.startDate,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(_vm.item, "endDate", $event)
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(_vm.item, "endDate", $event)
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(_vm.item, "startDate", $event)
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(_vm.item, "startDate", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.save },
              },
              [_vm._v("儲存")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v("關閉")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }