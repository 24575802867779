<template>
  <!--帳號設定-->
  <div class="modal fade" ref="accountManagement">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">編輯帳號</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>帳號</td>
                  <td colspan="2">{{employee.userid}}</td>
                </tr>
                <tr>
                  <td>姓名</td>
                  <td colspan="2">{{employee.employeeName}}</td>
                </tr>
                <!-- <tr>
                  <td>隸屬公司</td>
                  <td colspan="2">網銀國際</td>
                </tr>-->
                <tr>
                  <td>隸屬部門</td>
                  <td colspan="2">{{employee.deptName}}</td>
                </tr>
                <tr>
                  <td>職稱</td>
                  <td colspan="2">{{employee.empTitleName}}</td>
                </tr>
                <tr>
                  <td>工號</td>
                  <td colspan="2">{{employee.employeeId}}</td>
                </tr>
                <tr>
                  <td>電子郵件</td>
                  <td colspan="2">{{employee.empEmail}}</td>
                </tr>
                <!-- <tr>
                    <td>到職日期</td>
                    <td colspan="2">2019/01/01</td>
                </tr>-->
                <tr>
                  <td>辦公室</td>
                  <td colspan="2">
                    <select class="form-control" v-model="employee.office">
                      <option
                        :key="index"
                        v-for="(offices, index) in officeList"
                        :value="offices.office"
                      >{{offices.office}}</option>
                    </select>
                    <p class="mt-10 text-orange">
                      <i class="fa fa-warning"></i> 如需新增或修改辦公室，請至【
                      <router-link to="/OfficeSetting" data-dismiss="modal">辦公室管理</router-link>】進行設定。
                    </p>
                  </td>
                </tr>
                <!-- <tr>
                  <td>連絡電話</td>
                  <td>
                    <select class="form-control">
                      <option>請選擇</option>
                      <option>04-2452-2811</option>
                    </select>
                  </td>
                  <td>
                    <input class="form-control" placeholder="分機號碼" type="text" />
                  </td>
                </tr>-->
                <tr>
                  <td>手機</td>
                  <td colspan="2">
                    <input class="form-control" type="text" v-model="employee.empCellphone" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="save()">儲存</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">取消</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      employee: {},
      officeList: {}
    };
  },
  created() {
    this.OfficeList();
  },
  methods: {
    OfficeList() {
      api.office.officeList({}).then(res => {
        if (res.data.status == "1") {
          this.officeList = res.data.result.datas;
        }
      });
    },
    async openEditWindow(id) {
      await api.organization
        .queryEmployeeOne({
          employeeId: id
        })
        .then(res => {
          if (res.data.status == "1") {
            this.employee = res.data.result;
            if (this.employee.office == null || this.employee.office == "")
              this.employee.office = this.officeList[0].office;
          } else {
            this.$eventBus.$emit("showAlert", "查無資料!!");
            $(this.$refs.accountManagement).modal("hide");
          }
        });

      $(this.$refs.accountManagement).modal("show");
    },
    save() {
      if (this.employee.office == "") {
        this.$eventBus.$emit("showAlert", "請選擇辦公室!");
        return;
      }

      api.organization.updateEmployee(this.employee).then(res => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
          return;
        } else {
          $(this.$refs.accountManagement).modal("hide");
          this.refreshList();
        }
      });
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>