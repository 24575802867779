<template>
  <div class="wrapper" id="App">
    <router-view v-if="isRouterAlive" />
    <AlertBox ref="alertBox" />
    <div id="loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import AlertBox from "@/components/AlertBox";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: false,
    };
  },
  components: {
    AlertBox,
  },
  mounted() {
    api.login
      .check()
      .then((res) => {
        if (res.data.status == "1") {
          this.$store.commit("setLoginUser", res.data.result.user);
          this.$store.commit("setLoginUserMenu", res.data.result.menu);
          this.$store.commit("setLoginPermission", res.data.result.permission);
          this.$store.commit("setLoginDeptEmp", res.data.result.deptEmp);
          this.$store.commit("setLoginMealAssign", res.data.result.mealAssign);
          if (res.data.result.user.changePwdDate != "") {
            this.$store.commit("setChangePassword", true);
          } else this.$store.commit("setChangePassword", false);
          // this.$router.push('/');
          this.getMenuNotice();
        } else {
          if (this.$route.query.c) {
            localStorage.setItem("company", this.$route.query.c);
          } else {
            localStorage.setItem("company", "");
          }
          this.$router.push("/login");
        }
        this.isRouterAlive = true;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    getMenuNotice() {
      // 簽核管理
      if (this.$user.hasPermission("S0")) {
        api.okr.mgmt.sign.count().then((res) => {
          this.$store.commit("setFlowNotice", res.data.result);
        });
      }

      // 績效考核
      if (this.$user.hasPermission("C0")) {
        api.okr.v2.rater.notice().then((res) => {
          this.$store.commit("setOkrNotice", res.data.result);
        });
      }

      // 教育訓練
      if (this.$user.hasPermission("ET")) {
        api.education.notice().then((res) => {
          if (res.data.status == 1) {
            const courseRecord = res.data.result.courseRecord;
            if (courseRecord) {
              this.$store.commit("setEducationNotice", res.data.result);
            }
          }
        });
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
