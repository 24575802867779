var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ScorePersonalList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.person.level === 4 ? "新進員工" : "工作績效") +
              "考核表\n      " +
              _vm._s(_vm.person.level === 4 ? "" : `(${_vm.person.text})`) +
              "\n    "
          ),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.person.level === 4 ? "新進員工" : "工作績效") +
                "考核表\n        " +
                _vm._s(_vm.person.level === 4 ? "" : `(${_vm.person.text})`) +
                "\n      "
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content seating" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "btns" }, [
                _vm.permission.add
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-warning btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.person.level === 9
                              ? _vm.updateFlowData()
                              : _vm.showContent()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v("\n              新增考核\n            "),
                      ]
                    )
                  : _vm.permission.total && _vm.searchData.month !== -1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-warning btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.updateTotalData()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-edit" }),
                        _vm._v("\n              總表分數修改\n            "),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("查詢考核："),
                  ]),
                  _vm.person.level === 9
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.searchData.deptLevel,
                              expression: "searchData.deptLevel",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control w90",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "deptLevel",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.getList()
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "2" } }, [
                            _vm._v("部級主管"),
                          ]),
                          _c("option", { attrs: { value: "3" } }, [
                            _vm._v("一般員工"),
                          ]),
                          _c("option", { attrs: { value: "4" } }, [
                            _vm._v("新進員工"),
                          ]),
                          _c("option", { attrs: { value: "5" } }, [
                            _vm._v("實習生"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.year,
                          expression: "searchData.year",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control w90",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "year",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getCycleMonths(true)
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.years, function (year, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: year } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(year) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.person.level === 2 || _vm.searchData.deptLevel === 2
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.searchData.month,
                              expression: "searchData.month",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control w90",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "month",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.resetDataList()
                              },
                            ],
                          },
                        },
                        [
                          !_vm.permission.check
                            ? _c("option", { attrs: { value: "-1" } }, [
                                _vm._v("全部"),
                              ])
                            : _vm._e(),
                          _c("option", { attrs: { value: "0" } }, [
                            _vm._v("上半年"),
                          ]),
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v("下半年"),
                          ]),
                        ]
                      )
                    : _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.searchData.month,
                              expression: "searchData.month",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control w90",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return _vm._n(val)
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "month",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.resetDataList()
                              },
                            ],
                          },
                        },
                        [
                          !_vm.permission.check
                            ? _c("option", { attrs: { value: "-1" } }, [
                                _vm._v("月份"),
                              ])
                            : _vm._e(),
                          _vm._l(_vm.months, function (month, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: month } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(month) +
                                    "\n                "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.permission.isEmployee ||
                            _vm.permission.isHR ||
                            _vm.person.level === 9,
                          expression:
                            "\n                  (!permission.isEmployee ||\n                  permission.isHR ||\n                  person.level === 9)\n                ",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.deptId,
                          expression: "searchData.deptId",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "deptId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.getUserList(true)
                          },
                        ],
                      },
                    },
                    [
                      _vm.permission.isHR || _vm.person.level === 9
                        ? _c("option", { attrs: { value: "" } }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.person.level > 2
                                    ? "部別"
                                    : _vm.person.level === 2 &&
                                      (_vm.permission.isEmployee ||
                                        _vm.permission.isDeptManager)
                                    ? "部別"
                                    : _vm.person.level >= 1
                                    ? "處別"
                                    : "單位"
                                ) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.deptId } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.deptName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.permission.isDeptManager &&
                            _vm.person.level !== 2 &&
                            _vm.person.level !== 9,
                          expression:
                            "\n                  permission.isDeptManager &&\n                  person.level !== 2 &&\n                  person.level !== 9\n                ",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.employeeId,
                          expression: "searchData.employeeId",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "employeeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("人員")]),
                      _vm._l(_vm.users, function (user, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: user.employeeId } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(user.employeeName) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.person.level !== 9,
                          expression: "person.level !== 9",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.scoreStatus,
                          expression: "searchData.scoreStatus",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "scoreStatus",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("狀態"),
                      ]),
                      _c("option", { attrs: { value: "-9" } }, [
                        _vm._v("未填寫"),
                      ]),
                      _c("option", { attrs: { value: "0" } }, [_vm._v("草稿")]),
                      _c("option", { attrs: { value: "1" } }, [
                        _vm._v("送簽中"),
                      ]),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("送簽完成"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", {
                  staticClass: "data-num",
                  domProps: { textContent: _vm._s(`資料總筆數：${_vm.total}`) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", { staticClass: "tc mw80" }, [_vm._v("年份")]),
                        _vm.person.level === 2 || _vm.searchData.deptLevel === 2
                          ? _c("th", { staticClass: "tc mw80" }, [
                              _vm._v(
                                "\n                  年度\n                "
                              ),
                            ])
                          : _c("th", { staticClass: "tc mw50" }, [
                              _vm._v("月份"),
                            ]),
                        _c("th", { staticClass: "tc mw100" }, [_vm._v("部別")]),
                        _c("th", { staticClass: "tc mw100" }, [
                          _vm._v("員工編號"),
                        ]),
                        _c("th", { staticClass: "tc mw100" }, [_vm._v("姓名")]),
                        _c("th", { staticClass: "tc mw90" }, [_vm._v("狀態")]),
                        _c("th", { staticClass: "tc mw150" }, [
                          _vm._v("送簽時間"),
                        ]),
                        _c("th", { staticClass: "tc mw150" }, [_vm._v("管理")]),
                      ]),
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "8" } },
                              [_vm._v("查詢中")]
                            ),
                          ])
                        : _vm.datas.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { staticClass: "tc", attrs: { colspan: "8" } },
                              [_vm._v("查詢無任何資料")]
                            ),
                          ])
                        : _vm._l(_vm.datas, function (data, index) {
                            return _c("tr", { key: index }, [
                              _c("td", {
                                staticClass: "tc",
                                domProps: { textContent: _vm._s(data.year) },
                              }),
                              _vm.person.level === 2 ||
                              _vm.searchData.deptLevel === 2
                                ? _c("td", {
                                    staticClass: "tc",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.define.halfYearStatus[data.month]
                                      ),
                                    },
                                  })
                                : _c("td", {
                                    staticClass: "tc",
                                    domProps: {
                                      textContent: _vm._s(data.month),
                                    },
                                  }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(data.deptName),
                                },
                              }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(data.employeeId),
                                },
                              }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(data.employeeName),
                                },
                              }),
                              _c("td", {
                                staticClass: "tc",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.define.sendStatus[data.scoreStatus]
                                  ),
                                },
                              }),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: data.signStartTime,
                                      expression: "data.signStartTime",
                                    },
                                  ],
                                  staticClass: "tc",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          data.signStartTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !data.signStartTime,
                                      expression: "!data.signStartTime",
                                    },
                                  ],
                                  staticClass: "tc",
                                },
                                [_vm._v("-")]
                              ),
                              _c(
                                "td",
                                { staticClass: "tc" },
                                [
                                  (_vm.permission.isHR &&
                                    _vm.person.level === 9) ||
                                  (data.scoreStatus <= 0 &&
                                    _vm.userData.user.employeeId ===
                                      data.employeeId)
                                    ? [
                                        data.scoreStatus === -9
                                          ? [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateData(
                                                        data
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        新增\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateData(
                                                        data
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        修改\n                      "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                       \n                      "
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-danger",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteData(
                                                        data
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        刪除\n                      "
                                                  ),
                                                ]
                                              ),
                                            ],
                                      ]
                                    : data.scoreStatus === -9
                                    ? [_vm._v("-")]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateData(data)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      檢視\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("ScoreAddContent", {
        ref: "scoreAddContent",
        on: { updateList: _vm.updateData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }