var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "OrderStatistics" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("訂單統計")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("訂餐服務管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("訂單統計")]),
      ]),
    ]),
    _c("section", { staticClass: "content order-statistics" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "group-left" }, [
                _c(
                  "div",
                  { staticClass: "radio-inline" },
                  [
                    _c("iCheckRedioButton", {
                      attrs: {
                        checkValue: "1",
                        message: "午餐",
                        name: "radioMode",
                      },
                      model: {
                        value: _vm.eatTime,
                        callback: function ($$v) {
                          _vm.eatTime = $$v
                        },
                        expression: "eatTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "radio-inline" },
                  [
                    _c("iCheckRedioButton", {
                      attrs: {
                        checkValue: "2",
                        message: "晚餐",
                        name: "radioMode",
                      },
                      model: {
                        value: _vm.eatTime,
                        callback: function ($$v) {
                          _vm.eatTime = $$v
                        },
                        expression: "eatTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.changeWeek(-1)
                        },
                      },
                    },
                    [_vm._v("上週")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.changeWeek(0)
                        },
                      },
                    },
                    [_vm._v("本週")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.changeWeek(1)
                        },
                      },
                    },
                    [_vm._v("下週")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "group-right" }, [
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DatePicker", {
                        model: {
                          value: _vm.dateTime,
                          callback: function ($$v) {
                            _vm.dateTime = $$v
                          },
                          expression: "dateTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportExcel()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-download" }),
                    _vm._v(" 匯出\n            "),
                  ]
                ),
              ]),
            ]),
            _vm._l(_vm.statisticsList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "table-responsive mt-10" },
                [
                  _c(
                    "table",
                    { staticClass: "table table-hover table-bordered min-600" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "bg-gray",
                                attrs: { colspan: "5" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$utils.formatTime(
                                      item.eatDay,
                                      "YYYY/MM/DD"
                                    )
                                  ) +
                                    " 【週" +
                                    _vm._s(_vm.$utils.getWeekDay(item.eatDay)) +
                                    "】"
                                ),
                              ]
                            ),
                          ]),
                          _vm._m(1, true),
                          _vm._l(item.meals, function (meal, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(meal.storeName))]),
                              _c("td", [_vm._v(_vm._s(meal.mealName))]),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(meal.price)),
                              ]),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(meal.eatCount)),
                              ]),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(meal.price * meal.eatCount)),
                              ]),
                            ])
                          }),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-right",
                                attrs: { colspan: "5" },
                              },
                              [_vm._v("總計：" + _vm._s(item.totalCost))]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("店家名稱")]),
      _c("td", [_vm._v("菜單名稱")]),
      _c("td", { staticClass: "text-right" }, [_vm._v("售價")]),
      _c("td", { staticClass: "text-right" }, [_vm._v("數量")]),
      _c("td", { staticClass: "text-right" }, [_vm._v("小計")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }