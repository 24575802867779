<template>
  <div id="ScorePersonalEdit">
    <section class="content">
      <div class="box-body">
        <div class="tc" v-if="data.message">
          {{ data.message }}
        </div>
        <div class="tc" v-else-if="!data.year">查詢中</div>
        <div v-else>
          <div
            class="table-responsive"
            style="max-width: 1400px; margin: auto"
          >
          </div>
          <div
            class="table-responsive"
            style="max-width: 1400px; margin: auto"
          >
            <table
              class="table table-hover table-bordered min-800"
            >
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">#</th>
                  <th class="tc mw50">年份</th>
                  <th class="tc mw50">月份</th>
                  <th class="tc mw90">目標種類</th>
                  <th class="tc mw150">部級目標</th>
                  <th class="tc mw150">人員目標</th>
                  <template v-for="(title, index) in titles">
                    <th
                      class="tc mw90 pre-wrap"
                      :key="index"
                      v-text="
                        `${title.employeeName}\n${
                          index === 0
                            ? '（自評）'
                            : title.isManager === '0' &&
                              title.deptType.includes('0060')
                            ? '（上層主管）'
                            : title.isManager === '1' &&
                              title.deptType.includes('0060')
                            ? '（部門主管）'
                            : '（高層主管）'
                        }`
                      "
                    ></th>
                    <th
                      class="tc mw90 pre-wrap"
                      :key="title.employeeId"
                      v-text="
                        `${
                          data.employeeId === title.employeeId
                            ? '說明'
                            : '考核意見'
                        }`
                      "
                    ></th>
                  </template>
                  <th class="tc mw90">不列入考核</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" :colspan="8 + 2 * titles.length">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" :colspan="8 + 2 * titles.length">
                    尚無設定目標
                  </td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="index + 1"></td>
                  <td class="tc" v-text="data.year"></td>
                  <td class="tc" v-text="data.month"></td>
                  <td
                    class="tc"
                    v-text="define.objectiveType[data.objectiveType]"
                  ></td>
                  <td class="pre-wrap" v-text="data.parentObjective"></td>
                  <td class="pre-wrap" v-text="data.objective"></td>
                  <template v-for="(scoreItem, index) in data.scores">
                    <td
                      class="tc"
                      :key="index"
                      v-text="scoreItem.score"
                    ></td>
                    <td
                      class="pre-wrap"
                      :class="[
                        scoreItem.note && scoreItem.note.length >= 300
                          ? 'mw300'
                          : 'mw90',
                      ]"
                      :key="scoreItem.scoreId"
                      v-text="scoreItem.note"
                    ></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="table-responsive"
            style="max-width: 1400px; margin: auto"
          >
            <table
              class="table table-bordered min-800"
            >
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw150" style="width: 180px">評分人員</th>
                  <th class="tc mw150" style="width: 150px">
                    績效分數
                  </th>
                  <th
                    class="tc"
                    :class="userData.user.employeeId === id ? 'wp40' : 'wp25'"
                  >
                    說明
                  </th>
                  <th
                    class="tc wp25"
                    v-if="userData.user.employeeId !== id"
                  >
                    給主管說明
                  </th>
                  <th class="tc mw300">附件</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="6">查詢中</td>
                </tr>
                <tr v-else-if="scoreDatas.length === 0">
                  <td class="tc" colspan="6">尚無評分人員</td>
                </tr>
                <tr
                  v-else
                  v-for="(scoreData, index) in scoreDatas"
                  :key="index"
                >
                  <td class="tc" v-text="scoreData.createEmpName"></td>
                  <td
                    class="tc fw-b fz25"
                    v-text="scoreData.totalScore"
                  ></td>
                  <td class="pre-wrap" v-text="scoreData.note"></td>
                  <td
                    class="pre-wrap"
                    v-if="userData.user.employeeId !== id"
                    v-text="scoreData.privateNote"
                  ></td>
                  <td class="tc">
                    <table class="table no-border">
                      <tbody>
                        <tr
                          v-if="
                            userData.user.employeeId !==
                                scoreData.createEmpId &&
                            scoreData.files &&
                            scoreData.files.length === 0
                          "
                        >
                          <td class="tc">－</td>
                        </tr>
                        <tr
                          v-else
                          v-for="(file, index) in scoreData.files"
                          :key="index"
                        >
                          <td class="tr">
                            <a target="_blank" :href="file.filesUrl">
                              {{ file.name }}
                            </a>
                          </td>
                          <td
                            class="w90"
                            v-if="
                              userData.user.employeeId ===
                                  scoreData.createEmpId
                            "
                          >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props:['minScoreList'],
  data() {
    return {
      loading: true,
      total: 0,
      addData: {
        type: this.$route.params.type,
        employeeId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        deptId: this.$route.params.deptId,
        deptLevel: this.$route.params.deptLevel,
        empList: this.$route.params.empList,
        adminAdd: this.$route.params.adminAdd || 0,
        formId: this.$route.params.formId,
        scoreGroupId: this.$route.params.formId,
        back: this.$route.params.back,
        searchData: this.$route.params.searchData,
      },
      view: this.$route.params.back === "SignHistory",
      person: this.global.getPerson('NewCrew'),
      id: this.$route.params.id,
      userData: {},
      permission: {},
      scoreDatas: [],
      scoreData: {},
      titles: [],
      datas: [],
      data: {},
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.addData.month = this.minScoreList.month;
      this.addData.deptLevel = this.minScoreList.deptLevel;
      this.addData.formId = this.minScoreList.formId;
      this.addData.scoreGroupId = this.minScoreList.formId;
      if (this.id) {
        api.okr.score.min.queryAll(this.addData).then((res) => {
          this.data = res.data;
          if (res.data.status === "1") {
            if (res.data.result) {
              this.titles = res.data.result.empList || [];
              this.datas = res.data.result.scoreList || [];
              this.data = res.data.result.group || this.datas[0];
              this.total = res.data.result.total;
              this.getScoreDataList();
            }
          }
        });
      } else {
        this.loading = false;
      }
    },
    getScoreDataList() {
      this.data.monthStatus = 1;
      api.okr.score.min.monthQueryAll(this.data).then((res) => {
        if (res.data.status === "1") {
          if (res.data.result) {
            this.scoreDatas = res.data.result.datas || [];
          }
          this.updateDataList();
        }
      });
    },
    updateDataList() {
      this.datas.forEach((data) => {
        data.checked = data.noCount ? 1 : 0;
        setTimeout(() => {
          data.checked = data.noCount ? true : false;
          data.disabled = true;
          this.$forceUpdate();
        }, 300);
      });
      this.loading = false;
    },
  },
};
</script>
