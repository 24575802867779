var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ScorePersonalEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.person.level === 4 ? "新進員工" : "工作績效") +
              "考核表\n      " +
              _vm._s(_vm.person.level === 4 ? "" : `(${_vm.person.text})`) +
              "\n    "
          ),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: `/OKR/ScorePersonalList/${_vm.person.type}` } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.person.level === 4 ? "新進員工" : "工作績效") +
                      "考核表\n          " +
                      _vm._s(
                        _vm.person.level === 4 ? "" : `(${_vm.person.text})`
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(
              "\n        " +
                _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
                "考核\n      "
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(!_vm.id ? "新增" : _vm.hasEdit ? "編輯" : "檢視") +
                    "考核\n          "
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v("\n            返回列表\n          "),
                ]
              ),
              _vm.data.scoreStatus === 1 &&
              _vm.data.createId === _vm.userData.user.employeeId
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn-cog",
                      staticStyle: { width: "auto" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.signCancel()
                        },
                      },
                    },
                    [_vm._v("\n            抽單\n          ")]
                  )
                : _vm._e(),
            ]),
            _vm.data.message
              ? _c("div", { staticClass: "tc" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.data.message) + "\n        "
                  ),
                ])
              : !_vm.data.year
              ? _c("div", { staticClass: "tc" }, [_vm._v("查詢中")])
              : _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "table-responsive",
                        staticStyle: { "max-width": "1400px", margin: "auto" },
                      },
                      [
                        _c("table", { staticClass: "table no-border" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table no-border",
                                    staticStyle: { width: "400px" },
                                  },
                                  [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("th", { staticClass: "tr w90" }, [
                                          _vm._v("員工編號："),
                                        ]),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.data.employeeId
                                            ),
                                          },
                                        }),
                                        _c("th", { staticClass: "tr" }, [
                                          _vm._v("部別："),
                                        ]),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.data.deptName
                                            ),
                                          },
                                        }),
                                      ]),
                                      _c("tr", [
                                        _c("th", { staticClass: "tr" }, [
                                          _vm._v("員工姓名："),
                                        ]),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.data.employeeName
                                            ),
                                          },
                                        }),
                                        _vm.person.level === 2 ||
                                        _vm.addData.deptLevel === 2
                                          ? _c("th", { staticClass: "tr" }, [
                                              _vm._v(
                                                "\n                            年度：\n                          "
                                              ),
                                            ])
                                          : _c("th", { staticClass: "tr" }, [
                                              _vm._v("狀態："),
                                            ]),
                                        _vm.person.level === 2 ||
                                        _vm.addData.deptLevel === 2
                                          ? _c("td", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  `${_vm.addData.year}年(${
                                                    _vm.define.halfYearStatus[
                                                      _vm.addData.month
                                                    ]
                                                  })`
                                                ),
                                              },
                                            })
                                          : _c("td", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.define.sendStatus[
                                                    _vm.data.scoreStatus
                                                  ]
                                                ),
                                              },
                                            }),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("td", [_c("ScoreNote")], 1),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "box-footer mt-15" }),
                    _vm.person.level === 2 || _vm.addData.deptLevel === 2
                      ? _c("div", [_c("ScoreDeptEdit")], 1)
                      : _c(
                          "div",
                          {
                            staticClass: "table-responsive",
                            staticStyle: {
                              "max-width": "1400px",
                              margin: "auto",
                            },
                          },
                          [
                            _vm.hasEdit &&
                            (_vm.permission.isEmployee ||
                              _vm.person.level === 9)
                              ? _c("div", { staticClass: "btns" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning",
                                      staticStyle: {
                                        float: "right",
                                        margin: "10px 0px",
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showContent()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-plus" }),
                                      _vm._v(
                                        "\n                新增目標\n              "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-hover table-bordered min-800",
                                staticStyle: { margin: "5px auto" },
                              },
                              [
                                _c(
                                  "tbody",
                                  [
                                    _c(
                                      "tr",
                                      { staticClass: "bg-gray" },
                                      [
                                        _c("th", { staticClass: "tc" }, [
                                          _vm._v("#"),
                                        ]),
                                        _c("th", { staticClass: "tc mw50" }, [
                                          _vm._v("年份"),
                                        ]),
                                        _c("th", { staticClass: "tc mw50" }, [
                                          _vm._v("月份"),
                                        ]),
                                        _c("th", { staticClass: "tc mw90" }, [
                                          _vm._v("目標種類"),
                                        ]),
                                        _c("th", { staticClass: "tc mw150" }, [
                                          _vm._v("部級目標"),
                                        ]),
                                        _c("th", { staticClass: "tc mw150" }, [
                                          _vm._v("人員目標"),
                                        ]),
                                        _vm._l(
                                          _vm.titles,
                                          function (title, index) {
                                            return [
                                              _vm.hasView
                                                ? _c("th", {
                                                    key: index,
                                                    staticClass:
                                                      "tc mw90 pre-wrap",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        `${
                                                          title.employeeName
                                                        }\n${
                                                          index === 0
                                                            ? "（自評）"
                                                            : title.isManager ===
                                                                "0" &&
                                                              title.deptType.includes(
                                                                "0060"
                                                              )
                                                            ? "（上層主管）"
                                                            : title.isManager ===
                                                                "1" &&
                                                              title.deptType.includes(
                                                                "0060"
                                                              )
                                                            ? "（部門主管）"
                                                            : "（高層主管）"
                                                        }`
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("th", {
                                                key: title.employeeId,
                                                staticClass: "tc mw90 pre-wrap",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    `${
                                                      !_vm.hasView
                                                        ? `${title.employeeName}\n`
                                                        : ""
                                                    }${
                                                      _vm.data.employeeId ===
                                                      title.employeeId
                                                        ? "說明"
                                                        : "考核意見"
                                                    }`
                                                  ),
                                                },
                                              }),
                                            ]
                                          }
                                        ),
                                        _c("th", { staticClass: "tc mw90" }, [
                                          _vm._v("不列入考核"),
                                        ]),
                                        _vm.hasEdit
                                          ? _c(
                                              "th",
                                              { staticClass: "tc mw150" },
                                              [_vm._v("管理")]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm.loading
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "tc",
                                              attrs: {
                                                colspan:
                                                  8 + 2 * _vm.titles.length,
                                              },
                                            },
                                            [_vm._v("查詢中")]
                                          ),
                                        ])
                                      : _vm.datas.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "tc",
                                              attrs: {
                                                colspan:
                                                  8 + 2 * _vm.titles.length,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    尚無設定目標\n                  "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.datas,
                                          function (data, index) {
                                            return _c(
                                              "tr",
                                              { key: index },
                                              [
                                                _c("td", {
                                                  staticClass: "tc",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      index + 1
                                                    ),
                                                  },
                                                }),
                                                _c("td", {
                                                  staticClass: "tc",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.year
                                                    ),
                                                  },
                                                }),
                                                _c("td", {
                                                  staticClass: "tc",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.month
                                                    ),
                                                  },
                                                }),
                                                _c("td", {
                                                  staticClass: "tc",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.define.objectiveType[
                                                        data.objectiveType
                                                      ]
                                                    ),
                                                  },
                                                }),
                                                _c("td", {
                                                  staticClass: "pre-wrap",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.parentObjective
                                                    ),
                                                  },
                                                }),
                                                _c("td", {
                                                  staticClass: "pre-wrap",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.objective
                                                    ),
                                                  },
                                                }),
                                                _vm._l(
                                                  data.scores,
                                                  function (scoreItem, index) {
                                                    return [
                                                      _vm.hasView
                                                        ? _c("td", {
                                                            key: index,
                                                            staticClass: "tc",
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  scoreItem.score
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm.hasView ||
                                                      (!_vm.hasView &&
                                                        index === 0)
                                                        ? _c("td", {
                                                            key: scoreItem.scoreId,
                                                            staticClass:
                                                              "pre-wrap",
                                                            class: [
                                                              scoreItem.note &&
                                                              scoreItem.note
                                                                .length >= 300
                                                                ? "mw300"
                                                                : "mw90",
                                                            ],
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  scoreItem.note
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  }
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "tc" },
                                                  [
                                                    _c("iCheckCheckBox", {
                                                      attrs: {
                                                        propNone: true,
                                                        propChecked:
                                                          data.checked,
                                                        propDisabled:
                                                          data.disabled,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm.hasEdit
                                                  ? _c(
                                                      "td",
                                                      { staticClass: "tc" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showScoreContent(
                                                                  data
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      評分\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  data.objectiveType,
                                                                expression:
                                                                  "data.objectiveType",
                                                              },
                                                            ],
                                                          },
                                                          [_vm._v(" ")]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  data.objectiveType,
                                                                expression:
                                                                  "data.objectiveType",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn btn-danger",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteData(
                                                                  data
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      刪除\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                    _c("div", { staticClass: "box-footer no-border mt-10" }),
                    _c(
                      "div",
                      {
                        staticClass: "table-responsive",
                        staticStyle: { "max-width": "1400px", margin: "auto" },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table table-bordered min-800",
                            staticStyle: { margin: "5px auto" },
                          },
                          [
                            _c(
                              "tbody",
                              [
                                _c("tr", { staticClass: "bg-gray" }, [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "tc mw150",
                                      staticStyle: { width: "180px" },
                                    },
                                    [_vm._v("評分人員")]
                                  ),
                                  _vm.hasView
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "tc mw150",
                                          staticStyle: { width: "150px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    績效分數\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "tc",
                                      class:
                                        _vm.userData.user.employeeId === _vm.id
                                          ? "wp40"
                                          : "wp25",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    說明\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm.hasView &&
                                  _vm.userData.user.employeeId !== _vm.id
                                    ? _c("th", { staticClass: "tc wp25" }, [
                                        _vm._v(
                                          "\n                    給主管說明\n                  "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("th", { staticClass: "tc mw300" }, [
                                    _vm._v("附件"),
                                  ]),
                                  _vm.hasEdit
                                    ? _c("th", { staticClass: "tc mw80" }, [
                                        _vm._v("管理"),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm.loading
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "tc",
                                          attrs: { colspan: "6" },
                                        },
                                        [_vm._v("查詢中")]
                                      ),
                                    ])
                                  : _vm.scoreDatas.length === 0
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "tc",
                                          attrs: { colspan: "6" },
                                        },
                                        [_vm._v("尚無評分人員")]
                                      ),
                                    ])
                                  : _vm._l(
                                      _vm.scoreDatas,
                                      function (scoreData, index) {
                                        return _c("tr", { key: index }, [
                                          _c("td", {
                                            staticClass: "tc",
                                            domProps: {
                                              textContent: _vm._s(
                                                scoreData.createEmpName
                                              ),
                                            },
                                          }),
                                          _vm.hasExcept ||
                                          (_vm.hasEdit &&
                                            _vm.userData.user.employeeId ===
                                              scoreData.createEmpId)
                                            ? _c("td", { staticClass: "tc" }, [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scoreData.totalScore,
                                                      expression:
                                                        "scoreData.totalScore",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control fw-b fz25",
                                                  staticStyle: {
                                                    height: "auto",
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    min: "0",
                                                  },
                                                  domProps: {
                                                    value: scoreData.totalScore,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        scoreData,
                                                        "totalScore",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ])
                                            : _vm.hasView
                                            ? _c("td", {
                                                staticClass: "tc fw-b fz25",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scoreData.totalScore
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.hasExcept ||
                                          (_vm.hasEdit &&
                                            _vm.userData.user.employeeId ===
                                              scoreData.createEmpId)
                                            ? _c("td", { staticClass: "tc" }, [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.trim",
                                                      value: scoreData.note,
                                                      expression:
                                                        "scoreData.note",
                                                      modifiers: { trim: true },
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    rows: "4",
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value: scoreData.note,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        scoreData,
                                                        "note",
                                                        $event.target.value.trim()
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                }),
                                              ])
                                            : _c("td", {
                                                staticClass: "pre-wrap",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scoreData.note
                                                  ),
                                                },
                                              }),
                                          _vm.hasExcept ||
                                          (_vm.hasEdit &&
                                            _vm.userData.user.employeeId !==
                                              _vm.id &&
                                            _vm.userData.user.employeeId ===
                                              scoreData.createEmpId)
                                            ? _c("td", { staticClass: "tc" }, [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.trim",
                                                      value:
                                                        scoreData.privateNote,
                                                      expression:
                                                        "scoreData.privateNote",
                                                      modifiers: { trim: true },
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    rows: "4",
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scoreData.privateNote,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        scoreData,
                                                        "privateNote",
                                                        $event.target.value.trim()
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                }),
                                              ])
                                            : _vm.hasView &&
                                              _vm.userData.user.employeeId !==
                                                _vm.id
                                            ? _c("td", {
                                                staticClass: "pre-wrap",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scoreData.privateNote
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _c("td", { staticClass: "tc" }, [
                                            _vm.hasExcept ||
                                            (_vm.hasEdit &&
                                              _vm.userData.user.employeeId ===
                                                scoreData.createEmpId)
                                              ? _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "btn btn-success",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticStyle: {
                                                        display: "none",
                                                      },
                                                      attrs: {
                                                        id: "inputFile",
                                                        type: "file",
                                                        accept: "*",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeFile(
                                                            $event,
                                                            scoreData
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-upload",
                                                    }),
                                                    _vm._v(
                                                      " 上傳檔案\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "table",
                                              {
                                                staticClass: "table no-border",
                                              },
                                              [
                                                _c(
                                                  "tbody",
                                                  [
                                                    !_vm.hasExcept &&
                                                    (!_vm.hasEdit ||
                                                      _vm.userData.user
                                                        .employeeId !==
                                                        scoreData.createEmpId) &&
                                                    scoreData.files &&
                                                    scoreData.files.length === 0
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass: "tc",
                                                            },
                                                            [_vm._v("－")]
                                                          ),
                                                        ])
                                                      : _vm._l(
                                                          scoreData.files,
                                                          function (
                                                            file,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              { key: index },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "tr",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          target:
                                                                            "_blank",
                                                                          href: file.filesUrl,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              file.name
                                                                            ) +
                                                                            "\n                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.hasExcept ||
                                                                (_vm.hasEdit &&
                                                                  _vm.userData
                                                                    .user
                                                                    .employeeId ===
                                                                    scoreData.createEmpId)
                                                                  ? _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "w90",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-danger",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteFile(
                                                                                    file.applyId,
                                                                                    scoreData
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              刪除\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm.hasExcept || _vm.hasEdit
                                            ? _c("td", { staticClass: "tc" }, [
                                                _vm.hasExcept ||
                                                _vm.userData.user.employeeId ===
                                                  scoreData.createEmpId
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateScoreData(
                                                              scoreData
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      儲存\n                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [_vm._v("－")]),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("BigScoreNote"),
                    _vm.person.level !== 9
                      ? _c(
                          "div",
                          [
                            _c("SignLog", {
                              attrs: {
                                datas: _vm.datas,
                                data: _vm.data,
                                hasEdit: _vm.hasEdit,
                                backList: _vm.backList,
                                scoreDatas: _vm.scoreDatas,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
            _vm.datas.length === 0
              ? _c("div", { staticClass: "box-footer no-border mt-10" })
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("PersonalContent", {
        ref: "personalContent",
        on: { updateList: _vm.getDataList },
      }),
      _c("ScorePersonalContent", {
        ref: "scorePersonalContent",
        on: { updateList: _vm.getDataList },
      }),
      _c("SignCancel", {
        ref: "signCancel",
        attrs: { backList: _vm.backList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }