var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "MeetingRoom" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("會議室")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("場地申請")]),
          _c("li", { staticClass: "active" }, [_vm._v("會議室")]),
        ]),
      ]),
      _c("section", { staticClass: "content meeting-room" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _vm.checkByUserPermission()
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-add",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openMeetingRoomEdit()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" 新增會議室\n          "),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "input-group date" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DatePicker", {
                      model: {
                        value: _vm.meetingDay,
                        callback: function ($$v) {
                          _vm.meetingDay = $$v
                        },
                        expression: "meetingDay",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("目前查看：" + _vm._s(_vm.meetingDay)),
                ]),
              ]),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c("th", [_vm._v("會議室名稱")]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("09:00-10:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("10:00-11:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("11:00-12:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("12:00-13:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("13:00-14:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("14:00-15:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("15:00-16:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("16:00-17:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("17:00-18:00"),
                      ]),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("18:00-19:00"),
                      ]),
                      _vm.checkByUserPermission()
                        ? _c(
                            "th",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: { "min-width": "120px" },
                            },
                            [
                              _vm._v(
                                "\n                  功能\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._l(_vm.meetingRoomList.datas, function (room, index) {
                      return _c(
                        "tr",
                        { key: index },
                        [
                          room.isStop == 1
                            ? _c("td", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(room.roomName) +
                                    " (\n                  "
                                ),
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("暫停使用"),
                                ]),
                                _vm._v(")\n                "),
                              ])
                            : _c("td", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(room.roomName) +
                                    " (\n                  "
                                ),
                                _c("span", { staticClass: "num" }, [
                                  _vm._v(_vm._s(room.seatCount)),
                                ]),
                                _vm._v("人)\n                "),
                              ]),
                          _vm._l(_vm.period, function (item) {
                            return _c("td", {
                              key: item,
                              class: {
                                booking: _vm.checkUserBooked(
                                  room,
                                  item + 8 + "00",
                                  item + 9 + "00"
                                ),
                                disabled: _vm.checkCanBooking(
                                  room,
                                  item + 8,
                                  item + 9
                                ),
                                stopping: room.isStop == 1,
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.checkMeetingTimeBooked(
                                    room.roomId,
                                    item + 8 + "00",
                                    item + 9 + "00"
                                  )
                                ),
                              },
                            })
                          }),
                          _vm.checkByUserPermission()
                            ? _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openMeetingRoomEdit(room)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    修改\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  "\n                   \n                  "
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteMeetingRoom(room)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    刪除\n                  "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._m(2),
          ]),
        ]),
      ]),
      _c("roomBooking", {
        ref: "roomBooking",
        on: { refreshList: _vm.GetMeetingRoomBooked },
      }),
      _c("meetingRoomEdit", {
        ref: "meetingRoomEdit",
        on: { refreshList: _vm.GetMeetingRoomBooked },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-top": "10px" } }, [
      _vm._v(
        "\n          EIP會議室預約系統已鎖定，改由Office 365 outlook 行事曆或TEAMS行事曆進行預約。"
      ),
      _c("br"),
      _c("span", { staticClass: "text-red" }, [
        _vm._v(
          "週期預約功能沒有開放，最多可以預約60天後的會議室超過無法預約。"
        ),
      ]),
      _c("br"),
      _vm._v("\n          O365預約會議室操作SOP ➡ \n          "),
      _c(
        "a",
        {
          attrs: {
            href: "/uploads/Office365版會議室預約操作方式.pdf",
            target: "_blank",
          },
        },
        [_vm._v("鏈接")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red mt-10" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(
        "\n          點擊空白時段即可進行預約或取消，已過的時段無法預約。\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }