// import Vue from 'vue'; 沒使用到
import store from '@/store.js';

class User {
    constructor() {
        this.isInstalled = false;
    }
    install(Vue, options) {
        if (this.isInstalled) return;
        this.isInstalled = true;
        Vue.prototype.$user = this;
    }
    hasPermission(permissions) {
        let userPermissionList = store.state.loginUserPermission;
        let isCheck = false;
        isCheck = permissions.split(",").some(element => userPermissionList.indexOf(element) >= 0);
        return isCheck;
    }
    getUserData() {
        return {
            user: store.state.loginUser,
            deptEmp: store.state.loginDeptEmp,
        };
    }
    getUserCompanyId() {
        return store.state.loginUser.companyId;
    }
    getUserExpatId() {
        let expatId = store.state.loginUser.companyId;
        let mealAssign = store.state.loginMealAssign;
        if (mealAssign && Number(mealAssign.isAssign)) {
            expatId = Number(mealAssign.isLong) ? 'J' : expatId;
        }
        return expatId;
    }
}

export default new User();