<template>
  <div id="RoleSwitching">
    <section class="content-header">
      <h1>帳號切換設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li>帳號管理</li>
        <li class="active">帳號切換設定</li>
      </ol>
    </section>
    <section class="content role-switching">
      <div class="box box-warning">
        <div class="box-body">
          <table class="table table-bordered table-hover dataTable min-800" style="width: 100%;">
            <thead>
              <tr>
                <th>員工編號</th>
                <th>帳號</th>
                <th>姓名</th>
                <th>部門</th>
                <th>職稱</th>
                <th>E-mail</th>
                <th class="text-nowrap text-center" style="min-width: 180px;">功能</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>104001</td>
                <td>aaa.aaa</td>
                <td>李XX</td>
                <td>研發二部(專2)</td>
                <td>工程師</td>
                <td>aaa.aaa@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104002</td>
                <td>bbb.bbb</td>
                <td>陳XX</td>
                <td>研發二部(專2)</td>
                <td>組長</td>
                <td>bbb.bbb@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104003</td>
                <td>ccc.ccc</td>
                <td>陳XX</td>
                <td>研發一部</td>
                <td>組長</td>
                <td>ccc.ccc@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104004</td>
                <td>ddd.ddd</td>
                <td>廖XX</td>
                <td>營運部</td>
                <td>專員</td>
                <td>ddd.ddd@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104005</td>
                <td>eee.eee</td>
                <td>紀XX</td>
                <td>營運設計部</td>
                <td>專員</td>
                <td>eee.eee@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104006</td>
                <td>fff.fff</td>
                <td>盧XX</td>
                <td>客服服務組</td>
                <td>專員</td>
                <td>fff.fff@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104007</td>
                <td>ggg.ggg</td>
                <td>張XX</td>
                <td>人力資源部</td>
                <td>高級專員</td>
                <td>ggg.ggg@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104008</td>
                <td>hhh.hhh</td>
                <td>王XX</td>
                <td>總務部</td>
                <td>專員</td>
                <td>hhh.hhh@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104009</td>
                <td>iii.iii</td>
                <td>莊XX</td>
                <td>行銷部</td>
                <td>專員</td>
                <td>iii.iii@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104010</td>
                <td>jjj.jjj</td>
                <td>蔡XX</td>
                <td>研發三部(專3)</td>
                <td>組長</td>
                <td>jjj.jjj@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104011</td>
                <td>kkk.kkk</td>
                <td>蘇XX</td>
                <td>遊戲維運組</td>
                <td>專員</td>
                <td>kkk.kkk@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
              <tr>
                <td>104012</td>
                <td>lll.lll</td>
                <td>張XX</td>
                <td>董事長室</td>
                <td>特別助理(2)</td>
                <td>lll.lll@wanin.tw</td>
                <td class="text-center">
                  <button class="btn btn-primary" data-target="#role-setting" data-toggle="modal">角色設定</button>
                  &nbsp;
                  <button class="btn btn-danger" data-target="#role-attention" data-toggle="modal">帳號切換</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import Pagination from '../components/Pagination';
import AlertBox from '../components/AlertBox';

export default {
  components: {
    Pagination,
    AlertBox,
  },
};
</script>
