<template>
  <div id="Score">
    <section class="content-header">
      <h1>考核紀錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">考核紀錄</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div
              class="btns"
              style="display: flex; justify-content: space-around"
            >
              <button
                :class="[
                  'btn btn-block btn-add',
                  raterType == 'other' ? 'btn-primary active' : 'btn-default',
                ]"
                type="button"
                @click="raterType = 'other'"
              >
                <span>受評紀錄</span>
                <span
                  id="menuNum"
                  class="menuNum hide"
                  style="margin-left: 15px"
                  v-if="okrNotice.receiveCount != 0"
                >
                  {{ okrNotice.receiveCount }}
                </span>
              </button>
              <button
                :class="[
                  'btn btn-block btn-add',
                  raterType == 'self' ? 'btn-primary active' : 'btn-default',
                ]"
                type="button"
                @click="raterType = 'self'"
              >
                <span>考評紀錄</span>
                <span
                  id="menuNum"
                  class="menuNum hide"
                  style="margin-left: 15px"
                  v-if="okrNotice.addCount != 0"
                >
                  {{ okrNotice.addCount }}
                </span>
              </button>
            </div>
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="getDataList()"
                  style="margin-left: 0"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="getDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>

                <select
                  class="form-control"
                  v-model="searchData.QueryDeptId"
                  @change="getDataList()"
                  v-if="loginUserPermission.indexOf('C0-102A') > -1"
                >
                  <option value="">請選擇部別</option>
                  <option
                    v-for="(item, index) in deptList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw50">年分</th>
                  <th class="tc mw50">月份</th>
                  <th class="tc mw100">部門</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw100" v-if="raterType == 'other'">時間</th>
                  <template v-if="raterType == 'self' || raterType == 'read'">
                    <th class="tc mw50">加分</th>
                  </template>
                  <th class="tc mw150">說明</th>
                  <template v-if="raterType == 'self' || raterType == 'read'">
                    <th class="tc mw100">狀態</th>
                    <th class="tc mw150">送簽時間</th>
                  </template>
                  <th
                    class="tc mw100"
                    v-if="
                      loginUserPermission.indexOf('C0-102A') > -1 ||
                      raterType == 'self' ||
                      raterType == 'read'
                    "
                  >
                    管理
                  </th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="10">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="10">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(data, index) in list" :key="index">
                  <td class="tc">
                    <template v-if="data.isEdit">
                      <select
                        class="form-control w90"
                        v-model="data.year"
                        style="margin-left: 0"
                      >
                        <option
                          v-for="(year, index) in years"
                          :key="index"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </template>
                    <template v-else>{{ data.year }}</template>
                  </td>
                  <td class="tc">
                    <template v-if="data.isEdit">
                      <select class="form-control w90" v-model="data.month">
                        <option value="0">上半年</option>
                        <option value="1">下半年</option>
                      </select>
                    </template>
                    <template v-else>
                      {{ define.halfYearStatus[data.month] }}
                    </template>
                  </td>
                  <template v-if="raterType == 'other'">
                    <td class="tc">{{ data.createDeptName }}</td>
                    <td class="tc">{{ data.createEmpId }}</td>
                    <td class="tc">{{ data.createEmpName }}</td>
                    <td class="tc">
                      {{ data.createTime || dateFormat("YYYY/MM/DD hh:mm") }}
                    </td>
                  </template>
                  <template v-if="raterType == 'self' || raterType == 'read'">
                    <td class="tc">{{ data.deptName }}</td>
                    <td class="tc">{{ data.employeeId }}</td>
                    <td class="tc">{{ data.employeeName }}</td>
                    <td class="tc">{{ data.totalScore }}</td>
                  </template>
                  <td class="tc">{{ data.note }}</td>
                  <template v-if="raterType == 'self' || raterType == 'read'">
                    <td class="tc">
                      {{ define.sendStatus[data.scoreStatus] }}
                    </td>
                    <td class="tc">
                      {{
                        new Date(data.createTime)
                          | dateFormat("YYYY/MM/DD HH:mm:ss")
                      }}
                    </td>
                    <td
                      class="tc"
                      :style="
                        data.scoreStatus == 0 ||
                        (loginUserPermission.indexOf('C0-102A') > -1 &&
                          raterType == 'read')
                          ? 'min-width: 140px;'
                          : ''
                      "
                    >
                      <button
                        class="btn btn-primary mr-10"
                        @click="updateData(data)"
                      >
                        檢視
                      </button>
                      <button
                        class="btn btn-danger"
                        @click="deleteData(data)"
                        v-if="data.scoreStatus == 0"
                      >
                        刪除
                      </button>
                      <button
                        class="btn"
                        :class="data.isEdit ? 'btn-success' : 'btn-purple'"
                        @click="editItem(data)"
                        v-if="
                          loginUserPermission.indexOf('C0-102A') > -1 &&
                          raterType == 'read'
                        "
                      >
                        {{ data.isEdit ? "儲存" : "編輯" }}
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-if="total > 0">
          <Pagination
            :total="total"
            ref="pagination"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      searchData: {
        QueryDeptId: "",
        pageNum: 1,
        pageSize: 10,
        year: this.$route.params.year || this.global.getNowHalfYear(),
        month: this.$route.params.month || this.global.getHalfYear(),
      },
      years: this.global.getYearList(true),
      raterType: this.$route.params.listType || "other",
      list: [],
      deptList: [],
      okrNotice: [],
      loginUserPermission: [],
      total: 0,
    };
  },
  watch: {
    raterType(val) {
      if (val != "read") this.searchData.QueryDeptId = "";
      this.$forceUpdate();
      this.getOkrNotice();
      this.getDataList();
    },
  },
  mounted() {
    if (this.$route.params.searchData) {
      this.searchData = this.$route.params.searchData;
    }
    this.loginUserPermission = this.$store.state.loginUserPermission;
    this.getOkrNotice();
    this.getDataList();
    this.getList();
  },
  methods: {
    getDataList() {
      if (this.searchData.QueryDeptId != "") {
        this.raterType = "read";
        api.okr.v2.rater
          .DeptHistoryQueryAll(this.searchData)
          .then(({ data }) => {
            this.loading = false;
            this.list = data.result.datas;
            this.total = data.result.total;
            this.$forceUpdate();
          });
      } else {
        const action = this.raterType == "other" ? "queryReceive" : "queryAdd";
        api.okr.v2.history[action](this.searchData).then(({ data }) => {
          this.loading = false;
          this.list = data.result.datas;
          this.total = data.result.total;
          this.$forceUpdate();
        });
      }
    },
    getList() {
      api.okr.goal.department.list({ deptLevel: 0 }).then((res) => {
        if (res.data.status === "1") {
          this.deptList = res.data.result || [];
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateData(item) {
      this.$router.push({
        name: "ScoreEdit",
        params: {
          id: item.employeeId,
          year: this.searchData.year,
          month: this.searchData.month,
          formId: item.scoreGroupId,
          formType: item.formTypeId,
          back: "ScoreList",
          listType: this.searchData.QueryDeptId != "" ? "read" : this.raterType,
          data: item,
          searchData: this.searchData,
        },
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    deleteData(item) {
      this.$eventBus.$emit("showConfirm", `確定要刪除嗎?`, (isOK) => {
        if (isOK) {
          api.okr.score.min
            .groupDelete({ ScoreGroupId: item.scoreGroupId })
            .then((res) => {
              if (res.data.status == "1") {
                this.global.showResponse(this, res, "delete");
                this.resetDataList();
              }
            });
        }
      });
    },
    getOkrNotice() {
      api.okr.v2.rater.notice().then((res) => {
        this.$store.commit("setOkrNotice", res.data.result);
        this.okrNotice = this.$store.state.okrNotice;
        $("#Score .menuNum").removeClass("hide");
      });
    },

    editItem(item) {
      if (item.isEdit) {
        if (item.beforeYear == item.year && item.beforeMonth == item.month) {
          item.isEdit = false;
          this.$forceUpdate();
          return;
        }

        api.okr.v2.rater
          .updateRaterMonth({
            ScoreGroupId: item.scoreGroupId,
            year: item.year,
            month: item.month,
          })
          .then(({ data }) => {
            if (data.status == 1) {
              item.isEdit = false;
              this.$forceUpdate();
              this.getDataList();
            } else {
              if (data.message) {
                this.$eventBus.$emit("showAlert", data.message);
              }
            }
          });
      } else {
        item.isEdit = true;
        item.beforeYear = item.year;
        item.beforeMonth = item.month;
        this.$forceUpdate();
      }
    },
  },
};
</script>