var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Announcement" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.announcementtypes.typeName) + " - 公告內容"),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公司公告")]),
        _c("li", [_vm._v(_vm._s(_vm.announcementtypes.typeName))]),
        _c("li", { staticClass: "active" }, [_vm._v("公告內容")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h4", { staticClass: "h4" }, [
              _vm._v(_vm._s(_vm.announcement.title)),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticStyle: { width: "10%" } }, [
                    _vm._v("發布日期"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.announcement.publishTime))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("公告文號")]),
                  _c("td", [_vm._v(_vm._s(_vm.announcement.docNo))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("公告單位")]),
                  _c("td", [_vm._v(_vm._s(_vm.announcement.issuedUnitName))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("公告聯絡人")]),
                  _c("td", [_vm._v(_vm._s(_vm.announcement.contactEmpName))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("內容")]),
                  _c("td", [
                    _c("div", {
                      staticClass: "text",
                      domProps: { innerHTML: _vm._s(_vm.announcementContents) },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("附件")]),
                  _c(
                    "td",
                    _vm._l(_vm.files, function (file) {
                      return _c(
                        "a",
                        {
                          key: file.id,
                          staticClass: "file-icon col-sm-1",
                          attrs: {
                            href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                            title: file.name,
                          },
                        },
                        [
                          _c("i", {
                            class: "icon icon-" + file.mine.substring(0, 3),
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default mt-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.goBack()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }