<template>
  <div id="OrderStatistics">
    <section class="content-header">
      <h1>訂單統計</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">訂單統計</li>
      </ol>
    </section>
    <section class="content order-statistics">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <div class="group-left">
              <div class="radio-inline">
                <iCheckRedioButton checkValue="1" message="午餐" name="radioMode" v-model="eatTime" />
              </div>
              <div class="radio-inline">
                <iCheckRedioButton checkValue="2" message="晚餐" name="radioMode" v-model="eatTime" />
              </div>
              <div class="btns">
                <button @click="changeWeek(-1)" class="btn btn-default" type="button">上週</button>
                <button @click="changeWeek(0)" class="btn btn-warning" type="button">本週</button>
                <button @click="changeWeek(1)" class="btn btn-default" type="button">下週</button>
              </div>
            </div>
            <div class="group-right">
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DatePicker v-model="dateTime" />
                </div>
              </div>
              <button @click="exportExcel()" class="btn btn-primary" type="button">
                <i class="fa fa-download"></i> 匯出
              </button>
            </div>
          </div>
          <div :key="index" class="table-responsive mt-10" v-for="(item, index) in statisticsList">
            <table class="table table-hover table-bordered min-600">
              <tbody>
                <tr>
                  <th
                    class="bg-gray"
                    colspan="5"
                  >{{$utils.formatTime(item.eatDay, "YYYY/MM/DD")}} 【週{{$utils.getWeekDay(item.eatDay)}}】</th>
                </tr>
                <tr>
                  <td>店家名稱</td>
                  <td>菜單名稱</td>
                  <td class="text-right">售價</td>
                  <td class="text-right">數量</td>
                  <td class="text-right">小計</td>
                </tr>
                <tr :key="index" v-for="(meal, index) in item.meals">
                  <td>{{meal.storeName}}</td>
                  <td>{{meal.mealName}}</td>
                  <td class="text-right">{{meal.price}}</td>
                  <td class="text-right">{{meal.eatCount}}</td>
                  <td class="text-right">{{meal.price*meal.eatCount}}</td>
                </tr>
                <tr>
                  <td class="text-right" colspan="5">總計：{{item.totalCost}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import moment from 'moment';
import iCheckRedioButton from '@/components/iCheckRedioButton';

export default {
  components: {
    DatePicker,
    iCheckRedioButton
  },
  data() {
    return {
      eatTime: 1,
      statisticsList: {},
      eatStartDay: new moment().startOf('isoWeek').format('YYYY/MM/DD'),
      eatEndDay: new moment().endOf('isoWeek').format('YYYY/MM/DD'),
      dateTime: new moment().format('YYYY/MM/DD')
    };
  },
  created() {
    this.getMealTotal();
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: 'icheckbox_flat-blue',
      radioClass: 'iradio_flat-blue'
    });
  },
  watch: {
    eatTime() {
      this.getMealTotal();
    },
    dateTime(value) {
      this.dateTime = value;
    }
  },
  methods: {
    getMealTotal() {
      api.mealManage
        .queryMealTotal({
          eatStartDay: this.eatStartDay,
          eatEndDay: this.eatEndDay,
          eatTime: this.eatTime
        })
        .then(res => {
          if (res.data.status == '1') {
            this.statisticsList = res.data.result;
            this.statisticsList.forEach(element => {
              element.meals.forEach(res => {
                element.totalCost += res.price * res.eatCount;
              });
            });
          }
        });
    },
    changeWeek(conut) {
      this.eatStartDay = new moment()
        .add(conut, 'weeks')
        .startOf('isoWeek')
        .format('YYYY/MM/DD');
      this.eatEndDay = new moment()
        .add(conut, 'weeks')
        .endOf('isoWeek')
        .format('YYYY/MM/DD');

      this.getMealTotal();
    },
    exportExcel() {
      api.mealManage
        .queryMealTotal({
          eatStartDay: this.dateTime,
          eatEndDay: this.dateTime,
          eatTime: this.eatTime
        })
        .then(res => {
          let data = [];
          if (res.data.status == '1' && res.data.result.length != 0) {
            res.data.result[0].meals.forEach(res => {
              data.push({
                店家名稱: res.storeName,
                菜單名稱: res.mealName,
                售價: res.price,
                數量: res.eatCount,
                小計: res.eatCount * res.price
              });
            });
            utils.exportData(data, this.dateTime + '.xlsx');
          } else {
            this.$eventBus.$emit('showAlert', '查無資料!!');
            return;
          }
        });
    }
  }
};
</script>
