var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "receptionEdit", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("CKEditor", {
              ref: "CKEditor",
              attrs: { content: _vm.areaRuler.roomInfo },
              on: { onContentChagne: _vm.onEditorContentChange },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.saveReceptionArea()
                },
              },
            },
            [_vm._v("儲存")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("取消")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 修改使用規則"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }