var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$route.params.name) + "-章節管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", [_vm._v("課程管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("章節管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add mr-10",
                staticStyle: { "min-width": "auto" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" 新增章節\n          "),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(1)]
                      : _vm.list.length == 0
                      ? [_vm._m(2)]
                      : _vm._l(_vm.list, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "td-sort text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.chapterOrder,
                                    expression: "item.chapterOrder",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  disabled: item.chapterStatus != "1",
                                },
                                domProps: { value: item.chapterOrder },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "chapterOrder",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              item.chapterStatus == "1"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning btn-upDate",
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeOrder(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      更新\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.chapterName))]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.createEmpName)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.chapterTime)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("dateFormat")(item.createTime)
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    item.chapterStatus == "1" ? "啟用" : "停用"
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      編輯\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      刪除\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  排序\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "15%" },
          },
          [_vm._v("\n                  章節名稱\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "8%" },
          },
          [_vm._v("\n                  建立者\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "8%" },
          },
          [_vm._v("\n                  時數(分鐘)\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "10%" },
          },
          [_vm._v("\n                  建立時間\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "8%" },
          },
          [_vm._v("\n                  狀態\n                ")]
        ),
        _c(
          "th",
          {
            staticClass: "text-nowrap text-center",
            staticStyle: { width: "15%" },
          },
          [_vm._v("\n                  功能\n                ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("查無資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }