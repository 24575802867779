<template>
  <div id="ScoreHistoryAll"></div>
</template>

<script>
export default {
  created() {
    this.$router.push({
      name: "ScoreHistoryList",
      params: {
        type: this.$route.params.type || "Personal",
      },
    });
  },
};
</script>
