<template>
  <div id="AlertBox">
    <!-- 刪除提醒 -->
    <div class="modal fade" id="attention" style="z-index: 999">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              id="attention-close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i>
              {{ title }}
            </h4>
          </div>
          <div class="modal-body">
            <p id="attention-message" v-html="message"></p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" id="attention-delete" type="button">
              確定
            </button>
            <button
              class="btn btn-default pull-right"
              id="attention-dismiss"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!--預定會議室-->
    <div class="modal fade" id="booking">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">預約會議室</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive mt-10">
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td>會議室名稱</td>
                    <td>28F-B1</td>
                  </tr>
                  <tr>
                    <td>設備</td>
                    <td>主機(內網)、主機(外網)、投影機</td>
                  </tr>
                  <tr>
                    <td>座位</td>
                    <td>6人</td>
                  </tr>
                  <tr>
                    <td>會議日期</td>
                    <td>2019/07/01</td>
                  </tr>
                  <tr>
                    <td>會議時段</td>
                    <td class="form-inline">
                      <div class="form-group">
                        <select class="form-control">
                          <option>09:00</option>
                        </select>
                      </div>
                      至
                      <div class="form-group">
                        <select class="form-control">
                          <option>10:00</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>預約人</td>
                    <td>星寶</td>
                  </tr>
                  <tr>
                    <td>會議主題</td>
                    <td>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">確定預約</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!--新增(修改)會議室-->
    <div class="modal fade" id="edit-room">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">新增(修改)會議室</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive mt-10">
              <form>
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>會議室名稱</td>
                      <td>
                        <input
                          class="form-control"
                          required="required"
                          type="text"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>設備</td>
                      <td>
                        <div class="form-group">
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 投影機
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 主機(內網)
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 主機(外網)
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 白板
                            </label>
                          </div>
                        </div>
                        <p class="text-orange">
                          <i class="fa fa-warning"></i>
                          如需新增設備請洽系統開發人員。
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>座位(人)</td>
                      <td>
                        <div class="form-group">
                          <!-- has-error -->
                          <input
                            class="form-control"
                            max="100"
                            min="1"
                            required="required"
                            type="number"
                          />
                          <span class="help-block">不得為空</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>可預約的會議時段</td>
                      <td>
                        <div class="form-group">
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 09:00-10:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 10:00-11:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 11:00-12:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 12:00-13:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 13:00-14:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 14:00-15:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 15:00-16:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 16:00-17:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 17:00-18:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 18:00-19:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 19:00-20:00
                            </label>
                          </div>
                          <div class="checkbox">
                            <label>
                              <input class="flat" type="checkbox" /> 20:00-21:00
                            </label>
                          </div>
                        </div>
                        <p class="text-orange">
                          <i class="fa fa-warning"></i>
                          如需新增時段請洽系統開發人員。
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">
              確定新增(修改)
            </button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!--預定交誼廳-->
    <div class="modal fade" id="booking-area">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">預約交誼廳</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive mt-10">
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td>活動日期</td>
                    <td>2019/07/01</td>
                  </tr>
                  <tr>
                    <td>會議時段</td>
                    <td class="form-inline">
                      <div class="form-group">
                        <select class="form-control">
                          <option>18:00</option>
                        </select>
                      </div>
                      至
                      <div class="form-group">
                        <select class="form-control">
                          <option>19:00</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>預約人</td>
                    <td>星寶</td>
                  </tr>
                  <tr>
                    <td>活動主題</td>
                    <td>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>參與人數</td>
                    <td>
                      <input
                        class="form-control"
                        max="100"
                        min="1"
                        required="required"
                        type="number"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">確定預約</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- 新增群組 -->
    <div class="modal fade" id="add-group">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">新增(修改)群組</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputGroupName">
                  <span class="text-red">*</span> 群組名稱：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputGroupName"
                    required="required"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  class="col-sm-3 control-label"
                  for="inputGroupDescription"
                >
                  <span class="text-red">*</span> 群組描述：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputGroupDescription"
                    required="required"
                    type="text"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button">新增(修改)</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- 新增功能 -->
    <div class="modal fade" id="add-features">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">新增(修改)功能</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputFeaturesID">
                  <span class="text-red">*</span> 功能代號：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputFeaturesID"
                    required="required"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputFeaturesName">
                  <span class="text-red">*</span> 功能名稱：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputFeaturesName"
                    required="required"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputFeaturesNum">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputFeaturesNum"
                    required="required"
                    type="text"
                  />
                  <div class="checkbox">
                    <label class="text-muted">
                      <input class="flat" type="checkbox" /> 為網頁MENU
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputFeaturesPath">
                  <span class="text-red">*</span> 頁面路徑：
                </label>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    id="inputFeaturesPath"
                    required="required"
                    type="text"
                  />
                  <p class="help-block">※ 例如：/Announcements</p>
                  <div class="checkbox">
                    <label class="text-muted">
                      <input class="flat" type="checkbox" /> 開放設定
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button">新增(修改)</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- 帳號切換提醒 -->
    <div class="modal fade" id="role-attention">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> 注意
            </h4>
          </div>
          <div class="modal-body">
            <p>您確定要切換成此帳號？</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button">確定</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- 角色設定 -->
    <div class="modal fade" id="role-setting">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">[aaa.aaa] 角色設定</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputGroupName">
                  <span class="text-red">*</span> 選擇角色：
                </label>
                <div class="col-sm-9">
                  <div class="radio">
                    <label>
                      <input class="flat" id="role1" name="role" type="radio" />
                      系統管理員
                      <i class="fa fa-long-arrow-right"></i>
                      僅權限管理不能使用，其他都能用
                    </label>
                  </div>
                  <div class="radio">
                    <label>
                      <input class="flat" id="role2" name="role" type="radio" />
                      SystemAdmin
                      <i class="fa fa-long-arrow-right"></i>
                      系統開發人員管理角色
                    </label>
                  </div>
                  <button class="btn btn-warning mt-10" type="button">
                    <i class="fa fa-arrow-down"></i> 新增
                  </button>
                </div>
              </div>
            </form>
            <div class="table-responsive mt-10">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th>角色名稱</th>
                    <th>角色敘述</th>
                    <th class="text-nowrap text-center" style="min-width: 90px">
                      功能
                    </th>
                  </tr>
                  <tr>
                    <td>SystemAdmin</td>
                    <td>系統開發人員管理角色</td>
                    <td class="text-center">
                      <button
                        class="btn btn-danger"
                        data-target="#attention"
                        data-toggle="modal"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button">儲存</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!--本月新進同仁-->
    <div class="modal fade newcomers-info" id="newcomers-info">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">廖OO</h4>
          </div>
          <div class="modal-body">
            <div class="pic jqimgFill">
              <img draggable="false" src="../assets/user-160x160.jpg" />
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <th>單位</th>
                    <th>工號</th>
                    <th>職等</th>
                    <th>職稱</th>
                    <th>報到日期</th>
                  </tr>
                  <tr>
                    <td>企劃處-產品企劃部</td>
                    <td>104744</td>
                    <td>2</td>
                    <td>企劃師</td>
                    <td>2019/07/01</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import DatePicker from "../components/DatePicker";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      title: "",
      message: "",
      issueTargets: [],
      files: [],
      hasEditPermission: false,
      searchDatas: {
        pageNum: 1,
        pageSize: 20,
        typeId: "-1",
        target: "",
        keyword: "",
      },
    };
  },
  mounted() {
    // console.log("Alert box mounted");
    // imgLiquid
    $(".jqimgFill").imgLiquid();

    // Timepicker
    $(".timepicker").timepicker({
      showInputs: false,
    });

    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });
    // 複選下拉選單
    // $('.select2').select2();
    // //给select 添加 select2tags。如下初始化
    // $('.select2tags').select2({
    //   tags: true, //允许手动输入，生成标签
    //   tokenSeparators: [',', ';', '，', '；', ' '],
    //   width: '100%',
    //   maximumSelectionSize: 5,
    //   language: {
    //     noResults: function(params) {
    //       return '按ENTER即可新增';
    //     }
    //   },
    //   createTag: function(params) {
    //     //解决部分浏览器开启 tags: true 后无法输入中文的BUG
    //     if (/[,;，； ]/.test(params.term)) {
    //       //支持【逗号】【分号】【空格】结尾生成tags
    //       var str = params.term.trim().replace(/[,;，；]*$/, '');
    //       return { id: str, text: str };
    //     } else {
    //       return null;
    //     }
    //   }
    // });
    //解决输入中文后无法回车结束的问题。
    $(document).on(
      "keyup",
      ".select2-selection--multiple .select2-search__field",
      function (event) {
        if (event.keyCode == 13) {
          var $this = $(this);
          var optionText = $this.val();
          //如果没有就添加
          if (
            optionText != "" &&
            $this.find("option[value='" + optionText + "']").length === 0
          ) {
            //我还不知道怎么优雅的定位到input对应的select
            var $select = $this
              .parents(".select2-container")
              .prev(".select2tags");
            var newOption = new Option(optionText, optionText, true, true);
            $select.append(newOption).trigger("change");
            $this.val("");
          }
        }
      }
    );

    // 多功能表格
    $(".dataTable").dataTable({
      language: {
        //"url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Chinese-traditional.json"
        processing: "處理中...",
        loadingRecords: "載入中...",
        lengthMenu: "顯示 _MENU_ 項結果",
        zeroRecords: "沒有符合的結果",
        info: "顯示第 _START_ 至 _END_ 項結果，共 _TOTAL_ 項",
        infoEmpty: "顯示第 0 至 0 項結果，共 0 項",
        infoFiltered: "(從 _MAX_ 項結果中過濾)",
        infoPostFix: "",
        search: "搜尋:",
        paginate: {
          first: "第一頁",
          previous: "上一頁",
          next: "下一頁",
          last: "最後一頁",
        },
        aria: {
          sortAscending: ": 升序排列",
          sortDescending: ": 降序排列",
        },
      },
      scrollX: true,
    });

    // 彈窗同時顯示Modal
    $(document).on("show.bs.modal", ".modal", function () {
      let alreadyHasMast = $(this).find('.modal-mask').length > 0;
      if (!alreadyHasMast) {
        $(this).append(`<div class="modal-mask" data-dismiss="modal"></div>`);
      }
      $(".modal-backdrop").hide();
    });

    this.$eventBus.$on("showAlert", this.showAlert);
    this.$eventBus.$on("showConfirm", this.showConfirm);
  },
  methods: {
    showAlert(message, title) {
      this.message = message;
      this.title = title == undefined ? "提示" : title;
      $("#attention-delete").hide();
      this.listenAlertBoxClick();
      $("#attention").modal("show");
    },
    showConfirm(message, resultCallback, title, hiddenDismiss = false) {
      this.message = message;
      this.title = title == undefined ? "提示" : title;
      $("#attention-delete").show();
      if (hiddenDismiss) {
        $("#attention-dismiss").hide();

        $("#attention-close").click(function () {
          if (resultCallback) resultCallback(false);
        });
      } else $("#attention-dismiss").show();

      this.listenAlertBoxClick();

      $("#attention-delete").click(function () {
        setTimeout(() => {
          if (resultCallback) resultCallback(true);
        }, 300);
      });

      $("#attention-dismiss").click(function () {
        if (resultCallback) resultCallback(false);
      });

      $("#attention").modal("show");
    },
    listenAlertBoxClick() {
      $("#attention-delete").click(function () {
        // console.log("attention-delete click");
        $("#attention-dismiss").off("click");
        $("#attention-delete").off("click");
        $("#attention").modal("hide");
      });
      $("#attention-dismiss").click(function () {
        // console.log("attention-dismiss click");
        $("#attention-dismiss").off("click");
        $("#attention-delete").off("click");
        $("#attention").modal("hide");
      });
    },
  },
  destroyed() {
    this.$eventBus.$off("showAlert", this.showAlert);
    this.$eventBus.$off("showConfirm", this.showConfirm);
  },
};
</script>