<template>
  <div id="Shop">
    <section class="content-header">
      <h1>特約商店區域</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">特約商店區域</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="請輸入關鍵字"
                type="text"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc">區域ID</th>
                  <th class="tc">區域名稱</th>
                  <th class="tc">排序</th>
                  <th class="tc">啟用狀態</th>
                  <th class="tc">功能</th>
                </tr>
                <!-- <tr v-if="loading">
                  <td class="tc" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="7">查詢無任何資料</td>
                </tr> -->
                <tr v-for="(data, index) in 5">
                  <td class="tc">台中</td>
                  <td class="tc">台中</td>
                  <td class="tc">1</td>
                  <td class="tc">啟用</td>
                  <td class="tc">
                    <button
                      class="btn btn-primary mr-10"
                    >
                      修改
                    </button>   
                    <button
                      class="btn btn-danger"
                    >
                      刪除
                    </button>                 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  delimiters: ["${", "}"],
  name: "app",
  data() {},
  mounted() {},
  methods: {
    showDetail() {
      this.$router.push({
        name: 'ShopDetail'
      })
    }
  },
}
</script>