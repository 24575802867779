<template>
  <div class="modal fade" ref="xinStarsContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ !searchData.oldCellphone ? '新增' : '修改'}}</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label">
                      門號：
                    </td>
                    <td>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                        v-model="searchData.EmpCellphone"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      備註：
                    </td>
                    <td>
                      <input
                        class="form-control"
                        type="text"
                        v-model="searchData.Note"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">
              {{ !searchData.oldCellphone ? '新增' : '修改'}}
            </button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      phone: '',
      searchData: {
        EmployeeId: '',
        EmployeeName: '',
        EmpCellphone: '',
        oldCellphone: '',
        Note: '',
      }
    };
  },
  methods: {
    async showContent(data) {
      this.searchData.EmployeeId = data.employeeId
      this.searchData.EmployeeName = data.employeeName
      this.searchData.EmpCellphone = data.empCellphone
      this.searchData.oldCellphone = data.empCellphone
      this.searchData.Note = data.note
      $(this.$refs.xinStarsContent).modal("show");
    },
    submit() {
      let action = this.searchData.oldCellphone ? 'update' : 'create'
      api.xinStarts[action](this.searchData).then((res) => {
        $(this.$refs.xinStarsContent).modal("hide");
        this.$parent.resetDataList()
        this.global.showResponse(this, res, action);
      })
    },
  },
};
</script>