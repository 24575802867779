<template>
  <div id="ActionWorkSystem">
    <section class="content-header">
      <h1>移動式作業人員設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>線上點名</li>
        <li class="active">移動式作業人員設定</li>
      </ol>
    </section>

    <section class="content order-management">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box box-header with-border">
            <div class="date-group">
              <!-- <DateRangePicker
                v-bind:propEndDate.sync="searchData.endDate"
                v-bind:propStartDate.sync="searchData.startDate"
              />-->
              <DatePicker v-model="searchData.startDate" :propDisplay="true" />
            </div>
            <div class="date-group">
              <!-- <DateRangePicker
                v-bind:propEndDate.sync="searchData.endDate"
                v-bind:propStartDate.sync="searchData.startDate"
              />-->
              <DatePicker v-model="searchData.endDate" :propDisplay="true" />
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋員工編號"
                type="text"
                v-model="searchData.employeeId"
              />
              <div class="input-group-btn">
                <button v-on:click="onSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回線上點名
            </button>
          </div>

          <form class="form-horizontal batchCancelOrder clearfix">
            <div class="form-group name-group">
              <label class="control-label">名單匯入：</label>
              <div class="right">
                <input id="batchCancelOrder" ref="batchCancelOrder" type="file" />
              </div>
            </div>
            <button
              @click="readExcel()"
              class="btn btn-danger btn-import mr-10 mr-0-w600"
              type="button"
            >確定匯入</button>
            <a
              href="/uploads/行動作業人員範例.xlsx"
              class="btn btn-success btn-example mr-10 mr-0-w600"
              type="button"
              download
            >名單範例</a>

            <button
              @click="RollCallExportExcel()"
              class="btn btn-info btn-import mr-10 mr-0-w600"
              type="button"
            >點名結果匯出</button>
            <button
              @click="exportExcel()"
              class="btn btn-info btn-import mr-10 mr-0-w600"
              type="button"
            >名單匯出</button>
          </form>

          <div class="top-box">
            <form class="form-horizontal">
              <button @click="addType()" class="btn btn-block btn-warning btn-add" type="button">
                <i class="fa fa-plus"></i> 新增人員
              </button>
              <button
                @click="onSelectDelete()"
                class="btn btn-block btn-danger btn-add mr-10 mr-0-w600"
                type="button"
              >
                <i class="fa fa-trash"></i> 批次刪除
              </button>
            </form>
            <label>顯示筆數 ：</label>
            <select v-model="searchData.pageSize" @change="onSearch">
              <option value="9999">全部</option>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
            <p class="data-num" v-text="'資料總筆數：'+total"></p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 20;" width="1%">
                    <input type="checkbox" id="selectAll" @click="selectAll" />
                  </th>
                  <th style="min-width: 120px;" width="10%">部門</th>
                  <th style="min-width: 120px;" width="10%">工號</th>
                  <th style="min-width: 120px;" class="text-nowrap" width="10%">姓名</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 50%;">執行區間</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <template v-if="actionworks.length > 0">
                  <tr :key="index" v-for="(item, index) in actionworks">
                    <td>
                      <input type="checkbox" :value="item" v-model="selectDelete" />
                    </td>
                    <td>{{ item.deptName }}</td>
                    <td>
                      <p
                        :id="'name'+item.id"
                        class="m-0"
                        v-show="item.editor==0"
                      >{{ item.employeeId }}</p>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                        v-model="item.employeeId"
                        v-show="item.editor!=0"
                      />
                    </td>
                    <td>{{ item.employeeName }}</td>
                    <td class="text-center">
                      <p
                        class="m-0"
                        v-show="item.editor==0"
                      >{{formatTime( item.startDate) }} ~ {{formatTime(item.endDate)}}</p>
                      <DateRangePicker
                        v-bind:propEndDate.sync="item.endDate"
                        v-bind:propStartDate.sync="item.startDate"
                        v-show="item.editor==1"
                      />
                    </td>
                    <td class="text-center">
                      <button
                        @click="change(item)"
                        class="btn btn-primary"
                        v-show="item.editor==0"
                      >修改</button>
                      <button
                        @click="save(item)"
                        class="btn btn-success btn-save"
                        v-show="item.editor!=0"
                      >儲存</button>
                      &nbsp;
                      <button
                        @click="deleteType(item, index)"
                        class="btn btn-danger"
                        v-show="item.editor==0"
                      >刪除</button>
                      <button
                        @click="cancel(item, index)"
                        class="btn btn-default"
                        v-show="item.editor!=0"
                      >取消</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="6">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div class="box-footer text-center no-border" v-show="total > 0">
            <Pagination
              ref="pagination"
              :total="total"
              :pageLimitCount="searchData.pageSize"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
      </div>

      <AddActionWork ref="editDialog" v-on:refresh="onSearch()" />
    </section>
  </div>
</template>

<script>
import api from "../assets/js/app-api";
import DateRangePicker from "@/components/DateRangePicker";
import AddActionWork from "@/views/AddActionWork";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import xlsx from "xlsx";
import config from "@/assets/js/app-config";
import moment from "moment";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    DateRangePicker,
    Pagination,
    DatePicker,
    AddActionWork
  },
  delimiters: ["${", "}"],
  data() {
    return {
      hasEditPermission: false,
      actionworks: [],
      actionworkTemp: [],
      selectDelete: [],
      total: 0, //總筆數
      searchData: {
        employeeId: "",
        pageSize: 10,
        pageNum: 1,
        startDate: new moment().format("YYYY-MM-DD"),
        endDate: new moment().add(1, "years").format("YYYY-MM-DD")
      }
    };
  },
  created() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.ActionWorkSystem
    );
    if (!this.hasEditPermission) {
      this.backList();
      return;
    }
  },
  mounted() {
    this.workShowAll();
  },
  methods: {
    backList() {
      this.$router.push({
        name: "RollCallSystem"
      });
      return;
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.workShowAll();
    },
    onSearch() {
      this.searchData.pageNum = 1;
      this.workShowAll();
    },
    // 顯示列表
    async workShowAll() {
      this.searchData.startDate = utils.formatTime(
        this.searchData.startDate,
        "YYYY-MM-DD 00:00:00"
      );
      this.searchData.endDate = utils.formatTime(
        this.searchData.endDate,
        "YYYY-MM-DD 23:59:59"
      );
      var task1 = api.actionwork.queryAll(this.searchData);
      var response = await task1;
      var tmp = response.data.result.datas;
      this.total = response.data.result.total;
      for (var i = 0; i < tmp.length; i++) {
        tmp[i]["editor"] = 0;
      }
      this.actionworkTemp = JSON.parse(JSON.stringify(tmp));
      this.actionworks = tmp;
      // console.log(response.data.result);
      this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    selectAll() {
      if ($("#selectAll").prop("checked")) {
        this.selectDelete = this.actionworks;
      } else {
        this.selectDelete = [];
      }
    },

    onSelectDelete() {
      if (this.selectDelete.length == 0) {
        this.$eventBus.$emit("showAlert", "請勾選你要刪除項目", "注意");
        return;
      }

      let that = this;
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", function(res) {
        if (res) {
          api.actionwork.batchDelete({ datas: that.selectDelete }).then(res => {
            if (res.data.status == "1") {
              $("#selectAll").prop("checked", false);
              that.workShowAll();
            } else {
              that.$eventBus.$emit("showAlert", res.data.message, "注意");
            }
          });
        }
      });
    },

    // 儲存更新
    workUpdate(item) {
      api.actionwork
        .update(item)
        .then(res => {
          // console.log('workUpdate', res);
          if (res.data.status == "1") {
            item.editor = 0;
            this.workShowAll();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message, "注意");
          }
        })
        .catch(err => {
          this.$eventBus.$emit("showAlert", err.data.message, "注意");
          // console.log('workUpdate', err);
        });
    },

    // 切換文字或輸入框
    change(item) {
      //this.$data.typeName = this.$options.data().typeName;
      item.editor = item.editor == 1 ? 0 : 1;
      // console.log(item.typeName, 'change editor:' + item.editor);
    },

    // 儲存
    save(item) {
      var that = this;
      item.startDate = this.formatTime(item.startDate);
      item.endDate = this.formatTime(item.endDate);
      if (item.id == 0) {
        api.actionwork
          .create(item)
          .then(res => {
            if (item.typeName == "") {
              this.$eventBus.$emit("showAlert", res.data.message, "注意");
            } else {
              item.editor = item.editor == 1 ? 0 : 1;
              that.workShowAll();
            }
          })
          .catch(err => {
            this.$eventBus.$emit("showAlert", err.data.message, "注意");
            // console.log('save', err);
          });
      } else {
        //item.editor = 0
        this.workUpdate(item);
      }
      // console.log(
      //   'id:' + item.id,
      //   'status:' + item.status,
      //   'editor:' + item.editor
      // );
    },

    // 新增類別
    addType() {
      this.$refs.editDialog.show();
    },

    cancel(item, index) {
      var that = this;
      if (item.id == 0) {
        // console.log('splice');
        that.actionworks.splice(index, 1);
      } else {
        var temp = JSON.parse(JSON.stringify(that.actionworkTemp[index]));
        that.actionworks.splice(index, 1, temp);
        // console.log('cancel');
      }
    },

    // 改變狀態
    changeStatus(item) {
      this.workUpdate(item);
      // console.log(item.typeName, 'status:' + item.status);
    },

    // 刪除
    deleteType(item) {
      var that = this;
      this.$eventBus.$emit(
        "showConfirm",
        "刪除後將無法復原，請確認是否刪除？",
        "注意"
      );
      $("#attention-delete").click(function() {
        api.actionwork
          .delete(item)
          .then(res => {
            $("#attention").modal("hide");
            that.workShowAll();
            // console.log(item.typeName, 'Delete success!');
          })
          .catch(err => {
            this.$eventBus.$emit("showAlert", err.data.message, "注意");
            $("#attention-delete").hide();
            // console.log(item.typeName, 'Delete error!');
          });
      });
    },
    formatTime: function(t) {
      return utils.formatTime(t, "YYYY-MM-DD");
    },
    readExcel() {
      //excel匯入
      var that = this;
      const files = that.$refs.batchCancelOrder.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
            cellDates: true
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
          let outputs = []; //清空
          let error = false;
          let errMessage = "";
          for (var i = 0; i < ws.length; i++) {
            if (ws[i].工號 == "" || ws[i].工號 == undefined) {
              errMessage = "資料內有工號未填寫!!";
              error = true;
              break;
            } else if (ws[i].開始時間 == "" || ws[i].開始時間 == undefined) {
              errMessage = "工號: " + ws[i].工號 + " 該筆資料開始時間未填寫!!";
              error = true;
              break;
            } else if (ws[i].結束時間 == "" || ws[i].結束時間 == undefined) {
              errMessage = "工號: " + ws[i].工號 + " 該筆資料結束時間未填寫!!";
              error = true;
              break;
            } else if (new moment(ws[i].開始時間).isAfter(ws[i].結束時間)) {
              errMessage =
                "工號: " + ws[i].工號 + " 該筆資料開始時間不得晚於結束時間!!";
              error = true;
              break;
            }

            outputs.push({
              employeeId: ws[i].工號,
              startDate: new moment(ws[i].開始時間).format("YYYY-MM-DD"),
              endDate: new moment(ws[i].結束時間).format("YYYY-MM-DD")
            });
          }

          if (error) {
            this.$eventBus.$emit("showAlert", errMessage);
            return;
          }

          api.actionwork
            .import({
              datas: outputs
            })
            .then(res => {
              if (res.data.status == "1") {
                that.$eventBus.$emit("showAlert", "名單匯入成功!!");
              } else {
                that.$eventBus.$emit("showAlert", res.data.message);
              }
              that.workShowAll();
            });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    async exportExcel() {
      this.searchData.startDate = utils.formatTime(
        this.searchData.startDate,
        "YYYY-MM-DD 00:00:00"
      );
      this.searchData.endDate = utils.formatTime(
        this.searchData.endDate,
        "YYYY-MM-DD 23:59:59"
      );

      this.searchData.pageNum = 1;
      this.searchData.pageSize = 9999;

      let task_QueryAll = api.actionwork.queryAll(this.searchData);
      let response = await task_QueryAll;
      if (response.data.status != "1" || response.data.result.total == 0) {
        this.$eventBus.$emit("showAlert", "查詢資料失敗，請重新操作!");
        return;
      }

      let data = [];
      // let index = 0;
      response.data.result.datas.forEach(res => {
        data.push({
          部門: res.deptName,
          工號: res.employeeId,
          姓名: res.employeeName,
          執行區間:
            utils.formatTime(res.startDate) +
            " ~ " +
            utils.formatTime(res.endDate)
          // 個人飲食習慣: this.getMealType(res.mealType) + ";" + res.notEat
        });
      });

      utils.exportData(
        data,
        utils.formatTime(this.eatDay, "YYYYMMDD") + "_移動式人員名單.xlsx"
      );
    },

    async RollCallExportExcel() {
      let task_QueryAll = api.rollcall.queryAll({
        employeeId: this.searchData.employeeId,
        countMode: 1,
        startTime: utils.formatTime(
          this.searchData.startDate,
          "YYYY-MM-DD 00:00:00"
        ),
        endTime: utils.formatTime(
          this.searchData.endDate,
          "YYYY-MM-DD 23:59:59"
        )
      });
      let response = await task_QueryAll;
      if (response.data.status != "1" || response.data.result.total == 0) {
        this.$eventBus.$emit("showAlert", "查詢資料失敗，請重新操作!");
        return;
      }

      let data = [];
      // let index = 0;
      response.data.result.datas.forEach(res => {
        data.push({
          工號: res.employeeId,
          姓名: res.employeeName,
          部門: res.deptName,
          日期: utils.formatTime(res.checkinTime, "YYYY-MM-DD"),
          時間: utils.formatTime(res.checkinTime, "HH:mm"),
          狀態: res.status
        });
      });

      utils.exportData(
        data,
        utils.formatTime(this.eatDay, "YYYYMMDD") + "_移動式人員點名名單.xlsx"
      );
    }
  }
};
</script>

<style scoped>
input[type="checkbox"] {
  position: relative;
  opacity: 1;
}
</style>