<template>
  <div id="DeptEdit">
    <section class="content-header">
      <h1>部級目標</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/OKR/DeptList">部級目標</router-link>
        </li>
        <li class="active">
          {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}目標
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">
              {{ !id ? "新增" : hasEdit ? "編輯" : "檢視" }}目標
            </h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i>
              返回列表
            </button>
          </div>
          <div class="table-responsive" style="max-width: 1400px; margin: auto">
            <div class="btns" v-if="hasEdit">
              <button
                class="btn btn-warning"
                style="float: right; margin: 10px 0px"
                type="button"
                @click="showContent()"
              >
                <i class="fa fa-plus"></i>
                新增目標
              </button>
            </div>
            <table
              class="table table-hover table-bordered min-800"
              style="margin: 5px auto"
            >
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw90">部別</th>
                  <th class="tc mw50">年份</th>
                  <th class="tc mw80">年度</th>
                  <th class="tc mw150">部級目標</th>
                  <th class="tc mw150">目標說明</th>
                  <th class="tc mw80">狀態</th>
                  <th class="tc mw80">部屬方向</th>
                  <th class="tc mw150" v-if="hasChange">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="tc" colspan="8">尚無設定目標</td>
                </tr>
                <tr v-else v-for="(data, index) in datas" :key="index">
                  <td class="tc" v-text="data.deptName"></td>
                  <td class="tc" v-text="data.year"></td>
                  <td
                    class="tc"
                    v-text="define.halfYearStatus[data.month]"
                  ></td>
                  <td class="pre-wrap" v-text="data.objective"></td>
                  <td class="pre-wrap" v-text="data.note || '-'"></td>
                  <td
                    class="tc"
                    v-text="define.sendStatus[data.objectiveStatus]"
                  ></td>
                  <td class="tc">
                    <iCheckCheckBox
                      :propNone="true"
                      :propChecked="data.checked"
                      :propDisabled="data.disabled"
                    />
                  </td>
                  <td class="tc" v-if="hasChange">
                    <button class="btn btn-primary" @click="showContent(data)">
                      修改
                    </button>
                    <span v-if="hasEdit">&nbsp;</span>
                    <button
                      class="btn btn-danger"
                      v-if="hasEdit"
                      @click="deleteData(data)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <SignLog
            :datas="datas"
            :data="data"
            :hasEdit="hasEdit"
            :backList="backList"
          />
          <div
            class="box-footer no-border mt-10"
            v-if="datas.length === 0"
          ></div>
        </div>
      </div>
    </section>
    <DeptContent ref="deptContent" v-on:updateList="getDataList" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import iCheckCheckBox from "@/components/iCheckBox";
import SignLog from "@/components/SignLog";
import DeptContent from "./DeptContent";

export default {
  components: {
    iCheckCheckBox,
    SignLog,
    DeptContent,
  },
  data() {
    return {
      hasAll: false,
      hasEdit: false,
      hasChange: false,
      loading: true,
      total: 0,
      addData: {
        deptId: this.$route.params.id,
        year: this.$route.params.year || this.global.getYear(),
        month: isNaN(this.$route.params.month) ? -1 : this.$route.params.month,
        formId: this.$route.params.formId,
        back: this.$route.params.back,
        searchData: this.$route.params.searchData,
      },
      view: this.$route.params.back === "SignHistory",
      id: this.$route.params.id,
      userData: {},
      permission: {},
      datas: [],
      data: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.deptId;
    this.addData.deptId = to.params.deptId;
    this.addData.year = to.params.year;
    this.addData.month = to.params.month;
    this.getDataList();
    next();
  },
  created() {
    this.userData = this.$user.getUserData();
    this.permission = this.global.getPermission(this.userData, 60);
    this.hasAll = !this.view;
    this.hasEdit =
      this.hasAll && (!this.id || this.permission.deptIds.includes(this.id));
    this.getDataList();
  },
  methods: {
    getDataList() {
      if (this.id) {
        this.addData.objectiveType = -1;
        api.okr.goal.department.queryAll(this.addData).then((res) => {
          if (res.data.status === "1") {
            this.datas = res.data.result.datas || [];
            this.total = res.data.result.total;
            if (this.datas.length) {
              this.data = this.datas[0];
              this.hasEdit = this.hasEdit && !this.data.objectiveStatus;
              this.hasChange =
                this.hasAll &&
                this.data.objectiveStatus !== 2 &&
                this.permission.allIds.includes(this.id);
            }
            this.updateDataList();
          }
        });
      } else {
        this.loading = false;
      }
    },
    updateDataList() {
      this.datas.forEach((data) => {
        data.checked = data.isDeptFollow ? 1 : 0;
        setTimeout(() => {
          data.checked = data.isDeptFollow ? true : false;
          data.disabled = true;
          this.$forceUpdate();
        }, 300);
      });
      this.loading = false;
    },
    deleteData(data) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.okr.goal.delete(data).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    showContent(data) {
      this.addData.objectiveType = 0;
      this.addData.userData = this.userData;
      this.addData.permission = this.permission;
      this.addData.deptId = this.id || this.userData.user.deptId;
      this.$refs.deptContent.showContent(data, this.addData);
    },
    backList() {
      this.$router.push({
        name: this.$route.params.back || "DeptList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
