<template>
  <select class="select2" multiple="multiple">
    <slot></slot>
  </select>
</template>
<script>
// import api from "@/assets/js/app-api"; 沒使用到

export default {
  components: {},
  props: {
    propOptions: "",
    propSelecteds: ""
  },
  data() {
    return {};
  },
  mounted() {
    this.updateOptions();
    this.updateSelecteds();
  },
  methods: {
    updateOptions() {
      var thisRef = this;
      $(this.$el)
        .empty()
        .select2({ data: this.propOptions })
        .on("change", function() {
          var tmpSelecteds = $(this).val();
          thisRef.$emit("update:propSelecteds", tmpSelecteds);
        });
    },
    updateSelecteds() {
      $(this.$el).val(this.propSelecteds);
      $(this.$el).trigger("change");
    }
  },
  watch: {
    propOptions: function(value) {
      $(this.$el).empty();
      $(this.$el).select2({ data: this.propOptions });
      this.updateSelecteds();
    },
    propSelecteds: function(value) {
      var tmpSelecteds = $(this.$el)
        .val()
        .join(",");
      if (value == tmpSelecteds) return;
      this.updateSelecteds();
    }
  }
};
</script>