<template>
  <div id="CapterEdit">
    <section class="content-header">
      <h1>章節管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">章節管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning company-files-edit">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">
              課程{{ isEdit ? "編輯" : "新增" }}
            </h4>
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  <span class="text-red">*</span> 章節名稱：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="data.chapterName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  章節說明：
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    required="required"
                    v-model="data.chapterDesc"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  <span class="text-red">*</span> 時數：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    required="required"
                    type="number"
                    min="0"
                    v-model="data.chapterTime"
                    style="
                      max-width: 100px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    :disabled="isEdit"
                  />
                  分鐘
                  <p class="text-gray">
                    ※在章節頁停留時間達該時數，代表完成章節
                    (影片建議使用影片時長)
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  <span class="text-red">*</span> 講師：
                </label>
                <div class="col-sm-4">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="data.teacher"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="datetimepicker">
                  <span class="text-red">*</span> 上傳檔案：
                </label>
                <div class="col-sm-8">
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="影片"
                      name="radioMode1"
                      v-model="data.learnMaterialType"
                    />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="嵌入碼(Youtube影片、Google簡報...適用)"
                      name="radioMode1"
                      v-model="data.learnMaterialType"
                    />
                  </div>
                  <div
                    class="mt-10"
                    v-if="
                      data.learnMaterialType == 0 &&
                      videoFile &&
                      videoFile.length < 1
                    "
                  >
                    <input
                      @change="changeFile($event, 'EducationChapterVideo')"
                      accept="video/*"
                      id="inputVideo"
                      name="file"
                      type="file"
                    />
                  </div>
                  <p class="text-red" v-if="data.learnMaterialType == 0">
                    *影片限制150MB，超過大小請剪輯後分段上傳
                  </p>
                  <div class="mt-10" v-if="data.learnMaterialType == 1">
                    <div>
                      請填上嵌入碼
                      <a
                        target="_blank"
                        class="pull-right"
                        href="https://steam.oxxostudio.tw/category/html/example/google-ppt.html#google_vignette"
                      >
                        <i class="fa fa-info-circle"></i>
                        Goolge簡報嵌入碼在哪裡
                      </a>
                    </div>
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="data.pptUrl"
                    ></textarea>
                  </div>
                  <div v-else class="mt-10">
                    <ul class="file-list list-unstyled">
                      <li v-for="(file, index) in videoFile" :key="index">
                        <div class="name">{{ file.name }}</div>
                        <div class="btn-area">
                          <button
                            type="button"
                            class="btn btn-danger"
                            @click="
                              deleteFile(file.applyId, 'EducationChapterVideo')
                            "
                          >
                            刪除
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="datetimepicker">
                  上傳附件：
                </label>
                <div class="col-sm-8 text">
                  <input
                    @change="changeFile($event, 'EducationChapter')"
                    accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip"
                    id="inputFile"
                    maxlength="5"
                    name="file2"
                    type="file"
                  />
                  <div class="help-block mt-10">
                    文件檔案上傳格式：
                    <span class="text-red">(上限10MB)</span>
                    <br />1.WORD：DOC、DOCX。 <br />2.EXCEL：XLS、XLSX。
                    <br />3.POWERPOINT：PPT、PPTX。 <br />4.PDF。 <br />5.TXT。
                    <br />6.XPS。 <br />7.ZIP。
                    <br />(需上傳多個檔案建議使用ZIP檔上傳)
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="files && files.length > 0">
                <label class="col-sm-3 control-label" for="datetimepicker">
                  已有檔案：
                </label>
                <div class="col-sm-8">
                  <ul class="file-list list-unstyled">
                    <li v-for="(file, index) in files" :key="index">
                      <div class="name">{{ file.name }}</div>
                      <div class="btn-area">
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="deleteFile(file.applyId, 'EducationChapter')"
                        >
                          刪除
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="datetimepicker">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-8">
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="啟用"
                      name="radioMode2"
                      v-model="data.chapterStatus"
                    />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="停用"
                      name="radioMode2"
                      v-model="data.chapterStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="save()"
                  :disabled="waittingFilesUpload"
                >
                  {{ waittingFilesUpload ? "檔案上傳中..." : "送出" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    iCheckRedioButton,
    DatePicker,
  },
  data() {
    return {
      isEdit: false,
      data: {
        chapterName: this.$route.params.chapterName || "", // 章節名稱
        chapterDesc: "", // 章節介紹
        courseId: this.$route.params.id || "", // 課程id
        chapterId: this.$route.params.chapterId || utils.getTimeId(),
        chapterStatus: 1, // 章節狀態
        chapterTime: 0, // 章節時數
        learnMaterialType: 0, // 教材類型 0：影片、1：簡報
        teacher: "", // 講師
        pptUrl: "",
      },
      files: [],
      videoFile: [],
      startTime: new Date(),
      waittingFilesUpload: false,
    };
  },
  watch: {
    "data.learnMaterialType"(val) {
      console.log("learnMaterialType: " + val);
      if (val == 1) {
        this.changeLearnMaterialType();
      }
    },
  },
  async created() {
    if (this.$route.params.chapterId) {
      this.isEdit = true;
      this.getData();
    }
  },
  mounted() {
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });
    console.log(this.isEdit);
  },
  methods: {
    getData() {
      api.education.chapter
        .query({
          courseId: this.$route.params.id, // 課程id
          chapterId: this.$route.params.chapterId, // 章節id
        })
        .then(({ data }) => {
          this.data = data.result;
          this.files = data.result.files;
          this.videoFile = data.result.videoFiles;
          this.startTime = this.dateFormat(data.result.startTime);
          this.$forceUpdate();
        });
    },
    changeFile(e, module) {
      var files = e.target.files;

      for (var i = 0; i < files.length; i++) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re =
          module == "EducationChapter"
            ? /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i
            : /\.(mp4|m4v|mov|qt|avi|flv|wmv|asf|mpeg|mpg|vob|mkv|asf|rm|rmvb|vob|ts|dat)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        console.log("changeFile", module, fileSize);

        if (module == "EducationChapterVideo" && fileSize > 150000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 150 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
        if (module == "EducationChapter" && fileSize > 10000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 10 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //檔案上傳
      this.uploadFile(files, module, this.data.chapterId, true);
    },

    removeFile() {
      $("#inputVideo").val("");
    },

    async uploadFile(files, module, id, multi = false) {
      this.waittingFilesUpload = true;
      let formdata = new FormData();

      for (var i = 0; i < files.length; i++) {
        formdata.append("files", files[i]);
      }

      formdata.append("files", files);
      formdata.append("module", module);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      if (response_upload.data.status == 1) {
        $("#inputFile").val("");
      } else {
        if (response_upload.data.message) {
          this.$eventBus.$emit("showAlert", response_upload.data.message);
        }
      }
      this.refreshFileList(module);
    },

    async refreshFileList(module) {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.data.chapterId,
        module: module,
      });
      var response_queryall = await task_queryall;
      if (module == "EducationChapter") {
        this.files = response_queryall.data.result;
      } else {
        this.videoFile = response_queryall.data.result;
      }
      this.waittingFilesUpload = false;
      this.$forceUpdate();
    },

    deleteFile(applyId, module, isAgree) {
      if (isAgree) {
        this.keepDeleteFile(applyId, module);
      } else {
        this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
          if (isOK) {
            this.keepDeleteFile(applyId, module);
          }
        });
      }
    },
    keepDeleteFile(applyId, module) {
      api.file.deleteApply({ id: applyId }).then((res) => {
        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", "刪除成功!");
        } else {
          this.$eventBus.$emit("showAlert", "刪除失敗!");
        }
        this.refreshFileList(module);
      });
    },
    save() {
      const action = this.isEdit ? "update" : "create";
      this.data.startTime = $(".datepicker").val();

      if (this.data.chapterName == "") {
        this.$eventBus.$emit("showAlert", "章節名稱不能為空");
        return;
      }

      if (this.data.chapterTime == "") {
        this.$eventBus.$emit("showAlert", "時數不能為0");
        return;
      }

      if (!Number.isInteger(Number(this.data.chapterTime))) {
        this.$eventBus.$emit("showAlert", "時數必須為整數");
        return;
      }

      if (this.data.teacher == "") {
        this.$eventBus.$emit("showAlert", "講師不能為空");
        return;
      }

      if (
        (this.data.learnMaterialType == 0 && this.videoFile == "") ||
        (this.data.learnMaterialType == 1 && this.data.pptUrl == "")
      ) {
        this.$eventBus.$emit("showAlert", "請上傳上課影片/嵌入碼");
        return;
      }

      if (
        this.data.learnMaterialType == 1 &&
        this.data.pptUrl.indexOf("iframe") == -1
      ) {
        this.$eventBus.$emit("showAlert", "請確認嵌入碼");
        return;
      }

      this.data.startTime = moment(this.startTime).format(
        "YYYY-MM-DD 00:00:00"
      );

      if (this.isEdit) {
        api.education.chapter[action](this.data).then((res) => {
          this.global.showResponse(this, res, action, this.back);
        });
      } else {
        this.$eventBus.$emit(
          "showConfirm",
          "新增後無法修改上課時數，確定新增嗎？",
          (isOK) => {
            if (isOK) {
              api.education.chapter[action](this.data).then((res) => {
                this.global.showResponse(this, res, action, this.back);
              });
            }
          }
        );
      }
    },
    dateFormat(time) {
      let newTime = time ? time : new Date();
      return new moment(newTime).format("YYYY/MM/DD");
    },
    back() {
      this.$router.push({
        name: "ETChapter",
        params: {
          id: this.$route.params.id,
          name: this.$route.params.name,
          searchData: this.$route.params.searchData,
        },
      });
    },

    changeLearnMaterialType() {
      if (this.videoFile && this.videoFile.length < 1) return;

      this.$eventBus.$emit(
        "showConfirm",
        "切換上傳檔案類別，會刪除影片，是否確認切換？",
        (isOK) => {
          if (isOK) {
            this.videoFile.forEach((file) => {
              this.deleteFile(file.applyId, "EducationChapterVideo", "agree");
            });
          } else {
            this.data.learnMaterialType = 0;
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
#CapterEdit {
  .text-gray {
    color: #999 !important;
  }

  .form-group {
    .file-list {
      li {
        display: flex;
        align-items: center;
        border: 1px solid #e3e3e3;
        border-bottom: none;
        padding-left: 5px;
        &:last-child {
          border: 1px solid #e3e3e3;
        }
        .name {
          width: calc(100% - 55px);
        }
        .btn-area {
          border-left: 1px solid #e3e3e3;
          width: 55px;
          .btn {
            margin: auto !important;
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
</style>
