<template>
  <!--預定活動-->
  <div class="modal fade" ref="activity_member">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">承辦人員設定-{{ title }}</h4>
        </div>
        <div class="modal-body">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td class="text-nowrap" style="width: 10%;">
                  <span class="text-red">*</span> 選擇部門
                </td>
                <td>
                  <select2Single
                    :options="depts"
                    data-placeholder="請選擇部門"
                    v-model="deptId"
                  />
                </td>
              </tr>
              <tr>
                <td><span class="text-red">*</span> 選擇人員</td>
                <td>
                  <select
                    class="form-control"
                    required="required"
                    v-model="employee"
                  >
                    <option value disabled>請選擇人員</option>
                    <option
                      :value="item"
                      v-for="(item, index) in memberOptions"
                      :key="index"
                      >{{ item.employeeName }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button
                    class="btn btn-primary"
                    title="新增人員"
                    type="button"
                    @click="addManager"
                  >
                    新增人員
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered table-striped mt-10">
            <tbody>
              <tr>
                <th>部門</th>
                <th>工號</th>
                <th>姓名</th>
                <th style="width: 10%;">功能</th>
              </tr>
              <tr v-for="(item, index) in activityManagers" :key="index">
                <td>{{ item.deptName }}</td>
                <td>{{ item.managerEmpId }}</td>
                <td>{{ item.managerEmpName }}</td>
                <td>
                  <button
                    class="btn btn-danger"
                    type="button"
                    title="刪除"
                    @click="deleteManager(item)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import select2Single from "@/components/select2Single";
import api from "@/assets/js/app-api";

export default {
  components: {
    select2Single
  },
  data() {
    return {
      title: "",
      deptId: "",
      employee: "",
      kindId: "",
      activityManagers: [],
      depts: [
        {
          id: "",
          text: "請選擇部門"
        }
      ],
      memberOptions: []
    };
  },
  watch: {
    deptId() {
      this.selectDept();
    }
  },
  methods: {
    async showActivityMember(item) {
      if (item.kindId == "" || item.kindId == null) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return;
      }
      this.kindId = item.kindId;
      this.title = item.kindName;

      await this.queryAll();

      this.employee = "";
      this.memberOptions=[];

      var task_deps = api.organization.getDeptList({
        companyId: ""
        // companyId: this.$store.state.loginUser.companyId
      });
      var response_desps = await task_deps;
      let temp = response_desps.data.result;
      this.depts = [
        {
          id: "",
          text: "請選擇部門"
        }
      ];
      temp.forEach(dept => {
        this.depts.push({
          id: dept.deptId,
          text: dept.deptIdName
        });
      });

      $(this.$refs.activity_member).modal("show");
    },

    //*---- 切換部門時替換員工資料
    async selectDept() {
      this.employee = "";
      var task_emps = api.organization.getDeptEmpList({
        deptId: this.deptId
      });
      var response_emps = await task_emps;
      this.memberOptions = response_emps.data.result;
    },

    async queryAll() {
      let activityManager = await api.activity.queryAllManager({
        kindId: this.kindId,
        pageNum: 0,
        pageSize: 0
      });

      this.activityManagers = activityManager.data.result.datas;
    },

    async addManager() {
      let response = await api.activity.createManager({
        kindId: this.kindId,
        managerEmpId: this.employee.employeeId,
        managerEmpName: this.employee.employeeName
      });

      if (response.data.status == "1") {
        this.$emit("refresh");
      } else {
        this.$eventBus.$emit("showAlert", "新增失敗!");
      }

      this.queryAll();
    },

    async deleteManager(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          api.activity.deleteManager(item).then(res => {
            if (res.data.status == "1") {
              this.$emit("refresh");
            } else {
              this.$eventBus.$emit("showAlert", "刪除失敗!");
            }
            this.queryAll();
          });
        }
      });
    }
  }
};
</script>
