<template>
  <div id="ProductsManager">
    <section class="content-header">
      <h1>公司產品管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/Products">公司產品</router-link>
        </li>
        <li class="active">公司產品管理</li>
      </ol>
    </section>
    <section class="content products">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <!-- <h4 class="pull-left">公司產品管理</h4> -->
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回
            </button>
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="openProductEdit('')"
            >
              <i class="fa fa-plus"></i> 新增公司產品
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th style="min-width: 150px;" width="15%">名稱</th>
                  <th style="min-width: 150px;" width="15%">簡述</th>
                  <th style="min-width: 150px;" width="15%">連結</th>
                  <th style="min-width: 150px;" width="15%">Android</th>
                  <th style="min-width: 150px;" width="15%">iOS</th>
                  <th class="text-center" style="min-width: 80px;" width="10%">排序</th>
                  <th class="text-nowrap text-center" style="min-width: 80px;" width="10%">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px;" width="15%">功能</th>
                </tr>
                <tr :key="index" v-for="(product, index) in products.datas">
                  <td>{{product.productName}}</td>
                  <td>{{product.productInfo}}</td>
                  <td>{{product.productUrl}}</td>
                  <td class="text-center">
                    <div class="pic" :key="index" v-for="(file, index) in product.androidFiles">
                      <img :alt="file.name" draggable="false" :src="'https://dev-eip.wanin.tw/' + file.filesUrl" width="100" />
                    </div>
                    <P>{{product.androidUrl}}</P>
                  </td>
                  <td class="text-center">
                    <div class="pic" :key="index" v-for="(file, index) in product.iosFiles">
                      <img :alt="file.name" draggable="false" :src="'https://dev-eip.wanin.tw/' + file.filesUrl" width="100" />
                    </div>
                    <P>{{product.iosUrl}}</P>
                  </td>
                  <td class="text-center">{{product.orderNum}}</td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'check'+product.productId"
                        :true-value="1"
                        @change="changeStatus(product)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="product.status"
                      />
                      <label :for="'check'+product.productId"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" @click="openProductEdit(product)">修改</button>
                    &nbsp;
                    <button
                      class="btn btn-danger"
                      @click="deleteProduct(product)"
                    >刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <productEdit ref="productEdit" v-on:refreshList="getProductsList" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import productEdit from "@/views/Product/ProductEdit";

export default {
  components: {
    productEdit
  },
  data() {
    return {
      products: {}
    };
  },
  created() {
    this.getProductsList();
  },
  methods: {
    async getProductsList() {
      var task_products = await api.product.queryAll({});
      this.products = task_products.data.result;
    },
    changeStatus(product) {
      api.product.update(product).then(res => {
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        }
      });
      this.getProductsList();
    },
    deleteProduct(product) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          api.product.delete(product).then(res => {
            if (res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "刪除成功!");
            } else {
              this.$eventBus.$emit("showAlert", "刪除失敗!");
            }
            this.getProductsList();
          });
        }
      });
    },
    openProductEdit(product) {
      this.$refs.productEdit.openProductEdit(product);
    },
    backList() {
      this.$router.push({
        name: "Products"
      });
      return;
    }
  }
};
</script>