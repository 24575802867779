var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "booking_activity", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "text-nowrap" }, [
                    _vm._v("活動類別"),
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.data.typeName) +
                        "-" +
                        _vm._s(_vm.data.kindName) +
                        "-" +
                        _vm._s(_vm.data.activityName) +
                        "\n                "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("活動時間")]),
                  _c("td", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          _vm.getDuration(
                            _vm.data.activitySTime,
                            _vm.data.activityETime
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("活動地點")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.location))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("報名時間")]),
                  _c("td", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          _vm.getDuration(
                            _vm.data.bookingSTime,
                            _vm.data.bookingETime
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("聯絡人")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.contacter))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("報名對象")]),
                  _c("td", [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          _vm.data.isLimit == 1
                            ? _vm.getJoinLimit(_vm.data.limitList)
                            : "全公司"
                        ) +
                        "\n                "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("備註")]),
                  _c("td", [
                    _c("div", {
                      staticClass: "editor",
                      domProps: { innerHTML: _vm._s(_vm.data.note) },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("人數限制")]),
                  _c("td", [_vm._v(_vm._s(_vm.getMaxLimit(_vm.data)))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("附件")]),
                  _c(
                    "td",
                    _vm._l(_vm.data.files, function (file) {
                      return _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.data.files.length > 0,
                              expression: "data.files.length > 0",
                            },
                          ],
                          key: file.id,
                          staticClass: "file-icon",
                          attrs: {
                            href: `${_vm.host}/file/GetCompanyFile?filesId=${file.filesId}`,
                          },
                        },
                        [
                          _c("i", {
                            class: "icon icon-" + file.mine.substring(0, 3),
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _vm._m(1),
          _vm.cancelBooking
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelBookingActivity(_vm.data)
                    },
                  },
                },
                [_vm._v("\n          取消報名\n        ")]
              )
            : _vm.booking
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.bookingActivity(_vm.data.activityId)
                    },
                  },
                },
                [_vm._v("\n          確定報名\n        ")]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("\n          關閉\n        ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("活動資訊")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red pull-left mt-5" }, [
      _c("i", { staticClass: "fa fa-warning" }),
      _vm._v(" 已過的時段無法取消報名。\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }