<template>
  <!-- 編輯店家 -->
  <div class="modal fade" ref="store_setting" id="storeEdit">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">編輯店家</h4>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreName">
                <span class="text-red">*</span> 店家名稱：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputStoreName"
                  required="required"
                  type="text"
                  v-model="edit.storeName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreTel">
                <span class="text-red">*</span> 電話：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputStoreTel"
                  required="required"
                  type="text"
                  v-model="edit.phone"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreFax">傳真：</label>
              <div class="col-sm-9">
                <input class="form-control" id="inputStoreFax" type="text" v-model="edit.fax" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreAddress">
                <span class="text-red">*</span> 地址：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputStoreAddress"
                  required="required"
                  type="text"
                  v-model="edit.address"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreEmail">電子郵件：</label>
              <div class="col-sm-9">
                <input class="form-control" id="inputStoreEmail" type="text" v-model="edit.email" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreInfo">
                描述：
                <br />(使用者可見)
              </label>
              <div class="col-sm-9">
                <textarea class="form-control" id="inputStoreInfo" v-model="edit.storeInfo"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputStoreNote">
                備註：
                <br />(僅限管理員)
              </label>
              <div class="col-sm-9">
                <textarea class="form-control" id="inputStoreNote" v-model="edit.note"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">素食：</label>
              <div class="col-sm-9">
                <div class="checkbox-inline">
                  <iCheckCheckBox
                    v-bind:propChecked="edit.isVegetarian"
                    v-on:update:checked="edit.isVegetarian=$event['checked']"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" ref="editSaveButton">儲存</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import iCheckCheckBox from "@/components/iCheck_CheckBox";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  data() {
    return {
      editMode: false,
      responseWait: false,
      edit: {
        storeId: utils.getTimeId(),
        storeName: "",
        phone: "",
        fax: "",
        address: "",
        email: "",
        averageSorce: 0,
        storeStatus: 1,
        storeInfo: "",
        note: "",
        isVegetarian: 0
      }
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue"
    });
  },
  methods: {
    async showStoreEditBox(storeId) {
      if (storeId != "") {
        this.editMode = true;
        await this.getStoreInfo(storeId);
        $("#storeEdit .modal-title").html("編輯店家");
      } else {
        this.editMode = false;
        this.edit = {
          storeId: utils.getTimeId(),
          storeName: "",
          phone: "",
          fax: "",
          address: "",
          email: "",
          averageSorce: 0,
          storeStatus: 1,
          storeInfo: "",
          note: "",
          isVegetarian: 0
        };
        $("#storeEdit .modal-title").html("新增店家");
      }
      $(this.$refs.store_setting).modal("show");
      $(this.$refs.editSaveButton).click(() => {
        if (this.editMode) this.updateStore(this.edit);
        else {
          this.addStore(this.edit);
        }
      });
    },
    getStoreInfo(storeId) {
      api.store
        .query({
          storeId: storeId
        })
        .then(res => {
          if (res.data.status == "1")
            this.edit = JSON.parse(JSON.stringify(res.data.result));
        });
    },
    updateStore(store) {

      if (!this.checkStoreInfo(store)) return;

      if (this.responseWait) return;
      this.responseWait = true;

      api.store.update(store).then(res => {
        this.responseWait = false;
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        } else {
          $(this.$refs.store_setting).modal("hide");
        }
        this.$emit("getStoreList");
      });
    },
    addStore(store) {
      // 添加店家
      if (!this.checkStoreInfo(store)) return;

      if (this.responseWait) return;
      this.responseWait = true;

      api.store.create(store).then(res => {
        this.responseWait = false;
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        } else {
          $(this.$refs.store_setting).modal("hide");
        }
        this.$emit("getStoreList");
      });
    },
    checkStoreInfo(store) {
      if (store == null || store == undefined) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return false;
      }
      if (store.storeName == undefined || store.storeName == "") {
        this.$eventBus.$emit("showAlert", "店家名稱不可為空!!");
        return false;
      }

      if (store.phone == undefined || store.phone == "") {
        this.$eventBus.$emit("showAlert", "店家電話不可為空!!");
        return false;
      }

      if (store.address == undefined || store.address == "") {
        this.$eventBus.$emit("showAlert", "店家地址不可為空!!");
        return false;
      }

      return true;
    }
  }
};
</script>