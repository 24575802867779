<template>
  <div class="modal fade" ref="bigScorePersonalContent">
    <div class="modal-dialog" style="width: 900px">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">評分</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="control-label w90">考核項目</th>
                    <td colspan="2" v-text="data.itemName"></td>
                  </tr>
                  <tr
                    v-if="
                      define.bigType[data.itemName] &&
                      define.bigType[data.itemName].s0
                    "
                  >
                    <th class="control-label">
                      <span>評分參考</span>
                    </th>
                    <td>
                      {{ define.bigType[data.itemName].s0.text }}
                    </td>
                    <td class="w90"></td>
                  </tr>
                  <template v-for="(num, index) in 5">
                    <tr v-if="define.bigType[data.itemName]" :key="index">
                      <th class="control-label">
                        <span
                          v-if="
                            index === 0 && !define.bigType[data.itemName].s0
                          "
                        >
                          評分參考
                        </span>
                      </th>
                      <td>
                        {{ define.bigType[data.itemName][`s${num}`].text }}
                      </td>
                      <td class="w90">
                        {{ define.bigType[data.itemName][`s${num}`].score }}
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="control-label" colspan="3">
                      <table
                        class="table table-bordered"
                        v-for="(scoreItem, index) in data.scores"
                        :key="index"
                      >
                        <tbody>
                          <tr>
                            <td
                              colspan="2"
                              class="tc bg-dark"
                              v-text="
                                `${scoreItem.createEmpName}${
                                  index === 0
                                    ? '（自評）'
                                    : scoreItem.createIsManager === '0' &&
                                      scoreItem.createDeptType.includes('0060')
                                    ? '（上層主管）'
                                    : scoreItem.createIsManager === '1' &&
                                      scoreItem.createDeptType.includes('0060')
                                    ? '（部門主管）'
                                    : '（高層主管）'
                                }`
                              "
                            ></td>
                          </tr>
                          <tr>
                            <td class="tc bg-dark-gray" width="200px">
                              <div
                                class="fw-b fz50"
                                v-text="scoreItem.score"
                              ></div>
                              <button
                                class="btn btn-dark btn-lg"
                                type="button"
                                @click="
                                  minusScore(
                                    scoreItem,
                                    data.hasHR ||
                                      data.userData.user.employeeId ===
                                        scoreItem.createEmpId
                                  )
                                "
                              >
                                <i class="fa fa-minus"></i>
                              </button>
                              <button
                                class="btn btn-dark btn-lg"
                                type="button"
                                @click="
                                  plusScore(
                                    scoreItem,
                                    data.hasHR ||
                                      data.userData.user.employeeId ===
                                        scoreItem.createEmpId
                                  )
                                "
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </td>
                            <td>
                              <textarea
                                rows="6"
                                type="text"
                                class="form-control"
                                placeholder="輸入文字"
                                :disabled="
                                  !data.hasHR &&
                                  data.userData.user.employeeId !==
                                    scoreItem.createEmpId
                                "
                                v-model.trim="scoreItem.note"
                              ></textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">儲存</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      data: {},
      scoreItem: {},
    };
  },
  methods: {
    async showContent(data) {
      this.data = JSON.parse(JSON.stringify(data));
      this.getScoreItem();
      $(this.$refs.bigScorePersonalContent).modal("show");
    },
    getScoreItem() {
      this.scoreItem =
        this.data.scores.find(
          (x) => this.data.userData.user.employeeId === x.createEmpId
        ) || {};
      this.resetScore();
    },
    resetScore() {
      this.scoreItem.score = this.scoreItem.score || 15;
    },
    minusScore(scoreItem, hasEdit) {
      if (hasEdit && scoreItem.score > 10) {
        scoreItem.score--;
      }
    },
    plusScore(scoreItem, hasEdit) {
      if (hasEdit && scoreItem.score < 20) {
        scoreItem.score++;
      }
    },
    submit() {
      this.scoreItem.bigGroupId = this.data.bigGroupId;
      api.okr.score.max.update(this.scoreItem).then((res) => {
        this.global.showResponse(this, res, "update");
        $(this.$refs.bigScorePersonalContent).modal("hide");
        this.$emit("updateList");
      });
    },
  },
};
</script>