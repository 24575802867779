<template>
  <div id="OrderNextWeekMealAll"></div>
</template>

<script>
export default {
  created() {
    this.$router.push({
      name: "OrderNextWeekMeal",
      params: {
        expatId: this.$route.params.expatId || this.$user.getUserExpatId(),
      },
    });
  },
};
</script>