<template>
  <div>
    <section class="content-header">
      <h1>文件類別設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司文件</li>
        <li class="active">公司文件類別設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="addType()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增類別
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;" width="50%">文件類別</th>
                  <th class="text-nowrap" width="10%">文件數</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">首頁顯示</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">首頁排序</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <tr :key="item.id" v-for="(item, index) in announcementtypes">
                  <td>
                    <p :id="'name'+item.id" class="m-0" v-show="item.editor==0">{{ item.typeName }}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.typeName"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td>{{ item.companyDocumentCount }}</td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'check'+item.tId"
                        :true-value="1"
                        @change="changeStatus(item)"
                        :name="'check'+item.tId"
                        type="checkbox"
                        v-model="item.docStatus"
                      />
                      <label :for="'check'+item.tId"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    {{ item.isShowHome > 0 ? '是' : '否' }}
                  </td>
                  <td>
                    <p :id="'order'+item.id" class="m-0" v-show="item.editor==0">{{ item.isShowHome }}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.isShowHome"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td class="text-center">
                    <button @click="change(item)" class="btn btn-primary" v-show="item.editor==0">修改</button>
                    <button
                      @click="saveType(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor!=0"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      data-target="#attention"
                      data-toggle="modal"
                      v-show="item.editor==0"
                    >刪除</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor!=0"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <AlertBox />
  </div>
</template>

<script>
import AlertBox from '@/components/AlertBox';
import api from '@/assets/js/app-api';
// import utils from '@/assets/js/app-utils'; 尚未使用到

export default {
  components: {
    AlertBox
  },
  delimiters: ['${', '}'],
  data() {
    return {
      announcementtypes: [],
      announcementTemp: []
    };
  },
  mounted() {
    this.queryAll();
  },
  methods: {
    // 顯示列表
    async queryAll() {
      var task1 = api.companyFilesType.queryallTypeEdit({});
      var response = await task1;
      var tmp = response.data.result.datas;
      this.announcementtypes = [];
      this.announcementTemp = [];
      if (tmp.length > 0) {
        for (var i = 0; i < tmp.length; i++) {
          tmp[i]['editor'] = 0;
          if (!tmp[i]['docStatus']) tmp[i]['docStatus'] = 0;
          if (!tmp[i]['isShowHome']) tmp[i]['isShowHome'] = 0;
        }
        this.announcementTemp = JSON.parse(JSON.stringify(tmp));
        this.announcementtypes = tmp;
      }
    },

    // 儲存更新
    workUpdate(item, type) {
      api.companyFilesType
        .update(item)
        .then(res => {
          if (res.data.status == '1') {
            item.editor = 0;
          } else {
            $('#attention').modal('show');
            $('#attention-message').html(res.data.message);
            $('#attention-delete').hide();
          }

          if (type != 'docStatus') {
            this.updateTypeOrder(item, this.queryAll); //更新排序
          }
        })
        .catch(err => {
          $('#attention').modal('show');
          $('#attention-message').html(err.data.message);
          $('#attention-delete').hide();
        });
    },

    // 切換文字或輸入框
    change(item) {
      //this.$data.typeName = this.$options.data().typeName;
      item.editor = item.editor == 1 ? 0 : 1;
    },

    // 儲存
    saveType(item) {
      if (item.tId == 0) {
        api.companyFilesType
          .create(item)
          .then(res => {
            if (item.typeName == '') {
              $('#attention').modal('show');
              $('#attention-message').html(res.data.message);
              $('#attention-delete').hide();
            } else {
              item.editor = item.editor == 1 ? 0 : 1;
            }

            this.updateTypeOrder(item, this.queryAll); //更新排序
          })
          .catch(err => {
            $('#attention').modal('show');
            $('#attention-message').html(err.data.message);
            $('#attention-delete').hide();
          });
      } else {
        //item.editor = 0
        this.workUpdate(item);
      }
    },
    updateTypeOrder(item, callback) {
      api.companyFilesType
        .updateOrder({
          TId: item.tId,
          IsShowHome: item.isShowHome
        })
        .then(res => {
          if (callback) callback();
        })
        .catch(err => {
          $('#attention').modal('show');
          $('#attention-message').html(err.data.message);
          $('#attention-delete').hide();
        });
    },

    // 新增類別
    addType() {
      this.announcementtypes.push({
        tId: '',
        typeName: '',
        docStatus: '1',
        editor: '1'
      });
    },

    cancel(item, index) {
      var that = this;
      if (item.tId == 0) {
        that.announcementtypes.splice(index, 1);
      } else {
        var temp = JSON.parse(JSON.stringify(that.announcementTemp[index]));
        that.announcementtypes.splice(index, 1, temp);
      }
    },

    // 改變狀態
    changeStatus(item) {
      this.workUpdate(item, 'docStatus');
    },

    // 刪除
    deleteType(item) {
      $('#attention-message').html('刪除後將無法復原，請確認是否刪除？');
      $('#attention-delete').show();
      $('#attention-delete').click(()=>{
        api.companyFilesType
          .delete(item)
          .then(res => {
            $('#attention').modal('hide');
            this.queryAll();
          })
          .catch(err => {
            $('#attention').modal('show');
            $('#attention-message').html(err.data.message);
            $('#attention-delete').hide();
          });
      });
    }
  }
};
</script>
