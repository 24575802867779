<template>
  <div class="modal fade" ref="scoreAddContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增考核</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit()">
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table no-border">
                <tbody>
                  <tr>
                    <td class="control-label w90">
                      <span class="text-red">*</span>
                      考核單位
                    </td>
                    <td colspan="2">
                      <select
                        class="form-control"
                        required
                        :disabled="!isHR"
                        v-model="data.assignDeptId"
                        @change="getUserList(isHR)"
                      >
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      所屬人員
                    </td>
                    <td class="wp40" v-if="isHR">
                      <select
                        class="form-control"
                        v-model="data.orginDeptId"
                        @change="getUserList(false)"
                      >
                        <option
                          v-for="(item, index) in list"
                          :key="index"
                          :value="item.deptId"
                        >
                          {{ item.deptName }}
                        </option>
                      </select>
                    </td>
                    <td :colspan="isHR ? 1 : 2">
                      <select
                        class="form-control"
                        required
                        :disabled="!isHR"
                        v-model="data.employeeId"
                      >
                        <option
                          v-for="(user, index) in users"
                          :key="index"
                          :value="user.employeeId"
                        >
                          {{ user.employeeName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="control-label">
                      <span class="text-red">*</span>
                      年度
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-model.number="data.year"
                        @change="getCycleMonths()"
                      >
                        <option
                          v-for="(year, index) in years"
                          :key="index"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-if="data.openType === 1"
                        v-model.number="data.month"
                      >
                        <option
                          v-for="(month, index) in months"
                          :key="index"
                          :value="month"
                        >
                          {{ month }}
                        </option>
                      </select>
                      <select
                        class="form-control"
                        required
                        :disabled="disabled"
                        v-if="data.openType === 2"
                        v-model.number="data.month"
                      >
                        <option value="0">上半年</option>
                        <option value="1">下半年</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">新增</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  data() {
    return {
      isHR: false,
      disabled: false,
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      months: [],
      list: [],
      users: [],
      data: {},
    };
  },
  methods: {
    async showContent(data) {
      this.disabled = data.month != -1 ? true : false;
      this.data = JSON.parse(JSON.stringify(data));
      this.data.assignDeptId = this.data.deptId;
      this.data.orginDeptId = this.data.deptId;
      // TODO: HR例外起單
      // this.isHR = this.data.permission.isHR;
      this.getList();
      $(this.$refs.scoreAddContent).modal("show");
    },
    getList() {
      api.organization
        .getDeptList({
          companyId: this.$store.state.loginUser.companyId,
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.list = res.data.result || [];
            this.getUserList();
            this.getCycleMonths();
          }
        });
    },
    getUserList(stop) {
      if (!stop) {
        this.data.deptId = this.isHR
          ? this.data.orginDeptId
          : this.data.assignDeptId;
        if (this.data.userData.user.traineeDeptId) {
          api.okr.mgmt.assign.queryAll().then((res) => {
            if (res.data.status === "1") {
              this.users = res.data.result.datas || [];
            }
          });
        } else {
          api.organization.queryEmployeeAll(this.data).then((res) => {
            if (res.data.status === "1") {
              this.users = res.data.result.datas || [];
            }
          });
        }
      }
    },
    getCycleMonths() {
      if (this.data.openType === 1) {
        api.okr.score.min.cycleQuery(this.data).then((res) => {
          if (res.data.status === "1") {
            this.months = res.data.result || [];
          }
        });
      }
    },
    submit() {
      this.data.deptId = this.data.assignDeptId;
      $(this.$refs.scoreAddContent).modal("hide");
      this.$emit("updateList", this.data);
    },
  },
};
</script>